<div *ngIf="showTexbox" [@fadeInOut]="showTexbox">
    <div class="fadeinout" [@fadeInOut]="!EditToggle" *ngIf="!EditToggle">
        <p *ngIf="mx.fieldname != 'q7_ans' && mx.fieldname != 'career_status' && mx.fieldname != 'external_links'" class="regular abcx" style="white-space: pre-wrap;" [innerHTML]="service.addLineBreaks(mx.data) | linky:{stripPrefix: false}">
            <!-- {{ mx.data }} -->
        </p>
        <p *ngIf="mx.fieldname == 'q7_ans'">
            <span *ngFor="let item of mx.data; let i = index" style="background-color: #ffe284; padding: 5px 10px; color: black;margin: 2px; cursor: pointer;" class="badge badge-dark" (click)="gotoPeopleExplorer(item)">{{item}}</span>
        </p>
        <div *ngIf="mx.fieldname == 'career_status'">
            <ul class="career">
                <li *ngFor="let item of selectedCareerStatus; let i = index">{{item}} <i *ngIf="edit" class="glyphicon glyphicon-minus-sign" (click)="removeCareer(i)"></i></li>
            </ul>
        </div>
        <div *ngIf="mx.fieldname == 'external_links'">
            <ul class="career">
                <li *ngFor="let item of mx.data; let i = index">
                    <a target="_blank" href="{{item.link_url}}">{{item.link_title}}</a>
                </li>
            </ul>
        </div>
        <span *ngIf="edit" (click)="EditToggle = !EditToggle" class="glyphicon glyphicon-pencil"></span>
    </div>
    <div [@fadeInOut]="EditToggle" *ngIf="EditToggle">
        <p *ngIf="mx.fieldname != 'q6_ans' && mx.fieldname != 'q7_ans' && mx.fieldname != 'about' && mx.fieldname != 'q8_ans' && mx.fieldname != 'q9_ans' && mx.fieldname != 'career_status'" style="font-family: OpenSans-Light; font-style: italic; color:black;">Enter 1 to 3 sentences</p>
        <p *ngIf="mx.fieldname == 'q6_ans' || mx.fieldname == 'about' || mx.fieldname == 'q8_ans' || mx.fieldname == 'q9_ans'" style="font-family: OpenSans-Light; font-style: italic; color:black;">Be as lengthy as you'd like.</p>
        <!-- <p *ngIf="mx.fieldname == 'q7_ans'" style="font-family: OpenSans-Light; font-style: italic; color:black;">Enter </p> -->
        <textarea *ngIf="mx.fieldname != 'q7_ans' && mx.fieldname != 'career_status'" #info placeholder="Enter information" [innerHTML]="service.removeLineBreaks(mx.data)"></textarea>
        <p *ngIf="mx.fieldname == 'q7_ans'">
            <span *ngFor="let item of mx.data; let i = index" style="background-color: #ffe284; padding: 5px 10px; color: black;margin: 2px; cursor: pointer;" class="badge badge-dark" (click)="gotoPeopleExplorer(item)">{{item}} <i class="glyphicon glyphicon-remove" (click)="removeHobbies(i);" ></i></span>
        </p>
        <textarea *ngIf="mx.fieldname == 'q7_ans'" [(ngModel)]="tag" placeholder="Enter comma separated hobbies & interests"></textarea>
        <i *ngIf="mx.fieldname != 'q7_ans' && mx.fieldname != 'career_status'" class="glyphicon glyphicon-check dsc-glyp" (click)="onEnterDesc()"></i>
        <i *ngIf="mx.fieldname == 'q7_ans'" class="glyphicon glyphicon-check dsc-glyp" (click)="addTag()"></i>

        <div *ngIf="mx.fieldname == 'career_status'">
            <my-dropdown [defaultText]="'Select career status'" [myOptions]="careerStatus" [selectedOptions]="selectedCareerStatus" (selectedArray)="onChangedd($event)" style="width: 70%; display: inline-block"></my-dropdown>
            <i class="glyphicon glyphicon-check dsc-glyp" (click)="updateCareerStatus()"></i>
        </div>
    </div>
</div>
<div *ngIf="!showTexbox" [@fadeInOut]="!showTexbox">
    <p style="font-style: italic;">Edit to add more information
        <i *ngIf="edit" class="glyphicon glyphicon-pencil" (click)="showText()"></i>
    </p>
</div>
<style>
     :host ::ng-deep .abcx br {
        line-height: 27px;
    }
    
     :host ::ng-deep .dropdown button {
        width: 100%;
    }
    
    i {
        vertical-align: top;
        padding-left: 5px;
    }
    
    .regular {
        font-family: open-regular;
    }
    
    .water-res {
        font-family: OpenSans-Semibold;
        color: #929292;
        margin: 0 0 0px;
        font-size: 13px;
    }
    
    .exp-desc {
        color: #a2a8a8;
        font-size: 13px;
    }
    
    .para-title {
        padding-left: 30px;
        font-size: 13px;
        color: grey;
    }
    
    textarea {
        max-width: 500px;
        width: 90%;
        height: 100px;
        -moz-border-bottom-colors: none;
        -moz-border-left-colors: none;
        -moz-border-right-colors: none;
        -moz-border-top-colors: none;
        background: none repeat scroll 0 0 rgba(0, 0, 0, 0.07);
        border-color: -moz-use-text-color #FFFFFF #FFFFFF -moz-use-text-color;
        border-image: none;
        border-radius: 6px 6px 6px 6px;
        border-style: none solid solid none;
        border-width: medium 1px 1px medium;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12) inset;
        color: #555555;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 1em;
        line-height: 1.4em;
        padding: 5px 8px;
        transition: background-color 0.2s ease 0s;
    }
    
    textarea:focus {
        background: none repeat scroll 0 0 #FFFFFF;
        outline-width: 0;
    }
    
    @font-face {
        font-family: open-regular;
        src: url('/assets/fonts/new/opensans-regular-webfont.eot?#iefix') format('embedded-opentype'), /* IE */
        url('/assets/fonts/new/opensans-regular-webfont.ttf') format("truetype");
        /* non-IE */
    }
    
    @font-face {
        font-family: OpenSans-Semibold;
        src: url('/assets/fonts/new/opensans-semibold-webfont.eot?#iefix') format('embedded-opentype'), /* IE */
        url('/assets/fonts/new/opensans-semibold-webfont.ttf') format("truetype");
        /* non-IE */
    }
    
    @font-face {
        font-family: OpenSans-Bold;
        src: url('/assets/fonts/new/opensans-bold-webfont.eot?#iefix') format('embedded-opentype'), /* IE */
        url('/assets/fonts/new/opensans-bold-webfont.ttf') format("truetype");
        /* non-IE */
    }
</style>