// import { PersonCreate } from './createperson.component';
import { Router, ActivatedRoute } from '@angular/router';
import { Ng2AutoCompleteModule } from 'ng2-auto-complete';

import {
  Component,
  NgZone,
  EventEmitter,
  Input,
  Output,
  ChangeDetectorRef
} from '@angular/core';

import {
  ProjectService
}
  from './../projectservice';


import { IMultiSelectOption } from 'angular-2-dropdown-multiselect';

import * as _ from 'lodash';

import {
  CookieService
} from 'angular2-cookie/core';

import { Title } from '@angular/platform-browser';

@Component({
  selector: 'claimperson',
  templateUrl: './claimperson.html'
})

export class ClaimPerson {

  otherRoleDialog: boolean = false;
  personCreated: boolean = false;
  otherCat: any;
  id: any;
  email: any;
  person_name: any;
  useremail: any;
  count: any = 0;
  personRole: any = [];
  addorganization: any;
  user: any = {}
  loginStatus: boolean = false;
  created: boolean = false;
  checked: boolean = false;
  createdByRep: boolean = false;
  createdByOther: boolean = false;
  verified: boolean = false;
  verify_code: any;
  notifySetting: boolean = false;
  notify_email: any;
  loginObject: any = {};
  personid: any;
  personname: any;
  me: boolean = true;
  terms: boolean = false;
  agelimit: boolean = false;
  data: any = 'RePicture Engineering will not sell your personal data to third parties. By clicking the "Join Now" button below I am consenting to use of my personal data for RePicture Engineering\'s internal business purposes.  I verify that I am not a citizen of any jurisdiction (such as the European Union) that imposes regulations on data processors or data controllers that are more stringent than those imposed by the United States government';
  private personRoleOptions: IMultiSelectOption[] = [
    { id: 'Civil Engineer', name: 'Civil Engineer' },
    { id: 'Architect', name: 'Architect' },
    { id: 'Construction Contractor', name: 'Construction Contractor' },
    { id: 'Developer', name: 'Developer' },
    { id: 'Electrical Engineer', name: 'Electrical Engineer' },
    { id: 'Environmental Consultant', name: 'Environmental Consultant' },
    { id: 'Estimator', name: 'Estimator' },
    { id: 'Landscape Architect', name: 'Landscape Architect' },
    { id: 'Mechanical Engineer', name: 'Mechanical Engineer' },
    { id: 'Owner', name: 'Owner' },
    { id: 'Planner', name: 'Planner' },
    { id: 'Surveyor', name: 'Surveyor' },
    { id: 'Other', name: 'Other' },
  ];

  constructor(public service: ProjectService, private cookie: CookieService, private cd: ChangeDetectorRef, private titleService: Title, private router : Router) { }

  ngOnInit() {
    this.checked = true;
    this.titleService.setTitle("Claim person | Repicture App");
    if (!localStorage.getItem('authtoken')) {
      this.loginStatus = false;

      let isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
      if(isIEOrEdge){



        if(this.service.claimableObject.userid != ''){
        this.personid = this.service.claimableObject.userid;
        this.user.first_name = this.service.claimableObject.firstname;
        this.user.last_name = this.service.claimableObject.lastname;
        }
        else{
          this.router.navigate(['/']);
        }

      }else{
        this.personid = this.service.claimableObject.userid;
        // this.personname = this.cookie.get('claimPersonName').split(' ');
        // this.personRole = this.cookie.get('claimPersonRole').split(',');
        this.user.first_name = this.service.claimableObject.firstname;
        this.user.last_name = this.service.claimableObject.lastname;
      }
   
    } else {
      this.loginStatus = true;
      //alert('You already have an account and profile.');
      this.service.sendAlert('Error', "You already have an account and profile.", 0);
      return;
    }
  }

  onChangedd($event) {
    this.personRole = $event;
    console.log(this.personRole);
    if (_.includes($event, 'Other')) {
      this.otherRoleDialog = true;
    } else {
      this.otherRoleDialog = false;
    }
  }

  claimPerson() {
    // if (window.localStorage.getItem('authtoken')) {
    if(!this.terms) {
      //alert('You need to agree account setup and data terms.');
      this.service.sendAlert('Error', 'You need to agree account setup and data terms.', 0);
      return;
    }
    if(!this.validate()) {
      return;
    }
    if (!this.me) {
      //alert('You can only claim one profile as your own.');
      this.service.sendAlert('Error', "You can only claim one profile as your own.", 0);
      return;
    }
    if (!this.user.first_name || !this.user.last_name || !this.user.email || !this.user.password) {
      //alert('Complete all fields to continue.');
      this.service.sendAlert('Error', "Complete all fields to continue.", 0);
      return;
    }
    // this.service.sendAlert('Info', "Please contact info@RePicture.com to claim a user profile.", 1);
    // return;
    let obj = {
      email: this.user.email,
      password: this.user.password,
      user_id: this.personid
    }
    this.service.claimPerson(obj).then(res => {
      this.cookie.put('public', 'public');
      //alert('An email has been sent to your email address with account activation instructions.');
      this.service.sendAlert('Success', "An email has been sent to your email address with account activation instructions.", 1);
    });
    // if (!this.checked) {
    //   alert('You need to be a representative of this organization.');
    //   return;
    // }
    // if (this.service.myOrg && this.checked) {
    //   alert('Your profile is already associated with another organization.');
    //   return;
    // }
    // if (_.isEmpty(this.personRole)) {
    //   alert('Please select role.');
    //   return;
    // }
    // if (this.checked && !this.email) {
    //   alert("Please enter your organization's email.");
    //   return;
    // }
    // if (this.checked && this.email) {
    //   this.service.isPersonEmailValid(this.email).then(res => {
    //     if (res.status == "Invalid email") {
    //       alert("Email doesn't seem to be an organizations's email.");
    //       return;
    //     } else if (res.status == "Valid email") {
    //       let obj = {};
    //       if (this.otherRoleDialog) {
    //         let index = this.personRole.indexOf('Other')
    //         this.personRole[index] = this.otherCat;
    //       }

    //       obj = {
    //         org_role: this.personRole,
    //         org_email: this.checked ? this.email : '',
    //         is_representative: this.checked ? true : false
    //       };

    //       this.service.doPersonProfilePatch(this.personid, obj).then(result => {
    //         // this.createdByRep = true;
    //         // this.created = true;
    //       });

    //       this.service.sendCode(this.personid, this.email).then(result => {
    //         this.createdByRep = true;
    //         this.created = true;
    //       });
    //     }
    //   });
    // }
    // } else {
    //   this.loginStatus = false;
    // setTimeout(() => {
    //   this.cd.detectChanges();
    //   document.getElementById('loginModal').click();
    //   this.cd.detectChanges();
    //   if (this.count == 0) {
    //     document.getElementById('loginModal').click();
    //     this.count++;
    //   }
    // }, 4);
    // }
  }

  verifyCode() {
    if (!this.verify_code) {
      //alert('Please enter the code.')
      this.service.sendAlert('Error', "Please enter the code.", 0);
      return;
    }
    let obj = {
      org_verify_code: this.verify_code,
      org_id: this.personid
    }
    this.service.verifyOrgRep(obj).then(result => {
      if (result['status'] == "Verified") {
        this.createdByRep = false;
        this.verified = true;
        this.addorganization = this.personid;
      } else if (result['status'] == "Incorrect code") {
        //alert('Incorrect code, please try again!');
        this.service.sendAlert('Error', "Incorrect code, please try again!", 0);
        return;
      } else {
        //alert('Unknown error!');
        this.service.sendAlert('Error', "Unknown error!", 0);
        return;
      }
    });

  }

  notify() {
    if (!this.notify_email) {
      //alert('Please enter email.');
      this.service.sendAlert('Error', "Please enter email.", 0);
      return;
    }
    let body;
    if (this.notifySetting) {
      body = 'Hi, Someone has created a profile for your organization. Please claim if it belongs to your organization. Please goto following URL to visit the page: ' + this.service.url + '/personprofile/' + this.id;
    } else {
      body = 'Hi, ' + localStorage.getItem('first_name') + ' ' + localStorage.getItem('last_name') + ' has created a profile for your organization. Please goto following URL to visit the page: ' + this.service.url + '/personprofile/' + this.id;
    }

    let obj = {
      to: this.notify_email,
      body: body
    }
    this.service.notifyUser(obj).then(result => {
      let body = {
        "created_by_name": localStorage.getItem('first_name') + ' ' + localStorage.getItem('last_name')
      };
      let personId = this.id;
      this.service.doUserProfilePatch(personId, body).then(result => {
       // alert('Email has been sent.');
       this.service.sendAlert('Success', "Email has been sent.", 1);
      });
    });
  }

  // signup() {
  //   if (this.validate()) {
  //     this.service.doReg(this.user.first_name, this.user.last_name, this.user.email, this.user.password, this.user.password, this.user.email).then(res => {
  //       this.user.first_name = '';
  //       this.user.last_name = '';
  //       this.user.email = '';
  //       this.user.password = '';
  //       window.localStorage.setItem("first_name", this.user.first_name);
  //       window.localStorage.setItem("last_name", this.user.last_name);
  //       window.localStorage.setItem("username", this.user.email);
  //       //alert('An email has been sent to your email address with account activation instructions.');
  //       this.service.sendAlert('Success', "An email has been sent to your email address with account activation instructions.", 1);
  //       setTimeout(() => {
  //         // this.cookie.remove('sessionid');
  //       }, 1000);
  //     });
  //   }
  // }

  validate() {
    if (this.user.first_name.trim() == "") {
      //alert("Please provide your first name!");
      this.service.sendAlert('Error', "Please provide your first name!", 0);
      return false;
    }

    if (this.user.email == "") {
      //alert("Please provide your email!");
      this.service.sendAlert('Error', "Please provide your email!", 0);
      return false;
    } else {
      if (this.validateEmail(this.user.email)) {
        //alert("You have entered an invalid email address!");
        this.service.sendAlert('Error', "You have entered an invalid email address!", 0);
        return false;
      }
    }

    if (this.user.password == "") {
     // alert("Please provide your password!");
     this.service.sendAlert('Error', "Please provide your password!", 0);
      return false;
    }

    if (this.user.confirm_password == "") {
      //alert("Please provide your password again!");
      this.service.sendAlert('Error', "Please provide your password again!", 0);
      return false;
    }

    if (this.user.password != this.user.confirm_password) {
      //alert("Passwords didn't match!");
      this.service.sendAlert('Error', "Passwords didn't match!", 0);
      return false;
    }

    var elem = document.getElementById("pwd");

    var pattern = elem.getAttribute("pattern");
    var re = new RegExp(pattern);
    if (!re.test(elem['value'])) {
      // Pattern does NOT match.
      //alert('Please revise password to meet complexity requirements!');
      this.service.sendAlert('Error', "Please revise password to meet complexity requirements!", 0);
      return false;
    }

    elem = document.getElementById("pwd1");

    pattern = elem.getAttribute("pattern");
    re = new RegExp(pattern);
    if (!re.test(elem['value'])) {
      // Pattern does NOT match.
      //alert('Please revise password to meet complexity requirements in confirm password field!');
      this.service.sendAlert('Error', "Please revise password to meet complexity requirements in confirm password field!", 0);
      return false;
    }


    return true;
  }

  login() {
    if (!this.loginObject.email.trim() || !this.loginObject.password.trim()) {
      return;
    }
    if (this.validateEmail(this.loginObject.email)) {
     // alert("You have entered an invalid email address!");
     this.service.sendAlert('Error', "You have entered an invalid email address!", 0);
      return false;
    }
    // this.loginClicked = true;
    //this.loading = true;
    this.service.doLogin(this.loginObject.email, this.loginObject.password).then(result => {
      if (result['token']) {
        // this.router.navigate(['']);
        this.loginStatus = true;
      } else {
        // this.loginClicked = false;
        //this.loading = false;
        let varx = "";

        _.forEach(<any>result, function (value, key) {

          varx = varx + value[0];

        });
        //alert(varx);
        this.service.sendAlert('Error',varx, 0);
      }
    });
  }

  validateEmail(email) {
    if (!/^[\w._-]+[+]?[\w._-]+@[\w.-]+\.[a-zA-Z]{2,6}$/.test(email)) {
      return true;
    }
  }
}