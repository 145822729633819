<div style="height: 100%;">
    <div class="org-flex">
        <div>
            <!-- <img src="../../assets/new-assets/testimonials/{{orgCardData?.image}}.png" alt=""> -->
            <img src="{{orgCardData?.image ? orgCardData?.image : 'assets/new-assets/default/default.png'}}" alt="">
            <!-- <img src="assets/new-assets/default/default.png" alt=""> -->
        </div>
        <div class="data-div">
            <h1 class="org-name">{{orgCardData?.name}}</h1>
        </div>
        
        <button (click)="goToOrg(orgCardData?.slug)" class="org-button"  rp-button size="medium" type="accent">View Profile</button>
    </div>
    
</div>