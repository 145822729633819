<navbar></navbar>
<div class="container">
    <h2 class="text-center">Reset Password</h2>
    <div class="col-sm-6 col-sm-offset-3">
        <div class="col-sm-12"> <input id="p1" class="form-control" type="password" placeholder="New Password*" [(ngModel)]="passwordObject.password" [ngModelOptions]="{standalone: true}" required pattern="(?=^.{6,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$" name="pwd1">
        </div>
        <div class="col-sm-12" style="margin-bottom: 10px;">
            <span style="font-size: 11px; font-style: italic;"> (Passwords must be 6 or more characters and include at least one uppercase letter, one lowercase letter, and one number or special character)</span>
        </div>
        <div class="col-sm-12" style="margin-bottom: 10px;"> <input id="p2" type="password" class="form-control" placeholder="Confirm Password*" [(ngModel)]="passwordObject.confirm_password" [ngModelOptions]="{standalone: true}" required pattern="(?=^.{6,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$"
                name="pwd2">
        </div>
        <div class="text-center col-sm-12"> <button class="btn btn-default" (click)="reset()">Reset</button> </div>
    </div>
</div>


<style>
    @font-face {
        font-family: OpenSans-Regular;
        src: url('/assets/fonts/new/opensans-regular-webfont.eot?#iefix') format('embedded-opentype'), /* IE */
        url('/assets/fonts/new/opensans-regular-webfont.ttf') format("truetype");
        /* non-IE */
    }
    
    @font-face {
        font-family: OpenSans-Light;
        src: url('/assets/fonts/new/opensans-light-webfont.eot?#iefix') format('embedded-opentype'), /* IE */
        url('/assets/fonts/new/opensans-light-webfont.ttf') format("truetype");
        /* non-IE */
    }
    
    @font-face {
        font-family: OpenSans-Bold;
        src: url('/assets/fonts/new/opensans-bold-webfont.eot?#iefix') format('embedded-opentype'), /* IE */
        url('/assets/fonts/new/opensans-bold-webfont.ttf') format("truetype");
        /* non-IE */
    }
    
    @font-face {
        font-family: OpenSans-ExtraBold;
        src: url('/assets/fonts/new/opensans-extrabold-webfont.eot?#iefix') format('embedded-opentype'), /* IE */
        url('/assets/fonts/new/opensans-extrabold-webfont.ttf') format("truetype");
        /* non-IE */
    }
    
    @font-face {
        font-family: OpenSans-Semibold;
        src: url('/assets/fonts/new/opensans-semibold-webfont.eot?#iefix') format('embedded-opentype'), /* IE */
        url('/assets/fonts/new/opensans-semibold-webfont.ttf') format("truetype");
        /* non-IE */
    }
    
    * {
        font-family: OpenSans-Regular;
    }
    
    .container {
        margin-top: 50px;
    }
    
    .padding-bottom {
        padding-bottom: 15px;
    }
    
    .btn {
        background-color: #ffe27e;
    }
    
    .block a {
        cursor: pointer;
    }
    
    .glyphicon-refresh-animate {
        -animation: spin .7s infinite linear;
        -webkit-animation: spin2 .7s infinite linear;
    }
    
    @-webkit-keyframes spin2 {
        from {
            -webkit-transform: rotate(0deg);
        }
        to {
            -webkit-transform: rotate(360deg);
        }
    }
    
    @keyframes spin {
        from {
            transform: scale(1) rotate(0deg);
        }
        to {
            transform: scale(1) rotate(360deg);
        }
    }
</style>