import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RpButtonsDirective } from '../directives/button-directive/rp-buttons.directive';
import { FilterChipsDirective } from '../../new-repicture/directives/filter-chips/filter-chips.directive';
import { InputDirective } from '../directives/input-directive/input.directive';



@NgModule({
  declarations: [RpButtonsDirective,FilterChipsDirective, InputDirective],
  imports: [
    CommonModule
  ],
  exports: [RpButtonsDirective,FilterChipsDirective,InputDirective],
})
export class ButtonsModule { }
 