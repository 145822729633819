<nav class="navbar navbar-default">
    <div class="header-main">
        <!-- Brand and toggle get grouped for better mobile display -->
        <div class="navbar-header" style="color: #5f5e5e; min-width: 320px">
            <button type="button" class="navbar-toggle collapsed" data-toggle="collapse"
                data-target="#bs-example-navbar-collapse-1" aria-expanded="false" (click)="dd()">
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </button>
            <a routerLinkActive="active" routerLink="" (click)="sendEvt('logo')" class="navbar-brand"><img
                    src="assets/new-assets/repicture/rpLogo.svg" style="width: 220px" /></a>
        </div>
        <!-- Collect the nav links, forms, and other content for toggling -->
        <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
            <ul class="nav navbar-nav" style="display: flex; align-items: center; height: auto;flex-wrap: wrap;">
                <li>
                    <a routerLinkActive="active" routerLink="" (click)="sendEvt('home')" class="scroll">Home</a>
                </li>

                <li role="presentation" class="dropdown">
                    <a class="dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true"
                        aria-expanded="false">
                        Explore <span class="caret"></span>
                    </a>
                    <ul class="dropdown-menu" style="min-width: inherit !important">
                        <li>
                            <a routerLink="/explorer/projects" routerLinkActive="active"
                                (click)="sendEvt('projects')">Projects</a>
                        </li>
                        <li>
                            <a routerLink="/explorer/people" routerLinkActive="active"
                                (click)="sendEvt('people')">People</a>
                        </li>
                        <li>
                            <a routerLink="/explorer/org" routerLinkActive="active"
                                (click)="sendEvt('org')">Organizations</a>
                        </li>
                        <!-- <li>
                            <a routerLinkActive="active" routerLink="/lessonplans"
                                (click)="sendEvt('lesson plans')">Lesson Plans</a>
                        </li>
                        <li>
                            <a routerLink="/jobs" routerLinkActive="active" (click)="sendEvt('careers')">Careers</a>
                        </li>
                        <li>
                            <a routerLink="/chat" routerLinkActive="active" (click)="sendEvt('group chats')">Connect</a>
                        </li> -->
                    </ul>
                </li>

                <li>
                    <a [routerLink]="['/accelerator']" routerLinkActive="active" (click)="sendEvt('jobs')"
                        class="scroll">Career
                        Accelerator</a>
                </li>

                <li>
                     
                    
                    <a (click)="sendEvt('about')" data-toggle="dropdown" class="scroll dropdown-toggle" routerLinkActive="active">About
                        <span class="caret"></span></a>
                        <ul class="dropdown-menu" style="padding: 5px 0; border-radius: 4px">
                            <li style="padding: 0">
                                <a routerLink="/about" routerLinkActive="active" (click)="sendEvt('about us')">About us</a>
                            </li>
                            <li style="padding: 0">
                                <a (click)="goToUrl('/about','au-whoweserve')" routerLinkActive="active" (click)="sendEvt('about us')">Who We Serve</a>
                            </li>
                            <li style="padding: 0">
                                <a (click)="goToUrl('/about','au-testimonials')" routerLinkActive="active" (click)="sendEvt('about us')">Testimonials</a>
                            </li>
                            
                            <li style="padding: 0">
                                <a  (click)="goToUrl('/about','au-team')">Team</a>
                            </li>
                            <li style="padding: 0">
                                <a  routerLink="/about/partners" routerLinkActive="active">Partners</a>
                            </li> 
                            <li style="padding: 0">
                                <a (click)="goToUrl('/about','au-journey')" routerLinkActive="active" (click)="sendEvt('intro students')">Journey & story</a>
                            </li>
                            <li style="padding: 0">
                                <a routerLink="/about/news" routerLinkActive="active"
                                    (click)="sendEvt('intro professionals')">News & Publications</a>
                            </li>
                            <li style="padding: 0">
                                <a routerLink="/about/core-values" routerLinkActive="active"
                                    (click)="sendEvt('intro professionals')">Core Values</a>
                            </li>
                            
                        </ul>
                    <!-- <ul class="dropdown-menu" style="padding: 5px 0; border-radius: 4px">
                        <li style="padding: 0">
                            <a routerLink="/about" routerLinkActive="active" (click)="sendEvt('about us')">About us</a>
                        </li>
                        <li style="padding: 0">
                            <a routerLink="/accelerator" routerLinkActive="active" (click)="sendEvt('intro students')">For
                                Students</a>
                        </li>
                        <li style="padding: 0">
                            <a routerLink="/professionals" routerLinkActive="active"
                                (click)="sendEvt('intro professionals')">For Professionals</a>
                        </li>
                        <li style="padding: 0">
                            <a routerLink="/educators" routerLinkActive="active"
                                (click)="sendEvt('intro educators')">For Educators</a>
                        </li>
                        <li style="padding: 0">
                            <a routerLink="/colleges" routerLinkActive="active" (click)="sendEvt('intro colleges')">For
                                Colleges</a>
                        </li>
                        <li style="padding: 0">
                            <a routerLink="/jobseekers" routerLinkActive="active"
                                (click)="sendEvt('intro jobseekers')">For Job Seekers</a>
                        </li>
                        <li style="padding: 0">
                            <a routerLink="/employers" routerLinkActive="active"
                                (click)="sendEvt('intro employers')">For Employers</a>
                        </li>
                    </ul> -->
                </li>
                <li class="loginlogout" style="padding: 15px" *ngIf="!loginstatus">
                    <a routerLink="/login" routerLinkActive="active" class="ac"
                        (click)="sendEvt('login')">Login</a><span> | </span><a routerLink="/createaccount"
                        routerLinkActive="active" (click)="sendEvt('join')" class="scroll ac">Join</a>
                </li>
                <li *ngIf="loginstatus">
                    <a class="navbarPic dropdown-toggle" style="padding-top: 0px !important" data-toggle="dropdown"><img
                            class="img-grid" src="{{imagesrc}}" /></a>
                    <ul class="dropdown-menu" style="padding: 5px 0; margin-left: -80px; border-radius: 4px">
                        <li style="padding: 0">
                            <a routerLinkActive="active" routerLink="/userprofile/{{profileURL}}"
                                (click)="sendEvt('view profile')">View Profile</a>
                        </li>
                        <li *ngIf="company_id" style="padding: 0">
                            <a routerLinkActive="active" routerLink="/companyprofile/{{company_id}}"
                                (click)="sendEvt('organization page')">Organization Page</a>
                        </li>
                        <li style="padding: 0">
                            <a routerLinkActive="active" routerLink="/manageprojects"
                                (click)="sendEvt('my projects')">My Projects</a>
                        </li>
                        <li style="padding: 0">
                            <a routerLinkActive="active" routerLink="/account" (click)="sendEvt('account')">Account</a>
                        </li>
                        <li style="padding: 0">
                            <a (click)="logout()" (click)="sendEvt('logout')">Logout</a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</nav>