<!-- <div class="footer-new">
    <div class="container">
        <div class="row">
            <div class="col-md-4 first-section">
                <p><a [routerLink]="['']" routerLinkActive="router-link-active">Home</a></p>
                <p style="margin-bottom: 5px;">Explorer</p>
                <ul>
                    <li><a [routerLink]="['/explorer/projects']" routerLinkActive="router-link-active">Projects</a></li>
                    <li><a [routerLink]="['/explorer/people']" routerLinkActive="router-link-active">People</a></li>
                    <li><a [routerLink]="['/explorer/org']" routerLinkActive="router-link-active">Organizations</a></li>
                    <li><a [routerLink]="['/lessonplans']" routerLinkActive="router-link-active">Lesson Plans</a></li>
                    <li><a [routerLink]="['/jobs']" routerLinkActive="router-link-active">Careers</a></li>
                </ul>
                <p><a [routerLink]="['/jobs']" routerLinkActive="router-link-active">Jobs</a></p>
                <p style="margin-bottom: 5px;">About</p>
                <ul>
                    <li><a [routerLink]="['/about']" routerLinkActive="router-link-active">About Us</a></li>
                    <li><a [routerLink]="['/chat']" routerLinkActive="router-link-active">Group Chats</a></li>
                </ul>
                <p *ngIf="showJoin"><a [routerLink]="['/login']" routerLinkActive="router-link-active">Login</a>/<a [routerLink]="['/createaccount']" routerLinkActive="router-link-active">Join</a></p>
                <div class="social">
                    <a target="_blank" href="https://twitter.com/_RePicture">
                        <img src="assets/images/homepagenew/SocialMediaIcon-Twitter.png" />
                    </a>
                    <a target="_blank" href="https://www.facebook.com/RePictureYourCareer/">
                        <img src="assets/images/homepagenew/SocialMediaIcon-Facebook.png" />
                    </a>
                    <a target="_blank" href="https://www.youtube.com/channel/UC5QYVuq9CDm9TyOaZbl9_PQ">
                        <img src="assets/images/homepagenew/SocialMediaIcon-YouTube.png" />
                    </a>
                    <a target="_blank" href="https://www.instagram.com/repictureyourcareer/">
                        <img src="assets/images/homepagenew/SocialMediaIcon-Instagram.png" />
                    </a>
                    <a target="_blank" href="https://www.linkedin.com/company/repicture">
                        <img src="assets/images/homepagenew/linkedin.png"/>
                    </a>
                </div>
            </div>
            <div class="col-md-4 second-section">
                <p class="second-section-heading">Send Us A Message</p>
                <div class="form">
                    <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                        <div class="form-elem">
                            <p>First Name</p>
                            <input type="text" formControlName="firstname" [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }" />
                            <div *ngIf="submitted && f.firstname.errors" class="invalid-feedback">
                                <div *ngIf="f.firstname.errors.required">First name is required</div>
                            </div>
                        </div>
                        <div class="form-elem">
                            <p>Last Name</p>
                            <input type="text" formControlName="lastname" [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }" />
                            <div *ngIf="submitted && f.lastname.errors" class="invalid-feedback">
                                <div *ngIf="f.lastname.errors.required">Last name is required</div>
                            </div>
                        </div>
                        <div class="form-elem">
                            <p>Email Address</p>
                            <input type="text" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                <div *ngIf="f.email.errors.required">Email is required</div>
                                <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                            </div>
                        </div>
                        <div class="form-elem">
                            <p>Message</p>
                            <textarea formControlName="message" [ngClass]="{ 'is-invalid': submitted && f.message.errors }"></textarea>
                            <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
                                <div *ngIf="f.message.errors.required">Message is required</div>
                            </div>
                        </div>
                        <button>Submit</button>
                    </form>
                </div>
            </div>
            <div class="col-md-4 third-section">
                <div class="logo">
                    <img src="assets/images/homepagenew/RePicture-White.png" />
                </div>
                <p class="post2">
                    RePicture.com was created by RePicture Engineering, PBC, a Public Benefit Corporation. The site provides career advice for science, technology, engineering and math (STEM) careers. With RePicture you can discover careers shaping the future, learn about
                    engineering projects, meet STEM role models, find companies with engineering job openings, and become part of a unique STEM community.
                </p>
                <button *ngIf="showJoin" [routerLink]="['/createaccount']" routerLinkActive="router-link-active">Join Now</button>
                <div class="contactDiv">
                    <p class="contact">
                        EMAIL: info@repicture.com
                    </p>
                    
                </div>
                <p class="rights-txt">Copyright All Rights Reserved © {{currentYear}}.</p>
                <p class="terms-txt">
                    <a style="color: #ECEBE9; cursor: pointer; text-decoration: underline;" href="assets/pdfs/Website Terms of Use_Dec 12 2020.pdf" target="_blank">Privacy and Terms</a> 
      
                </p>
            </div>
        </div>
    </div>
</div>
<div id="agreementModal" class="modal fade" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content" style="border-radius: 0px;">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <h4 class="modal-title">Agreement</h4>
            </div>
            <div class="modal-body" [innerHTML]="data">
            </div>
            <div class="modal-footer">
                <button class="btn btn-default" data-dismiss="modal">OK</button>
            </div>
        </div>
    </div>
</div> -->


<div>
    <div class="footer-main">
        <div class="footer-logoAndText">
            <img src="assets/new-assets/repicture/rpLogo.svg" alt="">
            <div class="footer-subTitle">RePicture is a Science, Technology, Engineering, and Mathematics (STEM) career accelerator platform with a mission to increase interest and diversity in STEM, and a focus on historically underrepresented groups.  Our workshops, lesson plans, and Career Accelerator help all students and professionals succeed in their dream STEM career and make a world of difference.</div>
        </div>
        <div class="footer-divs">
            <h1 class="footer-title">REPICTURE FOR</h1>
            <div>
                <div routerLink="/{{item.link}}" class="footer-subTitle" *ngFor="let item of footerInfoDivOne">
                    {{item.title}}
                </div>
            </div>    
        </div>
        <div class="footer-divs">
            <h1 class="footer-title">EXPLORE</h1>
            <div>
                <div routerLink="/{{item.link}}" class="footer-subTitle" *ngFor="let item of footerInfoDivTwo">
                    {{item.title}}
                </div>
            </div>    
        </div>
        <div class="footer-divs">
            <h1 class="footer-title">ABOUT</h1>
            <div>
                <div  routerLink="/about" class="footer-subTitle">
                    About Us
                </div>
                <div >
                    <a class="footer-subTitle" target="_blank" href="assets/pdfs/tou2020.pdf">Privacy and Terms</a> 
                </div>
                <div class="footer-subTitle" >
                    ©RePicture Engineering, PBC, 2022
                </div>
            </div>    
        </div>
        <div class="footer-divs">
            <h1 class="footer-title">CONTACT</h1>
            <div>
                <div class="footer-subTitle" *ngFor="let item of footerInfoDivFour">
                    {{item.title}}
                </div>
                <div class="social-div">
                    <div (click)="goToUrls(i)" class="social-icons" *ngFor="let item of social;let i = index">
                        <img src="assets/new-assets/icons/{{item}}.png" alt="">
                    </div>
                </div>
                
            </div>    
        </div>
        
    </div>
    <div>

    </div>
</div>