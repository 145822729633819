import { Router, ActivatedRoute, Route } from "@angular/router";
import { Ng2AutoCompleteModule } from "ng2-auto-complete";

import {
  Component,
  NgZone,
  EventEmitter,
  Input,
  Output,
  ChangeDetectorRef,
  Inject,
  PLATFORM_ID,
  OnChanges,
} from "@angular/core";

import { Http, Response, Headers, URLSearchParams } from "@angular/http";

import { ProjectService } from "./../projectservice";

import { IMultiSelectOption } from "angular-2-dropdown-multiselect";

import * as _ from "lodash";

import { CookieService } from "angular2-cookie/core";

import { Title } from "@angular/platform-browser";
import { isPlatformBrowser } from "@angular/common";
import { FormControl, Validators } from "@angular/forms";

@Component({
  selector: "regForm",
  templateUrl: "./register.html",
  styleUrls: ["./register.scss"],
})
export class RegisterForm implements OnChanges {

  email = new FormControl('',Validators.email);
  constructor(
    public service: ProjectService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platform: any
  ) {
    if (localStorage.getItem("peopleid")) {
      this.router.navigate([""]);
      return;
    }
  }
  insti_name: any;
  user: any = {};
  terms: boolean = false;
  agelimit: boolean = false;
  @Input() forStudents: boolean;
  teachingAssistant = [];
  data: any =
    'RePicture Engineering will not sell your personal data to third parties. By clicking the "Join Now" button below I am consenting to use of my personal data for RePicture Engineering\'s internal business purposes.  I verify that I am not a citizen of any jurisdiction (such as the European Union) that imposes regulations on data processors or data controllers that are more stringent than those imposed by the United States government';

  ngOnInit() {
    if (isPlatformBrowser(this.platform)) {
      setTimeout(() => {
        document.getElementById("pwd").setAttribute("autocomplete", "off");
        document.getElementById("pwd1").setAttribute("autocomplete", "off");
      }, 1000);
    }
  }

  ngOnChanges(): void {
    if (this.forStudents) {
      this.user.teaching_assistant = "";
      //get TA
      this.route.params.subscribe((params) => {
        console.log(params["collegename"]);
        //get TA by collegename
        this.service.getTeachers(params["collegename"]).then((res) => {
          console.log(res["results"]);
          this.teachingAssistant = res["results"];
        });
      });
    }
  }

  ta_changed(evt) {
    console.log(evt);
    if (evt.target.selectedIndex > 0) {
      this.insti_name =
        this.teachingAssistant[evt.target.selectedIndex - 1].insti_name;
      console.log(this.insti_name);
    }
  }

  signup() {
    if (this.forStudents) {
      if (!this.user.teaching_assistant) {
        this.service.sendAlert(
          "Error",
          "As part of the RePicture Educational Program, you must select a Teaching Assistant. If you do not know the name of your Teaching Assistant, please contact your professor. If you are not part of the RePicture Education Program, please join using https://www.repicture.com/createaccount",
          0
        );
        return;
      }
    }
    if (this.validate()) {
      this.service
        .doReg(
          this.user.first_name,
          this.user.last_name,
          this.user.email.toLowerCase(),
          this.user.password,
          this.user.password,
          this.user.email.toLowerCase(),
          this.agelimit,
          this.forStudents,
          this.user.teaching_assistant,
          this.insti_name
        )
        .then((res) => {
          this.user.first_name = "";
          this.user.last_name = "";
          this.user.email = "";
          this.user.password = "";
          this.user.confirm_password = "";
          localStorage.setItem("first_name", this.user.first_name);
          localStorage.setItem("last_name", this.user.last_name);
          localStorage.setItem("username", this.user.email);
          console.log("email pop uop");
          // alert('An email has been sent to your email address with account activation instructions.');
          this.service.sendAlert(
            "Success",
            "An email has been sent to your email address with account activation instructions.",
            1
          );
          setTimeout(() => {
            // this.cookie.remove('sessionid');
            this.router.navigate([''])
          }, 1000);
        });
    }
  }

  validate() {
    if (this.service.isNullOrWhitespace(this.user.first_name)) {
      this.service.sendAlert("Error", "Please provide your first name!", 0);
      return false;
    }
    if (this.service.isNullOrWhitespace(this.user.last_name)) {
      this.service.sendAlert("Error", "Please provide your last name!", 0);
      return false;
    }

    if (this.service.isNullOrWhitespace(this.user.email)) {
      // alert("Please provide your email!");
      this.service.sendAlert("Error", "Please provide your email!", 0);
      return false;
    } else {
      if (this.validateEmail(this.user.email)) {
        this.service.sendAlert(
          "Error",
          "You have entered an invalid email address!",
          0
        );
        return false;
      }
    }

    if (this.service.isNullOrWhitespace(this.user.password)) {
      // alert("Please provide your password!");
      this.service.sendAlert("Error", "Please provide your password!", 0);
      return false;
    }

    if (this.service.isNullOrWhitespace(this.user.confirm_password)) {
      this.service.sendAlert("Error", "Please provide your password again!", 0);

      return false;
    }

    if (this.user.password != this.user.confirm_password) {
      //alert("Passwords didn't match!");
      this.service.sendAlert("Error", "Passwords didn't match!", 0);
      return false;
    }
    if (!this.user.tac) {
      //alert("Passwords didn't match!");
      this.service.sendAlert("Error", "Please verify the terms and conditions!", 0);
      return false;
    }

    var elem = document.getElementById("pwd");

    var pattern = elem.getAttribute("pattern");
    var re = new RegExp(pattern);
    if (!re.test(elem["value"])) {
      // Pattern does NOT match.
      // alert('Please revise password to meet complexity requirements in password field!');
      this.service.sendAlert(
        "Error",
        "Please revise password to meet complexity requirements in password field!",
        0
      );
      return false;
    }

    elem = document.getElementById("pwd1");

    pattern = elem.getAttribute("pattern");
    re = new RegExp(pattern);
    if (!re.test(elem["value"])) {
      // Pattern does NOT match.
      //alert('Please revise password to meet complexity requirements in confirm password field!');
      this.service.sendAlert(
        "Error",
        "Please revise password to meet complexity requirements in confirm password field!",
        0
      );
      return false;
    }

    return true;
  }

  validateEmail(email) {
    // if (!/^[\w._-]+[+]?[\w._-]+@[\w.-]+\.[a-zA-Z]{2,6}$/.test(email)) {
    //   return true;
    // }
    const regex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i; // the "i" at the end makes the regex case-insensitive
    const isValidEmail = regex.test(email);
    if (email != null && !isValidEmail) {
      return true
    }
    return false
  }

  linkedinLogin() {
    window.open(
      "https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=" +
        this.service.linkedinObject.client_id +
        "&redirect_uri=" +
        this.service.linkedinObject.redirectURI +
        "&state=c7RFX4w8PNiLA2Yxq1mq8ASyZocyKW&scope=" +
        this.service.linkedinObject.scope,
      "_self"
    );
  }
}
