/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./career-job-listing.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../navbar/navbar.component.ngfactory";
import * as i3 from "../../navbar/navbar.component";
import * as i4 from "../../new-repicture/auth/auth.service";
import * as i5 from "../../projectservice";
import * as i6 from "@angular/router";
import * as i7 from "angular2-cookie/services/cookies.service";
import * as i8 from "@angular/platform-browser";
import * as i9 from "../../../../node_modules/ng2-pdfjs-viewer/ng2-pdfjs-viewer.ngfactory";
import * as i10 from "ng2-pdfjs-viewer";
import * as i11 from "./career-job-listing.component";
import * as i12 from "@angular/common/http";
var styles_CareerJobListingComponent = [i0.styles];
var RenderType_CareerJobListingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CareerJobListingComponent, data: {} });
export { RenderType_CareerJobListingComponent as RenderType_CareerJobListingComponent };
export function View_CareerJobListingComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { pdfViewerAutoLoad: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 1, "navbar", [], null, null, null, i2.View_NavbarComponent_0, i2.RenderType_NavbarComponent)), i1.ɵdid(2, 4833280, null, 0, i3.NavbarComponent, [i4.AuthService, i5.ProjectService, i6.Router, i7.CookieService, i1.ChangeDetectorRef, i8.Title, i1.PLATFORM_ID], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "ng2-pdfjs-viewer", [["zoom", "auto"]], null, null, null, i9.View_PdfJsViewerComponent_0, i9.RenderType_PdfJsViewerComponent)), i1.ɵdid(5, 114688, [[1, 4], ["pdfViewerAutoLoad", 4]], 0, i10.PdfJsViewerComponent, [], { zoom: [0, "zoom"] }, null)], function (_ck, _v) { _ck(_v, 2, 0); var currVal_0 = "auto"; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_CareerJobListingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-career-job-listing", [], null, null, null, View_CareerJobListingComponent_0, RenderType_CareerJobListingComponent)), i1.ɵdid(1, 114688, null, 0, i11.CareerJobListingComponent, [i6.ActivatedRoute, i5.ProjectService, i8.DomSanitizer, i8.Meta, i8.Title, i12.HttpClient], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CareerJobListingComponentNgFactory = i1.ɵccf("app-career-job-listing", i11.CareerJobListingComponent, View_CareerJobListingComponent_Host_0, {}, {}, []);
export { CareerJobListingComponentNgFactory as CareerJobListingComponentNgFactory };
