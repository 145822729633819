<p *ngIf="mx.fieldname != 'q7_ans' && mx.fieldname != 'q6_ans' && mx.fieldname != 'career_status' && mx.fieldname != 'professional_certification' && mx.fieldname != 'technical_expertise' && mx.fieldname != 'user_awards' && mx.fieldname != 'user_quotes' && mx.fieldname != 'external_links'"
    style="font-family: OpenSans-Light; font-style: italic; color:black;">Maximum 1500 characters</p>
<p *ngIf="mx.fieldname == 'q6_ans'" style="font-family: OpenSans-Light; font-style: italic; color:black;">Maximum 3000 characters</p>
<p *ngIf="mx.fieldname == 'user_quotes'" style="font-family: OpenSans-Light; font-style: italic; color:black;">Maximum 300 characters</p>
<textarea autosize *ngIf="mx.fieldname == 'user_quotes'" id="{{mx.fieldname}}" placeholder="Enter information" maxlength="300" [ngModel]="service.removeLineBreaks(mx.data)"></textarea>
<textarea autosize *ngIf="mx.fieldname == 'q6_ans'" id="{{mx.fieldname}}" placeholder="Enter information" maxlength="3000" [ngModel]="service.removeLineBreaks(mx.data)"></textarea>
<!-- <p *ngIf="mx.fieldname == 'q6_ans' || mx.fieldname == 'about' || mx.fieldname == 'q8_ans' || mx.fieldname == 'q9_ans'" style="font-family: OpenSans-Light; font-style: italic; color:black;">Be as lengthy as you'd like.</p> -->
<!-- <p *ngIf="mx.fieldname == 'q7_ans'" style="font-family: OpenSans-Light; font-style: italic; color:black;">Enter </p> -->
<textarea autosize *ngIf="mx.fieldname != 'q7_ans' && mx.fieldname != 'q6_ans' && mx.fieldname != 'career_status' && mx.fieldname != 'professional_certification' && mx.fieldname != 'technical_expertise' && mx.fieldname != 'user_awards' && mx.fieldname != 'user_quotes' && mx.fieldname != 'external_links'"
    placeholder="Enter information" id="{{mx.fieldname}}" maxlength="1500" [ngModel]="service.removeLineBreaks(mx.data)"></textarea>
<p *ngIf="mx.fieldname == 'q7_ans'">
    <span *ngFor="let item of mx.data; let i = index" style="background-color: #ffe284; padding: 5px 10px; color: black;margin: 2px;" class="badge badge-dark">{{item}} <i class="glyphicon glyphicon-remove" (click)="removeHobbies(i);" ></i></span>
</p>
<textarea autosize *ngIf="mx.fieldname == 'q7_ans'" id="q7_ans" [(ngModel)]="tag" placeholder="Enter comma separated hobbies & interests"></textarea>
<!-- <i *ngIf="mx.fieldname != 'q7_ans' && mx.fieldname != 'career_status'" class="glyphicon glyphicon-check dsc-glyp" (click)="onEnterDesc()"></i> -->
<!-- <i *ngIf="mx.fieldname == 'q7_ans'" class="glyphicon glyphicon-check dsc-glyp" (click)="addTag(////)"></i> -->

<div *ngIf="mx.fieldname == 'career_status'">
    <!-- <my-dropdown [defaultText]="'Select career status'" [myOptions]="careerStatus" [selectedOptions]="selectedCareerStatus" (selectedArray)="onChangedd($event)" style="display: inline-block"></my-dropdown> -->
    <div class="flex-column">
        <p *ngFor="let item of careerStatus" class="col-md-6">
            <label class="checkbox-inline checkboxes" [class.disabled]="item.isDisabled">
                <input type="checkbox" [(ngModel)]="item.isLabel" [disabled]="item.isDisabled" (change)="onConditionCheck(item)" />{{item.id}}
            </label>
        </p>
    </div>

    <!-- <i class="glyphicon glyphicon-check dsc-glyp" (click)="updateCareerStatus()"></i> -->
</div>
<div *ngIf="mx.fieldname == 'professional_certification'">
    <!-- <div *ngFor="let item of collectionCertificates; let i = index" class="water-res">
        <span class="certif1" *ngFor="let item1 of item.certifications">{{ item1 }} </span>
        <span *ngIf="item.location"> - {{item.location}}</span>
        <span class="glyphicon glyphicon-minus-sign" (click)="removeCertificate(i)"></span>
    </div> -->
    <div>
        <ss-multiselect-dropdown [options]="certifications" [settings]="mySettings" [(ngModel)]="selected_certifications1" (ngModelChange)="certificationChange($event)" [texts]="myTexts"></ss-multiselect-dropdown>
        <!-- <my-dropdown [defaultText]="'Certifications'" [selectedOptions]="selected_certifications1" [myOptions]="certifications" (selectedArray)="certificationChange($event)"></my-dropdown> -->
        <!-- <p *ngFor="let item of certifications">
            <label class="checkbox-inline">
                <input type="checkbox" [checked]="hasProfCert(item.id)" (change)="certificationChange(item.id, $event.target.checked)" />{{item.id}}
            </label>
        </p> -->
    </div>
    <input *ngIf="is_prof_certi_other" [(ngModel)]="prof_certi_other" (blur)="emitOtherCert()" type="text" class="form-control txt-cert" style="display:block;" placeholder="Enter other certification" />
    <!-- <input [(ngModel)]="certi_location" type="text" class="form-control txt-cert" placeholder="Enter state(s)/location(s) where applicable" /> -->
    <!-- <i class="glyphicon glyphicon-ok" (click)="addCertification()"></i> -->
</div>
<div *ngIf="mx.fieldname == 'technical_expertise'">
    <input id="technical_expertise" [(ngModel)]="mx.data" type="text" class="form-control txt-cert" placeholder="Enter your technical expertise" />
</div>
<div *ngIf="mx.fieldname == 'user_awards'">
    <div *ngIf="collectionUserAwards.length > 0" style="margin-bottom: 5px;">
        <p class="awards" *ngFor="let item of collectionUserAwards; let i = index;">
            <a target="_blank" href="{{ item.award_url }}" style="color:rgba(26, 73, 109, 255);font-family: open-regular;font-size:14px; text-decoration: none;">{{item.award_name}}</a>
            <i class="glyphicon glyphicon-minus-sign" (click)="removeUserAwards(i)" style="color: black"></i>
        </p>
    </div>
    <p><a *ngIf="!addmoreCliked && collectionUserAwards?.length > 0" (click)="addmoreCliked = true" style="cursor: pointer">Add more</a></p>
    <div *ngIf="collectionUserAwards?.length == 0 || addmoreCliked">
        <input id="user_award_id" [(ngModel)]="user_award_name_mystory" type="text" class="form-control txt-data1" placeholder="Enter award name" /><br>
        <input id="user_award_url_id" [(ngModel)]="user_award_url" type="text" class="form-control txt-data1" style="margin-top: 5px;" placeholder="Enter link to award announcement" />
        <i class="glyphicon glyphicon-ok" (click)="addUserAward()"></i>
    </div>

</div>
<div *ngIf="mx.fieldname == 'external_links'">
    <div *ngIf="collectionExternalLinks.length > 0" style="margin-bottom: 5px;">
        <p class="awards" *ngFor="let item of collectionExternalLinks; let i = index;">
            <a target="_blank" href="{{ item.link_url }}" style="color:rgba(26, 73, 109, 255);font-family: open-regular;font-size:14px; text-decoration: none;">{{item.link_title}}</a>
            <i class="glyphicon glyphicon-minus-sign" (click)="removeExternalLinks(i)" style="color: black"></i>
        </p>
    </div>
    <p><a *ngIf="!addmoreCliked && collectionExternalLinks?.length > 0" (click)="addmoreCliked = true" style="cursor: pointer">Add more</a></p>
    <div *ngIf="collectionExternalLinks?.length == 0 || addmoreCliked">
        <input id="link_title_id" [(ngModel)]="exLinks.link_title" type="text" class="form-control txt-data1" placeholder="Enter link title" /><br>
        <input id="link_url_id" [(ngModel)]="exLinks.link_url" type="text" class="form-control txt-data1" style="margin-top: 5px;" placeholder="Enter link url, eg http://website.com" />
        <i class="glyphicon glyphicon-ok" (click)="addExternalLinks()"></i>
    </div>

</div>