import {
  Component,
  NgZone,
  EventEmitter,
  Input,
  Output
} from '@angular/core';





import { IMultiSelectOption } from 'angular-2-dropdown-multiselect';

declare var google: any;

@Component({
  selector: 'my-dropdown',
  templateUrl: './multiselectdropdown.html',
  styleUrls: ['./dropdown.scss']
})
export class MultiselectDropdown {


  myTexts: { checkAll: string; uncheckAll: string; checked: string; checkedPlural: string; searchPlaceholder: string; defaultTitle: any; };
  @Input() mySettings: IMultiSelectSettings;
  @Input() myOptions: IMultiSelectOption;
  @Input() defaultText: any;
  @Input() selectedOptions: number[];
  @Input() makeArrayEmpty: boolean;
  @Output() selectedArray = new EventEmitter<any>();

  ngOnInit() {
    //console.log(this.myOptions);
    this.mySettings = {
      pullRight: false,
      enableSearch: false,
      checkedStyle: 'glyphicon',
      buttonClasses: 'btn btn-default',
      selectionLimit: 0,
      closeOnSelect: false,
      autoUnselect: true,
      showCheckAll: true,
      showUncheckAll: true,
      dynamicTitleMaxItems: 1,
      maxHeight: '200px'
    };

    this.myTexts = {
      checkAll: 'Check all',
      uncheckAll: 'Uncheck all',
      checked: 'checked',
      checkedPlural: 'checked',
      searchPlaceholder: 'Search...',
      defaultTitle: this.defaultText
    }

  }

  // private selectedOptions: number[];
  //  private myOptions: IMultiSelectOption[] = [];

  onChangex($event) {
    // console.log($event,this.selectedOptions);
    this.selectedArray.emit(this.selectedOptions);
  }

  ngOnChanges() {
    if (this.makeArrayEmpty) {
      this.selectedOptions = [];
    }
  }
}


interface IMultiSelectSettings {
  pullRight?: boolean;
  enableSearch?: boolean;
  checkedStyle?: 'checkboxes' | 'glyphicon' | 'fontawsome';
  buttonClasses?: string;
  selectionLimit?: number;
  closeOnSelect?: boolean;
  autoUnselect?: boolean;
  showCheckAll?: boolean;
  showUncheckAll?: boolean;
  dynamicTitleMaxItems?: number;
  maxHeight?: string;
}

interface IMultiSelectTexts {
  checkAll?: string;
  uncheckAll?: string;
  checked?: string;
  checkedPlural?: string;
  searchPlaceholder?: string;
  defaultTitle?: string;
}

interface marker {
  lat: string;
  lng: string;
  label: string;
  name: string;
  type: string;
  subtype: string;
  status: string;
  draggable: boolean;
}

