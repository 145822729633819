<div class="profile-mini-main">
    <img *ngIf="!profileMiniData.co_owners" src="{{profileMiniData.image ? profileMiniData.image : 'assets/new-assets/default/default.png'}}" alt="">
    <div>
        <h1 *ngIf="profileMiniData.name" (click)="goToProfile(profileMiniData.slug,$event)"><p>By</p>{{profileMiniData.name}}</h1>
        <p *ngIf="profileMiniData.role">{{profileMiniData.role}}</p>
    </div>

    <div class="mulImages" *ngIf="profileMiniData.co_owners">
        <div *ngFor="let item of profileMiniData.co_owners;let i = index">
            <img *ngIf="i < 5" (click)="goToProfile(item.slug)" src="{{item.image ? item.image : 'assets/new-assets/default/default.png'}}" alt="">
        </div>
    </div>
</div>