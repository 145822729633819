import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, Title, Meta } from '@angular/platform-browser';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ProjectService } from '../../projectservice';

@Component({
  selector: 'app-teacher-student-guide',
  templateUrl: './teacher-student-guide.component.html',
  styleUrls: ['./teacher-student-guide.component.css']
})
export class TeacherStudentGuideComponent implements OnInit {
  @ViewChild('pdfViewerAutoLoad', { static: true }) pdfViewerAutoLoad;
  type: any;
  constructor(private route: ActivatedRoute, private sanitizer: DomSanitizer, private title: Title, private meta: Meta, private http: HttpClient, private service: ProjectService) { }

  ngOnInit() {
    let pdfSrc;
    this.route.params.subscribe(param => {
      this.type = param['type'];
      if (this.type === 'TeacherGuide') {
        this.title.setTitle('Lesson Plan for teaching about STEM careers' + ' | ' + this.service.project_name);
        this.meta.updateTag({ property: 'og:title', content: 'Lesson Plan for teaching about STEM careers' + ' | ' + this.service.project_name });
        this.meta.updateTag({ property: 'og:description', content: 'Free lesson plan for helping students discover engineering and science projects and careers.' });
        this.meta.updateTag({ name: 'description', content: 'Free lesson plan for helping students discover engineering and science projects and careers.' });
        pdfSrc = 'assets/pdfs/lessonplans/Teacher_Guide_RePicture_Aug_2021.pdf';
      } else if (this.type === 'StudentGuide') {
        this.title.setTitle('Student Guide for learning about STEM careers' + ' | ' + this.service.project_name);
        this.meta.updateTag({ property: 'og:title', content: 'Student Guide for learning about STEM careers' + ' | ' + this.service.project_name });
        this.meta.updateTag({ property: 'og:description', content: 'Free tool to help students discover engineeering and science careers.' });
        this.meta.updateTag({ name: 'description', content: 'Free tool to help students discover engineeering and science careers.' });
        pdfSrc = 'assets/pdfs/lessonplans/RePicture_Guide_for_Students.pdf';
      } else if (this.type === 'EngineeringBingo.pdf') {
        this.title.setTitle('Engineering Bingo: Free Engineering Lesson Plan' + ' | ' + this.service.project_name);
        this.meta.updateTag({ property: 'og:title', content: 'Engineering Bingo: Free Engineering Lesson Plan' + ' | ' + this.service.project_name });
        this.meta.updateTag({ property: 'og:description', content: 'Help students relate the engineering they are learning in class with the world around them with this engineering lesson plan.' });
        this.meta.updateTag({ name: 'description', content: 'Help students relate the engineering they are learning in class with the world around them with this engineering lesson plan.' });
        pdfSrc = 'assets/pdfs/lessonplans/1_Civil_Engineering_Bingo_Jan_23_2020.pdf';
      } else if (this.type === 'KeyPlayersOfSTEM.pdf') {
        this.title.setTitle('Key Players of STEM: Free Engineering and Science Lesson Plan' + ' | ' + this.service.project_name);
        this.meta.updateTag({ property: 'og:title', content: 'Key Players of STEM: Free Engineering and Science Lesson Plan' + ' | ' + this.service.project_name });
        this.meta.updateTag({ property: 'og:description', content: 'Help students learn about the engineering and science projects all around them and improve their research, presentation, and writing skills.' });
        this.meta.updateTag({ name: 'description', content: 'Help students learn about the engineering and science projects all around them and improve their research, presentation, and writing skills.' });
        pdfSrc = 'assets/pdfs/lessonplans/2_Key_Players_of_STEM_Jan_23_2020.pdf';
      } else if (this.type === 'FindYourPassion.pdf') {
        this.title.setTitle('Find Your Engineering Passon Free Lesson Plan' + ' | ' + this.service.project_name);
        this.meta.updateTag({ property: 'og:title', content: 'Find Your Engineering Passon Free Lesson Plan' + ' | ' + this.service.project_name });
        this.meta.updateTag({ property: 'og:description', content: 'Free Engineering Lesson Plan to help students discover different types of engineering careers and engineering projects that excite them.' });
        this.meta.updateTag({ name: 'description', content: 'Free Engineering Lesson Plan to help students discover different types of engineering careers and engineering projects that excite them.' });
        pdfSrc = 'assets/pdfs/lessonplans/3_Find_Your_Passion_Jan_17_2020.pdf';
      } else if (this.type === 'DesignProcess.pdf') {
        pdfSrc = 'assets/pdfs/lessonplans/4_Design_Process_to_increase_Girls_interest_Jan_17_2020.pdf';
      } else if (this.type === 'MeetAnEngineer.pdf') {
        pdfSrc = 'assets/pdfs/lessonplans/5_Meet_a_Engineer_or_Scientist_Feb_20_2020.pdf';
      } else if (this.type === 'AISC.pdf') {
        pdfSrc = 'assets/pdfs/lessonplans/AISC2021.pdf';
      } else if (this.type === 'Geotech.pdf') {
        pdfSrc = 'assets/pdfs/lessonplans/Explore Geotechnical Engineering Careers Lesson Plan.pdf';
      }
    });
    this.downloadFile(pdfSrc).subscribe(
      (res) => {
        this.pdfViewerAutoLoad.pdfSrc = res; // pdfSrc can be Blob or Uint8Array
        this.pdfViewerAutoLoad.refresh(); // Ask pdf viewer to load/refresh pdf
      }
    );
  }

  downloadFile(url: string): any {
    return this.http.get(url, { responseType: 'blob' })
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }
}
