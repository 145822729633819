import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs-compat/BehaviorSubject";
import { environment } from "src/environments/environment";
import { Testimonial } from "../testimonial-card/testimonial";

@Injectable({
  providedIn: "root",
})
export class AcceleratorService {
  
  private url: string = environment.baseurl;
  private checkoutApi = "/api-v1/postings/students/";
  private couponsApi = "/api-v1/postings/couponsv1/";
  paySuccess = new BehaviorSubject<boolean>(false);

  testimonials: Testimonial[] = [
    {
      image:'noran',
      slug:'noran-shahin_1',
      name: "Noran",
      year: "2020",
      major: "Engineering Student",
      desc: `Before the Repicture Program, I didn't really know where I was going to go after college... After participating in the Repicture Program… I was able to find a career path that aligned with my interests and that I could really see myself doing and enjoying.`,
      reverse: false,
    },
    {
      image:'taiseer',
      slug:'taiseer-al-salihi_1',
      name: "Taiseer",
      year: "2020",
      major: "Engineering Grad Student",
      desc: `One of the factors that helped me land my first research assistant job at UNC was one of the projects I submitted for the RePicture-AISC award. My advisor asked me for a writing sample and I shared with him a link to the RePicture project. He was impressed by the way it was written and I got the job. Now I get to work on a project that I’m very excited about.`,
      reverse: true
    },
    {
      image:'chalisa',
      slug:'chalisa-pusitdhikul',
      name: "Chalisa",
      year: "2020",
      major: "High School Student",
      desc: `The RePicture summer program is the best summer program I’ve attended! The support I got from the RePicture team was also very welcoming. The immense STEM knowledge I gained this summer was far beyond what I expected.`,
      reverse: false

    },
  ];

  constructor(private httpClient: HttpClient) {}

  toCent = (amount) => {
    const str = amount.toString();
    const [int] = str.split(".");

    return Number(
      amount
        .toFixed(2)
        .replace(".", "")
        .padEnd(int.length === 1 ? 3 : 4, "0")
    );
  };

  createPaymentIntent(obj: any) {
    return this.httpClient.post(`${this.url + this.checkoutApi}`, obj);
  }

  getCoupons(coupon) {
    const params = new HttpParams({
      fromObject: {
        coupon_code: coupon,
      },
    });
    return this.httpClient.get<Coupon[]>(`${this.url + this.couponsApi}`, {
      params: params,
    });
  }
}

export interface Coupon {
  discount_value: number;
  is_active: boolean;
  title: string;
}

export interface StudentObj {
  full_name?: string;
  email?: string;
  receive_email?: boolean;
  qualification?: string;
  year?: string;
  reference?: string;
  referral_other?: string;
  referral_name?: string;
  total_amount?: string;
  payment_options?: string;
  address?: string;
  apartment?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  coupon_code?: string;
  coupon_applied?: boolean;
}
