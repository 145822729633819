import {
  Component,
  NgZone,
  ViewContainerRef
} from '@angular/core';

// import {
//   MapsAPILoader
// } from 'angular2-google-maps/core';

// import { MdSnackBar , MdSnackBarConfig } from '@angular/material';
import { Router, ActivatedRoute }       from '@angular/router';


import {
  ProjectService
}
from './../projectservice';

declare var google: any;

@Component({
  selector: 'my-projectlist',
  templateUrl: './userlist.html'
})
export class UserListComponent {
  lat: any = 37.77493;
  lng: any = -122.419416;
  divcounter: number = 1;
  increment: number = 0;
  show:boolean=false;
  conditionExpression: number = 3;

  listcolletion:any;

  lngt: any;
  coobj:any;
  showSection: boolean = false;
  zoom: number = 8;
  name: any;
  type: any;
  status: any;
  subtype: any;
  dummydata: any;
  togglesection: any = true;
  location: any = "";
  // markers: marker[] = [];
  // markers1: marker[] = [];
  // markers2: marker[] = [];

  constructor(
//    private _loader: MapsAPILoader, 
 //   private zone: NgZone,
    public service: ProjectService,
    private router:Router,
 //   public snackBar: MdSnackBar,
      public viewContainerRef: ViewContainerRef
    ) {}










ngOnInit() {

   
    this.service.getUserList()
      .then(result => {

        this.listcolletion = result ;

        console.log(result)
      
    
  })
  }

GoToDetailPage(id:string)
{

   this.router.navigate(['/userprofile', id]);


}








}

