<navbar></navbar>
<div id="publishDialog1" class="modal fade" role="dialog" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content" style="border-radius: 0px !important;">
            <div class="modal-header" style="background-color: #FFE089;">
                <h4 class="modal-title">Confirm</h4>
            </div>
            <div class="modal-body">
                Do you want to adopt this page and control it's content. If you adopt this project, it will show on your
                profile page.
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default" data-dismiss="modal"
                    (click)="isAdopt = false;publishStatus = 'Uploaded';adoptAndPublish()">No</button>
                <button type="button" class="btn btn-primary" data-dismiss="modal"
                    (click)="isAdopt = true;publishStatus = 'Adopted';adoptAndPublish()">Yes</button>
            </div>
        </div>
    </div>
</div>
<div id="publishDialog2" class="modal fade" role="dialog" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content" style="border-radius: 0px !important;">
            <div class="modal-header" style="background-color: #FFE089;">
                <h4 class="modal-title">Set Owner Display Preference</h4>
            </div>
            <div class="modal-body">
                <p *ngIf="service.myOrg">
                    <label class="radio-inline">
                        <input type="radio" name="setting" [(ngModel)]="owner_display_preferences"
                            [checked]="owner_display_preferences == 'company_name'" value="company_name" />Show my
                        company name as the owner of this page</label>
                </p>
                <p>
                    <label class="radio-inline">
                        <input type="radio" name="setting" [(ngModel)]="owner_display_preferences"
                            [checked]="owner_display_preferences == 'user_name'" value="user_name" />Show me as the
                        owner of this page</label>
                </p>
            </div>
            <div class="modal-footer">
                <!-- <button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button> -->
                <button type="button" class="btn btn-primary" data-dismiss="modal"
                    (click)="setOwnerDisplay(true)">Submit</button>
            </div>
        </div>
    </div>
</div>
<h1 class="heading">Manage Projects</h1>
<div>
    <div [ngClass]="{'leftmenu': !service.mobilecheck(), 'navbar-fixed-left': !service.mobilecheck()}" class="navbar">
        <!--<a class="navbar-brand" routerLinkActive="active" routerLink="#">Brand</a>-->
        <ul class="nav navbar-nav" [ngClass]="{'mobile-ul': service.mobilecheck()}">
            <!--<li class="dropdown"><a routerLinkActive="active" routerLink="#" class="dropdown-toggle" data-toggle="dropdown">Dropdown <span class="caret"></span></a>
        <ul class="dropdown-menu" role="menu">
          <li><a routerLinkActive="active" routerLink="#">Sub Menu1</a></li>
          <li><a routerLinkActive="active" routerLink="#">Sub Menu2</a></li>
          <li><a routerLinkActive="active" routerLink="#">Sub Menu3</a></li>
          <li class="divider"></li>
          <li><a routerLinkActive="active" routerLink="#">Sub Menu4</a></li>
          <li><a routerLinkActive="active" routerLink="#">Sub Menu5</a></li>
        </ul>
      </li>-->
            <li class="text-center pad" [ngClass]="{'active': selectedItemId === 1}" (click)="selectedItemId = 1">
                In-Progress</li>
            <!-- <li class="text-center pad" [ngClass]="{'active': selectedItemId === 2}" (click)="selectedItemId = 2">
                Adopted</li> -->
            <li class="text-center pad" [ngClass]="{'active': selectedItemId === 3}" (click)="selectedItemId = 3">Team
                Member</li>
            <li class="text-center pad" [ngClass]="{'active': selectedItemId === 4}" (click)="selectedItemId = 4">
                Followed</li>
            <li class="text-center pad" [ngClass]="{'active': selectedItemId === 5}" (click)="selectedItemId = 5">
                Published</li>
            <li *ngIf="service.isAdmin" class="text-center pad" [ngClass]="{'active': selectedItemId === 6}"
                (click)="selectedItemId = 6">Flagged</li>
        </ul>
    </div>
    <div [ngClass]="{'rightList': !service.mobilecheck()}">
        <div class="loader1" *ngIf="loading"></div>
        <div *ngIf="selectedItemId == 1">
            <h3 *ngIf="noInprogress" class="text-center">No projects available!</h3>
            <div class="column col-md-4 col-sm-6" *ngFor="let item of userProjectsInprogress; let i = index">
                <!--<a class="ach" routerLinkActive="active" routerLink="/project/{{item.project_name_slug}}">-->
                <div class="card">
                    <div class="imgParent">
                        <img src="{{item.imageurl}}" onError="assets/images/no-image.jpg" alt="image">
                    </div>
                    <div class="container1">
                        <h4>{{item.name}}</h4>
                        <p style="height: 20px;">{{item.address}}</p>
                        <div class="bottom">
                            <a class="navigateBtn" routerLinkActive="active"
                                routerLink="/project/{{item.project_name_slug}}">
                                <button class="button">Go to Project</button>
                            </a>
                            <a class="navigateBtn1 navigateBtn"
                                (click)="projectid = item.project_name_slug; publishProjectIndex = i"
                                data-toggle="modal" data-target="#publishDialog1">
                                <button class="button">Publish</button>
                            </a>
                            <i class="glyphicon glyphicon-trash" (click)="deleteProject(item.project_name_slug, i)"
                                style="font-size: 24px; padding: 0 5px;"></i>
                            <!--<span class="glyphicon glyphicon-remove" (click)="deleteProject(item.project_id, i)"></span>-->
                        </div>
                    </div>
                </div>
                <!--</a>-->
            </div>
        </div>
        <div *ngIf="selectedItemId == 2">
            <h3 *ngIf="noAdopted" class="text-center">No projects available!</h3>
            <div class="column col-sm-4" *ngFor="let item of userProjectsAdopted; let i = index">
                <!--<a class="ach" routerLinkActive="active" routerLink="/project/{{item.project_name_slug}}">-->
                <div class="card">
                    <div class="imgParent">
                        <img src="{{item.imageurl}}" onError="assets/images/no-image.jpg" alt="image">
                    </div>
                    <div class="container1">
                        <h4>{{item.name}}</h4>
                        <p style="height: 20px;">{{item.address}}</p>
                        <div class="bottom">
                            <a class="navigateBtn" routerLinkActive="active"
                                routerLink="/project/{{item.project_name_slug}}">
                                <button class="button">Go to Project</button>
                            </a>
                            <a class="navigateBtn1 navigateBtn"
                                (click)="unadoptProject(item.project_name_slug, i, 'project_publish_status')">
                                <button class="editBtnCss btn">Unadopt</button>
                            </a>
                            <!--<span class="glyphicon glyphicon-remove" (click)="unadoptUnpublishProject(item.project_id, i, 'project_publish_status')"></span></div>-->
                        </div>
                    </div>
                    <!--</a>-->
                </div>
            </div>
        </div>
        <div *ngIf="selectedItemId == 3">
            <h3 *ngIf="noTagged && noTaggedOrg" class="text-center">No projects available!</h3>
            <div class="column col-sm-4" *ngFor="let item of userProjectsTagged; let i = index">
                <!--<a class="ach" routerLinkActive="active" routerLink="/project/{{item.project_name_slug}}">-->
                <div class="card">
                    <div class="box" *ngIf="item.isOrg">
                        <div class="ribbon">
                            <span>Organization</span>
                        </div>
                    </div>
                    <div class="imgParent">
                        <img src="{{item.imageurl}}" onError="assets/images/no-image.jpg" alt="image">
                    </div>
                    <div class="container1">
                        <h4>{{item.name}}</h4>
                        <p style="height: 20px;">{{item.address}}</p>
                        <div class="bottom">
                            <a class="navigateBtn" routerLinkActive="active"
                                routerLink="/project/{{item.project_name_slug}}">
                                <button class="button">Go to Project</button>
                            </a>
                            <a *ngIf="!item.isOrg" class="navigateBtn1 navigateBtn"
                                (click)="untagProject(item.project_id, i)">
                                <button class="editBtnCss btn">Untag</button>
                            </a>
                            <a *ngIf="item.isOrg" class="navigateBtn1 navigateBtn"
                                (click)="untagProjectOrg(item.project_id, i)">
                                <button class="editBtnCss btn">Untag</button>
                            </a>
                            <!--<span class="glyphicon glyphicon-remove" (click)="untagProject(item.project_id, i)"></span></div>-->
                        </div>
                    </div>
                    <!--</a>-->
                </div>
            </div>
        </div>
        <div *ngIf="selectedItemId == 4">
            <h3 *ngIf="noFollowed" class="text-center">No projects available!</h3>
            <div class="column col-sm-4" *ngFor="let item of userProjectsFollowed; let i = index">
                <!--<a class="ach" routerLinkActive="active" routerLink="/project/{{item.project_name_slug}}">-->
                <div class="card">
                    <div class="imgParent">
                        <img src="{{item.imageurl}}" onError="assets/images/no-image.jpg" alt="image">
                    </div>
                    <div class="container1">
                        <h4>{{item.name}}</h4>
                        <p style="height: 20px;">{{item.address}}</p>
                        <div class="bottom">
                            <a class="navigateBtn" routerLinkActive="active"
                                routerLink="/project/{{item.project_name_slug}}">
                                <button class="button">Go to Project</button>
                            </a>
                            <a class="navigateBtn1 navigateBtn" (click)="unfollowProject(item.project_id, i)">
                                <button class="editBtnCss btn">Unfollow</button>
                            </a>
                            <!--<span class="glyphicon glyphicon-remove" (click)="unfollowProject(item.project_id, i)"></span></div>-->
                        </div>
                    </div>
                    <!--</a>-->
                </div>
            </div>
        </div>
        <div *ngIf="selectedItemId == 5">
            <h3 *ngIf="noUploaded" class="text-center">No projects available!</h3>
            <div class="column col-sm-4" *ngFor="let item of userProjectsUploaded; let i = index">
                <!--<a class="ach" routerLinkActive="active" routerLink="/project/{{item.project_name_slug}}">-->
                <div class="card">
                    <div class="imgParent">
                        <img src="{{item.imageurl}}" onError="assets/images/no-image.jpg" alt="image">
                    </div>
                    <div class="container1">
                        <h4>{{item.name}}</h4>
                        <p style="height: 20px;">{{item.address}}</p>
                        <div class="bottom">
                            <a class="navigateBtn" routerLinkActive="active"
                                routerLink="/project/{{item.project_name_slug}}">
                                <button class="button">Go to Project</button>
                            </a>
                            <a class="navigateBtn1 navigateBtn"
                                (click)="unpublishProject(item.project_name_slug, i, 'is_published')">
                                <button class="editBtnCss btn">Unpublish</button>
                            </a>
                            <!--<span class="glyphicon glyphicon-remove" (click)="unadoptUnpublishProject(item.project_id, i, 'is_published')"></span></div>-->
                        </div>
                    </div>
                    <!--</a>-->
                </div>
            </div>
        </div>
        <div *ngIf="selectedItemId == 6">
            <h3 *ngIf="noFlagged" class="text-center">No projects available!</h3>
            <div class="column col-sm-4" *ngFor="let item of userProjectsFlagged; let i = index">
                <!--<a class="ach" routerLinkActive="active" routerLink="/project/{{item.project_name_slug}}">-->
                <div class="card">
                    <div class="imgParent">
                        <img src="{{item.imageurl}}" onError="assets/images/no-image.jpg" alt="image">
                    </div>
                    <div class="container1">
                        <h4>{{item.name}}</h4>
                        <p style="height: 20px;">{{item.address}}</p>
                        <div class="bottom">
                            <a class="navigateBtn" routerLinkActive="active"
                                routerLink="/project/{{item.project_name_slug}}">
                                <button class="button">Go to Project</button>
                            </a>
                            <i [popover]="myPopover" class="glyphicon glyphicon-exclamation-sign"
                                style="font-size: xx-large;padding-left: 5px;"></i>
                            <!--<a class="navigateBtn1 navigateBtn" (click)="unpublishProject(item.project_id, i, 'is_published')"><button class="editBtnCss btn">Reason for flagged</button></a>-->
                            <!--<span class="glyphicon glyphicon-remove" (click)="unadoptUnpublishProject(item.project_id, i, 'is_published')"></span></div>-->
                        </div>
                        <popover-content #myPopover title="Reported reason" placement="top" [animation]="true"
                            [closeOnClickOutside]="true">
                            <div style="max-width: 276px;">{{ item.reason }}</div>
                        </popover-content>
                    </div>
                    <!--</a>-->
                </div>
            </div>
        </div>
    </div>
</div>
<app-confirm-c [sanket]="confirmObject" (performAction)="confirmedAction($event)"></app-confirm-c>

<!--<footer></footer>-->
<style>
    .loader1 {
        border: 4px solid #f3f3f3;
        border-top: 4px solid #3498db;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        animation: spin 1s linear infinite;
        position: absolute;
        top: 35%;
        left: 49%;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    .editBtnCss {
        border: 1px solid #8c8c8c;
        font-size: 12px;
        height: 36px;
        padding: 0px 10px;
        background-color: #f5f5f5;
        font-family: OpenSans-Bold;
        color: #9e9e9e;
    }

    .bottom {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
      
    }

    .navigateBtn {
        width: 80%;
        display: inline-block;
    }

    .navigateBtn1 {
        margin-left: 10px;
        width: auto;
    }

    .bottom span {
        font-size: 24px;
        margin-left: 10px;
        top: 0;
    }

    .parent {
        position: fixed;
        top: 153px;
        bottom: 0px;
        width: 100%;
    }

    .rightList {
        height: 100%;
        overflow: auto;
        margin-left: 200px;
    }

    .leftmenu {
        display: flex;
        justify-content: center;
        margin-top: 9px;
        width: 200px;
    }

    .pad {
        padding: 10px;
        cursor: pointer;
    }

    .active {
        color: white;
        background-color: #1a496d;
        /*border: 1px solid black;*/
    }

    .navbar-fixed-left {
        /*max-width: 140px;
    min-width: 140px;*/
        position: fixed;
        border-radius: 0;
        height: 100%;
    }

    .navbar-fixed-left .navbar-nav>li {
        float: none;
        /* Cancel default li float: left */
        width: 139px;
    }

    .navbar-fixed-left+.container {
        padding-left: 100px;
    }

    /* On using dropdown menu (To right shift popuped) */

    .navbar-fixed-left .navbar-nav>li>.dropdown-menu {
        margin-top: -50px;
        margin-left: 140px;
    }

    @font-face {
        font-family: OpenSans-Regular;
        src: url('/assets/fonts/new/opensans-regular-webfont.eot?#iefix') format('embedded-opentype'),
            /* IE */
            url('/assets/fonts/new/opensans-regular-webfont.ttf') format("truetype");
        /* non-IE */
    }

    @font-face {
        font-family: OpenSans-Light;
        src: url('/assets/fonts/new/opensans-light-webfont.eot?#iefix') format('embedded-opentype'),
            /* IE */
            url('/assets/fonts/new/opensans-light-webfont.ttf') format("truetype");
        /* non-IE */
    }

    @font-face {
        font-family: OpenSans-Bold;
        src: url('/assets/fonts/new/opensans-bold-webfont.eot?#iefix') format('embedded-opentype'),
            /* IE */
            url('/assets/fonts/new/opensans-bold-webfont.ttf') format("truetype");
        /* non-IE */
    }

    @font-face {
        font-family: OpenSans-ExtraBold;
        src: url('/assets/fonts/new/opensans-extrabold-webfont.eot?#iefix') format('embedded-opentype'),
            /* IE */
            url('/assets/fonts/new/opensans-extrabold-webfont.ttf') format("truetype");
        /* non-IE */
    }

    @font-face {
        font-family: OpenSans-Semibold;
        src: url('/assets/fonts/new/opensans-semibold-webfont.eot?#iefix') format('embedded-opentype'),
            /* IE */
            url('/assets/fonts/new/opensans-semibold-webfont.ttf') format("truetype");
        /* non-IE */
    }

    /* Three columns side by side */

    .ach {
        color: black !important;
        text-decoration: none !important;
    }

    /* .loader1 {
      border: 4px solid #f3f3f3;
      border-top: 4px solid #3498db;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      animation: spin 1s linear infinite;
      position: absolute;
      top: 35%;
      left: 44%;
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    } */

    .container1 h4,
    .container1 p {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .container1 h4 {
        padding-bottom: 3px;
    }

    .mapsBtn {
        background-color: burlywood;
        cursor: pointer;
        padding: 6px;
        margin-left: 2px;
        text-align: center;
        font-weight: bold;
        color: black !important;
        text-decoration: none !important;
    }

    .imgParent {
        width: 100%;
        padding-top: 75%;
        position: relative;
        background: rgba(212, 212, 212, 0.4);
    }

    .imgParent img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        display: block;
        margin: auto;
        object-fit: cover;
        /* image-rendering: pixelated; */
    }

    .column {
        margin-bottom: 10px;
        margin-top: 10px;
    }

    /* Display the columns below each other instead of side by side on small screens */

    @media (max-width: 650px) {
        .column {
            width: 100%;
            display: block;
        }
    }

    /* Add some shadows to create a card effect */

    .card {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    }

    /* Some left and right padding inside the container */

    .container1 {
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    /* Clear floats */

    .container1::after,
    .row::after {
        content: "";
        clear: both;
        display: table;
    }

    .button {
        border: none;
        outline: 0;
        display: inline-block;
        padding: 8px;
        color: white;
        background-color: #1a496d;
        text-align: center;
        cursor: pointer;
        width: 100%;
    }

    .button:hover {
        background-color: burlywood;
    }

    .img-nearby {
        float: left;
        margin-left: 40px;
        width: 130px;
    }

    .nearby-txtcolor {
        color: #757575;
        font-size: 13px;
    }

    .nearby-content {
        font-size: 80%;
        margin-top: -5%;
        font-family: OpenSans-Light;
        color: #818181;
    }

    .heading {
        color: #757575;
        font-family: OpenSans-Regular !important;
        border-bottom: 2px solid #ffdd73;
        padding-bottom: 30px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .projectsTypes {
        color: #757575;
        font-family: OpenSans-Regular !important;
        font-size: 16px;
    }

    .projects {
        padding: 20px 0;
    }

    .projects:not(:last-child) {
        border-bottom: 2px solid gray;
    }

    .box {
        /*width: 200px;
      height: 300px;*/
        position: relative;
        border: 1px solid #BBB;
        background: #EEE;
    }

    .ribbon {
        position: absolute;
        right: -5px;
        top: -5px;
        z-index: 1;
        overflow: hidden;
        width: 75px;
        height: 75px;
        text-align: right;
    }

    .ribbon span {
        font-size: 9px;
        font-weight: bold;
        color: #FFF;
        text-transform: uppercase;
        text-align: center;
        line-height: 20px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        width: 100px;
        display: block;
        background: #79A70A;
        background: linear-gradient(#9BC90D 0%, #79A70A 100%);
        box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
        position: absolute;
        top: 19px;
        right: -21px;
    }

    .ribbon span::before {
        content: "";
        position: absolute;
        left: 0px;
        top: 100%;
        z-index: -1;
        border-left: 3px solid #79A70A;
        border-right: 3px solid transparent;
        border-bottom: 3px solid transparent;
        border-top: 3px solid #79A70A;
    }

    .ribbon span::after {
        content: "";
        position: absolute;
        right: 0px;
        top: 100%;
        z-index: -1;
        border-left: 3px solid transparent;
        border-right: 3px solid #79A70A;
        border-bottom: 3px solid transparent;
        border-top: 3px solid #79A70A;
    }

    .mobile-ul {
        margin: 0;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
</style>