import { DialogueComponent } from './../../../dialogue/dialogue.component';
import { Router } from '@angular/router';
import { AboutusService } from './../../aboutuspage/aboutus.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { OrganizationCard } from './../../org-card/organization';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-get-in-touch',
  templateUrl: './get-in-touch.component.html',
  styleUrls: ['./get-in-touch.component.scss']
})
export class GetInTouchComponent implements OnInit {


  user = new FormGroup({
    first_name: new FormControl('', Validators.required),
    last_name: new FormControl('', Validators.required),
    email: new FormControl('', [
      Validators.required,
      Validators.email,
      // Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
    ]),
    organization: new FormControl('', Validators.required),
    message: new FormControl('', Validators.required),
    type: new FormControl('')
  });

  constructor(private aboutService:AboutusService,private router:Router,public dialog: MatDialog) { }

  ngOnInit() {
    if(history.state){
      if (history.state.val) {
        console.log(history.state.val);
        this.user.get('type').setValue(history.state.val);
      }
    }
  }

  submit(){
    if(!this.user.invalid){
      console.log(this.user.value);
      this.aboutService.getInTouch(this.user.value).subscribe(data => {
        console.log(data)
        if(data){
          this.openSuccessDialogues("Success", { type: 'success', message: "Check your email!, We'll see you soon!" }, "common");
        }else{
          this.openSuccessDialogues("Error", { type: 'error', message: "Something went wrong, Try again!" }, "common");

        } 
      }) 
    }
  }

  openSuccessDialogues(title, content, type) {
    const dialogRef = this.dialog.open(DialogueComponent, {

      disableClose: true,
      data: {
        title: title,
        content: content,
        type: type
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result == 'OK') {
        this.reloadComponent();
      }

    });
  }

  reloadComponent() {
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }

}
