import { MetaService } from './../new-repicture/shared/meta.service';
import {
  Component,
  NgZone,
  Input,
  EventEmitter,
  Output,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
  Inject,
  PLATFORM_ID,
  ChangeDetectorRef
} from "@angular/core";

import {
  trigger,
  state,
  style,
  animate,
  transition,
  keyframes
} from "@angular/animations";
import { Location } from "@angular/common";

import {
  ImageCropperComponent,
  CropperSettings,
  Bounds
} from "ng2-img-cropper";
import { DomSanitizer, Meta } from "@angular/platform-browser";
import { Http, RequestOptions, Headers } from "@angular/http";
import { Title } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import { ProjectService } from "../projectservice";
// import { CookieService } from "angular2-cookie/core";
import { EditprofilemystoryComponent } from './editprofilemystory/editprofilemystory.component';
import * as _ from "lodash";
import * as $ from "jquery";
import { isPlatformBrowser } from "@angular/common";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { ProjectsorderComponent } from "../projectsorder/projectsorder.component";
import { Observable, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { ProfileService } from "./profile.service";
import { School } from "./profileModel";
import { FormControl } from "@angular/forms";
declare var jQuery: any;

@Component({
  selector: "userprofile",
  templateUrl: "./profile.html",
  styleUrls: ["./profile.component.scss"],
  encapsulation: ViewEncapsulation.Emulated,
})
export class UserProfileComponent {

  confirmObject: { 'confirmTitle': string; 'tag': string; 'i': any; 'type': string; };
  @ViewChild('input', { static: false }) fileInput: ElementRef;
  @ViewChild('cropper', { static: false }) cropper: ImageCropperComponent;
  @ViewChild('imgcrp', { static: false }) imgcrp: HTMLImageElement;
  @ViewChild(ProjectsorderComponent, { static: false }) projectOrderComp;
  @ViewChild(EditprofilemystoryComponent, { static: false }) myStory: EditprofilemystoryComponent;
  imagesrc: any;
  userInfo: any;
  doneloading: boolean = false;
  textboxedit: boolean = false;
  textboxedit1: boolean = false;
  textboxedit2: boolean = false;
  userProjects: any = [];
  userCreatedAdoptedProjects = [];
  adoptedWithOthersProjects = [];
  noProjects: boolean = false;
  noProjectsAdopted: boolean = false;
  audio: any;
  video: any;
  external_video: any = [];
  external_video_edit: any = [];
  external_video_iframe: any = [];
  external_video_iframe_edit: any = [];
  cropperSettings1: CropperSettings;
  croppedWidth: number;
  croppedHeight: number;
  Onimage: boolean = false;
  dat: any;
  tab = 1;
  career: any;
  stem: any;
  stem1: boolean;
  evt: any;
  videoURL: any;
  showwilling: boolean = false;
  sameUser: boolean = false;
  privacy: boolean = false;
  is_claimable: boolean = false;
  user: any;
  is_public: boolean = false;
  publicUser: any;
  exp: any = ["0 - 2", "2 - 5", "5 - 10", "10 - 20", "20+", "Retired", "College Student: Graduating within 1 year", "College Student: Graduating more than 1 year", "High School Student"];
  existingSocial: social_obj = { Facebook: "", LinkedIn: "", Twitter: "", Other: { displayText: "", displayURL: "" }, Instagram: "" };
  socialArr = ["Facebook", "LinkedIn", "Twitter", "Other"];
  is_prof_certi_other: boolean = false;
  prof_certi_other: any;
  certi_location: any;
  selected_certifications: any = [];
  certifications: any = [
    { id: 'Fundamentals of Engineering (FE)', name: 'Fundamentals of Engineering (FE)' },
    { id: 'Professional Engineer (PE)', name: 'Professional Engineer (PE)' },
    { id: 'Structural Engineer (SE)', name: 'Structural Engineer (SE)' },
    { id: 'Registered Engineer (RE)', name: 'Registered Engineer (RE)' },
    { id: 'International Professional Engineer (IntPE)', name: 'International Professional Engineer (IntPE)' },
    { id: 'Chartered Engineer (CEng)', name: 'Chartered Engineer (CEng)' },
    { id: 'Incorporated Engineer (IEng)', name: 'Incorporated Engineer (IEng)' },
    { id: 'Engineering Technician (EngTech)', name: 'Engineering Technician (EngTech)' },
    { id: 'Information and Communications Technology Technicians (ICTTech)', name: 'Information and Communications Technology Technicians (ICTTech)' },
    { id: 'Project Management Professional (PMP)', name: 'Project Management Professional (PMP)' },
    { id: 'LEED AP', name: 'LEED AP' },
    { id: 'LEED Green Associate', name: 'LEED Green Associate' },
    { id: 'Other', name: 'Other' }
  ];
  degrees: any = [
    { id: "Bachelor's Degree", name: "Bachelor's Degree" },
    { id: "Bachelor of Arts (B.A.)", name: "Bachelor of Arts (B.A.)" },
    { id: "Bachelor of Science (B.S.)", name: "Bachelor of Science (B.S.)" },
    { id: "Bachelor of Fine Arts (BFA)", name: "Bachelor of Fine Arts (BFA)" },
    { id: "Bachelor of Applied Science (BAS)", name: "Bachelor of Applied Science (BAS)" },
    { id: "Bachelor of Engineering (B.E.)", name: "Bachelor of Engineering (B.E.)" },
    { id: "Master's Degree", name: "Master's Degree" },
    { id: "Master of Arts (M.A.)", name: "Master of Arts (M.A.)" },
    { id: "Master of Science (M.S.)", name: "Master of Science (M.S.)" },
    { id: "Master of Engineering (M.E.)", name: "Master of Engineering (M.E.)" },
    { id: "Doctoral Degree", name: "Doctoral Degree" },
    { id: "Doctor of Philosophy (Ph.D.)", name: "Doctor of Philosophy (Ph.D.)" },
    { id: "Doctor of Education (Ed.D.)", name: "Doctor of Education (Ed.D.)" },
    { id: "Juris Doctorate (J.D.)", name: "Juris Doctorate (J.D.)" },
    { id: "Associate's Degree ", name: "Associate's Degree " },
    { id: "Associate of Arts (A.A.)", name: "Associate of Arts (A.A.)" },
    { id: "Associate of Science (A.S.)", name: "Associate of Science (A.S.)" },
    { id: "Associate of Applied Science (A.A.S.)", name: "Associate of Applied Science (A.A.S.)" },
    { id: 'Other', name: 'Other' }
  ];
  collectionEducation: any = [];
  collectionCertificates: any = [];
  birth_year: any;
  death_year: any;
  ObjCollection: any = {
    name: {
      btntitle: "name",
      fieldname: "name",
      edit: false,
      data: "",
      project_id: "",
      placeholder: "Add Name"
    },
    user_designation: {
      btntitle: "line of work",
      fieldname: "user_designation",
      edit: false,
      data: "",
      project_id: "",
      placeholder: "Add designation"
    },
    professional_certification: {
      btntitle: "professional certification",
      fieldname: "professional_certification",
      edit: false,
      data: "",
      project_id: "",
      placeholder: "Add professional certification"
    },
    user_experience: {
      btntitle: "years experience",
      fieldname: "user_experience",
      edit: false,
      data: "",
      project_id: "",
      placeholder: "Add experience"
    },
    user_stories: {
      btntitle: "story",
      fieldname: "user_stories",
      edit: false,
      data: "",
      project_id: "",
      placeholder: "Add story"
    },
    user_quotes: {
      btntitle: "favorite quote or motto",
      fieldname: "user_quotes",
      edit: false,
      data: "",
      project_id: "",
      placeholder: "Add quote"
    },
    user_education: {
      btntitle: "education",
      fieldname: "user_education",
      edit: false,
      data: "",
      project_id: "",
      placeholder: "Add education"
    },
    user_awards: {
      btntitle: "awards",
      fieldname: "user_awards",
      edit: false,
      data: "",
      project_id: "",
      placeholder: "Add awards"
    },
    social_network: {
      btntitle: "social networks",
      fieldname: "social_network",
      edit: false,
      data: "",
      project_id: "",
      placeholder: "Add linkedin url"
    },
    birth_death: {
      btntitle: "years of birth and death",
      fieldname: "birth_death",
      edit: false,
      data: "",
      project_id: "",
      placeholder: "Add linkedin url"
    }
  };

  myStoryCollection: any = {
    'external_links': {
      'btntitle': 'external_links',
      'fieldname': 'external_links',
      'edit': false,
      'data': '',
      'project_id': ''
    },
    'technical_expertise': {
      'btntitle': 'technical_expertise',
      'fieldname': 'technical_expertise',
      'edit': false,
      'data': '',
      'project_id': ''
    },
    'media': {
      'btntitle': 'media',
      'fieldname': 'media',
      'edit': false,
      'data': '',
      'project_id': ''
    },
    stem_program: {
      btntitle: "stem_program",
      fieldname: "stem_program",
      edit: false,
      data: "",
      project_id: ""
    },
    career_status: {
      btntitle: "career_status",
      fieldname: "career_status",
      edit: false,
      data: "",
      project_id: ""
    },
    engineering_education_helped_me: {
      btntitle: "engineering_education_helped_me",
      fieldname: "engineering_education_helped_me",
      edit: false,
      data: "",
      project_id: ""
    },
    user_team_experience: {
      btntitle: "user_team_experience",
      fieldname: "user_team_experience",
      edit: false,
      data: "",
      project_id: ""
    },
    q1_ans: {
      btntitle: "i_am",
      fieldname: "q1_ans",
      edit: false,
      data: "",
      project_id: ""
    },
    q2_ans: {
      btntitle: "i_chose",
      fieldname: "q2_ans",
      edit: false,
      data: "",
      project_id: ""
    },
    q3_ans: {
      btntitle: "typical_day",
      fieldname: "q3_ans",
      edit: false,
      data: "",
      project_id: ""
    },
    q4_ans: {
      btntitle: "others_know",
      fieldname: "q4_ans",
      edit: false,
      data: "",
      project_id: ""
    },
    q5_ans: {
      btntitle: "my_story",
      fieldname: "q5_ans",
      edit: false,
      data: "",
      project_id: ""
    },
    q6_ans: {
      btntitle: "my_story",
      fieldname: "q6_ans",
      edit: false,
      data: "",
      project_id: ""
    },
    q7_ans: {
      btntitle: "my_story",
      fieldname: "q7_ans",
      edit: false,
      data: "",
      project_id: ""
    },
    q8_ans: {
      btntitle: "my_story",
      fieldname: "q8_ans",
      edit: false,
      data: "",
      project_id: ""
    },
    q9_ans: {
      btntitle: "my_story",
      fieldname: "q9_ans",
      edit: false,
      data: "",
      project_id: ""
    },
    about: {
      btntitle: "about",
      fieldname: "about",
      edit: false,
      data: "",
      project_id: ""
    }
  };
  user_id: any;
  reasonCode: any;

  lineofwork = ['Aerospace Engineer', 'Biomedical Engineer', 'Chemical Engineer', 'Civil Engineer', 'Computer Hardware Engineer', 'Electrical Engineer', 'Electronics Engineer', 'Environmental Engineer', 'Industrial Engineer', 'Materials Engineer', 'Mechanical Engineer', 'Petroleum Engineer', 'Software Engineer', 'Other Engineer', 'Architect', 'Biologist', 'Computer Scientist', 'Chemist', 'Environmental Scientist', 'Landscape Architect', 'Planner', 'Project Manager', 'Surveyor', 'Other'];
  selectedLineOfWork = [];
  first_name: any;
  last_name: any;
  selected_degrees: any = [];
  is_degree_other: boolean = false;
  degree_other: any;
  field_of_study: any;
  school: any;
  year: any;
  designation: any = '';
  user_award_url: any;
  // user_award_name: any;
  collectionUserAwards: any = [];
  collectionExternalLinks = [];
  textinput: any;

  projectobj: any;
  toggleVisibitly = false;
  ShowWebsiteDiv: boolean = false;
  ShowProjectTypeDiv: boolean = false;
  EditToggle: boolean = false;
  showTexbox: boolean = false;
  isEngineer: boolean = false;
  ShowWebsiteDivData: boolean = false;
  ShowProjectTypeDivData: boolean = false;
  projecttypeArr: any = [];
  url: string;
  desc: any;
  printArray: any = [];
  data: any;
  data1: any = [];
  varib: boolean = false;
  fields: any;
  txt: any;
  otherClicked: boolean = false;
  socialNetworkArr: any = [];
  other: boolean = false;
  user_designation_arr = [];
  hasOtherLineOfWork: boolean;
  hasOtherEngineer: boolean = false;
  otherLOW: any = '';
  user_experience: any;
  user_pic_alt: any;
  profilePic: File;
  otherEngineers = [];
  addmoreCliked: boolean = false;
  appending: boolean = false;
  ta_id: any;
  user_type: any;
  loginStatus: boolean = false;
  projectObj = {};
  schoolTypeField: FormControl;
  schoolArray$: Observable<School[]>;

  // dragDropArray = ["hi1","hi2","hi3","hi4","hi5"]

  constructor(public service: ProjectService, private profileService: ProfileService, private http: Http, private router: Router, private route: ActivatedRoute, private titleService: Title, private _sanitizer: DomSanitizer, private meta: Meta, private metaService: MetaService, private cd: ChangeDetectorRef, @Inject(PLATFORM_ID) private platform: any, private location: Location) {
    // force route reload whenever params change;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit() {
    if (localStorage.getItem('authtoken')) {
      this.loginStatus = true;
    } else {
      this.loginStatus = false;
    }
    let length = this.meta.getTags('property="og:image"').length;
    for (let i = 0; i < length; i++) {
      this.meta.removeTag('property="og:image"');
    }
    this.service.publicUser = "";
    // this.titleService.setTitle('User profile');
    if (isPlatformBrowser(this.platform)) {
      this.cropperSettings1 = new CropperSettings();
      this.cropperSettings1.width = 200;
      this.cropperSettings1.height = 200;

      this.cropperSettings1.croppedWidth = 200;
      this.cropperSettings1.croppedHeight = 200;

      this.cropperSettings1.canvasWidth =
        document.getElementsByTagName("body")[0].clientWidth - 200;
      this.cropperSettings1.canvasHeight = 600;

      this.cropperSettings1.minWidth = 10;
      this.cropperSettings1.minHeight = 10;

      this.cropperSettings1.rounded = true;
      this.cropperSettings1.keepAspect = true;
      this.cropperSettings1.preserveSize = true;

      this.cropperSettings1.cropperDrawSettings.strokeColor = "cadetblue";
      this.cropperSettings1.cropperDrawSettings.strokeWidth = 3;

      this.dat = {};
    }
    try {
      this.route.params.subscribe(params => {
        this.service.getPeopleDetail(params['id']).subscribe(res => {
          console.log(res);
          console.log('ig', res['is_public']);
          if (res['is_public']) {
            this.is_public = true;
            this.service.ispublic = true;
            this.service.publicUser = params['id'];
          } else {
            this.is_public = false;
            this.service.ispublic = false;
          }
          this.checkUser();
          let designation = '';
          if (res['user_designation'] != null) {
            if (res['user_designation'].length > 0) {
              designation = res['user_designation'].split(',')[0];
              this.user_pic_alt = res['user_designation'].split(',')[0] + ' ' + res["first_name"] + ' ' + res["last_name"];
            } else {
              this.user_pic_alt = res["first_name"] + ' ' + res["last_name"];
            }
          } else {
            this.user_pic_alt = res["first_name"] + ' ' + res["last_name"];
          }
          let title = res["first_name"] + ' ' + res["last_name"] + ' | ' + designation;


          this.titleService.setTitle(title + ' | ' + this.service.project_name);


          this.setMeta(`${title} | ${this.service.project_name}`, 'Meet ' + res["first_name"] + ' ' + res["last_name"] + '. Learn about their career as ' + designation + ', best part of their job, and science & engineering projects they worked on.', `${res['profileImage_thumbnail_v1']}`);
          this.user_id = res['id'];
          sessionStorage.setItem('currentUserSlug', params['id']);
          sessionStorage.setItem('currentUserId', this.user_id);
          // var frames = document.getElementsByClassName("vid");
          // if(frames.length > 0)
          // $("#vide").contents().empty();
          // console.log('vide', $("#vide"));
          // var frame = document.getElementsByClassName("vid");
          // for(let i=0; i<frame.length; i++) {
          //   frame[i].parentNode.removeChild(frame[i]);
          // }
          this.ta_id = res['teaching_assistant'] ? res['teaching_assistant'] : -1;
          if (localStorage.getItem("full_name_slug") == params['id']) {
            this.sameUser = true;
            this.service.sendEvent(
              "visiting own profile",
              "view/edit own profile",
              "visited userprofile id/name: " +
              params["id"] +
              "/" +
              this.ObjCollection.name.data
            );
          } else {
            this.service.sendEvent(
              "userprofile page visit",
              "view/edit userprofile",
              "visited userprofile id/name: " +
              params["id"] +
              "/" +
              this.ObjCollection.name.data
            );
            this.sameUser = false;
          }

          if (res['linkedin_signup']) {
            this.imagesrc = res['linkedinProfileImageURL'];
          } else if (res['profileImage_thumbnail_v1'] != null) {
            this.imagesrc = res['profileImage_thumbnail_v1'];
          } else {
            this.imagesrc = 'assets/images/default.png';
          }

          if (res['is_private']) {
            this.privacy = true;

            if (this.sameUser) {
              this.privacy = false;
            }
          }

          this.user_type = res['user_type'];

          if (res['is_claimable']) {
            this.is_claimable = true;
            if (res['created_by_name']) {
              this.user = res['created_by_name'];
            } else {
              this.user = false;
            }
          } else {
            this.is_claimable = false;
            // let years;
            // if (res['dob']) {
            //   years = res['dob']
            // }
            // if (res['dod']) {
            //   if (res['dob']) {
            //     years += " " + res['dod'];
            //   } else {
            //     years = res['dod'];
            //   }
            // }
            this.death_year = res['dod'] ? res['dod'] : '';
            this.birth_year = res['dob'] ? res['dob'] : '';
            if (this.death_year) {
              this.ObjCollection.birth_death.data = this.birth_year + '-' + this.death_year;
            } else {
              this.ObjCollection.birth_death.data = '';
            }
          }

          this.ObjCollection.name.data = res['first_name'] + " " + res['last_name'];
          this.first_name = res['first_name'];
          this.last_name = res['last_name'];
          // this.titleService.setTitle(this.ObjCollection.name.data + ' | ' + this.service.project_name);
          if (res['user_designation'] != null) {
            this.ObjCollection.user_designation.data = _.uniq(res['user_designation'].split(',')).toString().replace(/,/g, ', ');
          } else {
            this.ObjCollection.user_designation.data = '';
          }
          if (_.includes(this.ObjCollection.user_designation.data, 'Engineer')) {
            this.isEngineer = true;
          }
          if (res['user_designation'])
            this.user_designation_arr = _.uniq(res['user_designation'].split(','));
          for (let i = 0; i < this.user_designation_arr.length; i++) {
            if (_.includes(this.lineofwork, this.user_designation_arr[i])) {
              this.selectedLineOfWork.push(this.user_designation_arr[i]);
            } else if (this.user_designation_arr[i].match(/(^|\W)engineer($|\W)/i)) {
              this.hasOtherEngineer = true;
              this.otherEngineers = this.user_designation_arr[i];
              if (!_.includes(this.selectedLineOfWork, 'Other Engineer'))
                this.selectedLineOfWork.push('Other Engineer');
            } else {
              this.otherLOW = this.user_designation_arr[i];
              this.hasOtherLineOfWork = true;
              if (!_.includes(this.selectedLineOfWork, 'Other'))
                this.selectedLineOfWork.push('Other');
            }
          }
          console.log('this.user_designation_arr[i]', this.selectedLineOfWork);
          if (!_.isEmpty(res['user_designation'])) {
            this.career = "to be a " + res['user_designation'];
          } else {
            this.career = "my career";
          }
          this.ObjCollection.professional_certification.data = !_.isEmpty(res['professional_certification']) ? res['professional_certification'] : '';
          this.ObjCollection.user_education.data = !_.isEmpty(res['user_education']) ? res['user_education'] : [];
          this.collectionEducation = this.ObjCollection.user_education.data;
          this.ObjCollection.user_awards.data = !_.isEmpty(res['user_awards']) ? res['user_awards'] : '';
          this.ObjCollection.user_experience.data = res['user_experience'];
          this.user_experience = res['user_experience'];
          this.ObjCollection.user_stories.data = res['user_stories'];
          this.ObjCollection.user_quotes.data = res['user_quotes'];
          this.ObjCollection.social_network.data = !_.isEmpty(res['socialNetworks']) ? res['socialNetworks'] : '';
          if (this.ObjCollection.social_network.data) {
            if (this.ObjCollection.social_network.data.hasOwnProperty('Facebook'))
              this.existingSocial.Facebook = this.ObjCollection.social_network.data.Facebook;
            if (this.ObjCollection.social_network.data.hasOwnProperty('LinkedIn'))
              this.existingSocial.LinkedIn = this.ObjCollection.social_network.data.LinkedIn;
            if (this.ObjCollection.social_network.data.hasOwnProperty('Twitter'))
              this.existingSocial.Twitter = this.ObjCollection.social_network.data.Twitter;
            if (this.ObjCollection.social_network.data.hasOwnProperty('Other'))
              this.existingSocial.Other = this.ObjCollection.social_network.data.Other;
            if (this.ObjCollection.social_network.data.hasOwnProperty('Instagram'))
              this.existingSocial.Twitter = this.ObjCollection.social_network.data.Instagram;
          }
          if (res['user_questions_willingness']) {
            this.stem = 'Yes';
          } else if (!res['user_questions_willingness']) {
            this.stem = 'No';
          }
          this.myStoryCollection.career_status.data = !_.isEmpty(res['career_status']) ? res['career_status'] : null;
          this.myStoryCollection.engineering_education_helped_me.data = res['engineering_education_helped_me'];
          this.myStoryCollection.user_team_experience.data = res['user_team_experience'];
          this.myStoryCollection.technical_expertise.data = res['technical_expertise'];
          this.myStoryCollection.q1_ans.data = res['q1_ans'];
          this.myStoryCollection.q2_ans.data = res['q2_ans'];
          this.myStoryCollection.q3_ans.data = res['q3_ans'];
          this.myStoryCollection.q4_ans.data = res['q4_ans'];
          this.myStoryCollection.q5_ans.data = res['q5_ans'];
          this.myStoryCollection.q6_ans.data = res['q6_ans'];
          this.myStoryCollection.q7_ans.data = res['q7_ans'];
          this.myStoryCollection.q8_ans.data = res['q8_ans'];
          this.myStoryCollection.q9_ans.data = res['q9_ans'];
          this.myStoryCollection.about.data = res['about'];
          this.myStoryCollection.external_links.data = res['external_links'];
          this.audio = res['user_audio'];
          this.video = res['user_video'];
          this.external_video = res['external_video'] ? res['external_video'] : [];//? this.convertMedia(res.external_video) : '';
          this.external_video_edit = _.clone(this.external_video);
          for (let i = 0; i < this.external_video.length; i++) {
            // this.external_video_iframe.push(this.convertMedia(this.external_video[key], 'get'));
            this.convertMedia(this.external_video[i], "get");
          }
          console.log(this.external_video_iframe);
          //this.service.setUserDetails(res);


          // pruserv1 -> userProjects
          // createdby -> userCreatedAdoptedProjects
          // prowner -> adoptedWithOthersProjects


          this.doneloading = true;

          this.service.getProjectsCreatedByUser(this.user_id).then(res => {
            const arr: any = res;
            if (arr.length > 0) {
              arr.forEach(element => {
                if (element.adopted_by_id) {
                  if (element.project_publish_status == 'Adopted' && element.adopted_by_id == this.user_id && element.is_published == true && element.co_owners.length == 0) {
                    this.userCreatedAdoptedProjects.push({ address: element.shorter_location ? element.shorter_location : element.project_location, name: element.project_name ? element.project_name : 'Name This Project', imageurl: element.project_images.length > 0 ? element.project_images[0].image_detail : 'assets/images/no-image.jpg', project_id: element.project_id, project_name_slug: element.project_name_slug });
                  }
                } else {
                  if (element.project_publish_status == 'Adopted' && element.created_by_id == this.user_id && element.is_published == true && element.co_owners.length == 0) {
                    this.userCreatedAdoptedProjects.push({ address: element.shorter_location ? element.shorter_location : element.project_location, name: element.project_name ? element.project_name : 'Name This Project', imageurl: element.project_images.length > 0 ? element.project_images[0].image_detail : 'assets/images/no-image.jpg', project_id: element.project_id, project_name_slug: element.project_name_slug });
                  }
                }
              });
            } else {
              this.noProjectsAdopted = true;
            }
          });

        });

        this.service.getPeopleProjects(params["id"]).then(res => {
          this.userProjects = res; //res.results;
          if (_.isEmpty(this.userProjects)) {
            this.noProjects = true;
          }
        });

        this.service.getProjectsAdoptedWithOthers(params['id'], false).then(res => {
          const arr: any = res;
          if (arr.length > 0) {
            arr.forEach(element => {
              this.adoptedWithOthersProjects.push({ address: element.shorter_location ? element.shorter_location : element.project_location, name: element.project_name ? element.project_name : 'Name This Project', imageurl: element.project_images.length > 0 ? element.project_images[0].image_detail : 'assets/images/no-image.jpg', project_id: element.project_id, project_name_slug: element.project_name_slug });
            });
          }
          // this.textboxedit = true;
        });
      });

    } catch (e) { }


    this.schoolTypeField = new FormControl();
    // this.schoolArray$ = 

    this.schoolArray$ = this.schoolTypeField.valueChanges
      .debounceTime(500)
      .distinctUntilChanged()
      .switchMap(term => this.profileService.getSchoolList(term));

  }

  setMeta(title, desc, img) {
    this.metaService.setTitle(title);
    this.metaService.updateTitle(title);
    this.metaService.updateDescription(desc)
    this.metaService.updateOgImage(img)

  }

  ngAfterViewInit() {
    console.log('baali', this.cile, this.service.isAdmin, this.service.teachingAssistant, this.ta_id);
  }

  gotoDashboard() {
    localStorage.setItem('ta_name', this.first_name + ' ' + this.last_name);
    this.router.navigate(['dashboard', this.user_id]);
  }

  matchExact(r, str) {
    console.log(r, str);
    var match = str.match(r);
    return match && str === match[0];
  }

  onChange(item: string, isChecked: boolean) {
    if (isChecked) {
      if (!_.includes(this.selectedLineOfWork, item)) {
        this.selectedLineOfWork.push(item);
      }
      if (item == 'Other')
        this.hasOtherLineOfWork = true;
      if (item == 'Other Engineer')
        this.hasOtherEngineer = true;
    } else {
      let index = this.selectedLineOfWork.indexOf(item);
      if (index != -1) {
        this.selectedLineOfWork.splice(index, 1);
        if (item == 'Other')
          this.hasOtherLineOfWork = false;
        if (item == 'Other Engineer')
          this.hasOtherEngineer = false;
      }
    }
    // this.selectedLineOfWork = _.uniq(this.selectedLineOfWork);
    console.log(this.selectedLineOfWork);
  }

  hasLineOfWork(item) {
    if (item == 'Other' && this.hasOtherLineOfWork) {
      return true;
    } else if (item == 'Other Engineer' && this.hasOtherEngineer) {
      return true;
    }
    if (_.includes(this.user_designation_arr, item)) {
      return true;
    }
    return false;
  }

  showAlert() {
    this.service.sendAlert('', 'This feature is available only for students enrolled in the RePicture Educational Program. Email info@RePicture.com if you would like more information about this program', 1);
  }

  showOther: boolean = false;
  otherReason: any;
  reason1: any;
  flaggedAlready: boolean = false;
  radioClick(reason) {
    this.reasonCode = reason;
    if (reason == "7") {
      this.showOther = true;
      this.reason1 = reason;
    } else {
      this.showOther = false;
      this.reason1 = reason;
    }
  }

  submitReport() {
    if (!localStorage.getItem("authtoken")) {
      this.service.sendAlert("Error", "Please login to continue!", 0);
      return;
    }
    if (this.showOther) {
      if (!this.otherReason) {
        //alert('Please enter your reason!');
        this.service.sendAlert("Error", "Please enter your reason!", 0);
        return;
      }
    } else {
      if (!this.reason1) {
        //alert('Please select one of your reason!');
        this.service.sendAlert("Error", "Please select one of your reason!", 0);
        return;
      }
    }
    let obj = {
      user: this.user_id,
      reason: this.reasonCode
    };

    if (this.reasonCode == 7) {
      obj["other_reason"] = this.otherReason;
    }

    this.service.flagPeople(obj).then(res => {
      // this.flaggedAlready = true;
      jQuery("#flagModal1").modal("hide");
      let x2 = document.getElementById("snackbar3");

      // Add the "show" class to DIV
      x2.className = "show";

      // After 3 seconds, remove the show class from DIV
      setTimeout(function () {
        x2.className = x2.className.replace("show", "");
      }, 3000);
    });
  }

  checkUser() {
    let id = this.location.path();
    let xid = id.split("/");
    // //console.log(id," choco iz ",xid);
    let id1 = xid[2];
    let id2 = localStorage.getItem("full_name_slug");
    //console.log('aunti', id1, " ", id2, this.is_public);
    if (id1 == id2 || this.is_public) {
      //console.log('cile', this.cile);
      this.cile = true;
    } else {
      this.cile = false;
    }
    //this.cile = true;
  }

  getId(url) {
    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);

    if (match && match[2].length == 11) {
      return match[2];
    } else {
      return "error";
    }
  }

  convertMedia(html, type) {
    var pattern1 = /(?:http?s?:\/\/)?(?:www\.)?(?:vimeo\.com)\/?(.+)/g;
    var pattern2 = /(?:http?s?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?(.+)/g;
    // var pattern3 = /([-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?(?:jpg|jpeg|gif|png))/gi;
    let pat1 = false;
    let pat2 = false;

    if (pattern1.test(html)) {
      pat1 = true;
      var replacement =
        '<iframe style="border: none; margin-top: 5px; width: 100%; height: 100%;" src="//player.vimeo.com/video/$1" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>';
      var html = html.replace(pattern1, replacement);
    }

    if (pattern2.test(html)) {
      pat2 = true;
      var id = this.getId(html);
      var replacement =
        '<iframe style="border: none; margin-top: 5px; width: 100%; height: 100%;" src="https://www.youtube.com/embed/' +
        id +
        '" allowfullscreen></iframe>';
      var html = html.replace(pattern2, replacement);
    }

    if (!pat1 && !pat2 && type == "patch") {
      //alert('Please enter only youtube or vimeo video URL');
      this.service.sendAlert(
        "Error",
        "Please enter only youtube or vimeo video URL",
        0
      );
      return false;
    }

    // if(pattern3.test(html)){
    //     var replacement = '<a href="$1" target="_blank"><img class="sml" src="$1" /></a><br />';
    //     var html = html.replace(pattern3, replacement);
    // }
    if (!pat1 && !pat2 && type == "get") {
      return;
    }

    if (type == 'get') {
      if (!this.appending) {
        this.external_video_iframe.push(this._sanitizer.bypassSecurityTrustHtml(html));
      }
      this.external_video_iframe_edit.push(this._sanitizer.bypassSecurityTrustHtml(html));
    } else {
      return this._sanitizer.bypassSecurityTrustHtml(html);
    }
  }

  shareDashboardAccess(email) {
    console.log(email);
    if (!email) {
      this.service.sendAlert('Error', 'You must enter an email of user registered on repicture', 0);
      return;
    }

    this.service.giveDashboardAccess(email).then(res => {
      this.service.sendAlert('Success', 'You have shared access of dashboard to ' + email, 1);
    });
  }

  confirmedAction($event) {
    switch ($event["tag"]) {
      case "external":
        this.confirmDeleteExternal($event["i"]);
        break;

      case "internal":
        this.confirmDeleteMedia($event["type"]);
        break;

      default:
        break;
    }
  }

  confirmDeleteExternal(i) {
    this.external_video.splice(i, 1);
    let body = {
      external_video: this.external_video
    };

    let people_id = sessionStorage.getItem("currentUserSlug");
    if (this.service.publicUser) {
      people_id = this.service.publicUser;
    }
    this.service.doUserProfilePatch(people_id, body).then(result => {
      this.external_video_iframe.splice(i, 1);
    });
  }

  deleteExternalMedia(i) {
    // this.confirmObject = {
    //   'confirmTitle': 'Do you want to delete this video?',
    //   'tag': 'external',
    //   'i': i,
    //   'type': ''
    // };
    console.log(this.external_video);
    this.external_video_iframe_edit.splice(i, 1);
    this.external_video_edit.splice(i, 1);
    console.log(this.external_video);
  }

  confirmDeleteMedia(type) {
    let body = {
      [type]: null
    };

    let people_id = sessionStorage.getItem("currentUserSlug");
    if (this.service.publicUser) {
      people_id = this.service.publicUser;
    }
    this.service.doUserProfilePatch(people_id, body).then(result => {
      if (type == "user_video") {
        this.video = null;
      } else if (type == "external_video") {
        this.external_video = null;
      } else {
        this.audio = null;
      }
    });
  }

  deleteMedia(type) {
    this.confirmObject = {
      confirmTitle: "Do you want to delete this file?",
      tag: "internal",
      i: "",
      type: ""
    };
  }

  changePrivacy() {
    //alert(this.privacy);
    let body = {
      is_private: this.privacy
    };

    //this.toggleVisibitly = true;
    let people_id = sessionStorage.getItem("currentUserSlug");
    if (this.service.publicUser) {
      people_id = this.service.publicUser;
    }
    this.service.doUserProfilePatch(people_id, body).then(result => {
      // if($event.target.value == "true"){
      //   this.stem = 'Yes';
      // }else{
      //   this.stem = 'No';
      // }
      // this.showwilling = false;
    });
  }

  changeWillingness($event) {
    if ($event.target.value == "true") {
      this.stem = 'Yes';
    } else {
      this.stem = 'No';
    }
    // let body = {
    //   "user_questions_willingness": $event.target.value
    // };

    // //this.toggleVisibitly = true;
    // let people_id = sessionStorage.getItem("currentUserSlug");
    // if (this.service.publicUser) {
    //   people_id = this.service.publicUser;
    // }
    // this.service.doUserProfilePatch(people_id, body).then(result => {
    //   if ($event.target.value == "true") {
    //     this.stem = 'Yes';
    //   } else {
    //     this.stem = 'No';
    //   }
    //   this.showwilling = false;
    // });

  }

  updateCareer($event) {
    this.career = "to be a " + $event;
  }

  mediaSelected1($event) {
    this.evt = $event;
  }

  mediaSelected($event) {
    let btnUpload = document.getElementById("btn");
    btnUpload.classList.remove("glyphicon-upload");
    btnUpload.classList.add("glyphicon-refresh");
    btnUpload.classList.add("glyphicon-refresh-animate");
    //console.log($event);
    let elem = $event.target.files[0];
    let type = $event.target.files[0].type.split('/')[0];
    this.service.setUserAudioVideo(sessionStorage.getItem("currentUserSlug"), elem).then(res => {
      //location.reload();
      if (type == 'audio') {
        this.audio = res['user_audio'];
      } else if (type == 'video') {
        this.video = res['user_video'];
      }
      this.evt = '';
      btnUpload.classList.add("glyphicon-upload");
      btnUpload.classList.remove("glyphicon-refresh");
      btnUpload.classList.remove("glyphicon-refresh-animate");
    });
  }

  abc: boolean = false;
  html: any;

  externalMediaSelected() {
    if (!this.convertMedia(this.videoURL, "patch")) {
      //console.log('cancel');
      return;
    }
    // let btnUpload = document.getElementById('btn1');
    // btnUpload.classList.remove("glyphicon-upload");
    // btnUpload.classList.add("glyphicon-refresh");
    // btnUpload.classList.add("glyphicon-refresh-animate");
    //this.html = this.convertMedia(this.videoURL);
    // //console.log($event);
    // let elem = $event.target.files[0];
    // let type = $event.target.files[0].type.split('/')[0];
    // let video = ''
    // let obj;
    // if (this.videoURL.indexOf('youtube') >= 0) {
    this.external_video_edit.push(this.videoURL);
    this.external_video_iframe_edit.push(this.convertMedia(this.videoURL, 'patch'));
    // let obj = {
    //   'external_video': this.external_video //.replace("watch?v=", "embed/")
    // };
    //   video = this.videoURL.replace("watch?v=", "embed/");
    // } else {
    //   let parts = this.videoURL.split('/');
    //   let lastPart = parts[parts.length - 1];
    //   obj = {
    //     'external_video': 'https://player.vimeo.com/video/'+lastPart
    //   };
    //   video = 'https://player.vimeo.com/video/'+lastPart;
    // }

    // console.log(this.videoURL.replace("watch?v=", "embed/"));
    // this.service.setUserExternalVideo(sessionStorage.getItem("currentUserSlug"), obj).then(res => {
    //   // this.external_video = this.convertMedia(this.videoURL);
    this.videoURL = '';
    this.abc = false;
    console.log(this.external_video_iframe, this.external_video_edit);
    //   btnUpload.classList.add("glyphicon-upload");
    //   btnUpload.classList.remove("glyphicon-refresh");
    //   btnUpload.classList.remove("glyphicon-refresh-animate");
    // });
  }

  goToProject(proj_id) {
    this.router.navigate(["/project", proj_id]);
  }

  checkEmptyProjects() {
    if (this.userProjects.length == 1) {
      this.noProjects = true;
    }
  }

  dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI
      .split(",")[0]
      .split(":")[1]
      .split(";")[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    //Old Code
    //write the ArrayBuffer to a blob, and you're done
    //var bb = new BlobBuilder();
    //bb.append(ab);
    //return bb.getBlob(mimeString);

    //New Code
    return new Blob([ab], { type: mimeString });
  }

  blobToFile = (theBlob: Blob, fileName: string): File => {
    var b: any = theBlob;
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModifiedDate = new Date();
    b.name = fileName;

    //Cast to a File() type
    return <File>theBlob;
  };

  Oncropped() {
    var imgcrp1 = this.dat.image;
    ////console.log(cropimg);
    // //console.log('image actual',imgcrp1);
    this.Onimage = false;
    // //console.log('image actual2', this.dataURItoBlob(imgcrp1));
    let blob = this.dataURItoBlob(imgcrp1);
    let myfile = this.blobToFile(blob, "my-image.png");
    // //console.log('image actual2', myfile);
    // return;
    // let peopleid;
    // if (this.is_public) {
    //   peopleid = this.service.publicUser;
    // } else {
    //   peopleid = window.localStorage.getItem('currentUserSlug');
    // }
    console.log(myfile);
    this.profilePic = myfile;
    console.log(document.getElementsByClassName('tempImg-grid')[0]);
    document.getElementsByClassName('tempImg')[0]['src'] = imgcrp1;
    // this.service.setPeoplePic(peopleid, myfile).then(res => {
    //   this.service.peoples = [];
    //   console.log(res);
    //   if (!this.is_public) {
    //     document.getElementsByClassName('img-grid')[0]['src'] = imgcrp1;
    //   }
    //   document.getElementsByClassName('img-grid')[1]['src'] = imgcrp1;
    // })
  }

  cropped(bounds: Bounds) {
    this.croppedHeight = bounds.bottom - bounds.top;
    this.croppedWidth = bounds.right - bounds.left;
    ////console.log(this.croppedHeight);
    // //console.log('hello',this.uploader.queue);
  }

  onCancel() {
    this.Onimage = false;
  }

  cile: boolean = false;

  checkLogin(param) {
    if (!localStorage.getItem("authtoken")) {
      //alert('You must create an account and log in to edit this profile.');
      this.service.sendAlert(
        "Error",
        "You must create an account and log in to edit this profile.",
        0
      );
      return;
    }
    // this.sameUser = true;
    if (param == "first") {
      this.textboxedit = !this.textboxedit;
      this.external_video_edit = _.clone(this.external_video);

      console.log(this.external_video_iframe.length, this.external_video_iframe_edit.length);
      if (this.external_video_iframe.length > this.external_video_iframe_edit.length) {
        this.appending = true;
        for (let i = 0; i < this.external_video.length; i++) {
          this.convertMedia(this.external_video[i], 'get');
        }
        this.appending = false;
      }
    }

    if (param == "second")
      this.textboxedit1 = !this.textboxedit1;

    if (param == "third")
      this.textboxedit2 = !this.textboxedit2;

      this.clickProjectOrder();
  }

  changeInLogin1($event) {
    this.loginStatus = $event;
    this.sameUser = false;
    if (!this.is_public) {
      this.cile = $event;
    }

    if ($event) {
      this.route.params.subscribe(params => {
        if (sessionStorage.getItem("currentUserSlug") == params['id']) {
          this.cile = true;
        }
      });
      try {
        this.service.getPeopleDetail(localStorage.getItem("currentUserSlug")).subscribe(res => {
          this.doneloading = true;
          this.ObjCollection.name.data = res['first_name'] + " " + res['last_name'];
          this.first_name = res['first_name'];
          this.last_name = res['last_name'];
          this.ObjCollection.user_designation.data = res['user_designation'];
          if (!_.isEmpty(res['user_designation'])) {
            this.career = "to be a " + res['user_designation'];
          } else {
            this.career = "my career";
          }
          this.ObjCollection.user_experience.data = res['user_experience'];
          this.ObjCollection.user_stories.data = res['user_stories'];
          this.ObjCollection.user_quotes.data = res['user_quotes'];
          this.ObjCollection.social_network.data = !_.isEmpty(res['socialNetworks']) ? res['socialNetworks'] : '';
          if (res['user_questions_willingness']) {
            this.stem = 'Yes';
          } else if (!res['user_questions_willingness']) {
            this.stem = 'No';
          }
          this.myStoryCollection.q1_ans.data = res["q1_ans"];
          this.myStoryCollection.q2_ans.data = res["q2_ans"];
          this.myStoryCollection.q3_ans.data = res["q3_ans"];
          this.myStoryCollection.q4_ans.data = res["q4_ans"];
          this.myStoryCollection.q5_ans.data = res["q5_ans"];
          this.myStoryCollection.q6_ans.data = res["q6_ans"];
          this.audio = res["user_audio"];
          this.video = res["user_video"];
          //this.service.setUserDetails(res);
          if (res['linkedin_signup']) {
            this.imagesrc = res['linkedinProfileImageURL'];
          } else if (res['profileImage_thumbnail_v1']) {
            this.imagesrc = res['profileImage_thumbnail_v1'];
          } else {
            this.imagesrc = "assets/images/default.png";
          }
          window.history.replaceState({}, "", "userprofile/" + this.user_id);
        });

        this.service.getPeopleProjects(this.user_id).then(res => {
          this.userProjects = res;
          if (_.isEmpty(this.userProjects)) {
            this.noProjects = true;
          }
        });


      } catch (e) { }
    } else {
      // setTimeout(() => {
      this.service.isAdmin = false;
      // }, 2000);
    }
  }

  claim() {
    if (localStorage.getItem("authtoken")) {
      //alert('You already have an account and profile.');
      this.service.sendAlert(
        "Error",
        "You already have an account and profile.",
        0
      );
      return;
    }
    // this.cookie.put('claimPersonId', this.user_id);
    // this.cookie.put('claimPersonName', this.ObjCollection.name.data);

    this.service.claimableObject.firstname = this.first_name;
    this.service.claimableObject.lastname = this.last_name;
    this.service.claimableObject.userid = this.user_id;
    // this.cookie.put('claimPersonRole', this.aboutUsCollection.org_role.data.toString());
    this.router.navigate(["/claimperson"]);
  }

  choosePic() {
    if (this.is_public && this.is_claimable && !this.service.isAdmin) {
      //alert('You must claim this profile to upload a picture.');
      this.service.sendAlert(
        "Error",
        "You must claim this profile to upload a picture.",
        0
      );
      return;
    }

    jQuery("#pickPhotoModal").modal("show");
  }

  putCertificates($event) {
    let _certifications = $event;
    if (_.includes(_certifications[0].certifications, 'Other')) {
      let index = _certifications[0].certifications.indexOf('Other');
      _certifications[0].certifications.splice(index, 1);
    }
    this.selected_certifications = []
    this.selected_certifications = _certifications;
  }

  putAwards($event) {
    this.collectionUserAwards = $event
  }

  putLinks($event) {
    this.collectionExternalLinks = $event
    console.log(this.collectionExternalLinks)
  }

  choose() {
    this.fileInput.nativeElement.click();
  }

  // fileChangeListener($event) {
  //   var image:any = new Image();
  //   var file:File = $event[0];
  //   var myReader:FileReader = new FileReader();
  //   var that = this;
  //   myReader.onloadend = (loadEvent:any)=> {
  //       image.src = loadEvent.target.result;
  //       ////console.log(this.cropper.cropcanvas.nativeElement);
  //       // this.cropper.cropcanvas.nativeElement.offsetWidth = '800';
  //       // this.cropper.cropcanvas.nativeElement.offsetHeight = '600';
  //       this.cropper.setImage(image);

  //   };

  //   myReader.readAsDataURL(file);
  //   }

  fileChange(event) {
    let file = event.target.files[0];
    if (file) {
      this.Onimage = true;
      var reader = new FileReader();
      reader.onload = e => {
        var image: any = new Image();
        image.src = e.target["result"];
        this.cropper.setImage(image);
      };

      reader.readAsDataURL(file);
    }
  }
  imageIsLoaded(e) {
    var image: any = new Image();
    image.src = e.target.result;
    this.cropper.setImage(image);
    // document.getElementsByClassName('img-grid')[0].src = e.target.result;
    // document.getElementsByClassName('img-grid')[1].src = e.target.result;
  }

  // updateSocialNetwork(type, url) {
  //   if (!url.trim()) {
  //     return;
  //   }
  //   let innerObj = {};
  //   innerObj[type] = url;

  //   let body = {
  //     [this.mx.fieldname]: innerObj
  //   };

  //   let people_id = sessionStorage.getItem("currentUserSlug");
  //   if (this.service.publicUser) {
  //     people_id = this.service.publicUser;
  //   }
  //   this.service.doUserProfilePatch(people_id, body).then(result => {

  //     //this.toggleVisibitly = false;
  //     try {
  //       document.getElementsByClassName('cmw')[0]['style'].display = 'inline-block';
  //     } catch (e) { console.log('hide edit btn try'); }

  //     if (this.mx.data) {
  //       this.mx.data[type] = url;
  //       // console.log('ss', this.mx.data);
  //       this.socialNetworkArr = this.createDemo(this.mx.data);
  //       // console.log('ss1', this.socialNetworkArr);
  //     } else {
  //       let obj = {};
  //       obj[type] = url;
  //       // console.log('ss', obj);
  //       this.socialNetworkArr = this.createDemo(obj);
  //       // console.log('ss1', this.socialNetworkArr);
  //       this.mx.data = obj;
  //     }

  //     let index = this.socialArr.indexOf(type);
  //     this.socialArr.splice(index, 1);

  //     if (this.other) {
  //       this.other = false;
  //     }

  //     if (this.socialArr.length == 1) {
  //       if (_.includes(this.socialArr, 'Other')) {
  //         this.other = true;
  //       }
  //     }

  //     this.EditToggle = false;
  //     this.showTexbox = true;
  //     // console.log(result);
  //   });
  // }

  // expChange(value) {
  //   console.log(value);
  //   let body = {
  //     [this.mx.fieldname]: value
  //   };

  //   //this.toggleVisibitly = true;
  //   let people_id = sessionStorage.getItem("currentUserSlug");
  //   if (this.service.publicUser) {
  //     people_id = this.service.publicUser;
  //   }
  //   this.service.doUserProfilePatch(people_id, body).then(result => {

  //     //this.toggleVisibitly = false;
  //     try {
  //       document.getElementsByClassName('cmw')[0]['style'].display = 'inline-block';
  //     } catch (e) { console.log('hide edit btn try'); }

  //     this.mx.data = value;
  //     this.EditToggle = false;
  //     this.showTexbox = true;
  //   });
  // }

  // addBirthDeath() {
  //   if (!this.death_year) {
  //     //alert('Please enter year of death');
  //     this.service.sendAlert('Error', 'Please enter year of death', 0);
  //     return;
  //   }
  //   let body = {
  //     dob: this.birth_year,
  //     dod: this.death_year
  //   };

  //   //this.toggleVisibitly = true;
  //   let people_id = sessionStorage.getItem("currentUserSlug");
  //   if (this.service.publicUser) {
  //     people_id = this.service.publicUser;
  //   }
  //   this.service.doUserProfilePatch(people_id, body).then(result => {
  //     if (!this.birth_year) {
  //       this.mx.data = '(d.' + this.death_year + ')';
  //     } else {
  //       this.mx.data = '(' + this.birth_year + ' - ' + this.death_year + ')';
  //     }
  //     this.EditToggle = false;
  //     this.showTexbox = true;
  //   });
  // }

  // socialChange(e) {
  //   if (e.target['value'] == 'Other') {
  //     this.other = true;
  //   } else {
  //     this.other = false;
  //   }
  // }

  // showText() {
  //   //this.varib = true;
  //   //console.log(this.varib);
  //   if (this.varib) {
  //     this.showTexbox = true;
  //     this.EditToggle = true;
  //   }
  // }


  // hideBtn() {
  //   document.getElementsByClassName('cmw')[0]['style'].display = 'none';
  // }

  // onEnterDesc() {
  //   let val = document.getElementById('desc1')['value'];
  //   console.log(this.fields == 'user_designation', val == 'Other', !this.otherClicked, 'fzfsd', );
  //   if (this.fields == 'user_designation' && val == 'Other' && !this.otherClicked) {
  //     return;
  //   }
  //   if (this.mx.fielname == 'user_designation', val == 'Other', this.otherClicked) {
  //     val = document.getElementById('desc2')['value'];
  //   }
  //   if (!val.trim()) {
  //     console.log("empty dala");
  //     return;
  //   }
  //   let body;
  //   if (this.mx.fieldname == 'name') {
  //     let fname = val.substr(0, val.indexOf(' ')); // "72"
  //     let lname = val.substr(val.indexOf(' ') + 1); // "tocirah sneab"
  //     body = {
  //       "first_name": fname,
  //       "last_name": lname
  //     };
  //   } else {
  //     body = {
  //       [this.mx.fieldname]: val
  //     };
  //   }

  //   //this.toggleVisibitly = true;
  //   let people_id = sessionStorage.getItem("currentUserSlug");
  //   if (this.service.publicUser) {
  //     people_id = this.service.publicUser;
  //   }
  //   this.service.doUserProfilePatch(people_id, body).then(result => {
  //     console.log(this.mx.fieldname, " ", 'user_designation');
  //     if (this.mx.fieldname == 'user_designation') {
  //       console.log('emit');
  //       this.service.peoples = [];
  //       // this.careerChanged.emit(val);
  //     }

  //     if (this.mx.fieldname == 'name') {
  //       this.service.peoples = [];
  //     }
  //     //this.toggleVisibitly = false;
  //     try {
  //       document.getElementsByClassName('cmw')[0]['style'].display = 'inline-block';
  //     } catch (e) { console.log('hide edit btn try'); }
  //     this.otherClicked = false;
  //     this.mx.data = val;
  //     this.EditToggle = false;
  //     this.showTexbox = true;





  //     console.log(result);
  //   });
  // }

  createDemo(mydemo: any): Array<demo> {
    const tempdemo: Array<demo> = [];

    // Caution: use "of" and not "in"
    for (const key of Object.keys(mydemo)) {
      tempdemo.push(
        { key: key, value: mydemo[key] }
      );
    }

    return tempdemo;
  }

  // deleteSocial(name, i) {
  //   let innerObj = {};
  //   innerObj['remove'] = name;

  //   let body = {
  //     [this.mx.fieldname]: innerObj
  //   };

  //   let people_id = sessionStorage.getItem("currentUserSlug");
  //   if (this.service.publicUser) {
  //     people_id = this.service.publicUser;
  //   }
  //   this.service.doUserProfilePatch(people_id, body).then(result => {
  //     this.EditToggle = false;
  //     this.socialNetworkArr.splice(i, 1);
  //     this.mx.data = _.omit(this.mx.data, name);
  //     if (_.includes(["Facebook", "LinkedIn", "Twitter"], name)) {
  //       this.socialArr.push(name);
  //     } else {
  //       this.socialArr.push('Other');
  //       if (this.socialArr.length == 1) {
  //         this.other = true;
  //       }
  //     }
  //   });
  // }

  // certificationChange($event) {
  //   this.selected_certifications = $event;
  //   if (_.includes($event, 'Other')) {
  //     this.is_prof_certi_other = true;
  //   } else {
  //     this.is_prof_certi_other = false;
  //   }
  // }

  degreesChange($event) {
    this.selected_degrees = $event;
    if (_.includes($event, 'Other')) {
      this.is_degree_other = true;
    } else {
      this.is_degree_other = false;
    }
  }

  // removeUserAwards(index) {
  //   this.collectionUserAwards.splice(index, 1);
  //   //console.log(this.collectionEducation);
  //   //return;
  //   let body = {
  //     [this.mx.fieldname]: this.collectionUserAwards
  //   };

  //   let people_id = sessionStorage.getItem("currentUserSlug");
  //   if (this.service.publicUser) {
  //     people_id = this.service.publicUser;
  //   }
  //   this.service.doUserProfilePatch(people_id, body).then(result => {
  //   });
  // }

  // addUserAward() {
  //   if (_.isEmpty(this.user_award_name)) {
  //     //alert('please enter award name');
  //     this.service.sendAlert('Error', 'please enter award name', 0);
  //     return;
  //   }

  //   let obj = {
  //     award_name: this.user_award_name,
  //     award_url: this.user_award_url
  //   };

  //   this.collectionUserAwards.push(obj);
  //   //console.log(this.collectionEducation);
  //   //return;

  //   let body = {
  //     [this.mx.fieldname]: this.collectionUserAwards
  //   };

  //   //this.toggleVisibitly = true;
  //   let people_id = sessionStorage.getItem("currentUserSlug");
  //   if (this.service.publicUser) {
  //     people_id = this.service.publicUser;
  //   }
  //   this.service.doUserProfilePatch(people_id, body).then(result => {
  //     this.mx.data = this.collectionUserAwards;
  //     this.EditToggle = false;
  //     this.showTexbox = true;
  //     this.user_award_url = '';
  //     this.user_award_name = '';
  //   });
  // }



  addEducation() {
    if (_.isEmpty(this.selected_degrees)) {
      //alert('please select degree(s)');
      this.service.sendAlert('Error', 'Please select degree(s)', 0);
      return;
    }

    if (this.is_degree_other) {
      if (_.includes(this.selected_degrees, 'Other')) {
        let index = this.selected_degrees.indexOf('Other');
        this.selected_degrees.splice(index, 1);
      }
      this.selected_degrees.push(this.degree_other);
    }

    let obj = {
      degrees: this.selected_degrees,
      school: this.school,
      field_of_study: this.field_of_study,
      year: this.year
    };
    // console.log(this.collectionEducation);

    this.collectionEducation.push(obj);
    this.selected_degrees = [];
    this.year = '';
    this.school = '';
    this.field_of_study = '';
    this.addmoreCliked = false;
    // return true;

    // let body = {
    //   user_designation: this.collectionEducation
    // };

    // //this.toggleVisibitly = true;
    // let people_id = sessionStorage.getItem("currentUserSlug");
    // if (this.service.publicUser) {
    //   people_id = this.service.publicUser;
    // }
    // this.service.doUserProfilePatch(people_id, body).then(result => {

    //   //this.toggleVisibitly = false;
    //   try {
    //     document.getElementsByClassName('cmw')[0]['style'].display = 'inline-block';
    //   } catch (e) { console.log('hide edit btn try'); }

    //   // this.mx.data = {
    //   //   degrees: this.selected_degrees,
    //   //   school: this.school,
    //   //   field_of_study: this.field_of_study,
    //   //   year: this.year
    //   // };
    //   // this.mx.data = this.collectionEducation;
    //   this.EditToggle = false;
    //   this.showTexbox = true;
    //   this.selected_degrees = [];
    //   this.year = '';
    //   this.school = '';
    //   this.field_of_study = '';
    // });
  }

  removeEducation(index) {
    this.collectionEducation.splice(index, 1);
    console.log(this.collectionEducation);
    //return;
    // let body = {
    //   user_education: this.collectionEducation
    // };

    // let people_id = sessionStorage.getItem("currentUserSlug");
    // if (this.service.publicUser) {
    //   people_id = this.service.publicUser;
    // }
    // this.service.doUserProfilePatch(people_id, body).then(result => {
    // });
  }

  // removeCertificate(index) {
  //   this.collectionCertificates.splice(index, 1);
  //   console.log(this.collectionCertificates);
  //   //return;
  //   let body = {
  //     [this.mx.fieldname]: this.collectionCertificates
  //   };

  //   let people_id = sessionStorage.getItem("currentUserSlug");
  //   if (this.service.publicUser) {
  //     people_id = this.service.publicUser;
  //   }
  //   this.service.doUserProfilePatch(people_id, body).then(result => {
  //   });
  // }

  // addCertification() {
  //   if (_.isEmpty(this.selected_certifications)) {
  //     //alert('please select certifications');
  //     this.service.sendAlert('Error', 'please select certifications', 0);
  //     return;
  //   }

  //   if (this.is_prof_certi_other) {
  //     if (_.includes(this.selected_certifications, 'Other')) {
  //       let index = this.selected_certifications.indexOf('Other');
  //       this.selected_certifications.splice(index, 1);
  //     }
  //     this.selected_certifications.push(this.prof_certi_other);
  //   }

  //   let obj = {
  //     certifications: this.selected_certifications,
  //     location: this.certi_location
  //   }

  //   this.collectionCertificates.push(obj);

  //   let body = {
  //     [this.mx.fieldname]: this.collectionCertificates
  //   };

  //   //this.toggleVisibitly = true;
  //   let people_id = sessionStorage.getItem("currentUserSlug");
  //   if (this.service.publicUser) {
  //     people_id = this.service.publicUser;
  //   }
  //   this.service.doUserProfilePatch(people_id, body).then(result => {

  //     //this.toggleVisibitly = false;
  //     try {
  //       document.getElementsByClassName('cmw')[0]['style'].display = 'inline-block';
  //     } catch (e) { console.log('hide edit btn try'); }

  //     this.mx.data = this.collectionCertificates;
  //     this.EditToggle = false;
  //     this.showTexbox = true;
  //     this.certi_location = '';
  //     this.selected_certifications = [];
  //   });
  // }

  onChangeExp(item) {
    console.log(item);
    this.user_experience = item;
  }

  save(action) {
    if (this.tab == 1) {
      if (this.hasOtherLineOfWork) {
        let index = this.selectedLineOfWork.indexOf('Other');
        this.selectedLineOfWork[index] = this.otherLOW;
      }
      if (this.hasOtherEngineer) {
        let index = this.selectedLineOfWork.indexOf('Other Engineer');
        this.selectedLineOfWork[index] = this.otherEngineers;
      }
      let obj = {};
      if (this.existingSocial.LinkedIn) {
        obj['LinkedIn'] = this.existingSocial.LinkedIn;
      }
      if (this.existingSocial.Facebook) {
        obj['Facebook'] = this.existingSocial.Facebook;
      }
      if (this.existingSocial.Twitter) {
        obj['Twitter'] = this.existingSocial.Twitter;
      }
      if (this.existingSocial.Other.displayText && this.existingSocial.Other.displayURL) {
        obj['Other'] = { displayText: this.existingSocial.Other.displayText, displayURL: this.existingSocial.Other.displayURL };
      }
      if (this.existingSocial.Instagram) {
        obj['Instagram'] = this.existingSocial.Instagram;
      }

      if ((this.addmoreCliked && this.selected_degrees.length > 0) || (this.collectionEducation.length == 0 && this.selected_degrees.length > 0)) {
        this.addEducation();
      }
      let body = {
        "user_education": this.collectionEducation,
        "first_name": this.first_name,
        "last_name": this.last_name,
        "user_designation": _.uniq(this.selectedLineOfWork).toString(),
        "socialNetworks": obj
      };
      // console.log(body); return;
      if (this.is_claimable) {
        body['user_experience'] = this.user_experience;
      } else {
        if (!this.death_year) {
          //alert('Please enter year of death');
          this.service.sendAlert('Error', 'Please enter year of death', 0);
          return;
        }
        body['dob'] = this.birth_year;
        body['dod'] = this.death_year;
      }

      // console.log(body, this.profilePic);

      let people_id = sessionStorage.getItem("currentUserSlug");
      if (this.service.publicUser) {
        people_id = this.service.publicUser;
      }
      this.service.doUserProfilePatch(people_id, body).then(res => {
        if (this.profilePic) {
          this.service.setPeoplePic(people_id, this.profilePic).then(result => {
            this.service.peoples = [];
            this.profilePic = null;
            this.imagesrc = this.dat.image;
            // this.service.sendAlert('Success', 'Profile details are updated successfully.', 1);
            if (action == 'exit') {
              this.textboxedit = !this.textboxedit;
            } else if (action == 'continue') {
              console.log('zero', $('#tab2'));
              $('#tab2')[0].click();
            }
          });
        }
        this.ObjCollection.name.data = res['first_name'] + " " + res['last_name'];
        this.first_name = res['first_name'];
        this.last_name = res['last_name'];
        this.ObjCollection.user_designation.data = res['user_designation'].replace(/,/g, ', ');
        let designation = '';
        if (this.ObjCollection.user_designation.data.length > 0 && this.ObjCollection.user_designation.data != null) {
          designation = ' | ' + res['user_designation'].split(',')[0];
        }
        this.user_designation_arr = _.uniq(res['user_designation'].split(','));
        this.selectedLineOfWork = [];
        for (let i = 0; i < this.user_designation_arr.length; i++) {
          if (_.includes(this.lineofwork, this.user_designation_arr[i])) {
            this.selectedLineOfWork.push(this.user_designation_arr[i]);
          } else if (this.user_designation_arr[i].match(/(^|\W)engineer($|\W)/i)) {
            this.hasOtherEngineer = true;
            this.otherEngineers = this.user_designation_arr[i];
            if (!_.includes(this.selectedLineOfWork, 'Other Engineer'))
              this.selectedLineOfWork.push('Other Engineer');
          } else {
            this.otherLOW = this.user_designation_arr[i];
            this.hasOtherLineOfWork = true;
            if (!_.includes(this.selectedLineOfWork, 'Other'))
              this.selectedLineOfWork.push('Other');
          }
        }
        let title = res["first_name"] + ' ' + res["last_name"] + designation;
        this.titleService.setTitle(title + ' | ' + this.service.project_name);
        this.ObjCollection.user_education.data = !_.isEmpty(res['user_education']) ? res['user_education'] : '';
        this.ObjCollection.social_network.data = !_.isEmpty(res['socialNetworks']) ? res['socialNetworks'] : '';
        if (!this.profilePic) {
          // this.service.sendAlert('Success', 'Profile details are updated successfully.', 1);
          if (action == 'exit') {
            this.textboxedit = !this.textboxedit;
          } else if (action == 'continue') {
            console.log('zero', $('#tab2'));
            $('#tab2')[0].click();
          }
        }
        if (this.is_claimable) {
          this.ObjCollection.user_experience.data = res['user_experience'];
        } else {
          if (!this.birth_year) {
            this.ObjCollection.birth_death.data = '(d.' + this.death_year + ')';
          } else {
            this.ObjCollection.birth_death.data = '(' + this.birth_year + ' - ' + this.death_year + ')';
          }
        }
      });
    } else if (this.tab == 2) {
      if (!this.is_public) {
        let tag = document.getElementById('q7_ans')['value'];
        let tags = [];
        if (tag) {
          let lastChar = tag.charAt(tag.length - 1);
          let firstChar = tag.charAt(0);
          if (lastChar == ',') {
            tag = tag.substr(0, tag.length - 1);
          }
          if (firstChar == ',') {
            tag = tag.slice(1, tag.length);
          }
          // tags = [];
          for (let i = 0; i < tag.split(',').length; i++) {
            tags.push(tag.split(',')[i].trim());
          }
        }
        // console.log(tags, this.myStoryCollection.q7_ans.data);
        tags = _.concat(tags, this.myStoryCollection.q7_ans.data);
        // console.log(tags);
        // return;
        let careerStatus = [];
        for (let i = 0; i < this.myStory.careerStatus.length; i++) {
          if (this.myStory.careerStatus[i].isLabel) {
            careerStatus.push(this.myStory.careerStatus[i].id);
          }
        }
        // if (thi)
        let awardname = document.getElementById('user_award_id')
        let awardurl = document.getElementById('user_award_url_id')
        if (awardname) {
          console.log("not hiddn");
          awardname = awardname['value']
          awardurl = awardurl['value']
          console.log(awardname, awardurl);
        }

        let exlinktitle = document.getElementById('link_title_id')
        let exlinkurl = document.getElementById('link_url_id')
        if (exlinktitle) {
          console.log("not hiddn");
          exlinktitle = exlinktitle['value']
          exlinkurl = exlinkurl['value']
          console.log(exlinktitle, exlinkurl);
        }
        if (awardname) {
          let obj = {
            award_name: awardname,
            award_url: awardurl ? awardurl : ""
          };

          this.collectionUserAwards.push(obj)
        } else if (!awardname && awardurl) {
          this.service.sendAlert('Error', 'please enter award name', 0);
          return;
        }

        if (exlinktitle && exlinkurl) {
          let obj = {
            link_title: exlinktitle,
            link_url: exlinkurl
          };
          this.collectionExternalLinks.push(obj)
        } else if (exlinktitle && !exlinkurl) {
          this.service.sendAlert('Error', 'please enter title and URL.', 0);

          return;
        } else if (!exlinktitle && exlinkurl) {
          this.service.sendAlert('Error', 'please enter title and URL.', 0);

          return;
        }



        let body = {
          "career_status": careerStatus,// this.myStory.selectedCareerStatus,
          "user_questions_willingness": this.stem == 'Yes' ? true : false,
          "professional_certification": this.selected_certifications,
          "technical_expertise": document.getElementById('technical_expertise')['value'] ? document.getElementById('technical_expertise')['value'] : '',
          "q8_ans": document.getElementById('q8_ans')['value'] ? document.getElementById('q8_ans')['value'] : '',
          "q9_ans": document.getElementById('q9_ans')['value'] ? document.getElementById('q9_ans')['value'] : '',
          "q2_ans": document.getElementById('q2_ans')['value'] ? document.getElementById('q2_ans')['value'] : '',
          "q3_ans": document.getElementById('q3_ans')['value'] ? document.getElementById('q3_ans')['value'] : '',
          "q4_ans": document.getElementById('q4_ans')['value'] ? document.getElementById('q4_ans')['value'] : '',
          "q5_ans": document.getElementById('q5_ans')['value'] ? document.getElementById('q5_ans')['value'] : '',
          "q6_ans": document.getElementById('q6_ans')['value'] ? document.getElementById('q6_ans')['value'] : '',
          "engineering_education_helped_me": document.getElementById('engineering_education_helped_me')['value'] ? document.getElementById('engineering_education_helped_me')['value'] : '',
          "user_team_experience": document.getElementById('user_team_experience')['value'] ? document.getElementById('user_team_experience')['value'] : '',
          "q1_ans": document.getElementById('q1_ans')['value'] ? document.getElementById('q1_ans')['value'] : '',
          "user_awards": this.collectionUserAwards,
          "user_quotes": document.getElementById('user_quotes')['value'] ? document.getElementById('user_quotes')['value'] : '',
          "q7_ans": tags,
          "external_links": this.collectionExternalLinks,
          "external_video": this.external_video_edit
        };

        console.log(body);
        let people_id = sessionStorage.getItem("currentUserSlug");
        if (this.service.publicUser) {
          people_id = this.service.publicUser;
        }
        this.service.doUserProfilePatch(people_id, body).then(res => {
          this.myStoryCollection.career_status.data = !_.isEmpty(res['career_status']) ? res['career_status'] : null;
          this.myStoryCollection.engineering_education_helped_me.data = res['engineering_education_helped_me'];
          this.myStoryCollection.user_team_experience.data = res['user_team_experience'];
          this.myStoryCollection.q1_ans.data = res['q1_ans'];
          this.myStoryCollection.q2_ans.data = res['q2_ans'];
          this.myStoryCollection.q3_ans.data = res['q3_ans'];
          this.myStoryCollection.q4_ans.data = res['q4_ans'];
          this.myStoryCollection.q5_ans.data = res['q5_ans'];
          this.myStoryCollection.q6_ans.data = res['q6_ans'];
          this.myStoryCollection.q7_ans.data = res['q7_ans'];
          this.myStoryCollection.q8_ans.data = res['q8_ans'];
          this.myStoryCollection.q9_ans.data = res['q9_ans'];
          this.myStoryCollection.technical_expertise.data = res['technical_expertise'];
          this.ObjCollection.professional_certification.data = !_.isEmpty(res['professional_certification']) ? res['professional_certification'] : '';
          this.ObjCollection.user_awards.data = !_.isEmpty(res['user_awards']) ? res['user_awards'] : '';
          this.ObjCollection.user_quotes.data = res['user_quotes'];
          this.myStoryCollection.external_links.data = res['external_links'];
          this.external_video = res['external_video'] ? res['external_video'] : [];
          this.external_video_iframe = [];
          this.external_video_iframe_edit = [];
          for (let i = 0; i < res['external_video'].length; i++) {
            this.convertMedia(res['external_video'][i], 'get');
          }
          this.cd.detectChanges();
          // this.service.sendAlert('Success', 'Profile details are updated successfully.', 1);
          // this.refresh();
          if (action == 'exit') {
            this.textboxedit = !this.textboxedit;
          } else if (action == 'continue') {
            $('#tab3')[0].click();
          }
        });
      } else {
        let people_id = sessionStorage.getItem("currentUserSlug");
        if (this.service.publicUser) {
          people_id = this.service.publicUser;
        }
        let body = {
          "about": document.getElementById('mystory_about')['value'] ? document.getElementById('mystory_about')['value'] : '',
          "q1_ans": document.getElementById('mystory_about')['value'] ? document.getElementById('mystory_about')['value'] : '',
          "technical_expertise": document.getElementById('technical_expertise')['value'] ? document.getElementById('technical_expertise')['value'] : '',
          "user_awards": this.collectionUserAwards,
          "external_links": this.collectionExternalLinks,
          "external_video": this.external_video_edit
        }
        this.service.doUserProfilePatch(people_id, body).then(res => {
          this.myStoryCollection.about.data = res['about'];
          this.myStoryCollection.technical_expertise.data = res['technical_expertise'];
          this.ObjCollection.user_awards.data = !_.isEmpty(res['user_awards']) ? res['user_awards'] : '';
          this.myStoryCollection.external_links.data = res['external_links'];
          this.external_video = res['external_video'] ? res['external_video'] : [];
          this.external_video_iframe = [];
          this.external_video_iframe_edit = [];
          for (let i = 0; i < res['external_video'].length; i++) {
            this.convertMedia(res['external_video'][i], 'get');
          }
          this.cd.detectChanges();
          // this.service.sendAlert('Success', 'Profile details are updated successfully.', 1);
          // this.refresh();
          if (action == 'exit') {
            this.textboxedit = !this.textboxedit;
          } else if (action == 'continue') {
            $('#tab3')[0].click();
          }
        });
      }
    } else if (this.tab == 3) {
      console.log("on tab 3", this.service.userprojectsindex.length);
      if (this.service.userprojectsindex.length > 0) {
        for (let i = 0; i < this.service.userprojectsindex.length; i++) {
          let btn_index = this.service.userprojectsindex[i];
          console.log(btn_index);
          $('.savebtn')[btn_index].click();
          if (i === this.service.userprojectsindex.length - 1) {
            console.log("here");
            // this.textboxedit = !this.textboxedit;
            // this.tab = 1;
            if (action == 'exit') {
              this.tab = 1;
              this.textboxedit = !this.textboxedit;
            } else if (action == 'continue') {
              $('#tab4')[0].click();
            }
          }
        }
      } else {
        // this.textboxedit = !this.textboxedit;
        // this.tab = 1;
        if (action == 'exit') {
          this.tab = 1;
          this.textboxedit = !this.textboxedit;
        } else if (action == 'continue') {
          $('#tab4')[0].click();
        }
      }
    } else {
      console.log("last call");

      this.service.setProjectsOrder(this.projectOrderComp.mainOrderingArray)
      this.textboxedit = !this.textboxedit;
      this.tab = 1;
    }
  }
  refresh(): void {
    let cloneMyStory = _.clone(this.myStoryCollection);
    this.myStoryCollection = cloneMyStory;

    let cloneBasicInfo = _.clone(this.ObjCollection);
    this.ObjCollection = cloneBasicInfo;
  }

  checkVideosChanges(event) {
    // alert('sf')
    console.log(event);
    this.userProjects[event.projectIndex]['project_people'][event.videosChangesIndex].external_video = event.updatedURL;
  }

  clickProjectOrder() {
    let obj = {};
    this.projectObj = {}

    obj['pr_user_ordering'] = this.userProjects
    obj['adopted'] = this.userCreatedAdoptedProjects
    obj['adopted_with_others'] = this.adoptedWithOthersProjects

    this.projectObj = obj
    console.log(this.projectObj);

  }

  // drop(event: CdkDragDrop<string[]>) {
  //   moveItemInArray(this.dragDropArray, event.previousIndex, event.currentIndex);
  // }
}


export class social_obj {
  Facebook: string;
  LinkedIn: string;
  Twitter: string;
  Other: otherSocial;
  Instagram: string;
}

export class otherSocial {
  displayText: string;
  displayURL: string;
}

interface demo {
  key: string;
  value: string;
}
