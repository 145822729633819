import { OrganizationCard } from './organization';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-org-card',
  templateUrl: './org-card.component.html',
  styleUrls: ['./org-card.component.scss']
})
export class OrgCardComponent implements OnInit {

  @Input() orgCardData? :OrganizationCard;

  constructor(private router:Router ) { }

  ngOnInit() {
  }

  goToOrg(slug){
    this.router.navigate(['/companyprofile/' + slug])
  }

}
