<navbar (changeInLogin)="changeInLogin1($event)"></navbar>
<div class="container-fluid" style="background-color: rgba(211, 211, 211, 0.68);">
    <div class="row" [ngStyle]="{'margin-bottom': service.mobilecheck() ? '-5px' : '0'}" style="background-color: white;">
        <div class="col-sm-12 topSection">
            <div class="col-sm-3 col-xs-12 img-profile" style="display: flex; align-items: center;">
                <img id="img-profile" src="{{imagesrc}}" class="img-responsive img-profile" alt="{{org_logo_alt}}"
                    style="flex-shrink: 0;">
                <span class="imgEdit glyphicon glyphicon-pencil" *ngIf="textboxedit" (click)="chooseFile($event);"
                    [ngClass]="{'enhanced-pencil': !service.isProfileEnhanced}"></span>
                <input type="file" #file (change)="fileChanged($event)" style="display: none;" />
            </div>
            <div class="col-sm-6 col-xs-12 orgDetails">
                <div style="width: 100%;">
                    <h1 *ngIf="!nameTextbox" class="txt-name" style="display: inline-block; margin-top: 0;">
                        {{aboutUsCollection.org_name.data}}</h1>
                    <i *ngIf="textboxedit && !nameTextbox" class="glyphicon glyphicon-pencil"
                        (click)="showText('nameTextbox')"></i>
                    <span *ngIf="nameTextbox">
                        <input type="text" #name placeholder="Enter organization name"
                            value="{{aboutUsCollection.org_name.data}}" style="margin-bottom: 10px;
            width: 50%;">
                        <i class="glyphicon glyphicon-ok" (click)="addName(name.value)"></i>
                    </span>
                    <div style="font-family: OpenSans-Semibold;">
                        <span *ngIf="!roleTextbox" style="color: #9e9e9e !important;">
                            <span *ngFor="let item of aboutUsCollection.org_role.data" class="roles">{{item}}</span>
                        </span>
                        <i *ngIf="textboxedit && !roleTextbox" class="glyphicon glyphicon-pencil"
                            (click)="showText('roleTextbox')"></i>
                        <div style="width: 50%; display: inline-block;" *ngIf="roleTextbox">
                            <my-dropdown [defaultText]="'Organization role'" [selectedOptions]="selectedRole"
                                [myOptions]="companyRoleOptions" (selectedArray)="onChangedd($event)"></my-dropdown>
                            <input style="margin-top: 5px;" type="text" *ngIf="otherRoleDialog && roleTextbox"
                                placeholder="Enter other role" [(ngModel)]="otherRoleDialogValue" />
                            <i *ngIf="roleTextbox" class="glyphicon glyphicon-ok" (click)="role()"
                                style="color: black;"></i>
                        </div>
                        <span style="color: #9e9e9e !important; padding-left: 10px;"
                            *ngIf="textboxedit && !ownershipTextbox && !aboutUsCollection.org_ownership_type.data">|&nbsp;&nbsp;Ownership</span>
                        <span style="color: #9e9e9e !important; padding-left: 10px;"
                            *ngIf="!ownershipTextbox && aboutUsCollection.org_ownership_type.data">|&nbsp;&nbsp;{{aboutUsCollection.org_ownership_type.data}}</span>
                        <i *ngIf="textboxedit && !ownershipTextbox" (click)="showText('ownershipTextbox')"
                            class="glyphicon glyphicon-pencil"></i>
                        <div style="width: 50%; display: inline-block; margin-left: 10px;"
                            *ngIf="textboxedit && ownershipTextbox">
                            <select class="form-control" style="width: auto; padding: 4px; display: inline-block;"
                                [(ngModel)]="selectedOwnershipType">
                                <option value="">Select</option>
                                <option *ngFor="let item of ownershipOptions" value="{{item}}">{{item}}</option>
                            </select>
                            <input style="margin-top: 5px;" type="text" *ngIf="selectedOwnershipType == 'Other'"
                                placeholder="Enter other ownership" [(ngModel)]="otherOwnershipValue" />
                            <i *ngIf="ownershipTextbox" class="glyphicon glyphicon-ok" (click)="ownership()"
                                style="color: black;"></i>
                        </div>
                        <span *ngIf="count == 1">&nbsp;&nbsp;|&nbsp;&nbsp;{{count}} follower</span>
                        <span *ngIf="count > 1">&nbsp;&nbsp;|&nbsp;&nbsp;{{count}} followers</span>

                    </div>
                    <p style="margin-top: 10px;">
                        <button *ngIf="!followed" type="button" class="btn btn-default btn-follow"
                            (click)="follow()">FOLLOW</button>
                        <button *ngIf="followed" type="button" class="btn btn-default btn-follow"
                            (click)="unfollow()">UNFOLLOW</button>
                        <button *ngIf="aboutUsCollection.org_website.data && !websiteTextbox" type="button"
                            class="btn btn-default btn-visit"
                            (click)="visitWebsite(aboutUsCollection.org_website.data)">VISIT WEBSITE</button>
                        <button *ngIf="!aboutUsCollection.org_website.data && textboxedit && !websiteTextbox"
                            type="button" class="btn btn-default btn-visit">Add
                            Website</button>
                        <i *ngIf="textboxedit && !websiteTextbox" class="glyphicon glyphicon-pencil"
                            (click)="showText('websiteTextbox')"></i>
                        <span *ngIf="websiteTextbox">
                            <input style="margin-left: 10px;" type="text" #website
                                value="{{aboutUsCollection.org_website.data}}" placeholder="http://example.com" />
                            <i class="glyphicon glyphicon-ok" (click)="addWebsite(website.value)"></i>
                        </span>
                    </p>
                    <div *ngIf="hasSocial" style="margin-top: 20px;font-family: open-regular">
                        <div *ngIf="!EditToggle1" style="display: flex; flex-wrap: wrap;">
                            Find us on:
                            <span class="types" *ngFor="let item of socialNetworkArr; let i = index;">
                                <a target="_blank" href="{{ item.value }}"
                                    style="color: rgb(70, 165, 247); text-decoration: none;">{{item.key}}</a>
                                <i *ngIf="textboxedit" class="glyphicon glyphicon-minus-sign"
                                    (click)="deleteSocial(item.key, i)" style="color: black"></i>
                            </span>
                            <span *ngIf="textboxedit && socialNetworkArr?.length < 6"
                                (click)="EditToggle1 = !EditToggle1;" class="addmore">Add
                                more</span>
                        </div>
                        <div *ngIf="EditToggle1">
                            Find us on:
                            <span class="types" *ngFor="let item of socialNetworkArr; let i = index;">
                                <a target="_blank" href="{{ item.value }}"
                                    style="color: rgb(70, 165, 247); text-decoration: none;">{{item.key}}</a>
                                <i *ngIf="textboxedit" class="glyphicon glyphicon-minus-sign"
                                    (click)="deleteSocial(item.key, i)" style="color: black"></i>
                            </span>
                            <div style="margin: 20px 0;font-family: open-regular;">
                                <select #selSocial style="margin-bottom: 5px;" (change)="socialChange($event)"
                                    class="form-control txt-select" style="width: 30%;">
                                    <option *ngFor="let item of socialArr" value="{{item}}">{{item}}</option>
                                </select>
                                <span *ngIf="!other">
                                    <input id="socialURL" #socialURL type="text" class="txt-data1"
                                        placeholder="Enter Complete URL">
                                    <i class="glyphicon glyphicon-ok"
                                        (click)="updateSocialNetwork(selSocial.value, socialURL.value)"></i>
                                </span>
                                <div *ngIf="other">
                                    <input #socialTextOther type="text" class="txt-data1"
                                        placeholder="Enter text to display">
                                    <input id="socialURL" #socialURL type="text" class="txt-data1"
                                        placeholder="Enter Complete URL">
                                    <i class="glyphicon glyphicon-ok"
                                        (click)="updateSocialNetwork(socialTextOther.value, socialURL.value)"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!hasSocial && cile" style="margin-top: 20px;font-family: open-regular">
                        <p style="font-style: italic;">Edit to add social network
                            <i *ngIf="textboxedit" class="glyphicon glyphicon-pencil"
                                (click)="showText('hasSocial')"></i>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-sm-3 col-xs-12 editBtn">
                <i *ngIf="!cile" class="glyphicon glyphicon-flag" data-toggle="modal" data-target="#flagModal2"
                    style="color: gray;vertical-align: bottom; font-size: 20px; margin-left: 5px; cursor: pointer;"></i>
                <div *ngIf="cile" style="text-align: right; margin-right: 30px;">
                    <div style="display: inline-block;">
                        <button *ngIf="!textboxedit" (click)="checkLogin('first')" class="editToggle">Edit Profile
                            <i class="glyphicon glyphicon-pencil"></i>
                        </button>
                        <button *ngIf="textboxedit"
                            (click)="textboxedit = false;websiteTextbox = false; nameTextbox = false; socialTextbox = false; EditToggle1 = false;roleTextbox=false;ownershipTextbox=false;hiringTextbox=false;checkSocial(); setRoles();"
                            class="editToggle1 btn btn-default cmw">Cancel</button>
                        <button *ngIf="textboxedit"
                            (click)="textboxedit = false;websiteTextbox = false; nameTextbox = false; socialTextbox = false; EditToggle1 = false;roleTextbox=false;ownershipTextbox=false;hiringTextbox=false;checkSocial(); setRoles();"
                            class="editToggle cmw">Done</button>
                    </div>
                    <i class="glyphicon glyphicon-flag" data-toggle="modal" data-target="#flagModal2"
                        style="color: gray;vertical-align: bottom; font-size: 20px; margin-left: 5px; cursor: pointer; top: -7px;"></i>
                </div>
                <div *ngIf="!claimed && !isUniversity" class="claimthisprof">
                    <button class="btn btn-danger" style="background-color: red;" (click)="claim()">Is this your
                        firm?</button>
                    <p style="font-size:12px; width: 152px; margin-bottom: 0; margin-top: 5px;">Claim this profile to
                        control the content.
                    </p>
                </div>
                <div *ngIf="!service.isProfileEnhanced && claimed">
                    <button *ngIf="cile" class="btn btn-default upgrade" data-toggle="modal"
                        data-target="#enhancedModal">Upgrade to Enhanced Profile</button>
                    <button *ngIf="service.isAdmin" class="btn btn-default upgrade" style="top: 90px;"
                        (click)="upgradeForFree()">Upgrade for free</button>
                    <!-- <p style="font-size:12px; width: 152px;">Upgrade to an Enhanced Profile to unlock the Enhanced Profile fields.</p> -->
                </div>
            </div>
            <div *ngIf="isUniversity" class="col-sm-3 col-xs-12 univ-btns">
                <div>
                    <button *ngIf="univ_students.length > 0" class="btn btn-default upgrade-2" data-toggle="modal" data-target="#univ_modal"
                        (click)="univModal = 'Meet Students'; list = univ_students;">Meet Students
                        ({{univ_students.length}})</button>
                    <button *ngIf="univ_alumni.length > 0" class="btn btn-default upgrade-2" data-toggle="modal" data-target="#univ_modal"
                        (click)="univModal = 'Meet Alumni'; list = univ_alumni;">Meet Alumni
                        ({{univ_alumni.length}})</button>
                    <!-- <button class="btn btn-default upgrade-2" routerLink="/chat/{{UnivChatURL}}">Chat</button> -->
                </div>
            </div>
            <div *ngIf="textboxedit || selectedHiring?.length > 0" class="hiring col-xs-12">
                <button class="btn btn-default" (click)="openHiringURL()"
                    style="font-family: OpenSans-Semibold; border: none;"
                    [ngStyle]="{'background-color': selectedHiring ? '#ffe27e' : 'white'}">We're Hiring!</button>
                <span *ngIf="hiringTextbox"> : </span>
                <i *ngIf="textboxedit && !hiringTextbox" (click)="checkHiring($event)"
                    class="glyphicon glyphicon-pencil" [ngClass]="{'enhanced-pencil': !service.isProfileEnhanced}"></i>
                <span class="hiringSpan" *ngIf="!hiringTextbox">
                    <span class="hire" *ngFor="let item of selectedHiring">{{item}}</span>
                </span>
                <my-dropdown *ngIf="textboxedit && hiringTextbox" [defaultText]="'Hirings'"
                    [selectedOptions]="selectedHiring" [myOptions]="companyHirings"
                    (selectedArray)="onChangedd1($event)"></my-dropdown>
                <input *ngIf="textboxedit && hiringTextbox" type="text" [(ngModel)]="hiringURL"
                    placeholder="URL for hiring page" style="margin-left: 5px;" />
                <i *ngIf="textboxedit && hiringTextbox" (click)="saveHirings()" class="glyphicon glyphicon-ok"></i>
            </div>
        </div>
    </div>
    <div class="row">
        <div *ngIf="!claimed && !user && !isUniversity"
            style="background: white; padding: 6px; color: black;text-align: left; padding-left: 15px;
            margin-top: 10px; border: 2px solid #999;margin-left: 3%; margin-right: 3%;">
            <p style="font-family: OpenSans-ExtraBold; margin-bottom: 5px; font-size: 18px;"><span style="text-transform: uppercase;">{{aboutUsCollection.org_name.data}}</span> is an unclaimed page</p>
            <p style="font-family: OpenSans-Regular; margin: 0;">Only a current employee of <span style="text-transform: uppercase;">{{aboutUsCollection.org_name.data}}</span> may claim this page, Know someone who can claim this page? Let them know to claim this page.</p>
        </div>
        <!-- <div *ngIf="!claimed && user"
            style="background: red; font-style: italic; padding: 6px; color: white;text-align: center;">
            This profile is publicly owned and was created by a {{ service.project_name }} contributor.
            If this is your firm, use the above "is this your firm" button to control the content.</div> -->
        <div class="content-txt1">
            <div class="sectionsParent">
                <div class="col-sm-5 scroll"
                    style="background-color: white; padding: 15px 25px;height:100vmin;overflow-y:auto; margin-top: 20px; margin-bottom: 20px;">
                    <div class="div-pad_btm">
                        <!-- <h4 *ngIf="claimed" class="my-stry">About Us</h4> -->
                        <h4 *ngIf="!claimed" class="my-stry">About</h4>
                        <div *ngIf="cile" style="float: right;">
                            <button *ngIf="!textboxedit1" (click)="checkLogin('second')" class="editToggle"
                                style="float: right;">Edit
                                <i class="glyphicon glyphicon-pencil"></i>
                            </button>
                            <button *ngIf="textboxedit1" (click)="textboxedit1 = !textboxedit1"
                                class="editToggle1 btn btn-default cmw" style="float: right;">Cancel</button>
                            <button *ngIf="textboxedit1" (click)="textboxedit1 = !textboxedit1" class="editToggle cmw"
                                style="float: right;">Done</button>
                        </div>
                        <div *ngIf="stem == 'Yes' && isUniversity" style="float:right; margin-right: 5px;">
                            <button class="btn btn-default" (click)="askClicked()"
                                style="float: right;padding: 4px 8px;font-family: OpenSans-SemiBold;">Ask
                                Us a Question</button>
                        </div>
                    </div>
                    <div style="font-family: open-regular;">
                        <div *ngIf="!cile && aboutUsCollection.org_audio_video.data">
                            <!-- <p style="font-family: OpenSans-Bold;">
                Add a Video
              </p> -->
                            <p class="content-gray">
                                <companyprofiletextbox [mx]="aboutUsCollection.org_audio_video" [edit]="textboxedit1">
                                </companyprofiletextbox>
                            </p>
                        </div>
                        <div *ngIf="cile">
                            <p *ngIf="!aboutUsCollection.org_audio_video.data.org_about_video && !aboutUsCollection.org_audio_video.data.org_external_video" style="font-family: OpenSans-Bold;">
                                Add a Video
                            </p>
                            <p class="content-gray">
                                <companyprofiletextbox [mx]="aboutUsCollection.org_audio_video" [edit]="textboxedit1">
                                </companyprofiletextbox>
                            </p>
                        </div>
                        <div *ngIf="!cile && aboutUsCollection.org_photos.data">
                            <!-- <p style="font-family: OpenSans-Bold;">
                Add a Photo
              </p> -->
                            <p class="content-gray">
                                <companyprofiletextbox [mx]="aboutUsCollection.org_photos" [edit]="textboxedit1">
                                </companyprofiletextbox>
                            </p>
                        </div>
                        <div *ngIf="cile">
                            <p *ngIf="!aboutUsCollection.org_photos.data" style="font-family: OpenSans-Bold;">
                                Add a Photo
                            </p>
                            <p class="content-gray">
                                <companyprofiletextbox [mx]="aboutUsCollection.org_photos" [edit]="textboxedit1">
                                </companyprofiletextbox>
                            </p>
                        </div>
                        <div *ngIf="cile">
                            <p style="font-family: OpenSans-Bold;">
                                Is your organization willing to answer questions from kids in participating STEM
                                programs?
                                <a style="font-family: OpenSans-Light;" data-toggle="collapse"
                                    data-target="#learnMoreText"> Learn more</a>
                            </p>
                            <p class="content-gray">
                                <span *ngIf="showwilling && textboxedit1" style="display: block;">
                                    <label class="radio-inline">
                                        <input type="radio" name="optradio1" (change)="changeWillingness($event)"
                                            value="true" [checked]="stem == 'Yes'">Yes</label>
                                    <label class="radio-inline">
                                        <input type="radio" name="optradio1" (change)="changeWillingness($event)"
                                            value="false" [checked]="stem == 'No'">No</label>
                                </span>
                                <span style="display: block; font-family: OpenSans-Regular;"
                                    *ngIf="!showwilling || !textboxedit1">{{stem}}
                                    <i *ngIf="textboxedit1" class="glyphicon glyphicon-pencil"
                                        [ngClass]="{'enhanced-pencil': !service.isProfileEnhanced}"
                                        (click)="checkWilling($event);"></i>
                                </span>
                            </p>
                        </div>
                        <p id="learnMoreText" class="collapse" style="background-color:#FFE089; padding:10px;">
                            Our education collaborators are working on programs to implement the app in STEM classrooms
                            and clubs for kids and teens nationally and around the world to learn about how engineering
                            shapes our every day lives. Kids and teens in these programs will be
                            invited to connect with engineers through the app so they can meet and interact with
                            professionals in their fields of interest. Please contact us to learn more about the program
                            and how you can participate.
                        </p>
                        <div *ngIf="!cile && aboutUsCollection.org_overview.data">
                            <p style="font-family: OpenSans-Bold;">
                                About Us
                            </p>
                            <p class="content-gray">
                                <companyprofiletextbox [mx]="aboutUsCollection.org_overview" [edit]="textboxedit1">
                                </companyprofiletextbox>

                            </p>
                        </div>
                        <div *ngIf="cile">
                            <p style="font-family: OpenSans-Bold;">
                                About Us
                            </p>
                            <p class="content-gray">
                                <companyprofiletextbox [mx]="aboutUsCollection.org_overview" [edit]="textboxedit1">
                                </companyprofiletextbox>

                            </p>
                        </div>
                        <div *ngIf="!cile && aboutUsCollection.org_mission.data">
                            <p style="font-family: OpenSans-Bold;">
                                Organization Mission
                            </p>
                            <p class="content-gray">
                                <companyprofiletextbox [mx]="aboutUsCollection.org_mission" [edit]="textboxedit1">
                                </companyprofiletextbox>

                            </p>
                        </div>
                        <div *ngIf="cile">
                            <p style="font-family: OpenSans-Bold;">
                                Organization Mission
                            </p>
                            <p class="content-gray">
                                <companyprofiletextbox [mx]="aboutUsCollection.org_mission" [edit]="textboxedit1">
                                </companyprofiletextbox>

                            </p>
                        </div>
                        <div *ngIf="!cile && aboutUsCollection.org_awards_distinctions.data">
                            <p style="font-family: OpenSans-Bold;">
                                Organization Awards and Distinctions
                            </p>
                            <p class="content-gray">
                                <companyprofiletextbox [mx]="aboutUsCollection.org_awards_distinctions"
                                    [edit]="textboxedit1"></companyprofiletextbox>
                                <!-- <br> -->
                            </p>
                        </div>
                        <div *ngIf="cile">
                            <p style="font-family: OpenSans-Bold;">
                                Organization Awards and Distinctions
                            </p>
                            <p class="content-gray">
                                <companyprofiletextbox [mx]="aboutUsCollection.org_awards_distinctions"
                                    [edit]="textboxedit1"></companyprofiletextbox>
                                <!-- <br> -->
                            </p>
                        </div>
                        <div *ngIf="!cile && aboutUsCollection.org_headquarters_location.data">
                            <p style="font-family: OpenSans-Bold;">
                                Headquarters Location
                            </p>
                            <p class="content-gray">
                                <companyprofiletextbox [mx]="aboutUsCollection.org_headquarters_location"
                                    [edit]="textboxedit1"></companyprofiletextbox>
                                <!-- <br> -->
                            </p>
                        </div>
                        <div *ngIf="cile">
                            <p style="font-family: OpenSans-Bold;">
                                Headquarters Location
                            </p>
                            <p class="content-gray">
                                <companyprofiletextbox [mx]="aboutUsCollection.org_headquarters_location"
                                    [edit]="textboxedit1"></companyprofiletextbox>
                                <!-- <br> -->
                            </p>
                        </div>
                        <div *ngIf="!cile && aboutUsCollection.org_size.data">
                            <p style="font-family: OpenSans-Bold;">
                                Organization Size
                            </p>
                            <p class="content-gray">
                                <companyprofiletextbox [mx]="aboutUsCollection.org_size" [edit]="textboxedit1">
                                </companyprofiletextbox>

                            </p>
                        </div>
                        <div *ngIf="cile">
                            <p style="font-family: OpenSans-Bold;">
                                Organization Size
                            </p>
                            <p class="content-gray">
                                <companyprofiletextbox [mx]="aboutUsCollection.org_size" [edit]="textboxedit1">
                                </companyprofiletextbox>

                            </p>
                        </div>
                        <div *ngIf="!cile && aboutUsCollection.org_year_founded.data">
                            <p style="font-family: OpenSans-Bold;">
                                Year Founded
                            </p>
                            <p class="content-gray">
                                <companyprofiletextbox [mx]="aboutUsCollection.org_year_founded" [edit]="textboxedit1">
                                </companyprofiletextbox>

                            </p>
                        </div>
                        <div *ngIf="cile">
                            <p style="font-family: OpenSans-Bold;">
                                Year Founded
                            </p>
                            <p class="content-gray">
                                <companyprofiletextbox [mx]="aboutUsCollection.org_year_founded" [edit]="textboxedit1">
                                </companyprofiletextbox>

                            </p>
                        </div>
                        <div *ngIf="!cile && aboutUsCollection.org_offered_services.data">
                            <p style="font-family: OpenSans-Bold;">
                                Services Provided
                                <span *ngIf="textboxedit1" style="font-size:12px; color:gray;">(List key services, limit
                                    300
                                    characters.)</span>
                            </p>
                            <p class="content-gray">
                                <companyprofiletextbox [mx]="aboutUsCollection.org_offered_services"
                                    [edit]="textboxedit1"></companyprofiletextbox>

                            </p>
                        </div>
                        <div *ngIf="cile">
                            <p style="font-family: OpenSans-Bold;">
                                Services Provided
                                <span *ngIf="textboxedit1" style="font-size:12px; color:gray;">(List key services, limit
                                    300
                                    characters.)</span>
                            </p>
                            <p class="content-gray">
                                <companyprofiletextbox [mx]="aboutUsCollection.org_offered_services"
                                    [edit]="textboxedit1"></companyprofiletextbox>

                            </p>
                        </div>
                        <div *ngIf="!cile && aboutUsCollection.org_certification.data">
                            <p style="font-family: OpenSans-Bold;">
                                Certifications
                            </p>
                            <p class="content-gray">
                                <companyprofiletextbox [mx]="aboutUsCollection.org_certification" [edit]="textboxedit1">
                                </companyprofiletextbox>

                            </p>
                        </div>
                        <div *ngIf="cile">
                            <p style="font-family: OpenSans-Bold;">
                                Certifications
                            </p>
                            <p class="content-gray">
                                <companyprofiletextbox [mx]="aboutUsCollection.org_certification" [edit]="textboxedit1">
                                </companyprofiletextbox>

                            </p>
                        </div>
                        <!-- <div *ngIf="!cile && aboutUsCollection.org_about.data">
              <p style="font-family: OpenSans-Bold;">
                We want others to know...
              </p>
              <p class="content-gray">
                <companyprofiletextbox [mx]="aboutUsCollection.org_about" [edit]="textboxedit1"></companyprofiletextbox>
              </p>
            </div>
            <div *ngIf="cile">
              <p style="font-family: OpenSans-Bold;">
                We want others to know...
              </p>
              <p class="content-gray">
                <companyprofiletextbox [mx]="aboutUsCollection.org_about" [edit]="textboxedit1"></companyprofiletextbox>
              </p>
            </div> -->
                        <!-- <div *ngIf="!cile && aboutUsCollection.org_certification.data">
                <p style="font-family: OpenSans-Bold;">
                  Certifications
                </p>
                <p class="content-gray">
                  <companyprofiletextbox [mx]="aboutUsCollection.org_certification" [edit]="textboxedit1"></companyprofiletextbox>
                </p>
              </div>
              <div *ngIf="cile">
                <p style="font-family: OpenSans-Bold;">
                  Certifications
                </p>
                <p class="content-gray">
                  <companyprofiletextbox [mx]="aboutUsCollection.org_certification" [edit]="textboxedit1"></companyprofiletextbox>
                </p>
              </div> -->
                        <div *ngIf="!cile && aboutUsCollection.pe_inhouse.data">
                            <p style="font-family: OpenSans-Bold;">
                                Licensed Professional Engineer(s) in house?
                            </p>
                            <p class="content-gray">
                                <companyprofiletextbox [mx]="aboutUsCollection.pe_inhouse" [edit]="textboxedit1">
                                </companyprofiletextbox>
                            </p>
                        </div>
                        <div *ngIf="cile">
                            <p style="font-family: OpenSans-Bold;">
                                Licensed Professional Engineer(s) in house?
                            </p>
                            <p class="content-gray">
                                <companyprofiletextbox [mx]="aboutUsCollection.pe_inhouse" [edit]="textboxedit1">
                                </companyprofiletextbox>

                            </p>
                        </div>
                        <div *ngIf="!cile && aboutUsCollection.org_our_commitments.data">
                            <p style="font-family: OpenSans-Bold;">
                                Our Commitments
                            </p>
                            <p class="content-gray">
                                <app-commitmentscomponent [mx]="aboutUsCollection.org_our_commitments"
                                    [edit]="textboxedit1"></app-commitmentscomponent>
                            </p>
                        </div>
                        <div *ngIf="cile">
                            <p style="font-family: OpenSans-Bold;">
                                Our Commitments
                            </p>
                            <p class="content-gray">
                                <app-commitmentscomponent [mx]="aboutUsCollection.org_our_commitments"
                                    [edit]="textboxedit1"></app-commitmentscomponent>
                            </p>
                        </div>
                        <div *ngIf="!cile && aboutUsCollection.our_leadership_team.data">
                            <p style="font-family: OpenSans-Bold;">
                                Our Leadership Team
                            </p>
                            <p class="content-gray">
                                <companyprofiletextbox [mx]="aboutUsCollection.our_leadership_team"
                                    [edit]="textboxedit1"></companyprofiletextbox>
                            </p>
                        </div>
                        <div *ngIf="cile">
                            <p style="font-family: OpenSans-Bold;">
                                Our Leadership Team
                            </p>
                            <p class="content-gray">
                                <companyprofiletextbox [mx]="aboutUsCollection.our_leadership_team"
                                    [edit]="textboxedit1"></companyprofiletextbox>
                            </p>
                        </div>
                        <div *ngIf="!cile && aboutUsCollection.org_story.data">
                            <p style="font-family: OpenSans-Bold;">
                                This is Our Story...
                            </p>
                            <p class="content-gray">
                                <companyprofiletextbox [mx]="aboutUsCollection.org_story" [edit]="textboxedit1">
                                </companyprofiletextbox>
                            </p>
                        </div>
                        <div *ngIf="cile">
                            <p style="font-family: OpenSans-Bold;">
                                This is Our Story...
                            </p>
                            <p class="content-gray">
                                <companyprofiletextbox [mx]="aboutUsCollection.org_story" [edit]="textboxedit1">
                                </companyprofiletextbox>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 scroll"
                    style="overflow-y:auto; background-color: white; padding: 15px 25px; margin-left: 20px;height:100vmin; margin-top: 20px; margin-bottom: 20px;">
                    <h4 class="prjct-wored" style="display: inline-block;">Projects</h4>
                    <div class="prjct-wored" *ngIf="noProjects">No projects yet!</div>
                    <div *ngIf="cile && !noProjects" style="float: right;">
                        <button *ngIf="!textboxedit2 && !noProjects" (click)="checkLogin('third')" class="editToggle"
                            style="float: right;">Edit
                            <i class="glyphicon glyphicon-pencil"></i>
                        </button>
                        <button *ngIf="textboxedit2" (click)="textboxedit2 = !textboxedit2;"
                            class="editToggle1 btn btn-default cmw" style="float: right;">Cancel</button>
                        <button *ngIf="textboxedit2" data-toggle="modal" data-target="#DoneConfirm" class="editToggle cmw" style="float: right;">Done</button>
                    </div>
                    <div *ngIf="cile && service.projectInfoShow1"
                        style="padding-top:20px; background-color: #ffe27e;padding-right: 10px;padding-left: 10px;">
                        <i style="float: right;" class="glyphicon glyphicon-remove"
                            (click)="service.projectInfoShow1 = !service.projectInfoShow1"></i>
                        <p style="font-size: 13px;">Add {{aboutUsCollection.org_name.data}} in the team section on any
                            project and the project will automatically show up here.
                        </p>
                        <p style="font-size: 13px; padding-bottom: 20px;">
                            <span style="cursor: pointer; text-decoration: underline;"
                                (click)="service.explorerSection = 'projects'" routerLinkActive="active"
                                routerLink="/explorer/projects">Browse
                                the explorer</span> to find projects or
                            <span style="cursor: pointer; text-decoration: underline;" routerLinkActive="active"
                                routerLink="/createproject">upload
                                a project here</span>.</p>
                    </div>
                    <div *ngIf="!noProjects">
                        <div id="contain" *ngFor="let item of companyProjects; let i = index">
                            <div id="projects{{i}}">
                                <companyprojects (checkProjectLength)="checkEmptyProjects($event);" [mx]="item"
                                    [edit]="textboxedit2" [currentProject]="i"></companyprojects>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

<div id="flagModal2" class="modal fade" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content" style="border-radius: 0px;">
            <div class="modal-body">
                <h4>Report content violation</h4>
                <br>
                <div class="form-group">
                    <label class="radio-inline">
                        <input type="radio" (click)="radioClick('1')" name="flagReason"> Violent or repulsive
                        content</label>
                </div>
                <div class="form-group">
                    <label class="radio-inline">
                        <input type="radio" (click)="radioClick('2')" name="flagReason"> Hateful or abusive
                        content</label>
                </div>
                <div class="form-group">
                    <label class="radio-inline">
                        <input type="radio" (click)="radioClick('3')" name="flagReason"> Promotes terrorism</label>
                </div>
                <div class="form-group">
                    <label class="radio-inline">
                        <input type="radio" (click)="radioClick('4')" name="flagReason"> Spam or misleading</label>
                </div>
                <div class="form-group">
                    <label class="radio-inline">
                        <input type="radio" (click)="radioClick('5')" name="flagReason"> Infringes my rights</label>
                </div>
                <div class="form-group">
                    <label class="radio-inline">
                        <input type="radio" (click)="radioClick('6')" name="flagReason"> Caption issue</label>
                </div>
                <div class="form-group">
                    <label class="radio-inline">
                        <input type="radio" (click)="radioClick('7')" name="flagReason"> Other</label>
                </div>
                <div *ngIf="showOther" class="form-group">
                    <input type="text" class="form-control" [(ngModel)]="otherReason" placeholder="Other reason" />
                </div>
                <p>
                    Flagged content will be reviewed by staff and appropriate action will be taken.
                </p>
                <br>
                <br>
                <div style="text-align: right;">
                    <button class="btn btn-default" data-dismiss="modal">Cancel</button>&nbsp;
                    <button class="btn btn-primary" (click)="submitReport();">Submit Report </button>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="univ_modal" class="modal fade" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content" style="border-radius: 0px; max-height: calc(100vh - 60px); overflow-y: auto;">
            <div class="modal-header" style="background-color: #FFE089;">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <h4 class="modal-title">{{univModal}}</h4>
            </div>
            <div class="modal-body" style="padding: 10px;">
                <div *ngIf="list.length > 0">
                    <div class="univ-cell" *ngFor="let item of list" (click)="gotoUser(item.full_name_slug)">
                        <div class="user-dp">
                            <img onError="this.src='assets/images/default.jpeg'" src="{{item.profileImage}}" />
                        </div>
                        <div class="user-info">
                            <p>{{item.full_name}} ({{item.edu}})</p>
                            <p>{{item.user_designation}}</p>
                        </div>
                    </div>
                </div>
                <div *ngIf="list.length == 0">
                    <p class="text-center">No data present for {{univModal}}</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="enhancedModal" class="modal fade" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content" style="border-radius: 0px;">
            <div class="modal-header" style="background-color: #FFE089;">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <h4 class="modal-title">Upgrade to an
                    <b>Enhanced Profile</b>
                </h4>
            </div>
            <div class="modal-body">
                <div class="text-center bodymain">
                    <p>What's included:</p>
                    <p class="logostext">
                        <img src="assets/images/Check3.png" class="check" />Add your logo</p>
                    <p class="logostext">
                        <img src="assets/images/Check3.png" class="check" />Show that you're hiring</p>
                    <p class="logostext">
                        <img src="assets/images/Check3.png" class="check" />Access more fields</p>
                    <div class="more-fields">
                        <p>Video and photos</p>
                        <p>Awards and distinctions</p>
                        <p>Contributions and giving back</p>
                        <p>Mission and story</p>
                    </div>
                </div>
                <p class="rate">Low introductory rate of $199 for 6 months</p>
                <div class="text-center">
                    <button class="btn btn-default upgradeBtn" (click)="upgrade()">Upgrade Now</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="DoneConfirm" class="modal fade" role="dialog" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog">
        <div class="modal-content" style="border-radius: 0px;">
            <div class="modal-header" style="background-color: #FFE089;">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <h4 class="modal-title">Confirm</h4>
            </div>
            <div class="modal-body">
                <p>Have you saved all changes by clicking the "Save" button next to project name?</p>
            </div>
            <div class="modal-footer">
                <button class="btn btn-default" data-dismiss="modal">No</button>
                <button class="btn btn-primary" data-dismiss="modal" (click)="doneModelYesClicked();">Yes</button>
            </div>
        </div>
    </div>
</div>

<div id="snackbar3">Thank you for helping to keep this site positive.</div>

<footer></footer>
<style>
.univ-cell {
        display: flex;
        padding: 10px;
        cursor: pointer;
    }

    .univ-cell:hover {
        background-color: #f5f5f5;
    }

    .univ-btns {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .univ-btns div {
        display: flex;
        flex-direction: column;
    }

    .univ-btns button {
        background-color: skyblue;
        font-size: 17px;
        color: white;
        outline: none;
        border: none;
        margin: 5px;
    }

    .user-dp img {
        height: 50px;
        width: 50px;
    }

    .user-info {
        margin-left: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .user-info p {
        margin: 0;
    }

    .user-info p:first-child {
        color: rgb(70, 165, 247);
    }
    .hiring {
        bottom: 0;
        position: absolute;
        right: 57px;
        width: auto;
    }

    .orgDetails {
        display: flex;
        align-items: center;
    }

    .topSection {
        margin-bottom: 15px;
        margin-top: 15px;
        display: flex;
        min-height: 175px;
    }

    .upgradeBtn {
        background-color: #4A90E2;
        color: white;
        font-size: 21px;
        font-family: OpenSans-Bold;
        border-radius: 10px;
        padding: 6px 18px;
        outline: none;
        border: none;
    }

    .rate {
        text-align: center;
        font-size: 15px;
        font-family: OpenSans-SemiBold;
        padding-top: 20px;
        padding-bottom: 30px;
    }

    .more-fields {
        margin-left: 28%;
        text-align: left;
    }

    .more-fields p {
        margin-bottom: 15px !important;
    }

    .logostext {
        margin-left: 15%;
        text-align: left;
    }

    .check {
        width: 35px;
        vertical-align: bottom;
        margin-right: 10px;
    }

    .bodymain {
        font-size: 18px;
        font-family: open-regular;
    }

    .bodymain p {
        margin-bottom: 20px;
    }

    .modal-title {
        /* font-size: 22px;
        font-family: open-regular;
        text-align: center; */
    }

    .modal-body {
        /* padding: 30px; */
    }

    .upgrade {
        background-color: skyblue;
        font-size: 17px;
        color: white;
        top: 50px;
        right: 45px;
        position: absolute;
        outline: none;
        border: none;
    }

    .enhanced-pencil {
        background: skyblue;
        padding: 6px;
        border-radius: 50%;
        color: black;
        margin-left: 5px;
    }

    #snackbar3 {
        visibility: hidden;
        /* Hidden by default. Visible on click */
        min-width: 250px;
        /* Set a default minimum width */
        margin-left: -125px;
        /* Divide value of min-width by 2 */
        background-color: #333;
        /* Black background color */
        color: #fff;
        /* White text color */
        text-align: center;
        /* Centered text */
        border-radius: 2px;
        /* Rounded borders */
        padding: 16px;
        /* Padding */
        position: fixed;
        /* Sit on top of the screen */
        z-index: 1;
        /* Add a z-index if needed */
        left: 50%;
        /* Center the snackbar */
        bottom: 30px;
        /* 30px from the bottom */
    }

    #snackbar.show {
        visibility: visible;
        -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
        animation: fadein 0.5s, fadeout 0.5s 2.5s;
    }

    #snackbar2.show {
        visibility: visible;
        -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
        animation: fadein 0.5s, fadeout 0.5s 2.5s;
    }

    #snackbar3.show {
        visibility: visible;
        -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
        animation: fadein 0.5s, fadeout 0.5s 2.5s;
    }

    @-webkit-keyframes fadein {
        from {
            bottom: 0;
            opacity: 0;
        }

        to {
            bottom: 30px;
            opacity: 1;
        }
    }

    @keyframes fadein {
        from {
            bottom: 0;
            opacity: 0;
        }

        to {
            bottom: 30px;
            opacity: 1;
        }
    }

    @-webkit-keyframes fadeout {
        from {
            bottom: 30px;
            opacity: 1;
        }

        to {
            bottom: 0;
            opacity: 0;
        }
    }

    @keyframes fadeout {
        from {
            bottom: 30px;
            opacity: 1;
        }

        to {
            bottom: 0;
            opacity: 0;
        }
    }

    :host ::ng-deep .dropdown {
        width: auto !important;
    }

    :host ::ng-deep .dropdown .dropdown-menu {
        min-width: 300px !important;
    }

    .roles:not(:last-child):after {
        content: "|";
        padding: 0 10px;
    }

    .hire {
        font-family: open-regular;
    }

    .hire:before {
        content: "\2022";
        color: gray;
        padding: 0 10px;
    }

    .intrinsic-container {
        position: relative;
        height: 0;
        overflow: hidden;
    }

    .intrinsic-container-16x9 {
        padding-bottom: 56.25%;
    }

    .intrinsic-container-4x3 {
        padding-bottom: 75%;
    }

    .intrinsic-container iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .glyphicon-refresh-animate {
        -webkit-animation: spin2 .7s infinite linear;
        animation: spin2 .7s infinite linear;
    }

    @-webkit-keyframes spin2 {
        from {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }

        to {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    @Keyframes spin {
        from {
            transform: scale(1) rotate(0deg);
        }

        to {
            transform: scale(1) rotate(360deg);
        }
    }

    .addmore {
        color: #14578a;
        font-size: 12px;
        cursor: pointer;
        margin-left: 10px;
    }

    .txt-data1 {
        height: 21px;
    }

    .txt-select {
        width: 30%;
        display: inline-block;
        height: 23px;
        font-size: 14px;
        color: black;
        padding: 0px 5px;
        border: 1px solid #ccc;
        margin-bottom: 5px;
    }

    .types:not(:last-of-type):after {
        content: "|";
        padding: 0 5px;
    }

    .imgEdit {
        position: absolute;
        left: 190px;
        top: 130px;
    }

    .txt3 {
        margin: 0 0 1px;
        font-family: open-regular;
        color: #818181;
        font-size: 12px;
    }

    .co-details {
        padding-top: 20px;
    }

    .yellow-color {
        color: #ffc500;
        font-family: OpenSans-Bold;
        font-size: 13px;
    }

    .co-para {
        width: 90%;
        font-family: open-regular;
        font-size: 12px;
        color: #818181;
    }

    .co-txt {
        margin: 0 0 3px;
        font-size: 12px;
        font-family: OpenSans-Bold;
        color: #545454;
    }

    li {
        padding-top: 1px;
        font-size: 12px;
        font-family: open-regular;
        color: #818181;
    }

    .images-content {
        /*padding-left: 28px;*/
        height: 350px;
        overflow-y: scroll;
        padding-top: 25px;
    }

    .img-second-title {
        margin-top: -10px;
        padding-bottom: 14px;
        font-family: open-regular;
        color: #898989;
        font-size: 13px;
    }

    .img-title {
        font-family: OpenSans-Bold;
        color: #777777;
        font-size: 13px;
    }

    .company-details {
        margin-top: 8%;
    }

    .img-profile {
        /*height: 150px;*/
        max-width: 100%;
        max-height: 100%;
    }

    .btn-visit {
        font-size: 13px;
        margin-left: 10px;
        font-family: OpenSans-Bold;
    }

    .btn-follow {
        font-size: 13px;
        background-color: #ffe27e;
        border: none;
        color: #030200;
        font-family: OpenSans-Bold;
    }

    .txt-name {
        color: #747474;
        font-family: open-regular;
    }

    .title-top3 {
        color: #555555;
        padding-left: 27px;
        font-family: OpenSans-Bold;
        font-size: 12px;
    }

    .title-top2 {
        padding-left: 27px;
        color: #898989;
        font-size: 13px;
        padding-bottom: 10px;
    }

    .title-top1 {
        font-family: OpenSans-Semibold;
        color: #767676;
        padding-left: 27px;
        font-size: 18px;
        margin: 4px 0 1px;
    }

    .msg-content {
        padding-left: 27px;
        color: #828282;
        font-size: 12px;
        font-family: open-regular;
    }

    @font-face {
        font-family: open-regular;
        src: url('/assets/fonts/new/opensans-regular-webfont.eot?#iefix') format('embedded-opentype'),
            /* IE */
            url('/assets/fonts/new/opensans-regular-webfont.ttf') format("truetype");
        /* non-IE */
    }

    @font-face {
        font-family: OpenSans-Semibold;
        src: url('/assets/fonts/new/opensans-semibold-webfont.eot?#iefix') format('embedded-opentype'),
            /* IE */
            url('/assets/fonts/new/opensans-semibold-webfont.ttf') format("truetype");
        /* non-IE */
    }

    @font-face {
        font-family: OpenSans-Bold;
        src: url('/assets/fonts/new/opensans-bold-webfont.eot?#iefix') format('embedded-opentype'),
            /* IE */
            url('/assets/fonts/new/opensans-bold-webfont.ttf') format("truetype");
        /* non-IE */
    }
</style>
<style>
    .content-gray {
        color: gray;
        font-size: 13px;
        margin-bottom: 20px;
    }

    div.fadeMe {
        opacity: 1;
        filter: alpha(opacity=20);
        background-color: #000;
        width: 100%;
        height: 100%;
        z-index: 10;
        top: 0;
        left: 0;
        position: fixed;
        text-align: center;
    }

    .not-selectable {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .backgroundPhotoText {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        font-weight: 600;
        color: #656565;
        font-family: OpenSans-Regular;
        cursor: pointer;
    }

    .editToggle {
        border-radius: 4px;
        background-color: #ffe27e;
        border: none;
        height: 30px;
        font-family: OpenSans-Bold;
        font-size: 0.9em;
    }

    .editToggle1 {
        border: none;
        font-size: 12px;
        height: 30px;
        background-color: transparent;
        font-family: OpenSans-Bold;
        color: #656565;
        padding: 0 2px
    }

    #input {
        position: absolute;
        margin-top: -200px;
    }

    .imgDiv {
        position: absolute;
        margin-left: 30px;
        margin-top: -10px;
    }

    .editPic {
        position: absolute;
        top: 160px;
        left: 179px;
        text-align: center;
        background-color: #ffe27e;
        width: 30px;
        height: 30px;
    }

    .navbar {
        margin-bottom: 0 !important;
    }

    .exp-desc {
        color: #6d6d6d;
        font-size: 13px;
    }

    .usr-name {
        font-family: open-regular;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .para-span {
        color: #444343;
        font-family: open-regular;
    }

    .para-title {
        padding-left: 30px;
        font-size: 13px;
        color: grey;
    }

    .stry-btn {
        background-color: #ffe27e;
        border: none;
        height: 30px;
        font-family: OpenSans-Bold;
        font-size: 0.9em;
    }

    .my-stry {
        display: inline-block;
        font-family: open-regular;
    }

    .div-pad_btm {
        padding-bottom: 25px;
    }

    .title-top3 {
        padding-left: 27px;
        font-family: OpenSans-Bold;
        font-size: 12px;
    }

    .title-top2 {
        padding-left: 27px;
        color: #898989;
        font-size: 13px;
        overflow-y: auto;
    }

    .title-top1 {
        font-family: OpenSans-Semibold;
        color: #767676;
        padding-left: 27px;
        font-size: 18px;
        margin: 0 0 1px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .water-res {
        font-family: OpenSans-Semibold;
        color: #808080;
        margin: 0 0 0px;
        font-size: 14px;
    }

    .desc-top {
        padding-top: 55px;
        font-family: open-regular;
    }

    .msg-content {
        padding-left: 27px;
        color: #828282;
        font-size: 12px;
        padding-right: 9px;
        font-family: open-regular;
    }

    .img-second-title {
        margin-top: -10px;
        padding-bottom: 14px;
        font-family: open-regular;
        color: #898989;
        font-size: 13px;
    }

    .img-title {
        font-family: OpenSans-Bold;
        color: #777777;
        font-size: 13px;
    }

    .text {
        display: inline-block;
        width: calc(100% - 130px);
    }

    .img-grid {
        border: 5px solid #aae7c8;
        background-color: white;
    }

    .row-grid {
        border-bottom: 2px solid #aae7c8;
    }

    @media only screen and (max-width: 768px) {
        .img-grid {
            margin-bottom: 0px;
        }
    }

    .popover.right>.arrow:after {
        border-right-color: #ebebeb;
    }

    .popover-content {
        background-color: #ebebeb;
    }

    @font-face {
        font-family: open-regular;
        src: url('/assets/fonts/new/opensans-regular-webfont.eot?#iefix') format('embedded-opentype'),
            /* IE */
            url('/assets/fonts/new/opensans-regular-webfont.ttf') format("truetype");
        /* non-IE */
    }

    @font-face {
        font-family: OpenSans-Light;
        src: url('/assets/fonts/new/opensans-light-webfont.eot?#iefix') format('embedded-opentype'),
            /* IE */
            url('/assets/fonts/new/opensans-light-webfont.ttf') format("truetype");
        /* non-IE */
    }

    @font-face {
        font-family: OpenSans-Semibold;
        src: url('/assets/fonts/new/opensans-semibold-webfont.eot?#iefix') format('embedded-opentype'),
            /* IE */
            url('/assets/fonts/new/opensans-semibold-webfont.ttf') format("truetype");
        /* non-IE */
    }

    @font-face {
        font-family: OpenSans-Bold;
        src: url('/assets/fonts/new/opensans-bold-webfont.eot?#iefix') format('embedded-opentype'),
            /* IE */
            url('/assets/fonts/new/opensans-bold-webfont.ttf') format("truetype");
        /* non-IE */
    }

    .prjct-wored {
        font-family: open-regular;
        padding-bottom: 15px;
    }

    .editBtn {
        text-align: right;
        margin-right: 30px;
    }

    .sectionsParent {
        display: flex;
        justify-content: center;
    }

    .claimthisprof {
        margin-top: 10px;
        text-align: left;
        float: right;
    }

    @media only screen and (max-width: 768px) {
        .prjct-wored {
            margin-top: 0px;
        }

        .topSection {
            display: unset !important;
        }

        .orgDetails {
            margin: 10px 0;
        }

        .hiring {
            position: static;
            width: 100% !important;
        }

        .editBtn {
            margin-bottom: 10px;
            margin-top: 10px;
        }

        .hiringSpan {
            display: block;
            padding: 5px 0;
        }

        .scroll {
            height: auto !important;
            margin-bottom: 0 !important;
            margin-left: 0 !important;
        }

        .sectionsParent {
            display: unset !important;
        }

        .sectionsParent div:first-child {
            margin-top: 0 !important;
        }

        .claimthisprof p {
            width: auto !important;
        }
    }

    .arrow-left {
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-right: 10px solid blue;
    }

    .scroll::-webkit-scrollbar {
        width: 5px;
    }

    .scroll::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px #888;
    }

    .scroll::-webkit-scrollbar-thumb {
        background: #808080;
    }

    textarea,
    .textareaClass {
        max-width: 500px;
        width: 90%;
        height: 100px;
        -moz-border-bottom-colors: none;
        -moz-border-left-colors: none;
        -moz-border-right-colors: none;
        -moz-border-top-colors: none;
        background: none repeat scroll 0 0 rgba(0, 0, 0, 0.07);
        border-color: -moz-use-text-color #FFFFFF #FFFFFF -moz-use-text-color;
        border-image: none;
        border-radius: 6px 6px 6px 6px;
        border-style: none solid solid none;
        border-width: medium 1px 1px medium;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12) inset;
        color: #555555;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 1em;
        line-height: 1.4em;
        padding: 5px 8px;
        transition: background-color 0.2s ease 0s;
    }

    textarea:focus,
    textareaClass:focus {
        background: none repeat scroll 0 0 #FFFFFF;
        outline-width: 0;
    }
</style>