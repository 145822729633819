import { InputDirective } from './../../directives/input-directive/input.directive';
import { Component, ContentChild, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss']
})
export class FormFieldComponent implements OnInit {

  @ContentChild(InputDirective, { static: true })
  myDirective: InputDirective;

  @Input() label: String;

  constructor() { }

  ngOnInit() {
    if (!this.myDirective) {
      throw new Error('MyInputDirective is required!')
    }
  }

  get errorMessage(): string | null {
    const touched = this.myDirective.ngControl.control.touched;
    const errors = Object.entries(
      this.myDirective.ngControl.control.errors || {}
    );
    if (!errors.length) {
      return null; 
    }
    if (touched) {
      const [key, value] = errors[0];
      return ERROR_MESSAGES[key];
    }
    return null;
  }

}

export const ERROR_MESSAGES = {
  required: 'This field is required',
  minlength: 'The entered value is too short',
  maxlength: 'The entered value is too long',
  email: 'Please enter valid email',
  passwordpattern: 'Password must be 6 or more characters and include at least one uppercase letter, one lowercase letter, and one number or special character',
  invalidEmail:'Please enter valid email',
  noSpaceAllowed :'No space allowed',
  invalidCode :'This isn’t a valid code.'
}
