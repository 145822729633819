<navbar></navbar>
<div style="margin-bottom: 50px;">
    <div *ngIf="!loginStatus" class="col-sm-8 col-sm-offset-2">
        <div class="text-center">
            <h4 style="color: gray;font-family: OpenSans-Semibold;font-size: 16px;">You must set up a login to claim your profile.
            </h4>
        </div>
        <br>
        <br>
        <div class="col-md-8 col-md-offset-2 signupbox">
            <h4 style="color: gray;font-family: OpenSans-Semibold;font-size: 21px;">Be part of something bigger</h4>
            <h5>Get started - it's free</h5>
            <br>
            <br>
            <div style="width: 80%; margin: 0 auto;">
                <p>
                    <input type="text" class="form-control" [(ngModel)]="user.first_name" placeholder="Enter first name" />
                </p>
                <p>
                    <input type="text" class="form-control" placeholder="Enter last name" [(ngModel)]="user.last_name" />
                </p>
                <p>
                    <input type="email" class="form-control" placeholder="Enter email" [(ngModel)]="user.email" />
                </p>
                <p>
                    <input id="pwd" type="password" class="form-control" placeholder="Enter password" [(ngModel)]="user.password" autocomplete="off" pattern="(?=^.{6,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$" />
                </p>
                <p>
                    <input id="pwd1" type="password" class="form-control" placeholder="Enter confirm password" [(ngModel)]="user.confirm_password" autocomplete="off" pattern="(?=^.{6,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$" />
                </p>
                <p>
                    <label class="checkbox-inline">
            <input type="checkbox" [(ngModel)]="me" [disabled]="true" />Yes - This is me</label>
                </p>
                <!-- <p>
          <button class="btn btn-default login" (click)="claimPerson()">Join Now</button>
        </p> -->
                <p class="gray">
                    <input type="checkbox" [(ngModel)]="terms" style="margin-right: 5px;vertical-align: sub; width: 15px; height: 15px;">I agree to the <span class="link" data-toggle="modal" data-target="#data_terms">account setup and data terms</span>.
                </p>
                <!-- <p class="gray">
                    <input type="checkbox" [(ngModel)]="agelimit" style="margin-right: 5px;vertical-align: sub; width: 15px; height: 15px;"><span class="link" data-toggle="modal" data-target="#data_18">I am age 18 years or older</span>.
                </p> -->
                <p>
                    <button class="btn btn-default login" (click)="claimPerson()">Join Now</button>
                </p>
            </div>
        </div>
        <div class="col-md-8 col-md-offset-2" style="padding-bottom: 20px; padding-left: 0; padding-right: 0;">
            <span class="gray" style="font-size: 12px; font-style: italic;"> (Note: Password must be 6 or more characters and
        include at least one uppercase letter, one lowercase letter, and one number or special character)</span>
        </div>
        <div id="data_terms" class="modal fade" role="dialog">
            <div class="modal-dialog">
                <div class="modal-content" style="border-radius: 0px;">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                        <h4 class="modal-title">Account Setup and Data Terms</h4>
                    </div>
                    <div class="modal-body" [innerHTML]="data">
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-default" data-dismiss="modal">OK</button>
                    </div>
                </div>
            </div>
        </div>
        <div id="data_18" class="modal fade" role="dialog">
            <div class="modal-dialog">
                <div class="modal-content" style="border-radius: 0px;">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                        <h4 class="modal-title">Information</h4>
                    </div>
                    <div class="modal-body">
                        You may join if you are under 18 years old. However, your profile will typiically not be published.
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-default" data-dismiss="modal">OK</button>
                    </div>
                </div>
            </div>
        </div>
        <style>
            .gray {
                color: gray;
            }
            
            .signupbox {
                background-color: #d6d6d6;
                text-align: center;
                padding: 18px;
            }
            
            .login {
                background-color: #ffe284;
                margin-top: -2px;
                color: gray;
            }
            
            .link {
                color: #4688F0;
                text-align: center;
                cursor: pointer;
            }
            
            .modal-header {
                background-color: #ffe284;
            }
            
            sup {
                color: red;
            }
            
            .createOrganizationcontent {
                padding: 20px;
                background-color: lightgray;
                margin-top: 20px;
            }
            
            @font-face {
                font-family: OpenSans-Regular;
                src: url('/assets/fonts/new/opensans-regular-webfont.eot?#iefix') format('embedded-opentype'), /* IE */
                url('/assets/fonts/new/opensans-regular-webfont.ttf') format("truetype");
                /* non-IE */
            }
            
            @font-face {
                font-family: OpenSans-Light;
                src: url('/assets/fonts/new/opensans-light-webfont.eot?#iefix') format('embedded-opentype'), /* IE */
                url('/assets/fonts/new/opensans-light-webfont.ttf') format("truetype");
                /* non-IE */
            }
            
            @font-face {
                font-family: OpenSans-Bold;
                src: url('/assets/fonts/new/opensans-bold-webfont.eot?#iefix') format('embedded-opentype'), /* IE */
                url('/assets/fonts/new/opensans-bold-webfont.ttf') format("truetype");
                /* non-IE */
            }
            
            @font-face {
                font-family: OpenSans-ExtraBold;
                src: url('/assets/fonts/new/opensans-extrabold-webfont.eot?#iefix') format('embedded-opentype'), /* IE */
                url('/assets/fonts/new/opensans-extrabold-webfont.ttf') format("truetype");
                /* non-IE */
            }
            
            @font-face {
                font-family: OpenSans-Semibold;
                src: url('/assets/fonts/new/opensans-semibold-webfont.eot?#iefix') format('embedded-opentype'), /* IE */
                url('/assets/fonts/new/opensans-semibold-webfont.ttf') format("truetype");
                /* non-IE */
            }
            
            * {
                /*font-family: OpenSans-Regular;*/
                color: gray;
            }
            
            .form-group label {
                font-family: OpenSans-Semibold;
            }
            
            .video {
                /*width: 400px;*/
                height: 400px;
                border: 1px solid;
            }
            
            .checkboxdiv {
                text-align: center;
                font-family: OpenSans-Semibold;
            }
            
            .signupbox {
                background-color: #d6d6d6;
                text-align: center;
                padding: 18px;
            }
            
            .login {
                background-color: #ffe284;
                /*border: none;*/
                /* font-family: OpenSans-Bold; */
                margin-top: -2px;
            }
            
            .heading {
                color: #757575;
                font-family: OpenSans-Regular !important;
                /* border-bottom: 2px solid #ffdd73; */
                padding-bottom: 20px;
                margin-bottom: 20px;
                padding-left: 15px;
                padding-right: 15px;
                text-align: center;
                margin-top: 100px;
            }
            
            .textbox {
                display: inline-block;
                width: 50%;
            }
        </style>