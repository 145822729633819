<div class="chip-small">
    <ng-content></ng-content>
    <button class="clear" (click)="remove()">
        <!-- <img class="img" src="assets/new-assets/icons/clear.svg" alt=""> -->
        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.0581 2.37718C12.3918 2.04344 12.3776 1.48811 12.0263 1.13681C11.675 0.785503 11.1197 0.771264 10.7859 1.105L7.30589 4.58504C6.91537 4.97556 6.26554 4.9589 5.85446 4.54782L2.19126 0.884625C1.83996 0.533322 1.28463 0.519083 0.95089 0.852821C0.617152 1.18656 0.631392 1.74189 0.982695 2.0932L4.64589 5.75639C5.05697 6.16747 5.07363 6.8173 4.68311 7.20782L1.20307 10.6879C0.869332 11.0216 0.883572 11.5769 1.23487 11.9282C1.58618 12.2795 2.14151 12.2938 2.47525 11.96L5.95529 8.48C6.34581 8.08948 6.99564 8.10614 7.40672 8.51722L11.0699 12.1804C11.4212 12.5317 11.9766 12.546 12.3103 12.2122C12.644 11.8785 12.6298 11.3231 12.2785 10.9718L8.61529 7.30865C8.20421 6.89757 8.18755 6.24774 8.57807 5.85722L12.0581 2.37718Z" fill="currentColor"/>
        </svg>
        
        
    </button>
</div>

 