import { Router, ActivatedRoute } from '@angular/router';

import {
    Component,
    NgZone,
    Input,
    Output,
    EventEmitter,
    ElementRef,
    ViewChild,
    ChangeDetectorRef,
    ViewEncapsulation
} from '@angular/core';


import {
    trigger,
    state,
    style,
    animate,
    transition,
    keyframes
  } from '@angular/animations'

import { Title } from '@angular/platform-browser';
import { Http } from '@angular/http';
import {
    ProjectService
}
    from './../projectservice';

import {
    CookieService
} from 'angular2-cookie/core';


@Component({
    selector: 'feedback',
    templateUrl: './feedback.html'
})
export class FeedbackComponent {
    width: string;
    constructor(public service: ProjectService, private http: Http) { }
    legend: boolean = false;
    feedback: any = {};
    projects: any;
    ngOnInit() {
        if(this.service.mobilecheck()) {
            this.width = '-280px';
        } else {
            this.width = '-362px';            
        }
        document.getElementById("main").style.right = this.width;
    }

    toggleList1() {
        if (this.legend) {
            this.legend = false;
            document.getElementById("main").style.right = this.width; //'-362px';
        } else {
            this.legend = true;
            document.getElementById("main").style.right = '0px';
        }
    }

    test() {
        console.log(this.projects[0].poly.coordinates);
        let latlng = this.projects[0].poly.coordinates;
        this.http.get('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + latlng[1] + ',' + latlng[0])
            .toPromise()
            .then(result => {
                let res = JSON.parse(result['_body']);
                console.log('res', res['results']);
                res = res['results'];
                if (res.length != 0) {
                    let formatted_address = res[0]['formatted_address'].split(',');
                    for (let j = 0; j < res[0].address_components.length; j++) {
                        if (res[0].address_components[j].types[0] == 'locality') {
                            formatted_address[formatted_address.length - 3] = ' ' + res[0].address_components[j].short_name;
                        }
                        if (res[0].address_components[j].types[0] == 'administrative_area_level_1' && (formatted_address.toString().indexOf('United States') >= 0 || formatted_address.toString().indexOf('USA') >= 0)) {
                            formatted_address[formatted_address.length - 2] = ' ' + res[0].address_components[j].short_name;
                        } else if (res[0].address_components[j].types[0] == 'administrative_area_level_1' && (formatted_address.toString().indexOf('United States') == -1 || formatted_address.toString().indexOf('USA') == -1)) {
                            formatted_address[formatted_address.length - 2] = ' ' + res[0].address_components[j].long_name;
                        }
                        if (res[0].address_components[j].types[0] == 'country' && res[0].address_components[j].short_name == 'US') {
                            formatted_address[formatted_address.length - 1] = ' ' + res[0].address_components[j].short_name + 'A';
                        } else if (res[0].address_components[j].types[0] == 'country' && res[0].address_components[j].short_name != 'US') {
                            formatted_address[formatted_address.length - 1] = ' ' + res[0].address_components[j].long_name;
                        }
                    }
                    console.log('res1', formatted_address.join(','));
                    let newAddress = formatted_address.join(',');
                    let body = {
                        project_location: newAddress
                    }
                    this.service.doPatch(this.projects[0].project_name_slug, body).then(res => {
                        console.log('success');
                    });
                }

            });
    }

    test1(i) {

        let latlng = this.projects[i].poly.coordinates;
        if (latlng[1] != 0 || !latlng[1]) {
            this.http.get('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + latlng[1] + ',' + latlng[0])
                .toPromise()
                .then(result => {
                    let res = JSON.parse(result['_body']);
                    console.log('res', res['results']);
                    res = res['results'];
                    if (res.length != 0) {
                        console.log('i', this.i)
                        this.i++;                     //  increment the counter
                        let formatted_address = res[0]['formatted_address'].split(',');
                        let shorter_loc = '';
                        for (let j = 0; j < res[0].address_components.length; j++) {
                            if (res[0].address_components[j].types[0] == 'locality') {
                                shorter_loc = res[0].address_components[j].long_name + ", ";
                            }
                            if (res[0].address_components[j].types[0] == 'administrative_area_level_1') {
                                shorter_loc += res[0].address_components[j].long_name + ", ";
                            }
                            if (res[0].address_components[j].types[0] == 'country') {
                                shorter_loc += res[0].address_components[j].long_name;
                            }

                            if (res[0].address_components[j].types[0] == 'locality') {
                                formatted_address[formatted_address.length - 3] = ' ' + res[0].address_components[j].short_name;
                            }
                            if (res[0].address_components[j].types[0] == 'administrative_area_level_1' && (formatted_address.toString().indexOf('United States') >= 0 || formatted_address.toString().indexOf('USA') >= 0)) {
                                formatted_address[formatted_address.length - 2] = ' ' + res[0].address_components[j].short_name;
                            } else if (res[0].address_components[j].types[0] == 'administrative_area_level_1' && (formatted_address.toString().indexOf('United States') == -1 || formatted_address.toString().indexOf('USA') == -1)) {
                                formatted_address[formatted_address.length - 2] = ' ' + res[0].address_components[j].long_name;
                            }
                            if (res[0].address_components[j].types[0] == 'country' && res[0].address_components[j].short_name == 'US') {
                                formatted_address[formatted_address.length - 1] = ' ' + res[0].address_components[j].short_name + 'A';
                            } else if (res[0].address_components[j].types[0] == 'country' && res[0].address_components[j].short_name != 'US') {
                                formatted_address[formatted_address.length - 1] = ' ' + res[0].address_components[j].long_name;
                            }
                        }
                        let newAddress = formatted_address.join(',');
                        let body = {
                            project_location: newAddress,
                            shorter_location: shorter_loc
                        }
                        this.service.doPatch(this.projects[i].project_name_slug, body).then(res => {
                            console.log('success');
                        });
                    }

                });

        }

        // }

    }

    i: number = 0;

    myLoop() {
        setTimeout(() => {            
            this.test1(this.i);             
            if (this.i < this.projects.length) {            
                this.myLoop();       
            }                        
        }, 5000)
    }

    coolakshay() {
        console.log('hello');
    }

    test2() {
        for (let i = 0; i < 5; i++) {
            let latlng = this.projects[i].poly.coordinates;
            this.http.get('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + latlng[1] + ',' + latlng[0])
                .toPromise()
                .then(result => {
                    let res = JSON.parse(result['_body']);
                    console.log('res', res['results']);
                    res = res['results'];
                    if (res.length != 0) {
                        let formatted_address = res[0]['formatted_address'].split(',');
                        // console.log('res1111', formatted_address);
                        for (let j = 0; j < res[0].address_components.length; j++) {
                            if (res[0].address_components[j].types[0] == 'locality') {
                                formatted_address[formatted_address.length - 3] = ' ' + res[0].address_components[j].short_name;
                            }
                            if (res[0].address_components[j].types[0] == 'administrative_area_level_1' && (formatted_address.toString().indexOf('United States') >= 0 || formatted_address.toString().indexOf('USA') >= 0)) {
                                formatted_address[formatted_address.length - 2] = ' ' + res[0].address_components[j].short_name;
                            } else if (res[0].address_components[j].types[0] == 'administrative_area_level_1' && (formatted_address.toString().indexOf('United States') == -1 || formatted_address.toString().indexOf('USA') == -1)) {
                                formatted_address[formatted_address.length - 2] = ' ' + res[0].address_components[j].long_name;
                            }
                            if (res[0].address_components[j].types[0] == 'country' && res[0].address_components[j].short_name == 'US') {
                                formatted_address[formatted_address.length - 1] = ' ' + res[0].address_components[j].short_name + 'A';
                            } else if (res[0].address_components[j].types[0] == 'country' && res[0].address_components[j].short_name != 'US') {
                                formatted_address[formatted_address.length - 1] = ' ' + res[0].address_components[j].long_name;
                            }
                        }
                        console.log('res1', formatted_address.join(','));
                        let newAddress = formatted_address.join(',');
                        let body = {
                            project_location: newAddress
                        }
                        this.service.doPatch(this.projects[i].project_name_slug, body).then(res => {
                            console.log('success');
                        });
                    }

                });
        }

    }

    submit() {
        let metting_needs = this.feedback.needs ? this.feedback.needs : '';
        let easy_to_find = this.feedback.easy ? this.feedback.easy : '';
        let appealing = this.feedback.appeal ? this.feedback.appeal : '';
        let easy_to_understand = this.feedback.website ? this.feedback.website : '';
        let rating = this.feedback.rating ? this.feedback.rating : '';
        let comments = this.feedback.comments ? this.feedback.comments : '';
        let like_about_mcw = this.feedback.like ? this.feedback.like : '';
        let contactEmail = this.feedback.contactEmail ? this.feedback.contactEmail : '';
        if(!metting_needs && !easy_to_find && !appealing && !easy_to_understand && !rating && !comments && !like_about_mcw && !contactEmail) {
            this.service.sendAlert('Error', 'You have not attempted any questions', 0);
            return;
        }
        let obj = {
            metting_needs: metting_needs,
            easy_to_find: easy_to_find,
            appealing: appealing,
            easy_to_understand: easy_to_understand,
            rating: rating,
            comments: comments,
            like_about_mcw: like_about_mcw,
            contactEmail: contactEmail
        };

        let body = {
            feedback: obj
        };
        this.service.feedback(body).then(res => {
           // alert('Thank you for your feedback!');
           this.toggleList1();
            this.service.sendAlert('Info', 'Thank you for your feedback!', 1);
            this.feedback.needs = '';
            this.feedback.easy = '';
            this.feedback.appeal = '';
            this.feedback.website = '';
            this.feedback.rating = '';
            this.feedback.comments = '';
            this.feedback.like = '';
            this.feedback.contactEmail = '';
        });
    }
}