import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  $loginStatus = new BehaviorSubject<boolean>(false);


  constructor() { }

  getFullNameSlug(){
    if(localStorage.getItem("full_name_slug")){
      return localStorage.getItem("full_name_slug");
    }else{
      return '';
    }
  }

  isLoggedIn() : Observable<boolean> {
    return this.$loginStatus.asObservable();
  }

  login(){
    this.$loginStatus.next(true);
  }

  logout(){
    this.$loginStatus.next(false);

  }
}
