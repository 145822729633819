<navbar></navbar>
<div class="container-fluid">
    <!--<div *ngIf="!show">
    <div class="cssload-dots">
      <div class="cssload-dot"></div>
      <div class="cssload-dot"></div>
      <div class="cssload-dot"></div>
      <div class="cssload-dot"></div>
      <div class="cssload-dot"></div>
    </div>
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <filter id="goo">
          <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="12"></feGaussianBlur>
          <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0	0 1 0 0 0	0 0 1 0 0	0 0 0 18 -7" result="goo"></feColorMatrix>
          <!--<feBlend in2="goo" in="SourceGraphic" result="mix" ></feBlend>-->
    <!--</filter>
      </defs>
    </svg>
  </div>-->

    <div class="row">
        <div class="projectList" [@fadeInOut]="menu1" *ngIf="menu1">
            <div id="v-scrollable" ng2-scrollable (elementVisible)="scrollEvent()" style="overflow-y: auto; height:100%; border-bottom: 2px solid #656565;">
                <div *ngFor="let d of listcolletion; let i = index" (mouseover)="highlightPin(i)" (mouseout)="removeHighlightedMarker()">
                    <div id="list{{i}}" class="list">
                        <div class="listParent" (click)="GoToDetailPage(d.project_name_slug)">
                            <div class="listLeft">
                                <div class="imgParent">
                                    <img src="{{d.imageurl}}" />
                                </div>
                            </div>
                            <div class="listRight">
                                <div class="project_name project_details">{{d.name ? d.name : 'Name This Project'}}</div>
                                <div class="project_desc project_details">{{d.desc}}</div>
                                <div class="project_addr project_details">{{d.address}}</div>
                                <!--<div class="viewBtn" id="view{{i}}"><a href="project/{{d.project_id}}" target="_blank"><button md-raised-button>View</button></a></div>-->
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="noProjects" class="listEmpty noProjects">
                    <p>
                        <i class="glyphicon glyphicon-exclamation-sign" style="font-family: 'Glyphicons Halflings';"></i> No projects available...
                    </p>
                    <div style="font-size: 14px;"><a routerLinkActive="active" routerLink="/createproject">Create your project and publish it now</a></div>
                </div>
            </div>
            <div class="loader1" *ngIf="loading"></div>
            <!--<div class="bottomBtns" *ngIf="prevURL && nextURL">
        <div class="col-sm-6 buttons" (click)="GoToPrevPage(prevURL)" style="background-color: grey;">
          PREV
        </div>
        <div class="col-sm-6 buttons" (click)="GoToNextPage(nextURL)" style="background-color: #b3abab;">
          NEXT
        </div>-->
            <!--<button *ngIf="prevURL" md-button (click)="GoToPrevPage(prevURL)" style="margin-right:2px;">Prev</button>
        <button *ngIf="nextURL" md-button (click)="GoToNextPage(nextURL)" style="margin-right:2px;">Next</button>-->
            <!--</div>
      <div *ngIf="!prevURL || !nextURL">
        <div class="bottomBtns" *ngIf="prevURL">
          <div class="col-sm-12 buttons" (click)="GoToPrevPage(prevURL)" style="background-color: grey;">
            PREV
          </div>
        </div>
        <div class="bottomBtns" *ngIf="nextURL">
          <div class="col-sm-12 buttons" (click)="GoToNextPage(nextURL)" style="background-color: #b3abab;">
            NEXT
          </div>
        </div>
      </div>-->
            <div class="collapseProjectList" (click)="toggleList();">
                <button><span class="glyphicon glyphicon-chevron-left" style="font-family: 'Glyphicons Halflings';"></span></button>
            </div>
        </div>
        <div class="collapseProjectList1" #rightArrow (click)="toggleList();">
            <button><span class="glyphicon glyphicon-chevron-right" style="font-family: 'Glyphicons Halflings';"></span></button>
        </div>
        <div class="mapParent">
            <div class="searchbox">
                <div class="col-sm-5">
                    <input type="text" placeholder="Search" id="autocompleteinput" />
                </div>
                <div class="col-sm-7">
                    <!-- <div class="dropdown">
            <span class="dropbtn">PROJECT TYPE</span>
            <div class="dropdown-content">
              <a (click)="typeChanged('Water/Environmental')">Water/Environmental</a>
              <a (click)="typeChanged('Transportation')">Transportation</a>
              <a (click)="typeChanged('Building/Structural')">Building/Structural</a>
            </div>
          </div>

          <div class="dropdown left">
            <span class="dropbtn">PROJECT STATUS</span>
            <div class="dropdown-content">
              <a href="#">Study/Design Phase</a>
              <a href="#">Under Construction</a>
              <a href="#">Complete</a>
              <a href="#">Operation & Maintenance</a>
            </div>
          </div>

          <div class="dropdown left">
            <span class="dropbtn">MORE</span>
            <div class="dropdown-content">
              <a href="#">Item 1</a>
              <a href="#">Item 2</a>
              <a href="#">Item 3</a>
            </div>
          </div> -->
                </div>
                <div style="float: right; margin-right: 100px;">
                    <div class="dropdown" style="width: 100px;">
                        <a routerLinkActive="active" routerLink="/explorer/projects" style="color: white;"><img title="Go to grid view of this page" class="quickbtn" style="width: 28px;" src="assets/images/grid_view.png">Grid View</a>
                    </div>
                    <!--<div class="dropdown">
              <span class="dropbtn1 quickbtn">UNDER CONSTRUCTION</span>
            </div>
            <div class="dropdown left">
              <span class="dropbtn1 quickbtn">ALL</span>
            </div>-->
                </div>
            </div>
            <div class="searchasmove">
                <label class="checkbox-inline" style="font-family: OpenSans-Semibold;font-size: 13px;">
			  <input type="checkbox" class="checkMove" id="checkMove" (change)="searchAsMapMoved = !searchAsMapMoved" checked/>Search as I move the map
		  </label>
            </div>
            <div class="searchasmove1" [@LegendFlyout]="legend" *ngIf="legend">
                <ul>
                    <li>
                        MAP LEGEND
                        <span style="float: right; font-family: 'Glyphicons Halflings'; cursor: pointer;" class="glyphicon glyphicon-chevron-right" (click)="toggleList1()"></span>
                    </li>
                    <li><span class="dot" style="background-color: #c96fcc;"></span><span style="margin-left:5px;">Study/Design Phase</span></li>
                    <li><span class="dot" style="background-color: #fd9e45;"></span><span style="margin-left:5px;">Under Construction</span></li>
                    <li><span class="dot" style="background-color: #0060a2;"></span><span style="margin-left:5px;">Complete</span></li>
                    <li><span class="dot" style="background-color: #3e99e5;"></span><span style="margin-left:5px;">Operation & Maintenance</span></li>
                    <li><span class="dot" style="background-color: #ff535f;"></span><span style="margin-left:5px;">Unknown/Varies</span></li>
                </ul>
            </div>
            <div (click)="toggleList1()" class="searchasmove2 flex">
                <div class="legend">LEGEND</div>
            </div>
            <!--<sebm-google-map [latitude]="lat" [longitude]="lng" [zoom]="zoom" [disabledefaultui]="false" [zoomcontrol]="true" style="height: 100%;">
        <sebm-google-map-marker *ngfor="let m of listcolletion; let i = index" [latitude]="m.lat" [longitude]="m.lng" [markerdraggable]="m.draggable">
          <sebm-google-map-info-window>
            <md-list-item>
              <h3 md-line *ngif="m.name"> {{m.name}} </h3>
              <p md-line>
                <span *ngif="m.desc"> {{m.desc}} </span>
                <span class="demo-2" *ngif="m.address"> -- {{m.address}} </span>
              </p>
            </md-list-item>
          </sebm-google-map-info-window>
        </sebm-google-map-marker>
      </sebm-google-map>-->
            <div id="map" style="height: calc(100% - 124px);"></div>
            <div id="dontsee" class="dontSee">
                <div class="imageDiv"><img src="/assets/images/rightArrowWhite.png" /></div>
                <div style="margin: 5px 0 0 10px;">
                    <div style="font-family: OpenSans-Semibold; color:white;">Don't see your project here?</div>
                    <div><a routerLinkActive="active" routerLink="/createproject" style="color: #80bbfb;">Create a free profile and publish it now.</a></div>
                </div>
            </div>
        </div>
    </div>
</div>
<style>
    @font-face {
        font-family: OpenSans-Regular;
        src: url('/assets/fonts/new/opensans-regular-webfont.eot?#iefix') format('embedded-opentype'), /* IE */
        url('/assets/fonts/new/opensans-regular-webfont.ttf') format("truetype");
        /* non-IE */
    }
    
    @font-face {
        font-family: OpenSans-Light;
        src: url('/assets/fonts/new/opensans-light-webfont.eot?#iefix') format('embedded-opentype'), /* IE */
        url('/assets/fonts/new/opensans-light-webfont.ttf') format("truetype");
        /* non-IE */
    }
    
    @font-face {
        font-family: OpenSans-Bold;
        src: url('/assets/fonts/new/opensans-bold-webfont.eot?#iefix') format('embedded-opentype'), /* IE */
        url('/assets/fonts/new/opensans-bold-webfont.ttf') format("truetype");
        /* non-IE */
    }
    
    @font-face {
        font-family: OpenSans-ExtraBold;
        src: url('/assets/fonts/new/opensans-extrabold-webfont.eot?#iefix') format('embedded-opentype'), /* IE */
        url('/assets/fonts/new/opensans-extrabold-webfont.ttf') format("truetype");
        /* non-IE */
    }
    
    @font-face {
        font-family: OpenSans-Semibold;
        src: url('/assets/fonts/new/opensans-semibold-webfont.eot?#iefix') format('embedded-opentype'), /* IE */
        url('/assets/fonts/new/opensans-semibold-webfont.ttf') format("truetype");
        /* non-IE */
    }
    
    * {
        font-family: OpenSans-Regular;
    }
    
    .custom-pin {
        height: 10px;
        line-height: 1;
        width: 22px;
        padding: 6px 2px;
        margin: 10px;
        text-align: center;
        cursor: pointer;
        color: white;
        background: black;
        position: absolute;
        border-radius: .5em;
        font-size: 1em;
        font-weight: bold;
        transition: all 500ms;
    }
    
    .custom-pin::after {
        content: '';
        border-width: 1em .5em;
        border-color: black transparent transparent transparent;
        border-style: solid;
        position: absolute;
        top: 99%;
        left: calc(50% - .5em);
    }
    
    .custom-pin:hover {
        transform: scale(1.15);
    }
    
    .buttons {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 26px;
        cursor: pointer;
    }
    
    .navbar {
        border-bottom: 1px solid #d4d4d4 !important;
    }
    
    div.left {
        margin-left: 15px;
    }
    /* Dropdown Button */
    
    .dropbtn {
        background-color: rgba(26, 73, 109, 255) !important;
        -webkit-tap-highlight-color: rgba(26, 73, 109, 255) !important;
        color: white;
        /*padding: 16px;*/
        /*font-weight: bold;*/
        font-size: 14px;
        border: none;
        cursor: pointer;
        /*font-family: sans-serif;*/
    }
    
    .dropbtn1 {
        background-color: rgba(26, 73, 109, 255) !important;
        -webkit-tap-highlight-color: rgba(26, 73, 109, 255) !important;
        color: white;
        font-size: 14px;
        border: none;
        cursor: pointer;
        /*font-family: sans-serif;*/
    }
    
    .dropbtn:after {
        content: ' ▾';
        margin-top: -11px;
        right: 0;
        position: absolute;
        display: flex;
    }
    /* The container <div> - needed to position the dropdown content */
    
    .dropdown {
        position: relative;
        display: inline-block;
    }
    /* Dropdown Content (Hidden by Default) */
    
    .dropdown-content {
        display: none;
        position: absolute;
        background-color: #f9f9f9;
        min-width: 205px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 4;
    }
    /* Links inside the dropdown */
    
    .dropdown-content a {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
    }
    /* Change color of dropdown links on hover */
    
    .dropdown-content a:hover {
        background-color: #f1f1f1
    }
    /* Show the dropdown menu on hover */
    
    .dropdown:hover .dropdown-content {
        display: block;
    }
    /* Change the background color of the dropdown button when the dropdown content is shown */
    
    .dropdown:hover .dropbtn {
        background-color: rgba(26, 73, 109, 255);
    }
    
     ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: white !important;
    }
    
     ::-moz-placeholder {
        /* Firefox 19+ */
        color: white !important;
    }
    
     :-ms-input-placeholder {
        /* IE 10+ */
        color: white !important;
    }
    
     :-moz-placeholder {
        /* Firefox 18- */
        color: white !important;
    }
    
    .searchbox {
        height: 50px;
        /*padding: 0 10px;*/
        display: flex;
        align-items: center;
        background-color: rgba(26, 73, 109, 255);
    }
    
    .searchbox input {
        border: none;
        background-color: rgb(17, 53, 80);
        color: white;
        width: 375px;
        height: 38px;
        border-radius: 3px;
        box-shadow: none;
        text-indent: 15px;
        outline: none;
        font-family: OpenSans-Regular;
        transition: all 0.3s ease;
    }
    
    .searchbox input:focus {
        background-color: white;
        color: black;
    }
    
    .listParent {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .checkMove {
        margin-top: 2px;
    }
    
    .noProjects {
        font-size: 18px;
        font-family: OpenSans-Bold;
        color: #8e8e8e;
    }
    
    .listEmpty {
        font-size: 18px;
        font-family: OpenSans-Bold;
        color: #8e8e8e;
        padding: 14px;
        text-align: center;
    }
    
    .mapParent {
        padding: 0;
        position: absolute;
        top: 62px;
        height: calc(100% - 62px);
        left: 0;
        right: 0;
        /* z-index: -1; */
        overflow: hidden;
    }
    
    .viewBtn {
        margin-top: 5px;
        text-align: center;
        margin-right: 15px;
        /*display: none;*/
    }
    
    .viewBtn button {
        font-size: 12px;
        color: #656565;
        line-height: 2;
    }
    
    .loader1 {
        border: 4px solid #f3f3f3;
        border-top: 4px solid #3498db;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        animation: spin 1s linear infinite;
        position: absolute;
        top: 35%;
        left: 44%;
        z-index: 9;
    }
    
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    
    .flex {
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
    }
    
    .legend {
        transform: rotate(180deg);
        word-wrap: break-word;
        font-family: monospace;
        writing-mode: vertical-rl;
        letter-spacing: 7px;
        white-space: pre;
    }
    
    .searchasmove2 {
        padding: 4px 5px 5px 5px;
        background: white;
        border-radius: 2px;
        font-size: 12px;
        color: #b5b4b5;
        position: absolute;
        font-family: OpenSans-Semibold;
        width: 23px;
        z-index: 1;
        right: 0;
        cursor: pointer;
        top: 20%;
        height: 144px;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75) !important;
    }
    
    .searchasmove1 {
        position: absolute;
        font-family: OpenSans-Semibold;
        z-index: 2;
        padding: 4px 5px 5px 5px;
        background: white;
        border-radius: 2px;
        font-size: 12px;
        color: #b5b4b5;
        width: 200px;
        /*height: 144px;*/
        top: 20% !important;
        right: 0;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75) !important;
    }
    
    .searchasmove1 ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
    
    .dot {
        width: 10px;
        height: 10px;
        float: left;
        margin-top: 4px;
        border-radius: 40px;
    }
    
    .searchasmove1 ul li {
        padding: 5px 8px;
    }
    
    .searchasmove {
        position: absolute;
        font-family: OpenSans-Semibold;
        top: 75px;
        left: 45%;
        z-index: 2;
        padding: 4px 5px 5px 5px;
        background: white;
        border-radius: 2px;
        font-size: 12px;
        color: #717171;
        box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.75);
    }
    
    .imageDiv {
        width: 50px;
        height: 50px;
        border-radius: 50px;
        background-color: #ffd93b;
    }
    
    .imageDiv img {
        width: 35px;
        height: 45px;
        margin: 2px 0 0 9px;
    }
    
    .dontSee {
        background-color: #808080;
        height: 74px;
        display: flex;
        padding: 14px;
        align-items: center;
        justify-content: center;
        border-top: 2px solid #656565;
    }
    /*.list:nth-child(even) {
    background: #e8e8e8;
  }
  
  .list:nth-child(odd) {
    background: white;
  }*/
    
    .bottomBtns {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 72px;
        background-color: #cbcbcb;
    }
    
    .project_details {
        overflow: hidden;
        /*text-overflow: ellipsis;
    white-space: nowrap;*/
    }
    
    .project_name {
        font-family: OpenSans-Semibold;
        font-size: 18px;
        line-height: 1.2;
    }
    
    .project_desc {
        font-family: OpenSans-Semibold;
        font-size: 14px;
        margin-top: 5px;
        color: #8e8e8e;
        line-height: 1.2;
    }
    
    .project_addr {
        color: #8c8c8c;
        font-size: 12px;
        margin-top: 3px;
    }
    
    .listRight {
        width: calc(100% - 120px);
        float: right;
        /* padding: 7px; */
        padding-left: 20px;
    }
    
    .listLeft {
        width: 120px;
        float: left;
    }
    
    .list {
        /*height: 110px;*/
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 2;
        margin: 0;
        cursor: pointer;
        padding: 5px;
        background-color: white;
        border-bottom: 1px solid rgba(179, 171, 171, 0.3);
        /*transition: background-color 0.5s ease;
    -webkit-transition: background-color 0.5s ease;*/
    }
    
    #map {
        transition: margin-left 0.5s ease;
    }
    
    .list:hover {
        /*box-sizing: content-box;
    margin: 20px 0; 
    box-shadow: 0 -5px 50px rgba(0, 0, 0, 0.4), 0 5px 50px rgba(0, 0, 0, 0.4);
    z-index: 4;*/
        /*transform: scale(1, 1.1);*/
        /*color: white !important;*/
        background-color: rgba(0, 0, 0, 0.07);
    }
    
    .collapseProjectList {
        position: absolute;
        z-index: -1;
        top: 0px;
        left: 100.2%;
    }
    
    .collapseProjectList1 {
        position: absolute;
        z-index: 1;
        top: 112px;
    }
    
    .collapseProjectList button,
    .collapseProjectList1 button {
        width: 50px;
        height: 50px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0;
        border: none;
        background: white;
        outline: none;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);
    }
    
    .collapseProjectList button span,
    .collapseProjectList1 button span {
        font-size: 20px;
        top: 0;
    }
    /* Let's get this party started */
    
     ::-webkit-scrollbar {
        width: 5px;
    }
    /* Track */
    
     ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px #888;
        /*-webkit-border-radius: 10px;
    border-radius: 10px;*/
    }
    /* Handle */
    
     ::-webkit-scrollbar-thumb {
        /*-webkit-border-radius: 10px;
    border-radius: 10px;*/
        background: #808080;
        /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); */
    }
    
     ::-webkit-scrollbar-thumb:window-inactive {
        /*background: rgba(255,0,0,0.4); */
    }
</style>