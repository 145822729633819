<div class="project-card-main">
    <!-- <img src="assets/new-assets/home/home-project-card/{{project?.image}}.png" alt=""> -->
    <img src="{{project?.image}}" alt="">
    <div class="card-text-div">
        <div class="project-card-text">
            <h1>{{project?.title}}</h1>
            <h2>{{project?.location}}</h2>
            <p *ngIf="project?.creator">{{project?.creator}}</p>
        </div>
        <!-- <p class="project-card-desc">{{project?.description}}<a href="">Read More</a></p> -->
        <p *ngIf="project?.description" class="project-card-desc">{{project?.description | slice: 0:70}} {{ project?.description.length > 70 ? '...' : ''}} <a (click)="goToProject(project?.slug)">Read More</a></p>

        <a *ngIf="!project?.description" (click)="goToProject(project?.slug)">Read More</a>


        <app-profile-mini-card style="position: absolute;bottom: 16px;" *ngIf="project?.profile" [profileMiniData]="project?.profile"></app-profile-mini-card>
    </div>


</div>