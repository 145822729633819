

<div class="container-fluid"><br>
<div class="" style=""><h3 style="color:#FFE089;margin-left:85px;">Create Your Profile</h3><h5 style="margin-left:85px;color:grey;">Set up your account login details</h5></div>
 <br><div class="row">
  <div class="col-sm-2" ></div>
   <div class="col-sm-4" style="">
<div class="form-group" style="color:grey;">
     First name* &nbsp;&nbsp;<input #a type="text" class="" id="usr" style="width:70%;border: 1px solid #d8d5d5;background-color:#e2dede;border-radius:2px;" required>
   </div>
<div class="form-group" style="color:grey;">
     Last name &nbsp;&nbsp;&nbsp;&nbsp;<input #b type="text" class="" id="usr" style="width:70%;border: 1px solid #d8d5d5;background-color:#e2dede;border-radius:2px;" required>
   </div>
<div class="form-group" style="color:grey;">
     Email* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input #c type="email" class="" id="usr" style="width:70%;border: 1px solid #d8d5d5;background-color:#e2dede;border-radius:2px;">
   </div>
<div class="form-group" style="color:grey;">
     Password* &nbsp;&nbsp;&nbsp;<input #p type="password" class="" id="usr" style="width:70%;border: 1px solid #d8d5d5;background-color:#e2dede;border-radius:2px;" required>
   </div>
<div class="form-group" style="color:grey;margin-left:-38px;">
    Verify Password* &nbsp;&nbsp; <input type="password" class="" id="usr" style="width:65%;border: 1px solid #d8d5d5;background-color:#e2dede;border-radius:2px;" required>
   </div>
<div class="checkbox" style="margin-left:114px;color:grey;">
 <label class="text-right" style="margin-right:64px;"><input type="checkbox" value="">Yes, I'd like to receive {{ service.project_name }}'s project newsletter (we promise we won't spam you!)</label>
 <br><br><label style="font-size:95%;"><i>By creating my account I agree to {{ service.project_name }}'s </i><a href="#" style="float:right;margin-right:67px;color:grey;"><u>Terms and Conditions</u></a></label>
</div><br>
<button type="submit" class="btn btn-warning"  (click)="doReg(a.value,c.value,p.value)" style="float:right;margin-right:62px;padding-bottom:2px;color:#97885f;background-color:#FFE089;border:1px solid #FFE089;">Create Account</button>
</div>
   <div class="col-sm-6" style=""></div>
 </div>

