import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AcceleratorService } from '../new-repicture/accelerator/accelerator.service';
import { NgModel } from '@angular/forms';

export interface DialogData {
  title: string;
  content: any;
  type: string;
}

@Component({
  selector: 'app-dialogue',
  templateUrl: './dialogue.component.html',
  styleUrls: ['./dialogue.component.scss']
})
export class DialogueComponent implements OnInit {

  @ViewChild('codefield', { static: false }) codefield!: NgModel;
  orgcodeTitle = 'Enter Organization Code'
  orgcodeSubTitle = 'If you received a code from your organization, enter it here.'
  orgButtonTitle = 'Apply Code'
  isCodeApplied = false
  code = ''

  constructor(public dialogRef: MatDialogRef<DialogueComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData, private service: AcceleratorService,) { }

  ngOnInit() {
    console.log(this.data);
    // this.setLayoutWhenCodeApplied()

  }

  close() {
    this.dialogRef.close(null);
  }

  get okayOrRetry() {
    if (this.data.type == 'common') {
      if (this.data.content.type == 'success') {
        return 'OK'
      } else {
        return 'Retry'
      }
    } else {
      return 'OK'
    }
  }

  get paymentConfirmfunc() {
    if (this.data.type == 'paymentConfirm') {
      if (this.data.content.type == 'success') {
        return 'OK'
      } else {
        return 'Retry'
      }
    } else {
      return 'OK'
    }
  }

  orgCodeEnterd(code) {

    if (!this.isCodeApplied) {
      this.service.getCoupons(code).subscribe(res => {
        console.log(res);
        if (res.length > 0) {
          const val = res[0].discount_value
          if (val === 100) {
            console.log(val);
            this.isCodeApplied = true
            this.setLayoutWhenCodeApplied()
          } else {
            this.codefield.control.setErrors({ invalidCode: true })
          }
        } else {
          this.codefield.control.setErrors({ invalidCode: true })

        }

      })
    } else {
      console.log('finish registration');
      this.dialogRef.close(code);
     
    }

  }

  setLayoutWhenCodeApplied() {
    this.orgcodeTitle = 'Woohoo! Your organization is giving you this course for free!'
    this.orgcodeSubTitle = 'This course usually costs $299, but your organization is giving you this course for free.'
    this.orgButtonTitle = 'Finish Registration'
  }

}
