import { Router, ActivatedRoute } from '@angular/router';

import {
  Component,
  NgZone,
  EventEmitter,
  Input,
  Output,
  ChangeDetectorRef
} from '@angular/core';

import {
  ProjectService
}
  from './../projectservice';

import {
  CookieService
} from 'angular2-cookie/core';

import * as _ from 'lodash';

import { Title } from '@angular/platform-browser';

@Component({
  selector: 'forgotpassword',
  templateUrl: './forgotpassword.html'
})

export class ForgotPassword {
  id: any;
  key: any;
  loading: boolean = false;
  signupClicked: boolean = false;
  loginClicked: boolean = false;
  passwordObject: userLogin = {
    password: '',
    confirm_password: ''
  }
  constructor(public service: ProjectService, private cookie: CookieService, private cd: ChangeDetectorRef, private titleService: Title, private router: Router, private route: ActivatedRoute) {
    if (localStorage.getItem('authtoken')) {
      this.router.navigate(['']);
    }
  }

  ngOnInit() {
    this.titleService.setTitle("Reset Password | Repicture App");
    try {
      this.route.params.subscribe(params => {
        if (params['uid'] && params['key']) {
          this.id = params['uid'];
          this.key = params['key'];
        } else {
          //alert('Parameters error!');
          this.service.sendAlert('Error', 'Parameters error!', 0);
        }
      });
    } catch (e) { }
  }

  reset() {
    if (!this.passwordObject.confirm_password.trim() || !this.passwordObject.password.trim()) {
      //alert('Both passwords are mandatory!');
      this.service.sendAlert('Error', 'Both passwords are mandatory!', 0);
      return;
    }

    if (this.passwordObject.password != this.passwordObject.confirm_password) {
      //alert("Passwords didn't match!");
      this.service.sendAlert('Error', "Passwords didn't match!", 0);
      return false;
    }

    var elem = document.getElementById("p1");

    var pattern = elem.getAttribute("pattern");
    var re = new RegExp(pattern);
    if (!re.test(elem['value'])) {
      //alert('Please revise password to meet complexity requirements!');
      this.service.sendAlert('Error', 'Please revise password to meet complexity requirements!', 0);
      return;
    }

    var elem = document.getElementById("p2");

    var pattern = elem.getAttribute("pattern");
    var re = new RegExp(pattern);
    if (!re.test(elem['value'])) {
      //alert('Please revise confirm password to meet complexity requirements!');
      this.service.sendAlert('Error', 'Please revise confirm password to meet complexity requirements!', 0);
      return;
    }
    let obj = {
      "new_password1": this.passwordObject.password, 
      "new_password2": this.passwordObject.confirm_password, 
      "uid": this.id, 
      "token": this.key
    }

    this.service.resetPassword(obj).then(res=> {
      //alert('Your password has been changed successfully! Now login with new password.');
      this.service.sendAlert('Success', 'Your password has been changed successfully! Now login with new password.', 1);
      this.router.navigate(['/login']);
    });
  }
}

interface userSignup {
  first_name: any;
  last_name: any;
  email: any;
  password: any;
  confirm_password: any;
}

interface userLogin {
  confirm_password: any;
  password: any;
}