<navbar></navbar>
<div class="get-in-touch">
    <h3>Get in touch!</h3>
   
        <form action="" [formGroup]="user">
            <div class="fandlname">
                <app-form-field [label]="'First Name'">
                    <input formControlName="first_name" placeholder="First Name" autocomplete="off" appInput type="text" required />
                </app-form-field>
                <app-form-field [label]="'Last Name'">
                    <input formControlName="last_name" placeholder="Last Name" autocomplete="off" appInput type="text" required />
                </app-form-field>
            </div>

            <app-form-field [label]="'Email'">
                <input formControlName="email" placeholder="Email" autocomplete="off" appInput type="text" required />
            </app-form-field>
            <app-form-field [label]="'Organization'">
                <input formControlName="organization" placeholder="Organization" autocomplete="off" appInput type="text" required />
            </app-form-field>

            <app-form-field [label]="'Your message'">
                <textarea cols="30" rows="8" formControlName="message" placeholder="Your message" autocomplete="off" appInput type="text" required ></textarea>
            </app-form-field>

            <button (click)="submit()" rp-button type="primary" size="jumbo">Submit request</button>

            
        </form> 
        

</div>

<footer></footer>