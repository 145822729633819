import {
    Component,
    NgZone,
    Input,
    Output, EventEmitter, OnChanges
} from '@angular/core';

import {
    trigger,
    state,
    style,
    animate,
    transition,
    keyframes
} from '@angular/animations'


import {
    ProjectService
}
    from './../projectservice';

import { Router, ActivatedRoute } from '@angular/router';

import * as _ from 'lodash';
import * as $ from 'jquery';

import { IMultiSelectOption } from 'angular-2-dropdown-multiselect';
import { CompanyProfile } from '../companyprofilecomponent/companyprofile.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'companyprojects',
    templateUrl: './companyprojects.component.html',
    animations: [
        trigger('fadeInOut', [
            transition('void => *', [
                style({ opacity: 0 }),
                animate(700, style({ opacity: 1 }))
            ]),
            transition('* => void', [
                animate(0, style({ opacity: 0 }))
            ])
        ])
    ]

})
export class CompanyProjectsComponent implements OnChanges {

    @Input() mx: any;
    @Input() edit: any;
    @Input() currentProject: any;
    @Input() fieldname: any;
    @Output() checkProjectLength: EventEmitter<any> = new EventEmitter();
    textinput: any;

    projectobj: any;
    toggleVisibitly = false;
    ShowWebsiteDiv: boolean = false;
    ShowProjectTypeDiv: boolean = false;
    EditToggle: boolean = false;
    showTexbox: boolean = false;
    collectionArticles: any = [{ reference_url: '', reference_desc: '', reference_source: '' }];
    collectionAwards: any = [{ award_name: '', award_link: '', award_description: '' }];
    track2: any = 0;
    track1: any = 0;
    ShowWebsiteDivData: boolean = false;
    ShowProjectTypeDivData: boolean = false;
    projecttypeArr: any = [];
    url: string;
    desc: any;
    printArray: any = [];
    data: any;
    peoples: any = [];
    peoples1: any = [];
    varib: boolean = false;
    fields: any;
    txt: any
    companyData: any;
    papers: boolean = false;
    papers2: boolean = false;
    otherRole: any;
    companyRole: any = [];
    companyOther: boolean = false;
    roleOnProject: any = '';
    selectedOptions: any = [];
    show: boolean = false;
    exp: any = ["0", "0 - 1", "1 - 2", "2 - 3", "3 - 4", "4 - 5", "5 - 6", "6 - 7", "7 - 8", "8 - 9", "9 - 10", "10+"];

    private companyRoleOptions: IMultiSelectOption[] = [
        { id: 'Civil Engineer', name: 'Civil Engineer' },
        { id: 'Architect', name: 'Architect' },
        { id: 'Construction Contractor', name: 'Construction Contractor' },
        { id: 'Developer', name: 'Developer' },
        { id: 'Electrical Engineer', name: 'Electrical Engineer' },
        { id: 'Environmental Consultant', name: 'Environmental Consultant' },
        { id: 'Estimator', name: 'Estimator' },
        { id: 'Landscape Architect', name: 'Landscape Architect' },
        { id: 'Mechanical Engineer', name: 'Mechanical Engineer' },
        { id: 'Owner', name: 'Owner' },
        { id: 'Planner', name: 'Planner' },
        { id: 'Surveyor', name: 'Surveyor' },
        { id: 'Other', name: 'Other' },
    ];
    companyRole_others = [];
    videoURL: any;
    external_video_edit: boolean = false;
    external_video_iframe: any;
    isVideoDeleted: boolean = false;

    constructor(public service: ProjectService, private route: ActivatedRoute, private obj: CompanyProfile, private _sanitizer: DomSanitizer) { }

    ngOnInit() {
        if (this.service.myOrg == sessionStorage.getItem("companyId")) {
            this.show = true;
        } else {
            this.show = false;
        }

        try {
            if (this.mx) {
                console.log("onchanges if1", " ", this.mx);
                this.showTexbox = true;
                if (this.mx.project_organization.length > 1) {
                    this.route.params.subscribe(params => {
                        let company_id = params['id'];
                        for (let i = 0; i < this.mx.project_organization.length; i++) {
                            if (company_id == this.mx.project_organization[i].org_name_slug) {
                                this.companyData = this.mx.project_organization[i];
                                break;
                            }
                        }
                    });
                } else {
                    this.companyData = this.mx.project_organization[0];
                }
                for (let i = 0; i < this.companyData.org_civil_role.length; i++) {
                    if (!_.includes(_.map(this.companyRoleOptions, 'id'), this.companyData.org_civil_role[i])) {
                        if (!_.includes(this.companyRole_others, this.companyData.org_civil_role[i])) {
                            this.companyRole_others.push(this.companyData.org_civil_role[i]);
                        }
                        if (!_.includes(this.companyRole, 'Other')) {
                            this.companyRole.push('Other');
                            this.companyOther = true;
                        }
                    } else {
                        this.companyRole.push(this.companyData.org_civil_role[i]);
                    }

                }
                console.log('fahs', this.companyData.org_civil_role.length, this.companyRole_others);
                this.otherRole = this.companyRole_others.toString();
                if (!_.isEmpty(JSON.parse(this.companyData.org_related_products))) {
                    this.papers = true;
                    this.peoples = JSON.parse(this.companyData.org_related_products);
                }
                if (!_.isEmpty(this.companyData.project_org_media)) {
                    this.papers = true;
                    this.media_papers = this.companyData.project_org_media;
                }
                if (this.companyData.external_video && this.companyData.external_video != null) {
                    const url = this.companyData.external_video;
                    console.log('urlChnaged', url);
                    this.companyData.external_video1 = this.convertMedia(url);
                    this.external_video_edit = true;
                    this.external_video_iframe = this.convertMedia(url);
                }
                if (!_.isEmpty(JSON.parse(this.companyData.project_awards))) {
                    this.papers2 = true;
                    this.peoples1 = JSON.parse(this.companyData.project_awards);
                }
            } else {
                console.log("onchanges else123", " ", this.mx);
                this.showTexbox = false;
            }
        } catch (e) {
            console.log('cac tch');
        }
    }

    showTextbox() {
        // if (!this.obj.claimed && !this.service.isAdmin) {
        //     this.service.sendAlert('', 'You need to claim this profile in order to edit this field.', 0);
        //     return;
        // }
        this.EditToggle = true;
    }

    onChangedd($event) {
        this.companyOther = false;
        this.companyRole = $event;
        if (_.includes($event, 'Other')) {
            this.companyOther = true;
        }
    }

    ngOnChanges() {
        if (!this.edit) {
            this.EditToggle = false;
            console.log('sca', this.showTexbox);
        } else {
            this.EditToggle = true;
        }
    }

    evt: any;
    clicked: boolean = false;
    media_papers: any = [];

    mediaSelected1($event) {
        this.evt = $event;
    }

    mediaSelected($event) {
        let btnUpload = document.getElementById('btn');
        btnUpload.classList.remove("glyphicon-upload");
        btnUpload.classList.add("glyphicon-refresh");
        btnUpload.classList.add("glyphicon-refresh-animate");
        let file = $event.target.files[0];
        let name = $event.target.files[0].name;
        let obj = {
            project_organization_file: file,
            project_organization_file_name: name,
            org_id: localStorage.getItem('org_id'),
            project_id: this.mx.project_id
        }
        this.service.setOrgFiles(obj).then(res => {
            console.log('media', res);
            let obj = {
                project_organization_file_name: res['project_organization_file_name'],
                project_organization_file: res['project_organization_file']
            }
            this.media_papers.push(obj);
            this.evt = '';
            this.clicked = false;
            this.papers = true;
            btnUpload.classList.add("glyphicon-upload");
            btnUpload.classList.remove("glyphicon-refresh");
            btnUpload.classList.remove("glyphicon-refresh-animate");
        });
    }

    showText() {
        this.showTexbox = true;
        this.EditToggle = true;
    }

    save(work_performed: any, closer_look: any, link: any) {
        if (work_performed.length > 300) {
            //alert('Type of work performed should be limited to 300 characters only.');
            this.service.sendAlert('Error', 'Type of work performed should be limited to 300 characters only.', 0);
            return;
        }
        let url1 = document.getElementById("articleUrl" + this.track2)['value'];
        let desc = document.getElementById("articleText" + this.track2)['value'];
        let source = document.getElementById("articleSource" + this.track2)['value'];
        if (url1 || desc || source) {
            this.collectionArticles.splice(this.track2, 1, { 'reference_url': url1, 'reference_desc': desc, 'reference_source': source });
        } else {
            this.collectionArticles.splice(-1, 1);
        }
        let newArray = [];
        newArray = _.concat(this.peoples, this.collectionArticles);

        let url2 = document.getElementById("awardUrl" + this.track1)['value'];
        let link1 = document.getElementById("awardLink" + this.track1)['value'];
        let desc2 = document.getElementById("awardText" + this.track1)['value'];
        if (url2 || desc2) {
            this.collectionAwards.splice(this.track1, 1, { 'award_name': url2, award_link: link1, 'award_description': desc2 });
        } else {
            this.collectionAwards.splice(-1, 1);
        }
        let newArray1 = [];
        newArray1 = _.concat(this.peoples1, this.collectionAwards);

        if (this.companyOther) {
            let index = this.companyRole.indexOf('Other');
            this.companyRole.splice(index, 1);
            this.companyRole.push(this.otherRole);
        }

        this.companyData.org_civil_role = this.companyRole;

        let company_id = localStorage.getItem('org_id');
        let body = {
            "org_id": company_id,
            "project_id": this.mx.project_id,
            "org_civil_role": this.companyData.org_civil_role,
            "org_contribution": work_performed,
            "org_page": link,
            "org_contrib_details": closer_look,
            "project_awards": JSON.stringify(newArray1),
            "org_related_products": JSON.stringify(newArray)
        };

        if (this.external_video_edit) {
            body['external_video'] = this.videoURL;
        } else if (this.isVideoDeleted) {
            body['external_video'] = null;
        }
        console.log(body);

        this.service.addCompanyProjectSpecificData(body).then(result => {
            console.log(result);
            this.companyData = result;
            // this.companyData.org_contribution = work_performed.trim();
            // this.companyData.org_page = link.trim();
            // this.companyData.org_contrib_details = closer_look.trim();
            // this.companyData.external_video = result['external_video'];
            if (this.companyData.external_video && this.companyData.external_video != null) {
                const url = this.companyData.external_video;
                console.log('urlChnaged', url);
                this.companyData.external_video1 = this.convertMedia(url);
                this.external_video_edit = true;
                this.external_video_iframe = this.convertMedia(url);
            }
            if (!_.isEmpty(newArray)) {
                this.papers = true;
                this.peoples = newArray;
            } else {
                this.papers = false;
            }
            if (!_.isEmpty(this.companyData.project_org_media)) {
                this.papers = true;
                this.media_papers = this.companyData.project_org_media;
            }
            if (!_.isEmpty(newArray1)) {
                this.papers2 = true;
                this.peoples1 = newArray1;
            } else {
                this.papers2 = false;
            }
            this.collectionArticles = [{ reference_url: '', reference_desc: '', reference_source: '' }];
            this.collectionAwards = [{ award_name: '', award_link: '', award_description: '' }];
            this.track1 = 0;
            this.track2 = 0;
            this.EditToggle = false;
        });
    }

    addmoreArticles(index) {
        let url1 = document.getElementById("articleUrl" + index)['value'];
        let desc = document.getElementById("articleText" + index)['value'];
        let source = document.getElementById("articleSource" + index)['value'];
        this.collectionArticles.splice(index, 1, { 'reference_url': url1, 'reference_desc': desc, 'reference_source': source });
        this.collectionArticles.push({ 'reference_url': '', 'reference_desc': '', 'reference_source': '' });
        this.track2++;
    }

    addmoreAwards(index) {
        let url1 = document.getElementById("awardUrl" + index)['value'];
        let link1 = document.getElementById("awardLink" + index)['value'];
        let desc = document.getElementById("awardText" + index)['value'];
        this.collectionAwards.splice(index, 1, { 'award_name': url1, 'award_link': link1, 'award_description': desc });
        this.collectionAwards.push({ 'award_name': '', award_link: '', 'award_description': '' });
        this.track1++;
    }

    removeArticle(i) {
        this.peoples.splice(i, 1);
        this.companyData.org_related_products = JSON.stringify(this.peoples);
        if (this.peoples.length == 0) {
            this.papers = false;
        }
        this.remove();
    }

    removeAward(i) {
        this.peoples1.splice(i, 1);
        this.companyData.project_awards = JSON.stringify(this.peoples1);
        if (this.peoples1.length == 0) {
            this.papers2 = false;
        }
        this.remove();
    }

    remove() {
        let company_id = localStorage.getItem('org_id');
        let body = {
            "org_id": company_id,
            "project_id": this.mx.project_id,
            "project_awards": JSON.stringify(this.peoples1),
            "org_related_products": JSON.stringify(this.peoples)
        };

        this.service.addCompanyProjectSpecificData(body).then(result => {

        });
    }

    removeCompanySelfFromProjects(project_id, currProject) {
        let obj = {
            'project_id': project_id,
            'org_id': localStorage.getItem('org_id')
        }

        this.service.removeCompanySelf(obj).then(result => {
            console.log('success');
            this.checkProjectLength.emit(currProject);
        });
    }

    externalMediaSelected() {
        if (this.convertMedia(this.videoURL)) {
            const btnUpload = document.getElementById('btn11');
            btnUpload.classList.remove('glyphicon-upload');
            btnUpload.classList.add('glyphicon-refresh');
            btnUpload.classList.add('glyphicon-refresh-animate');
            // const people_id = sessionStorage.getItem('currentUserId');

            // this.obj2 = {
            //     'user_id': people_id,
            //     'project_id': this.mx.project_id,
            //     'external_video': this.videoURL // .replace("watch?v=", "embed/")
            // };
            // console.log('obj2d', this.obj2);
            this.external_video_edit = true;
            // this.external_video_changed = true;
            // this.external_video = this.videoURL;
            this.external_video_iframe = this.convertMedia(this.videoURL);
            // this.videoURL = '';
            btnUpload.classList.add('glyphicon-upload');
            btnUpload.classList.remove('glyphicon-refresh');
            btnUpload.classList.remove('glyphicon-refresh-animate');
        }
    }

    getId(url) {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        const match = url.match(regExp);

        if (match && match[2].length === 11) {
            return match[2];
        } else {
            return 'error';
        }
    }

    convertMedia(url) {
        let html;
        const pattern1 = /(?:http?s?:\/\/)?(?:www\.)?(?:vimeo\.com)\/?(.+)/g;
        const pattern2 = /(?:http?s?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?(.+)/g;
        // var pattern3 = /([-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?(?:jpg|jpeg|gif|png))/gi;

        if (pattern1.test(url)) {
            const replacement = '<iframe style="border: none; width: 100%; height: 100%;" src="//player.vimeo.com/video/$1" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>';
            html = url.replace(pattern1, replacement);
        }


        if (pattern2.test(url)) {
            let vari = url.slice(0);
            // console.log('varieeeeeeeeeee', vari)
            const id = this.getId(vari);
            const replacement = '<iframe style="border: none; width: 100%; height: 100%;" src="https://www.youtube.com/embed/' + id + '" allowfullscreen></iframe>';
            html = url.replace(pattern2, replacement);
        }

        if (!pattern1.test(url) && !pattern2.test(url)) {
            // alert('Please enter only youtube or vimeo video URL');
            this.service.sendAlert('Error', 'Please enter only youtube or vimeo video URL', 0);
            return false;
        }

        // if(pattern3.test(html)){
        //     var replacement = '<a href="$1" target="_blank"><img class="sml" src="$1" /></a><br />';
        //     var html = html.replace(pattern3, replacement);
        // }
        return this._sanitizer.bypassSecurityTrustHtml(html);
    }

    deleteMedia() {
        this.isVideoDeleted = true;
        this.external_video_edit = false;
        this.companyData.external_video_iframe = null;
    }

    imageResizeUrl(imageUrl: string, ratio: string) {
        // return imageUrl;
        if (imageUrl != null && imageUrl.length > 0 && imageUrl !== 'assets/images/no-image.jpg') {
          const array = imageUrl.split('/');
    
          const lastsegment:string = array[array.length-1];
    
          const lastsegmentcopy = lastsegment.split(/\.(?=[^\.]+$)/)[0];
    
    
          let stringobj : string = imageUrl;
          // console.log(lastsegment);
    
    
          // if(lastsegmentcopy.search('.jpg') !== -1){
    
          //   lastsegmentcopy = lastsegment.replace('.jpg','.jpeg')
    
          // }
    
          // if(lastsegmentcopy.search('.png') !== -1){
          //   lastsegmentcopy = lastsegment.replace('.png','.jpeg')
          // }
    
    
          // if(lastsegmentcopy.search('.JPG') !== -1){
    
          //   lastsegmentcopy = lastsegment.replace('.JPG','.jpeg')
    
          // }
    
          // if(lastsegmentcopy.search('.PNG') !== -1){
          //   lastsegmentcopy = lastsegment.replace('.PNG','.jpeg')
          // }
    
    
          // if(lastsegmentcopy.search('.JPG') === -1 && lastsegmentcopy.search('.jpg') === -1 && lastsegmentcopy.search('.png') === -1){
    
          //   lastsegmentcopy = lastsegment+'.png'
    
          // }
    
    
    
          let obj =  stringobj.replace(lastsegment, ratio + lastsegmentcopy+'.jpg')
    
    
    
          // console.log(obj);
          return obj;
    
    
        }else {
          // console.log('no image');
    
         return 'assets/images/no-image.jpg';
        }
      }
}
