var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit, ElementRef, QueryList } from '@angular/core';
import { ProjectService } from '../projectservice';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { isPlatformBrowser } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';
export class MessagingComponent {
    constructor(service, route, router, meta, title, platform) {
        this.service = service;
        this.route = route;
        this.router = router;
        this.meta = meta;
        this.title = title;
        this.platform = platform;
        this.activeEl = 0;
        this.loginstatus = false;
        this.freq = 'never';
        this.isFreqSettingFound = false;
        this.topics = [];
        this.showChats = true;
        this.messages = [];
        this.month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        this.showInput = false;
        this.group_details = {
            id: '',
            group_description: '',
            group_homepage_url: '',
            group_location: '',
            group_logo: '',
            group_name: '',
            group_slug: '',
            group_type: '',
            topics: []
        };
        this.isDataAvailable = false;
        this.files = [];
        this.messageGroups = [];
        this.isRouteToTopic = false;
        this.noTopicsAdded = false;
        this.hasAudio = false;
        this.hasVideo = false;
        this.collection_members = [];
        this.groupJoined = false;
        this.showActionModal = false;
        this.getMembersAPI = this.service.url + '/api-v1/groups/groupuserlist/?group_slug=';
    }
    ngOnInit() {
        this.route.params.subscribe(params => {
            if (this.isRouteToTopic) {
                return;
            }
            let length = this.meta.getTags('property="og:image"').length;
            for (let i = 0; i < length; i++) {
                this.meta.removeTag('property="og:image"');
            }
            this.service.getChatGroupDetails(params['groupname']).then(res => {
                // if (res['id'] == 2) {
                //   this.title.setTitle('Engineering career advice, Engineer First Job' + ' | ' + this.service.project_name);
                //   this.meta.updateTag({ property: 'og:title', content: 'Engineering career advice, Engineer First Job' + ' | ' + this.service.project_name });
                // } else if (res['id'] == 3) {
                //   this.title.setTitle('Engineering First Job Seekers, Engineer Career' + ' | ' + this.service.project_name);
                //   this.meta.updateTag({ property: 'og:title', content: 'Engineering First Job Seekers, Engineer Career' + ' | ' + this.service.project_name });
                // } else if (res['id'] == 4) {
                //   this.title.setTitle('Engineering Careers and Jobs - Explore' + ' | ' + this.service.project_name);
                //   this.meta.updateTag({ property: 'og:title', content: 'Engineering Careers and Jobs - Explore' + ' | ' + this.service.project_name });
                // }
                this.title.setTitle(res['title']);
                this.meta.updateTag({ property: 'og:title', content: res['title'] });
                this.meta.addTag({ property: 'og:image', content: res['group_logo'] });
                this.meta.updateTag({ property: 'og:name', content: res['group_name'] });
                this.meta.updateTag({ property: 'og:description', content: res['group_description'] });
                this.meta.updateTag({ property: 'name', content: res['group_name'] });
                this.meta.updateTag({ property: 'description', content: res['group_description'] });
                this.group_details.group_name = res['group_name'];
                this.group_details.id = res['id'];
                this.group_details.group_logo = res['group_logo'];
                this.group_details.group_description = res['group_description'];
                this.group_details.group_homepage_url = res['group_homepage_url'];
                this.group_details.group_location = res['group_location'];
                this.group_details.group_slug = res['group_slug'];
                this.group_details.group_type = res['group_type'];
                this.group_details.topics = res['topics'];
                this.isDataAvailable = true;
                // this.getMembers(this.getMembersAPI + this.group_details.group_slug);
                if (localStorage.getItem('authtoken')) {
                    this.getnotifPref();
                    //check if user is present in the group
                    this.isAlreadyMember();
                    // this.useremail = localStorage.getItem('username');
                    this.loginstatus = true;
                    this.checkplaceholder = 'Enter message here...';
                    this.service.getPeopleDetail(localStorage.getItem('full_name_slug')).subscribe(res => {
                        this.isPrivate = res['is_private'];
                        if (this.isPrivate) {
                            this.checkplaceholder = 'You must be logged in and have a RePicture public profile to add your chat.';
                        }
                        this.fullname = res['first_name'] + ' ' + res['last_name'];
                        this.profileImage = localStorage.getItem('profileImage');
                        this.full_name_slug = localStorage.getItem('full_name_slug');
                        //check if user is member of this group/ show red/limegreen depends on result
                    });
                }
                else {
                    this.loginstatus = false;
                    this.checkplaceholder = 'You must be logged in and have a RePicture public profile to add your chat.';
                }
                if (this.group_details.topics.length > 0) {
                    if (params['topicname']) {
                        let index = this.group_details.topics.findIndex(topic => {
                            return topic['slug'] == params['topicname'];
                        });
                        this.gotoTopic(params['topicname'], index);
                    }
                    else {
                        if (this.group_details.topics.length > 0) {
                            this.gotoTopic(this.group_details.topics[0]['slug'], 0);
                        }
                    }
                }
                else {
                    this.noTopicsAdded = true;
                }
                this.isDataAvailable = true;
            });
        });
        if (localStorage.getItem('authtoken')) {
            // this.useremail = localStorage.getItem('username');
            this.loginstatus = true;
            this.checkplaceholder = 'Enter message here...';
            this.service.getPeopleDetail(localStorage.getItem('full_name_slug')).subscribe(res => {
                this.isPrivate = res['is_private'];
                if (this.isPrivate) {
                    this.checkplaceholder = 'You must be logged in and have a RePicture public profile to add your chat.';
                }
            });
        }
        else {
            this.loginstatus = false;
            this.checkplaceholder = 'You must be logged in and have a RePicture public profile to add your chat.';
        }
    }
    gotoTopic(arg, index) {
        this.isRouteToTopic = true;
        this.loading = true;
        this.showChats = true;
        // this.router.navigate(['/chat/' + this.group_details.group_slug, arg]);
        this.topicClicked(index);
    }
    isAlreadyMember() {
        this.service.isAlreadyMember(this.group_details.group_slug).then(res => {
            if (res['results'].length > 0) {
                if (res['results'][0].is_joined) {
                    //if joined
                    this.groupJoined = true;
                    jQuery('.dot').css('background-color', 'limegreen');
                }
                else {
                    this.groupJoined = false;
                    jQuery('.dot').css('background-color', 'red');
                }
            }
            else {
                this.groupJoined = false;
                jQuery('.dot').css('background-color', 'red');
            }
        });
    }
    joinGroup() {
        let obj = {
            "group": this.group_details.id
        };
        this.service.joinGroup(obj).then(res => {
            this.groupJoined = true;
            jQuery('.dot').css('background-color', 'limegreen');
            //update members list now
            this.getMembers(this.getMembersAPI + this.group_details.group_slug);
        });
    }
    leaveGroup() {
        this.service.leaveGroup(this.group_details.group_slug).then(res => {
            this.showActionModal = false;
            this.groupJoined = false;
            jQuery('.dot').css('background-color', 'red');
            //update members list now
            this.getMembers(this.getMembersAPI + this.group_details.group_slug);
        });
    }
    ngAfterViewInit() {
        if (isPlatformBrowser(this.platform)) {
            this.things.changes.subscribe(t => {
                this.scrollToBottom();
            });
        }
    }
    gotoTop() {
        setTimeout(() => {
            try {
                this.myScrollContainer.nativeElement.scrollTop = 0;
            }
            catch (err) { }
        }, 4);
    }
    showMembers() {
        // this.method = 'prepend';
        this.getMembers(this.getMembersAPI + this.group_details.group_slug);
    }
    getMembers(URL) {
        this.service.getGroupMembers(URL).then((result) => __awaiter(this, void 0, void 0, function* () {
            // this.collection_members = [];
            if (result['next']) {
                this.nextMembersURL = result['next'];
            }
            else {
                this.nextMembersURL = '';
            }
            for (let i = 0; i < result['results'].length; i++) {
                let obj = result['results'][i];
                if (!obj.is_private) {
                    if (!_.isEmpty(obj.external_audio) || !_.isEmpty(obj.user_audio)) {
                        this.hasAudio = true;
                    }
                    else {
                        this.hasAudio = false;
                    }
                    if (!_.isEmpty(obj.external_video) || !_.isEmpty(obj.user_video)) {
                        this.hasVideo = true;
                    }
                    else {
                        this.hasVideo = false;
                    }
                    this.collection_members.push({ designation: obj.user_designation, name: obj.first_name + " " + obj.last_name, imageurl: obj.profileImage_thumbnail_v1 ? obj.profileImage_thumbnail_v1 : 'assets/images/default.png', userid: obj.id, hasAudio: this.hasAudio, hasVideo: this.hasVideo, username: obj.full_name_slug });
                }
            }
            // console.log(this.collection_members);
        }));
    }
    setnotifPref() {
        let obj = {
            "notification_preference": this.freq,
            "group": this.group_details.id,
            "user": localStorage.getItem('peopleid')
        };
        if (!this.isFreqSettingFound) {
            this.service.setNotificationPref(obj).then(res => {
                if (res['notification_preference']) {
                    this.freq = res['notification_preference'];
                    this.isFreqSettingFound = true;
                }
            });
        }
        else {
            this.service.updateNotificationPref(obj).then(res => {
                if (res['notification_preference']) {
                    this.freq = res['notification_preference'];
                    this.isFreqSettingFound = true;
                }
            });
        }
    }
    getnotifPref() {
        this.service.getNotificationPref(this.group_details.id).then(res => {
            if (res['notification_preference']) {
                this.freq = res['notification_preference'];
                this.isFreqSettingFound = true;
            }
        }, err => {
            if (err['status'] == 404) {
                this.isFreqSettingFound = false;
            }
            else {
                this.isFreqSettingFound = true;
            }
        });
        this.getUserEmail();
    }
    getUserEmail() {
        this.service.getUserEmail().then(res => {
            this.useremail = res['email'];
        });
    }
    sendMessageWithMedia() {
        let obj = {
            "message": this.uploadMsg,
            "group": this.group_details.id,
            "group_topic": this.topic_id,
            "attachment": null
        };
        if (this.files.length > 0) {
            obj['attachment'] = this.files[0];
        }
        this.service.addTopicMessage(obj).then(res => {
            // this.getMessages();
            this.messages = [];
            this.messages.push(res);
            this.method = 'append';
            this.formatMessages(this.messages, this.method);
        });
    }
    removeAttachment(index) {
        this.files.splice(index, 1);
    }
    fileChanged($event) {
        console.log($event.target.files);
        this.uploadMsg = '';
        this.files = [];
        _.forEach($event.target.files, (value, key) => {
            this.files.push(value);
        });
        jQuery('#UploadModal').modal('show');
    }
    gotoJoin() {
        jQuery('#topicModal').modal('hide');
        setTimeout(() => {
            this.router.navigate(['/createaccount']);
        }, 1000);
    }
    logoutMode(evt) {
        this.loginstatus = false;
        this.checkplaceholder = 'You must be logged in and have a RePicture public profile to add your chat.';
    }
    changeEmailCheck($event) {
        console.log($event);
        this.showInput = $event.target.checked;
    }
    topicClicked(index) {
        this.activeEl = index;
        this.topic_slug = this.group_details.topics[index]['slug'];
        this.topic_id = this.group_details.topics[index]['id'];
        this.messageGroups = [];
        this.messages = [];
        this.getMessages();
    }
    radioClick(reason) {
        if (reason === '7') {
            this.showOther = true;
            this.reason1 = reason;
        }
        else {
            this.showOther = false;
            this.reason1 = reason;
        }
    }
    addTopic() {
        if (this.topicName == '') {
            this.service.sendAlert('Error', 'Topic cannot be empty!', 0);
            return;
        }
        let obj = {
            "name": this.topicName,
            "group": this.group_details.id
        };
        this.service.addGroupTopic(obj).then(res1 => {
            this.showChats = true;
            this.noTopicsAdded = false;
            this.service.getChatGroupDetails(this.group_details.group_slug).then(res => {
                this.group_details.topics = res['topics'];
                console.log(this.topicName);
                // let newIndex = _.findIndex(this.group_details.topics, { name: this.topicName });
                for (var i = 0; i < this.group_details.topics.length; ++i) {
                    console.log('this.group_details.topics[i]', this.group_details.topics[i]);
                    if (this.group_details.topics[i]['name'] == this.topicName) {
                        console.log('newIndex', i);
                        this.topicClicked(i);
                        break;
                    }
                }
            });
        });
    }
    scrollChats(evt) {
        // console.log('top',jQuery('.jumbo')[0].offsetTop);
        // console.log(evt.target.scrollTop, jQuery('.jumbo')[0].offsetTop);
        if (evt.target.scrollTop > jQuery('.jumbo')[0].offsetTop + 39) {
            jQuery('#jumbo').addClass('sticky-title');
        }
        else {
            jQuery('#jumbo').removeClass('sticky-title');
        }
    }
    openNav() {
        document.getElementById("mySidenav").style.marginLeft = "0";
        document.getElementById("mySidenav").style.padding = "0 15px";
    }
    closeNav() {
        if (this.service.mobilecheck()) {
            document.getElementById("mySidenav").style.marginLeft = "-240px";
            document.getElementById("mySidenav").style.padding = "0";
        }
    }
    joinG() {
        if (localStorage.getItem('authtoken') && !this.groupJoined && this.group_details.group_type.toUpperCase() == 'PUBLIC') {
            this.joinGroup();
        }
        else {
            this.service.sendAlert('Warning', "You must have a RePicture public profile and be logged in to join a group. To create a RePicture public profile, go <a class='routerlink' attr.link='/createaccount'>here</a>.", 1);
            return;
        }
    }
    submitReport() {
        let islogged = false;
        if (!localStorage.getItem('authtoken')) {
            // this.service.sendAlert('Error', "Please login to continue!", 0);
            // return;
            islogged = false;
        }
        else {
            islogged = true;
        }
        if (this.showOther) {
            if (!this.otherReason) {
                //alert('Please enter your reason!');
                this.service.sendAlert('Error', 'Please enter your reason!', 0);
                return;
            }
        }
        else {
            if (!this.reason1) {
                //alert('Please select one of your reason!');
                this.service.sendAlert('Error', 'Please select one of your reason!', 0);
                return;
            }
        }
        let obj = {
            "reason": this.reason1,
            "group": this.group_details.id
        };
        if (this.showOther) {
            obj['other_reason'] = this.otherReason;
        }
        this.service.flagGroup(obj, islogged).then(res => {
            // this.flaggedAlready = true;
            // jQuery('#flagModal2').modal('hide');
            // let x2 = document.getElementById("snackbar3");
            // x2.className = "show";
            // setTimeout(function () { x2.className = x2.className.replace("show", ""); }, 3000);
        });
    }
    searchMessages(msg) {
        if (msg == '') {
            return;
        }
        let obj = {
            "group_slug": this.group_details.group_slug,
            "topic_slug": this.topic_slug,
            "message": msg
        };
        this.service.getTopicMessages(obj).then(res => {
            // console.log(res.results);
            this.showChats = true;
            this.messages = _.reverse(res['results']);
            this.messageGroups = [];
            this.formatMessages(this.messages, 'append');
        });
    }
    sendMessage(msg) {
        if (msg == '') {
            return;
        }
        let obj = {
            "message": msg,
            "group": this.group_details.id,
            "group_topic": this.topic_id
        };
        this.service.addTopicMessage(obj).then(res => {
            // this.getMessages();
            this.messages = [];
            this.messages.push(res);
            this.method = 'append';
            this.formatMessages(this.messages, this.method);
        });
    }
    getMessages() {
        let obj = {
            "group_slug": this.group_details.group_slug,
            "topic_slug": this.topic_slug,
            "message": ""
        };
        this.service.getTopicMessages(obj).then(res => {
            // console.log(res.results);
            this.next = res['next'];
            this.messages = _.reverse(res['results']);
            this.method = 'append';
            this.formatMessages(this.messages, this.method);
        });
    }
    formatMessages(messages, method) {
        let removeIndices = [];
        for (let i = 0; i < messages.length; i++) {
            let date = this.getDate(messages[i].timestamp);
            let time = this.getTime(messages[i].timestamp);
            messages[i]['timestamp_date'] = date;
            messages[i]['timestamp_time'] = time;
            const index = this.messageGroups.findIndex(itemx => {
                return itemx.timestamp_date === date;
            });
            // console.log('index', index, messages[i]);
            if (index != -1) {
                if (method == 'prepend') {
                    this.messageGroups[index].data.unshift(messages[i]);
                }
                else if (method == 'append') {
                    this.messageGroups[index].data.push(messages[i]);
                }
                removeIndices.push(i);
            }
        }
        // console.log(removeIndices);
        if (removeIndices.length > 0) {
            removeIndices = _.reverse(removeIndices);
            // console.log(messages.slice(0));
            for (let i = 0; i < removeIndices.length; i++) {
                messages.splice(removeIndices[i], 1);
            }
        }
        // console.log('messagessfsf', messages);
        let newMessages = _.chain(messages)
            .groupBy("timestamp_date")
            .toPairs()
            .map(function (currentItem) {
            return _.fromPairs(_.zip(["timestamp_date", "data"], currentItem));
        })
            .value();
        if (method == 'prepend') {
            this.messageGroups.unshift(...newMessages);
            // console.log('this.myScrollContainer', this.messageGroups.slice(0));
        }
        else {
            this.messageGroups.push(...newMessages);
        }
        this.loading = false;
    }
    getDate(timestamp) {
        let datetime = new Date(timestamp);
        // console.log('datetime',datetime);
        return this.month[datetime.getMonth()] + ' ' + ("0" + datetime.getDate()).slice(-2) + ', ' + datetime.getFullYear(); // + ' ' + ("0" + datetime.getHours()).slice(-2) + ':' + ("0" + datetime.getMinutes()).slice(-2);
    }
    getTime(timestamp) {
        let datetime = new Date(timestamp);
        // console.log('datetime',datetime);
        // return this.month[datetime.getMonth()] + ' ' + ("0" + datetime.getDate()).slice(-2) + ', ' + datetime.getFullYear();  + ' ' + 
        return ("0" + datetime.getHours()).slice(-2) + ':' + ("0" + datetime.getMinutes()).slice(-2);
    }
    onScrollDown() {
        if (this.nextMembersURL && !this.showChats) {
            this.getMembers(this.nextMembersURL);
        }
    }
    onScrollUp() {
        if (this.next && this.showChats) {
            this.method = 'prepend';
            this.loading = true;
            this.service.getTopicMessagesPagination(this.next).then(res => {
                this.next = res['next'];
                this.messages = _.reverse(res['results']);
                this.formatMessages(this.messages, this.method);
                this.loading = false;
            });
        }
    }
    scrollToBottom() {
        if (!this.showChats) {
            this.method = 'prepend';
        }
        else {
            this.method = 'append';
        }
        console.log(this.method, 'method');
        if (this.method == 'append') {
            try {
                // console.log('this.myScrollContainer1', this.messageGroups);
                this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
            }
            catch (err) { }
        }
    }
}
