
    <div *ngIf="mainArray['pr_user_ordering'].length > 0">

        <h3>Examples of Projects I've Worked On</h3>
        
        <div cdkDropList class="example-list" (cdkDropListDropped)="dropPeopleProject($event)">
            <div class="example-box" *ngFor="let project of mainArray['pr_user_ordering']" cdkDrag>
               <img style="width: 60px;height: 60px;border-radius: 50%;" src="{{project.project_images[0].image_thumbnail}}" alt="">
                <p style="padding: 0px 20px;margin: 0;">{{project.project_name}}</p>
                </div>
        </div>
    </div>



<div *ngIf="mainArray['adopted'].length > 0">

    <h3>Projects I've Authored on RePicture</h3>
    
    <div cdkDropList class="example-list" (cdkDropListDropped)="dropProjectAdopted($event)">
        <div class="example-box" *ngFor="let project of mainArray['adopted']" cdkDrag>
           <img style="width: 60px;height: 60px;border-radius: 50%;" src="{{project.imageurl}}" alt="">
            <p style="padding: 0px 20px;margin: 0;">{{project.name}}</p>
            </div>
    </div>
</div>

<div *ngIf="mainArray['adopted_with_others'].length > 0">

    <h3>Projects I've Authored With Others</h3>
    
    <div  cdkDropList class="example-list" (cdkDropListDropped)="dropProjectAdoptedWithOthers($event)">
        <div class="example-box" *ngFor="let project of mainArray['adopted_with_others']" cdkDrag>
           <img style="width: 60px;height: 60px;border-radius: 50%;" src="{{project.imageurl}}" alt="">
            <p style="padding: 0px 20px;margin: 0;">{{project.name}}</p>
            </div>
    </div>
</div>
