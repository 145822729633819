<navbar></navbar>
<div>
    <div *ngIf="!loginStatus" >
        <regForm [forStudent]="false"></regForm>
    </div>
    <div *ngIf="loginStatus && !uploadPic && !success" class="col-sm-6 col-sm-offset-3 text-center">
        <h4 style="color: gray;font-family: OpenSans-Semibold;">Help others find you</h4>
        <br>
        <div class="checkboxdiv text-left">
            <div>
                <h4 style="color: gray;font-family: OpenSans-Semibold;text-align: center;">I'm a...</h4>
                <br>
                <p>
                    <label class="checkbox-inline"><input type="checkbox" [(ngModel)]="studentCheck" name="personType" />Student</label>
                </p>
                <p *ngIf="studentCheck">
                    <select class="form-control" [(ngModel)]="selectedStudent">
            <option *ngFor="let item of students" value="{{item}}">{{item}}</option>
          </select>
                    <input *ngIf="selectedStudent == 'Other'" placeholder="Enter other" style="margin-top: 5px;" type="text" class="form-control" [(ngModel)]="studentsOther" />
                </p>
                <p>
                    <label class="checkbox-inline"><input type="checkbox" [(ngModel)]="educatorCheck" name="personType" />Educator</label>
                </p>
                <p *ngIf="educatorCheck">
                    <my-dropdown [defaultText]="'Education'" [selectedOptions]="selectedEducator" [myOptions]="educatorsOptions" (selectedArray)="onChangedd($event)"></my-dropdown>
                    <input *ngIf="othersEducator" placeholder="Enter other" style="margin-top: 5px;" type="text" class="form-control" [(ngModel)]="educatorOther" />
                </p>
                <p>
                    <label class="checkbox-inline"><input type="checkbox" [(ngModel)]="professionalCheck" name="personType" />Professional</label>
                </p>
                <p *ngIf="professionalCheck">
                    <select [(ngModel)]="designation" class="form-control">
            <option  value="Civil Engineer">Civil Engineer</option>
                            
            <option  value="Architect">Architect</option>

            <option  value="Construction Contractor">Construction Contractor</option>

            <option  value="Developer">Developer</option>

            <option  value="Electrical Engineer">Electrical Engineer</option>

            <option  value="Environmental Consultant">Environmental Consultant</option>

            <option  value="Estimator">Estimator</option>

            <option  value="Landscape Architect">Landscape Architect</option>

            <option  value="Mechanical Engineer">Mechanical Engineer</option>

            <option  value="Owner">Owner</option>

            <option  value="Planner">Planner</option>

            <option  value="Surveyor">Surveyor</option>

            <option  value="Software Engineer">Software Engineer</option>

            <option  value="Artist">Artist</option>

            <option  value="Designer">Designer</option>

            <option  value="Other">Other</option>

          </select>
                    <input *ngIf="designation == 'Other'" placeholder="Enter other" style="margin-top: 5px;" type="text" class="form-control" [(ngModel)]="professionalOther" />
                </p>
                <br>
                <br>
                <button class="btn btn-default" (click)="uploadPic = true;">Skip for now</button> <button (click)="addUserDetails()" style="margin-left: 10px;" class="btn btn-default login">Continue</button>
            </div>
        </div>
    </div>
    <div *ngIf="loginStatus && uploadPic" class="col-sm-6 col-sm-offset-3 text-center">
        <h4 style="color: gray;font-family: OpenSans-Semibold;">Add a photo of yourself doing something you love.</h4>
        <br><br>
        <p *ngIf="uploaded">
            <i class="glyphicon glyphicon-ok" style="font-size: 18px; background-color: #ffe284; padding: 7px 10px 10px 10px;"> <span style="font-family: OpenSans-Bold;">Uploaded</span></i>
        </p>
        <div *ngIf="uploading" class="loaderParent">
        </div>
        <input type="file" (change)="fileChange($event)" #input style="visibility: hidden;" />
        <div style="display: inline-block;">
            <div *ngIf="!image" class="imgParent1" style="border-radius: 0;background-image: url(/assets/images/uploadpic.png)" (click)="choosePic()"></div>
            <div id="be" class="imgParent1" style="height: 0px;">
            </div>
        </div>
        <br><br><br>
        <button (click)="uploadPic = false; success = true;" class="btn btn-default" style="font-family: OpenSans-Bold;">Skip for now</button> <button (click)="uploadPic = false; success = true;" style="margin-left: 10px;" class="btn btn-default login">Continue</button>
    </div>
    <div *ngIf="loginStatus && success" class="text-center">
        <h4 *ngIf="!isPublicProfile" style="color: gray;font-family: OpenSans-Semibold;padding-top: 40px;">Congratulations! A profile has been created for you. <a style="color:#4688F0;" routerLinkActive="active" routerLink="/userprofile/{{peopleid}}">Click here</a> to view profile.</h4>
        <h4 *ngIf="isPublicProfile" style="color: gray;font-family: OpenSans-Semibold;padding-top: 40px;">Congratulations! Your profile now belongs to you. <a style="color:#4688F0;" routerLinkActive="active" routerLink="/userprofile/{{peopleid}}">Click here</a> to view profile.</h4>
    </div>
</div>
<style>
    @font-face {
        font-family: OpenSans-Regular;
        src: url('/assets/fonts/new/opensans-regular-webfont.eot?#iefix') format('embedded-opentype'), /* IE */
        url('/assets/fonts/new/opensans-regular-webfont.ttf') format("truetype");
        /* non-IE */
    }
    
    @font-face {
        font-family: OpenSans-Light;
        src: url('/assets/fonts/new/opensans-light-webfont.eot?#iefix') format('embedded-opentype'), /* IE */
        url('/assets/fonts/new/opensans-light-webfont.ttf') format("truetype");
        /* non-IE */
    }
    
    @font-face {
        font-family: OpenSans-Bold;
        src: url('/assets/fonts/new/opensans-bold-webfont.eot?#iefix') format('embedded-opentype'), /* IE */
        url('/assets/fonts/new/opensans-bold-webfont.ttf') format("truetype");
        /* non-IE */
    }
    
    @font-face {
        font-family: OpenSans-ExtraBold;
        src: url('/assets/fonts/new/opensans-extrabold-webfont.eot?#iefix') format('embedded-opentype'), /* IE */
        url('/assets/fonts/new/opensans-extrabold-webfont.ttf') format("truetype");
        /* non-IE */
    }
    
    @font-face {
        font-family: OpenSans-Semibold;
        src: url('/assets/fonts/new/opensans-semibold-webfont.eot?#iefix') format('embedded-opentype'), /* IE */
        url('/assets/fonts/new/opensans-semibold-webfont.ttf') format("truetype");
        /* non-IE */
    }
    
    /* * {
        color: gray;
    } */
    
    .signupbox {
        background-color: #d6d6d6;
        text-align: center;
        padding: 18px;
    }
    
    .login {
        background-color: #ffe284;
        /*border: none;*/
        font-family: OpenSans-Bold;
        margin-top: -2px;
    }
    
    .heading {
        color: #757575;
        font-family: OpenSans-Regular !important;
        border-bottom: 2px solid #ffdd73;
        padding-bottom: 20px;
        margin-bottom: 20px;
        padding-left: 15px;
        padding-right: 15px;
        text-align: center;
    }
    
    .checkboxdiv {
        display: flex;
        justify-content: center;
        color: gray;
        font-family: OpenSans-Semibold;
    }
    
    .imgParent {
        width: 100%;
        padding-top: 75%;
        position: relative;
    }
    
    .imgParent1 {
        width: 150px;
        height: 150px;
        background-size: cover;
        background-position: top center;
        border-radius: 50%;
    }
    
    .imgParent img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        max-height: 100%;
        max-width: 100%;
        display: block;
        margin: auto;
        padding: 6px 0;
    }
    
    .loaderParent {
        background-image: url(/assets/images/loader-new.gif);
        margin: 0 auto;
        background-repeat: no-repeat;
        background-position: center;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        position: inherit;
    }
    
    .loader {
        border: 4px solid #eae7e7;
        border-radius: 50%;
        border-top: 4px solid #3498db;
        width: 120px;
        height: 120px;
        -webkit-animation: spin 1s linear infinite;
        animation: spin 1s linear infinite;
    }
    
    @-webkit-keyframes spin {
        0% {
            -webkit-transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
        }
    }
    
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
</style>