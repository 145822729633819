<navbar></navbar>
<h1 class="heading">Account</h1>
<div>
    <div [ngClass]="{'leftmenu': !service.mobilecheck(), 'navbar-fixed-left': !service.mobilecheck()}" class="navbar">
        <ul class="nav navbar-nav"  [ngClass]="{'mobile-ul': service.mobilecheck()}">
            <li class="text-center pad" [ngClass]="{'active': selectedItemId == 1}" (click)="selectedItemId = 1">Change Password</li>
            <li class="text-center pad" [ngClass]="{'active': selectedItemId == 3}" (click)="selectedItemId = 3">Profile Privacy</li>
            <li class="text-center pad" [ngClass]="{'active': selectedItemId == 4}" (click)="selectedItemId = 4">Groups</li>
            <li class="text-center pad" [ngClass]="{'active': selectedItemId == 2}" (click)="selectedItemId = 2">Ask a Question/Follow</li>
        </ul>
    </div>
    <div [ngClass]="{'rightList': !service.mobilecheck()}">
        <div *ngIf="selectedItemId == 1" class="col-md-8" style="max-width: 500px;">
            <div class="form-group">
                <label for="pOld">Old Password
                    <sup>*</sup>
                </label>
                <input type="password" #pOld class="form-control" id="pOld" placeholder="Enter password" required
                    pattern="(?=^.{6,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$" name="pwdOld">
            </div>
            <div class="form-group">
                <label for="p1">New Password
                    <sup>*</sup>
                    <span style="font-size: 11px; font-style: italic; color: gray; line-height: 0;"> (Passwords must be
                        6 or more characters and include at least one uppercase letter, one lowercase letter, and one
                        number or special character)</span>
                </label>
                <input type="password" #p1 class="form-control" id="p1" placeholder="Enter password" required
                    pattern="(?=^.{6,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$" name="pwd1">
            </div>
            <div class="form-group">
                <label for="p2">Confirm New Password
                    <sup>*</sup>
                </label>
                <input type="password" #p2 class="form-control" id="p2" placeholder="Re-type password" required
                    pattern="(?=^.{6,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$" name="pwd2">
            </div>
            <p>
                <button class="btn btn-primary" (click)="changePassword(pOld.value, p1.value, p2.value)"
                    style="background-color: #1a496d; border-radius: 0px;">Change Password</button>
            </p>
        </div>
        <div *ngIf="selectedItemId == 3" class="col-md-8" style="max-width: 500px;">
            <div>
                <p class="checkbox-inline" style="display: block; margin-top: 20px;">
                    <input [(ngModel)]="privacy" type="checkbox" (change)="changePrivacy()" />Make my profile private
                    <br>
                    <span style="font-size: 12px; font-style: italic;">Note: Your profile will be hidden.</span>
                </p>
                <p *ngIf="showCompanyPrivacy" class="checkbox-inline"
                    style="display: block; margin-top: 20px;margin-left: 0px;">
                    <input [(ngModel)]="privacyCompany" type="checkbox" (change)="changePrivacyCompany()" />Make my
                    company private
                    <br>
                    <span style="font-size: 12px; font-style: italic;">Note: Your company will be hidden.</span>
                </p>
            </div>
            <!-- <div *ngIf="!isAdult">
                <p>Since you indicated you are under 18 years old, you are not eligible to make privacy changes. Your
                    profile will remain private.</p>
            </div> -->
        </div>
        <div *ngIf="selectedItemId == 2" class="col-md-8" style="max-width: 500px;">
            <label>Indicate your preference to receive an email when a question has been posted to a project you have adopted or followed.</label>
            <div style="font-family: OpenSans-regular;margin-left: 15px;">
                <p>
                    <label class="radio-inline">
                        <input type="radio" name="frequency" value="never" [(ngModel)]="freq">Never
                    </label>
                </p>
                <p>
                    <label class="radio-inline">
                        <input type="radio" name="frequency" value="realtime" [(ngModel)]="freq" />Real time
                    </label>
                </p>
                <p>
                    <label class="radio-inline">
                        <input type="radio" name="frequency" value="weekly" [(ngModel)]="freq" />Weekly
                    </label>
                </p>
                <p>
                    <label class="radio-inline">
                        <input type="radio" name="frequency" value="daily" [(ngModel)]="freq" />Daily
                    </label>
                </p>
                <button class="btn active" style="border-radius: 0;" (click)="savePref()">Save preference</button>
            </div>
            <div *ngIf="freq != 'never'" style="margin-top: 10px;">
                <label>Notifications will be sent to:</label>
                <p>{{userEmail}}</p>
            </div>
        </div>
        <div *ngIf="selectedItemId == 4">
            <p>
                Your current group membership and notifications are as follows. To change, go to the group and click on your name (on the left). See all groups at <a routerLink="/chat">RePicture.com/chat</a>
            </p>
            <div class="col-md-8" style="max-width: 500px;">
                <table style="width: 100%;">
                    <tr>
                        <th></th>
                        <th><span>Member</span></th>
                        <th><span>Notification</span></th>
                    </tr>
                    <tr *ngFor="let item of userGroups">
                        <td><a routerLink="/chat/{{item.slug}}">{{item.name}}</a></td>
                        <td>{{item.member}}</td>
                        <td style="text-transform: capitalize;">{{item.notifs}}</td>
                    </tr>
                    <tr *ngIf="userGroups.length == 0">
                        <td>None</td>
                        <td>NA</td>
                        <td>NA</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
    <style>
        td {
            padding: 5px;
        }

        td a {
            text-decoration: underline;
        }

        th span {
            text-decoration: underline;
            padding: 0 5px;
        }

        sup {
            color: red;
        }

        .parent {
            position: fixed;
            top: 153px;
            bottom: 0px;
            width: 100%;
        }

        .rightList {
            height: 100%;
            overflow: auto;
            margin-left: 200px;
            padding: 8px 20px;
        }

        .leftmenu {
            display: flex;
            justify-content: center;
            margin-top: 9px;
            width: 200px;
        }

        .pad {
            padding: 10px;
            cursor: pointer;
        }

        .active {
            color: white;
            background-color: #1a496d;
        }

        .navbar-fixed-left {
            position: fixed;
            border-radius: 0;
            height: 100%;
        }

        .navbar-fixed-left .navbar-nav>li {
            float: none;
            width: 139px;
        }

        .navbar-fixed-left+.container {
            padding-left: 100px;
        }

        .navbar-fixed-left .navbar-nav>li>.dropdown-menu {
            margin-top: -50px;
            margin-left: 140px;
        }

        @font-face {
            font-family: OpenSans-Regular;
            src: url('/assets/fonts/new/opensans-regular-webfont.eot?#iefix') format('embedded-opentype'),
                /* IE */
                url('/assets/fonts/new/opensans-regular-webfont.ttf') format("truetype");
            /* non-IE */
        }

        @font-face {
            font-family: OpenSans-Light;
            src: url('/assets/fonts/new/opensans-light-webfont.eot?#iefix') format('embedded-opentype'),
                /* IE */
                url('/assets/fonts/new/opensans-light-webfont.ttf') format("truetype");
            /* non-IE */
        }

        @font-face {
            font-family: OpenSans-Bold;
            src: url('/assets/fonts/new/opensans-bold-webfont.eot?#iefix') format('embedded-opentype'),
                /* IE */
                url('/assets/fonts/new/opensans-bold-webfont.ttf') format("truetype");
            /* non-IE */
        }

        @font-face {
            font-family: OpenSans-ExtraBold;
            src: url('/assets/fonts/new/opensans-extrabold-webfont.eot?#iefix') format('embedded-opentype'),
                /* IE */
                url('/assets/fonts/new/opensans-extrabold-webfont.ttf') format("truetype");
            /* non-IE */
        }

        @font-face {
            font-family: OpenSans-Semibold;
            src: url('/assets/fonts/new/opensans-semibold-webfont.eot?#iefix') format('embedded-opentype'),
                /* IE */
                url('/assets/fonts/new/opensans-semibold-webfont.ttf') format("truetype");
            /* non-IE */
        }

        * {
            font-family: OpenSans-Regular;
        }

        .ach {
            color: black !important;
            text-decoration: none !important;
        }

        .loader1 {
            border: 4px solid #f3f3f3;
            border-top: 4px solid #3498db;
            border-radius: 50%;
            width: 50px;
            height: 50px;
            animation: spin 1s linear infinite;
            position: absolute;
            top: 35%;
            left: 44%;
        }

        @keyframes spin {
            0% {
                transform: rotate(0deg);
            }

            100% {
                transform: rotate(360deg);
            }
        }

        .container1 h2,
        .container1 p {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        .container1 h2 {
            padding-bottom: 3px;
        }

        .mapsBtn {
            background-color: burlywood;
            cursor: pointer;
            padding: 6px;
            margin-left: 2px;
            text-align: center;
            font-weight: bold;
            color: black !important;
            text-decoration: none !important;
        }

        .imgParent {
            width: 100%;
            padding-top: 75%;
            position: relative;
            background: rgba(212, 212, 212, 0.4);
        }

        .imgParent img {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            max-height: 100%;
            max-width: 100%;
            display: block;
            margin: auto;
        }

        .column {
            margin-bottom: 10px;
            margin-top: 10px;
        }

        @media (max-width: 650px) {
            .column {
                width: 100%;
                display: block;
            }
        }

        .card {
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        }

        .container1 {
            padding: 0 16px;
        }

        .container1::after,
        .row::after {
            content: "";
            clear: both;
            display: table;
        }

        .button {
            border: none;
            outline: 0;
            display: inline-block;
            padding: 8px;
            color: white;
            background-color: #1a496d;
            text-align: center;
            cursor: pointer;
            width: 100%;
        }

        .button:hover {
            background-color: burlywood;
        }

        .img-nearby {
            float: left;
            margin-left: 40px;
            width: 130px;
        }

        .nearby-txtcolor {
            color: #757575;
            font-size: 13px;
        }

        .nearby-content {
            font-size: 80%;
            margin-top: -5%;
            font-family: OpenSans-Light;
            color: #818181;
        }

        .heading {
            color: #757575;
            font-family: OpenSans-Regular !important;
            border-bottom: 2px solid #ffdd73;
            padding-bottom: 30px;
            padding-left: 15px;
            padding-right: 15px;
        }

        .projectsTypes {
            color: #757575;
            font-family: OpenSans-Regular !important;
            font-size: 16px;
        }

        .projects {
            padding: 20px 0;
        }

        .projects:not(:last-child) {
            border-bottom: 2px solid gray;
        }

        .mobile-ul {
            margin: 0;
            display: flex;
            justify-content: center;
        }

    </style>