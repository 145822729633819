import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectService } from '../projectservice';

@Component({
  selector: 'app-student-dashboard',
  templateUrl: './student-dashboard.component.html',
  styleUrls: ['./student-dashboard.component.css']
})
export class StudentDashboardComponent implements OnInit {
  students = [];
  show: boolean = false;
  techer_name: string;
  colorCode: string;
  isCustomDashboardName: boolean = false;
  isHeadTA: boolean = false;
  showEdit: boolean = false;
  constructor(private router: ActivatedRoute, private service: ProjectService, private route: Router) { }

  ngOnInit() {
    // this.techer_name = localStorage.getItem('ta_name');
    this.router.params.subscribe(params => {
      if (params['id'] != localStorage.getItem('peopleid')) {
        this.route.navigate(['']);
        return;
      }
      this.service.getUserdetailbyId(params['id']).then(res => {
        this.isHeadTA = res['is_head_ta'];
      });

      this.service.getStudentsDetails(params['id']).then(res => {
        this.students = res['results'];
        if(this.students.length > 0) {
          if (this.students[0].dashboard_name) {
            this.isCustomDashboardName = true;
            this.techer_name = this.students[0].dashboard_name;
          } else {
            this.isCustomDashboardName = false;
            this.techer_name = this.students[0].ta_fullname+"'s Dashboard";
          }
        }
        this.show = true;
      });
    });
  }

  patchDashboardTitle() {
    let obj = {
      dashboard_name: this.techer_name
    };

    this.service.patchDashboardTitle(obj).then(res => {
      this.techer_name = this.techer_name;
      this.showEdit = false;
      this.isCustomDashboardName = true;
    });
  }

  getColorStatus(status: any) {
    switch (status) {
      case 'Ready for outside review':
        this.colorCode = "black";
        break;
      case "No changes needed":
        this.colorCode = 'green';
        break;
      case 'Minor changes needed':
        this.colorCode = 'yellow';
        break;
      case 'Major changes needed':
        this.colorCode = 'red';
        break;
      default:
        this.colorCode = 'white';
    }
    return this.colorCode;
  }

}
