<navbar></navbar>
<div class="login-container">
    <div class="heading">
        <h3>
            Log in to your account
        </h3>
        <h5>
            Don't have an account? <a routerLink="/createaccount">Sign Up</a>
        </h5>
    </div>


    <div class="login-main">
        <form>
            <div class="">
                <div class="form-group">

                    <app-form-field [label]="'Enter your email (username)'">
                        <input placeholder="Email (Username)*" class="form-control"  appInput
                            type="text" [(ngModel)]="loginObject.email" [ngModelOptions]="{standalone: true}"
                            required />
                    </app-form-field>
                    <!-- <input type="text" class="form-control" placeholder="Email (Username)*" [(ngModel)]="loginObject.email"
                        [ngModelOptions]="{standalone: true}" /> -->
                </div>
                <div class="form-group">
                    <app-form-field [label]="'Enter your password'">
                        <input placeholder="Password*" class="form-control"  appInput type="password"
                            [(ngModel)]="loginObject.password" [ngModelOptions]="{standalone: true}" required />
                    </app-form-field>
                    <!-- <input type="password" class="form-control" placeholder="Password*" [(ngModel)]="loginObject.password"
                        [ngModelOptions]="{standalone: true}" /> -->
                </div>
                <div class="form-group">
                    <button rp-button type="primary" size="medium" class="" style="width: 100%;"
                        [disabled]="loginClicked" (click)="login();">
                        LOGIN
                    </button>
                </div>
                <div class="forgot-pw">
                    <a data-toggle="modal" data-target="#forgotpwd">Forgot password?</a>
                </div>
            </div>
        </form>
        <!-- <h5 style="
              width: 100%;
              text-align: center;
              border-bottom: 1px solid #9999996b;
              line-height: 0.1em;
              margin: 10px 0 20px;
              color: #999;
            ">
            <span style="
                background: #fff;
                padding: 5px 7px;
                border-radius: 50%;
                font-weight: bold;
              ">or</span>
        </h5>
        <div class="lkdnText">
            <p>
                Login with your LinkedIn Account
            </p>

            <p (click)="linkedinLogin()" class="lkdnLogo"><img src="/assets/images/linkedin_logo.png" /></p>
        </div> -->
    </div>
</div>


<div id="forgotpwd" class="modal fade" role="dialog">
    <div class="modal-dialog" style="margin: 0 auto; top: 5%; color: black">
        <div class="modal-content" style="border-radius: 0px">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" style="height: auto; padding: 15px">
                    &times;
                </button>
                <h4 class="modal-title" style="margin-bottom: 0px; padding: 12px">
                    Forgot password
                </h4>
            </div>
            <div class="modal-body" style="text-align: center; height: auto">
                <p style="font-style: italic">
                    Enter the email you used to join RePicture. Password reset
                    instructions will then be sent to this email. If you do not remember
                    the email you used, send a note (with your profile name) to
                    password@repicture.com.
                </p>
                <input type="text" class="form-control" style="width: 60%; display: inline-block"
                    placeholder="Enter email" [(ngModel)]="emailForgot" />
                <button style="
              margin-top: 0;
              vertical-align: bottom;
              padding: 7px;
              margin-left: 5px;
            " class="btn btn-default" data-dismiss="modal" (click)="sendResetPwdEmail()">
                    OK
                </button>
            </div>
        </div>
    </div>
</div>