// import { ProjectCreate } from './../projectcreatecomponent/projectcreate.component';
import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ProjectService } from '../projectservice';
// import { PopoverModule } from "ngx-popover";

import * as _ from 'lodash';
import { Router } from '@angular/router';
declare var jQuery: any;

@Component({
  selector: 'manage-projects',
  templateUrl: './manageprojects.component.html',
  // styleUrls: ['../../node_modules/bootstrap/dist/css/bootstrap.min.css']
})

export class ManageProjects {
  confirmObject: { 'confirmTitle': string; 'tag': string; 'project_id': any; 'index': any; };
  projects: any = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  userProjects: any = [];
  userProjectsInprogress: any = [];
  userProjectsAdopted: any = [];
  userProjectsUploaded: any = [];
  userProjectsTagged: any = [];
  userProjectsFollowed: any = [];
  userProjectsFlagged: any = [];
  userProjectsAdoptedWithOthers: any = [];
  noFlagged: boolean = false;
  noUploaded: boolean = false;
  noAdopted: boolean = false;
  noInprogress: boolean = false;
  noTagged: boolean = false;
  noTaggedOrg: boolean = false;
  noFollowed: boolean = false;
  selectedItemId: any = 1;
  loading: boolean = false;
  org_id: any;
  owner_display_preferences: string;
  constructor(private title: Title, public service: ProjectService, private router: Router) {
    if (!localStorage.getItem('full_name_slug')) {
      this.router.navigate(['']);
    }
  }

  ngOnInit() {
    this.title.setTitle("Manage projects | Repicture App");
    this.loading = true;
    this.getAllUserProjects();
    this.getUserTaggedProjects();
    this.getUserFollowedProjects();
    if (this.service.isAdmin) {
      this.getFlaggedProjects();
    }
  }

  ngAfterContentChecked() {
    this.userProjectsInprogress = _.uniqBy(this.userProjectsInprogress, 'project_id');
    this.userProjectsAdopted = _.uniqBy(this.userProjectsAdopted, 'project_id');
    this.userProjectsUploaded = _.uniqBy(this.userProjectsUploaded, 'project_id');
  }

  ngAfterViewInit() {
    this.service.getProjectsAdoptedWithOthers(localStorage.getItem('full_name_slug'), true).then(res => {
      this.userProjectsAdoptedWithOthers = res;
      this.userProjectsAdoptedWithOthers.forEach(element => {
        if (element.project_publish_status == 'In-Progress') {
          this.userProjectsInprogress.push({ address: element.shorter_location ? element.shorter_location : element.project_location, name: element.project_name ? element.project_name : 'Name This Project', imageurl: element.project_images.length > 0 ? element.project_images[0].image : 'assets/images/no-image.jpg', project_id: element.project_id, project_name_slug: element.project_name_slug });
          this.noInprogress = false;

        }
        else if (element.project_publish_status == 'Adopted') {
          this.userProjectsAdopted.push({ address: element.shorter_location ? element.shorter_location : element.project_location, name: element.project_name ? element.project_name : 'Name This Project', imageurl: element.project_images.length > 0 ? element.project_images[0].image : 'assets/images/no-image.jpg', project_id: element.project_id, project_name_slug: element.project_name_slug });

          // this.userProjectsUploaded.push({ address: element.shorter_location ? element.shorter_location : element.project_location, name: element.project_name ? element.project_name : 'Name This Project', imageurl: element.project_images.length > 0 ? element.project_images[0].image : 'assets/images/no-image.jpg', project_id: element.project_id, project_name_slug: element.project_name_slug });
          this.noUploaded = false;

        }
        else if (element.is_published == true ) {
          console.log("hiiiiiiiiiiii");
          this.userProjectsUploaded.push({ address: element.shorter_location ? element.shorter_location : element.project_location, name: element.project_name ? element.project_name : 'Name This Project', imageurl: element.project_images.length > 0 ? element.project_images[0].image : 'assets/images/no-image.jpg', project_id: element.project_id, project_name_slug: element.project_name_slug });
          this.noUploaded = false;

        }
      });
    });
  }

  confirmedAction($event) {
    switch ($event['tag']) {
      case 'delete':
        this.confirmDeleteProject($event['project_id'], $event['index']);
        break;

      case 'unfollow':
        this.confirmUnfollowProject($event['project_id'], $event['index']);
        break;

      case 'untag':
        this.confirmUntagProject($event['project_id'], $event['index']);
        break;

      case 'untag_org':
        this.confirmUntagOrgProject($event['project_id'], $event['index']);
        break;

      case 'unadopt':
        this.confirmUnadoptProject($event['project_id'], $event['index']);
        break;

      case 'unpublish':
        this.confirmUnpublishProject($event['project_id'], $event['index']);
        break;

      default:
        break;
    }
  }

  confirmDeleteProject(project_id, index) {
    this.service.deleteProjectFromApp(project_id).then(res => {
      this.service.projects = [];
      this.userProjectsInprogress.splice(index, 1);
      if (this.userProjectsInprogress.length == 0) {
        this.noInprogress = true;
      }

      for (let i = 0; i < this.service.projectNames.length; i++) {
        if (this.service.projectNames[i].project_id == project_id) {
          this.service.projectNames.splice(i, 1);
          this.service.projectNamesonly = [];
          for (let i = 0; i < this.service.projectNames; i++) {
            this.service.projectNamesonly.push(this.service.projectNames[i].project_name);
          }
        }
      }
    });
  }

  deleteProject(project_id, index) {
    this.confirmObject = {
      'confirmTitle': 'Are you sure to delete this project?',
      'tag': 'delete',
      'project_id': project_id,
      'index': index
    };
    console.log(this.confirmObject);
  }

  confirmUnfollowProject(project_id, index) {
    this.service.unfollowProject(project_id, localStorage.getItem('full_name_slug')).then(result => {
      this.userProjectsFollowed.splice(index, 1);
      if (this.userProjectsFollowed.length == 0) {
        this.noFollowed = true;
      }
    });
  }

  unfollowProject(project_id, index) {
    this.confirmObject = {
      'confirmTitle': 'Are you sure to unfollow this project?',
      'tag': 'unfollow',
      'project_id': project_id,
      'index': index
    };
  }

  confirmUntagProject(project_id, index) {
    let obj = {
      'project_name': project_id,
      'user_id': localStorage.getItem('full_name_slug')
    }

    this.service.removeUserSelf(obj).then(result => {
      this.userProjectsTagged.splice(index, 1);
      if (this.userProjectsTagged.length == 0) {
        this.noTagged = true;
      }
    });
  }

  untagProject(project_id, index) {
    this.confirmObject = {
      'confirmTitle': 'Are you sure to untag yourself from this project?',
      'tag': 'untag',
      'project_id': project_id,
      'index': index
    };
  }

  confirmUntagOrgProject(project_id, index) {
    let obj = {
      'project_name': project_id,
      'org_id': this.org_id
    }

    this.service.removeCompanySelf(obj).then(result => {
      this.userProjectsTagged.splice(index, 1);
      if (this.userProjectsTagged.length == 0) {
        this.noTagged = true;
      }
    });
  }

  untagProjectOrg(project_id, index) {
    this.confirmObject = {
      'confirmTitle': 'Are you sure to untag organization from this project?',
      'tag': 'untag_org',
      'project_id': project_id,
      'index': index
    };
  }

  confirmUnadoptProject(project_id, index) {
    let obj = {};
    obj['project_publish_status'] = 'Uploaded';
    obj['project_ownership_status'] = false;
    obj['adopted_by_id'] = null;
    obj['adopted_by'] = null;
    obj['adopted_by_slug'] = null;
    obj['adopted_by_name'] = null;
    obj['adopted_by_company_id'] = null;
    obj['adopted_by_company_name'] = null;
    obj['adopted_by_company_name_slug'] = null;
    obj['owner_display_preferences'] = null;
    
    this.service.unadoptUnpubProject(project_id, obj).then(res => {
      this.userProjectsAdopted.splice(index, 1);
      if (this.userProjectsAdopted.length == 0) {
        this.noAdopted = true;
      }

      this.service.projects = [];
    });
  }

  unadoptProject(project_id, index, key) {
    let type;
    if (key == 'is_published') {
      type = "unpublish";
    } else {
      type = "unadopt";
    }
    this.confirmObject = {
      'confirmTitle': 'Are you sure to ' + type + ' this project?',
      'tag': 'unadopt',
      'project_id': project_id,
      'index': index,
    };
  }

  confirmUnpublishProject(project_id, index) {
    let obj = {};
    obj['project_publish_status'] = 'In-Progress';
    obj['is_published'] = false;

    this.service.unadoptUnpubProject(project_id, obj).then(res => {
      this.userProjectsInprogress.push(this.userProjectsUploaded[index]);
      this.noInprogress = false;
      this.userProjectsUploaded.splice(index, 1);
      if (this.userProjectsUploaded.length == 0) {
        this.noUploaded = true;
      }
      for (let i = 0; i < this.userProjectsAdopted.length; i++) {
        if (this.userProjectsAdopted[i].project_id == project_id) {
          this.userProjectsAdopted.splice(i, 1);
        }
      }

      this.service.projects = [];
    });
  }

  unpublishProject(project_id, index, key) {
    let type;
    if (key == 'is_published') {
      type = "unpublish";
    } else {
      type = "unadopt";
    }
    this.confirmObject = {
      'confirmTitle': 'Are you sure to ' + type + ' this project?',
      'tag': 'unpublish',
      'project_id': project_id,
      'index': index,
    };
  }

  isAdopt: boolean = false;
  publishStatus: any;
  projectid: any;
  publishProjectIndex: any;

  ownProject() {
    if (this.service.myOrg) {
      this.owner_display_preferences = 'company_name';
    } else {
      this.owner_display_preferences = 'user_name';
    }
    jQuery('#publishDialog2').modal('show');
  }

  setOwnerDisplay(flag) {
    let body = {
      "owner_display_preferences": this.owner_display_preferences
    };

    this.service.doPatch(this.projectid, body).then(response => {
      let publish;
      if (this.isAdopt) {
        // this.ownProject();
        publish = 'Project has been published and you have adopted this project.';
      } else {
        publish = 'Project has been published.';
      }
      // alert(publish);
      this.service.sendAlert('Success', publish, 1);
    });
  }
  
  adoptAndPublish() {
    //make is_publish true
    //if yes then make ownership true
    this.service.project_name_slug = this.projectid;
    let body = {
      "is_published": true,
      "project_ownership_status": this.isAdopt,
      "project_publish_status": this.publishStatus
    };

    if (this.isAdopt) {
      body['adopted_by'] = localStorage.getItem('peopleid');
    }

    this.service.doPatch(this.projectid, body).then(response => {
      // let arr = [];
      // arr = this.service.projectsGetter();
      this.service.getShorterAddress(response['centroid'].coordinates[1], response['centroid'].coordinates[0]).then(result => {
        let res = result['results'];
        let indice = null;
        let data;
        try {
          data = '';
          for (let j = 0; j < res[0].address_components.length; j++) {
            if (res[0].address_components[j].types[0] == 'locality') {
              data = res[0].address_components[j].long_name + ', ';
            }
            if (res[0].address_components[j].types[0] == 'administrative_area_level_1') {
              data += res[0].address_components[j].long_name + ', ';
            }
            if (res[0].address_components[j].types[0] == 'country') {
              data += res[0].address_components[j].long_name;
            }
          }
        } catch (e) { }

        //}
        //console.log('kasara', i ,res[0]);
        // arr.unshift({ address: data, name: response.project_name, imageurl: _.isEmpty(response.project_images) ? 'assets/images/no-image.jpg' : response.project_images[0].image_thumbnail, project_id: response.project_id });
        // this.service.projectsSetter(arr);
        this.service.projects = [];
        //console.log(arr);
        this.userProjectsInprogress.splice(this.publishProjectIndex, 1);
        if (this.publishStatus == 'Adopted') {
          this.noAdopted = false;
          this.userProjectsAdopted.push({ address: data, name: response['project_name'] ? response['project_name'] : 'Name This Project', imageurl: _.isEmpty(response['project_images']) ? 'assets/images/no-image.jpg' : response['project_images'][0].image_thumbnail, project_id: response['project_id'], project_name_slug: response['project_name_slug'] });
        } else {
          this.noUploaded = false;
          this.userProjectsUploaded.push({ address: data, name: response['project_name'] ? response['project_name'] : 'Name This Project', imageurl: _.isEmpty(response['project_images']) ? 'assets/images/no-image.jpg' : response['project_images'][0].image_thumbnail, project_id: response['project_id'], project_name_slug: response['project_name_slug'] });
        }
        if (this.isAdopt) {
          this.ownProject();
        }
      });
    });
  }

  getUserTaggedProjects() {
    this.service.getPeopleProjects(localStorage.getItem('full_name_slug')).then(res => {
      this.userProjects = res;
      if (_.isEmpty(this.userProjects)) {
        this.noTagged = true;
        if (this.service.myOrg) {
          this.getCompanyTaggedProjects();
        }
        return;
      }
      let i;
      for (i = 0; i < this.userProjects.length; i++) {
        let data = "";
        let obj = this.userProjects[i];
        if (_.isEmpty(obj.shorter_location) && this.userProjects[i].centroid) {
          this.service.getShorterAddress(this.userProjects[i].centroid.coordinates[1], this.userProjects[i].centroid.coordinates[0]).then(result => {
            let res = result['results'];
            let indice = null;
            for (let j = 0; j < res.length; j++) {
              if (res[j].types[0] == 'locality') {
                indice = j;
                //console.log(localStorage.getItem('full_name_slug'));
                break;
              }
            }
            if (indice) {
              data = res[indice].formatted_address;
            } else {
              data = '';
              try {
                for (let j = 0; j < res[0].address_components.length; j++) {
                  if (res[0].address_components[j].types[0] == 'locality') {
                    data = res[0].address_components[j].long_name + ', ';
                    //alert('sfs');
                  }
                  if (res[0].address_components[j].types[0] == 'administrative_area_level_1') {
                    data += res[0].address_components[j].long_name + ', ';
                  }
                  if (res[0].address_components[j].types[0] == 'country') {
                    data += res[0].address_components[j].long_name;
                  }
                }

              } catch (e) { }
            }

            this.userProjectsTagged.push({ address: data, name: obj.project_name ? obj.project_name : 'Name This Project', imageurl: obj.project_images.length > 0 ? obj.project_images[0].image_thumbnail : 'assets/images/no-image.jpg', project_id: obj.project_id, isOrg: false, project_name_slug: obj.project_name_slug });
            this.noTagged = false;


          });
        } else {
          this.userProjectsTagged.push({ address: obj.shorter_location, name: obj.project_name ? obj.project_name : 'Name This Project', imageurl: obj.project_images.length > 0 ? obj.project_images[0].image_thumbnail : 'assets/images/no-image.jpg', project_id: obj.project_id, isOrg: false, project_name_slug: obj.project_name_slug });
          this.noTagged = false;

        }
      }
      if (this.userProjects.length == i) {
        console.log('company1', this.userProjectsTagged);
        if (this.service.myOrg) {
          this.getCompanyTaggedProjects();
        }
      }
      if (_.isEmpty(this.userProjectsTagged)) {
        this.noTagged = true;
      }
    });
  }

  getCompanyTaggedProjects() {
    this.service.getPeopleDetail(localStorage.getItem('full_name_slug')).subscribe(res => {
      this.org_id = res['company_id'];
      this.service.getOrgProjects(this.org_id).then(res => {
        this.userProjects = res;//.results;
        if (_.isEmpty(this.userProjects)) {
          // this.noTagged = true;
          this.noTaggedOrg = true;
          return;
        }
        let i;
        for (i = 0; i < this.userProjects.length; i++) {
          let data = "";
          let obj = this.userProjects[i];
          if (_.isEmpty(obj.shorter_location) && this.userProjects[i].centroid) {
            this.service.getShorterAddress(this.userProjects[i].centroid.coordinates[1], this.userProjects[i].centroid.coordinates[0]).then(result => {
              let res = result['results'];
              let indice = null;
              for (let j = 0; j < res.length; j++) {
                if (res[j].types[0] == 'locality') {
                  indice = j;
                  // console.log(localStorage.getItem('full_name_slug'));
                  break;
                }
              }
              if (indice) {
                data = res[indice].formatted_address;
              } else {
                data = '';
                try {
                  for (let j = 0; j < res[0].address_components.length; j++) {
                    if (res[0].address_components[j].types[0] == 'locality') {
                      data = res[0].address_components[j].long_name + ', ';
                      //alert('sfs');
                    }
                    if (res[0].address_components[j].types[0] == 'administrative_area_level_1') {
                      data += res[0].address_components[j].long_name + ', ';
                    }
                    if (res[0].address_components[j].types[0] == 'country') {
                      data += res[0].address_components[j].long_name;
                    }
                  }

                } catch (e) { }
              }

              this.userProjectsTagged.push({ address: data, name: obj.project_name ? obj.project_name : 'Name This Project', imageurl: obj.project_images.length > 0 ? obj.project_images[0].image_thumbnail : 'assets/images/no-image.jpg', project_id: obj.project_id, isOrg: true, project_name_slug: obj.project_name_slug });
              this.noTagged = false;
            });
          } else {
            this.userProjectsTagged.push({ address: obj.shorter_location, name: obj.project_name ? obj.project_name : 'Name This Project', imageurl: obj.project_images.length > 0 ? obj.project_images[0].image_thumbnail : 'assets/images/no-image.jpg', project_id: obj.project_id, isOrg: true, project_name_slug: obj.project_name_slug });
            this.noTagged = false;
          }
        }
        // if (res.length == i) {
        //   console.log('company', this.userProjectsTagged);
        // }
        if (_.isEmpty(this.userProjectsTagged)) {
          // this.noTagged = true;
          this.noTaggedOrg = true;
        }
      });
    });
  }

  getUserFollowedProjects() {
    this.service.getFollowedProjects().then(res => {
      this.userProjects = res;//.results;
      if (_.isEmpty(this.userProjects)) {
        this.noFollowed = true;
        return;
      }
      for (let i = 0; i < this.userProjects.length; i++) {
        let data = "";
        let obj = this.userProjects[i];
        if (_.isEmpty(obj.following_projects.shorter_location)) {
          this.service.getShorterAddress(this.userProjects[i].following_projects.centroid.coordinates[1], this.userProjects[i].following_projects.centroid.coordinates[0]).then(result => {
            let res = result['results'];
            let indice = null;
            for (let j = 0; j < res.length; j++) {
              if (res[j].types[0] == 'locality') {
                indice = j;
                // console.log(localStorage.getItem('full_name_slug'));
                break;
              }
            }
            if (indice) {
              data = res[indice].formatted_address;
            } else {
              data = '';
              try {
                for (let j = 0; j < res[0].address_components.length; j++) {
                  if (res[0].address_components[j].types[0] == 'locality') {
                    data = res[0].address_components[j].long_name + ', ';
                    //alert('sfs');
                  }
                  if (res[0].address_components[j].types[0] == 'administrative_area_level_1') {
                    data += res[0].address_components[j].long_name + ', ';
                  }
                  if (res[0].address_components[j].types[0] == 'country') {
                    data += res[0].address_components[j].long_name;
                  }
                }

              } catch (e) { }
            }

            this.userProjectsFollowed.push({ address: data, name: obj.following_projects.project_name ? obj.following_projects.project_name : 'Name This Project', imageurl: obj.project_images.length > 0 ? obj.project_images[0].image_thumbnail : 'assets/images/no-image.jpg', project_id: obj.following_projects.project_id, project_name_slug: obj.project_name_slug });
            this.noFollowed = false;


          });
        } else {
          this.userProjectsFollowed.push({ address: obj.following_projects.shorter_location, name: obj.following_projects.project_name ? obj.following_projects.project_name : 'Name This Project', imageurl: obj.project_images.length > 0 ? obj.project_images[0].image_thumbnail : 'assets/images/no-image.jpg', project_id: obj.following_projects.project_id, project_name_slug: obj.project_name_slug });
          this.noFollowed = false;

        }
      }
      if (_.isEmpty(this.userProjectsFollowed)) {
        this.noFollowed = true;
      }
    });
  }

  getAllUserProjects() {
    this.service.getProjectsCreatedByUser(localStorage.getItem('peopleid')).then(res => {
      this.userProjects = res;//.results;
      if (_.isEmpty(this.userProjects)) {
        this.noInprogress = true;
        this.noAdopted = true;
        this.noUploaded = true;
        this.loading = false;

        return;
      }
      // let i;
      for (let i = 0; i < this.userProjects.length; i++) {
        let data = "";
        let obj = this.userProjects[i];
        // console.log('obj',obj);
        if (_.isEmpty(obj.shorter_location) && this.userProjects[i].centroid) {
          this.service.getShorterAddress(this.userProjects[i].centroid.coordinates[1], this.userProjects[i].centroid.coordinates[0]).then(result => {
            let res = result['results'];
            let indice = null;
            for (let j = 0; j < res.length; j++) {
              if (res[j].types[0] == 'locality') {
                indice = j;
                // console.log(localStorage.getItem('full_name_slug'));
                break;
              }
            }
            if (indice) {
              data = res[indice].formatted_address;
            } else {
              data = '';
              try {
                for (let j = 0; j < res[0].address_components.length; j++) {
                  if (res[0].address_components[j].types[0] == 'locality') {
                    data = res[0].address_components[j].long_name + ', ';
                    //alert('sfs');
                  }
                  if (res[0].address_components[j].types[0] == 'administrative_area_level_1') {
                    data += res[0].address_components[j].long_name + ', ';
                  }
                  if (res[0].address_components[j].types[0] == 'country') {
                    data += res[0].address_components[j].long_name;
                  }
                }

              } catch (e) { console.log('try'); }
            }
            //console.log('chat',this.userProjects[i]);
            if (this.userProjects[i].project_publish_status == 'In-Progress') {
              this.userProjectsInprogress.push({ address: data, name: obj.project_name ? obj.project_name : 'Name This Project', imageurl: obj.project_images.length > 0 ? obj.project_images[0].image_thumbnail : 'assets/images/no-image.jpg', project_id: obj.project_id, project_name_slug: obj.project_name_slug });
              this.noInprogress = false;

            }
            else if (this.userProjects[i].project_publish_status == 'Adopted') {
              this.userProjectsAdopted.push({ address: data, name: obj.project_name ? obj.project_name : 'Name This Project', imageurl: obj.project_images.length > 0 ? obj.project_images[0].image_thumbnail : 'assets/images/no-image.jpg', project_id: obj.project_id, project_name_slug: obj.project_name_slug });
              this.noAdopted = false;
              this.userProjectsUploaded.push({ address: data, name: obj.project_name ? obj.project_name : 'Name This Project', imageurl: obj.project_images.length > 0 ? obj.project_images[0].image_thumbnail : 'assets/images/no-image.jpg', project_id: obj.project_id, project_name_slug: obj.project_name_slug });
              this.noUploaded = false;

            }
            else if (this.userProjects[i].project_publish_status == 'Uploaded') {
              this.userProjectsUploaded.push({ address: data, name: obj.project_name ? obj.project_name : 'Name This Project', imageurl: obj.project_images.length > 0 ? obj.project_images[0].image_thumbnail : 'assets/images/no-image.jpg', project_id: obj.project_id, project_name_slug: obj.project_name_slug });
              this.noUploaded = false;

            }
          });
        } else {
          if (this.userProjects[i].project_publish_status == 'In-Progress') {
            this.userProjectsInprogress.push({ address: obj.shorter_location, name: obj.project_name ? obj.project_name : 'Name This Project', imageurl: obj.project_images.length > 0 ? obj.project_images[0].image_thumbnail : 'assets/images/no-image.jpg', project_id: obj.project_id, project_name_slug: obj.project_name_slug });
            this.noInprogress = false;

          }
          else if (this.userProjects[i].project_publish_status == 'Adopted') {
            this.userProjectsAdopted.push({ address: obj.shorter_location, name: obj.project_name ? obj.project_name : 'Name This Project', imageurl: obj.project_images.length > 0 ? obj.project_images[0].image_thumbnail : 'assets/images/no-image.jpg', project_id: obj.project_id, project_name_slug: obj.project_name_slug });
            this.userProjectsUploaded.push({ address: data, name: obj.project_name ? obj.project_name : 'Name This Project', imageurl: obj.project_images.length > 0 ? obj.project_images[0].image_thumbnail : 'assets/images/no-image.jpg', project_id: obj.project_id, project_name_slug: obj.project_name_slug });
            this.noUploaded = false;

          }
          else if (this.userProjects[i].project_publish_status == 'Uploaded') {
            this.userProjectsUploaded.push({ address: obj.shorter_location, name: obj.project_name ? obj.project_name : 'Name This Project', imageurl: obj.project_images.length > 0 ? obj.project_images[0].image_thumbnail : 'assets/images/no-image.jpg', project_id: obj.project_id, project_name_slug: obj.project_name_slug });
            this.noUploaded = false;

          }
        }
      }
      // if (this.userProjects.length == i) {
      //   this.getUserTaggedProjects();
      // }

      this.loading = false;
      if (_.isEmpty(this.userProjectsInprogress)) {
        this.noInprogress = true;
      }
      if (_.isEmpty(this.userProjectsAdopted)) {
        this.noAdopted = true;
      }
      if (_.isEmpty(this.userProjectsUploaded)) {
        this.noUploaded = true;
      }
      // console.log('progress', this.userProjectsInprogress);
      // console.log('progress1', this.userProjectsAdopted);
      // console.log('progress2', this.userProjectsUploaded);
    });
  }

  getFlaggedProjects() {
    this.service.getFlaggedProjects().then(res => {
      this.userProjects = res['results'];
      if (_.isEmpty(this.userProjects)) {
        this.noFlagged = true;
        return;
      }
      for (let i = 0; i < this.userProjects.length; i++) {
        let data = "";
        let obj = this.userProjects[i].project;
        console.log('oboobo', obj);
        if (_.isEmpty(obj.shorter_location) && this.userProjects[i].centroid) {
          this.service.getShorterAddress(this.userProjects[i].project.centroid.coordinates[1], this.userProjects[i].project.centroid.coordinates[0]).then(result => {
            let res = result['results'];
            let indice = null;
            for (let j = 0; j < res.length; j++) {
              if (res[j].types[0] == 'locality') {
                indice = j;
                // console.log(localStorage.getItem('full_name_slug'));
                break;
              }
            }
            if (indice) {
              data = res[indice].formatted_address;
            } else {
              data = '';
              try {
                for (let j = 0; j < res[0].address_components.length; j++) {
                  if (res[0].address_components[j].types[0] == 'locality') {
                    data = res[0].address_components[j].long_name + ', ';
                    //alert('sfs');
                  }
                  if (res[0].address_components[j].types[0] == 'administrative_area_level_1') {
                    data += res[0].address_components[j].long_name + ', ';
                  }
                  if (res[0].address_components[j].types[0] == 'country') {
                    data += res[0].address_components[j].long_name;
                  }
                }
              } catch (e) { }
            }

            this.userProjectsFlagged.push({ address: data, name: obj.project_name ? obj.project_name : 'Name This Project', imageurl: this.userProjects[i].project_images.length > 0 ? this.userProjects[i].project_images[0].image_thumbnail : 'assets/images/no-image.jpg', project_id: obj.project_id, reason: this.userProjects[i].reason, project_name_slug: obj.project_name_slug });
            this.noFollowed = false;


          });
        } else {
          this.userProjectsFlagged.push({ address: obj.shorter_location, name: obj.project_name ? obj.project_name : 'Name This Project', imageurl: this.userProjects[i].project_images.length > 0 ? this.userProjects[i].project_images[0].image_thumbnail : 'assets/images/no-image.jpg', project_id: obj.project_id, reason: this.userProjects[i].reason, project_name_slug: obj.project_name_slug });
          this.noFollowed = false;

        }
      }
      setTimeout(() => {
        if (_.isEmpty(this.userProjectsFlagged)) {

          this.noFlagged = true;
        }
      }, 1000);

    });
  }
}