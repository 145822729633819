import {
  Component,
  NgZone,
  Input,
  EventEmitter, Output, ViewEncapsulation, ChangeDetectorRef
} from '@angular/core';

import {
  trigger,
  state,
  style,
  animate,
  transition,
  keyframes
} from '@angular/animations'

import { PipeTransform, Pipe } from '@angular/core';
import {
  ProjectService
}
  from './../projectservice';

import * as _ from 'lodash';

import { IMultiSelectOption } from 'angular-2-dropdown-multiselect';
import { Location } from '@angular/common';

@Component({
  selector: 'textboxprofile',
  templateUrl: './textboxprofile.html',
  styleUrls: ['./textbox.css'],
  animations: [
    trigger('fadeInOut', [
      transition('void => *', [
        style({ opacity: 0 }), //style only for transition transition (after transiton it removes)
        animate(700, style({ opacity: 1 })) // the new state of the transition(after transiton it removes)
      ]),
      transition('* => void', [
        animate(0, style({ opacity: 0 })) // the new state of the transition(after transiton it removes)
      ])
    ])
  ],
  encapsulation: ViewEncapsulation.Emulated

})
export class TextboxProfileComponent {

  @Input() mx: any;
  @Input() edit: boolean;
  @Input() hideToggle: any;
  @Output() careerChanged: EventEmitter<any> = new EventEmitter();

  textinput: any;

  projectobj: any;
  toggleVisibitly = false;
  ShowWebsiteDiv: boolean = false;
  ShowProjectTypeDiv: boolean = false;
  EditToggle: boolean = false;
  showTexbox: boolean = false;

  ShowWebsiteDivData: boolean = false;
  ShowProjectTypeDivData: boolean = false;
  projecttypeArr: any = [];
  url: string;
  desc: any;
  printArray: any = [];
  data: any;
  data1: any = [];
  varib: boolean = false;
  fields: any;
  txt: any;
  otherClicked: boolean = false;
  socialNetworkArr: any = [];
  other: boolean = false;
  exp: any = ["0 - 1", "1 - 2", "2 - 5", "5 - 10", "10+", "15+", "20+", "Retired", "Graduating 2019", "Graduating after 2019"];
  existingSocial: any = [];
  socialArr = ["Facebook", "LinkedIn", "Twitter", "Other"];
  is_prof_certi_other: boolean = false;
  prof_certi_other: any;
  certi_location: any;
  selected_certifications: any = [];
  certifications: any = [
    { id: 'Fundamentals of Engineering (FE)', name: 'Fundamentals of Engineering (FE)' },
    { id: 'Professional Engineer (PE)', name: 'Professional Engineer (PE)' },
    { id: 'Structural Engineer (SE)', name: 'Structural Engineer (SE)' },
    { id: 'Registered Engineer (RE)', name: 'Registered Engineer (RE)' },
    { id: 'International Professional Engineer (IntPE)', name: 'International Professional Engineer (IntPE)' },
    { id: 'Chartered Engineer (CEng)', name: 'Chartered Engineer (CEng)' },
    { id: 'Incorporated Engineer (IEng)', name: 'Incorporated Engineer (IEng)' },
    { id: 'Engineering Technician (EngTech)', name: 'Engineering Technician (EngTech)' },
    { id: 'Information and Communications Technology Technicians (ICTTech)', name: 'Information and Communications Technology Technicians (ICTTech)' },
    { id: 'Project Management Professional (PMP)', name: 'Project Management Professional (PMP)' },
    { id: 'LEED AP', name: 'LEED AP' },
    { id: 'LEED Green Associate', name: 'LEED Green Associate' },
    { id: 'Other', name: 'Other' }
  ];
  degrees: any = [
    { id: "Bachelor's Degree", name: "Bachelor's Degree" },
    { id: "Bachelor of Arts (B.A.)", name: "Bachelor of Arts (B.A.)" },
    { id: "Bachelor of Science (B.S.)", name: "Bachelor of Science (B.S.)" },
    { id: "Bachelor of Fine Arts (BFA)", name: "Bachelor of Fine Arts (BFA)" },
    { id: "Bachelor of Applied Science (BAS)", name: "Bachelor of Applied Science (BAS)" },
    { id: "Bachelor of Engineering (B.E.)", name: "Bachelor of Engineering (B.E.)" },
    { id: "Master's Degree", name: "Master's Degree" },
    { id: "Master of Arts (M.A.)", name: "Master of Arts (M.A.)" },
    { id: "Master of Science (M.S.)", name: "Master of Science (M.S.)" },
    { id: "Master of Engineering (M.E.)", name: "Master of Engineering (M.E.)" },
    { id: "Doctoral Degree", name: "Doctoral Degree" },
    { id: "Doctor of Philosophy (Ph.D.)", name: "Doctor of Philosophy (Ph.D.)" },
    { id: "Doctor of Education (Ed.D.)", name: "Doctor of Education (Ed.D.)" },
    { id: "Juris Doctorate (J.D.)", name: "Juris Doctorate (J.D.)" },
    { id: "Associate's Degree ", name: "Associate's Degree " },
    { id: "Associate of Arts (A.A.)", name: "Associate of Arts (A.A.)" },
    { id: "Associate of Science (A.S.)", name: "Associate of Science (A.S.)" },
    { id: "Associate of Applied Science (A.A.S.)", name: "Associate of Applied Science (A.A.S.)" },
    { id: 'Other', name: 'Other' }
  ];

  selected_degrees: any = [];
  is_degree_other: boolean = false;
  degree_other: any;
  field_of_study: any;
  school: any;
  year: any;
  designation: any = '';
  user_award_url: any;
  user_award_name: any;
  collectionUserAwards: any = [];
  constructor(public service: ProjectService, private cd: ChangeDetectorRef, private location: Location) { }

  ngOnInit() {
    let id = this.location.path();
    console.log('idid', id);
    let xid = id.split("/");
    let id1 = xid[2];
    let id2 = localStorage.getItem("full_name_slug");
    if (id1 == id2 || this.service.publicUser) {
      this.varib = true;
    }
    //this.varib = true;
    this.fields = this.mx.fieldname;
    // if (this.mx.fieldname == 'social_network' && !_.isEmpty(this.mx.data)) {
    //   this.socialNetworkArr = this.createDemo(this.mx.data);
    //   console.log('ss', this.socialNetworkArr);
    //   setTimeout(() => {

    //     for (let i = 0; i < this.socialNetworkArr.length; i++) {
    //       // setTimeout(() => {
    //       if (_.includes(this.socialArr, this.socialNetworkArr[i].key)) {
    //         console.log('if', this.socialNetworkArr, this.socialNetworkArr[i].key);
    //         let index = this.socialArr.indexOf(this.socialNetworkArr[i].key);
    //         this.socialArr.splice(index, 1);
    //       } else {
    //         console.log('else', this.socialNetworkArr, this.socialNetworkArr[i].key);
    //         let index = this.socialArr.indexOf("Other");
    //         this.socialArr.splice(index, 1);
    //       }
    //       // }, 500);
    //     }

    //     if (this.socialArr.length == 1) {
    //       if (_.includes(this.socialArr, 'Other')) {
    //         this.other = true;
    //       }
    //     }
    //   }, 1000);
    // }

  }

  updateSocialNetwork(type, url) {
    if (!url.trim()) {
      return;
    }
    let innerObj = {};
    innerObj[type] = url;

    let body = {
      [this.mx.fieldname]: innerObj
    };

    let people_id = sessionStorage.getItem("currentUserSlug");
    if (this.service.publicUser) {
      people_id = this.service.publicUser;
    }
    this.service.doUserProfilePatch(people_id, body).then(result => {

      //this.toggleVisibitly = false;
      try {
        document.getElementsByClassName('cmw')[0]['style'].display = 'inline-block';
      } catch (e) { console.log('hide edit btn try'); }

      if (this.mx.data) {
        this.mx.data[type] = url;
        // console.log('ss', this.mx.data);
        this.socialNetworkArr = this.createDemo(this.mx.data);
        // console.log('ss1', this.socialNetworkArr);
      } else {
        let obj = {};
        obj[type] = url;
        // console.log('ss', obj);
        this.socialNetworkArr = this.createDemo(obj);
        // console.log('ss1', this.socialNetworkArr);
        this.mx.data = obj;
      }

      let index = this.socialArr.indexOf(type);
      this.socialArr.splice(index, 1);

      if (this.other) {
        this.other = false;
      }

      if (this.socialArr.length == 1) {
        if (_.includes(this.socialArr, 'Other')) {
          this.other = true;
        }
      }

      this.EditToggle = false;
      this.showTexbox = true;
      // console.log(result);
    });
  }

  expChange(value) {
    console.log(value);
    let body = {
      [this.mx.fieldname]: value
    };

    //this.toggleVisibitly = true;
    let people_id = sessionStorage.getItem("currentUserSlug");
    if (this.service.publicUser) {
      people_id = this.service.publicUser;
    }
    this.service.doUserProfilePatch(people_id, body).then(result => {

      //this.toggleVisibitly = false;
      try {
        document.getElementsByClassName('cmw')[0]['style'].display = 'inline-block';
      } catch (e) { console.log('hide edit btn try'); }

      this.mx.data = value;
      this.EditToggle = false;
      this.showTexbox = true;
    });
  }

  collectionEducation: any = [];
  collectionCertificates: any = [];
  birth_year: any;
  death_year: any;

  ngOnChanges() {
    // console.log("assert");
    try {
      if (!_.isEmpty(this.mx.data)) {
        if (this.mx.fieldname == 'professional_certification') {
          this.selected_certifications = this.mx.data.certifications;
          this.collectionCertificates = this.mx.data;
        } else if (this.mx.fieldname == 'user_education') {
          this.selected_degrees = this.mx.data.degrees;
        }
        if (this.mx.fieldname == 'user_designation') {
          this.designation = this.mx.data;
        }

        if (this.mx.fieldname == 'user_education') {
          this.collectionEducation = this.mx.data;
        }

        if (this.mx.fieldname == 'user_awards') {
          this.collectionUserAwards = this.mx.data;
        }

        if (this.mx.fieldname == 'social_network') {
          this.socialArr = ["Facebook", "LinkedIn", "Twitter", "Other"];
          console.log('tag', this.mx.data)
          this.socialNetworkArr = this.createDemo(this.mx.data);
          setTimeout(() => {
            for (let i = 0; i < this.socialNetworkArr.length; i++) {
              // setTimeout(() => {
              if (_.includes(this.socialArr, this.socialNetworkArr[i].key)) {
                // console.log('if', this.socialNetworkArr, this.socialNetworkArr[i].key);
                let index = this.socialArr.indexOf(this.socialNetworkArr[i].key);
                this.socialArr.splice(index, 1);
              } else {
                // console.log('else', this.socialNetworkArr, this.socialNetworkArr[i].key);
                let index = this.socialArr.indexOf("Other");
                this.socialArr.splice(index, 1);
              }
              // }, 500);
            }

            if (this.socialArr.length == 1) {
              if (_.includes(this.socialArr, 'Other')) {
                this.other = true;
              }
            }
          console.log('ss', this.socialNetworkArr);

          }, 1000);
        }

        if (this.mx.fieldname == 'birth_death') {
          // if(!_.includes(this.mx.data, '(')) {
            this.birth_year = this.mx.data.split('-')[0];
            this.death_year = this.mx.data.split('-')[1];
            this.mx.data = '';
            if(this.death_year){
              if (!this.birth_year) {
                this.mx.data = '(d.' + this.death_year + ')';
              } else {
                this.mx.data = '(' + this.birth_year + ' - ' + this.death_year + ')';
              }
              console.log(this.mx.data);
            }
          // }
        }

        // console.log("onchanges if", " ", this.mx.fieldname, " ", this.mx.data);
        this.showTexbox = true;
      } else {
        // console.log("onchanges else", " ", this.mx.fieldname, " ", this.mx.data);
        this.showTexbox = false;
      }
    } catch (e) { console.log("e", e); this.showTexbox = false; }

    if (!this.edit) {
      this.EditToggle = false;
    }

  }

  getType(obj) {
    return typeof(obj);
  }

  addBirthDeath() {
    if (!this.death_year) {
      //alert('Please enter year of death');
      this.service.sendAlert('Error', 'Please enter year of death', 0);
      return;
    }
    let body = {
      dob: this.birth_year,
      dod: this.death_year
    };

    //this.toggleVisibitly = true;
    let people_id = sessionStorage.getItem("currentUserSlug");
    if (this.service.publicUser) {
      people_id = this.service.publicUser;
    }
    this.service.doUserProfilePatch(people_id, body).then(result => {
      if (!this.birth_year) {
        this.mx.data = '(d.' + this.death_year + ')';
      } else {
        this.mx.data = '(' + this.birth_year + ' - ' + this.death_year + ')';
      }
      this.EditToggle = false;
      this.showTexbox = true;
    });
  }

  socialChange(e) {
    if (e.target['value'] == 'Other') {
      this.other = true;
    } else {
      this.other = false;
    }
  }

  showText() {
    //this.varib = true;
    //console.log(this.varib);
    if (this.varib) {
      this.showTexbox = true;
      this.EditToggle = true;
    }
  }


  hideBtn() {
    document.getElementsByClassName('cmw')[0]['style'].display = 'none';
  }

  onEnterDesc() {
    let val = document.getElementById('desc1')['value'];
    console.log(this.fields == 'user_designation', val == 'Other', !this.otherClicked, 'fzfsd', );
    if (this.fields == 'user_designation' && val == 'Other' && !this.otherClicked) {
      return;
    }
    if (this.mx.fielname == 'user_designation', val == 'Other', this.otherClicked) {
      val = document.getElementById('desc2')['value'];
    }
    if (!val.trim()) {
      console.log("empty dala");
      return;
    }
    let body;
    if (this.mx.fieldname == 'name') {
      let fname = val.substr(0, val.indexOf(' ')); // "72"
      let lname = val.substr(val.indexOf(' ') + 1); // "tocirah sneab"
      body = {
        "first_name": fname,
        "last_name": lname
      };
    } else {
      body = {
        [this.mx.fieldname]: val
      };
    }

    //this.toggleVisibitly = true;
    let people_id = sessionStorage.getItem("currentUserSlug");
    if (this.service.publicUser) {
      people_id = this.service.publicUser;
    }
    this.service.doUserProfilePatch(people_id, body).then(result => {
      console.log(this.mx.fieldname, " ", 'user_designation');
      if (this.mx.fieldname == 'user_designation') {
        console.log('emit');
        this.service.peoples = [];
        this.careerChanged.emit(val);
      }

      if (this.mx.fieldname == 'name') {
        this.service.peoples = [];
      }
      //this.toggleVisibitly = false;
      try {
        document.getElementsByClassName('cmw')[0]['style'].display = 'inline-block';
      } catch (e) { console.log('hide edit btn try'); }
      this.otherClicked = false;
      this.mx.data = val;
      this.EditToggle = false;
      this.showTexbox = true;





      console.log(result);
    });
  }

  createDemo(mydemo: any): Array<demo> {
    let tempdemo = [];
    
    // Caution: use "of" and not "in"
    for (const key of Object.keys(mydemo)) {
        tempdemo.push(
          { key: key, value: mydemo[key] }
        );
      }
      for(let i=0; i<tempdemo.length; i++) {
        if(tempdemo[i].key == 'Other') {
          tempdemo.push(tempdemo.splice(i,1)[0]);
        }
      }
    return tempdemo;
  }

  deleteSocial(name, i) {
    let innerObj = {};
    innerObj['remove'] = name;

    let body = {
      [this.mx.fieldname]: innerObj
    };

    let people_id = sessionStorage.getItem("currentUserSlug");
    if (this.service.publicUser) {
      people_id = this.service.publicUser;
    }
    this.service.doUserProfilePatch(people_id, body).then(result => {
      this.EditToggle = false;
      this.socialNetworkArr.splice(i, 1);
      this.mx.data = _.omit(this.mx.data, name);
      if (_.includes(["Facebook", "LinkedIn", "Twitter"], name)) {
        this.socialArr.push(name);
      } else {
        this.socialArr.push('Other');
        if (this.socialArr.length == 1) {
          this.other = true;
        }
      }
    });
  }

  certificationChange($event) {
    this.selected_certifications = $event;
    if (_.includes($event, 'Other')) {
      this.is_prof_certi_other = true;
    } else {
      this.is_prof_certi_other = false;
    }
  }

  degreesChange($event) {
    this.selected_degrees = $event;
    if (_.includes($event, 'Other')) {
      this.is_degree_other = true;
    } else {
      this.is_degree_other = false;
    }
  }

  removeUserAwards(index) {
    this.collectionUserAwards.splice(index, 1);
    //console.log(this.collectionEducation);
    //return;
    let body = {
      [this.mx.fieldname]: this.collectionUserAwards
    };

    let people_id = sessionStorage.getItem("currentUserSlug");
    if (this.service.publicUser) {
      people_id = this.service.publicUser;
    }
    this.service.doUserProfilePatch(people_id, body).then(result => {
    });
  }

  addUserAward() {
    if (_.isEmpty(this.user_award_name)) {
      //alert('please enter award name');
      this.service.sendAlert('Error', 'please enter award name', 0);
      return;
    }

    let obj = {
      award_name: this.user_award_name,
      award_url: this.user_award_url
    };

    this.collectionUserAwards.push(obj);
    //console.log(this.collectionEducation);
    //return;

    let body = {
      [this.mx.fieldname]: this.collectionUserAwards
    };

    //this.toggleVisibitly = true;
    let people_id = sessionStorage.getItem("currentUserSlug");
    if (this.service.publicUser) {
      people_id = this.service.publicUser;
    }
    this.service.doUserProfilePatch(people_id, body).then(result => {
      this.mx.data = this.collectionUserAwards;
      this.EditToggle = false;
      this.showTexbox = true;
      this.user_award_url = '';
      this.user_award_name = '';
    });
  }

  addEducation() {
    if (_.isEmpty(this.selected_degrees)) {
      //alert('please select degree(s)');
      this.service.sendAlert('Error', 'please enter award name', 0);
      return;
    }

    if (this.is_degree_other) {
      if (_.includes(this.selected_degrees, 'Other')) {
        let index = this.selected_degrees.indexOf('Other');
        this.selected_degrees.splice(index, 1);
      }
      this.selected_degrees.push(this.degree_other);
    }

    let obj = {
      degrees: this.selected_degrees,
      school: this.school,
      field_of_study: this.field_of_study,
      year: this.year
    };

    this.collectionEducation.push(obj);
    console.log(this.collectionEducation);
    //return;

    let body = {
      [this.mx.fieldname]: this.collectionEducation
    };

    //this.toggleVisibitly = true;
    let people_id = sessionStorage.getItem("currentUserSlug");
    if (this.service.publicUser) {
      people_id = this.service.publicUser;
    }
    this.service.doUserProfilePatch(people_id, body).then(result => {

      //this.toggleVisibitly = false;
      try {
        document.getElementsByClassName('cmw')[0]['style'].display = 'inline-block';
      } catch (e) { console.log('hide edit btn try'); }

      // this.mx.data = {
      //   degrees: this.selected_degrees,
      //   school: this.school,
      //   field_of_study: this.field_of_study,
      //   year: this.year
      // };
      this.mx.data = this.collectionEducation;
      this.EditToggle = false;
      this.showTexbox = true;
      this.selected_degrees = [];
      this.year = '';
      this.school = '';
      this.field_of_study = '';
    });
  }

  removeEducation(index) {
    this.collectionEducation.splice(index, 1);
    console.log(this.collectionEducation);
    //return;
    let body = {
      [this.mx.fieldname]: this.collectionEducation
    };

    let people_id = sessionStorage.getItem("currentUserSlug");
    if (this.service.publicUser) {
      people_id = this.service.publicUser;
    }
    this.service.doUserProfilePatch(people_id, body).then(result => {
    });
  }

  removeCertificate(index) {
    this.collectionCertificates.splice(index, 1);
    console.log(this.collectionCertificates);
    //return;
    let body = {
      [this.mx.fieldname]: this.collectionCertificates
    };

    let people_id = sessionStorage.getItem("currentUserSlug");
    if (this.service.publicUser) {
      people_id = this.service.publicUser;
    }
    this.service.doUserProfilePatch(people_id, body).then(result => {
    });
  }

  addCertification() {
    if (_.isEmpty(this.selected_certifications)) {
      //alert('please select certifications');
      this.service.sendAlert('Error', 'please select certifications', 0);
      return;
    }

    if (this.is_prof_certi_other) {
      if (_.includes(this.selected_certifications, 'Other')) {
        let index = this.selected_certifications.indexOf('Other');
        this.selected_certifications.splice(index, 1);
      }
      this.selected_certifications.push(this.prof_certi_other);
    }

    let obj = {
      certifications: this.selected_certifications,
      location: this.certi_location
    }

    this.collectionCertificates.push(obj);

    let body = {
      [this.mx.fieldname]: this.collectionCertificates
    };

    //this.toggleVisibitly = true;
    let people_id = sessionStorage.getItem("currentUserSlug");
    if (this.service.publicUser) {
      people_id = this.service.publicUser;
    }
    this.service.doUserProfilePatch(people_id, body).then(result => {

      //this.toggleVisibitly = false;
      try {
        document.getElementsByClassName('cmw')[0]['style'].display = 'inline-block';
      } catch (e) { console.log('hide edit btn try'); }

      this.mx.data = this.collectionCertificates;
      this.EditToggle = false;
      this.showTexbox = true;
      this.certi_location = '';
      this.selected_certifications = [];
    });
  }

}

interface demo {
  key: string;
  value: string;
}

