<navbar (logout1)="loginStatus = false;" [changeNavItem]="loginStatus"></navbar>
<h2 *ngIf="!created && loginStatus" class="heading" style="margin-top: 50px;">Add Person</h2>
<h4 *ngIf="!loginStatus" style="margin-top: 50px; text-align: center;color: gray;font-family: OpenSans-Semibold;font-size: 16px;margin-bottom: 20px;">You must be logged in to add a profile for someone. To add a profile for yourself, create an account below.</h4>
<div *ngIf="!loginStatus" class="col-sm-8 col-sm-offset-2" style="margin-bottom: 20px">
    <div class="text-center">
        <input type="text" class="form-control" [(ngModel)]="loginObject.email" placeholder="Enter email" style="width: 30%; display: inline-block;" />
        <input type="password" class="form-control margin-left" [(ngModel)]="loginObject.password" placeholder="Enter password" style="width: 30%; display: inline-block;" />
        <button class="btn btn-default login margin-left" (click)="login()">Login</button>
    </div>
    <br>
    <br>
    <regForm></regForm>
</div>
<div *ngIf="loginStatus && !created" class="col-sm-4 col-sm-offset-4">
    <div class="form-group">
        <label for="fname">First name
      <sup>*</sup>
    </label>
        <input type="text" [(ngModel)]="fname" class="form-control" id="fname" placeholder="Enter first name" style="color: black;">
    </div>
    <div class="form-group">
        <label for="lname">Last name
      <sup>*</sup>
    </label>
        <input type="text" [(ngModel)]="lname" class="form-control" id="lname" placeholder="Enter last name" style="color: black;">
    </div>
    <div class="form-group">
        <label for="exampleInputFile">Line of Work
      <sup>*</sup>
    </label>
        <select [(ngModel)]="designation" class="form-control" style="color: black;">

      <option value="">Select role</option>
      <option *ngFor="let item of lineofwork" value="{{item}}">{{item}}</option>  
    </select>
    </div>
    <div class="form-group" *ngIf="designation == 'Other'">
        <label for="otherCat">Other role
      <sup>*</sup>
    </label>
        <input type="text" class="form-control" [(ngModel)]="otherRole" id="otherCat" placeholder="Enter other role" style="color: black;">
    </div>
    <br>
    <div class="checkboxdiv">
        <div>
            <p>This person is: <sup>*</sup> </p>
            <form>
                <div>
                    <label class="radio-inline">
            <input type="radio" name="personType" [(ngModel)]="personType" value="Famous STEM professional" />Famous STEM professional</label>
                </div>
                <div>
                    <label class="radio-inline">
            <input type="radio" name="personType" [(ngModel)]="personType" value="A historical figure" />A historical figure</label>
                </div>
                <div>
                    <label class="radio-inline">
            <input type="radio" name="personType" [(ngModel)]="personType" value="No longer living" />No longer living</label>
                </div>
            </form>
            <div style="margin-top: 20px;">
                <button class="btn btn-default login" (click)="createPerson()">Create Profile</button>
            </div>
        </div>
    </div>
</div>
<div *ngIf="loginStatus && created" style="color: gray;font-family: OpenSans-Semibold;margin-top: 50px;" class="col-sm-10 col-sm-offset-1">
    <h3 style="font-size: 20px;text-align: center;">Congratulations! A profile has been created for {{ fname +' '+ lname }}.
        <a style="color: #4688F0;" routerLinkActive="active" routerLink="/userprofile/{{full_name_slug}}">Click here</a> to view profile.</h3>
    <br>
    <br>
    <div class="col-sm-6 col-sm-offset-3 text-center">
        <h4 *ngIf="!personType">Let {{ fname +' '+ lname }} know:</h4>
        <h4 *ngIf="personType">Let someone know:</h4>
        <p>
            <input type="email" class="form-control" [(ngModel)]="notify_email" placeholder="Enter email" />
        </p>
        <p>
            <label class="checkbox-inline">
        <input type="checkbox" [(ngModel)]="notifySetting" name="personType" />Send anonymous notification</label>
        </p>
        <button class="btn btn-default login" (click)="notify()">Notify</button>
    </div>
</div>
<style>
    @font-face {
        font-family: OpenSans-Regular;
        src: url('/assets/fonts/new/opensans-regular-webfont.eot?#iefix') format('embedded-opentype'), /* IE */
        url('/assets/fonts/new/opensans-regular-webfont.ttf') format("truetype");
        /* non-IE */
    }
    
    @font-face {
        font-family: OpenSans-Light;
        src: url('/assets/fonts/new/opensans-light-webfont.eot?#iefix') format('embedded-opentype'), /* IE */
        url('/assets/fonts/new/opensans-light-webfont.ttf') format("truetype");
        /* non-IE */
    }
    
    @font-face {
        font-family: OpenSans-Bold;
        src: url('/assets/fonts/new/opensans-bold-webfont.eot?#iefix') format('embedded-opentype'), /* IE */
        url('/assets/fonts/new/opensans-bold-webfont.ttf') format("truetype");
        /* non-IE */
    }
    
    @font-face {
        font-family: OpenSans-ExtraBold;
        src: url('/assets/fonts/new/opensans-extrabold-webfont.eot?#iefix') format('embedded-opentype'), /* IE */
        url('/assets/fonts/new/opensans-extrabold-webfont.ttf') format("truetype");
        /* non-IE */
    }
    
    @font-face {
        font-family: OpenSans-Semibold;
        src: url('/assets/fonts/new/opensans-semibold-webfont.eot?#iefix') format('embedded-opentype'), /* IE */
        url('/assets/fonts/new/opensans-semibold-webfont.ttf') format("truetype");
        /* non-IE */
    }
    
    .margin-left {
        margin-left: 10px;
    }
    
    * {
        color: gray;
    }
    
    h5 {
        font-size: 16px;
        font-family: OpenSans-Regular;
        margin-top: 15px;
        font-style: italic;
    }
    
    .signupbox {
        background-color: #d6d6d6;
        text-align: center;
        padding: 18px;
    }
    
    .login {
        background-color: #ffe284;
        margin-top: -2px;
    }
    
    .heading {
        color: #757575;
        font-family: OpenSans-Regular !important;
        padding-bottom: 20px;
        margin-bottom: 20px;
        padding-left: 15px;
        padding-right: 15px;
        text-align: center;
    }
    
    .checkboxdiv {
        display: flex;
        justify-content: center;
        color: gray;
        font-family: OpenSans-Semibold;
    }
</style>