/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./form-field.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./form-field.component";
var styles_FormFieldComponent = [i0.styles];
var RenderType_FormFieldComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FormFieldComponent, data: {} });
export { RenderType_FormFieldComponent as RenderType_FormFieldComponent };
function View_FormFieldComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "error"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", ""], ["src", "assets/new-assets/icons/alert-triangle.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorMessage; _ck(_v, 3, 0, currVal_0); }); }
export function View_FormFieldComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "main-ip"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "p", [["class", ""]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵncd(null, 0), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormFieldComponent_1)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ""; var currVal_1 = (_co.errorMessage ? "custom-label custom-label--error" : "custom-label"); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_3 = _co.errorMessage; _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.label; _ck(_v, 4, 0, currVal_2); }); }
export function View_FormFieldComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-form-field", [], null, null, null, View_FormFieldComponent_0, RenderType_FormFieldComponent)), i1.ɵdid(1, 114688, null, 1, i3.FormFieldComponent, [], null, null), i1.ɵqud(335544320, 1, { myDirective: 0 })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FormFieldComponentNgFactory = i1.ɵccf("app-form-field", i3.FormFieldComponent, View_FormFieldComponent_Host_0, { label: "label" }, {}, ["*"]);
export { FormFieldComponentNgFactory as FormFieldComponentNgFactory };
