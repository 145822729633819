import { Router, ActivatedRoute, Route } from '@angular/router';
import { Ng2AutoCompleteModule } from 'ng2-auto-complete';

import {
  Component,
  NgZone,
  EventEmitter,
  Input,
  Output,
  ChangeDetectorRef
} from '@angular/core';

import {
  Http,
  Response,
  Headers,
  URLSearchParams
}

  from '@angular/http';

import {
  ProjectService
}
  from './../projectservice';


import { IMultiSelectOption } from 'angular-2-dropdown-multiselect';

import * as _ from 'lodash';

import {
  CookieService
} from 'angular2-cookie/core';

import { Title } from '@angular/platform-browser';

@Component({
  selector: 'account',
  templateUrl: './account.html'
})

export class Account {
  selectedItemId: any = 1;
  privacy: boolean = false;
  privacyCompany: boolean = false;
  showCompanyPrivacy: boolean = false;
  org_name_slug: any;
  userEmail: any;
  orgEmail: any;
  isAdult: boolean = true;
  userGroups = [];
  freq = 'daily';
  hasPreference: boolean;
  constructor(public service: ProjectService, private router: Router, private http: Http, private cookie: CookieService, private cd: ChangeDetectorRef, private titleService: Title, private route: ActivatedRoute) {
    if(!localStorage.getItem('peopleid')) {
      this.router.navigate(['']);
    }
   }

  ngOnInit() {
    this.titleService.setTitle("Account | Repicture App");
    this.service.getPeopleDetail(localStorage.getItem('full_name_slug')).subscribe(res => {
      // this.userEmail = res['email'];
      if (res['is_private']) {
        this.privacy = true;
      }
      if(!res['is_adult']) {
        this.isAdult = false;
      }

      if (res['org_name_slug']) {
        this.org_name_slug = res['org_name_slug'];
        this.showCompanyPrivacy = true;
        this.service.getOrgDetail(res['org_name_slug']).subscribe(res => {
          this.orgEmail = res['org_email'];
          if (res['is_private']) {
            this.privacyCompany = true;
          }
        });
      } else {
        this.showCompanyPrivacy = false;
      }
    });
    this.service.getUserEmail().then(res => {
      this.userEmail = res['email'];
    });
    if (!_.isEmpty(this.route.snapshot.queryParams)) {
      this.selectedItemId = this.route.snapshot.queryParams['section'];
    }
  }

  ngAfterViewInit() {
    this.service.getMyGroupsWithNotifsPref().then(res => {
      for (let i = 0; i < res['results'].length; i++) {
        let obj = {};
        obj['notifs'] = _.isEmpty(res['results'][i].notification_preference) ? 'None' : res['results'][i].notification_preference;
        obj['member'] = res['results'][i].is_joined ? 'Yes' : 'No';
        obj['slug'] = res['results'][i].group_slug;
        obj['name'] = res['results'][i].group_name;
        this.userGroups.push(obj);
      }
    });

    this.service.getProjectNotification().then(res => {
        this.hasPreference = true;
        this.freq = res['notification_preference'];
    }).catch(err => {
      console.log(err);
      if (err['status'] == 404) {
        this.hasPreference = false;
      }
    });
  }

  updateEmail() {
    if (!this.userEmail) {
      // alert('Please enter an email!');
      this.service.sendAlert('Error', 'Please enter an email!', 0);
      return;
    }

    let body = {
      "email": this.userEmail.trim()
    };

    let people_id = localStorage.getItem("peopleid");
    this.service.updateuseremail(body).then(result => {
     // alert('An email has been sent to your email address with re-verifying email instructions.');
      this.service.sendAlert('Success', 'An email has been sent to your email address with re-verifying email instructions.', 0);
    });
  }

  updateOrgEmail() {
    if (!this.orgEmail) {
     // alert('Please enter an email!');
      this.service.sendAlert('Error', 'Please enter an email!', 0);
      return;
    }

    let body = {
      "org_email": this.orgEmail.trim()
    };

    this.service.doCompanyProfilePatch(this.service.myOrg, body).then(result => {
      //alert('Email updated successfully!');
      this.service.sendAlert('Success', 'Email updated successfully!', 1);
    });
  }


  changePrivacy() {
    let body = {
      "is_private": this.privacy
    };

    let people_id = localStorage.getItem("full_name_slug");
    this.service.doUserProfilePatch(people_id, body).then(result => {
      this.service.peoples = [];
    });

  }

  savePref() {
    let obj = {
      notification_preference: this.freq
    };

    if (this.hasPreference) {
      this.service.updateProjectNotification(obj).then(res => {
        this.service.sendAlert('Success', 'Preference is updated successfully.', 1);
      });
    } else {
      this.service.setProjectNotification(obj).then(res => {
        this.service.sendAlert('Success', 'Preference is updated successfully.', 1);
      });
    }
  }

  changePrivacyCompany() {
    let body = {
      "is_private": this.privacyCompany
    };

    this.service.doCompanyProfilePatch(this.org_name_slug, body).then(result => {
      this.service.companies = [];
    });

  }

  changePassword(pOld, p1, p2) {
    if (pOld.trim() == "" || p1.trim() == "" || p2.trim() == "") {
      //alert('Complete all fields to continue.');
      this.service.sendAlert('Error', 'Complete all fields to continue.', 0);
      return;
    }
    var elem = document.getElementById("p1");
    var pattern = elem.getAttribute("pattern");
    var re = new RegExp(pattern);
    if (!re.test(elem['value'])) {
      //alert('Please revise password to meet complexity requirements.');
      this.service.sendAlert('Error', 'Please revise password to meet complexity requirements.', 0);
      return;
    } else {
      this.service.changePassword(pOld, p1, p2).then(result => {
        //alert('Password changed successfully.');
        this.service.sendAlert('Success', 'Password changed successfully.', 1);
        document.getElementById("pOld")['value'] = '';
        document.getElementById("p1")['value'] = '';
        document.getElementById("p2")['value'] = '';
      });
    }
  }
}
