import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { reject } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { resolve } from 'url';
import { ProjectService } from '../projectservice';
import { School } from './profileModel';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {

  schoolArray$:Observable<School[]>;
 


  constructor(private httpcClient : HttpClient,private service:ProjectService) { }

  getSchoolList(searchedText:string) : Observable<School[]>{
    console.log(searchedText);

    let headers = new HttpHeaders({
        'Content-Type':  'application/json',
        Authorization: localStorage.getItem('authtoken')
      })

      return this.httpcClient.post<School[]>(this.service.url + "/" + searchedText,headers);

  }

}