import { CreatePerson } from './../createpersoncomponent/createperson.component';
import {
  Component,
  NgZone,
  Input,
  ViewEncapsulation, ChangeDetectorRef, ViewChild, Inject, PLATFORM_ID
} from '@angular/core';

import {
  trigger,
  state,
  style,
  animate,
  transition,
  keyframes,
} from "@angular/animations";

import { Http } from "@angular/http";
import { Title, Meta } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";

import { ProjectService } from "./../projectservice";

import { CookieService } from "angular2-cookie/core";
import { Ng2AutoCompleteModule } from "ng2-auto-complete";

import { IMultiSelectOption } from "angular-2-dropdown-multiselect";
import {
  ImageCropperComponent,
  CropperSettings,
  Bounds,
} from "ng2-img-cropper";

import {
  FileSelectDirective,
  FileDropDirective,
  FileUploader,
  FileUploaderOptions,
  FileUploadModule,
} from "ng2-file-upload";

// import {
//   MapsAPILoader
// } from 'angular2-google-maps/core';

import { NgControl, Validators, FormBuilder } from "@angular/forms";

import { isPlatformBrowser } from '@angular/common';
import * as _ from "lodash";
import { Subject } from "rxjs/Subject";
import { Observable } from "rxjs/Observable";

declare var google: any;
declare var jQuery: any;

@Component({
  selector: "my-app",
  templateUrl: "./projectcreate.html",
  styleUrls: ["./projectcss.scss"],
  encapsulation: ViewEncapsulation.Emulated,
  animations: [
    trigger("fadeInOut", [
      transition("void => *", [
        style({
          opacity: 0,
        }), //style only for transition transition (after transiton it removes)
        animate(
          700,
          style({
            opacity: 1,
          })
        ), // the new state of the transition(after transiton it removes)
      ]),
      transition("* => void", [
        animate(
          700,
          style({
            opacity: 0,
          })
        ), // the new state of the transition(after transiton it removes)
      ]),
    ]),
    trigger("enterAnimation", [
      transition("void => *", [
        style({ transform: "translateX(100%)", opacity: 0 }),
        animate("500ms", style({ transform: "translateX(0)", opacity: 1 })),
      ]),
    ]),
  ],
})
export class ProjectCreate {
  name1: string;
  dat: any;
  cropperSettings1: CropperSettings;
  croppedWidth: number;
  croppedHeight: number;
  lat: any = 37.77493;
  lng: any = -122.419416;
  public tags = ["Car", "Bus", "Train"];
  otherCat: any;
  otherCatType: any;
  showModal: boolean = false;
  isCCSelected: boolean = false;
  showMap: boolean = false;
  // loginstatus:string="Log in";
  // public autocompleteTags = [];
  formDataFromServer: any;
  state: any = "inactive";
  imageData: any;
  data: any = {
    name: "Testing",
    rooms: {},
  };
  clicked: boolean = false;
  Onimage: boolean = false;
  displaylistofimages: boolean = false;
  projectbuildtext: string = "Publish Your Project";
  imageDataFromServer: any = [];
  month: any = "Month";
  year: any = "Year";
  latformarker: any;
  lngformarker: any;
  locationToggle: any;
  divcounter: number = 0;
  increment: number = 0;
  conditionExpression: number = 3;
  date: any = '';
  Caption: any;
  Tags: any;
  b1: boolean = true;
  b2: boolean = false;
  b3: boolean = false;
  b4: boolean = false;
  b5: boolean = false;

  d1: boolean = true;
  d2: boolean = false;
  d3: boolean = false;
  d4: boolean = false;
  d5: boolean = false;

  lngt: any;
  showSection: boolean = false;
  showWarning: boolean = false;
  showAlert: boolean = false;
  showLogin: boolean = false;
  showValidation: boolean = false;
  loginStatus: boolean = false;
  zoom: number = 8;
  name: any = '';
  type: any = [];
  status: any = "";
  subcat: any = [];
  yearText: any = "Year Completed or Anticipated Completion Year";
  yearTextErr: any;
  itext: string = " Indicates required fields";
  location_desc: any;
  fileUploading: boolean = false;
  dummydata: any;
  togglesection: any = true;
  location: any = "";
  shorter_location: any = "";
  ProjectDetailModel: ProjectDetailModel[] = [];
  markers: marker[] = [];
  markers1: marker[] = [];
  markers2: marker[] = [];
  nextBtnShow: boolean = true;
  ValidationToggleValue: any;
  project_id: any;
  map: any;
  loginObject: any = {};
  multiddindex: any;
  placeRecognized: boolean = false;
  @ViewChild("cropper", { static: false }) cropper: ImageCropperComponent;
  @ViewChild("imgcrp", { static: false }) imgcrp: HTMLImageElement;
  project_id1: any;
  license_selected: any;
  showLengthAlert = false;
  authors: Owners[] = [
    { user_id: 0, author_name: '', slug: '' },
    { user_id: 0, author_name: '', slug: '' },
    { user_id: 0, author_name: '', slug: '' },
    { user_id: 0, author_name: '', slug: '' },
  ]
  arrayOfStrings = [];
  currentEntering = 0;
  permission_file_name: any;
  isPermissionUploaded: boolean = false;
  permission_file: any;
  perm_index: any;
  permission_file_URL: any;
  constructor(
    private zone: NgZone, private router: Router, public service: ProjectService, private cookie: CookieService, private titleService: Title, private http: Http, private route: ActivatedRoute, private cd: ChangeDetectorRef, @Inject(PLATFORM_ID) private platform: any, private meta: Meta) {
    this.name1 = 'Angular2';
    if (isPlatformBrowser(this.platform)) {
      this.cropperSettings1 = new CropperSettings();
      this.cropperSettings1.width = 600;
      this.cropperSettings1.height = 450;

      this.cropperSettings1.croppedWidth = 200;
      this.cropperSettings1.croppedHeight = 200;

      var w = window,
        d = document,
        e = d.documentElement,
        g = d.getElementsByTagName('body')[0],
        x = w.innerWidth || e.clientWidth || g.clientWidth,
        y = w.innerHeight || e.clientHeight || g.clientHeight;

      this.cropperSettings1.canvasWidth = x - 200; //document.getElementsByTagName('body')[0].clientWidth - 200;
      this.cropperSettings1.canvasHeight = y - 200; //document.getElementsByTagName('body')[0].clientHeight - 200;

      this.cropperSettings1.minWidth = 10;
      this.cropperSettings1.minHeight = 10;

      this.cropperSettings1.rounded = false;
      this.cropperSettings1.keepAspect = true;
      this.cropperSettings1.preserveSize = true;

      this.cropperSettings1.cropperDrawSettings.strokeColor = 'cadetblue';
      this.cropperSettings1.cropperDrawSettings.strokeWidth = 3;

      this.cropperSettings1.fileType = 'image/jpeg';
      this.cropperSettings1.noFileInput = true;

      this.dat = {};

      this.service.search(this.searchTerm$.asObservable(), 'people')
        .subscribe(results => {
          this.appendDropDown(results);
        });
    }
  }

  searchTerm$ = new Subject<string>();


  bs: any =
    "<b><u>Our Photo Sharing Policy</u></b><br>" +
    "<br>" +
    "It is important that you only use photographs that you are legally allowed to share and that you give proper credit. This means you can’t use any photograph you find on the internet (or elsewhere). In addition, must give the licensing information and appropriate attribution for any photograph you use." +
    "<br><br>" +
    "You may only upload the follow three types of photos:" +
    "<br><br>" +
    "<ol>" +
    "<li><b>Taken by You.</b> Feel free to post photos you have taken of engineering projects. However, it cannot be photos of published material or include logos.</li>" +
    "<li><b>Designed by the Creator as Public Domain.</b> If the photo is clearly designated as public domain, you can upload it. You cannot assume a photo is public domain – it must be indicated as such.</li>" +
    "<li><b>Taken by You.Licensed by Creative Commons Attribution.logos.</b>When you search the internet for images that are “labeled for reuse”, these photographs are often covered by Creative Commons Attribution. Typically, this will be indicted in the licensing section or you have to click “some rights reserved” to determine if they are Licensed by creative commons attributions. For photos that are licensed by Creative Commons Attribution (CCO), you may use the image if you provide proper credit (i.e. attribution) for the photograph. Repicture shows the required fields during the photo upload process.</li>" +
    "</ol>" +
    "<br>" +
    "If you are not sure if a photo fits one of these three areas, do not include the photograph!" +
    "<br><br>" +
    "<b><u>Where to Find Photos Online</u></b><br>" +
    "There are many online sites where you can find photos. However, you must be in compliance with any copyright or license requirements. Sources that may have photos include: <br>" +
    "<br>" +
    "<b><u>1. Online Sources of Free, License-Free Photos</u></b> <br>" +
    "<br>" +
    "The photos on these sites are free to download and use without getting permission or giving credit. They are mostly <a class='link' href='https://creativecommons.org/publicdomain/zero/1.0/' target='_blank'>Public Domain Dedication (CC0)</a>, but they appreciate giving credit to the creator." +
    "<br>" +
    "<br>" +
    "<a class='link' href='https://www.pexels.com/' target='_blank'>pexels.com</a> <br>" +
    "<br>" +
    "<a class='link' href='https://unsplash.com/' target='_blank'>unsplash.com</a>  <br>" +
    "<br>" +
    "<a class='link' href='https://pixabay.com/' target='_blank'>pixabay.com</a>  <br>" +
    "<br>" +
    "<a class='link' href='https://publicdomainpictures.net/' target='_blank'>publicdomainpictures.net</a>  <br>" +
    "<br><br>" +
    "<b><u>2. Other Online Sources of Free Photos</u></b><br>" +
    "<br>" +
    "There are many other websites where you can find photos under <a class='link' href='https://creativecommons.org/' target='_blank'>Creative Commons</a> licenses that are free to use. Creative Commons is a non-profit organization that publishes free copyright licenses to allow creators to share and license their work online. Look for the Creative Commons <a class='link' href='https://creativecommons.org/about/downloads' target='_blank'>logo and license icons</a>, usually found at the bottom of the photo to check the license requirements before using these photos. Some sites with free photos include:" +
    "<br><br>" +
    "<a class='link' href='https://www.wikipedia.org/' target='_blank'>wikipedia.org</a><br>" +
    "<br>" +
    "<a class='link' href='https://creativecommons.org/' target='_blank'>Creativecommons.org</a><br>" +
    "<br>" +
    "<a class='link' href='https://www.flickr.com/' target='_blank'>flickr.com</a> (you must verify that the photo is under a Creative Commons license or designated as public domain. If all rights are reserved, you cannot use it)<br>" +
    "<br>" +
    "<br>" +
    "<b><u>How to Credit the Author on the RePicture App</u></b>  <br>" +
    "<br>" +
    "When you upload a photo you can credit the Author in the “Credit” field. If the photo is under a <a class='link' href='https://creativecommons.org/' target='_blank'>Creative Commons</a> license use the drop downs to select the Creative Commons license that applies and complete the required information. <br>" +
    "<br>" +
    "<br>" +
    "<b><u>Apply a License or Reserve Rights to Your Own Photo on the RePicture App</u></b><br>" +
    "<br>" +
    "If a photo belongs to you, you can decide if you will allow the photo to be public domain or covered by Creative Commons. In the License dropdown select “This file is my own work” and in the second drop down select the Creative Commons license you would like to use.<br>" +
    "<br>" +
    "<br>" +
    "<b><u>Creative Commons Licenses</u></b><br>" +
    "<br>" +
    "Creative Commons is a non-profit organization that publishes free copyright licenses to allow creators to share and license their work online. Creative Commons allows authors to specify how their photos can be used and distributed. To see more details about Creative Commons Licenses, go to<br>" +
    "<a class='link' href='https://creativecommons.org/licenses' target='_blank'>https://creativecommons.org/licenses</a> <br>" +
    "<br>" +
    "<br>" +
    "<b><u>About Public Domain Photos</u></b><br>" +
    "<br>" +
    "Public domain photos are free of known copyright restrictions. Learn more about public domain photos here: <a class='link' href='https://creativecommons.org/share-your-work/public-domain/' target='_blank'>Creative Commons Public Domain</a><br>" +
    "<br>";

  ngOnInit() {
    // this.titleService.setTitle("Create project");
    this.meta.updateTag({
      property: "og:title",
      content: "Create project" + " | " + this.service.project_name,
    });
    this.meta.updateTag({
      property: "og:description",
      content: "Research and upload your engineering or science project.",
    });
    this.meta.updateTag({
      name: "description",
      content: "Research and upload your engineering or science project.",
    });
    this.divcounter++;
    this.divVisiblity();
    if (localStorage.getItem('authtoken')) {
      this.loginStatus = true;
    } else {
      this.loginStatus = false;
    }
    if (_.isEmpty(this.service.projectNames)) {
      this.service.getProjectsName().subscribe(res => {
        this.service.projectNames = res['results'];
        for (let i = 0; i < this.service.projectNames.length; i++) {
          this.service.projectNamesonly.push(
            this.service.projectNames[i].project_name
          );
        }
      });
    }
    this.route.params.subscribe(params => {
      if (params['id']) {
        this.divcounter++;
        this.divVisiblity();
        //this.formDataFromServer.project_id = params['id'];
        this.itext = 'Upload Image Files';
        this.service.getProjectDetail(params['id']).subscribe(res => {
          this.project_id1 = res['project_id'];
          this.project_id = res['project_name_slug'];
        });
        // window.history.replaceState({}, '', 'createproject/' + this.project_id1);
      }
    });
    this.uploader.onBeforeUploadItem = (item) => {
      item.withCredentials = false;
    }
  }

  validateEmail(email) {
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    }
  }

  login() {
    if (!this.loginObject.email.trim() || !this.loginObject.password.trim()) {
      return;
    }
    if (this.validateEmail(this.loginObject.email)) {
      //alert("You have entered an invalid email address!");
      this.service.sendAlert(
        "Error",
        "You have entered an invalid email address!",
        0
      );
      return false;
    }
    // this.loginClicked = true;
    //this.loading = true;
    this.service
      .doLogin(this.loginObject.email, this.loginObject.password)
      .then((result) => {
        if (result["token"]) {
          // this.router.navigate(['']);
          this.loginObject.email = "";
          this.loginObject.password = "";
          this.loginStatus = true;
        } else {
          // this.loginClicked = false;
          //this.loading = false;
          let varx = "";

          _.forEach(<any>result, function (value, key) {
            varx = varx + value[0];
          });
          alert(varx);
        }
      });
  }

  closeMultidd() {
    try {
      for (let i = 0; i < 2; i++) {
        document.getElementsByClassName("dropdown-menu")[i]["style"].display =
          "none";
      }
    } catch (e) { }
  }

  onCancel2() {
    this.authors = [
      { user_id: 0, author_name: '', slug: '' },
      { user_id: 0, author_name: '', slug: '' },
      { user_id: 0, author_name: '', slug: '' },
      { user_id: 0, author_name: '', slug: '' },
    ];
  }



  filterAuthors() {
    // const result = _.filter(_.uniq(this.authors, function(item, key, a) {
    //   return item.user_id;
    // }), function(element) {
    //   return element.user_id && element.author_name && element.slug
    // });
    this.authors = this.authors.filter(a => a.user_id != this.service.loggedInUserGetter().id)

    const result = this.authors.slice(0);
    for (let index = 0; index < result.length; index++) {
      const element = result[index];
      if (Object.values(element).every(o => o === '' || o === 0)) {
        this.authors.splice(index, result.length - index);
        break;
      }
    }
    if (this.authors.length > 0) {
      console.log(this.service.loggedInUserGetter());
      const uid = this.service.loggedInUserGetter().id;
      const uname = this.service.loggedInUserGetter().first_name + " " + this.service.loggedInUserGetter().last_name;
      const uslug = this.service.loggedInUserGetter().full_name_slug;

      this.authors.push({user_id:uid,author_name:uname,slug:uslug});
    }
    console.log(this.authors);
    // if (this.authors.length > 0) {
    // this.authors.push({user_id: Number(localStorage.getItem('peopleid')), author_name: localStorage.getItem('first_name')+' '+localStorage.getItem('last_name'), slug: localStorage.getItem('full_name_slug')});
    // }
  }

  onEnter() {
    if (this.authors.length === 0) {
      this.service.sendAlert("Error!", "No co-owner has been added.", 0);
      return;
    }
  }

  onAddmore() {
    if (this.authors.length === 9) {
      this.service.sendAlert("Error", "Maximum 9 co-authors are allowed!", 0);
      return;
    }
    this.authors.push({ author_name: '', user_id: 0, slug: '' });
  }

  selectName($event) {
    this.authors[this.currentEntering].author_name = $event['fullname'];
    this.authors[this.currentEntering].user_id = $event['id'];
    this.authors[this.currentEntering].slug = $event['slug'];
    this.arrayOfStrings = [];
  }

  appendDropDown(data) {
    this.arrayOfStrings = [];
    _.forEach(data, (value) => {
      let obj = {
        fullname: value.first_name + " " + value.last_name,
        // email: value.email,
        profile_image: value.profileImage,
        id: value.id,
        slug: value.full_name_slug
      }
      if (value.id != window.localStorage.getItem('peopleid')) {
        this.arrayOfStrings.push(obj);
      }
    });
  }

  showOwnerSetting: boolean = false;
  isEmptyString: boolean = false;

  onChange1($event, index) {
    console.log(index, $event.target.value);
    this.license_selected = $event.target.value;
    if ($event.target.value == "own_work") {
      this.showOwnerSetting = true;
      this.uploader.queue[index]["showOwnerSetting"] = true;
      this.uploader.queue[index]["isEmptyString"] = false;
      this.isEmptyString = false;
    } else if ($event.target.value == "" || $event.target.value == "none") {
      this.uploader.queue[index]["isEmptyString"] = false;
      this.isEmptyString = false;
      this.showOwnerSetting = false;
      this.uploader.queue[index]["showOwnerSetting"] = false;
      this.cd.detectChanges();
      this.uploader.queue[index]["isCCSelected"] = false;
      this.cd.detectChanges();
      console.log(this.uploader.queue[index]["isCCSelected"]);
      console.log(this.uploader.queue);
    } else {
      this.uploader.queue[index]["isEmptyString"] = true;
      this.isEmptyString = true;
      this.showOwnerSetting = false;
      this.uploader.queue[index]["showOwnerSetting"] = false;
    }
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platform)) {
      try {
        for (let i = 0; i < document.getElementsByClassName("dropdown-toggle").length; i++) {
          if (document.getElementsByClassName("dropdown-toggle")[i]['innerText'].toString().trim() == 'Select project subcategory') {
            document.getElementsByClassName("dropdown-toggle")[i].setAttribute("disabled", "disabled");
            //console.log('raja', document.getElementsByClassName("dropdown-toggle")[i]);
            this.multiddindex = i;
            break;
          }
        }
      } catch (e) { console.log('try catch') }
    }
  }

  checkName() {
    if (_.includes(this.service.projectNamesonly, this.name)) {
      jQuery("#SameNameModal").modal("show");
      return true;
    } else {
      return false;
    }
  }

  gotoExplorer() {
    sessionStorage.setItem('query', this.name);
    // window.open('/explorer', '_blank');
    this.router.navigate(["/explorer/projects"]);
  }

  initMap() {
    if (this.showMap) {
      return;
    }
    this.ShowMap();
    setTimeout(() => {
      var myLatLng = { lat: this.lat, lng: this.lng };
      this.map = new google.maps.Map(document.getElementById("map"), {
        center: myLatLng,
        zoom: this.zoom,
        zoomControl: true,
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        zoomControlOptions: {
          position: google.maps.ControlPosition.RIGHT_BOTTOM,
          style: google.maps.ZoomControlStyle.LARGE,
        },
      });

      var input = document.getElementById("autocompleteinput");
      var autocomplete = new google.maps.places.Autocomplete(input);

      // Bind the map's bounds (viewport) property to the autocomplete object,
      // so that the autocomplete requests use the current map bounds for the
      // bounds option in the request.
      autocomplete.bindTo("bounds", this.map);

      // var marker = new google.maps.Marker({
      //   map: this.map,
      //   anchorPoint: new google.maps.Point(0, -29)
      // });
      // this.marker = new google.maps.Marker({
      //   draggable: true,
      //   map: this.map
      // });

      let componentForm = {
        locality: "long_name",
        administrative_area_level_1: "long_name",
        country: "long_name",
      };

      autocomplete.addListener("place_changed", () => {
        this.placeRecognized = true;
        this.place = autocomplete.getPlace();
        let place = autocomplete.getPlace();
        this.location = document.getElementById("autocompleteinput")["value"];
        var val = "";
        for (var i = 0; i < place.address_components.length; i++) {
          var addressType = place.address_components[i].types[0];
          if (componentForm[addressType]) {
            if (addressType == "country") {
              val += place.address_components[i][componentForm[addressType]];
            } else {
              val +=
                place.address_components[i][componentForm[addressType]] + ", ";
            }
          }
        }
        this.shorter_location = val;
        this.service.checkForSameAddress(this.location).then((res) => {
          if (res["status"] == "Location already exists") {
            //alert('This address is already being used on another project.');
            // this.service.sendAlert('Error', 'This address is already being used on another project.', 0);
          }
        });
        if (!place.geometry) {
          // User entered the name of a Place that was not suggested and
          // pressed the Enter key, or the Place Details request failed.
          //window.alert("Error in map data, try with different location or try again later.");
          this.service.sendAlert(
            "Error",
            "Error in map data, try with different words or try again later.",
            0
          );
          return;
        }

        // If the place has a geometry, then present it on a map.
        if (place.geometry.viewport) {
          this.map.fitBounds(place.geometry.viewport);
        } else {
          this.map.setCenter(place.geometry.location);
          this.map.setZoom(17); // Why 17? Because it looks good.
        }
        // this.marker.setVisible(true);
        // this.marker.setPosition(place.geometry.location);

        // console.log('ss',place.geometry.location);
        this.latformarker = place.geometry.location.lat();
        this.lngformarker = place.geometry.location.lng();
        // this.placeMarker(place.geometry.location, this.map);
        if (this.count > 0) {
          this.marker.setMap(null);
        }
        this.count++;
        // this.latformarker = place.geometry.location.lat();
        // this.lngformarker = place.geometry.location.lng();
        this.marker = new google.maps.Marker({
          position: place.geometry.location,
          draggable: true,
          map: this.map,
        });
        // }
        this.marker.addListener("dragend", (e) => {
          this.putMapLocationToTextbox(e.latLng.lat(), e.latLng.lng());
        });
        this.map.addListener("click", (e) => {
          this.placeMarker(e.latLng, this.map);
        });
      });
      this.map.addListener("click", (e) => {
        this.placeMarker(e.latLng, this.map);
      });
    }, 600);
  }
  latlngChecked: boolean = false;
  worldwideChecked: boolean = false;
  count: any = 0;
  marker: any;
  place: any;
  placeMarker(latLng, map) {
    console.log(latLng);

    if (this.count == 0) {
      this.count++;
      this.latformarker = latLng.lat();
      this.lngformarker = latLng.lng();
      this.marker = new google.maps.Marker({
        position: latLng,
        draggable: true,
        map: map,
      });
    }
    this.marker.addListener("dragend", (e) => {
      this.putMapLocationToTextbox(e.latLng.lat(), e.latLng.lng());
    });
    this.marker.setPosition(new google.maps.LatLng(latLng.lat(), latLng.lng()));
    //map.panTo(latLng);
    this.putMapLocationToTextbox(latLng.lat(), latLng.lng());
  }

  limitChars(evt) {
    // get the string
    let myString = this.name;
    if (this.name && this.name.length > 0) {
      // use / /g to remove all spaces from the string
      let remText = myString.replace(/ /g, "");

      // get the length of the string after removal
      const length = remText.length;
      if (length === 80 && evt.keyCode !== 8) {
        evt.preventDefault();
        if (evt.keyCode !== 8) {
          jQuery("#lengthAlertModel").modal("show");
        }
        return false;
      }
    }
  }

  moveMap() {
    if (this.location_latlng.indexOf(",") >= 0) {
      if (this.marker) {
        this.marker.setMap(null);
      }
      this.map.setCenter(
        new google.maps.LatLng(
          this.location_latlng.split(",")[0],
          this.location_latlng.split(",")[1]
        )
      );
      this.map.setZoom(10);

      this.count++;
      // this.latformarker = place.geometry.location.lat();
      // this.lngformarker = place.geometry.location.lng();
      this.marker = new google.maps.Marker({
        position: new google.maps.LatLng(
          this.location_latlng.split(",")[0],
          this.location_latlng.split(",")[1]
        ),
        // draggable: true,
        map: this.map,
      });
    }
  }

  isLogged() {
    return localStorage.getItem('authtoken');
  }

  //loginstatus:string="Log in";

  check() {
    // console.log("Session Id",this.cookie.getObject('sessionid'));
    // console.log("Cookie", this.cookie.get('csrftoken'));
    // console.log("GetAll", this.cookie.getAll());

  }

  ShowMap() {
    this.showMap = true;
    this.state = "active";
    // if (this.showMap) {
    //   this.showMap = false;
    //   this.state = "inactive";
    // } else {
    //   this.showMap = true;
    //   this.state = "active";

    // }
  }

  putMapLocationToTextbox(lat, lng) {
    this.latformarker = lat;
    this.lngformarker = lng;
    this.http
      .get(
        "https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyC3u8Ed_b8ejvMLt8O3Vs7QNkYMMKdqvgc&latlng=" +
        lat +
        "," +
        lng
      )
      .toPromise()
      .then((result) => {
        let res = JSON.parse(result["_body"]);
        console.log("res", res["results"]);
        res = res["results"];
        if (res.length == 0) {
          //alert("Something's not quite right. Try entering the address again or re-fresh the page.");
          this.service.sendAlert(
            "Error",
            "1Something's not quite right. Try entering the address again or re-fresh the page.",
            0
          );
          this.location = "";
          return;
        }
        let loc = "";
        let formatted_address = res[0]["formatted_address"].split(",");
        this.cd.detectChanges();
        let shorter_loc = "";
        for (let j = 0; j < res[0].address_components.length; j++) {
          if (res[0].address_components[j].types[0] == "locality") {
            // formatted_address[formatted_address.length - 3] = ' ' + res[0].address_components[j].long_name;
            shorter_loc = res[0].address_components[j].long_name + ", ";
          }
          if (
            res[0].address_components[j].types[0] ==
            "administrative_area_level_1"
          ) {
            // formatted_address[formatted_address.length - 2] = ' ' + res[0].address_components[j].long_name;
            shorter_loc += res[0].address_components[j].long_name + ", ";
          }
          if (res[0].address_components[j].types[0] == "country") {
            // formatted_address[formatted_address.length - 1] = ' ' + res[0].address_components[j].long_name;
            shorter_loc += res[0].address_components[j].long_name;
          }

          if (res[0].address_components[j].types[0] == "locality") {
            formatted_address[formatted_address.length - 3] =
              " " + res[0].address_components[j].short_name;
          }
          if (
            res[0].address_components[j].types[0] ==
            "administrative_area_level_1" &&
            (formatted_address.toString().indexOf("United States") >= 0 ||
              formatted_address.toString().indexOf("USA") >= 0)
          ) {
            formatted_address[formatted_address.length - 2] =
              " " + res[0].address_components[j].short_name;
          } else if (
            res[0].address_components[j].types[0] ==
            "administrative_area_level_1" &&
            (formatted_address.toString().indexOf("United States") == -1 ||
              formatted_address.toString().indexOf("USA") == -1)
          ) {
            formatted_address[formatted_address.length - 2] =
              " " + res[0].address_components[j].long_name;
          }
          if (
            res[0].address_components[j].types[0] == "country" &&
            res[0].address_components[j].short_name == "US"
          ) {
            formatted_address[formatted_address.length - 1] =
              " " + res[0].address_components[j].short_name + "A";
          } else if (
            res[0].address_components[j].types[0] == "country" &&
            res[0].address_components[j].short_name != "US"
          ) {
            formatted_address[formatted_address.length - 1] =
              " " + res[0].address_components[j].long_name;
          }
        }
        this.shorter_location = shorter_loc;
        this.location = formatted_address.join(",");
        //this.location.replace(/,\s3$/, loc);
        this.cd.detectChanges();
        console.log("dsf", this.location);
        console.log("dsf", this.shorter_location);
        this.placeRecognized = true;
      })
      .catch((ex) => {
        console.error("Error fetching location", ex);
      });
  }

  statusChange(val) {
    if (val == "Study/Design Phase" || val == "Under Construction") {
      this.yearText = "Anticipated Completion Year";
    } else if (val == "") {
      this.yearText = "Year Completed or Anticipated Completion Year";
    } else {
      this.yearText = "Year Completed";
    }
  }

  removeMarker(label: string, index: number) {
    let x: JSON;
  }

  i: number = 0;

  testJson(test: string) {
    this.i++;

    let newUser = "user" + this.i;
    let newValue = "value" + this.i;

    this.data["rooms"][newUser] = {};

    //data.['a']={};
    //  data.test['b']={};
    //data.test['c']="Hello";

    console.log(this.data);
  }

  unknownNamechkbox: boolean = false;

  clickNextx(comment: string) {
    if (this.checkName()) {
      return;
    }
    console.log("hello", this.latformarker, this.lngformarker);
    this.divcounter++;
    //alert(this.divcounter);
    this.divVisiblity();
  }

  location_latlng: any = "";

  clickNext(comment: string) {
    

    console.log(this.name.length);
    if (this.name.length > 80) {
      jQuery("#lengthAlertModel").modal("show");

      return;
    }

    this.yearTextErr = "";
    this.service.sendEvent(
      "click",
      "project creation started(goto next step button)",
      "project name: " + this.name
    );

    if (this.divcounter < 6 && this.divcounter >= 1) {
      if (comment === "back") {
        if (this.divcounter !== 1) {
          this.divcounter--;
          this.divVisiblity();

          this.service.postDataFromCreatePage([]);
        }
      }

      if (comment === "next") {
        if (this.divcounter == 2) {
          this.cookie.put("firstVisit", "1");
          if (
            this.uploader.queue.length != 0 &&
            this.uploader.getNotUploadedItems().length == 0
          ) {
            console.log("Hello", this.uploader.getNotUploadedItems().length);
            this.service.sendEvent(
              "click",
              "project creation (step 2) finished successfully",
              "landed to project page: " + this.name
            );
            this.router.navigate(["/project", this.project_id]);
            //            this.router.navigate(['/project', this.project_id, 'test']);
          } else {
            // this.showWarning = true;
            this.service.sendAlert(
              "Error",
              "You have not uploaded any images, Please add image(s) to continue.",
              0
            );
          }
        }

        if (this.divcounter == 1) {
          // this.onEnter();
          if (!_.isEmpty(this.name) || this.unknownNamechkbox) {
            if (this.noNeedForLocation) {
              this.showAlert = false;
              if (!this.yearValidation(this.date)) {
                return;
              }
              if (this.date) {
                let date1 = new Date();
                let currentYear = date1.getFullYear();
                if (
                  this.yearText !=
                  "Year Completed or Anticipated Completion Year"
                ) {
                  if (this.yearText == "Anticipated Completion Year") {
                    if (this.date < currentYear) {
                      this.yearTextErr =
                        "Please enter future year as project status is " +
                        this.status +
                        ".";
                      return;
                    }
                  } else {
                    if (this.date > currentYear) {
                      this.yearTextErr =
                        "Please enter past year as project status is " +
                        this.status +
                        ".";
                      return;
                    }
                  }
                }
              }
              if (localStorage.getItem('authtoken')) {
                if (this.checkName()) {
                  console.log("checkname");
                  return;
                }
                console.log("continue");
                //this.showModal = false;
                //this.d1 = false;
                // if (_.includes(this.type, 'OTR')) {
                //   if (!_.isEmpty(this.otherCatType)) {
                //     let index = _.indexOf(this.type, _.find(this.type, 'OTR'));
                //     this.type.splice(index, 1);
                //     this.type.push(this.otherCatType);
                //   }
                // }

                // if (_.includes(this.subcat, 'OT')) {
                //   if (!_.isEmpty(this.otherCat)) {
                //     let index = _.indexOf(this.subcat, _.find(this.subcat, 'OT'))
                //     this.subcat.splice(index, 1);
                //     this.subcat.push(this.otherCat);
                //   }
                // }




                this.filterAuthors();
                // if (this.authors.length > 0) {
                //   console.log(this.service.loggedInUserGetter());
                //   const uid = this.service.loggedInUserGetter().id;
                //   const uname = this.service.loggedInUserGetter().first_name + " " + this.service.loggedInUserGetter().last_name;
                //   const uslug = this.service.loggedInUserGetter().full_name_slug;

                //   this.authors.push({user_id:uid,author_name:uname,slug:uslug});
                // }




                this.ProjectDetailModel.push({
                  lat: this.latformarker ? this.latformarker : 0,
                  lng: this.lngformarker ? this.lngformarker : 0,
                  label: this.name,
                  name: this.name,
                  type: this.type,
                  subcat: this.subcat,
                  shorter_location: this.shorter_location,
                  status: this.status,
                  location: this.worldwideChecked ? "Worldwide" : this.location,
                  location_desc: this.location_desc,
                  date: this.date,
                  draggable: false,
                  has_poly: false,
                  project_subtype_other: this.otherCat,
                  project_type_other: this.otherCatType,
                  co_owners: this.authors
                });

                console.log('modal', this.ProjectDetailModel);
                this.service
                  .doPostProject(this.ProjectDetailModel[0])
                  .then((result) => {
                    this.formDataFromServer = result;
                    this.projectbuildtext = "Upload Project Images";
                    this.project_id = this.formDataFromServer.project_name_slug;
                    this.project_id1 = this.formDataFromServer.project_id;
                    // for (let i = 0; i < this.service.projectNames.length; i++) {
                    //   if (this.service.projectNames[i].project_name == this.name) {
                    this.service.projectNames.push({
                      project_id: this.project_id,
                      project_name: this.name,
                    });
                    //   }
                    // }
                    this.itext = "Upload Image Files";
                    window.history.replaceState(
                      {},
                      "",
                      "createproject/" + this.project_id
                    );
                    this.divcounter++;
                    this.divVisiblity();
                    this.service.sendEvent(
                      "click",
                      "project upload step 1 success",
                      "project name: " + this.name
                    );
                    console.log("jj");
                  });
                this.ProjectDetailModel = [];
              } else {
                this.showAlert = false;
                // this.showLogin = true;
                this.service.sendAlert(
                  "Error",
                  "You need to login or join in order to add a project.",
                  0
                );
                //this.ValidationToggleValue = "1";
                //this.showModal = true;
                // document.getElementById('loginModal').click();
                return false;
              }
            } else {
              if (
                !_.isEmpty(this.location) ||
                !_.isEmpty(this.location_desc) ||
                !_.isEmpty(this.location_latlng)
              ) {
                this.showAlert = false;
                if (!this.yearValidation(this.date)) {
                  return;
                }
                if (this.date) {
                  let date1 = new Date();
                  let currentYear = date1.getFullYear();
                  if (
                    this.yearText !=
                    "Year Completed or Anticipated Completion Year"
                  ) {
                    if (this.yearText == "Anticipated Completion Year") {
                      if (this.date < currentYear) {
                        this.yearTextErr =
                          "Please enter future year as project status is " +
                          this.status +
                          ".";
                        return;
                      }
                    } else {
                      if (this.date > currentYear) {
                        this.yearTextErr =
                          "Please enter past year as project status is " +
                          this.status +
                          ".";
                        return;
                      }
                    }
                  }
                }
                if (localStorage.getItem('authtoken')) {
                  //this.showModal = false;
                  //this.d1 = false;
                  // if (_.includes(this.type, 'OTR')) {
                  //   let index = _.indexOf(this.type, _.find(this.type, 'OTR'))
                  //   this.type.splice(index, 1);
                  //   this.type.push(this.otherCatType);
                  // }

                  // if (_.includes(this.subcat, 'OT')) {
                  //   let index = _.indexOf(this.subcat, _.find(this.subcat, 'OT'))
                  //   this.subcat.splice(index, 1);
                  //   this.subcat.push(this.otherCat);
                  // }
                  if (this.latlngChecked) {
                    this.latformarker = parseFloat(
                      this.location_latlng.split(",")[0]
                    );
                    this.lngformarker = parseFloat(
                      this.location_latlng.split(",")[1]
                    );
                    this.http
                      .get(
                        "https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyC3u8Ed_b8ejvMLt8O3Vs7QNkYMMKdqvgc&latlng=" +
                        this.location_latlng.split(",")[0] +
                        "," +
                        this.location_latlng.split(",")[1]
                      )
                      .toPromise()
                      .then((result) => {
                        let res = JSON.parse(result["_body"]);
                        console.log("res", res["results"]);
                        res = res["results"];
                        if (res.length == 0) {
                          //alert("Something's not quite right. Try entering the address again or re-fresh the page.");
                          this.service.sendAlert(
                            "Error",
                            "2Something's not quite right. Try entering the address again or re-fresh the page.",
                            0
                          );
                          this.location = "";
                          return;
                        }
                        let loc = "";
                        let formatted_address = res[0][
                          "formatted_address"
                        ].split(",");
                        this.cd.detectChanges();
                        let shorter_loc = "";
                        for (
                          let j = 0;
                          j < res[0].address_components.length;
                          j++
                        ) {
                          if (
                            res[0].address_components[j].types[0] == "locality"
                          ) {
                            // formatted_address[formatted_address.length - 3] = ' ' + res[0].address_components[j].long_name;
                            shorter_loc =
                              res[0].address_components[j].long_name + ", ";
                          }
                          if (
                            res[0].address_components[j].types[0] ==
                            "administrative_area_level_1"
                          ) {
                            // formatted_address[formatted_address.length - 2] = ' ' + res[0].address_components[j].long_name;
                            shorter_loc +=
                              res[0].address_components[j].long_name + ", ";
                          }
                          if (
                            res[0].address_components[j].types[0] == "country"
                          ) {
                            // formatted_address[formatted_address.length - 1] = ' ' + res[0].address_components[j].long_name;
                            shorter_loc +=
                              res[0].address_components[j].long_name;
                          }

                          if (
                            res[0].address_components[j].types[0] == "locality"
                          ) {
                            formatted_address[formatted_address.length - 3] =
                              " " + res[0].address_components[j].short_name;
                          }
                          if (
                            res[0].address_components[j].types[0] ==
                            "administrative_area_level_1" &&
                            (formatted_address
                              .toString()
                              .indexOf("United States") >= 0 ||
                              formatted_address.toString().indexOf("USA") >= 0)
                          ) {
                            formatted_address[formatted_address.length - 2] =
                              " " + res[0].address_components[j].short_name;
                          } else if (
                            res[0].address_components[j].types[0] ==
                            "administrative_area_level_1" &&
                            (formatted_address
                              .toString()
                              .indexOf("United States") == -1 ||
                              formatted_address.toString().indexOf("USA") == -1)
                          ) {
                            formatted_address[formatted_address.length - 2] =
                              " " + res[0].address_components[j].long_name;
                          }
                          if (
                            res[0].address_components[j].types[0] ==
                            "country" &&
                            res[0].address_components[j].short_name == "US"
                          ) {
                            formatted_address[formatted_address.length - 1] =
                              " " +
                              res[0].address_components[j].short_name +
                              "A";
                          } else if (
                            res[0].address_components[j].types[0] ==
                            "country" &&
                            res[0].address_components[j].short_name != "US"
                          ) {
                            formatted_address[formatted_address.length - 1] =
                              " " + res[0].address_components[j].long_name;
                          }
                        }
                        this.shorter_location = shorter_loc;
                        this.location = formatted_address.join(",");
                        //this.location.replace(/,\s3$/, loc);
                        this.cd.detectChanges();
                        console.log("dsf", this.location);
                        console.log("dsf", this.shorter_location);
                        // if (this.authors.length > 0) {
                        //   this.authors.push({user_id: Number(localStorage.getItem('peopleid')), author_name: localStorage.getItem('first_name')+' '+localStorage.getItem('last_name'), slug: localStorage.getItem('full_name_slug')});
                        // }
                        this.filterAuthors();
                        this.ProjectDetailModel.push({
                          lat: this.latformarker ? this.latformarker : 0,
                          lng: this.lngformarker ? this.lngformarker : 0,
                          label: this.name,
                          name: this.name,
                          type: this.type,
                          subcat: this.subcat,
                          shorter_location: this.shorter_location,
                          status: this.status,
                          location: this.worldwideChecked
                            ? "Worldwide"
                            : this.location,
                          location_desc: this.location_desc,
                          date: this.date,
                          has_poly: true,
                          draggable: false,
                          project_subtype_other: this.otherCat,
                          project_type_other: this.otherCatType,
                          co_owners: this.authors
                        });

                        console.log('modal', this.ProjectDetailModel);
                        this.service
                          .doPostProject(this.ProjectDetailModel[0])
                          .then((result) => {
                            this.formDataFromServer = result;
                            this.projectbuildtext = "Upload Project Images";
                            this.project_id = this.formDataFromServer.project_name_slug;
                            this.project_id1 = this.formDataFromServer.project_id;
                            this.itext = "Upload Image Files";
                            window.history.replaceState(
                              {},
                              "",
                              "createproject/" + this.project_id
                            );
                            this.divcounter++;
                            this.divVisiblity();
                            this.service.sendEvent(
                              "click",
                              "project upload step 1 success",
                              "project name: " + this.name
                            );
                            console.log("jj");
                          });
                        this.ProjectDetailModel = [];
                      })
                      .catch((ex) => {
                        console.error("Error fetching location", ex);
                      });
                  } else {
                    if (!this.placeRecognized) {
                      //alert("Something's not quite right. The address you selected is not recognized. Select an address again, enter a latitude and longitude, or pick a point on the map to continue.");
                      this.service.sendAlert(
                        "Error",
                        "Something's not quite right. The address you selected is not recognized. Select an address again, enter a latitude and longitude, or pick a point on the map to continue.",
                        0
                      );
                      return;
                    }
                    this.filterAuthors();
                    // if (this.authors.length > 0) {
                    //   this.authors.push({user_id: Number(localStorage.getItem('peopleid')), author_name: localStorage.getItem('first_name')+' ' +localStorage.getItem('last_name'), slug: localStorage.getItem('full_name_slug')});
                    // }
                    this.ProjectDetailModel.push({
                      lat: this.latformarker ? this.latformarker : 0,
                      lng: this.lngformarker ? this.lngformarker : 0,
                      label: this.name,
                      name: this.name,
                      type: this.type,
                      subcat: this.subcat,
                      shorter_location: this.shorter_location,
                      status: this.status,
                      location: this.worldwideChecked
                        ? "Worldwide"
                        : this.location,
                      location_desc: this.location_desc,
                      date: this.date,
                      draggable: false,
                      has_poly: false,
                      project_subtype_other: this.otherCat,
                      project_type_other: this.otherCatType,
                      co_owners: this.authors
                    });

                    console.log('modal', this.ProjectDetailModel);
                    this.service
                      .doPostProject(this.ProjectDetailModel[0])
                      .then((result) => {
                        this.formDataFromServer = result;
                        this.projectbuildtext = "Upload Project Images";
                        this.project_id = this.formDataFromServer.project_name_slug;
                        this.project_id1 = this.formDataFromServer.project_id;
                        // for (let i = 0; i < this.service.projectNames.length; i++) {
                        //   if (this.service.projectNames[i].project_name == this.name) {
                        this.service.projectNames.push({
                          project_id: this.project_id,
                          project_name: this.name,
                        });
                        //   }
                        // }
                        this.itext = "Upload Image Files";
                        window.history.replaceState(
                          {},
                          "",
                          "createproject/" + this.project_id
                        );
                        this.divcounter++;
                        this.divVisiblity();
                        this.service.sendEvent(
                          "click",
                          "project upload step 1 success",
                          "project name: " + this.name
                        );
                        console.log("jj");
                      });
                  }
                } else {
                  this.showAlert = false;
                  //this.ValidationToggleValue = "1";
                  // this.showLogin = true;
                  this.service.sendAlert(
                    "Error",
                    "You need to login or join in order to add a project.",
                    0
                  );
                  // document.getElementById('loginModal').click();
                  return false;
                }
              } else {
                // this.showAlert = true;
                this.service.sendAlert(
                  "Error",
                  "Fields marked with an asterisk (*) are required.",
                  0
                );
                this.showLogin = false;
                console.log("jssj");
                return false;
              }
            }
          } else {
            //console.log("nko")
            // this.ValidationToggleValue = "2";
            // this.showModal = true;
            //document.getElementById('loginModal1').click();
            // this.showAlert = true;
            this.service.sendAlert(
              "Error",
              "Fields marked with an asterisk (*) are required.",
              0
            );
            this.showLogin = false;
            console.log("jssj");
            return false;
          }
        }
      }

      if (comment === "skip") {
        // this.divcounter + 2;
        this.divVisiblity();
      }
    }
  }

  divVisiblity() {
    if (this.divcounter === 1) {
      this.b1 = true;
      this.b2 = false;
      this.b3 = false;
      this.b4 = false;
      this.b5 = false;

      this.d1 = true;
      this.d2 = false;
      this.d3 = false;
      this.d4 = false;
      this.d5 = false;
    }
    if (this.divcounter === 2) {
      this.b1 = true;
      this.b2 = true;
      this.b3 = false;
      this.b4 = false;
      this.b5 = false;

      this.d1 = false;
      setTimeout(() => {
        this.d2 = true;
      }, 500);
      this.d3 = false;
      this.d4 = false;
      this.d5 = false;
    }
    if (this.divcounter === 3) {
      this.b1 = true;
      this.b2 = true;
      this.b3 = true;
      this.b4 = false;
      this.b5 = false;

      this.d1 = false;
      this.d2 = false;
      this.d3 = true;
      this.d4 = false;
      this.d5 = false;
    }
    if (this.divcounter === 4) {
      this.b1 = true;
      this.b2 = true;
      this.b3 = true;
      this.b4 = true;
      this.b5 = false;

      this.d1 = false;
      this.d2 = false;
      this.d3 = false;
      this.d4 = true;
      this.d5 = false;
    }
    if (this.divcounter === 5) {
      this.b1 = true;
      this.b2 = true;
      this.b3 = true;
      this.b4 = true;
      this.b5 = true;

      this.d1 = false;
      this.d2 = false;
      this.d3 = false;
      this.d4 = false;
      this.d5 = true;
    }
  }

  goAhead() {
    this.router.navigate(["/project", this.project_id]);
  }

  public uploader: FileUploader = new FileUploader({
    allowedMimeType: ["image/png", "image/jpeg", "image/gif"],

    maxFileSize: 5242880,
  });

  dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    //Old Code
    //write the ArrayBuffer to a blob, and you're done
    //var bb = new BlobBuilder();
    //bb.append(ab);
    //return bb.getBlob(mimeString);

    //New Code
    return new Blob([ab], { type: mimeString });
  }

  cropped(bounds: Bounds) {
    this.croppedHeight = bounds.bottom - bounds.top;
    this.croppedWidth = bounds.right - bounds.left;
  }

  dontCrop() { }

  onCancel() {
    this.Onimage = false;
    this.uploader.queue.splice(this.uploader.queue.length - 1, 1);
  }

  fileChangeListener1($event) {
    console.log($event, "cdk");
    var image: any = new Image();
    var file: File = $event;
    var myReader: FileReader = new FileReader();
    myReader.onload = (loadEvent: any) => {
      console.log("loaded");
      image.src = loadEvent.target.result;
      setTimeout(() => {
        this.cropper.setImage(image);
      }, 400);
    };
    console.log("loading");
    myReader.readAsDataURL(file);
  }

  public hasBaseDropZoneOver: boolean = false;
  public hasAnotherDropZoneOver: boolean = false;

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;

    if (e) {
      this.showFileError = false;
      this.BadFile = [];
      console.log(e);
      //  this.showFileError = false ;
    }
    //console.log(this.uploader.queue);
  }

  BadFile: any = [];
  showFileError: boolean = false;
  evt: any;

  pos: number = 0;

  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  blobToFile = (theBlob: Blob, fileName: string): File => {
    var b: any = theBlob;
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModifiedDate = new Date();
    b.name = fileName;

    //Cast to a File() type
    return <File>theBlob;
  };

  //**dataURL to blob**
  dataURLtoBlob(dataurl) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  Oncropped() {
    var imgcrp1 = this.dat.image;
    // console.log('laal',imgcrp1);
    // let myfile = this.dataURLtoFile(imgcrp1, 'project-image.jpg');
    let myfile1 = this.dataURLtoBlob(imgcrp1);
    let myfile = this.blobToFile(myfile1, "project-image.jpg");
    console.log(myfile);
    // return;
    this.uploader.queue[this.pos]._file = myfile;
    this.pos++;
    // console.log('ds', this.uploader.queue.length, this.pos);
    if (this.uploader.queue.length == this.pos) {
      //this.pos++;
      this.Onimage = false;
      // console.log('ds1', this.uploader.queue.length, this.pos);
    } else {
      this.fileChangeListener1(this.evt[this.pos]);
      // this.pos++;
      // console.log('ds2', this.uploader.queue.length, this.pos);
    }
  }

  Onskipped() {
    this.Onimage = false;
    this.pos++;
  }

  onFileDrop($event) {
    for (let i = 0; i < $event.length; i++) {
      if ($event[i].size > 5242880) {
        this.BadFile.push({ name: $event[i].name });
        this.showFileError = true;
        this.service.sendEvent(
          "image drop",
          "adding image to list successful",
          "project name: " + this.name
        );
        return;
      } else if ($event[i].size < 20000) {
        this.service.sendAlert("Warning", "This image size is very small and may not show clearly. Please continue or use a larger image.", 0);
      } else if (!$event[i].type) {
        this.service.sendAlert("Error", "Invalid image extension!", 0);
        return;
      }
    }
    this.Onimage = true;
    this.clicked = false;
    this.evt = $event;
    this.fileChangeListener1($event[0]);
    //this.pos = 1;
    for (
      let i = this.uploader.queue.length - 1;
      i < this.uploader.queue.length;
      i++
    ) {
      this.uploader.queue[i]["license"] = "";
      this.uploader.queue[i]["btn_permission"] = "Upload written approval";
      this.uploader.queue[i]["isPermissionAdded"] = false;
      this.uploader.queue[i]["owner_settings"] = "";
      this.uploader.queue[i]["isCCSelected"] = false;
      // this.uploader

      // this.uploader.queue[i].alias="";
      if (this.uploader.queue[i].alias === "file") {
        this.uploader.queue[i].alias = "";
      }
    }

    this.service.sendEvent(
      "image drop",
      "adding image to list failed",
      "project name: " + this.name
    );
    //console.log('canvas',document.getElementsByTagName('canvas'));
  }
  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }

  onUpload() {
    this.service.sendEvent(
      "click",
      "upload images started",
      "project name: " + this.name
    );
    //document.getElementById('uploadBtn').innerHTML = 'Uploading...';
    // console.log(this.uploader.queue.push());
    // let btnUpload = document.getElementById('btn1');
    // btnUpload.classList.remove("glyphicon-upload");
    // btnUpload.classList.add("glyphicon-refresh");
    // btnUpload.classList.add("glyphicon-refresh-animate");
    this.fileUploading = true;
    this.cd.detectChanges();
    this.clicked = true;
    this.cd.detectChanges();
    let i;
    for (i = 0; i < this.uploader.queue.length; i++) {
      // console.log(this.uploader.queue[i]._file.name);
      if (!this.uploader.queue[i].isUploaded) {
        if (!this.uploader.queue[i]["license"]) {
          this.service.sendAlert(
            "Error",
            "You must select a photo license.",
            0
          );
          this.cd.detectChanges();
          this.clicked = false;
          this.cd.detectChanges();
          return;
        } else {
          if (this.uploader.queue[i]["license"] == "Unknown or Other") {
            this.service.sendAlert(
              "Error",
              "Only images you have taken, are explicitly indicated as under Creative Commons (CCO) or Public Domain, or you have written approval can be used.",
              0
            );
            this.cd.detectChanges();
            this.clicked = false;
            this.cd.detectChanges();
            return;
          } else if (this.uploader.queue[i]["license"] == "own_work") {
            if (
              !this.uploader.queue[i]["owner_settings"] ||
              !this.uploader.queue[i]["photo_credit"]
            ) {
              this.service.sendAlert(
                "Error",
                "Fields marked with an asterisk (*) are required.",
                0
              );
              this.cd.detectChanges();
              this.clicked = false;
              this.cd.detectChanges();
              return;
            }
          } else if (this.uploader.queue[i]["license"].indexOf("Waiver") == -1 && this.uploader.queue[i]["license"] != 'Taken by someone else who’s given written approval to use') {
            if (
              !this.uploader.queue[i]["photo_link"] ||
              !this.uploader.queue[i]["photo_credit"]
            ) {
              this.service.sendAlert(
                "Error",
                "Fields marked with an asterisk (*) are required.",
                0
              );
              this.cd.detectChanges();
              this.clicked = false;
              this.cd.detectChanges();
              return;
            }
          } else if (
            this.uploader.queue[i]["license"].indexOf("Waiver") != -1
          ) {
            if (!this.uploader.queue[i]["photo_link"]) {
              this.service.sendAlert(
                "Error",
                "Fields marked with an asterisk (*) are required.",
                0
              );
              this.cd.detectChanges();
              this.clicked = false;
              this.cd.detectChanges();
              return;
            }
          } else if (this.uploader.queue[i]["license"] == 'Taken by someone else who’s given written approval to use') {
            if (!this.uploader.queue[i]["photo_credit"] || !this.uploader.queue[i]["photo_link"] || !this.uploader.queue[i].isPermissionAdded) {
              this.service.sendAlert(
                "Error",
                "Fields marked with an asterisk (*) are required.",
                0
              );
              this.cd.detectChanges();
              this.clicked = false;
              this.cd.detectChanges();
              return;
            }
          }
        }
        this.uploader.queue[i].isUploading = true;
        //console.log("inside if", this.uploader.queue[i].description);
        this.onChange(
          this.uploader.queue[i]._file,
          i,
          this.uploader.queue[i]["photo_name"],
          this.uploader.queue[i]["photo_link"],
          "",
          this.uploader.queue[i]["author_page_link"],
          this.uploader.queue[i]["license"],
          this.uploader.queue[i]["owner_settings"],
          this.uploader.queue[i]["photo_credit"],
          this.uploader.queue[i]["description"],
          this.uploader.queue[i]["date_taken"]
        );
      }
    }
    console.log(i);
    if (i == this.uploader.queue.length) {
      // btnUpload.classList.add("glyphicon-upload");
      // btnUpload.classList.remove("glyphicon-refresh");
      // btnUpload.classList.remove("glyphicon-refresh-animate");
      this.nextBtnShow = true;
      this.clicked = false;
    }
    //document.getElementById('uploadBtn').innerHTML = 'Upload';
  }

  dropHandler(evt) {
    evt.preventDefault();
    console.log(event['dataTransfer']['files'][0]);
    this.uploader.queue[this.perm_index].permission_file = event['dataTransfer']['files'][0];
    // this.uploader.queue[this.perm_index].permission_filename = event['dataTransfer']['files'][0]['name'];
    this.permission_file_name = event['dataTransfer']['files'][0]['name'];
  }

  dragOverHandler(ev) {
    console.log('File(s) in drop zone');

    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();
  }

  permissionClicked(index) {
    if (this.uploader.queue[index].btn_permission == 'Written approval added') {
      this.service.sendAlert('Alert', 'Written approval added but not uploaded yet. Please click upload to view written approval.', 1);
      return;
    }
    this.perm_index = index;
    if (!this.uploader.queue[this.perm_index].isPermissionUploaded) {
      jQuery('#permissionModal').modal('show');
    } else {
      //VIEW PERMISSION
      window.open(this.uploader.queue[this.perm_index].permission_file_URL, '_blank', 'toolbar=no,location=no');
    }
  }

  onUploadPermission() {
    this.uploader.queue[this.perm_index].btn_permission = 'Written approval added';
    this.uploader.queue[this.perm_index].isPermissionAdded = true;
    jQuery('#permissionModal').modal('hide');
  }

  test(item: any) {
    console.log();
  }
  onUpload2($event: any) {
    let file = $event.srcElement.files;
    //  console.log(file);
    // this.onChange(file);
  }

  getLicenseURL(license) {
    if (
      license == "Creative Commons Attribution ShareAlike 4.0 (CC BY-SA 4.0)"
    ) {
      return "https://creativecommons.org/licenses/by-sa/4.0/";
    }
    if (
      license == "Creative Commons Attribution ShareAlike 3.0 (CC BY-SA 3.0)"
    ) {
      return "https://creativecommons.org/licenses/by-sa/3.0/";
    }
    if (
      license == "Creative Commons Attribution ShareAlike 2.5 (CC BY-SA 2.5)"
    ) {
      return "https://creativecommons.org/licenses/by-sa/2.5/";
    }
    if (
      license == "Creative Commons Attribution ShareAlike 2.0 (CC BY-SA 2.0)"
    ) {
      return "https://creativecommons.org/licenses/by-sa/2.0/";
    }
    if (license == "Creative Commons Attribution 4.0 (CC BY 4.0)") {
      return "https://creativecommons.org/licenses/by/4.0/";
    }
    if (license == "Creative Commons Attribution 3.0 (CC BY 3.0)") {
      return "https://creativecommons.org/licenses/by/3.0/";
    }
    if (license == "Creative Commons Attribution 2.5 (CC BY 2.5)") {
      return "https://creativecommons.org/licenses/by/2.5/";
    }
    if (license == "Creative Commons Attribution 2.0 (CC BY 2.0)") {
      return "https://creativecommons.org/licenses/by/2.0/";
    }
    if (
      license ==
      "Creative Commons CC0 Waiver (release all rights, like public domain)"
    ) {
      return "https://creativecommons.org/publicdomain/zero/1.0/";
    }
    if (license == "Creative Commons Public Domain Dedication (CC0)") {
      return "https://creativecommons.org/publicdomain/zero/1.0/";
    }
    if (license == "Creative Commons Attribution-ShareAlike (CC BY-SA 4.0)") {
      return "https://creativecommons.org/licenses/by-sa/4.0/";
    }
    if (license == "Creative Commons Attribution-NoDerivs (CC BY-ND 4.0)") {
      return "https://creativecommons.org/licenses/by-nd/4.0/";
    }
    if (
      license == "Creative Commons Attribution-NonCommercial (CC BY-NC 4.0)"
    ) {
      return "https://creativecommons.org/licenses/by-nc/4.0/";
    }
    if (
      license ==
      "Creative Commons Attribution-NonCommercial-ShareAlike (CC BY-NC-SA 4.0)"
    ) {
      return "https://creativecommons.org/licenses/by-nc-sa/4.0/";
    }
    if (
      license ==
      "Creative Commons Attribution-NonCommercial-NoDerivs (CC BY-NC-ND 4.0)"
    ) {
      return "https://creativecommons.org/licenses/by-nc-nd/4.0/";
    }
  }

  onChange(
    file: any,
    index: any,
    photo_name: any,
    photo_link: any,
    author_name: any,
    author_page_link: any,
    license: any,
    owner_settings: any,
    photo_credit: any,
    description: any,
    date_taken: any
  ) {
    let postData = {
      image_name: photo_name ? photo_name : "",
      publish: "",
      image_url: photo_link ? photo_link : "",
      tags: ["test"],
      author_name: author_name ? author_name : "",
      author_url: author_page_link ? author_page_link : "",
      license_number: license ? license : "",
      owner_settings: owner_settings ? owner_settings : "",
      caption: photo_credit ? photo_credit : "",
      source: description ? description : "",
      project: this.project_id1,
      date_taken: date_taken ? date_taken : "",
    }; // Put your form data variable. This is only example.

    if (license == 'Taken by someone else who’s given written approval to use') {
      postData['permission_file'] = this.uploader.queue[index].permission_file;
    }

    if (license) {
      if (license == "own_work") {
        if (owner_settings) {
          let l = this.getLicenseURL(owner_settings);
          if (l) {
            postData["license_url"] = l;
          }
        }
      } else {
        if (license != 'Taken by someone else who’s given written approval to use') {
          let l = this.getLicenseURL(license);
          postData["license_url"] = l;
        }
      }
      console.log("this", postData);
    }

    console.log("postdata", postData);
    this.service.postWithFile(postData, file).then((result) => {
      this.uploader.queue[index].isSuccess = true;
      this.uploader.queue[index].isUploading = false;
      this.uploader.queue[index].isUploaded = true;
      if (license == 'Taken by someone else who’s given written approval to use') {
        this.uploader.queue[index].permission_file_URL = result['permission_file'];
        this.uploader.queue[index].btn_permission = 'View written approval';
        this.uploader.queue[index].isPermissionUploaded = true;
        this.permission_file_name = '';
      }
      //this.uploader.queue[index].progress;
      this.service.sendEvent(
        "click",
        "uploaded images successfully",
        "project name: " + this.name
      );

      // this.imageData = result;
      // this.displaylistofimages = true;
      // this.imageDataFromServer.push({
      //   caption: this.imageData.caption,
      //   tags: this.imageData.tags[0],
      //   url: this.imageData.image,
      //   createdby: this.imageData.created_by
      // });
    });
  }

  yearValidation(year) {
    if (year != "") {
      const text = /^[0-9]+$/;
      if (year.toString().length != 4) {
        this.yearTextErr = "Year should be 4 digit only.";
        return false;
      }
      if (!text.test(year)) {
        this.yearTextErr = "Please enter numeric values only.";
        return false;
      }
      return true;
    } else {
      return true;
    }
  }

  worldwideChanged() {
    this.showMap = true;
    this.worldwideChecked = !this.worldwideChecked;
    this.latlngChecked = false;
    if (this.worldwideChecked) {
      this.noNeedForLocation = true;
      if (!this.locationToggle) this.locationToggle = this.location;
      this.location = "Worldwide";
      let myLatLng = { lat: 0, lng: 0 };
      this.map = null;
      setTimeout(() => {
        this.map = new google.maps.Map(document.getElementById("map"), {
          center: myLatLng,
          zoom: 1,
          zoomControl: true,
          mapTypeControl: false,
          streetViewControl: false,
          fullscreenControl: false,
          zoomControlOptions: {
            position: google.maps.ControlPosition.RIGHT_BOTTOM,
            style: google.maps.ZoomControlStyle.LARGE,
          },
        });
      }, 1000);
    } else {
      this.noNeedForLocation = false;
      this.location = this.locationToggle;
      let myLatLng = { lat: this.lat, lng: this.lng };
      this.map = null;
      setTimeout(() => {
        this.map = new google.maps.Map(document.getElementById("map"), {
          center: myLatLng,
          zoom: this.zoom,
          zoomControl: true,
          mapTypeControl: false,
          streetViewControl: false,
          fullscreenControl: false,
          zoomControlOptions: {
            position: google.maps.ControlPosition.RIGHT_BOTTOM,
            style: google.maps.ZoomControlStyle.LARGE,
          },
        });
        // this.marker = new google.maps.Marker({
        //   position: myLatLng,
        //   draggable: true,
        //   map: this.map,
        // });

        var input = document.getElementById("autocompleteinput");
        var autocomplete = new google.maps.places.Autocomplete(input);

        // Bind the map's bounds (viewport) property to the autocomplete object,
        // so that the autocomplete requests use the current map bounds for the
        // bounds option in the request.
        autocomplete.bindTo("bounds", this.map);

        // var marker = new google.maps.Marker({
        //   map: this.map,
        //   anchorPoint: new google.maps.Point(0, -29)
        // });
        // this.marker = new google.maps.Marker({
        //   draggable: true,
        //   map: this.map
        // });

        let componentForm = {
          locality: "long_name",
          administrative_area_level_1: "long_name",
          country: "long_name",
        };

        autocomplete.addListener("place_changed", () => {
          this.placeRecognized = true;
          this.place = autocomplete.getPlace();
          let place = autocomplete.getPlace();
          this.location = document.getElementById("autocompleteinput")["value"];
          var val = "";
          for (var i = 0; i < place.address_components.length; i++) {
            var addressType = place.address_components[i].types[0];
            if (componentForm[addressType]) {
              if (addressType == "country") {
                val += place.address_components[i][componentForm[addressType]];
              } else {
                val +=
                  place.address_components[i][componentForm[addressType]] + ", ";
              }
            }
          }
          this.shorter_location = val;
          this.service.checkForSameAddress(this.location).then((res) => {
            if (res["status"] == "Location already exists") {
              //alert('This address is already being used on another project.');
              // this.service.sendAlert('Error', 'This address is already being used on another project.', 0);
            }
          });
          if (!place.geometry) {
            // User entered the name of a Place that was not suggested and
            // pressed the Enter key, or the Place Details request failed.
            //window.alert("Error in map data, try with different location or try again later.");
            this.service.sendAlert(
              "Error",
              "Error in map data, try with different words or try again later.",
              0
            );
            return;
          }

          // If the place has a geometry, then present it on a map.
          if (place.geometry.viewport) {
            this.map.fitBounds(place.geometry.viewport);
          } else {
            this.map.setCenter(place.geometry.location);
            this.map.setZoom(17); // Why 17? Because it looks good.
          }
          // this.marker.setVisible(true);
          // this.marker.setPosition(place.geometry.location);

          // console.log('ss',place.geometry.location);
          this.latformarker = place.geometry.location.lat();
          this.lngformarker = place.geometry.location.lng();
          // this.placeMarker(place.geometry.location, this.map);
          if (this.count > 0) {
            this.marker.setMap(null);
          }
          this.count++;
          // this.latformarker = place.geometry.location.lat();
          // this.lngformarker = place.geometry.location.lng();
          this.marker = new google.maps.Marker({
            position: place.geometry.location,
            draggable: true,
            map: this.map,
          });
          // }
          this.marker.addListener("dragend", (e) => {
            this.putMapLocationToTextbox(e.latLng.lat(), e.latLng.lng());
          });
          this.map.addListener("click", (e) => {
            this.placeMarker(e.latLng, this.map);
          });
        });
        this.map.addListener("click", (e) => {
          this.placeMarker(e.latLng, this.map);
        });
      }, 1000);
    }
  }

  changedLatlng() {
    // this.locationToggle = this.location;
    this.latlngChecked = !this.latlngChecked;
    this.worldwideChecked = false;
    // console.log(this.latlngChecked, this.worldwideChecked);
  }

  //project type multiple dropdown
  private projectTypeOptions: IMultiSelectOption[] = [
    { id: "ENV", name: "Water/Environmental" },
    { id: "TRN", name: "Transportation" },
    { id: "STR", name: "Building/Structural" },
    { id: "SFT", name: "Software" },
    { id: "PRO", name: "Product" },
    { id: "OTR", name: "Other" },
  ];

  private projectSubtypeOptions: IMultiSelectOption[] = [
    { id: "OT", name: "Other" },
  ];

  private type1: IMultiSelectOption[] = [
    { id: "FS", name: "Flood Study" },
    { id: "SR", name: "Stream Restoration" },
    { id: "SM", name: "Stormwater Management" },
    { id: "ES", name: "Erosion & Sediment Control" },
    { id: "WS", name: "Water Supply" },
    { id: "WR", name: "Water Reuse" },
    { id: "WW", name: "Wastewater" },
  ];

  private type2: IMultiSelectOption[] = [
    { id: "HP", name: "Hospitality" },
    { id: "RS", name: "Residential" },
    { id: "MU", name: "Mixed-Use" },
    { id: "CM", name: "Commercial" },
    { id: "OS", name: "Office Space" },
  ];

  private type3: IMultiSelectOption[] = [
    { id: "RW", name: "Roadway" },
    { id: "SW", name: "Subway" },
    { id: "BD", name: "Bridge" },
    { id: "PW", name: "Pedestrian Walkway" },
    { id: "BP", name: "Bike Path" },
  ];
  selectedOptionss: number[];
  hasOther: boolean;
  hasOtherType: boolean;
  noNeedForLocation: boolean = false;
  showWorldwide: boolean = false;
  onChangedd($event, ddId) {
    this.hasOther = false;

    if (ddId == 1) {
      //console.log($event)
      if (_.isEmpty($event)) {
        document
          .getElementsByClassName("dropdown-toggle")
        [this.multiddindex].setAttribute("disabled", "disabled");
      } else {
        // if ($event.length == 3) {
        //   if (_.includes($event, 'SFT') && _.includes($event, 'PRO') && _.includes($event, 'OTR')) {
        //     document.getElementsByClassName("dropdown-toggle")[this.multiddindex].setAttribute("disabled", "disabled");
        //   } else {
        document
          .getElementsByClassName("dropdown-toggle")
        [this.multiddindex].removeAttribute("disabled");
        //   }
        // }
      }

      this.type = $event;
      this.projectSubtypeOptions = [];
      this.selectedOptionss = [];
      this.noNeedForLocation = false;
      this.showWorldwide = false;
      this.hasOtherType = false;
      _.forEach($event, (value) => {
        if (value == "ENV") {
          this.projectSubtypeOptions = _.concat(
            this.projectSubtypeOptions,
            this.type1
          );
        }

        if (value == "STR") {
          this.projectSubtypeOptions = _.concat(
            this.projectSubtypeOptions,
            this.type2
          );
        }

        if (value == "TRN") {
          this.projectSubtypeOptions = _.concat(
            this.projectSubtypeOptions,
            this.type3
          );
        }

        if (value == "SFT" || value == "PRO") {
          this.noNeedForLocation = true;
          this.showWorldwide = true;
        }

        if (value == "OTR") {
          this.noNeedForLocation = true;
          this.hasOtherType = true;
        }
      });
      this.projectSubtypeOptions.push({ id: "OT", name: "Other" });
    }

    if (ddId == 2) {
      this.subcat = $event;
      _.forEach($event, (value) => {
        if (value == "OT") {
          this.hasOther = true;
        }
      });
      console.log($event);
    }
  }
}

interface datex {
  month: string;
  year: string;
}
interface marker {
  lat: string;
  lng: string;
  label: string;
  name: string;
  type: string;
  subcat: string;
  status: string;
  draggable: boolean;
}

interface ProjectDetailModel {
  lat: string;
  lng: string;
  label: string;
  name: string;
  type: string;
  subcat: string;
  status: string;
  shorter_location: string;
  has_poly: boolean;
  location: string;
  location_desc: string;
  date: any;
  draggable: boolean;
  project_type_other: string;
  project_subtype_other: string;
  co_owners: any[];
}

interface Owners {
  user_id: number;
  author_name: string;
  slug: string;
}

// lat: any = 39.43;
//   lng: any = -3.25;
//   increment: number = 0;
//   lngt: any;
//   showSection: boolean = false;
//   zoom: number = 8;
//   name: any;
//   type: any;
//   status: any;
//   subtype: any;
//   dummydata: any;
//   togglesection: any = true;
//   location: any = "";

//     // google maps zoom level

//   markers: marker[] = [];

//   markers1: marker[] = [];
//   markers2: marker[] = [];

//   // initial center position for the map

//   clickedMarker(label: string, index: number) {
//     this.showSection = true;
//     console.log(`clicked the marker: ${label || index}`);
//   }

//   removeMarker(label: string, index: number) {
//     this.showSection = false;

//     if (this.togglesection) {
//       this.markers1.splice(index, 1);
//       this.markers2.splice(index, 1);

//       this.markers.splice(index, 1);

//     } else {
//       console.log(`clicked the marker: ${index}`);
//       this.markers.splice(index, 1);
//       this.markers2 = this.markers;

//     }

//     // this.markers2.splice(index, 1);
//   }

//   selectMarker(m: marker) {
//     this.lat = m.lat;
//     this.lng = m.lng;
//     this.zoom = 12;
//   }

//   toggleView(label: string) {
//     // console.log(label);
//     if (label === "showproject") {
//       console.log(label);
//       this.togglesection = true;
//       this.markers = [];
//       this.markers1 = [];
//     } else {

//       this.togglesection = false;

//       this.markers = this.markers2;

//     }
//   }

//   submitEntry() {
//     this.markers1 = [];
//     this.markers = [];

//     this.name = "";
//     this.type = "";
//     this.subtype = "";
//     this.status = "";
//     this.location = "";

//   }

//   mapClicked1($event: MouseEvent) {
//     this.showSection = true;
//     console.log($event);
//   }

//   markerDragEnd(m: marker, $event: MouseEvent) {
//     console.log('dragEnd', m, $event);
//     // this.latt = $event.coords.lat;
//     // this.lngt = $event.coords.lng;
//   }
