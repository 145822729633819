<div style="float: left; width: 100%;">
    <!-- <navbar></navbar> -->
    <div class="container-fluid">
        <div class="row" style="height: 100%;">
            <div class="banner-image first-div" style="height: 100%;">
                <div class="first-div-child div1 clip-svg-inline" style="float: left; display: flex; width: 45%; z-index: 1; clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%); margin: 6% 0;">
                    <div class="col-md-2 text-center" style="display: flex; align-items: center; padding: 0;">
                        <div class="row right-arrow"></div>
                    </div>
                    <div class="col-md-10 top-section-text" style="display: flex; align-items: center; padding: 0; padding-left: 3%;">
                        <div>
                            <h1 class="textLogo">STEM Lesson Plans</h1>
                            <div>
                                <p class="top-second-line">
                                    Use the below RePicture lesson plans, which are designed to introduce college and high school students to the world of science, technology, engineering and math (STEM) careers. They help students discover engineering and science projects shaping the future.
                                    They also let ALL students know they belong in STEM.
                                </p>
                            </div>
                            <div class="btns">
                                <div>
                                    <button class="btn btn-default learn-more-second learn-more-second2"><a routerLink="/lessonplans/TeacherGuide" target="_blank">Teacher Guide for Adding Projects</a></button>
                                </div>
                                <div>
                                    <button class="btn btn-default learn-more-second learn-more-second2"><a routerLink="/lessonplans/StudentGuide">Student Guide for Adding Projects</a></button>
                                </div>
                            </div>
                            <!-- <h1 class="textLogo">Discover Careers Shaping the Future<sup style="font-size: 24px;">TM</sup></h1> -->
                            <!-- <div>
                                <div class="text-topic">
                                    <img style="width:45px;" src="/assets/images/homepagenew/Icon-Projects-500px.jpg" />&nbsp;
                                    <a class="topic-link" [routerLink]="['/explorer/projects']" routerLinkActive="router-link-active">Projects</a>
                                </div>
                                <div class="text-topic">
                                    <img style="width:45px;" src="/assets/images/homepagenew/Icon-Professionals-500px.jpg" />&nbsp;
                                    <a class="topic-link" [routerLink]="['/explorer/people']" routerLinkActive="router-link-active">Professionals</a>
                                </div>
                                <div class="text-topic">
                                    <img style="width:45px;" src="/assets/images/homepagenew/Icon-Companies-500px.jpg" />&nbsp;
                                    <a class="topic-link" [routerLink]="['/explorer/org']" routerLinkActive="router-link-active">Companies and Organizations</a>
                                </div>
                                <div class="text-topic">
                                    <img style="width:45px;" src="/assets/images/homepagenew/Icon-Jobs-500px.jpg" />&nbsp;
                                    <a class="topic-link" [routerLink]="['/jobs']" routerLinkActive="router-link-active">Jobs and Careers</a>
                                </div>
                                <div class="text-topic">
                                    <img style="width:45px;" src="/assets/images/homepagenew/Icon-GroupChats-500px.jpg" />&nbsp;
                                    <a class="topic-link" [routerLink]="['/lessonplans']" routerLinkActive="router-link-active">Lesson Plans</a>
                                </div>
                            </div>
                            <p style="color: gray; font-family: OpenSans-Regular; font-size: 16px; padding-top: 15px;">Looking for a job? Find
                                <a routerLink="/jobs" style="font-family: OpenSans-SemiBold;">STEM job postings here</a>
                            </p> -->
                        </div>
                    </div>
                </div>
                <div class="first-div-child div2 clip-svg-inline1" style="float: right; width: 55%; overflow: hidden; clip-path: polygon(20% 0, 100% 0, 100% 100%, 0 100%);">
                    <!-- <carousel noPause="true">
                        <slide class="carousel" *ngFor="let slide of service.featured; let index=index"> -->
                    <div class="image">
                        <div class="foreground-text">
                            <h2>Benefits of using RePicture.com in your class:</h2><br>
                            <div>
                                <div class="text-topic">
                                    <img src="/assets/images/lesson/Icon-Technology-500px.png" />
                                    <p class="topic-link">Leverages technology students use every day to enhance the classroom experience.</p>
                                </div>
                                <div class="text-topic">
                                    <img src="/assets/images/lesson/Icon-DiscoverSTEM-500px.png" />
                                    <p class="topic-link">Helps students discover STEM in the world all around them.</p>
                                </div>
                                <div class="text-topic">
                                    <img src="/assets/images/lesson/Icon-Published-500px.png" />
                                    <p class="topic-link">Students' work can be published on RePicture and shared with others worldwide.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="oneImageCaption">
                                <p class="fp2">Featured Project:</p>
                                <p>{{slide.project_name}}</p>
                            </div>
                        </slide>
                    </carousel> -->
                </div>
            </div>
        </div>
    </div>
</div>
<div class="exploreParent">
    <div>
        <img src="assets/images/homepagenew/line.jpg" style="width: 100%; float: left; height: 10px;" />
    </div>
    <h4 class="text-center second-top-line">HELP STUDENTS DISCOVER ENGINEERING AND SCIENCE PROJECTS ALL AROUND THEM</h4>
    <p class="browse">Lesson Plans</p>
    <div class="boxesParent col-sm-12">
            <div class="boxes col-md-4">
                    <div style="margin-bottom: 20px;">
                        <img src="/assets/images/lesson/AISC Lesson Plan Photo.png" style="max-height: 100%; max-width: 100%;" />
                    </div>
                    <div class="exploreNames">AISC &amp; Modern Steel Construction</div>
                    <p>(Thanks to American Institute of Steel Construction)</p>
                    <div class="exploreNames1">Improves students’ writing skills as they learn about steel construction projects and can publish their work in a database of engineering projects available to a worldwide audience.
                        <app-read-more>
                            <b>Best for:</b> Structural engineering, architectural, and construction management classes<br />
                            <br />
                            <b>Benefit of Lesson Plan:</b> Helps students improve writing skills and relate concepts they are
                            learning to the “real world”.<br />
                            <br />
                            <b>Summary:</b> Students identify and research a structural steel project that has been profiled
                            in Modern Steel Construction and publish their work on RePicture.com<br />
                            <br />
                            <b>What RePicture Offers:</b>
                            <br /> &bull; Unlike a typical assignment, students&rsquo; work may be published and shared with others worldwide on RePicture.com. This may motivate students to provide higher quality work. <br /> &bull; Free access to a teacher “dashboard” where you can see the students’ work while it is in
                            progress. Email Edu@RePicture.com to set-up a dashboard for your class.<br /> &bull; If students are completing assignment during a RePicture Resume-Builder session, students can
                            submit their work for review by steel professionals to win a prize. See <a href="https://docs.google.com/document/d/1bTe-fgFTVz688j7oxs07-y0kwyYNvrsihTuZsWewd5U/edit?usp=sharing" target="_blank">here</a> 
                            for more information.<br />
                            <br />
                            <b>Feedback:</b><i> &ldquo;Students who were less familiar with structural steel projects have told us that the RePicture
                                    program has sparked a deep interest and opened their eyes to new design and career
                                    possibilities. RePicture’s Resume-Builder program offers students a unique opportunity to gain
                                    valuable experience researching structural steel buildings and bridges. Not only can students
                                    gain a deeper understanding of technical topics, but they can also develop marketable writing skills that will serve them well whether they go into research, consulting, or practice.&quot;real.&quot;&quot; &ndash; Christina
                                    Harber, SE, PE, AISC Director of Education</i>
                        </app-read-more>
                    </div>
                    <div class="lm">
                        <button class="learn-more-second btn btn-default"><a routerLink="/lessonplans/AISC.pdf" target="_blank">Download Educator's Guide (PDF)</a></button>
                    </div>
                </div>
                <div class="boxes col-md-4">
                        <div style="margin-bottom: 20px;">
                            <img src="/assets/images/lesson/dirt in hand.jpg" style="max-height: 100%; max-width: 100%;" />
                        </div>
                        <div class="exploreNames">Exploring Geotechnical Engineering Careers</div>
                        <!-- <p>(Thanks to Dr. Evan Variano and Wyeth Binder, University of California, Berkeley)</p> -->
                        <div class="exploreNames1">Helps students relate their classwork to actual geotechnical engineering projects, improves students’ writing skills, and provides an opportunity to build their resume.
                            <app-read-more>
                                <b>Best for:</b> Students in Geotechnical Engineering class<br />
                                <br />
                                <b>Benefit of Lesson Plan:</b> Helps students relate their classwork to actual geotechnical engineering projects, improves students’ writing skills, and provides an opportunity to build their resume.<br />
                                <br />
                                <b>Summary:</b> Students research projects done by geotechnical engineers. They then identify, research, write-up, and publish on RePicture.com a geotechnical engineering project. Students can use this assignment to build a supplement to their resume for when apply to jobs or internships.<br />
                                <br />
                                <b>What RePicture Offers:</b>
                                <br /> &bull; Unlike a typical assignment, students&rsquo; work may be published and shared with others worldwide on RePicture.com. This may motivate students to provide higher quality work. <br /> &bull; Free access to a teacher “dashboard” where you can see the students’ work while it is in
                                progress. Email Edu@RePicture.com to set-up a dashboard for your class.<br /> &bull; If students are completing assignment during a RePicture Resume-Builder session, students can
                                submit their work for review by steel professionals to win a prize. See <a href="RePicture.com/students" target="_blank" >RePicture.com/students</a>
                                for more information.<br />
                            </app-read-more>
                        </div>
                        <div class="lm">
                            <button class="learn-more-second btn btn-default"><a routerLink="/lessonplans/Geotech.pdf">Download Educator's Guide (PDF)</a></button>
                        </div>
                    </div>
        <div class="boxes col-md-4">
            <div style="margin-bottom: 20px;">
                <img src="/assets/images/lesson/bingo.jpg" style="max-height: 100%; max-width: 100%;" />
            </div>
            <div class="exploreNames">Engineering Bingo</div>
            <p>(Thanks to Dr. Evan Variano and Wyeth Binder, University of California, Berkeley)</p>
            <div class="exploreNames1">Helps students relate engineering concepts that they are learning to the &ldquo;real world.&rdquo; Best for college or high school engineering or physical science classes.
                <app-read-more>
                    <b>Best for:</b> College or high school engineering or physical science classes<br />
                    <br />
                    <b>Benefit of Lesson Plan:</b> Helps students relate engineering concepts that they are learning to the &ldquo;real world.&rdquo;<br />
                    <br />
                    <b>Summary:</b> Students take, or find, pictures of the engineering concepts they are learning in class and enter the pictures and basic information into RePicture.com.<br />
                    <br />
                    <b>What RePicture Offers:</b>
                    <br /> &bull; Unlike a typical assignment, students&rsquo; work may be published and shared with others worldwide on RePicture.com. This may motivate students to provide higher quality work. <br /> &bull; Students can use RePicture.com
                    to search for previously entered engineering elements (if it was used in a previous class). This may introduce students to different examples of engineering elements and increase their understanding.<br /> &bull; Requiring students
                    to search for and geocode the engineering elements may help them better relate the assignment to actual engineering projects.<br />
                    <br />
                    <b>Feedback:</b><i> &ldquo;RePicture made the assignment much more exciting, and caused the students to go deeper and think harder. I think it was because of the high-quality examples that they viewed on RePicture, and also the Geo-tagging. The latter effect surprised me, but I think this tagging made the assignment more &quot;real.&quot;&quot; &ndash; Dr. Evan Variano, University of California, Berkeley</i>
                </app-read-more>
            </div>
            <div class="lm">
                <button class="learn-more-second btn btn-default"><a routerLink="/lessonplans/EngineeringBingo.pdf">Download Educator's Guide (PDF)</a></button>
            </div>
        </div>
        <div class="boxes col-md-4">
            <div style="margin-bottom: 20px;">
                <img src="/assets/images/lesson/keyplayers.jpg" style="max-height: 100%; max-width: 100%;" />
            </div>
            <div class="exploreNames">Key Players of STEM</div>
            <p>(Thanks to Jason Ebner, Antioch High School, CA and AGU Centennial Grant)</p>
            <div class="exploreNames1">Helps students learn about STEM projects that excite them, while improving their research and communication skills. Best for college or high school STEM classes.
                <app-read-more>
                    <b>Best for:</b> College or high school STEM classes<br />
                    <br />
                    <b>Benefit of Lesson Plan:</b> Helps students learn about STEM projects that excite them, while improving their research and communication skills. Thanks to AGU for supporting the piloting of this lesson plan.<br />
                    <br />
                    <div class="intrinsic-container intrinsic-container-16x9">
                        <div style="width: 100%; height: 100%; position: absolute;" [innerHTML]="html"></div>
                    </div>
                    <br />
                    <b>Summary:</b> Students choose a topic of interest to them, research &amp; create a presentation about it, and enter it into RePicture.com. High quality work will be published (with the student&rsquo;s name) for the world to see!<br
                    />
                    <br />
                    <b>What RePicture Offers:</b>
                    <br /> &bull; Unlike a typical assignment, students&rsquo; work may be published and shared with others worldwide on RePicture.com. If the project write-up meets quality requirements, the work will be published and the student shown
                    as the &ldquo;page owner&rdquo;, which may motivate students to create higher quality work.<br /> &bull; Challenges students with a real-life problem that is common for STEM professionals - writing for a real audience. Plus, there
                    is no limits to the work an exceptional student can do. The assignment can be completed by students of any ability. <br /> &bull; We provide a clear rubric for reviewing the assignment. You don&rsquo;t have to be an expert in the area
                    the student is studying in order to review their work. The RePicture dashboard also allows you to easily access all students&rsquo; work and comment on it online, where only the student can see your comment. <br />
                    <br />
                    <b>Feedback:</b><i> &ldquo;This experience has been one of the best for my students. There is no other experience I could give them, in the classroom, where a grade could become meaningless if their work is not accepted for upload to the RePicture App&rdquo;&ndash; Jason Ebner, Antioch High School, California</i>
                </app-read-more>
            </div>
            <div class="lm">
                <button class="learn-more-second btn btn-default"><a routerLink="/lessonplans/KeyPlayersOfSTEM.pdf">Download Educator's Guide (PDF)</a></button>
            </div>
        </div>
        <div class="boxes col-md-4">
            <div style="margin-bottom: 20px;">
                <img src="/assets/images/lesson/findpassion.jpg" style="max-height: 100%; max-width: 100%;" />
            </div>
            <div class="exploreNames">Find Your Passion</div>
            <p>(Thanks to Dr. Charles Riley, Oregon Institute of Technology)</p>
            <div class="exploreNames1">Helps students discover different types of engineering careers and engineering projects that excite them. Best for college or high school introduction to engineering classes.
                <app-read-more>
                    <b>Best for:</b> College or high school introduction to engineering classes<br />
                    <br />
                    <b>Benefit of Lesson Plan:</b> Helps students discover different types of engineering careers and engineering projects that excite them.<br />
                    <br />
                    <b>Summary:</b> Students research their chosen major using RePicture.com and the U.S. Bureau of Labor Statistics website. Using RePicture they find an engineer and/or engineering project that resonates with them.<br />
                    <br />
                    <b>What RePicture Offers:</b>
                    <br /> &bull; Profiles of engineers that match the demographics (e.g., gender, race) of the U.S. students, so students can find an engineer that &ldquo;looks&rdquo; like them.<br /> &bull; Ability to search for engineers and projects
                    based on key words, such as &ldquo;energy&rdquo; for students interested in clean energy.<br /> &bull; Chance for student to learn about the type of engineer or scientist that works on different types of projects and how these projects
                    benefit society and/or the environment. <br />
                    <br />
                    <b>Feedback:</b><i> &ldquo;In many cases, the students have found something valuable on RePicture.com. It certainly added to the experience&#8230; RePicture.com is a truly valuable portal into the world of engineering for students who are studying AND graduating.&rdquo;&ndash; Charles Riley, Oregon Institute of Technology</i>
                </app-read-more>
            </div>
            <div class="lm">
                <button class="learn-more-second btn btn-default"><a routerLink="/lessonplans/FindYourPassion.pdf">Download Educator's Guide (PDF)</a></button>
            </div>
        </div>
        <div class="boxes col-md-4">
            <div style="margin-bottom: 20px;">
                <img src="/assets/images/lesson/usingdesignprocess.jpg" style="max-height: 100%; max-width: 100%;" />
            </div>
            <div class="exploreNames">Using Design Process To Increase Interest in Engineering</div>
            <!-- <p>(Thanks to Patricia Neary, Long Reach High School, MD)</p> -->
            <div class="exploreNames1">Helps girls understand the benefits of diversity in engineering and change their perceptions of who are engineers. Best for All-girls high school STEM class or club.
                <app-read-more>
                    <b>Best for:</b> All-girls high school STEM class or club<br />
                    <br />
                    <b>Benefit of Lesson Plan:</b> Helps girls understand the benefits of diversity in engineering and change their perceptions of who are engineers.<br />
                    <br />
                    <b>Summary:</b> Students learn about the benefits of engineering and the benefits of having diversity in design. They then use the engineering design process to brainstorm ways to increase diversity in engineering and discuss using
                    RePicture to change perceptions about engineering.<br />
                    <br />
                    <b>What RePicture Offers:</b>
                    <br /> &bull; Students learn about the engineering design process and then use the process to solve a real-life &ldquo;people-centric&rdquo; problem. Unlike a typical assignment, their solutions may actually be implemented (by RePicture).<br
                    /> &bull; Provides an interactive engineering lesson plan without the need to build a physical object.<br /> &bull; Students learn about engineering through engineers that &ldquo;look&rdquo; like them, which increases their sense of
                    belonging in engineering.<br />
                    <br />
                    <b>Feedback:</b><i> &ldquo;I never knew very much about any type of engineer before using RePicture.com but now that I have, it is something that I want to get more involved in and learn about&rdquo; and &lsquo;I would like read about more women engineers on RePicture because if I were to go into an engineering career I would want to make an educated and well informed decision and this would help me&rdquo; &ndash; two students from all-girls high school in Maryland</i>
                </app-read-more>
            </div>
            <div class="lm">
                <button class="learn-more-second btn btn-default"><a routerLink="/lessonplans/DesignProcess.pdf">Download Educator's Guide (PDF)</a></button>
            </div>
        </div>
        <div class="boxes col-md-4">
            <div style="margin-bottom: 20px;">
                <img src="/assets/images/lesson/meetanengineer.jpg" style="max-height: 100%; max-width: 100%;" />
            </div>
            <div class="exploreNames">Meet an Engineer or Scientist</div>
            <p>(Thanks to Patricia Neary, Long Reach High School, MD)</p>
            <div class="exploreNames1">Helps all students understand the breadth of work different types of engineers and scientist do. Also provides girls and other underrepresented groups role models so they see that they &ldquo;belong&rdquo; in STEM. Best for college or high
                school introduction to engineering classes or after-school STEM clubs.
                <app-read-more>
                    <b>Best for:</b> College introduction to engineering classes or high school after-school STEM clubs<br />
                    <br />
                    <b>Benefit of Lesson Plan:</b> Helps all students understand the breadth of work different types of engineers and scientist do. Also provides girls and other underrepresented groups role models so they see that they &ldquo;belong&rdquo;
                    in STEM.<br />
                    <br />
                    <b>Summary:</b> The teacher, or student, provides a short summary about one engineer or scientist found on RePicture.com at the beginning of each class or club meeting.<br />
                    <br />
                    <b>What RePicture Offers:</b>
                    <br /> &bull; Profiles of engineers that match the demographics (e.g., gender, race) of the U.S. student population, so students can find an engineer that &ldquo;looks&rdquo; like them.<br /> &bull; Ability to search for engineers
                    based on key words, such as &ldquo;chemical&rdquo; for students interested in chemical engineering.<br /> &bull; Chance for students to learn about the work STEM professionals, such as the projects they work on, what their typical
                    day is like, and what skills have been most important to their career. <br />
                    <br />
                    <b>Feedback:</b><i> &ldquo;We found the profiles with videos the most engaging&rdquo; &ndash; Patricia Neary, AP Physics Teacher and SWENext Club Leader, Long Reach High School, Columbia, Maryland</i>
                </app-read-more>
            </div>
            <div class="lm">
                <button class="learn-more-second btn btn-default"><a routerLink="/lessonplans/MeetAnEngineer.pdf">Download Educator's Guide (PDF)</a></button>
            </div>
        </div>
        <div class="boxes col-md-4">
            <div style="margin-bottom: 20px;">
                <img src="/assets/images/lesson/yourlessonplan.jpg" style="max-height: 100%; max-width: 100%;" />
            </div>
            <div class="exploreNames">Your Lesson Plan</div>
            <div class="exploreNames1">
                <p style="margin-top: 5px;">Email your lesson plan that uses RePicture.com to edu@RePicture.com and we’ll include it here.</p>
            </div>
            <!-- <div class="lm">
                <button class="learn-more-second btn btn-default">Email Lesson Plan Today</button>
            </div> -->
        </div>
    </div>
    <!-- <p class="lessonPlansLink">Are you an educator looking for innovative STEM lesson plans? <a style="font-family: OpenSans-SemiBold;" routerLink="/lessonplans" routerLinkActive="active">Find lesson plans here.</a></p> -->
</div>
<div>
    <img src="assets/images/homepagenew/line.jpg" style="width: 100%; float: left; height: 10px;" />
</div>
<svg class="clip-svg">
        <defs>
            <clipPath id="clip-polygon" clipPathUnits="objectBoundingBox" >
            <polygon points="0 0, 1 0, 1 1, 0 1" />
            </clipPath>
        </defs>
    </svg>
<svg class="clip-svg">
        <defs>
            <clipPath id="clip-polygon1" clipPathUnits="objectBoundingBox" >
            <polygon points="0.2 0, 1 0, 1 1, 0 1" />
            </clipPath>
        </defs>
    </svg>
<footer style="float: left; width: 100%;"></footer>