/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./paymentcorecomponent.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./paymentcorecomponent.component";
import * as i4 from "@angular/router";
import * as i5 from "../../projectservice";
var styles_PaymentcorecomponentComponent = [i0.styles];
var RenderType_PaymentcorecomponentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PaymentcorecomponentComponent, data: {} });
export { RenderType_PaymentcorecomponentComponent as RenderType_PaymentcorecomponentComponent };
function View_PaymentcorecomponentComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Paypal button is loading"]))], null, null); }
function View_PaymentcorecomponentComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["style", "margin: 30px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "p", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Click "])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "continue"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["PayPal Checkout"])), (_l()(), i1.ɵted(-1, null, [" to checkout as a guest or log in to confirm your purchase. "])), (_l()(), i1.ɵeld(7, 0, null, null, 5, "div", [["class", "grp-btns text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["Amount Payable: $", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PaymentcorecomponentComponent_2)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 0, "div", [["id", "paypal-checkout-btn"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.paypalLoad; _ck(_v, 11, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.finalAmount; _ck(_v, 9, 0, currVal_0); }); }
export function View_PaymentcorecomponentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "border whole-div"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "payments"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "input", [["checked", ""], ["class", "radio2"], ["name", "1"], ["type", "radio"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.pay = 2) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "span", [["class", "checkmark"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["class", "img-pay dis"], ["height", "62"], ["src", "assets/images/payments/paypal.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "span", [["class", "checkmark"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "hr", [["class", "hrs"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PaymentcorecomponentComponent_1)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.pay == 2); _ck(_v, 8, 0, currVal_0); }, null); }
export function View_PaymentcorecomponentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-paymentcorecomponent", [], null, null, null, View_PaymentcorecomponentComponent_0, RenderType_PaymentcorecomponentComponent)), i1.ɵdid(1, 9027584, null, 0, i3.PaymentcorecomponentComponent, [i4.Router, i5.ProjectService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PaymentcorecomponentComponentNgFactory = i1.ɵccf("app-paymentcorecomponent", i3.PaymentcorecomponentComponent, View_PaymentcorecomponentComponent_Host_0, { data: "data" }, {}, []);
export { PaymentcorecomponentComponentNgFactory as PaymentcorecomponentComponentNgFactory };
