<nav class="navbar navbar-default">
  <div class="container-fluid">
    <!-- Brand and toggle get grouped for better mobile display -->
    <div class="navbar-header" style="color:#5f5e5e;">
      <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1"
        aria-expanded="false">
        <span class="sr-only">Toggle navigation</span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
      <a routerLinkActive="active" routerLink="" (click)="sendEvt('logo')" class="navbar-brand">
        <img src="/assets/images/crop-logo-title.png" style="width: 51px;">
      </a>
      <a routerLinkActive="active" routerLink="" (click)="sendEvt('logo')">
        <div style="font-size: 14px; color: white; float: left; margin-top: 17px; margin-left: 30px;">
          <img src="assets/images/locked-padlock-white.png" class="lock" />
          <span style="font-family: OpenSans-SemiBold;">SECURE CHECKOUT</span>
        </div>
      </a>
    </div>
  </div>
</nav>
<div class="body" id="v-scrollable">
  <div class="container">
    <div class="border" style="display: flex; margin-bottom: 30px;">
      <div class="enhanced-title">
        Enhanced
        <br>Profile
      </div>
      <div class="enhanced-title-info">
        <p style="font-style: italic;">
          Get discovered in the engineering community and support k12 outreach
        </p>
        <p style="margin: 0;">
          100% of revenue generated from enhanced profiles will go to App improvements and the development of k12 educational materials
        </p>
      </div>
    </div>
    <!-- <br>
    <br>
    <br> -->
    <div>
      <div class="row flex-center">
        <div [ngClass]="{'disabled-box': disabled && num != 'Basic'}">
          <div class="box border">
            <div class="box1 text-center green" [ngStyle]="{'background-color': disabled && num != 'Basic' ? '#808080': '#2B9720'}">
              <p class="basic">Basic</p>
              <p class="market">Marketing & Hiring</p>
            </div>
            <div class="box2">
              <i class="glyphicon glyphicon-ok greenColor"></i>
              <div class="bd">
                <p class="txt1">
                  Access enhanced profile marketing & hiring features
                </p>
                <ul class="txt1">
                  <li class="lis">Add your logo</li>
                  <li class="lis">Show you're hiring</li>
                  <li class="lis">Access more fields
                    <ul style="padding-left: 18px;">
                      <li>Video & photos</li>
                      <li>Awards & distinction</li>
                      <li>Contributions & giving back</li>
                      <li>Mission & story</li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <button type="button" (click)="planSelected('Basic')" class="btn btn-default text-center select">Select plan</button>
          </div>
        </div>
        <div [ngClass]="{'disabled-box': disabled && num != 'Premium'}">
          <div class="box border">
            <div class="box1 text-center lightblue" [ngStyle]="{'background-color': disabled && num != 'Premium' ? '#808080': '#5DA9E9'}">
              <p class="basic">Premium</p>
              <p class="market">Business Plus</p>
            </div>
            <div class="box2">
              <i class="glyphicon glyphicon-ok greenColor"></i>
              <div class="bd">
                <p class="txt1">
                  Access enhanced profile marketing & hiring features
                </p>
              </div>
              <div style="display: flex">
                <i class="glyphicon glyphicon-plus greenColor plusIcon" ></i>
                <p class="txt1" style="margin-left: 0;">
                  We will create your enhanced profile and 3 project pages for you
                </p>
              </div>
            </div>
            <button type="button" (click)="planSelected('Premium')" class="btn btn-default text-center select">Select plan</button>
          </div>
        </div>
        <div [ngClass]="{'disabled-box': disabled && num != 'Platinum'}">
          <div class="box border">
            <div class="box1 text-center darkblue" [ngStyle]="{'background-color': disabled && num != 'Platinum' ? '#808080': '#2176AE'}">
              <p class="basic">Platinum</p>
              <p class="market">Portfolio</p>
            </div>
            <div class="box2">
              <i class="glyphicon glyphicon-ok greenColor"></i>
              <div class="bd">
                <p class="txt1">
                  Access enhanced profile marketing & hiring features
                </p>
              </div>
              <div style="display: flex">
                <i class="glyphicon glyphicon-plus greenColor plusIcon" ></i>
                <p class="txt1" style="margin-left: 0;">
                  Your profile and up to 3 projects uploaded for you
                </p>
              </div>
              <div style="display: flex">
                <i class="glyphicon glyphicon-plus greenColor plusIcon" ></i>
                <p class="txt1" style="margin-left: 0;">
                  Profile and project portfolio with up to 12 projects
                </p>
              </div>
              <ul class="txt1">
                <li class="lis">Good for proposals</li>
                <li class="lis">Share with clients</li>
              </ul>
            </div>
            <button type="button" (click)="planSelected('Platinum')" class="btn btn-default text-center select">Select plan</button>
          </div>
        </div>
      </div>
    </div>
    <div #target *ngIf="disabled" [@fadeInOut]="disabled">
      <br>
      <br>
      <br>
      <p class="heading">Take advantage of a low introductory rate</p>
      <div class="border">
        <div style="display: flex; width: 100%;    height: 150px;">
          <div class="tabs" (click)="tabClicked(1)">
            <div>
              <p class="heading text-center">Biannual</p>
              <span class="regular-text" style="text-decoration: line-through;">{{crossedRate}} bianually</span>
              <span class="regular-text" style="color: red;"> {{biannualRate}} / 6 months</span>
            </div>
            <div *ngIf="index == 1" class="tabSelection" [ngStyle]="{'background-color': index == 1 ? 'rgb(146, 194, 251)' : 'white'}"></div>
          </div>
          <div class="tabs" (click)="tabClicked(2)">
            <div>
              <p class="heading text-center">Annual</p>
              <span class="regular-text">{{annualRate}} anually (${{divide(annualRate)}} / 6 months)</span>
            </div>
            <div *ngIf="index == 2" class="tabSelection" [ngStyle]="{'background-color': index == 2 ? 'rgb(146, 194, 251)' : 'white'}"></div>
          </div>
        </div>
        <div class="price-details">
          <p class="regular-text prices">
            <span>1st month free</span>
            <span>$0.00</span>
          </p>
          <p class="regular-text prices">
            <span>{{ period }} rate</span>
            <span>{{ total }}</span>
          </p>
          <p class="regular-text prices">
            <span>Sales tax (0%)</span>
            <span>{{ tax }}</span>
          </p>
          <p class="regular-text prices">
            <span>Today's total</span>
            <span>{{ total }}</span>
          </p>
        </div>
      </div>
      <br>
      <p class="plan-info">
        Your plan begins on
        <span class="dates">{{nextDayCurrentYear}}</span> and renews automatically on
        <span class="dates">{{endDate}}</span>. To avoid charges for the next plan period, you can cancel anytime before
        <span class="dates">{{endDate}}</span>.
      </p>
      <br>
      <br>
      <br>
      <p class="heading">Select a payment method</p>
      <app-paymentcorecomponent [data]="data"></app-paymentcorecomponent>
      <br>
      <br>
      <br>
      <p class="heading">Frequently Asked Questions</p>
      <br>
      <div class="row">
        <div class="col-sm-4 faqs">
          <p>
            Can I cancel my subscription?
          </p>
          <p>
            Yes you can cancel any time by going to the "Subscription" tab on your Account page or by contacting us at billing@repictureengineering.com
          </p>
        </div>
        <div class="col-sm-4 faqs">
          <p>
            Are you a for-profit company?
          </p>
          <p>
            The RePicture App is developed by RePicture Engineering, a Public Benefit Corporation. We are mission-driven and for-profit.
            <a class="learn" target="_blank" href="assets/pdfs/RePictureOverview2018.pdf">Learn more here</a>.
          </p>
        </div>
        <div class="col-sm-4 faqs">
          <p>
            What k12 outreach do you do?
          </p>
          <p>
            We are partnering with high schools and universities to develop curriculum that integrates the RePicture App in classroom
            activities.
            <a class="learn" target="_blank" href="assets/pdfs/RePictureOverview2018.pdf">Learn more here</a>.
          </p>
        </div>
      </div>
      <br>
      <br>
      <br>
      <p class="copyright">RePicture Engineering Public Benefit Corporation &#9400; 2018 -
        <span data-toggle="modal" data-target="#myModal" class="privacy">Privacy and Terms</span>
      </p>
    </div>
  </div>
</div>
<div id="myModal" class="modal fade" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content" style="border-radius: 0px;">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
        <h4 class="modal-title">Privacy and Terms</h4>
      </div>
      <div class="modal-body" [innerHTML]="text">
      </div>
      <div class="modal-footer">
        <button class="btn btn-default" data-dismiss="modal">OK</button>
      </div>
    </div>
  </div>
</div>
