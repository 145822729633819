import {
  Component,
  NgZone,
  Input,
  AfterViewInit,
  Inject,
  PLATFORM_ID
} from '@angular/core';

import {
  trigger,
  state,
  style,
  animate,
  transition,
  keyframes
} from '@angular/animations'

import {
  Http,
  Response,
  Headers,
  URLSearchParams
}
  from '@angular/http';

import {
  ProjectService
}
  from './../projectservice';

import * as _ from 'lodash';
import * as $ from 'jquery';

import { IMultiSelectOption } from 'angular-2-dropdown-multiselect';
import { DomSanitizer } from '@angular/platform-browser';
import { CompanyProfile } from '../companyprofilecomponent/companyprofile.component';
import { ImageViewerModule } from "ngx-image-viewer";
import { isPlatformBrowser } from '@angular/common';
import { Subject } from 'rxjs/Subject';

declare var google: any;
declare var jQuery: any;

@Component({
  selector: 'companyprofiletextbox',
  templateUrl: './companyprofiletextbox.html',
  animations: [
    trigger('fadeInOut', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(700, style({ opacity: 1 }))
      ]),
      transition('* => void', [
        animate(0, style({ opacity: 0 }))
      ])
    ])
  ]

})
export class CompanyProfileTextboxComponent implements AfterViewInit {

  imageIndex: any;
  photos = [];
  photo_url: any;
  confirmObject: { 'confirmTitle': string; 'type': any; };
  @Input() mx: any;
  @Input() edit: any;
  @Input() hideToggle: any;
  @Input() fieldname: any;
  leaderName = '';
  textinput: any;
  photo: any;
  projectobj: any;
  toggleVisibitly = false;
  ShowWebsiteDiv: boolean = false;
  ShowProjectTypeDiv: boolean = false;
  EditToggle: boolean = false;
  showTexbox: boolean = false;

  ShowWebsiteDivData: boolean = false;
  ShowProjectTypeDivData: boolean = false;
  projecttypeArr: any = [];
  url: string;
  desc: any;
  printArray: any = [];
  data: any;
  data1: any = [];
  varib: boolean = false;
  fields: any;
  txt: any
  certifications: any = [];
  certiName: any = '';
  certiLoc: any;
  isClicked: boolean = true;
  certiOther: any;
  exp: any = ["0", "0 - 1", "1 - 2", "2 - 3", "3 - 4", "4 - 5", "5 - 6", "6 - 7", "7 - 8", "8 - 9", "9 - 10", "10+"];
  external_video: any;
  video: any;
  audio: any;
  org_photos = [];
  org_awards_distinctions = [];
  certidd: any = ['Disadvantaged Business Enterprise (DBE)', 'Disabled Veteran Business Enterprise (DVBE)', 'Local Business Enterprise (LBE)', 'Minority Business Enterprise (MBE)', 'Small Business Enterprise (SBE)', 'Small Disadvantaged Business (SDB)', 'Underutilized Disadvantaged Business Enterprise (UDBE)', 'Women Business Enterprise (WBE)', 'Veteran Business Enterprise (VBE)', '8(a)', 'Other'];
  location: any;
  certiLocation1: any;
  leadership_roles = [];
  searchTerm$ = new Subject<string>();
  arrayOfStrings = [];
  model1: any;
  peopleExists: boolean;
  peopleExistsId: any;
  peopleExistsName: any;
  leaderOther: any;
  peopleExistsSlug: any;

  constructor(public service: ProjectService, public _sanitizer: DomSanitizer, public obj: CompanyProfile, @Inject(PLATFORM_ID) private platform: any) { }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    if (this.mx.fieldname == 'our_leadership_team') {
      this.service.search(this.searchTerm$, 'people')
      .subscribe(results => {
        this.appendDropDown(results);
      });
    }
  }

  ngOnChanges() {
    // setTimeout(() => {
    try {
      if (!_.isEmpty(this.mx.data)) {
        //let data = this.mx.data
        this.showTexbox = true;
        console.log(this.mx.fieldname, this.mx.data);
        if (this.mx.fieldname == 'org_certification' && this.isClicked) {
          console.log('1');
          this.certifications = JSON.parse(this.mx.data);
          console.log('2', this.certifications);
          this.isClicked = false;
        }

        if (this.mx.fieldname == 'org_audio_video') {
          // console.log('lauyer',this.mx.data.org_external_video);
          this.audio = this.mx.data.org_about_audio;
          this.video = this.mx.data.org_about_video;
          console.log('vide', $("#vide"));
          $("#vide").contents().empty();
          this.external_video = this.mx.data.org_external_video ? this.convertMedia(this.mx.data.org_external_video) : '';
          if (this.mx.data.org_about_audio || this.mx.data.org_about_video || this.mx.data.org_external_video) {
            this.showTexbox = true;
          } else {
            this.showTexbox = false;
          }
        }

        if (this.mx.fieldname == 'org_photos') {
          this.org_photos = this.mx.data;
          this.photos = _.map(this.org_photos, 'org_image');
          console.log('this.photos', this.photos);
        }
        if (this.mx.fieldname == 'org_awards_distinctions') {
          this.awardsDist = JSON.parse(this.mx.data);
        }

        if (this.mx.fieldname == 'our_leadership_team') {
          this.leadership_roles = _.isEmpty(this.mx.data) ? [] : this.mx.data;
          if (this.leadership_roles.length > 0) {
            this.showTexbox = true;
          }
        }

      } else {
        this.showTexbox = false;
        if (this.mx.fieldname == 'org_audio_video') {
          const org_audio_video_obj = {
            org_about_audio: '',
            org_about_video: '',
            org_external_video: ''
          };
          this.mx.data = org_audio_video_obj;
        }
        // if (this.mx.fieldname == 'pe_inhouse') {
        //   this.mx.data = this.mx.data ? 'Yes' : 'No';
        //   this.showTexbox = true;
        // }
      }
    } catch (e) {
      console.log("e", e);
      this.showTexbox = false;
    }
    if (!this.edit) {
      this.EditToggle = false;
    }
    console.log(this.mx.fieldname, this.showTexbox, 'from companyprofiletextbox');
    // }, 1000);

  }

  removeLeader(index) {
    this.leadership_roles.splice(index, 1);
    let body = {
      leadership_roles: this.leadership_roles
    }
    let companyId = sessionStorage.getItem("companyId");
    this.service.doCompanyProfilePatch(companyId, body).then(result => {
      this.mx.data = this.leadership_roles;
    });
  }

  updateLeaders() {
    if (!this.leaderName || !this.model1) {
      return;
    }
    if (this.leaderName == 'Other') {
      this.leaderName = this.leaderOther;
    }

    if (!this.peopleExists) {
      let name = this.model1.split(/ (.+)/);
      let obj = {
        first_name: name[0],
        last_name: name[1],
        is_claimable: true,
        is_public: true
      };
      this.service.createPublicProfiles(obj).then(result => {
        this.peopleExistsId = result['id'];
        this.peopleExistsSlug = result['full_name_slug'];
        this.leadership_roles.push({'name': this.model1, 'role': this.leaderName, 'id': this.peopleExistsId, 'full_name_slug': this.peopleExistsSlug});
        this.saveLeaders();
      });
    } else {
      this.leadership_roles.push({'name': this.model1, 'role': this.leaderName, 'id': this.peopleExistsId, 'full_name_slug': this.peopleExistsSlug});
        this.saveLeaders();
    }
  }

  saveLeaders() {
    let body = {
      leadership_roles: this.leadership_roles
    }
    let companyId = sessionStorage.getItem("companyId");
    this.service.doCompanyProfilePatch(companyId, body).then(result => {
      this.leaderName = '';
      this.model1 = '';
      this.showTexbox = true;
      this.mx.data = this.leadership_roles;
      this.peopleExists = false;
      this.peopleExistsId = '';
      this.peopleExistsName = '';
      this.peopleExistsSlug = '';
    });
  }

  myCallback($event: any, flag) {
    console.log($event);
    if (_.isObject($event)) {
        this.model1 = $event['fullname'];
        this.peopleExists = true;
        this.peopleExistsId = $event['id'];
        this.peopleExistsName = $event['fullname'];
        this.peopleExistsSlug = $event['full_name_slug'];
        this.arrayOfStrings = [];
    }
  }

  appendDropDown(data) {
      this.arrayOfStrings = [];
      _.forEach(data, (value) => {
        let obj = {
          fullname: value.first_name + " " + value.last_name,
          // email: value.email,
          profile_image: value.profileImage,
          id: value.id,
          full_name_slug: value.full_name_slug
        }
        this.arrayOfStrings.push(obj);
      });

    }

  deleteOrgPhoto(index, id) {
    this.service.deleteOrgPhoto(id).then(res => {
      this.org_photos.splice(index, 1);
    });
  }

  openModal(img_url, i) {
    this.photo_url = img_url;
    this.imageIndex = i;
    console.log(this.photo_url);
    // jQuery('#myModal').modal('show');
  }

  deleteMedia(type) {
    this.confirmObject = {
      'confirmTitle': 'Do you want to delete this file?',
      'type': type
    };
  }

  confirmedAction($event) {
    let body = {
      [$event['type']]: null
    };
    let company_id = sessionStorage.getItem("companyId");
    this.service.doCompanyProfilePatch(company_id, body).then(result => {
      if ($event['type'] == 'org_about_video') {
        this.video = null;
      } else if ($event['type'] == 'org_external_video') {
        this.external_video = null;
        this.mx.data.org_external_video = null;
      } else {
        this.audio = null;
      }
    });
  }

  mediaSelected1($event) {
    this.evt = $event;
  }

  mediaSelected($event) {
    let FileSize = $event.target.files[0].size / 1024 / 1024; // in MB
    if (FileSize > 10) {
      this.service.sendAlert('Error', 'File size exceeds 10 MB', 0);
      return;
    }
    let btnUpload = document.getElementById('btn');
    btnUpload.classList.remove("glyphicon-upload");
    btnUpload.classList.add("glyphicon-refresh");
    btnUpload.classList.add("glyphicon-refresh-animate");
    console.log($event);
    let elem = $event.target.files[0];
    let type = $event.target.files[0].type.split('/')[0];
    this.service.setUserAudioVideoForCompany(sessionStorage.getItem("companyId"), elem).then(res => {
      //location.reload();
      if (type == 'audio') {
        this.audio = res['org_about_audio'];
      } else if (type == 'video') {
        this.video = res['org_about_video'];
      }
      this.evt = '';
      btnUpload.classList.add("glyphicon-upload");
      btnUpload.classList.remove("glyphicon-refresh");
      btnUpload.classList.remove("glyphicon-refresh-animate");
    });
  }

  externalMediaSelected() {
    let btnUpload = document.getElementById('btn1');
    btnUpload.classList.remove("glyphicon-upload");
    btnUpload.classList.add("glyphicon-refresh");
    btnUpload.classList.add("glyphicon-refresh-animate");
    // console.log($event);
    // let elem = $event.target.files[0];
    // let type = $event.target.files[0].type.split('/')[0];
    // let video = ''
    let obj;
    // if (this.videoURL.indexOf('youtube') >= 0) {
    obj = {
      'org_external_video': this.videoURL//.replace("watch?v=", "embed/")
    };
    // 	video = this.videoURL.replace("watch?v=", "embed/");
    // } else {
    // 	let parts = this.videoURL.split('/');
    // 	let lastPart = parts[parts.length - 1];
    // 	obj = {
    // 		'org_external_video': 'https://player.vimeo.com/video/' + lastPart
    // 	};
    // 	video = 'https://player.vimeo.com/video/' + lastPart;
    // }

    // console.log(this.videoURL.replace("watch?v=", "embed/"));
    this.service.setOrgExternalVideo(sessionStorage.getItem("companyId"), obj).then(res => {
      $("#vide").contents().empty();
      this.external_video = this.convertMedia(this.videoURL);
      console.log(this.mx);
      this.mx.data['org_external_video'] = this.videoURL;
      this.videoURL = '';
      this.abc = false;
      this.showTexbox = true;
      btnUpload.classList.add("glyphicon-upload");
      btnUpload.classList.remove("glyphicon-refresh");
      btnUpload.classList.remove("glyphicon-refresh-animate");
    });
  }

  photosSelected($event) {
    this.evt1 = $event;
  }

  photosUpload() {
    let btnUpload = document.getElementById('btn2');
    btnUpload.classList.remove("glyphicon-upload");
    btnUpload.classList.add("glyphicon-refresh");
    btnUpload.classList.add("glyphicon-refresh-animate");
    let images = this.evt1.target.files;
    for (let i = 0; i < images.length; i++) {
      this.service.postOrgMedia(images[i], localStorage.getItem("org_id"))
        .subscribe(
          data => {
            this.render(images[i]);
            this.photos.unshift(data['org_image']);
            this.evt1 = '';
          },
          error => { },
          () => {
            btnUpload.classList.add("glyphicon-upload");
            btnUpload.classList.remove("glyphicon-refresh");
            btnUpload.classList.remove("glyphicon-refresh-animate");
          }
        );
    }
  }

  render(img) {
    var reader = new FileReader();
    reader.readAsDataURL(img);
    reader.onload = () => {
      this.org_photos.splice(0, 0, { 'org_image': reader.result });
      this.mx.data = this.org_photos;
    };
  }

  getId(url) {
    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);

    if (match && match[2].length == 11) {
      return match[2];
    } else {
      return 'error';
    }
  }

  convertMedia(html) {
    var pattern1 = /(?:http?s?:\/\/)?(?:www\.)?(?:vimeo\.com)\/?(.+)/g;
    var pattern2 = /(?:http?s?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?(.+)/g;
    // var pattern3 = /([-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?(?:jpg|jpeg|gif|png))/gi;

    if (pattern1.test(html)) {
      var replacement = '<iframe style="border: none; margin-top: 5px; width: 100%; height: 100%;" src="//player.vimeo.com/video/$1" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>';
      var html = html.replace(pattern1, replacement);
    }


    if (pattern2.test(html)) {
      var id = this.getId(html);
      var replacement = '<iframe style="border: none; margin-top: 5px; width: 100%; height: 100%;" src="https://www.youtube.com/embed/' + id + '" allowfullscreen></iframe>';
      var html = html.replace(pattern2, replacement);
    }

    if (!pattern1.test(html) && !pattern2.test(html)) {
      //alert('Please enter only youtube or vimeo video URL');
      this.service.sendAlert('Error', 'Please enter only youtube or vimeo video URL', 0);
      return;
    }

    // if(pattern3.test(html)){
    //     var replacement = '<a href="$1" target="_blank"><img class="sml" src="$1" /></a><br />';
    //     var html = html.replace(pattern3, replacement);
    // }          
    return this._sanitizer.bypassSecurityTrustHtml(html);
  }

  abc: boolean = false;
  evt: any;
  evt1: any = '';
  videoURL: any;

  showText(evt) {
    if (evt.srcElement.classList.contains('enhanced-pencil') && !this.service.isAdmin) {
      if (!this.obj.claimed) {
        this.service.sendAlert('', 'Claim this profile and upgrade to an Enhanced Profile to unlock this field.', 0);
      } else {
        jQuery('#enhancedModal').modal('show');
      }
      return;
    }

    // if (!this.obj.claimed && !this.service.isAdmin) {
    //   this.service.sendAlert('', 'You need to claim this profile in order to edit this field.', 0);
    //   return;
    // }
    this.showTexbox = true;
    this.EditToggle = true;
    if (this.mx.fieldname == 'org_certification') {
      if (isPlatformBrowser(this.platform)) {

        setTimeout(() => {
          var input = document.getElementById('autocompleteinput');
          var autocomplete = new google.maps.places.Autocomplete(input);
          let componentForm = {
            locality: 'long_name',
            administrative_area_level_1: 'long_name',
            country: 'long_name'
          };
          autocomplete.addListener('place_changed', () => {
            let place = autocomplete.getPlace();
            var val = '';
            for (var i = 0; i < place.address_components.length; i++) {
              var addressType = place.address_components[i].types[0];
              if (componentForm[addressType]) {
                if (addressType == 'country') {
                  val += place.address_components[i][componentForm[addressType]];
                } else {
                  val += place.address_components[i][componentForm[addressType]] + ', ';
                }
              }
            }
            console.log(val);
            this.certiLocation1 = val;
            console.log(this.certiLocation1);
          });
        }, 1000);
      }
    }

    if (this.mx.fieldname == 'org_headquarters_location') {
      if (isPlatformBrowser(this.platform)) {

        setTimeout(() => {
          var input = document.getElementById('autocompleteinput1');
          var autocomplete = new google.maps.places.Autocomplete(input);
          let componentForm = {
            locality: 'long_name',
            administrative_area_level_1: 'long_name',
            country: 'long_name'
          };
          autocomplete.addListener('place_changed', () => {
            let place = autocomplete.getPlace();
            var val = '';
            for (var i = 0; i < place.address_components.length; i++) {
              var addressType = place.address_components[i].types[0];
              if (componentForm[addressType]) {
                if (addressType == 'country') {
                  val += place.address_components[i][componentForm[addressType]];
                } else {
                  val += place.address_components[i][componentForm[addressType]] + ', ';
                }
              }
            }
            console.log(val);
            this.location = val;
          });
        }, 1000);
      }
    }
  }

  choose1(elem) {
    elem.click();
  }

  sizeChange(val) {
    this.onEnterDesc(val);
  }

  changePE(e) {
    this.onEnterDesc(e.target['value']);
  }

  hideBtn() {
  }

  onEnterDesc(value: any) {
    let body;
      if (this.mx.fieldname == 'pe_inhouse') {
        body = {
          pe_inhouse_v1: value
        };
      } else {
        body = {
          [this.mx.fieldname]: value
        };
      }
    let companyId = sessionStorage.getItem("companyId");
    this.service.doCompanyProfilePatch(companyId, body).then(result => {

      // if (this.mx.fieldname == 'pe_inhouse') {
      //   if (value == 'true') {
      //     this.mx.data = 'Yes';
      //   } else if (value == 'false') {
      //     this.mx.data = 'No';
      //   }
      // } else {
        this.mx.data = value;
      // }
      if (this.mx.fieldname != 'org_size') {
        if (!value.trim()) {
          this.showTexbox = false;
        } else {
          this.showTexbox = true;
        }
      } else {
        this.showTexbox = true;
      }

      this.EditToggle = false;

      console.log(result);
    });
  }

  onEnterDesc1(certification: any, location: any) {
    if (!certification.trim() || !location.trim()) {
      //alert('field cannot be blank');
      this.service.sendAlert('Error', 'field cannot be blank', 0);
      console.log("empty dala");
      return;
    }

    let certi = certification;

    if (this.certiName == 'Other') {
      certi = this.certiOther;
    }
    this.certifications.push({ "certification": certi, "location": location });
    let body = {
      "org_certification": JSON.stringify(this.certifications)
    };
    let companyId = sessionStorage.getItem("companyId");
    this.service.doCompanyProfilePatch(companyId, body).then(result => {

      this.mx.data = JSON.stringify(this.certifications)
      this.certiName = '';
      document.getElementById('autocompleteinput')['value'] = '';
      this.showTexbox = true;

      console.log(result);
    });
  }

  awardsDistinction: any = {};
  awardsDist = [];

  addAwardsDistinction() {
    if (!this.awardsDistinction.awardName.trim() || !this.awardsDistinction.link.trim()) {
      // alert('field cannot be blank');
      this.service.sendAlert('Error', "field cannot be blank.", 0);
      return;
    }

    this.awardsDist.push({ "name": this.awardsDistinction.awardName, "link": this.awardsDistinction.link });
    let body = {
      "org_awards": JSON.stringify(this.awardsDist)
    };

    //this.toggleVisibitly = true;
    let companyId = sessionStorage.getItem("companyId");
    this.service.doCompanyProfilePatch(companyId, body).then(result => {
      this.mx.data = JSON.stringify(this.awardsDist);
      //this.EditToggle = false;
      this.awardsDistinction.awardName = '';
      this.awardsDistinction.link = '';
      this.showTexbox = true;
    });
  }

  addHeadquarter() {
    if (!this.location.trim()) {
      //alert('field cannot be blank');
      this.service.sendAlert('Error', "field cannot be blank", 0);
      return;
    }
    let body = {
      "org_hq_location": this.location
    };
    let companyId = sessionStorage.getItem("companyId");
    this.service.doCompanyProfilePatch(companyId, body).then(result => {
      this.mx.data = this.location;
      this.EditToggle = false;
      this.showTexbox = true;
    });
  }

  removeAwardsDistinct(i: any) {
    this.awardsDist.splice(i, 1);

    let body;
    if (this.awardsDist.length == 0) {
      this.mx.data = null;
      body = {
        "org_awards": null
      };
    } else {
      body = {
        "org_awards": JSON.stringify(this.awardsDist)
      };
    }
    //this.toggleVisibitly = true;
    let companyId = sessionStorage.getItem("companyId");
    this.service.doCompanyProfilePatch(companyId, body).then(result => {

    });
  }

  removeCertification(i: any) {
    this.certifications.splice(i, 1);
    let body;
    if (this.certifications.length == 0) {
      this.mx.data = null;
      body = {
        "org_certification": null
      };
    } else {
      body = {
        "org_certification": JSON.stringify(this.certifications)
      };
    }

    let companyId = sessionStorage.getItem("companyId");
    this.service.doCompanyProfilePatch(companyId, body).then(result => {
      console.log(result);
    });
  }

}

