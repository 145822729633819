<div id="confirmModal" class="modal fade" role="dialog" data-keyboard="false" data-backdrop="static">
  <div class="modal-dialog">
    <div class="modal-content" style="border-radius: 0px;">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" (click)="no()">&times;</button>
        <h4 class="modal-title">Confirm</h4>
      </div>
      <div class="modal-body">
        {{confirmTitle}}
      </div>
      <div class="modal-footer">
        <button class="btn btn-default" data-dismiss="modal" (click)="no()">No</button>
        <button class="btn btn-primary" data-dismiss="modal" (click)="yes()">Yes</button>
      </div>
    </div>
  </div>
</div>
