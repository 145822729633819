import { Router, ActivatedRoute } from "@angular/router";

import { Component, NgZone, Input } from "@angular/core";

import { ProjectService } from "./../projectservice";
import { CookieService } from "angular2-cookie/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";

@Component({
  selector: "footer",
  templateUrl: "./footer.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent {
  footerInfoDivOne = [
    { title: "College Students", link: "/accelerator" },
    { title: "High School Students", link: "/accelerator" },
    // { title: "Professionals", link: "/professionals" },
    { title: "Educators", link: "/about/educators" },
    { title: "Employers", link: "/about/employers" },
    { title: "Mentors", link: "/about/mentors" },
  ];
  footerInfoDivTwo = [
    { title: "Career Accelerator", link: "/accelerator" },
    { title: "Projects", link: "/explorer/projects" },
    { title: "People", link: "/explorer/people" },
    { title: "Organizations", link: "/explorer/org" },
    { title: "Lesson Plans", link: "/explorer/lessonplans" },
    { title: "STEM Careers", link: "/explorer/jobs" },
  ];
  footerInfoDivThree = [
    { title: "About Us", link: "/about" },
    { title: "Privacy and Terms", link: "assets/pdfs/tou2020.pdf" },
  ];
  footerInfoDivFour = [{ title: "info@repicture.com", link: "" }];

  social = ["insta", "linkedin", "twitter", "facebook", "youtube"];

  contactForm: FormGroup;
  submitted: boolean;
  currentYear: number;
  constructor(
    public service: ProjectService,
    private cookie: CookieService,
    private router: Router,
    private formBuilder: FormBuilder
  ) {}
  data: any;
  showJoin: boolean = false;
  text: any =
    "By clicking below button, tester agrees to the below Software Beta Tester Nondisclosure Agreement<br>" +
    " <br>" +
    "SOFTWARE BETA TESTER NONDISCLOSURE AGREEMENT  <br>" +
    'This is an agreement, effective , between RePicture Engineering ("Company") and  "Tester", in which Tester agrees to test a software program known as ' +
    this.service.project_name +
    ' (the "Software") and keep Company aware of the test results (the “Beta Program”).<br><br>' +
    "1.  Beta Technology Limited License Grant<br>" +
    "<br>" +
    "Tester may, until the Company terminates the Beta Program, use the Software and related documentation solely in connection with testing and evaluation purposes, the reporting obligations described below, and for no other purpose.  Tester agrees that the Software may not be used for productive use, and that Tester will not attempt to disassemble, alter, decompile or otherwise reverse engineer, prepare derivative works from, or modify the Beta Technology in any way, or remove any copyright or other proprietary rights notices or confidentiality legends placed upon or contained in the Software or documentation.  No transfer of ownership is intended by this Agreement.<br>" +
    "<br>" +
    "2.  Software a Trade Secret<br>" +
    "<br>" +
    "All technical, marketing, financial or other business information of Company related to the Beta Program, including but not limited to the Software, related documentation and any results, data or analysis arising from the Beta Program, whether or not included in a Test Report, is “Confidential Information”, whether or not marked as such.  The Software is proprietary to, and a valuable trade secret of, Company. It is entrusted to Tester only for the purpose set forth in this Agreement. Tester shall maintain Software and all Confidential Information in the strictest confidence. Tester will not, without Company's prior written consent:<br>" +
    "(a) disclose any Confidential Information including the Software, its design and performance specifications, its code, and the existence of the beta test and its results to anyone other than Tester's employees who are performing the testing and who shall be subject to nondisclosure restrictions at least as protective as those set forth in this Agreement; or<br>" +
    "(b) copy any portion of Software or documentation, except to the extent necessary to perform beta testing. <br>" +
    "Tester agrees that, due to the unique nature of the Confidential Information, any breach of this Section would cause irreparable harm to Company for which there is no adequate remedy at law; therefore, in the event of any breach or threatened breach of this Section, the Company shall be entitled to seek immediate injunctive relief, in addition to whatever remedies the Company may have under this Agreement or otherwise, without the necessity of posting a bond.<br>" +
    "<br>" +
    "3.  Tester Duties<br>" +
    "<br>" +
    "As a Tester, you agree to undertake the following activities:<br>" +
    "Test and evaluate the Software focusing on identification of, and assisting us in resolving, usability and feature functionality errors.<br>" +
    "Report to Company, as soon as is reasonably practical, any discovered bugs, errors, flaws or other perceived problems with the Software.<br>" +
    "Participate, as reasonably required, in our marketing-related post Beta Program interviews.<br>" +
    "Serve as a reference with respect to the Software for technology analysts, industry press, prospective customers or other third parties.<br>" +
    "<br>" +
    "4.  Term and Termination<br>" +
    "<br>" +
    "This Agreement shall end on the date of the first commercial release of the Software (“Termination Date”); provided, that the Company may terminate this Agreement and/or the Beta Program for convenience at any time prior to the Termination Date.  Upon termination, Tester shall promptly un-install and return all copies of the Software, documentation, any test results (including Test Reports), related files and any other Confidential Information (as defined below) that the Company may have provided.  Otherwise, all copies of any of the foregoing items will be destroyed.<br>" +
    "<br>" +
    "5. Disclaimer of Warranty<br>" +
    "<br>" +
    "Software is a test product and its accuracy and reliability are not guaranteed. Tester shall not rely exclusively on Software for any reason. Tester waives any and all claims Tester may have against Company arising out of the performance or nonperformance of Software.<br>" +
    "SOFTWARE IS PROVIDED “AS IS”, “WITH ALL FAULTS” AND COMPANY DISCLAIMS ANY AND ALL REPRESENTATIONS OR WARRANTIES OF ANY KIND, WHETHER ORAL OR WRITTENT, EXPRESS OR IMPLIED, WITH RESPECT TO IT, INCLUDING ANY IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.<br>" +
    "<br>" +
    "6. Limitation of Liability<br>" +
    "<br>" +
    "Company shall not be responsible for any loss or damage to Tester or any third parties caused by Software. COMPANY SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL OR CONSEQUENTIAL DAMAGE, WHETHER BASED ON CONTRACT OR TORT OR ANY OTHER LEGAL THEORY, ARISING OUT OF ANY USE OF SOFTWARE OR ANY PERFORMANCE OF THIS AGREEMENT.<br>" +
    "<br>" +
    "7. No Rights Granted<br>" +
    "<br>" +
    "This Agreement does not constitute a grant or an intention or commitment to grant any right, title or interest in Software or Company's trade secrets to Tester. Tester may not sell or transfer any portion of Software to any third party or use Software in any manner to produce, market or support its own products. Tester shall not identify Software as coming from any source other than Company.<br>" +
    "<br>" +
    "8. General<br>" +
    " <br>" +
    "Tester is acting as an independent contractor and not as an agent of Company. This Agreement is binding upon and inures to the benefit of the parties, their respective representatives, successors and assigns.  Tester may not assign or transfer any of its rights, duties or obligations hereunder.  This Agreement shall be construed and enforced pursuant to the laws of the State of Maryland, exclusive of rules governing conflict of laws and choice of laws.  This Agreement constitutes the entire Agreement between the parties hereto with respect to its subject matter, superseding all proposals, prior negotiations, understandings and agreements, whether oral or written, and may only be changed by a subsequent written agreement between the parties.<br>" +
    " <br>";

  text2 =
    "Website Terms of Use<br>" +
    " <br>" +
    "Agreement between user and RePicture Engineering, PBC<br>" +
    " <br>" +
    "Welcome to www.iLookLikeACivilEngineer.com, RePictureEngineeering.com, and/or RePictureApp.com.<br>" +
    "The www.iLookLikeACivilEngineer.com, RePictureEngineeering.com, and RePictureApp.com website (the<br>" +
    '"Site") is comprised of various web pages operated by RePicture Engineering ("RePicture Engineering").<br>' +
    "The Site is offered to you conditioned on your acceptance without modification of the terms, conditions,<br>" +
    'and notices contained herein (the "Terms"). Your use of the Site constitutes your agreement to all such<br>' +
    "Terms. Please read these terms carefully, and keep a copy of them for your reference.<br>" +
    " <br>" +
    "The site is a Forum/Online discussion Site to increase interest and diversity in Engineering<br>" +
    " <br>" +
    "Electronic Communications<br>" +
    " <br>" +
    "Visiting the Site or sending emails to RePicture Engineering constitutes electronic communications. You<br>" +
    "consent to receive electronic communications and you agree that all agreements, notices, disclosures<br>" +
    "and other communications that we provide to you electronically, via email and on the Site, satisfy any<br>" +
    "legal requirement that such communications be in writing.<br>" +
    " <br>" +
    "RePicture Engineering does not knowingly collect, either online or offline, personal information from<br>" +
    "persons under the age of thirteen. If you are under 18, you may use the SIte only with permission of a<br>" +
    "parent or guardian.<br>" +
    " <br>" +
    "Links to third party sites/Third party services<br>" +
    " <br>" +
    'The Site may contain links to other websites ("Linked Sites"). The Linked Sites are not under the control<br>' +
    "of RePicture Engineering RePicture Engineering is not responsible for the contents of any Linked Site,<br>" +
    "including without limitation any link contained in a Linked Site, or any changes or updates to a Linked<br>" +
    "Site. RePicture Engineering is providing these links to you only as a convenience, and the inclusion of any<br>" +
    "link does not imply endorsement by RePicture Engineering of the site or any association with its<br>" +
    "operators.<br>" +
    " <br>" +
    "Certain services made available via the Site are delivered by third party sites and organizations. By using<br>" +
    "any product, service or functionality originating from the Site domain, you hereby acknowledge and<br>" +
    "consent that RePicture Engineering may share such information and data with any third party with<br>" +
    "whom RePicture Engineering has a contractual relationship to provide the requested product, service or<br>" +
    "functionality on behalf of the Site users and customers.<br>" +
    " <br>" +
    "No unlawful or prohibited use/Intellectual Property<br>" +
    " <br>" +
    "You are granted a non-exclusive, non-transferable, revocable license to access and use the Site strictly in<br>" +
    "accordance with these terms of use. As a condition of your use of the Site, you warrant to RePicture<br>" +
    "Engineering that you will not use the Site for any purpose that is unlawful or prohibited by these Terms.<br>" +
    "You may not use the Site in any manner which could damage, disable, overburden, or impair the Site or<br>" +
    "interfere with any other party's use and enjoyment of the Site. You may not obtain or attempt to obtain<br>" +
    "any materials or information through any means not intentionally made available or provided for<br>" +
    "through the Site.<br>" +
    " <br>" +
    "All content included as part of the Service, such as text, graphics, logos, images, as well as the<br>" +
    "compilation thereof, and any software used on the Site, is the property of RePicture Engineering or its<br>" +
    "suppliers and protected by copyright and other laws that protect intellectual property and proprietary<br>" +
    "rights. You agree to observe and abide by all copyright and other proprietary notices, legends or other<br>" +
    "restrictions contained in any such content and will not make any changes thereto.<br>" +
    " <br>" +
    "You will not modify, publish, transmit, reverse engineer, participate in the transfer or sale, create<br>" +
    "derivative works, or in any way exploit any of the content, in whole or in part, found on the Site.<br>" +
    "RePicture Engineering content is not for resale. Your use of the Site does not entitle you to make any<br>" +
    "unauthorized use of any protected content, and in particular you will not delete or alter any proprietary<br>" +
    "rights or attribution notices in any content. You will use protected content solely for your personal use,<br>" +
    "and will make no other use of the content without the express written permission of RePicture<br>" +
    "Engineering and the copyright owner. You agree that you do not acquire any ownership rights in any<br>" +
    "protected content. We do not grant you any licenses, express or implied, to the intellectual property of<br>" +
    "RePicture Engineering or our licensors except as expressly authorized by these Terms.<br>" +
    " <br>" +
    "Use of communication services<br>" +
    " <br>" +
    "The Site may contain bulletin board services, chat areas, news groups, forums, communities, personal<br>" +
    "web pages, calendars, and/or other message or communication facilities designed to enable you to<br>" +
    'communicate with the public at large or with a group (collectively, "Communication Services"), you<br>' +
    "agree to use the Communication Services only to post, send and receive messages and material that are<br>" +
    "proper and related to the particular Communication Service.<br>" +
    " <br>" +
    "By way of example, and not as a limitation, you agree that when using a Communication Service, you<br>" +
    "will not: defame, abuse, harass, stalk, threaten or otherwise violate the legal rights (such as rights of<br>" +
    "privacy and publicity) of others; publish, post, upload, distribute or disseminate any inappropriate,<br>" +
    "profane, defamatory, infringing, obscene, indecent or unlawful topic, name, material or information;<br>" +
    "upload files that contain software or other material protected by intellectual property laws (or by rights<br>" +
    "of privacy of publicity) unless you own or control the rights thereto or have received all necessary<br>" +
    "consents; upload files that contain viruses, corrupted files, or any other similar software or programs<br>" +
    "that may damage the operation of another's computer; advertise or offer to sell or buy any goods or<br>" +
    "services for any business purpose, unless such Communication Service specifically allows such messages;<br>" +
    "conduct or forward surveys, contests, pyramid schemes or chain letters; download any file posted by<br>" +
    "another user of a Communication Service that you know, or reasonably should know, cannot be legally<br>" +
    "distributed in such manner; falsify or delete any author attributions, legal or other proper notices or<br>" +
    "proprietary designations or labels of the origin or source of software or other material contained in a file<br>" +
    "that is uploaded, restrict or inhibit any other user from using and enjoying the Communication Services;<br>" +
    "violate any code of conduct or other guidelines which may be applicable for any particular<br>" +
    "Communication Service; harvest or otherwise collect information about others, including e-mail<br>" +
    "addresses, without their consent; violate any applicable laws or regulations.<br>" +
    " <br>" +
    "RePicture Engineering has no obligation to monitor the Communication Services. However, RePicture<br>" +
    "Engineering reserves the right to review materials posted to a Communication Service and to remove<br>" +
    "any materials in its sole discretion. RePicture Engineering reserves the right to terminate your access to<br>" +
    "any or all of the Communication Services at any time without notice for any reason whatsoever.RePicture Engineering reserves the right at all times to disclose any information as necessary to satisfy<br>" +
    "any applicable law, regulation, legal process or governmental request, or to edit, refuse to post or to<br>" +
    "remove any information or materials, in whole or in part, in RePicture Engineering's sole discretion.<br>" +
    " <br>" +
    "Always use caution when giving out any personally identifying information about yourself or your<br>" +
    "children in any Communication Service. RePicture Engineering does not control or endorse the content,<br>" +
    "messages or information found in any Communication Service and, therefore, RePicture Engineering<br>" +
    "specifically disclaims any liability with regard to the Communication Services and any actions resulting<br>" +
    "from your participation in any Communication Service. Managers and hosts are not authorized<br>" +
    "RePicture Engineering spokespersons, and their views do not necessarily reflect those of RePicture<br>" +
    "Engineering.<br>" +
    " <br>" +
    "Materials uploaded to a Communication Service may be subject to posted limitations on usage,<br>" +
    "reproduction and/or dissemination. You are responsible for adhering to such limitations if you upload<br>" +
    "the materials.<br>" +
    " <br>" +
    "Materials provided to the Site or posted on any RePicture Engineering web page<br>" +
    " <br>" +
    "RePicture Engineering does not claim ownership of the materials you provide to the Site (including<br>" +
    "feedback and suggestions) or post, upload, input or submit to any RePicture Engineering Site or our<br>" +
    'associated services (collectively "Submissions"). However, by posting, uploading, inputting, providing or<br>' +
    "submitting your Submission you are granting RePicture Engineering, our affiliated companies and<br>" +
    "necessary sublicensees permission to use your Submission in connection with the operation of their<br>" +
    "Internet businesses including, without limitation, the rights to: copy, distribute, transmit, publicly<br>" +
    "display, publicly perform, reproduce, edit, translate and reformat your Submission; and to publish your<br>" +
    "name in connection with your Submission.<br>" +
    " <br>" +
    "No compensation will be paid with respect to the use of your Submission, as provided herein. RePicture<br>" +
    "Engineering is under no obligation to post or use any Submission you may provide and may remove any<br>" +
    "Submission at any time in RePicture Engineering's sole discretion.<br>" +
    " <br>" +
    "By posting, uploading, inputting, providing or submitting your Submission you warrant and represent<br>" +
    "that you own or otherwise control all of the rights to your Submission as described in this section<br>" +
    "including, without limitation, all the rights necessary for you to provide, post, upload, input or submit<br>" +
    "the Submissions.<br>" +
    " <br>" +
    "Third Party Accounts<br>" +
    " <br>" +
    "You will be able to connect your RePicture Engineering account to third party accounts. By connecting<br>" +
    "your RePicture Engineering account to your third party account, you acknowledge and agree that you<br>" +
    "are consenting to the continuous release of information about you to others (in accordance with your<br>" +
    "privacy settings on those third party sites). If you do not want information about you to be shared in this<br>" +
    "manner, do not use this feature.<br>" +
    " <br>" +
    "International Users<br>" +
    " <br>" +
    "The Service is controlled, operated and administered by RePicture Engineering from our offices within<br>" +
    "the USA. If you access the Service from a location outside the USA, you are responsible for compliancewith all local laws. You agree that you will not use the RePicture Engineering Content accessed through<br>" +
    "the Site in any country or in any manner prohibited by any applicable laws, restrictions or regulations.<br>" +
    " <br>" +
    "Indemnification<br>" +
    " <br>" +
    "You agree to indemnify, defend and hold harmless RePicture Engineering, its officers, directors,<br>" +
    "employees, agents and third parties, for any losses, costs, liabilities and expenses (including reasonable<br>" +
    "attorneys' fees) relating to or arising out of your use of or inability to use the Site or services, any user<br>" +
    "postings made by you, your violation of any terms of this Agreement or your violation of any rights of a<br>" +
    "third party, or your violation of any applicable laws, rules or regulations. RePicture Engineering reserves<br>" +
    "the right, at its own cost, to assume the exclusive defense and control of any matter otherwise subject<br>" +
    "to indemnification by you, in which event you will fully cooperate with RePicture Engineering in<br>" +
    "asserting any available defenses.<br>" +
    " <br>" +
    "Liability disclaimer<br>" +
    " <br>" +
    "THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE<br>" +
    "SITE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED<br>" +
    "TO THE INFORMATION HEREIN. REPICTURE ENGINEERING, PBC AND/OR ITS SUPPLIERS MAY MAKE<br>" +
    "IMPROVEMENTS AND/OR CHANGES IN THE SITE AT ANY TIME.<br>" +
    " <br>" +
    "REPICTURE ENGINEERING, PBC AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE<br>" +
    "SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY OF THE INFORMATION,<br>" +
    "SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS CONTAINED ON THE SITE FOR ANY<br>" +
    "PURPOSE. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION,<br>" +
    'SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS ARE PROVIDED "AS IS" WITHOUT<br>' +
    "WARRANTY OR CONDITION OF ANY KIND. REPICTURE ENGINEERING, PBC AND/OR ITS SUPPLIERS<br>" +
    "HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS INFORMATION,<br>" +
    "SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS, INCLUDING ALL IMPLIED WARRANTIES OR<br>" +
    "CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND<br>" +
    "NONINFRINGEMENT.<br>" +
    " <br>" +
    "TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL REPICTURE<br>" +
    "ENGINEERING, PBC AND/OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE,<br>" +
    "INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING,<br>" +
    "WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY<br>" +
    "WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE SITE, WITH THE DELAY OR INABILITY TO<br>" +
    "USE THE SITE OR RELATED SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR<br>" +
    "ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED THROUGH<br>" +
    "THE SITE, OR OTHERWISE ARISING OUT OF THE USE OF THE SITE, WHETHER BASED ON CONTRACT,<br>" +
    "TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF REPICTURE ENGINEERING, PBC OR ANY<br>" +
    "OF ITS SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME<br>" +
    "STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR<br>" +
    "CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU<br>" +
    "ARE DISSATISFIED WITH ANY PORTION OF THE SITE, OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE<br>" +
    "AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SITE.<br>" +
    " <br>" +
    "Termination/access restriction<br>" +
    " <br>" +
    "RePicture Engineering reserves the right, in its sole discretion, to terminate your access to the Site and<br>" +
    "the related services or any portion thereof at any time, without notice. To the maximum extent<br>" +
    "permitted by law, this agreement is governed by the laws of the State of Maryland and you hereby<br>" +
    "consent to the exclusive jurisdiction and venue of courts in Maryland in all disputes arising out of or<br>" +
    "relating to the use of the Site. Use of the Site is unauthorized in any jurisdiction that does not give effect<br>" +
    "to all provisions of these Terms, including, without limitation, this section.<br>" +
    " <br>" +
    "You agree that no joint venture, partnership, employment, or agency relationship exists between you<br>" +
    "and RePicture Engineering as a result of this agreement or use of the Site. RePicture Engineering's<br>" +
    "performance of this agreement is subject to existing laws and legal process, and nothing contained in<br>" +
    "this agreement is in derogation of RePicture Engineering's right to comply with governmental, court and<br>" +
    "law enforcement requests or requirements relating to your use of the Site or information provided to or<br>" +
    "gathered by RePicture Engineering with respect to such use. If any part of this agreement is determined<br>" +
    "to be invalid or unenforceable pursuant to applicable law including, but not limited to, the warranty<br>" +
    "disclaimers and liability limitations set forth above, then the invalid or unenforceable provision will be<br>" +
    "deemed superseded by a valid, enforceable provision that most closely matches the intent of the<br>" +
    "original provision and the remainder of the agreement shall continue in effect.<br>" +
    " <br>" +
    "Unless otherwise specified herein, this agreement constitutes the entire agreement between the user<br>" +
    "and RePicture Engineering with respect to the Site and it supersedes all prior or contemporaneous<br>" +
    "communications and proposals, whether electronic, oral or written, between the user and RePicture<br>" +
    "Engineering with respect to the Site. A printed version of this agreement and of any notice given in<br>" +
    "electronic form shall be admissible in judicial or administrative proceedings based upon or relating to<br>" +
    "this agreement to the same extent and subject to the same conditions as other business documents and<br>" +
    "records originally generated and maintained in printed form. It is the express wish to the parties that<br>" +
    "this agreement and all related documents be written in English.<br>" +
    " <br>" +
    "Changes to Terms<br>" +
    " <br>" +
    "RePicture Engineering reserves the right, in its sole discretion, to change the Terms under which the Site<br>" +
    "is offered. The most current version of the Terms will supersede all previous versions. RePicture<br>" +
    "Engineering encourages you to periodically review the Terms to stay informed of our updates.<br>" +
    " <br>" +
    "Contact Us<br>" +
    " <br>" +
    "RePicture Engineering welcomes your questions or comments regarding the Terms:<br>" +
    " <br>" +
    "RePicture Engineering, PBC<br>" +
    "PO Box 342231<br>" +
    "Bethesda, MD 20827<br>" +
    "Email Address: aelisa@RePicture Engineering.com<br>" +
    " <br>" +
    "Effective as of March 2, 2018<br>";

  text3 =
    "Website Terms of Use<br>" +
    "<br>" +
    "Agreement between user and RePicture Engineering, PBC<br>" +
    "<br>" +
    'Welcome to www.iLookLikeACivilEngineer.com, RePictureEngineeering.com, and/or RePictureApp.com. The www.iLookLikeACivilEngineer.com, RePictureEngineeering.com, and RePictureApp.com website (the "Site") is comprised of various web pages operated by RePicture Engineering ("RePicture Engineering").<br>' +
    "<br>" +
    'The Site is offered to you conditioned on your acceptance without modification of the terms, conditions, and notices contained herein (the "Terms"). Your use of the Site constitutes your agreement to all such Terms. Please read these terms carefully, and keep a copy of them for your reference.<br>' +
    "<br>" +
    "The site is a Forum/Online discussion Site to increase interest and diversity in Engineering<br>" +
    "<br>" +
    "Electronic Communications<br>" +
    "<br>" +
    "Visiting the Site or sending emails to RePicture Engineering constitutes electronic communications. You consent to receive electronic communications and you agree that all agreements, notices, disclosures and other communications that we provide to you electronically, via email and on the Site, satisfy any legal requirement that such communications be in writing.<br>" +
    "<br>" +
    "RePicture Engineering does not knowingly collect, either online or offline, personal information from persons under the age of thirteen. If you are under 18, you may use the SIte only with permission of a parent or guardian.<br>" +
    "<br>" +
    "Links to third party sites/Third party services<br>" +
    "<br>" +
    'The Site may contain links to other websites ("Linked Sites"). The Linked Sites are not under the control of RePicture Engineering RePicture Engineering is not responsible for the contents of any Linked Site, including without limitation any link contained in a Linked Site, or any changes or updates to a Linked Site. RePicture Engineering is providing these links to you only as a convenience, and the inclusion of any link does not imply endorsement by RePicture Engineering of the site or any association with its operators.<br>' +
    "<br>" +
    "Certain services made available via the Site are delivered by third party sites and organizations. By using any product, service or functionality originating from the Site domain, you hereby acknowledge and consent that RePicture Engineering may share such information and data with any third party with whom RePicture Engineering has a contractual relationship to provide the requested product, service or functionality on behalf of the Site users and customers.<br>" +
    "<br>" +
    "No unlawful or prohibited use/Intellectual Property<br>" +
    "<br>" +
    "You are granted a non-exclusive, non-transferable, revocable license to access and use the Site strictly in accordance with these terms of use. As a condition of your use of the Site, you warrant to RePicture Engineering that you will not use the Site for any purpose that is unlawful or prohibited by these Terms. You may not use the Site in any manner which could damage, disable, overburden, or impair the Site or interfere with any other party's use and enjoyment of the Site. You may not obtain or attempt to obtain any materials or information through any means not intentionally made available or provided for through the Site.<br>" +
    "<br>" +
    "All content included as part of the Service, such as text, graphics, logos, images, as well as the compilation thereof, and any software used on the Site, is the property of RePicture Engineering or its suppliers and protected by copyright and other laws that protect intellectual property and proprietary rights. You agree to observe and abide by all copyright and other proprietary notices, legends or other restrictions contained in any such content and will not make any changes thereto.<br>" +
    "<br>" +
    "You will not modify, publish, transmit, reverse engineer, participate in the transfer or sale, create derivative works, or in any way exploit any of the content, in whole or in part, found on the Site. RePicture Engineering content is not for resale. Your use of the Site does not entitle you to make any unauthorized use of any protected content, and in particular you will not delete or alter any proprietary rights or attribution notices in any content. You will use protected content solely for your personal use, and will make no other use of the content without the express written permission of RePicture Engineering and the copyright owner. You agree that you do not acquire any ownership rights in any protected content. We do not grant you any licenses, express or implied, to the intellectual property of RePicture Engineering or our licensors except as expressly authorized by these Terms.<br>" +
    "<br>" +
    "Use of communication services<br>" +
    "<br>" +
    'The Site may contain bulletin board services, chat areas, news groups, forums, communities, personal web pages, calendars, and/or other message or communication facilities designed to enable you to communicate with the public at large or with a group (collectively, "Communication Services"), you agree to use the Communication Services only to post, send and receive messages and material that are proper and related to the particular Communication Service.<br>' +
    "<br>" +
    "By way of example, and not as a limitation, you agree that when using a Communication Service, you will not: defame, abuse, harass, stalk, threaten or otherwise violate the legal rights (such as rights of privacy and publicity) of others; publish, post, upload, distribute or disseminate any inappropriate, profane, defamatory, infringing, obscene, indecent or unlawful topic, name, material or information; upload files that contain software or other material protected by intellectual property laws (or by rights of privacy of publicity) unless you own or control the rights thereto or have received all necessary consents; upload files that contain viruses, corrupted files, or any other similar software or programs that may damage the operation of another's computer; advertise or offer to sell or buy any goods or services for any business purpose, unless such Communication Service specifically allows such messages; conduct or forward surveys, contests, pyramid schemes or chain letters; download any file posted by another user of a Communication Service that you know, or reasonably should know, cannot be legally distributed in such manner; falsify or delete any author attributions, legal or other proper notices or proprietary designations or labels of the origin or source of software or other material contained in a file that is uploaded, restrict or inhibit any other user from using and enjoying the Communication Services; violate any code of conduct or other guidelines which may be applicable for any particular Communication Service; harvest or otherwise collect information about others, including e-mail addresses, without their consent; violate any applicable laws or regulations.<br>" +
    "<br>" +
    "RePicture Engineering has no obligation to monitor the Communication Services. However, RePicture Engineering reserves the right to review materials posted to a Communication Service and to remove any materials in its sole discretion. RePicture Engineering reserves the right to terminate your access to any or all of the Communication Services at any time without notice for any reason whatsoever.<br>" +
    "<br>" +
    "RePicture Engineering reserves the right at all times to disclose any information as necessary to satisfy any applicable law, regulation, legal process or governmental request, or to edit, refuse to post or to remove any information or materials, in whole or in part, in RePicture Engineering's sole discretion.<br>" +
    "<br>" +
    "Always use caution when giving out any personally identifying information about yourself or your children in any Communication Service. RePicture Engineering does not control or endorse the content, messages or information found in any Communication Service and, therefore, RePicture Engineering specifically disclaims any liability with regard to the Communication Services and any actions resulting from your participation in any Communication Service. Managers and hosts are not authorized RePicture Engineering spokespersons, and their views do not necessarily reflect those of RePicture Engineering.<br>" +
    "<br>" +
    "Materials uploaded to a Communication Service may be subject to posted limitations on usage, reproduction and/or dissemination. You are responsible for adhering to such limitations if you upload the materials.<br>" +
    "<br>" +
    "Materials provided to the Site or posted on any RePicture Engineering web page<br>" +
    "<br>" +
    'RePicture Engineering does not claim ownership of the materials you provide to the Site (including feedback and suggestions) or post, upload, input or submit to any RePicture Engineering Site or our associated services (collectively "Submissions"). However, by posting, uploading, inputting, providing or submitting your Submission you are granting RePicture Engineering, our affiliated companies and necessary sublicensees permission to use your Submission in connection with the operation of their Internet businesses including, without limitation, the rights to: copy, distribute, transmit, publicly display, publicly perform, reproduce, edit, translate and reformat your Submission; and to publish your name in connection with your Submission.<br>' +
    "<br>" +
    "No compensation will be paid with respect to the use of your Submission, as provided herein. RePicture Engineering is under no obligation to post or use any Submission you may provide and may remove any Submission at any time in RePicture Engineering's sole discretion.<br>" +
    "<br>" +
    "By posting, uploading, inputting, providing or submitting your Submission you warrant and represent that you own or otherwise control all of the rights to your Submission as described in this section including, without limitation, all the rights necessary for you to provide, post, upload, input or submit the Submissions.<br>" +
    "<br>" +
    "Third Party Accounts<br>" +
    "<br>" +
    "You will be able to connect your RePicture Engineering account to third party accounts. By connecting your RePicture Engineering account to your third party account, you acknowledge and agree that you are consenting to the continuous release of information about you to others (in accordance with your privacy settings on those third party sites). If you do not want information about you to be shared in this manner, do not use this feature.<br>" +
    "<br>" +
    "International Users<br>" +
    "<br>" +
    "The Service is controlled, operated and administered by RePicture Engineering from our offices within the USA. If you access the Service from a location outside the USA, you are responsible for compliance<br>" +
    "<br>" +
    "with all local laws. You agree that you will not use the RePicture Engineering Content accessed through the Site in any country or in any manner prohibited by any applicable laws, restrictions or regulations.<br>" +
    "<br>" +
    "Indemnification<br>" +
    "<br>" +
    "You agree to indemnify, defend and hold harmless RePicture Engineering, its officers, directors, employees, agents and third parties, for any losses, costs, liabilities and expenses (including reasonable attorneys' fees) relating to or arising out of your use of or inability to use the Site or services, any user postings made by you, your violation of any terms of this Agreement or your violation of any rights of a third party, or your violation of any applicable laws, rules or regulations. RePicture Engineering reserves the right, at its own cost, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will fully cooperate with RePicture Engineering in asserting any available defenses.<br>" +
    "<br>" +
    "Liability disclaimer<br>" +
    "<br>" +
    "THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN. REPICTURE ENGINEERING, PBC AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES IN THE SITE AT ANY TIME.<br>" +
    "<br>" +
    'REPICTURE ENGINEERING, PBC AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS CONTAINED ON THE SITE FOR ANY PURPOSE. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS ARE PROVIDED "AS IS" WITHOUT WARRANTY OR CONDITION OF ANY KIND. REPICTURE ENGINEERING, PBC AND/OR ITS SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS, INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NONINFRINGEMENT.<br>' +
    "<br>" +
    "TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL REPICTURE ENGINEERING, PBC AND/OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE SITE, WITH THE DELAY OR INABILITY TO USE THE SITE OR RELATED SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE SITE, OR OTHERWISE ARISING OUT OF THE USE OF THE SITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF REPICTURE ENGINEERING, PBC OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SITE, OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SITE.<br>" +
    "<br>" +
    "Termination/access restriction<br>" +
    "<br>" +
    "RePicture Engineering reserves the right, in its sole discretion, to terminate your access to the Site and the related services or any portion thereof at any time, without notice. To the maximum extent permitted by law, this agreement is governed by the laws of the State of Maryland and you hereby consent to the exclusive jurisdiction and venue of courts in Maryland in all disputes arising out of or relating to the use of the Site. Use of the Site is unauthorized in any jurisdiction that does not give effect to all provisions of these Terms, including, without limitation, this section.<br>" +
    "<br>" +
    "You agree that no joint venture, partnership, employment, or agency relationship exists between you and RePicture Engineering as a result of this agreement or use of the Site. RePicture Engineering's performance of this agreement is subject to existing laws and legal process, and nothing contained in this agreement is in derogation of RePicture Engineering's right to comply with governmental, court and law enforcement requests or requirements relating to your use of the Site or information provided to or gathered by RePicture Engineering with respect to such use. If any part of this agreement is determined to be invalid or unenforceable pursuant to applicable law including, but not limited to, the warranty disclaimers and liability limitations set forth above, then the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of the agreement shall continue in effect.<br>" +
    "<br>" +
    "Unless otherwise specified herein, this agreement constitutes the entire agreement between the user and RePicture Engineering with respect to the Site and it supersedes all prior or contemporaneous communications and proposals, whether electronic, oral or written, between the user and RePicture Engineering with respect to the Site. A printed version of this agreement and of any notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to this agreement to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form. It is the express wish to the parties that this agreement and all related documents be written in English.<br>" +
    "<br>" +
    "Changes to Terms<br>" +
    "<br>" +
    "RePicture Engineering reserves the right, in its sole discretion, to change the Terms under which the Site is offered. The most current version of the Terms will supersede all previous versions. RePicture Engineering encourages you to periodically review the Terms to stay informed of our updates.<br>" +
    "<br>" +
    "Contact Us<br>" +
    "<br>" +
    "RePicture Engineering welcomes your questions or comments regarding the Terms:<br>" +
    "<br>" +
    "RePicture Engineering, PBC<br>" +
    "PO Box 342231<br>" +
    "Bethesda, MD 20827<br>" +
    "Email Address: aelisa@RePicture Engineering.com<br>" +
    "<br>" +
    "Effective as of March 2, 2018<br>";

  ngOnInit() {
    if (localStorage.getItem("peopleid")) {
      this.showJoin = false;
    } else {
      this.showJoin = true;
    }
    this.contactForm = this.formBuilder.group({
      firstname: ["", [Validators.required]],
      lastname: ["", [Validators.required]],
      email: ["", [Validators.required, Validators.email]],
      message: ["", [Validators.required]],
    });

    this.currentYear = new Date().getFullYear();
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.contactForm.controls;
  }

  sendEvt(item) {
    this.service.sendEvent(
      "click",
      "redirect from menu item",
      "clicked item: " + item
    );
  }

  gotoAbout(item) {
    this.cookie.put("about", item);
    this.router.navigate(["/about"]);
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.contactForm.invalid) {
      return;
    }
    let obj = {
      first_name: this.contactForm.value["firstname"],
      last_name: this.contactForm.value["lastname"],
      email: this.contactForm.value["email"],
      message: this.contactForm.value["message"],
    };
    this.service.contactSubmit(obj).then((res) => {
      this.contactForm.reset();
      this.service.sendAlert("Thank you", "We'll get back to you shortly.", 1);
      this.submitted = false;
    });
  }

  goToUrls(i) {
    switch (i) {
      case 0:
        window.open("https://www.instagram.com/repictureyourcareer", "_blank");
        break;

      case 1:
        window.open("https://www.linkedin.com/company/repicture", "_blank");
      case 2:
        window.open("https://twitter.com/_RePicture", "_blank");
      case 3:
        window.open("https://www.facebook.com/RePictureYourCareer", "_blank");
      case 4:
        window.open(
          "https://www.youtube.com/channel/UC5QYVuq9CDm9TyOaZbl9_PQ",
          "_blank"
        );

      default:
        break;
    }
  }
}
