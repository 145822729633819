import { HomeTestimonialsComponent } from './../homepage/components/home-testimonials/home-testimonials.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TestimonialCardComponent } from '../testimonial-card/testimonial-card.component';
import { ProfileCardComponent } from '../profile-card/profile-card.component';
import { ButtonsModule } from '../buttons/buttons.module';
import { ProjectCardComponent } from '../project-card/project-card.component';
import { ProfileMiniCardComponent } from '../profile-mini-card/profile-mini-card.component';
import { VideoPlayerComponent } from '../video-player/video-player.component';
import { FaqDropdownComponent } from '../faq-dropdown/faq-dropdown.component';
import { DragScrollComponent } from './drag-scroll/drag-scroll.component';
import { FormFieldComponent } from './form-field/form-field.component';
import { RpChipsComponent } from './rp-chips/rp-chips.component';
import { SafePipe } from './safe.pipe';
import { OrgCardComponent } from '../org-card/org-card.component';
import { PaymentSuccessComponent } from './payment-success/payment-success/payment-success.component';
import { TeamComponent } from './team/team.component';
import { JourneyComponent } from './journey/journey.component';
import { NewsComponent } from './news/news.component';
import { LinkCardComponent } from './link-card/link-card.component';
import { SponsorsComponent } from './sponsors/sponsors.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { CardIconHeaderComponent } from './card-icon-header/card-icon-header.component';
import { HomeFaqsComponent } from '../homepage/components/home-faqs/home-faqs.component';
import { CardPriceComponent } from './card-price/card-price.component';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from 'src/environments/environment';
import { TestimonialSectionCardComponent } from './testimonial-section-card/testimonial-section-card.component';
import { PanelistComponent } from './panelist/panelist.component';



@NgModule({
  declarations: [
    TestimonialCardComponent,
    ProfileCardComponent,
    ProjectCardComponent,
    OrgCardComponent,
    ProfileMiniCardComponent,
    VideoPlayerComponent,
    FaqDropdownComponent,
    DragScrollComponent,
    FormFieldComponent,
    RpChipsComponent,
    SafePipe,
    PaymentSuccessComponent,
    HomeTestimonialsComponent,
    // HomeFaqsComponent,
    TeamComponent,
    JourneyComponent, 
    NewsComponent,
    LinkCardComponent,
    SponsorsComponent,
    CardIconHeaderComponent,
    CardPriceComponent,
    TestimonialSectionCardComponent,
    PanelistComponent
    
  ],
  imports: [
    CommonModule,
    ButtonsModule,
  ],
  exports:[

    //insert components here
    
    TestimonialCardComponent,
    HomeTestimonialsComponent,
    // HomeFaqsComponent,
    ProfileCardComponent,
    ProjectCardComponent,
    OrgCardComponent,
    ProfileMiniCardComponent,
    VideoPlayerComponent,
    FaqDropdownComponent,
    DragScrollComponent,
    FormFieldComponent,
    RpChipsComponent,
    PaymentSuccessComponent,
    TeamComponent,
    JourneyComponent,
    NewsComponent,
    LinkCardComponent,
    SponsorsComponent,
    CardIconHeaderComponent,
    CardPriceComponent,
    TestimonialSectionCardComponent,
    PanelistComponent,

    //insert modules here
    ButtonsModule,
    
    SafePipe,
  ]
})
export class SharedModuleNew { }
