import { Component } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { ProjectService } from '../projectservice';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'contact',
    styleUrls: ['./contact.css'],
    templateUrl: './contact.html'
})

export class ContactPage {
    checkBoxValue: boolean = true;
    success: boolean = false;
    fail: boolean = false;
    constructor(private http: Http, public service: ProjectService, private title: Title) { }

    ngOnInit(){
        // this.title.setTitle("Contact");
    }

    onSubmit(firstname, lastname, email, website, message) {
        let obj = {
            'first_name': firstname,
            'last_name': lastname,
            'email': email,
            'website': website,
            'message': message,
            'subscribe': this.checkBoxValue ? "True" : "False",
        }
        this.service.contactSubmit(obj).then(res => {
           console.log(res);
            this.success = true;
        });
    }

    check(val){
        this.checkBoxValue = val;
    }
}