import {
  Component,
  NgZone,
  ViewContainerRef, ElementRef, ChangeDetectorRef, ViewEncapsulation, PLATFORM_ID, Inject
} from '@angular/core';

import {
  trigger,
  state,
  style,
  animate,
  transition,
  keyframes
} from '@angular/animations'

// import {
//   MapsAPILoader
// } from 'angular2-google-maps/core';

// import * as MarkerClusterer from 'node-js-marker-clusterer';

// import { MdSnackBar, MdSnackBarConfig } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';

import { ProjectService } from './../projectservice';

import * as $ from 'jquery';
import * as _ from 'lodash';

import { scrollTo } from 'ng2-utils';
import { Ng2ScrollableDirective } from 'ng2-scrollable/dist/ng2-scrollable.directive';
import { Ng2ScrollableModule } from 'ng2-scrollable';
import { isPlatformBrowser } from '@angular/common';

declare var google: any;

@Component({
  selector: 'my-projectlist',
  templateUrl: './projectlist.html',
  styleUrls: ['./projectlist.css'],
  animations: [
    trigger('fadeInOut', [
      transition('void => *', [
        style({
          left: -408
        }), //style only for transition transition (after transiton it removes)
        animate(200, style({
          left: 0
        })) // the new state of the transition(after transiton it removes)
      ]),
      transition('* => void', [
        style({
          left: 0
        }),
        animate(200, style({
          left: -408
        })) // the new state of the transition(after transiton it removes)
      ])
    ]),
    trigger('LegendFlyout', [
      transition('void => *', [
        style({
          right: -200
        }), //style only for transition transition (after transiton it removes)
        animate(200, style({
          right: 0
        })) // the new state of the transition(after transiton it removes)
      ]),
      transition('* => void', [
        style({
          right: 0
        }),
        animate(200, style({
          right: -200
        })) // the new state of the transition(after transiton it removes)
      ])
    ])
  ],
  encapsulation: ViewEncapsulation.Emulated
})
export class ProjectListComponent {
  lat: any = 37.77493;
  lng: any = -122.419416;
  divcounter: number = 1;
  increment: number = 0;
  show: boolean = false;
  conditionExpression: number = 3;

  listcolletion: ListObject[] = [];

  lngt: any;
  coobj: any;
  showSection: boolean = false;
  zoom: number = 8;
  name: any;
  type: any;
  status: any;
  subtype: any;
  dummydata: any;
  togglesection: any = true;
  location: any = "";
  markers: any = [];
  // markers1: marker[] = [];
  // markers2: marker[] = [];
  prevURL: string;
  nextURL: string;
  menu1: boolean = false;
  legend: boolean = true;
  loading: boolean = true;
  northWest: any;
  southEast: any;
  searchAsMapMoved: boolean = true;
  noProjects: boolean = false;
  url: string = this.service.url;
  url1: string = this.url + "/api-v1/projects/list/?page=1&format=json";
  url2: string = this.url + "/api-v1/projects/map/?in_bbox=";

  constructor(
    // private _loader: MapsAPILoader,
    private zone: NgZone,
    public service: ProjectService,
    private router: Router,
    // public snackBar: MdSnackBar,
    private titleService: Title,
    public viewContainerRef: ViewContainerRef,
    private cd: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private platform: any,
    private meta: Meta,
    private title: Title
  ) { }

  GoToNextPage(nextURL) {
    this.listcolletion = [];
    this.getProjects(nextURL);
  }

  GoToPrevPage(prevURL) {
    this.listcolletion = [];
    this.getProjects(prevURL);
  }

  //new api for projects
  getProjects(url) {
    this.noProjects = false;
    this.cd.detectChanges();
    this.loading = true;
    this.cd.detectChanges();
    this.service.getProjectList(url)
      .subscribe(result => {
        this.cd.markForCheck();
        //console.log(result);
        this.cd.detectChanges();
        this.coobj = result['features'];
        // this.nextURL = result.next;
        // if (this.nextURL != null) {
        //   if (this.nextURL.indexOf('https') == -1)
        //     this.nextURL = this.nextURL.replace('http', 'https');
        // }
        // this.prevURL = result.previous;
        // if (this.prevURL != null) {
        //   if (this.prevURL.indexOf('https') == -1)
        //     this.prevURL = this.prevURL.replace('http', 'https');
        // }
        console.log(this.coobj);
        this.listcolletion = [];

        for (var i = 0; i < this.coobj.length; i++) {
          try {
            if (this.coobj[i].properties.is_published) {
              this.listcolletion.push({
                project_id: this.coobj[i].id,
                name: this.coobj[i].properties.project_name ? this.coobj[i].properties.project_name : '',
                desc: this.coobj[i].properties.project_description ? this.coobj[i].properties.project_description : '',
                address: this.coobj[i].properties.project_location ? this.coobj[i].properties.project_location : '',
                lat: this.coobj[i].geometry.coordinates[1],
                lng: this.coobj[i].geometry.coordinates[0],
                imageurl: !_.isEmpty(this.coobj[i].properties.project_images) ? this.coobj[i].properties.project_images[0].image : '/assets/images/no-image.jpg',
                status: this.coobj[i].properties.project_status ? this.coobj[i].properties.project_status : '',
                draggable: false,
                project_name_slug: this.coobj[i].properties.project_name_slug

              });
            }
          }
          catch (e) { console.log('fata', e) }
        }

        this.loading = false;

        this.zone.run(() => {
          this.listcolletion = this.listcolletion;
          if (this.listcolletion.length == 0) {
            this.noProjects = true;
          }
        });
        if (isPlatformBrowser(this.platform)) {
          this.initMarkers();
        }

      });
  }


  //old api for projects
  getProjects1(url) {
    // this.loading = true;
    // this.service.getProjectList(url)
    //   .then(result => {
    //     console.log(result);
    //     this.loading = false;
    //     this.coobj = result['results'];
    //     this.nextURL = result['next'];
    //     if (this.nextURL != null) {
    //       if (this.nextURL.indexOf('https') == -1)
    //         this.nextURL = this.nextURL.replace('http', 'https');
    //     }
    //     this.prevURL = result['previous'];
    //     if (this.prevURL != null) {
    //       if (this.prevURL.indexOf('https') == -1)
    //         this.prevURL = this.prevURL.replace('http', 'https');
    //     }
    //     console.log(this.coobj);

    //     for (var i = 0; i < this.coobj.length; i++) {

    //       try {

    //         this.listcolletion.push({
    //           project_id: this.coobj[i].project_id,
    //           name: this.coobj[i].project_name,

    //           desc: this.coobj[i].project_description,
    //           address: this.coobj[i].project_location,
    //           lat: this.coobj[i].poly.coordinates[1],
    //           lng: this.coobj[i].poly.coordinates[0],
    //           imageurl: this.coobj[i].project_images[0] ? this.coobj[i].project_images[0].image : '',
    //           status: this.coobj[i].project_status ? this.coobj[i].project_status : '',
    //           draggable: false,
    //           project_name_slug: ''
    //         });



    //       }
    //       catch (Error) {
    //         console.log(Error);
    //       }

    //     }

    //     this.initMarkers();

    //   });
  }

  arrayOfImages: any = [];
  base64_blue: any = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAABGdBTUEAALGPC/xhBQAAAbxJREFUOBGtlD9PwmAQxq+lga3AANEUNjsYWYDBwYhG2R1d3PzzDfwUfgPRSRdGdzQR48AALBiHGmMCjQZM+DOYQCB4T8MRRKON6SUvb0p/z9Pre3dV6IeoVCrbw+FwV1GUDN82Jog9Ho+LmqblU6nU9bxMmf2jWq2abJALBAIbkUiEgsEg+f1+BxkMBtTtdqnValG/379lw8NkMmmJfmpULpfX+YlXsVgsHI1GibMR5svODDWbTWo0Gm1mdtLp9B0Ah0Ymo9GoZJpmWNd1AnxWfKJzXg92xzFaMUK0n1miA154SK/XI8uy2j6fbxWZaaDwOvF43DF57XzQ3uk93Ty+OQbyU3p+J6x86YUujtZoMaQTsq/X6zlmNhUcLL9vIZFIOJrsSeGbiZjJvrW8QIXjrHNZq9WQSFZFdXCwSBevM5+JiGd3MGChgRYeKl9kUB0EzsRtCAstPFQWGlJiOVg3ZsJOtAaMPAkY2Wg2BErsNoSdaG0VbY+ORaBP3Iaw0MJDxeyg7dGEaDaU9q8AAxYaaOGhYgAxO2h7lPOSm+03M9wDAxYaaOHh2YhMJ9OToZUz8eQzImbY//Nh+wT2Zhrse/mdSwAAAABJRU5ErkJggg==';

  base64_lightblue: any = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAABGdBTUEAALGPC/xhBQAAAdNJREFUOBGtVLFOwlAUvS1NCQyQSmAQSFwaHVgKg5No1A0TR7/AD3DXyQ/wA/gCRxPZlESdGKALCZrGxARwKEFSBgy1Db7zwiOIRhvDHfrS9pzz7rv33CfRD9FoNPY8zzuSJKnIfqenkO5kMrlXFOUyn8/fLtKk+Q+maepMoBwOh7eTySTF43FSVZVDXNclx3Go1+vReDy+Y4LHhmFYgj8TqtfrW2zHq0wmo6VSKWLZCMyXlWHItm3qdDoDhjksFAoPAHA0MvF9v6bruhaLxQjg68cRVZ5G9PLmcaG1FYVK61E62IjyTYbDIVmWNQiFQpvITAEKx8lms1ykP/LpvDog89XlAuLRsj+oZTtUfX6n012NEmxDZN9ut8sMsyOhsOy8N7lcjnNOKv1vIkJMrMaqShelBH9tNptIZF9Gd1BY1ATHWcxEkOdXYIAFB1xoyOyliO4gUJOgIbDgQkNmxLRosShsEDGBnXLTEFpKQKgLsyHQ4qAhsFNuV4bt4VgEfBI0BBZcaMiYHdgeJoTZ0Nq/AhhgwQEXGjIGELMD26OdZ8xsv4nhHzDAggMuNHhRMIBsdmqRSESDY2G2ICOCeQMXJ5hN5lKGVtRkKdeIEMP6n4vtE8INFHnxkYyfAAAAAElFTkSuQmCC';

  base64_orange: any = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAABGdBTUEAALGPC/xhBQAAAdRJREFUOBGtVMFKAlEUvW8cFDdjIgqhQptx5SJ10SoLCqpVy76gb/Ab+ga/oGWrCgrKVi7UFi7CWRSoBFqYLgJFed3znDdYhkl4YXwzzjnn3XfvuSPol6hWq3vj8fhECJHn13EX0pZSlkzTPM9ms7c/aWL2j1qtZrNAMRAI7ESjUQqFQuT3+xVkNBpRv9+nbrdLw+HwngVPM5mMo/meUKVS2eYdLxKJRDgWixFnozHfVsZQp9OhVqvVY8xxLpd7AEChkclkMinbth22LIsApsYlycYV0cfLVGhtg0TqkCh1pDYZDAbkOE7P5/NtITMTKBwnmUxORT7fSZbOiF4fpwL69+2JJF/0fEeUL5BlRQjZN5vNIkN2BQrL571Jp9OKIq8L8yJaTK/rmyQOeDOOer2ORPYNdAeFVTXh48xlosmzK7JlLDjgQsPghzy6g1A1mSUsuNdYcKFhMDauW+wVdoGA98ptgsuNQ2glAaE2zKaCW7x0uFiX2zZgezgWoXyypJLGggsNA7MD2ysTstmIW/tnAMNYcMCFhoEBxOzA9minYLMtFIOHGAMsOOBCQzkbA8izUw4Gg+zuCBHMtsSIYN7AxQm8yVzJ0OqarOQzosWw/ufD9gVk5gZICTqPEAAAAABJRU5ErkJggg==';

  base64_purple: any = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAABGdBTUEAALGPC/xhBQAAAc1JREFUOBGtVDFPwlAQvpYG0oE2REtigEFNEwcWYCAOoonuji6uLv4gF1YXR3cd1MEwQBcGk8YYA8SEqqQQQqgQvO+FRxCNNoZL2pe23/f13t13T6Efolar7Y9GoyNFUUr8OTWFtCaTya2maRf5fP56kabMv3Acx2aBciwW27Usi0zTpGg0KiBBEJDv++R5Hg2HwxsWPMnlcq7kz4Sq1eoO//EynU4nkskkcTYS82VlDLXbbWo2mx3GHBYKhTsABBqZjMfjim3bCcMwCGDv/pW8yhsNXgZCSF/TySqukLW9Kn7S7XbJdd1OJBIpIjMNKGwnk8kIkcD/oMfzJ+q5PSEgb/3nPuF6dzq0cbxOhmkQsm80GmXG7CkoLO/3KpvNCs7DmftNRIrJNW7HaevUFo/1eh2JHKjoDgqLmmA7i5lI8vwKDLDggAsNlR9K6A4CNQkbEgsuNFQmpmSLZWHDiEnslJuC0FICQi2YDYEWhw2JnXJbKmwPxyLgk7AhseBCQ8XswPYwIcyG1v4VwAArjMtcaKgYQMwObI92brLZfhPDN2CABQdcaAhnYwB5diq6rifgWJgtzIhg3sDFDmaTuZShlTVZyjEixbD+52D7BCqeFBEOz2hhAAAAAElFTkSuQmCC';

  base64_red: any = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAABGdBTUEAALGPC/xhBQAAAbVJREFUOBGtVD1PwlAUva80sBUZYIGOHUy6AIOTYJTd0V+gbrq5Glc3R8MvcHRHEzAx0QRYmjg0cWm7wICwQSD1nkcfQTTamJ6kfWl7zul99+MJ+gG9Xu9gPp8fCSFq/LkYUYIwDDu6rt9VKpWHTZlYf9Hv9y02aGYymXo+n6dsNkvpdFpSZrMZjcdjGg6HNJ1O22x4XC6XXaVfGXW73V3+432pVMoVCgXiaBTny8ocGgwG5Pv+iDmH1Wr1CQTJRiSLxeLFsqycYRgEsmi1iR47RF6wNDJ5h/s1Cht1+ZPJZEKu645SqdQOItPBwnZM01yajD5I3NwSOW9LA3V334n4Es+vFJ6dkJHbIkTveV6TKXsCieX9tmzblhJxdf3dRJmp1d6m8PJCPjmOg0AaGqqDxCIncjubkSjx+soccKGBFh4aP9RQHQnkJC4iLrTw0FhXVCVeJTaOWVSESFuEUSKAUYBmk0CJ4yLiRtpAQ9ujYyW4T2Ij4kILDw2zg7ZHE6LZiEv7J1B+5kIDLTw0DCBmB22Pcobnp7+bwYQ54EIDLTwSG5HVZCYytConiRwjygzrfw62TyT0DXH07icAAAAAAElFTkSuQmCC';

  ngOnInit() {
    this.title.setTitle('Map of Engineering Projects' + ' | ' + this.service.project_name);
    this.meta.updateTag({ property: 'og:title', content: 'Map of Engineering Projects' + ' | ' + this.service.project_name });
    this.meta.updateTag({ property: 'og:description', content: 'Find an engineering or science project near you or across the world.' });
    this.meta.updateTag({ name: 'description', content: 'Find an engineering or science project near you or across the world.' });
    this.toggleList();
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platform)) {
      this.initMap();
    }
  }

  toggleList() {
    this.menu1 = !this.menu1;
    if (this.menu1) {
      document.getElementById("map").style.marginLeft = "375px";
      document.getElementById("dontsee").style.marginLeft = "375px";
    } else {
      document.getElementById("map").style.marginLeft = "0px";
      document.getElementById("dontsee").style.marginLeft = "0px";
    }
  }

  toggleList1() {
    //console.log(this.menu1);
    this.legend = this.legend === false ? true : false;
  }

  map: any;
  placeChanged: boolean = false;

  initMap() {
    setTimeout(() => {
      var myLatLng;
      console.log('center1', this.service.newCenter);

      if (!this.service.newCenter) {
        myLatLng = { lat: this.lat, lng: this.lng };
      } else {
        myLatLng = this.service.newCenter;
        this.zoom = this.service.newZoom;
      }
      this.map = new google.maps.Map(document.getElementById('map'), {
        center: myLatLng,
        zoom: this.zoom,
        zoomControl: true,
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        zoomControlOptions: {
          position: google.maps.ControlPosition.RIGHT_BOTTOM,
          style: google.maps.ZoomControlStyle.DEFAULT

        }
      });

      new google.maps.event.addListenerOnce(this.map, 'bounds_changed', () => {
        this.getBoundCoordinates();
      });

      new google.maps.event.addListener(this.map, 'dragend', () => {
        if (this.searchAsMapMoved) {
          this.getBoundCoordinates();
        }
        this.service.newCenter = this.map.getCenter();
        this.service.newZoom = this.map.getZoom();
        console.log('center', this.service.newCenter);
      });

      new google.maps.event.addListener(this.map, 'zoom_changed', () => {
        if (!this.placeChanged) {
          this.getBoundCoordinates();
        }
        this.service.newCenter = this.map.getCenter();
        this.service.newZoom = this.map.getZoom();
      });

      var input = document.getElementById('autocompleteinput');
      var autocomplete = new google.maps.places.Autocomplete(input);

      // Bind the map's bounds (viewport) property to the autocomplete object,
      // so that the autocomplete requests use the current map bounds for the
      // bounds option in the request.
      autocomplete.bindTo('bounds', this.map);

      autocomplete.addListener('place_changed', () => {
        this.placeChanged = true;
        console.log(input['value']);
        this.service.sendEvent('search', 'search location on map view page', 'searched location: ' + input['value']);
        var place = autocomplete.getPlace();
        if (!place.geometry) {
          // User entered the name of a Place that was not suggested and
          // pressed the Enter key, or the Place Details request failed.
          //window.alert("No details available for '" + place.name + "'");
          this.service.sendAlert('Error', "No details available for" + place.name + "", 0);
          return;
        }

        // If the place has a geometry, then present it on a map.
        if (place.geometry.viewport) {
          this.map.fitBounds(place.geometry.viewport);
        } else {
          this.map.setCenter(place.geometry.location);
          this.map.setZoom(17);  // Why 17? Because it looks good.
        }
        this.getBoundCoordinates();
        this.placeChanged = false;
      });

    }, 2000);
  }

  getBoundCoordinates() {
    this.listcolletion = [];
    let northEastLat = this.map.getBounds().getNorthEast().lat();
    let northEastLng = this.map.getBounds().getNorthEast().lng();
    let southWestLat = this.map.getBounds().getSouthWest().lat();
    let southWestLng = this.map.getBounds().getSouthWest().lng();
    this.northWest = southWestLng + "," + northEastLat;
    this.southEast = northEastLng + "," + southWestLat;
    console.log('mapBounds', this.southEast + "," + this.northWest);
    this.getProjects(this.url2 + this.southEast + "," + this.northWest);
  }

  initMarkers() {
    // for (var i = 0; i < this.markers.length; i++) {
    //   this.markers[i].setMap(null);
    // }
    // this.markers = [];

    // Create a marker and set its position.
    // for (var i = 0; i < this.listcolletion.length; i++) {
    var markers11 = this.listcolletion.map((location, i) => {
      var markersCoordinates = { lat: this.listcolletion[i].lat, lng: this.listcolletion[i].lng };
      var color = this.getColorStatus(this.listcolletion[i].status);
      //console.log('color', color);
      if (!color) {
        color = this.base64_red;
      }

      // var image1 = new google.maps.MarkerImage(
      //   'assets/images/' + color + '.png',
      //   new google.maps.Size(71, 71),
      //   new google.maps.Point(0, 0),
      //   new google.maps.Point(17, 34),
      //   new google.maps.Size(25, 25));

      //   var icon = {
      //     url: "assets/images/markers/Oval-lightblue.png", // url
      //     // scaledSize: new google.maps.Size(50, 50), // scaled size
      //     // origin: new google.maps.Point(0,0), // origin
      //     // anchor: new google.maps.Point(0, 0) // anchor
      // };

      var marker = new google.maps.Marker({
        map: this.map,
        position: markersCoordinates,
        icon: {
          url: color
        }
        //icon: image1
      });
      // this.markers.push(marker);
      this.zone.run(() => {

        var contentString =
          '<a href="/project/' + this.listcolletion[i].project_name_slug + '" id="infowindow-content" style="color: black; cursor: pointer;text-align: center; font-family: OpenSans-Regular !important;">' +
          '<div id="place-name" style="font-size: 16px; font-weight: bold;">' + this.listcolletion[i].name + '</div>' +
          '<div id="place-address">' + this.listcolletion[i].address + '</div>' +
          '</a>';

        var infowindow = new google.maps.InfoWindow();
        google.maps.event.addListener(infowindow, 'closeclick', () => {
          document.getElementById('list' + this.lastindex).style.backgroundColor = 'white';
        });

        //Attach click event to the marker.
        ((marker) => {
          google.maps.event.addListener(marker, "click", (e) => {
            this.scrollTo('#list' + i, '#v-scrollable', 'vertical');
            //Wrap the content inside an HTML DIV in order to set height and width of InfoWindow.
            $(".gm-style-iw").next().click();
            infowindow.setContent(contentString);
            infowindow.open(this.map, marker);
            if (this.lastindex == 'start') {
              this.lastindex = i;
              document.getElementById('list' + i).style.backgroundColor = 'rgba(0, 0, 0, 0.07)';
            } else {
              document.getElementById('list' + this.lastindex).style.backgroundColor = 'white';
              document.getElementById('list' + i).style.backgroundColor = 'rgba(0, 0, 0, 0.07)';
              this.lastindex = i;
            }
          });
        })(marker);
      });
      return marker;
    });
    var mcOptions = {
      gridSize: 50, maxZoom: 15,
      styles: [{
        textColor: 'black',
        height: 66,
        url: "assets/images/cluster/m3.png",
        width: 66
      }]
    };

    // var mc = new MarkerClusterer(this.map, markers11, mcOptions);

    // google.maps.event.addListener(mc, 'clusterclick', (cluster) => {
    //   this.loading = true;
    //   setTimeout(() => {
    //     this.getBoundCoordinates();
    //   }, 1000);
    // });

  }

  highlightedMarker: any = []

  highlightPin(i) {
    this.removeHighlightedMarker();
    let markersCoordinates = { lat: this.listcolletion[i].lat, lng: this.listcolletion[i].lng };
    var marker = new google.maps.Marker({
      map: this.map,
      position: markersCoordinates
    });
    this.highlightedMarker.push(marker);
  }

  removeHighlightedMarker() {
    for (let i = 0; i < this.highlightedMarker.length; i++) {
      this.highlightedMarker[i].setMap(null)
    }
  }

  scrollTo(selector, parentSelector, horizontal) {
    var selected = $('#v-scrollable ' + selector);
    // Scroll container to offset of the selected div
    // selected.parent().parent().scrollTop(selected[0].offsetTop);
    $('#v-scrollable').animate({ scrollTop: selected[0].offsetTop }, 1000);
    // Ng2ScrollableDirective.scrollTo(
    //   selector,       // scroll to this
    //   parentSelector, // scroll within (null if window scrolling)
    //   horizontal,     // is it horizontal scrolling
    //   0               // distance from top or left
    // );
  }

  scrollEvent(e) {
    console.log('e', e);
  }

  colorCode: any;
  lastindex: any = 'start';
  getColorStatus(status: any) {
    switch (status) {
      case "Study/Design Phase":
        return this.base64_purple;
      case "Under Construction":
        return this.base64_orange;
      case "Complete":
        return this.base64_blue;
      case "Operation & Maintenance":
        return this.base64_lightblue;
      case "":
        return this.base64_red;
    }
  }

  GoToDetailPage(project_name_slug: string) {
    //let win = window.open();
    this.router.navigate(['/project', project_name_slug]);
  }
}


// interface marker {
//   lat: string;
//   lng: string;
//   label: string;
//   name: string;
//   type: string;
//   subtype: string;
//   status: string;
//   draggable: boolean;
// }


interface ListObject {
  project_id: string;
  name: string;
  desc: string;
  address: string;
  lat: string;
  lng: string;
  status: string;
  imageurl: string;
  draggable: boolean;
  project_name_slug: string;
}