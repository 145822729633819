import { ChangeDetectorRef, Component } from '@angular/core';
import { Title, DomSanitizer, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CookieService } from 'angular2-cookie/core';
import * as _ from 'lodash';
import { ProjectService } from './../projectservice';
import { AlertCComponent } from '../alert-c/alert-c.component';
//import { AlertCComponent } from ' '

@Component({
  selector: 'securelogin',
  styleUrls : [ 'securelogin.component.scss' ],
  templateUrl: './securelogin.html'
})

export class Login {

  section: any = 'login';
  loading: boolean = false;
  signupClicked: boolean = false;
  loginClicked: boolean = false;
  emailForgot: any;
  signupObject: userSignup = {
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    confirm_password: ''
  };
  loginObject: userLogin = {
    email: '',
    password: ''
  }

  //obj: AlertCComponent = new AlertCComponent('asda');
  //do all this.router.navigate(['/home']) to empty to remove login to app restriction
  constructor(public service: ProjectService, private cookie: CookieService, private cd: ChangeDetectorRef, private titleService: Title, private router: Router, public _sanitizer: DomSanitizer, private meta: Meta) {
    if (localStorage.getItem('authtoken')) {
      this.router.navigate(['']);
    }
  }

  linkedinLogin() {
    // this.service.getAccessForLinkedin(this.linkedinObject).then(res => {
    //   console.log(res);
    // });
// console.log('https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id='+this.service.linkedinObject.client_id+'&redirect_uri='+this.service.linkedinObject.redirectURI+'&state=c7RFX4w8PNiLA2Yxq1mq8ASyZocyKW&scope='+this.service.linkedinObject.scope);
    window.open('https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id='+this.service.linkedinObject.client_id+'&redirect_uri='+this.service.linkedinObject.redirectURI+'&state=c7RFX4w8PNiLA2Yxq1mq8ASyZocyKW&scope='+this.service.linkedinObject.scope,'_self');
  }

  sendResetPwdEmail() {
    let obj = {
      "email": this.emailForgot.toLowerCase()
    }

    this.service.resetPasswordEmail(obj).subscribe(
      data => {

        console.log(data.json());
        this.service.sendAlert('', 'Password reset e-mail has been sent.', 2);
      },
      error => {
        console.log(error.status, typeof(error.status));
        if (error.status === 500) {
          this.service.sendAlert('', 'There is no response from server. Please try again later.', 3);
        } else if (error.status === 404) {
          this.service.sendAlert('', 'There is no username associated with this email. Please join the App or contact password@RePictureEngineering.com with any questions.', 3);
        }
      }
    );
  }

  ngOnInit() {
    // this.titleService.setTitle("Login");
    this.meta.updateTag({ property: 'og:description', content: 'Join and learn about engineering and science careers.' });
    this.meta.updateTag({ name: 'description', content: 'Join and learn about engineering and science careers.' });
  }



  login() {
    if (!this.loginObject.email.trim() || !this.loginObject.password.trim()) {
      return;
    }
    if (this.validateEmail(this.loginObject.email)) {

      this.service.sendAlert('Error', 'You have entered an invalid email address!', 0);
      //alert("You have entered an invalid email address!")
      // const obj = new AlertCComponent('asda');
      //   const alert = new Alert(AlertType.INFO, 'You have entered an invalid email address!');

      // this.alert.alert(alert);



      return false;
    }
    this.loginClicked = true;
    //this.loading = true;
    this.service.doLogin(this.loginObject.email.toLowerCase(), this.loginObject.password).then(result => {
      if (result['token']) {
        this.service.sendEvent('click', 'login', 'success with email: ' + this.loginObject.email);
        this.service.getPeopleDetail(localStorage.getItem("full_name_slug")).subscribe(res => {
          if(res['is_superuser']) {
            this.service.isAdmin = true;
          } else {
            this.service.isAdmin = false;
          }
          // if (!res['user_agreement']) {
          //   this.router.navigate(['/agreement']);
          // } else {
            //make below [''] to remove login to app restriction
            this.router.navigate(['']);
          // }
        });
      } else {
        this.service.sendEvent('click', 'login', 'fail with email: ' + this.loginObject.email);
        this.loginClicked = false;
        //this.loading = false;
        let varx = "";

        _.forEach(<any>result, function (value, key) {

          varx = varx + value[0];

        });
        //  alert(varx);
        this.service.sendAlert('Error', varx, 0);

      }
    });
  }




  validateEmail(email) {
    if (!/^[\w._-]+[+]?[\w._-]+@[\w.-]+\.[a-zA-Z]{2,6}$/.test(email)) {
      return true;
    }
  }
}

interface userSignup {
  first_name: any;
  last_name: any;
  email: any;
  password: any;
  confirm_password: any;
}

interface userLogin {
  email: any;
  password: any;
}
