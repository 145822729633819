import { OrganizationCard } from './../new-repicture/org-card/organization';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from "rxjs";
import { environment } from "src/environments/environment";
import { ProjectCardModel } from "../new-repicture/project-card/ProjectCardModel";
import { ProfileCard } from "../new-repicture/profile-card/profile-card";
import { map, reduce } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ExplorerService {


  projectUrl = environment.baseurl + "/api-v1/projects/redis-list/"
  projectFilterURL = environment.baseurl + "/api-v1/projects/filter/";

  peopleUrl = environment.baseurl + "/api-v1/projects/redis-user/"
  peopleFilterURL = environment.baseurl + "/api-v1/projects/peoplefilter/";

  orgUrl = environment.baseurl + "/api-v1/organizations/listings/";
  orgFilterUrl = environment.baseurl + "/api-v1/organizations/filter/";

  projectNextUrl: any;
  peopleNextUrl: any;
  orgNextUrl: any;

  exploreProjectsSubject = new BehaviorSubject<ProjectCardModel[]>(null);
  explorePeopleSubject = new BehaviorSubject<ProfileCard[]>(null);
  exploreOrgSubject = new BehaviorSubject<OrganizationCard[]>(null);

  exploreProjectsData$ = this.exploreProjectsSubject.asObservable();
  explorePeopleData$ = this.explorePeopleSubject.asObservable();
  exploreOrgData$ = this.exploreOrgSubject.asObservable();

  constructor(private httpClient: HttpClient) { }

  getProjects(url) {
    this.exploreProjectsSubject.next([]);

    return this.httpClient
      .get<ProjectCardModel[]>(
        url
      )
      .pipe(
        map((data: any) => {

          console.log(data.next);

          this.projectNextUrl = data.next

          let projects = data.results.map((project: any) => {
            let result: ProjectCardModel = {
              image: project.project_media_thumbnail,
              title: project.project_name,
              location: project.shorter_location,
              // creator: project.created_by_name,
              slug: project.project_name_slug,

            };
            if (project.project_details && project.project_details.length > 0) {
              result.description = project.project_details[0].data
            }
            if (project.co_owners_data.length > 0) {
              console.log('mult co ow');
              var coOwnerObj = []
              project.co_owners_data.forEach(data => {
                console.log(data);
                coOwnerObj.push({ image: data.user_image, slug: data.slug })

              });
              result.profile = { co_owners: coOwnerObj }

            } else if (project.adopted_by_name) {
              result.profile = {
                image: project.adopted_by_image,
                name: project.adopted_by_name,
                role: project.adopted_by_designation,
                slug: project.adopted_by_slug
              }
            } else if(project.created_by_name){
              result.profile = {
                image: project.created_by_image,
                name: project.created_by_name,
                role: project.created_by_designation,
                slug: project.created_by_slug
              }
            }else{
              result.profile = {
                image: 'https://beta-api.repicture.com/media/CACHE/images/my-image_A8gkH4L/ca5ce66a0553ae778394aa5c492997d9.jpg',
                name: 'RePicture Team',
                role: '',
                slug: 'repicture-communications'
              }
            }
            return result;
          });
          console.log(projects);
          return projects;
        })
      )
      .subscribe(projects => {
        console.log(projects);

        this.exploreProjectsSubject.next(projects);
      })
  }

  loadMoreProjects() {
    console.log('loading more projects');
    if (this.projectNextUrl) {
      // this.getProjects(this.projectNextUrl);
      return this.httpClient
        .get<ProjectCardModel[]>(
          this.projectNextUrl
        )
        .pipe(
          map((data: any) => {
            console.log(data.next);

            this.projectNextUrl = data.next

            let projects = data.results.map((project: any) => {
              let result: ProjectCardModel = {
                image: project.project_media_thumbnail,
                title: project.project_name,
                location: project.shorter_location,
                // creator: project.created_by_name,
                slug: project.project_name_slug,
                // description: project.project_details[0].data,
              };
              if (project.project_details && project.project_details.length > 0) {
                result.description = project.project_details[0].data
              }
              if (project.co_owners_data.length > 0) {
                console.log('mult co ow');
                var coOwnerObj = []
                project.co_owners_data.forEach(data => {
                  console.log(data);
                  coOwnerObj.push({ image: data.user_image, slug: data.slug })
  
                });
                result.profile = { co_owners: coOwnerObj }
  
              } else if (project.adopted_by_name) {
                result.profile = {
                  image: project.adopted_by_image,
                  name: project.adopted_by_name,
                  role: project.adopted_by_designation,
                  slug: project.adopted_by_slug
                }
              } else if(project.created_by_name){
                result.profile = {
                  image: project.created_by_image,
                  name: project.created_by_name,
                  role: project.created_by_designation,
                  slug: project.created_by_slug
                }
              }else{
                result.profile = {
                  image: 'https://beta-api.repicture.com/media/CACHE/images/my-image_A8gkH4L/ca5ce66a0553ae778394aa5c492997d9.jpg',
                  name: 'RePicture Team',
                  role: '',
                  slug: 'repicture-communications'
                }
              }
              return result;
            });
            console.log(projects);
            return projects;
          })
        )
        .subscribe(projects => {
          console.log(projects);
          // this.exploreProjectsSubject.next(projects);
          this.exploreProjectsSubject.next(this.exploreProjectsSubject.getValue().concat(projects))
        })
    }
  }

  getPeople(url) {
    this.explorePeopleSubject.next([]);

    return this.httpClient
      .get(url)
      .pipe(
        map((data: any) => {
          this.peopleNextUrl = data.next

          let profiles = data.results.map((profile: any) => {
            let result: ProfileCard = {
              image: profile.profileImage_thumbnail_v1,
              name: profile.first_name + " " + profile.last_name,
              role: profile.user_designation,
              // major: profile.user_education[0].field_of_study,
              // school: profile.user_education[0].school,
              // year: profile.user_education[0].year,
              slug: profile.full_name_slug,
            };
            if (profile.user_education) {
              if (profile.user_education.length > 0) {
                if (profile.user_education[0].field_of_study) {
                  result.major = profile.user_education[0].field_of_study;
                }

                if (profile.user_education[0].school) {
                  result.school = profile.user_education[0].school;
                }

                if (profile.user_education[0].year) {
                  result.year = profile.user_education[0].year;
                }
              }
            }

            return result;
          });
          console.log(profiles);
          return profiles;
        })
      )
      .subscribe(people => {
        console.log(people);
        this.explorePeopleSubject.next(people);
        // this.explorePeopleSubject.next(this.explorePeopleSubject.getValue().concat(people));
      })
  }

  loadMorePeople() {
    console.log('loading more people');
    if (this.peopleNextUrl) {
      // this.getPeople(this.peopleNextUrl);
      return this.httpClient
        .get(this.peopleNextUrl)
        .pipe(
          map((data: any) => {
            this.peopleNextUrl = data.next

            let profiles = data.results.map((profile: any) => {
              let result: ProfileCard = {
                image: profile.profileImage_thumbnail_v1,
                name: profile.first_name + " " + profile.last_name,
                role: profile.user_designation,
                // major: profile.user_education[0].field_of_study,
                // school: profile.user_education[0].school,
                // year: profile.user_education[0].year,
                slug: profile.full_name_slug,
              };
              if (profile.user_education) {
                if (profile.user_education.length > 0) {
                  if (profile.user_education[0].field_of_study) {
                    result.major = profile.user_education[0].field_of_study;
                  }

                  if (profile.user_education[0].school) {
                    result.school = profile.user_education[0].school;
                  }

                  if (profile.user_education[0].year) {
                    result.year = profile.user_education[0].year;
                  }
                }
              }

              return result;
            });
            console.log(profiles);
            return profiles;
          })
        )
        .subscribe(people => {
          console.log(people);
          // this.explorePeopleSubject.next(people);
          this.explorePeopleSubject.next(this.explorePeopleSubject.getValue().concat(people));
        })
    }
  }

  getOrgs(url) {
    this.exploreOrgSubject.next([]);

    return this.httpClient
      .get(url)
      .pipe(
        map((data: any) => {
          this.orgNextUrl = data.next

          let orgsData = data.results.map((org: any) => {
            let result: OrganizationCard = {
              image: org.org_image_thumbnail,
              name: org.org_name,
              slug: org.org_name_slug,
            };

            return result;
          });
          console.log(orgsData);
          return orgsData;
        })
      )
      .subscribe(orgs => {
        console.log(orgs);
        this.exploreOrgSubject.next(orgs);
        // this.explorePeopleSubject.next(this.explorePeopleSubject.getValue().concat(people));
      })
  }

  loadMoreOrgs() {
    console.log('loading more org');
    if (this.orgNextUrl) {
      // this.getPeople(this.peopleNextUrl);
      return this.httpClient
        .get(this.orgNextUrl)
        .pipe(
          map((data: any) => {
            this.orgNextUrl = data.next

            let orgsData = data.results.map((org: any) => {
              let result: OrganizationCard = {
                image: org.org_image_thumbnail,
                name: org.org_name,
                slug: org.org_name_slug,
              };

              return result;
            });
            console.log(orgsData);
            return orgsData;
          })
        )
        .subscribe(orgs => {
          console.log(orgs);
          // this.explorePeopleSubject.next(people);
          this.exploreOrgSubject.next(this.exploreOrgSubject.getValue().concat(orgs));
        })
    }
  }

  resetData() {
    this.exploreProjectsSubject.next(null);
    this.explorePeopleSubject.next(null);
    this.exploreOrgSubject.next(null);
  }

}
