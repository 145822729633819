import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input,OnChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProjectService } from '../projectservice';

@Component({
  selector: 'app-projectsorder',
  templateUrl: './projectsorder.component.html',
  styleUrls: ['./projectsorder.component.scss']
})
export class ProjectsorderComponent implements OnChanges {

  // @Input('array1') array1 : any[]; 
  // @Input('array2') array2 : any[]; 
  // @Input('array3') array3 : any[]; 

  pr_user_ordering:any = []
  adopted_ordering:any = []
  adopted_with_others_ordering:any = []

  mainOrderingArray : any = []

  @Input('mainArray') mainArray? :any;

   dragDropPeopleProjectArray :any = [];
   dragDropCreatedProjectAdoptedArray :any = [];
   dragDropProjectAdoptedWithOthesArray :any = [];


  constructor(private service : ProjectService,private route : ActivatedRoute) { }

  ngOnChanges() { 
    console.log(this.mainArray);
    this.mainOrderingArray = []
    this.pr_user_ordering = []
    this.adopted_ordering = []
    this.adopted_with_others_ordering = []
    // console.log(this.array1,this.array2,this.array3);
    // this.dragDropPeopleProjectArray = this.array1
    // this.dragDropCreatedProjectAdoptedArray = this.array2
    // this.dragDropProjectAdoptedWithOthesArray = this.array3
    this.mainArray['adopted'].forEach(project => {
      this.adopted_ordering.push(project['project_id'])
    })
    this.mainArray['pr_user_ordering'].forEach(project => {
      this.pr_user_ordering.push(project['project_id'])
    })
    
    this.mainArray['adopted_with_others'].forEach(project => {
      this.adopted_with_others_ordering.push(project['project_id'])
    })

    this.mainOrderingArray = [{
      'pr_user_ordering':this.pr_user_ordering,
      'adopted':this.adopted_ordering,
      'adopted_with_others':this.adopted_with_others_ordering
    }]

    console.log(this.mainOrderingArray);


    // this.service.getPeopleProjects("prashant-waghe_1").then((res) => {

    //     console.log(res);
    //     this.deagDropPeopleProjectArray = res

    // })

    // this.service.getProjectsAdoptedWithOthers("prashant-waghe_1",true).then((res) => {
    //   console.log(res);
    //   this.deagDropProjectAdoptedArray = res
    // })
  }

  

  dropPeopleProject(event: CdkDragDrop<string[]>) {
    this.pr_user_ordering = []
    moveItemInArray(this.mainArray['pr_user_ordering'], event.previousIndex, event.currentIndex);
    this.mainArray['pr_user_ordering'].forEach(project => {
      this.pr_user_ordering.push(project['project_id'])
    })
    this.mainOrderingArray = [{
      'pr_user_ordering':this.pr_user_ordering,
      'adopted':this.adopted_ordering,
      'adopted_with_others':this.adopted_with_others_ordering
    }]
    console.log(this.mainOrderingArray);
  }
  dropProjectAdopted(event: CdkDragDrop<string[]>) {
    this.adopted_ordering = []
    moveItemInArray(this.mainArray['adopted'], event.previousIndex, event.currentIndex);
    this.mainArray['adopted'].forEach(project => {
      this.adopted_ordering.push(project['project_id'])
    })
    this.mainOrderingArray = [{
      'pr_user_ordering':this.pr_user_ordering,
      'adopted':this.adopted_ordering,
      'adopted_with_others':this.adopted_with_others_ordering
    }]
    console.log(this.mainOrderingArray);
  }
  dropProjectAdoptedWithOthers(event: CdkDragDrop<string[]>) {
    this.adopted_with_others_ordering = []

    moveItemInArray(this.mainArray['adopted_with_others'], event.previousIndex, event.currentIndex);
    this.mainArray['adopted_with_others'].forEach(project => {
      this.adopted_with_others_ordering.push(project['project_id'])
    })
    this.mainOrderingArray = [{
      'pr_user_ordering':this.pr_user_ordering,
      'adopted':this.adopted_ordering,
      'adopted_with_others':this.adopted_with_others_ordering
    }]
    console.log(this.mainOrderingArray);
  }


}
