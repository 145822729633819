/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./read-more.component";
var styles_ReadMoreComponent = ["div.collapsed[_ngcontent-%COMP%] {\n        line-height: 2.5ex;\n        height: 0ex;\n        overflow: hidden;\n      }\n\n      a[_ngcontent-%COMP%] {\n        cursor: pointer;\n        margin-left: 4px;\n      }"];
var RenderType_ReadMoreComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_ReadMoreComponent, data: {} });
export { RenderType_ReadMoreComponent as RenderType_ReadMoreComponent };
function View_ReadMoreComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.isCollapsed = !_co.isCollapsed) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["read more"]))], null, null); }
function View_ReadMoreComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.isCollapsed = !_co.isCollapsed) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["read less"]))], null, null); }
export function View_ReadMoreComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ReadMoreComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 4, "div", [["class", "text-left"]], [[2, "collapsed", null]], null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), i0.ɵncd(null, 0), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ReadMoreComponent_2)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isCollapsed; _ck(_v, 1, 0, currVal_0); var currVal_2 = !_co.isCollapsed; _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.isCollapsed; _ck(_v, 2, 0, currVal_1); }); }
export function View_ReadMoreComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-read-more", [], null, null, null, View_ReadMoreComponent_0, RenderType_ReadMoreComponent)), i0.ɵdid(1, 49152, null, 0, i2.ReadMoreComponent, [], null, null)], null, null); }
var ReadMoreComponentNgFactory = i0.ɵccf("app-read-more", i2.ReadMoreComponent, View_ReadMoreComponent_Host_0, { datatext: "datatext" }, {}, ["*"]);
export { ReadMoreComponentNgFactory as ReadMoreComponentNgFactory };
