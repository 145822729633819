<navbar [checkUserStateInput]="loginNow"></navbar>
<h2 *ngIf="!created" class="heading">Add Organization</h2>
<div *ngIf="!loginStatus" class="col-sm-8 col-sm-offset-2">
    <div class="text-center">
        <h4 style="color: gray;font-family: OpenSans-Semibold;font-size: 16px;">You must be logged in to add an organization profile.</h4>
        <input type="text" class="form-control" [(ngModel)]="loginObject.email" placeholder="Enter email" style="width: 30%; display: inline-block;" />
        <input type="password" class="form-control margin-left" [(ngModel)]="loginObject.password" placeholder="Enter password" style="width: 30%; display: inline-block;" />
        <button class="btn btn-default login margin-left" (click)="login()">Login</button>
    </div>
    <br>
    <br>
    <regForm></regForm>
</div>
<div *ngIf="loginStatus && !created" class="col-sm-6 col-sm-offset-3" style="text-align: center;">
    <div class="form-group">
        <input #name type="text" class="textbox form-control" id="exampleInputEmail1" placeholder="Enter organization name" style="color: black;">
    </div>
    <div class="form-group">
        <my-dropdown [defaultText]="'Organization role'" [myOptions]="companyRoleOptions" (selectedArray)="onChangedd($event)"></my-dropdown>
    </div>
    <div class="form-group" *ngIf="otherRoleDialog">
        <input #otherCategory [(ngModel)]="otherCat" type="text" class="form-control" id="otherCat" placeholder="Enter Other Category" [ngModelOptions]="{standalone: true}">
    </div>
    <br>
    <div class="checkboxdiv">
        <div>
            <p style="font-size: 16px;">Would you like to control the content of the profile created ?</p>
            <!--<br>-->
            <div>
                <label class="checkbox-inline" style="font-size: 14px; text-align:left;"><input type="checkbox" name="personType" [(ngModel)]="checked" />
        Yes! I verify that I'm a representative of this organization <br>and have the authorization to act on their behalf.</label>
            </div>
            <div class="form-group text-center" *ngIf="checked" style="margin-top: 20px; font-family: OpenSans-Regular;">
                <input #name type="text" class="textbox form-control" id="email" [(ngModel)]="email" placeholder="Enter your organization email" style="color: black;">
            </div>
            <div style="margin-top: 20px;">
                <button class="btn btn-default login" (click)="createCompany(name.value)">Create Organization</button>
            </div>
        </div>
    </div>
</div>
<div *ngIf="loginStatus && createdByRep" class="col-sm-10 col-sm-offset-1" style="text-align: center;margin-top: 80px;">
    <p style="padding: 15px; color: black;font-family: OpenSans-Regular;text-align:center; display: inline-block;margin-bottom: 30px;">
        A verification code has been sent to the email provided. Please enter the code to continue.
    </p>
    <div class="form-group text-center" *ngIf="checked" style="float: left; width: 100%; font-family: OpenSans-Regular;">
        <div class="col-sm-6 col-sm-offset-3">
            <input #name type="text" class="form-control" id="verify_code" [(ngModel)]="verify_code" placeholder="Enter code" style="color: black;width: 96px;display:inline-block;text-align:center;">
        </div>
    </div>
    <p class="text-center">
        <button class="btn btn-default login" (click)="verifyCode()">Verify code</button>
        <button class="btn btn-default" style="font-family: OpenSans-Semibold;margin-top: -2px;border: none;color: #b7b7b7;" (click)="cancel()">Cancel organization creation</button>
    </p>
</div>
<p *ngIf="loginStatus && createdByOther" style="font-family: OpenSans-Semibold;font-size: 20px;margin-top: 50px;text-align:center;">
    Congratulations! A profile has been created for {{ company_name }}. <a style="color: #4688F0;" routerLinkActive="active" routerLink="/companyprofile/{{org_slug}}">Click here</a> to view profile.
</p>
<div *ngIf="loginStatus && createdByOther" style="font-family: OpenSans-Semibold;" class="col-sm-8 col-sm-offset-2">
    <br><br>
    <p class="text-center" style="font-size: 16px;">
        Notify a representative of this organization
    </p>
    <!-- <br> -->
    <p class="text-center">
        <input type="email" class="form-control" placeholder="Enter email" [(ngModel)]="notify_email" style="width:35%; display: inline-block;" />
    </p>
    <p class="text-center">
        <label class="checkbox-inline"><input type="checkbox" name="personType" [(ngModel)]="notifySetting" />Send anonymous notification</label>
        <br>
        <button style="margin-top: 20px;" class="btn btn-default login" (click)="notify()">Notify</button>
    </p>
</div>
<p *ngIf="loginStatus && verified" style="font-family: OpenSans-Semibold;font-size: 20px;margin-top: 50px;text-align:center;">
    Congratulations! A profile has been created for {{ company_name }}. <a style="color: #4688F0;" routerLinkActive="active" routerLink="/companyprofile/{{org_slug}}">Click here</a> to view profile.
</p>
<div *ngIf="loginStatus && verified" style="font-family: OpenSans-Semibold; display: none;" class="col-sm-8 col-sm-offset-2">
    <br><br>
    <p class="text-center" style="font-size: 14px;">
        Watch this video to learn about how you can enhance your organization's profile by supporting career awareness and education.
    </p>
    <div class="video"></div>
</div>
<style>
    .margin-left {
        margin-left: 10px;
    }
    
    sup {
        color: red;
    }
    
    h5 {
        font-size: 16px;
        font-family: OpenSans-Regular;
        margin-top: 15px;
        font-style: italic;
    }
    
    .createOrganizationcontent {
        padding: 20px;
        background-color: lightgray;
        margin-top: 20px;
    }
    
    @font-face {
        font-family: OpenSans-Regular;
        src: url('/assets/fonts/new/opensans-regular-webfont.eot?#iefix') format('embedded-opentype'), /* IE */
        url('/assets/fonts/new/opensans-regular-webfont.ttf') format("truetype");
        /* non-IE */
    }
    
    @font-face {
        font-family: OpenSans-Light;
        src: url('/assets/fonts/new/opensans-light-webfont.eot?#iefix') format('embedded-opentype'), /* IE */
        url('/assets/fonts/new/opensans-light-webfont.ttf') format("truetype");
        /* non-IE */
    }
    
    @font-face {
        font-family: OpenSans-Bold;
        src: url('/assets/fonts/new/opensans-bold-webfont.eot?#iefix') format('embedded-opentype'), /* IE */
        url('/assets/fonts/new/opensans-bold-webfont.ttf') format("truetype");
        /* non-IE */
    }
    
    @font-face {
        font-family: OpenSans-ExtraBold;
        src: url('/assets/fonts/new/opensans-extrabold-webfont.eot?#iefix') format('embedded-opentype'), /* IE */
        url('/assets/fonts/new/opensans-extrabold-webfont.ttf') format("truetype");
        /* non-IE */
    }
    
    @font-face {
        font-family: OpenSans-Semibold;
        src: url('/assets/fonts/new/opensans-semibold-webfont.eot?#iefix') format('embedded-opentype'), /* IE */
        url('/assets/fonts/new/opensans-semibold-webfont.ttf') format("truetype");
        /* non-IE */
    }
    
    * {
        color: gray;
    }
    
    .form-group label {
        font-family: OpenSans-Semibold;
    }
    
    .video {
        height: 400px;
        border: 1px solid;
    }
    
    .checkboxdiv {
        text-align: center;
        font-family: OpenSans-Semibold;
    }
    
    .signupbox {
        background-color: #d6d6d6;
        text-align: center;
        padding: 18px;
    }
    
    .login {
        background-color: #ffe284;
        margin-top: -2px;
    }
    
    .heading {
        color: #757575;
        font-family: OpenSans-Regular !important;
        padding-bottom: 20px;
        margin-bottom: 20px;
        padding-left: 15px;
        padding-right: 15px;
        text-align: center;
        margin-top: 50px;
    }
    
    .textbox {
        display: inline-block;
        width: 50%;
    }
</style>