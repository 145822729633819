<div class="my-alert-center-style">
    <nac-alert-center [animation]="'fancy'" [htmlTextEnabled]="true"></nac-alert-center>
</div>
<div>
    <h1 class="title">Sign up for an account</h1>
    <div class="inputs-div">
        <div class="firstAndLastName">
            <app-form-field [label]="'First Name'">
                <input  [(ngModel)]="user.first_name" placeholder="First Name" autocomplete="off" appInput type="text" required />
            </app-form-field>
            <app-form-field [label]="'Last Name'">
                <input  [(ngModel)]="user.last_name" placeholder="Last Name" autocomplete="off" appInput type="text" required />
            </app-form-field>
        </div>
        <app-form-field [label]="'Email'">
            <input [(ngModel)]="user.email" placeholder="Enter Email" appInput type="text" required email invalidEmail/>
        </app-form-field>
        <app-form-field [label]="'Password'">
            <input  id="pwd"  [(ngModel)]="user.password" placeholder="Enter Password" autocomplete="off" appInput type="password" required pattern="(?=^.{6,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$"/>
        </app-form-field>
        <span class="gray" style="font-size: 12px; font-style: italic;"> 
            (Note: Password must be 6 or more characters and include at least one uppercase letter, one lowercase letter, and one number or special character)
        </span>
        <app-form-field [label]="'Re-Enter Password'">
            <input  id="pwd1"  [(ngModel)]="user.confirm_password" placeholder="Re-Enter Password" autocomplete="off" appInput type="password" required pattern="(?=^.{6,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$" />
        </app-form-field>
        <p *ngIf="forStudents">
            <select  [(ngModel)]="user.teaching_assistant" (change)="ta_changed($event)">
                <option value="">Select Professor or Teaching Assistant</option>
                <option *ngFor="let item of teachingAssistant" value="{{item.teacher}}">{{item.full_name}}</option>
            </select>
        </p>
        <div class="checkbox">
            <input [(ngModel)]="user.tac" type="checkbox" id="tac" name="tac">
            <p>By signing up you are verifying you are over 14 years old and agree to <a target="_blank" href="assets/pdfs/tou2020.pdf">RePicture’s terms</a>.</p>
        </div>
        <button (click)="signup()" rp-button type="primary" size="jumbo" style="width: 100%;">Sign up</button>
        <h3>Already have an account? <span [routerLink]="[ '/login' ]">Login</span></h3>
    </div>
</div>
<!-- <div style="max-width: 500px; margin-left: auto; margin-right: auto;">
    <div class="text-center">
        <h3 style="color: gray;font-family: OpenSans-Semibold;">Create a FREE account</h3>
        <h5 class="gray">Already have an account? <a routerLink="/login">Login</a></h5>
    </div>
    <h5
        style="width: 100%; text-align: center; border-bottom: 1px solid #9999996b; line-height: 0.1em; margin: 20px 0 20px; color: #999;">
        <span style="background: #fff; padding: 0 15px;">Create an
            account</span></h5> 
    <div class="form-fields">
        <p>
            <input type="email" class="form-control" placeholder="Enter email" [(ngModel)]="user.email" />
        </p>
        <p>
            <input id="pwd" type="password" class="form-control" placeholder="Enter password"
                [(ngModel)]="user.password" autocomplete="off"
                pattern="(?=^.{6,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$" />
        </p>
        <p style="margin: 0;">
            <input id="pwd1" type="password" class="form-control" placeholder="Enter confirm password"
                [(ngModel)]="user.confirm_password" autocomplete="off"
                pattern="(?=^.{6,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$" />
        </p>
        <div>
            <span class="gray" style="font-size: 12px; font-style: italic;"> 
                (Note: Password must be 6 or more characters and include at least one uppercase letter, one lowercase letter, and one number or special character)
            </span>
        </div>
        <div style="display: flex; justify-content: space-between;">
            <p style="width: 49%;">
                <input type="text" class="form-control" [(ngModel)]="user.first_name" placeholder="Enter first name" />
            </p>
            <p style="width: 49%;">
                <input type="text" class="form-control" placeholder="Enter last name (Optional)"
                    [(ngModel)]="user.last_name" />
            </p>
        </div>
        <p *ngIf="forStudents">
            <select class="form-control" [(ngModel)]="user.teaching_assistant" (change)="ta_changed($event)">
                <option value="">Select Professor or Teaching Assistant</option>
                <option *ngFor="let item of teachingAssistant" value="{{item.teacher}}">{{item.full_name}}</option>
            </select>
        </p>
       
        <p>
            <button class="btn btn-default login" (click)="signup()">Create account</button>
        </p>
        <h5
            style="width: 100%; text-align: center; border-bottom: 1px solid #9999996b; line-height: 0.1em; margin: 20px 0 20px; color: #999;">
            <span style="background: #fff; padding: 0 15px;">or sign up with</span></h5>
        <div style="margin-top: 20px; text-align: center;">
            <span (click)="linkedinLogin()"
                style="width: 35px; height: 35px; display: inline-block;background: #EDEEEE; border-radius: 50%; cursor: pointer;"><img
                    style="width: 19px; margin-top: 8px; background-color: white;"
                    src="/assets/images/linkedin_logo.png" /></span>
        </div>
        <p>
            <span class="gray" style="font-size: 13px;"> 
                By clicking “create account” or signing up, you verify that you are over 14 years old and you agree to the <a style="text-decoration: underline;" href="/assets/pdfs/Website Terms of Use_Dec 12 2020 join.pdf" target="_blank">Privacy and Terms of Use</a>. By signing in with LinkedIn, you agree to allow RePicture to pre-fill in certain LInkedIn information on your RePicture profile.
            </span>
        </p>
    </div>
</div> -->

<div id="data_terms" class="modal fade" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content" style="border-radius: 0px;">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <h4 class="modal-title">Account Setup and Data Terms</h4>
            </div>
            <div class="modal-body" [innerHTML]="data">
            </div>
            <div class="modal-footer">
                <button class="btn btn-default" data-dismiss="modal">OK</button>
            </div>
        </div>
    </div>
</div>
<div id="data_18" class="modal fade" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content" style="border-radius: 0px;">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <h4 class="modal-title">Information</h4>
            </div>
            <div class="modal-body">
                You must be 14 years or older to join. However, anyone can view the information on RePicture.com.
            </div>
            <div class="modal-footer">
                <button class="btn btn-default" data-dismiss="modal">OK</button>
            </div>
        </div>
    </div>
</div>
<!-- <style>
    .form-fields input {
        background-color: #edeeee;
    }

    .gray {
        color: gray;
    }

    .signupbox {
        background-color: #d6d6d6;
        text-align: center;
        padding: 18px;
    }

    .login {
        background-color: #7ac90a;
        margin-top: -2px;
        color: white;
        width: 100%;
        font-family: OpenSans-Semibold;
    }

    .link {
        color: #4688F0;
        text-align: center;
        cursor: pointer;
    }

    .modal-header {
        background-color: #ffe284;
    }
</style> -->