<div id="main" class="searchasmove1">
  <div (click)="toggleList1()" class="searchasmove12">
    Feedback
  </div>
  <ul style="padding-right: 0; overflow-y: auto; height: calc(100% - 75px); padding-right: 10px;">
  
    <li>
      Tell us about your experience and help make {{ service.project_name }} better.
    </li>
    <li>
      <p>
        Overall, How well does {{ service.project_name }} meet your needs?
      </p>
      <div class="questions">
        <label class="radio-inline"><input type="radio" name="needs" [(ngModel)]="feedback.needs" value="Extremely well"/>Extremely well</label>
        <label class="radio-inline"><input type="radio" name="needs" [(ngModel)]="feedback.needs" value="Very well"  />Very well</label>
        <label class="radio-inline"><input type="radio" name="needs" [(ngModel)]="feedback.needs" value="Not so well" />Not so well</label>
        <label class="radio-inline"><input type="radio" name="needs" [(ngModel)]="feedback.needs" value="Somewhat well"  />Somewhat well</label>
        <label class="radio-inline"><input type="radio" name="needs" [(ngModel)]="feedback.needs" value="Not at all well"  />Not at all well</label>
      </div>
    </li>
    <li>
      <p>
        How easy was it to find what you were looking for?
      </p>
      <div class="questions">
        <label class="radio-inline"><input type="radio" name="easy" [(ngModel)]="feedback.easy" value="Extremely well"  />Extremely well</label>
        <label class="radio-inline"><input type="radio" name="easy" [(ngModel)]="feedback.easy" value="Very well"  />Very well</label>
        <label class="radio-inline"><input type="radio" name="easy" [(ngModel)]="feedback.easy" value="Not so well"  />Not so well</label>
        <label class="radio-inline"><input type="radio" name="easy" [(ngModel)]="feedback.easy" value="Somewhat well"  />Somewhat well</label>
        <label class="radio-inline"><input type="radio" name="easy" [(ngModel)]="feedback.easy" value="Not at all well"  />Not at all well</label>
      </div>
    </li>
    <li>
      <p>
        How visually appealing is our website?
      </p>
      <div class="questions">
        <label class="radio-inline"><input type="radio" name="appeal" [(ngModel)]="feedback.appeal" value="Extremely appealing"  />Extremely appealing</label>
        <label class="radio-inline"><input type="radio" name="appeal" [(ngModel)]="feedback.appeal" value="Very appealing"  />Very appealing</label>
        <label class="radio-inline"><input type="radio" name="appeal" [(ngModel)]="feedback.appeal" value="Not so appealing"  />Not so appealing</label>
        <label class="radio-inline"><input type="radio" name="appeal" [(ngModel)]="feedback.appeal" value="Somewhat appealing"  />Somewhat appealing</label>
        <label class="radio-inline"><input type="radio" name="appeal" [(ngModel)]="feedback.appeal" value="Not at all appealing"  />Not at all appealing</label>
      </div>
    </li>
    <li>
      <p>
        How easy is it to understand the information on our website?
      </p>
      <div class="questions">
        <label class="radio-inline"><input type="radio" name="website" [(ngModel)]="feedback.website" value="Extremely well"  />Extremely well</label>
        <label class="radio-inline"><input type="radio" name="website" [(ngModel)]="feedback.website" value="Very well"  />Very well</label>
        <label class="radio-inline"><input type="radio" name="website" [(ngModel)]="feedback.website" value="Not so well"  />Not so well</label>
        <label class="radio-inline"><input type="radio" name="website" [(ngModel)]="feedback.website" value="Somewhat well"  />Somewhat well</label>
        <label class="radio-inline"><input type="radio" name="website" [(ngModel)]="feedback.website" value="Not at all well"  />Not at all well</label>
      </div>
    </li>
    <li>
      <p>
        How likely is it that you would recommend our site to a friend or colleague?
      </p>
      <div style="float: left; width: 100%;">
        <div class="likely">Not at all likely</div>
        <div class="likely">Extremely likely</div>
      </div>
      <div class="rating_scale">
        <label><input type="radio" name="rad" [(ngModel)]="feedback.rating" value="1"/><span>1</span></label>
        <label><input type="radio" name="rad" [(ngModel)]="feedback.rating" value="2"/><span>2</span></label>
        <label><input type="radio" name="rad" [(ngModel)]="feedback.rating" value="3"/><span>3</span></label>
        <label><input type="radio" name="rad" [(ngModel)]="feedback.rating" value="4"/><span>4</span></label>
        <label><input type="radio" name="rad" [(ngModel)]="feedback.rating" value="5"/><span>5</span></label>
        <label><input type="radio" name="rad" [(ngModel)]="feedback.rating" value="6"/><span>6</span></label>
        <label><input type="radio" name="rad" [(ngModel)]="feedback.rating" value="7"/><span>7</span></label>
        <label><input type="radio" name="rad" [(ngModel)]="feedback.rating" value="8"/><span>8</span></label>
        <label><input type="radio" name="rad" [(ngModel)]="feedback.rating" value="9"/><span>9</span></label>
        <label><input type="radio" name="rad" [(ngModel)]="feedback.rating" value="10"/><span>10</span></label>
      </div>
    </li>
    <li>
      <p>
        Do you have any comments on how we can improve {{ service.project_name }}?
      </p>
      <div>
        <textarea spellcheck="true" [(ngModel)]="feedback.comments"></textarea>
      </div>
    </li>
    <li style="width: 100%;">
      <p>
        What do you like about {{ service.project_name }}?
      </p>
      <div>
        <textarea spellcheck="true" [(ngModel)]="feedback.like"></textarea>
      </div>
    </li>
    <li style="width: 100%;">
      <p>
        Enter preferred contact email if you are interested in participating in an App user focus group.
      </p>
      <div>
        <input type="text" class="form-control" placeholder="Enter your email" [(ngModel)]="feedback.contactEmail">
      </div>
    </li>
  </ul>
  <div class="buttons">
    <button class="yellow" (click)="toggleList1()">Cancel</button>
    <button class="yellow" (click)="submit()" >Submit</button>
  </div>
</div>

<style>
    @media (min-width: 320px) and (max-width: 480px) {
      .searchasmove1 {
        width: 280px !important;
        /* right: -280px !important; */
      }
    }
  
  .buttons {
    text-align: center;
    height: 75px;
    position: absolute;
    bottom: -18px;
    left: 0;
    right: 0;
  }
  
  .searchasmove1 {
    position: fixed;
    font-family: OpenSans-Semibold;
    z-index: 2;
    padding: 5px 10px;
    padding-right: 0;
    background: white;
    font-size: 12px;
    color: #1a496d !important;
    width: 362px;
    top: 0;
    /* right: -362px; */
    bottom: 0;
    border-left: 1px solid gray;
  }

  .searchasmove12 {
    border: 1px solid gray;
    cursor: pointer;
    border-left: 0px solid;
    border-bottom: 0px solid;
    content: 'Feedback';
    position: absolute;
    width: 40px;
    display: flex;
    height: 150px;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    background-color: white;
    left: -40px;
    top: 0px;
    writing-mode: tb-rl;
    transform: rotate(-180deg);
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: #1a496d;
  }

  .questions label {
    width: 49%;
  }

  .radio-inline+.radio-inline {
    margin: 0 !important;
  }

  #main {
    transition: right .5s;
  }

  .rating_scale {
    display: block;
    text-align: center;
    font-size: 0;
  }

  .rating_scale span {
    display: inline-block;
    box-sizing: border-box;
    padding: .3em .9em;
    font-size: 13px;
    font-weight: 100;
    background: #0f99d6;
    color: #eee;
    border-top: solid 1px #fff;
    border-bottom: solid 1px #fff;
    border-right: solid 1px #fff;
  }

  .rating_scale label:first-child span {
    border-left: solid 1px #fff;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  .rating_scale label:last-child span {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  .rating_scale input[type="radio"] {
    display: none;
  }

  .rating_scale input[type="radio"]:checked~span {
    background: #eb7823;
    color: #fff;
  }

  .likely {
    font-size: 12px;
  }

  .likely:first-child {
    float: left;
  }

  .likely:last-child {
    float: right;
  }

  textarea,
  .textareaClass {
    max-width: 500px;
    width: 100%;
    height: 100px;
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background: none repeat scroll 0 0 rgba(0, 0, 0, 0.07);
    border-color: -moz-use-text-color #FFFFFF #FFFFFF -moz-use-text-color;
    border-image: none;
    border-radius: 6px 6px 6px 6px;
    border-style: none solid solid none;
    border-width: medium 1px 1px medium;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12) inset;
    color: #555555;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 1em;
    line-height: 1.4em;
    padding: 5px 8px;
    transition: background-color 0.2s ease 0s;
  }

  textarea:focus,
  textareaClass:focus {
    background: none repeat scroll 0 0 #FFFFFF;
    outline-width: 0;
  }

  .searchasmove1 ul::-webkit-scrollbar {
    width: 5px;
  }
 

  .searchasmove1 ul::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #888;
    
  }
  

  .searchasmove1 ul::-webkit-scrollbar-thumb {
    
    background: #808080;
    
  }

  .yellow {
    border-radius: 4px;
    background-color: #ffe27e;
    border: none;
    height: 35px;
    width: 100px;
    font-family: OpenSans-Bold;
    font-size: 0.9em;
    margin: 0 5px;
  }

  li {
    font-size: 14px;
    display: inline-block;
    padding: 6px 3px;
  }

  a {
    display: inline-block;
  }

  ul {
    margin: 0;
    padding: 0;
    float: right;
    list-style-type: none;
    padding-right: 6%;
  }

</style>
