import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class MetaService {

  constructor(private title: Title, private meta: Meta) { }


  setTitle(title:string){
    this.title.setTitle(title);
  }

  updateTitle(title: string) {
    this.meta.updateTag({name:'name', property: 'og:title', content: title });
  }

  updateUrl(url: string) {
    this.meta.updateTag({name:'url', property: 'og:url', content: url })
  }

  updateOgImage(url: string) {
    this.meta.updateTag({ property: 'og:image', content: url })
  }

  updateDescription(desc: string) {
    this.meta.updateTag({name:'description', property: 'og:description', content: desc })
  }

  
  removeTag(){
    this.meta.removeTag("property='og:title'");
  }
}
