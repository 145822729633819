import { Component, OnInit, Input, Output, SimpleChanges, OnChanges, EventEmitter } from '@angular/core';
import { ProjectService } from '../projectservice';
declare var jQuery: any;

@Component({
  selector: 'app-confirm-c',
  templateUrl: './confirm-c.component.html',
  styleUrls: ['./confirm-c.component.css']
})
export class ConfirmCComponent implements OnInit, OnChanges {
  @Input() sanket: any;
  @Output() performAction: EventEmitter<any> = new EventEmitter();
  confirmTitle = '';
  constructor(private service: ProjectService) { }

  ngOnInit() {
  }

  ngOnChanges() {
    if (this.sanket) {
      if (this.sanket.confirmTitle) {
        this.confirmTitle = this.sanket.confirmTitle;
        console.log(this.confirmTitle);
        jQuery('#confirmModal').modal('show');
      }
    }
  }

  no() {
    this.service.confirmJson = '';
  }

  yes() {
    console.log(this.sanket);
    this.performAction.emit(this.sanket);
    this.service.confirmJson = '';    
  }

}
