import { CreateProfileComponent } from './createprofilecomponent/createprofile.component';
import { CompanyProfile } from './companyprofilecomponent/companyprofile.component';
import { ProjectCreate } from './projectcreatecomponent/projectcreate.component';
import { ProjectListComponent } from './projectlistcomponent/projectlist.component';
import { ListProjectComponent } from './listprojectcomponent/listproject.component';
import { UserProfileComponent } from './userprofilecomponent/userprofile.component';
import { UserListComponent } from './userlistcomponent/userlist.component';
import { ContactPage } from './contactpagecomponent/contactpage.component';
import { CompanyCreate } from './companycreatecomponent/createcompany.component';
import { ClaimCompany } from './companyclaimcomponent/claimcompany.component';
import { ClaimPerson } from './personclaimcomponent/claimperson.component';
import { Account } from './accountcomponent/account.component';
import { CreatePerson } from './createpersoncomponent/createperson.component';
import { CreateAccount } from './createaccountcomponent/createaccount.component';
import { Login } from './securelogincomponent/securelogin.component';
import { ManageProjects } from './manageprojectscomponent/manageprojects.component';
import { Agreement } from './agrrementcomponent/agreement.component';
import { FeedbackComponent } from './feedbackcomponent/feedback.component';
import { ForgotPassword } from './forgotpasswordcomponent/forgotpassword.component';
import { RestAuth } from './restauthlandingcomponent/restauth.component';
import { PaymentmaincomponentComponent } from './paymentmodule/paymentmaincomponent/paymentmaincomponent.component';
import { AuthGuardService as AuthGuard } from './auth-guard.service';
import { MessagingComponent } from './messaging/messaging.component';
import { CareerJobListingComponent } from './career/career-job-listing/career-job-listing.component';
import { StudentTeacherComponent } from './createaccountcomponent/student-teacher/student-teacher.component';
import { StudentDashboardComponent } from './student-dashboard/student-dashboard.component';
import { AddprojectlessonComponent } from './addprojectlesson/addprojectlesson.component';
import { TeacherStudentGuideComponent } from './lessonplans/teacher-student-guide/teacher-student-guide.component';
import { InspireComponent } from './inspire/inspire.component';
import { Covid19Component } from './covid19/covid19.component';
import { GetInTouchComponent } from './new-repicture/pages/get-in-touch/get-in-touch.component';
const ɵ0 = () => import("./new-repicture/homepage/home.module.ngfactory").then(m => m.HomeModuleNgFactory), ɵ1 = () => import("./linkedin-login/linkedin-login.module.ngfactory").then(m => m.LinkedinLoginModuleNgFactory), ɵ2 = {
    title: 'Login',
    metatags: {
        name: 'Repicture App',
        // description: 'repicture login.',
        keywords: 'repicture login, RePicture login'
    }
}, ɵ3 = () => import("./new-repicture/aboutuspage/aboutus.module.ngfactory").then(m => m.AboutusModuleNgFactory), ɵ4 = () => import("./new-repicture/accelerator/accelerator.module.ngfactory").then(m => m.AcceleratorModuleNgFactory), ɵ5 = {
    title: 'Contact',
    metatags: {
        name: 'Repicture App',
        // description: 'repicture contact',
        keywords: 'repicture contact, RePicture contact'
    }
}, ɵ6 = () => import("./detail/detail.module.ngfactory").then(m => m.DetailModuleNgFactory), ɵ7 = {
    title: 'Civil Engineering Career Advice, Engineering Jobs',
    metatags: {
        name: 'Civil Engineering Career Advice, Engineering Jobs',
        description: 'Learn about different engineering careers, find first engineering job, entry level  jobs, and engineering career advice',
        keywords: 'repicture jobs, careers, engineering, civil, RePicture, RePicture Engineering'
    },
    kind: 'jobs'
}, ɵ8 = {
    title: 'Engineering Career Advice',
    metatags: {
        name: 'Engineering Career Advice',
        description: 'Do you have questions about engineering?  Chat with engineers, get engineering career advice, learn about first engineering jobs',
        keywords: 'repicture groups, engineering, civil, RePicture, RePicture Engineering'
    }
}, ɵ9 = {
    title: 'Create project',
    metatags: {
        name: 'Repicture App',
    }
}, ɵ10 = {
    title: 'Create project',
    metatags: {
        name: 'Repicture App',
        // description: 'repicture create project',
        keywords: 'repicture create project, RePicture create project'
    }
}, ɵ11 = {
    title: 'Company Profile',
    metatags: {
        keywords: 'companies, engineering companies, repicture, repicture company, organization, organisation'
    }
}, ɵ12 = {
    title: 'User profile',
    metatags: {
        keywords: 'engineers, repicture, person, app user'
    }
}, ɵ13 = {
    title: 'Create account',
    metatags: {
        name: 'Repicture App',
        // description: 'repicture signup register',
        keywords: 'repicture signup register, RePicture signup register'
    }
}, ɵ14 = {
    title: 'Create account'
}, ɵ15 = {
    title: 'Engineers and Scientists: Tell Your Story',
};
// import { PageNotFoundComponent } from './404';
export const appRoutes = [
    {
        path: '',
        loadChildren: ɵ0,
    },
    {
        path: 'jobs/:slug',
        component: CareerJobListingComponent,
    },
    {
        path: 'createprofile',
        component: CreateProfileComponent,
    },
    {
        path: 'linkedinresponse',
        loadChildren: ɵ1
    },
    {
        path: 'login',
        component: Login,
        data: ɵ2
    },
    {
        path: 'about',
        loadChildren: ɵ3
    },
    {
        path: 'accelerator',
        loadChildren: ɵ4
    },
    {
        path: 'get-in-touch',
        component: GetInTouchComponent
    },
    {
        path: 'lessonplans',
        component: ListProjectComponent,
    },
    {
        path: 'contact',
        component: ContactPage,
        data: ɵ5
    },
    // {
    //   path: 'project/:id/:flag',
    //   component: ProjectDetailComponent,
    // },
    {
        path: 'project',
        // component: ProjectDetailComponent,
        loadChildren: ɵ6
    },
    // {
    //   path: 'students',
    //   // component: ProjectDetailComponent,
    //   loadChildren: () => import('./intro/intro.module').then(m => m.IntroModule)
    // },
    // {
    //   path: 'accelerator',
    //   // component: ProjectDetailComponent,
    //   loadChildren: () => import('./intro/intro.module').then(m => m.IntroModule)
    // },
    // {
    //   path: 'educators',
    //   // component: ProjectDetailComponent,
    //   loadChildren: () => import('./intro/intro.module').then(m => m.IntroModule)
    // },
    // {
    //   path: 'colleges',
    //   // component: ProjectDetailComponent,
    //   loadChildren: () => import('./intro/intro.module').then(m => m.IntroModule)
    // },
    // {
    //   path: 'jobseekers',
    //   // component: ProjectDetailComponent,
    //   loadChildren: () => import('./intro/intro.module').then(m => m.IntroModule)
    // },
    // {
    //   path: 'professionals',
    //   // component: ProjectDetailComponent,
    //   loadChildren: () => import('./intro/intro.module').then(m => m.IntroModule)
    // },
    // {
    //   path: 'employers',
    //   // component: ProjectDetailComponent,
    //   loadChildren: () => import('./intro/intro.module').then(m => m.IntroModule)
    // },
    //    {
    //    path: '',
    //    component: ProjectDetailComponent,
    //  },
    {
        path: 'explorer/:type',
        component: ListProjectComponent
    },
    {
        path: 'jobs',
        component: ListProjectComponent,
        data: ɵ7
    },
    {
        path: 'chat',
        component: ListProjectComponent,
        data: ɵ8
    },
    {
        path: 'chat/:groupname',
        component: MessagingComponent,
    },
    {
        path: 'chat/:groupname/:topicname',
        component: MessagingComponent,
    },
    // {
    //   path: '',
    //   component: ListProjectComponent,
    // },
    {
        path: 'listprojects',
        component: ProjectListComponent,
    },
    // {
    //   path: '',
    //   component: ProjectListComponent,
    // },
    {
        path: 'createproject/:id',
        component: ProjectCreate,
        data: ɵ9
    },
    {
        path: 'createproject',
        component: ProjectCreate,
        data: ɵ10
    },
    {
        path: 'createcompany',
        component: CompanyCreate,
    },
    //  {
    //    path: '',
    //    component: CompanyCreate,
    //  },
    // {
    //    path: '',
    //    component: ProjectCreate,
    //  },
    //  {
    //    path: '',
    //    component: CompanyProfile,
    //  },
    {
        path: 'companyprofile/:id',
        component: CompanyProfile,
        data: ɵ11
    },
    {
        path: 'userprofile/:id',
        component: UserProfileComponent,
        data: ɵ12
    },
    //  {
    //    path: '',
    //    component: UserProfileComponent,
    //  },
    {
        path: 'userlist',
        component: UserListComponent
    },
    {
        path: 'manageprojects',
        component: ManageProjects
    },
    {
        path: 'account',
        component: Account
    },
    {
        path: 'createperson',
        component: CreatePerson
    },
    {
        path: ':collegename/students/createaccount',
        component: StudentTeacherComponent
    },
    {
        path: 'createaccount',
        component: CreateAccount,
        data: ɵ13
    },
    {
        path: 'createaccount/:id',
        component: CreateAccount,
        data: ɵ14
    },
    {
        path: 'claimcompany',
        component: ClaimCompany
    },
    {
        path: 'claimperson',
        component: ClaimPerson
    },
    {
        path: 'feedback',
        component: FeedbackComponent
    },
    {
        path: 'agreement',
        component: Agreement
    },
    {
        path: 'reset/:uid/:key',
        component: ForgotPassword
    },
    {
        path: 'rest-auth/registration/account-confirm-email/:key',
        component: RestAuth
    },
    {
        path: 'payment',
        component: PaymentmaincomponentComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'messaging',
        component: MessagingComponent,
    },
    {
        path: 'dashboard/:id',
        component: StudentDashboardComponent,
    },
    {
        path: 'lessonplans/:type',
        component: TeacherStudentGuideComponent,
    },
    {
        path: 'covid19',
        component: Covid19Component,
    },
    {
        path: 'covid19DCjobs',
        component: Covid19Component,
    },
    {
        path: 'addprojectlesson',
        component: AddprojectlessonComponent
    },
    {
        path: 'inspire',
        component: InspireComponent,
        data: ɵ15
    },
    {
        path: 'people',
        redirectTo: '/explorer/people'
    },
    {
        path: 'projects',
        redirectTo: '/explorer/projects'
    },
    {
        path: 'org',
        redirectTo: '/explorer/org'
    },
];
export const routing = {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    initialNavigation: 'enabled'
};
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15 };
