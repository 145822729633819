<style>
  @font-face {
    font-family: OpenSans-Light;
    src: url('/assets/fonts/new/opensans-light-webfont.eot?#iefix') format('embedded-opentype'),
      /* IE */
      url('/assets/fonts/new/opensans-light-webfont.ttf') format("truetype");
    /* non-IE */
  }

  @font-face {
    font-family: OpenSans-Bold;
    src: url('/assets/fonts/new/opensans-bold-webfont.eot?#iefix') format('embedded-opentype'),
      /* IE */
      url('/assets/fonts/new/opensans-bold-webfont.ttf') format("truetype");
    /* non-IE */
  }

  @font-face {
    font-family: OpenSans-Semibold;
    src: url('/assets/fonts/new/opensans-semibold-webfont.eot?#iefix') format('embedded-opentype'),
      /* IE */
      url('/assets/fonts/new/opensans-semibold-webfont.ttf') format("truetype");
    /* non-IE */
  }

  /* Hide HTML5 Up and Down arrows. */

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  td {
    position: relative;
  }

  sup {
    position: absolute;
    top: 5px;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  input[type="text"] {
    font-size: 0.9em !important;
  }

  input::-webkit-input-placeholder {}

  :host ::ng-deep .dd .dropdown button {
    font-family: OpenSans-Light !important;
    color: #868181;
    font-size: 0.9em;
    height: 28px;
    width: 70%;
    text-align: left;
    padding: 0px 12px;
  }

  :host ::ng-deep .dd .dropdown button span {
    float: right;
    margin-top: 7px;
    margin-right: 4px;
  }

  :host ::ng-deep .dropdown ul {
    font-family: OpenSans-Light !important;
    font-size: 0.9em;
  }

  :host ::ng-deep .dropdown ul li a {
    color: #868181;
  }

  .margin-left {
    margin-left: 10px;
  }
</style>
<div class="wrapperMain">
  <navbar (logout1)="loginStatus = false;" [changeNavItem]="loginStatus"></navbar>
  <div *ngIf="!loginStatus" style="padding-bottom: 258px; padding-top: 30px;">
    <div class="row">
      <div class="text-center col-sm-8 col-sm-offset-2">
        <h3 style="color: gray;font-family: OpenSans-Semibold;margin-bottom: 30px;">Upload a Project</h3>
        <h4 style="color: gray;font-family: OpenSans-Semibold;font-size: 16px;">You must be logged in to upload a
          project.</h4>
          <div class="do-login">
            <app-form-field [label]="'Enter your email (username)'">
              <input placeholder="Email (Username)*" class="form-control"  appInput
                  type="text" [(ngModel)]="loginObject.email" [ngModelOptions]="{standalone: true}"
                  required />
          </app-form-field>
          <app-form-field [label]="'Enter your password'">
            <input placeholder="Password*" class="form-control"  appInput type="password"
                [(ngModel)]="loginObject.password" [ngModelOptions]="{standalone: true}" required />
        </app-form-field>
          <!-- <input type="text" class="form-control" [(ngModel)]="loginObject.email" placeholder="Enter email"
            style="width: 30%; display: inline-block;" />
          <input type="password" class="form-control margin-left" [(ngModel)]="loginObject.password"
            placeholder="Enter password" style="width: 30%; display: inline-block;" /> -->
          <button rp-button type="primary" size="medium" (click)="login()">Login</button>
          </div>
          
        <br>
        <br>
        <regForm></regForm>
      </div>
    </div>
  </div>
  <div class="prjct" *ngIf="loginStatus">
    <div class="row" style="padding-top: 20px;">
      <div class="col-sm-1">
      </div>
      <div class="col-sm-10">
        <div class="col-sm-4">
          <h3 class="publish">{{projectbuildtext}}</h3>
          <br>
        </div>
        <div class="col-sm-8" style="display: flex; justify-content: center;">
          <!--<ul class="progressbar" >
					<li class="progress-txt" [class.active]="b1">1. Project OverView</li>
					<li class="progress-txt prg2" [class.active]="b2">2. Photos & Media</li>
					<li class="progress-txt" [class.active]="b3">3. Publish & Add More</li>-->
          <!--	<li class="progress-txt" [class.active]="b4">4.More details</li>
		<li class="progress-txt" [class.active]="b5">5.People & Organizations</li>-->
          <!--</ul>-->
          <div class="row bs-wizard mobile-stepper" style="border-bottom:0;">
            <div class="col-xs-4 bs-wizard-step completed">
              <div class="progress">
                <div class="progress-bar" style="border-radius: 3px 0 0 3px;"></div>
              </div>
              <a href="#" class="bs-wizard-dot"></a>
              <div class="bs-wizard-info text-center">1. Project OverView</div>
            </div>
            <div class="col-xs-4 bs-wizard-step second" [ngClass]="{'completed': itext == 'Upload Image Files'}">
              <div class="progress">
                <div class="progress-bar"></div>
              </div>
              <a href="#" class="bs-wizard-dot"></a>
              <div class="bs-wizard-info text-center">2. Photos & Media</div>
            </div>
            <div class="col-xs-4 bs-wizard-step third">
              <div class="progress">
                <div class="progress-bar" style="border-radius: 0 3px 3px 0;"></div>
              </div>
              <a href="#" class="bs-wizard-dot"></a>
              <div class="bs-wizard-info text-center">3. Publish & Add More</div>
            </div>
          </div>
        </div>
        <!--<div class="col-md-2">
				</div>-->
      </div>
      <div class="col-sm-1">
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-md-1">
      </div>
      <div *ngIf="divcounter == 1" class="col-md-10" class="descprtion-txt">
        <i style="margin-left: 30px " class="loc-font">
          <font color='red'>*</font>{{itext}}
        </i>
      </div>
      <div class="col-md-1">
      </div>
    </div>
    <br>
    <br>
    <!--<div *ngIf="d1" [@fadeInOut]="showMap" class="row">-->
    <div *ngIf="d1" [@fadeInOut]="showMap" class="row" style="padding: 20px 0;">

      <div class="col-md-12">
        <!--<div class="form-group col-sm-12">
				<label for="name" class="col-sm-4 col-form-label loc">Project Name<sup class="sup">*</sup></label>
				<div class="col-sm-8">
					<input #nameP [ngClass]="{'disableElement': unknownNamechkbox == true }" class="form-control form-height bg-color" type="text" placeholder="Project Name" maxlength="70" [(ngModel)]="name" [ngModelOptions]="{standalone: true}" style="width: 86.5%;">
				</div>
				<label for="name" class="col-sm-4 col-form-label loc" style="padding-top: 4px;">Don't know?</label>
				<div class="col-sm-8 chkboxDiv">
					<input type="checkbox" (change)="unknownNamechkbox = !unknownNamechkbox; nameP.value = ''"/> I'm curious about this project
				</div>
			</div>-->
        <!-- <div class="form-group col-sm-12">
                    <label for="type" class="col-sm-4 col-form-label loc">Project Type Category</label>
                    <div class="col-sm-8 dd" style="font-family: OpenSans-Light !important;">
                        <my-dropdown [defaultText]="'Select project category'" [myOptions]="projectTypeOptions" (selectedArray)="onChangedd($event, 1)"></my-dropdown>
                    </div>
                    <div *ngIf="hasOtherType" class="col-sm-7 col-sm-offset-4" style="padding-top: 10px;">
                        <input class="form-control form-height bg-color" type="text" [(ngModel)]="otherCatType" placeholder="Other category">
                    </div>
                </div>
                <div class="form-group col-sm-12">
                    <label for="subcat" class="col-sm-4 col-form-label loc">Project Type Subcategory</label>
                    <div class="col-sm-8 dd" style="font-family: OpenSans-Light !important;">
                        <my-dropdown [selectedOptions]="selectedOptionss" [defaultText]="'Select project subcategory'" [myOptions]="projectSubtypeOptions" (selectedArray)="onChangedd($event, 2)"></my-dropdown>
                    </div>
                    <div *ngIf="hasOther" class="col-sm-7 col-sm-offset-4" style="padding-top: 10px;">
                        <input class="form-control form-height bg-color" type="text" [(ngModel)]="otherCat" placeholder="Other subcategory">
                    </div>
                </div> -->
        <div class="form-group col-sm-12">
          <label for="name" class="col-sm-4 col-form-label loc">Project Name
            <sup class="sup">*</sup>
          </label>
          <div class="col-sm-7">
            <input #nameP [ngClass]="{'disableElement': unknownNamechkbox == true }"
              class="form-control form-height bg-color" type="text" placeholder="Enter project name"
              (keydown)="limitChars($event)" [(ngModel)]="name" [ngModelOptions]="{standalone: true}"
              style="width: 95.5%; color:#868181;" ng2-auto-complete [source]="service.projectNamesonly"
              no-match-found-text=" " min-chars="2" (blur)="checkName()">
          </div>
        </div>
        <!-- <div class="col-sm-7 col-sm-offset-4 form-group">
          <label for="name" class="col-form-label loc" style="margin-left: 15px;">Don't know?</label>&nbsp;
          <span class="chkboxDiv">
            <label class="checkbox-inline" style="padding-left: 34px;">
              <input type="checkbox" (change)="unknownNamechkbox = !unknownNamechkbox; nameP.value = ''" value=""
                style="    height: 15px; margin-top: 0px;">I'm curious about this project</label>
          </span>
        </div> -->
        <!-- <div class="form-group col-sm-12">
          <label for="status" class="col-sm-4 col-form-label loc">Project Status</label>
          <div class="col-sm-8">
            <select (change)="statusChange($event.target.value)"
              class="form-control loc-font form-height select-txt select-width" [(ngModel)]="status" value="status"
              [ngModelOptions]="{standalone: true}" id="sel1"
              style="padding: 0px 12px; font-size: 0.9em !important; color:#868181;" (focus)="closeMultidd()">
              <option value="" style="color:#868181;">Select project status</option>
              <option value="Study/Design Phase">Study/Design Phase</option>
              <option value="Under Construction">Under Construction</option>
              <option value="Complete">Complete</option>
              <option value="Operation & Maintenance">Operation & Maintenance</option>
            </select>
          </div>
        </div> -->
        <!--<div class="form-group row">
<div class="col-sm-8">
  </div>
  </div>    -->
        <!--<div class="form-group row">
				<label class="col-sm-4 loc-font col-form-label" for="location">Project Location*</label>
				<div class="col-sm-8">

					<button btn-warning class="btn loc-font" (click)="ShowMap()" style="background-color:#FFE089;border:1px solid #ffd75d;color:#97885f;">Please select location on map</button>
				</div>
			</div>-->
        <div class="form-group col-sm-12">
          <label class="col-sm-4 loc-font col-form-label loc" for="location">Project Location
            <sup *ngIf="!noNeedForLocation" class="sup">*</sup>
          </label>
          <div *ngIf="!latlngChecked">
            <div class="col-sm-7">
              <input type="text" id="autocompleteinput" (focus)="initMap()"
                class="form-control form-height bg-color select-txt form-height" [(ngModel)]="location" name="location"
                placeholder="Enter address or select location" style="color:#868181;">
            </div>
            <div class="col-sm-1" style="text-align: right;">
              <button data-toggle="tooltip" data-placement="top" title="or pick a location on the map" class=" btn"
                (click)="initMap()" style="padding: 2px 4px 0 2px;background-color: #ffe089; height: 29px;">
                <i class="glyphicon glyphicon-map-marker"></i>
              </button>
            </div>
          </div>
          <div class="col-sm-7" *ngIf="latlngChecked">
            <input type="text" (focus)="initMap()" class="form-control form-height bg-color select-txt form-height"
              [(ngModel)]="location_latlng" placeholder="Enter comma-separated decimal latitude & longitude"
              style="color:#868181;" (keyup)="moveMap()">
          </div>
          <label class="col-sm-offset-4 checkbox-inline loc-font"
            style="padding-left: 34px; padding-top: 5px;font-size: 12px;">
            <input type="checkbox" (click)="changedLatlng()" [checked]="latlngChecked"
              style="height: 15px; margin-top: 0px;">I have latitude & longitude</label>
          <label class="col-sm-offset-4 checkbox-inline loc-font"
            style="padding-left: 34px; padding-top: 5px;font-size: 12px; margin: 0;">
            <input type="checkbox" (click)="worldwideChanged()" [checked]="worldwideChecked"
              style="height: 15px; margin-top: 0px;">This project is worldwide</label>
        </div>
        <div class="col-sm-7 col-sm-offset-4 form-group" *ngIf="showMap" [@fadeInOut]="showMap">
          <div class="alert alert-info alert-dismissable mapInstruction">
            <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a> Find desired location on map
            and click to drop a point.
          </div>
          <div id="map" style="height: 255px; margin-top: 18px;"></div>
        </div>
        <!--<div class="form-group row">
				<label class="loc-font" for="name" style="align:right" class="col-sm-4 col-form-label loc"><i class="loc-font"></i></label>
				<div class="col-sm-8">
					<input class="form-control form-height bg-color select-txt form-height" type="text" placeholder="Address line 2">
				</div>
			</div>
			<div class="form-group row">
				<label class="loc-font" for="name" style="align:right" class="col-sm-4 col-form-label loc"><i class="loc-font txt-size">Enter the cross street if address is unknown</i></label>
				<div class="col-sm-4">
					<input class="form-control form-height bg-color select-txt form-height" type="text" placeholder="Cross Street 1">
				</div>
				<div class="col-sm-4">
					<input class="form-control form-height bg-color select-txt form-height" type="text" placeholder="Cross Street 2">
				</div>
			</div>-->
        <div class="form-group col-sm-12">
          <label class="loc-font" for="location_desc" style="align:right" class="col-sm-4 col-form-label loc">
            <i class="loc-font txt-size">Description of Location, if there's no street address</i>
          </label>
          <div class="col-sm-8">
            <input class="form-control form-height bg-color select-txt form-height" type="text"
              [(ngModel)]="location_desc" [ngModelOptions]="{standalone: true}"
              placeholder="If no street address, enter a description of the location"
              style="width: 86.5%; color:#868181;">
          </div>
        </div>
        <!--<div class="form-group row">
				<label for="name" style="align:right" class="col-sm-4 col-form-label"></label>
				<div class="col-sm-4">
					<input class="form-control form-height bg-color select-txt form-height" type="text" placeholder="City">
				</div>
				<div class="col-sm-4">
					<input class="form-control form-height bg-color select-txt form-height" type="text" placeholder="County">

				</div>
			</div>

			<div class="form-group row">
				<label for="name" style="align:right" class="col-sm-4 col-form-label"></label>
				<div class="col-sm-4">
					<input class="form-control form-height bg-color select-txt form-height" type="text" placeholder="State">
				</div>
				<div class="col-sm-4">
					<input class="form-control form-height bg-color select-txt form-height" type="text" placeholder="Country">

				</div>
			</div>-->
        <div class="form-group col-sm-12">
          <label class="loc-font" for="name" style="padding-left:15px;"
            class="col-sm-4 col-form-label loc">{{yearText}}</label>
          <div class="col-sm-4">
            <form>
              <input class="form-control form-height loc-font select-txt form-height" [(ngModel)]="date"
                [ngModelOptions]="{standalone: true}" type="number" id="example-date-input" placeholder="Enter year"
                style="font-size: 0.9em; color:#868181;">
            </form>
          </div>
          <!--<div class="col-sm-4">
          <div class="form-check">
            <input type="checkbox" class="form-check-input form-height bg-color select-txt form-height" style="vertical-align: bottom;">
            <span class="loc-font col-form-label" style="font-weight:700;font-size:0.9em;">Year Unknown</span>
          </div>
        </div>-->
        </div>
        <div *ngIf="yearTextErr" class="form-group col-sm-12" style="padding-left: 36px;">
          <div class="col-sm-offset-4 alert alert-danger alert-dismissible" role="alert"
            style="font-family: OpenSans-Regular;">
            {{yearTextErr}}
          </div>
        </div>
      </div>
      <!-- <div class="col-md-5" *ngIf="showMap" [@fadeInOut]="showMap">
                <div class="alert alert-info alert-dismissable mapInstruction">
                    <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a> Find desired location on map and click to drop a point.
                </div>
                <div id="map" style="height: 255px; margin-top: 18px;"></div>
            </div> -->
    </div>
    <!--<div class="container-fluid" [@fadeInOut]="showMap" *ngIf="d2">-->
    <div *ngIf="d2" [@enterAnimation]="d2">
      <div class="row" style="font-family:OpenSans-Light !important;">
        <!--<div class="col-md-1">
			</div>-->
        <!--<input type="file" (change)="onUpload2($event)">-->
        <div class="col-md-4">
          <div style=" font-family:OpenSans-Light !important;">
            <p>Queue length: {{ uploader?.queue?.length }}</p>
            <p>Note: Maximum image size is 5 MB</p>
            <p style="font-family: OpenSans-Bold;">Please make sure you have permission to upload the photo. Only images
              you own, are explicitly labeled as "public domain" or have a CCO licence may be uploaded. Please complete
              all the fields to the right.</p>
            <p data-toggle="modal" data-target="#policyModal" class="link">View: where to find free photos online &
              photo sharing policy</p>
          </div>
          <div ng2FileDrop style="height:200px;border: 6px solid #e3e3e3;border-style:dashed;text-align: center;"
            [ngClass]="{'nv-file-over': hasBaseDropZoneOver}" (fileOver)="fileOverBase($event)"
            (onFileDrop)="onFileDrop($event)" [uploader]="uploader" class="well my-drop-zone">
            <div>
            <input #file type="file" ng2FileSelect [uploader]="uploader" (change)="onFileDrop($event.target.files)">
              <!--<button style=" font-family:OpenSans-Light !important;
						color: #97885f;
    background-color: #ffe089;
    border-color: #ffe089;
	    margin-top: 50px;
						" type="button" class="btn btn-success btn-s" (click)="onUpload()"
							*ngIf="uploader.getNotUploadedItems().length">
                    <span class="glyphicon glyphicon-upload"></span> Upload
                </button>-->
              <div>
                <p style="color: #97885f;  margin-top: 50px;" (click)="file.click()"> Drop images here / click here</p>
              </div>
            </div>
          </div>
          <div *ngIf="showFileError" [@fadeInOut]="showFileError">
            <div *ngFor="let item of BadFile">
              <div class="alert alert-danger alert-dismissible" role="alert">
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <strong>File not added!</strong> File {{item.name}} exceeds 5 mb.
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-8" style="overflow-x: auto;">
          <table class="table">
            <thead>
              <tr style="font-family:OpenSans-Light !important;">
                <th style="font-family:OpenSans-Light !important;">Image</th>
                <th style="font-family:OpenSans-Light !important;">Photo License</th>
                <!-- <th *ngIf="isCCSelected && license_selected == 'own_work'" style="font-family:OpenSans-Light !important;">Select Rights <sup style="color: red;">*</sup></th>
                                <th *ngIf="isCCSelected" style="font-family:OpenSans-Light !important;">Photo Credit/Author <sup *ngIf="license_selected != 'Creative Commons CC0 Waiver (release all rights) or Public Domain'" style="color: red;">*</sup></th>
                                <th *ngIf="isCCSelected" style="font-family:OpenSans-Light !important;">Description</th>
                                <th *ngIf="isCCSelected" style="font-family:OpenSans-Light !important;">Date Taken</th>
                                <th *ngIf="isCCSelected" style="font-family:OpenSans-Light !important;">Photo Name/Title</th>
                                <th *ngIf="isCCSelected" style="font-family:OpenSans-Light !important;">Photo Link <sup *ngIf="license_selected != 'own_work' && license_selected != 'Creative Commons CC0 Waiver (release all rights) or Public Domain'" style="color: red;">*</sup></th> -->
                <!-- <th *ngIf="isCCSelected" style="font-family:OpenSans-Light !important;">Author's Name</th> -->
                <!-- <th *ngIf="isCCSelected" style="font-family:OpenSans-Light !important;">Author's Page Link</th> -->
                <!--<th *ngIf="showOwnerSetting" style="font-family:OpenSans-Light !important;">Owner Settings</th>

              <th *ngIf="!showOwnerSetting && isEmptyString" style="font-family:OpenSans-Light !important;">Photo name</th>
              <th *ngIf="!showOwnerSetting && isEmptyString" style="font-family:OpenSans-Light !important;">Link to photo</th>
              <th *ngIf="!showOwnerSetting && isEmptyString" style="font-family:OpenSans-Light !important;">Author name</th>
              <th *ngIf="!showOwnerSetting && isEmptyString" style="font-family:OpenSans-Light !important;">Link to author's page</th>-->
                <!-- <th style="font-family:OpenSans-Light !important;">Creative commons license number</th>-->
                <!--<th style="font-family:OpenSans-Light !important;">Actions</th>-->
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of uploader.queue; let i = index">
                <td>
                  <div class="imgParent">
                    <img src="" imgPreview [image]="item?._file" class="media-object" />
                  </div>
                </td>
                <td>
                  <select [(ngModel)]="item.license" (change)="onChange1($event, i);item.isCCSelected = true;"
                    [disabled]="item.isUploading || item.isSuccess" style="max-width: 120px;">
                    <option value="">Select license</option>
                    <option value="own_work">This file is my own work</option>
                    <option value="Creative Commons Attribution ShareAlike 4.0 (CC BY-SA 4.0)">Creative Commons
                      Attribution ShareAlike 4.0 (CC BY-SA 4.0)</option>
                    <option value="Creative Commons Attribution ShareAlike 3.0 (CC BY-SA 3.0)">Creative Commons
                      Attribution ShareAlike 3.0 (CC BY-SA 3.0)</option>
                    <option value="Creative Commons Attribution ShareAlike 2.5 (CC BY-SA 2.5)">Creative Commons
                      Attribution ShareAlike 2.5 (CC BY-SA 2.5)</option>
                    <option value="Creative Commons Attribution ShareAlike 2.0 (CC BY-SA 2.0)">Creative Commons
                      Attribution ShareAlike 2.0 (CC BY-SA 2.0)</option>
                    <option value="Creative Commons Attribution 4.0 (CC BY 4.0)">Creative Commons Attribution 4.0 (CC BY
                      4.0)</option>
                    <option value="Creative Commons Attribution 3.0 (CC BY 3.0)">Creative Commons Attribution 3.0 (CC BY
                      3.0)</option>
                    <option value="Creative Commons Attribution 2.5 (CC BY 2.5)">Creative Commons Attribution 2.5 (CC BY
                      2.5)</option>
                    <option value="Creative Commons Attribution 2.0 (CC BY 2.0)">Creative Commons Attribution 2.0 (CC BY
                      2.0)</option>
                    <option value="Creative Commons CC0 Waiver (release all rights) or Public Domain">Creative Commons
                      CC0 Waiver (release all rights) or Public Domain</option>
                    <!-- <option value="Other Creative Commons Attribution">Other Creative Commons Attribution</option> -->
                    <option value="Taken by someone else who’s given written approval to use">Taken by someone else who’s given written approval to use</option>
                    <option value="Unknown or Other">Unknown or Other</option>
                    <!-- <option value="none">None</option> -->
                  </select><sup style="color: red;">*</sup>
                </td>
                <td *ngIf="item.showOwnerSetting && item.isCCSelected && item.license == 'own_work'">
                  <select [(ngModel)]="item.owner_settings" [disabled]="item.isUploading || item.isSuccess"
                    style="max-width: 120px;">
                    <option value="">Select</option>
                    <option value="All rights reserved">All rights reserved</option>
                    <option value="Public Domain Work">Public Domain Work</option>
                    <option value="Creative Commons Attribution ShareAlike 4.0 (CC BY-SA 4.0)">Creative Commons
                      Attribution ShareAlike 4.0 (CC BY-SA 4.0)</option>
                    <option value="Creative Commons Attribution ShareAlike 3.0 (CC BY-SA 3.0)">Creative Commons
                      Attribution ShareAlike 3.0 (CC BY-SA 3.0)</option>
                    <option value="Creative Commons Attribution ShareAlike 2.5 (CC BY-SA 2.5)">Creative Commons
                      Attribution ShareAlike 2.5 (CC BY-SA 2.5)</option>
                    <option value="Creative Commons Attribution ShareAlike 2.0 (CC BY-SA 2.0)">Creative Commons
                      Attribution ShareAlike 2.0 (CC BY-SA 2.0)</option>
                    <option value="Creative Commons Attribution 4.0 (CC BY 4.0)">Creative Commons Attribution 4.0 (CC BY
                      4.0)</option>
                    <option value="Creative Commons Attribution 3.0 (CC BY 3.0)">Creative Commons Attribution 3.0 (CC BY
                      3.0)</option>
                    <option value="Creative Commons Attribution 2.5 (CC BY 2.5)">Creative Commons Attribution 2.5 (CC BY
                      2.5)</option>
                    <option value="Creative Commons Attribution 2.0 (CC BY 2.0)">Creative Commons Attribution 2.0 (CC BY
                      2.0)</option>
                    <!-- <option value="Creative Commons CC0 Waiver (release all rights) or Public Domain">Creative Commons CC0 Waiver (release all rights) or Public Domain</option> -->
                  </select><sup style="color: red;">*</sup>
                </td>
                <td *ngIf="item.isCCSelected">
                  <input type="text" placeholder="Enter photo credit" [(ngModel)]="item.photo_credit"
                    [disabled]="item.isUploading || item.isSuccess">
                  <sup *ngIf="item.license != 'Creative Commons CC0 Waiver (release all rights) or Public Domain'" style="color: red;">*</sup>
                </td>
                <td *ngIf="item.isCCSelected">
                  <input type="text" placeholder="Enter photo description" [(ngModel)]="item.description"
                    [disabled]="item.isUploading || item.isSuccess">
                </td>
                <td *ngIf="item.isCCSelected">
                  <input type="text" placeholder="Enter date taken" [(ngModel)]="item.date_taken"
                    [disabled]="item.isUploading || item.isSuccess">
                </td>
                <td *ngIf="item.isCCSelected">
                  <input type="text" placeholder="Enter photo name" [(ngModel)]="item.photo_name"
                    [disabled]="item.isUploading || item.isSuccess">
                </td>
                <td *ngIf="item.isCCSelected">
                  <input type="text" placeholder="Enter photo link" [(ngModel)]="item.photo_link"
                    [disabled]="item.isUploading || item.isSuccess">
                  <sup *ngIf="item.license != 'own_work'" style="color: red;">*</sup>
                </td>
                <!-- <td *ngIf="item.isCCSelected">
                                    <input type="text" placeholder="Enter author name" [(ngModel)]="item.author_name" [disabled]="item.isUploading || item.isSuccess">
                                </td> -->
                <td *ngIf="item.isCCSelected && item.license != 'Taken by someone else who’s given written approval to use'">
                  <input type="text" placeholder="Enter author's page link" [(ngModel)]="item.author_page_link"
                    [disabled]="item.isUploading || item.isSuccess">
                </td>
                <td *ngIf="item.license == 'Taken by someone else who’s given written approval to use'">
                  <button style="color: black; background-color: #ffe089; border-color: #ffe089;font-family: OpenSans-Semibold !important;outline:none;padding: 1px 10px;" type="button" class="btn btn-success btn-s" (click)="permissionClicked(i)">{{item.btn_permission}}</button>
                  <sup *ngIf="item.btn_permission == 'Upload written approval'" style="color: red;">*</sup>
              </td>
                <td nowrap>
                  <button type="button" class="btn btn-danger btn-xs" (click)="item.remove()"
                    [disabled]="item.isUploading || item.isSuccess">
                    <span class="glyphicon glyphicon-trash"></span> Remove
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--<div class="col-md-1">
			</div>-->
      </div>
      <!--<div class="row" align="center">

			<button style=" font-family:OpenSans-Light !important;" type="button" class="btn btn-success btn-s" (click)="onUpload()"
							[disabled]="!uploader.getNotUploadedItems().length">
                    <span class="glyphicon glyphicon-upload"></span> Upload
                </button>
		</div>-->
      <div class="row">
        <br>
        <br>
      </div>
    </div>
    <!--*ngIf="displaylistofimages"-->
    <div align="center" *ngIf="d3">
    </div>
    <div align="center" *ngIf="d4">
      <h3>4 form</h3>
    </div>
    <div align="center" *ngIf="d5">
      <h3>5 form</h3>
    </div>
    <!--<loginreg [validationtoggle]="ValidationToggleValue" *ngIf="showModal" ></loginreg>-->
    <div *ngIf="showLogin" class="modal fade" id="myModal" role="dialog"
      style=" font-family:OpenSans-Light !important;">
      <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content" style="border-radius: 0px !important;">
          <div class="modal-header" style="background-color:#FFE089;">
            <h4 class="modal-title">Alert!</h4>
          </div>
          <div class="modal-body">
            <div class="alert alert-danger" role="alert">
              <!--<button type="button" class="close" data-dismiss="alert" aria-label="Close">
	  <span aria-hidden="true">&times;</span>
	  </button>-->
              You need to login or join in order to add a project!
            </div>
          </div>
          <div class="modal-footer">
            <!--<button type="button" class="btn btn-primary" (click)="goAhead()" data-dismiss="modal">Proceed</button>-->
            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="showWarning" class="modal fade" id="myModal" role="dialog"
      style=" font-family:OpenSans-Light !important;">
      <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content" style="border-radius: 0px !important;">
          <div class="modal-header" style="background-color:#FFE089;">
            <h4 class="modal-title">Alert!</h4>
          </div>
          <div class="modal-body">
            <div class="alert alert-danger" role="alert">
              <!--<button type="button" class="close" data-dismiss="alert" aria-label="Close">
	  <span aria-hidden="true">&times;</span>
	  </button>-->
              You have not uploaded any images, Please add image(s) to continue.
            </div>
          </div>
          <div class="modal-footer">
            <!--<button type="button" class="btn btn-primary" (click)="goAhead()" data-dismiss="modal">Proceed</button>-->
            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="showAlert" class="modal fade" id="myModal" role="dialog"
      style=" font-family:OpenSans-Light !important;">
      <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content" style="border-radius: 0px !important;">
          <div class="modal-header" style="background-color:#FFE089;">
            <button type="button" class="close" data-dismiss="modal">&times;</button>
            <h4 class="modal-title">Alert!</h4>
          </div>
          <div class="modal-body">
            <div class="alert alert-danger" role="alert">
              Fields marked with an asterisk (*) are required.
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="SameNameModal" role="dialog" style=" font-family:OpenSans-Light !important;">
      <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content" style="border-radius: 0px !important;">
          <div class="modal-header" style="background-color:#FFE089;">
            <button type="button" class="close" data-dismiss="modal">&times;</button>
            <h4 class="modal-title">Alert!</h4>
          </div>
          <div class="modal-body">
            <div class="alert alert-danger" role="alert">
              Note: there are one or more projects with the name "{{name}}".
              <a style="cursor: pointer;" (click)="gotoExplorer()">Click here</a> to view in the explorer on a new tab.
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="lengthAlertModel" role="dialog" style=" font-family:OpenSans-Light !important;">
      <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content" style="border-radius: 0px !important;">
          <div class="modal-header" style="background-color:#FFE089;">
            <button type="button" class="close" data-dismiss="modal">&times;</button>
            <h4 class="modal-title">Alert!</h4>
          </div>
          <div class="modal-body">
            <div class="alert alert-danger" role="alert">
              Note: Project name should be less than 80 character.
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="co-author-modal" role="dialog" data-keyboard="false" data-backdrop="static" style=" font-family:OpenSans-Light !important;">
      <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content" style="border-radius: 0px !important;">
          <div class="modal-header" style="background-color:#FFE089;">
            <button (click)="onCancel2()"  type="button" class="close" data-dismiss="modal">&times;</button>
            <h4 class="modal-title">Add co-authors (they must have already joined RePicture)</h4>
          </div>
          <div class="modal-body" >
            <p style="margin: 8px 0;" *ngFor="let author of authors; let i = index;"><input type="text" [(ngModel)]="author.author_name" class="form-control" placeholder="Enter name" (focus)="currentEntering = i" (keyup)="searchTerm$.next($event.target.value)" /></p>
            <ul *ngIf="arrayOfStrings.length > 0" class="contributor-ul">
              <li *ngFor="let result of arrayOfStrings | slice:0:9">
                <div style="padding: 5px 0; cursor: pointer;" (mousedown)="selectName(result)">
                  <img src='{{result.profile_image}}' onError="this.src='assets/images/default.png'"
                    style='width:30px;height:30px;border-radius:50%; margin-top: -3px;' /> {{result.fullname}}
                </div>
              </li>
            </ul>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="onEnter()" data-dismiss="modal">Enter</button>
            <button type="button" class="btn btn-primary" (click)="onAddmore()">Add more</button>
            <button type="button" class="btn btn-default" (click)="onCancel2()" data-dismiss="modal">Cancel</button>
          </div>
        </div>
      </div>
    </div>


    <div style align="center">
      <!--<button (click)="clickNext('back')" class="btn loc-font" style="background-color:#FFE089;border:1px solid #ffd75d;color:#97885f;">Back</button>-->
      <button
        style="color: #97885f; background-color: #ffe089; border-color: #ffe089;font-family: OpenSans-Light !important;outline:none;"
        type="button" class="btn btn-success btn-s" (click)="onUpload();" *ngIf="uploader.getNotUploadedItems().length">
        <span>
          <i id="btn1" class="glyphicon glyphicon-upload"></i> Upload</span>
        <i *ngIf="clicked" class="glyphicon glyphicon-refresh glyphicon-refresh-animate"></i>
      </button>
      <button *ngIf="uploader.getNotUploadedItems().length == 0" (click)="clickNext('next')" class="btn loc-font"
        style="background-color:#FFE089;border:1px solid #ffd75d;color:#97885f;outline:none;">Go to next step </button>
      <button *ngIf="d1" class="btn loc-font" data-toggle="modal" data-target="#co-author-modal"
        style="background-color:#FFE089;border:1px solid #ffd75d;color:#97885f;outline:none;margin-left: 15px;">Add
        co-author(s)</button>
      <!--<div class="arrow-right"></div>-->
      <!--<button  data-toggle="modal" data-target="#myModal" class="btn loc-font" style="background-color:#FFE089;border:1px solid #ffd75d;color:#97885f;">Skip</button>-->
    </div>
  </div>
  <br>
  <br>
  <div class="fadeMe not-selectable" *ngIf="Onimage">
    <img-cropper class="cropper1" #cropper [image]="dat" [settings]="cropperSettings1" (onCrop)="cropped($event)">
    </img-cropper>
    <div>
      <button class="btn-default" (click)="Oncropped()">Crop</button>
      <button class="btn-default" (click)="Onskipped()">Skip Crop</button>
      <button class="btn-default" (click)="onCancel()">Cancel</button>
    </div>
    <div class="result" *ngIf="dat.image" style="visibility: hidden;">
      <img #imgcrp [src]="dat.image" [width]="croppedWidth" [height]="croppedHeight">
    </div>
  </div>
  <br>
  <br>
  <div id="policyModal" class="modal fade" role="dialog" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog">
      <!-- Modal content-->
      <div class="modal-content" style="border-radius: 0px; font-family: OpenSans-Light;">
        <div class="modal-header" style="background-color: #FFE089;">
          <h4 class="modal-title">Photo Sharing Info & Policy</h4>
        </div>
        <div class="modal-body" style="height: calc(100vh - 185px); overflow-y: auto; font-size: 14px;">
          <p [innerHTML]="bs">
          </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" data-dismiss="modal">OK</button>
        </div>
      </div>
    </div>
  </div>
  <div id="permissionModal" class="modal fade" role="dialog" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog">
      <!-- Modal content-->
      <div class="modal-content" style="border-radius: 0px; font-family: OpenSans-Light;">
        <div class="modal-header" style="background-color: #FFE089;">
          <h4 class="modal-title">Upload written permission</h4>
        </div>
        <div class="modal-body">
          <div style="font-family:OpenSans-Bold; margin-bottom: 15px;">
              If an image/photo was created by someone else and is not covered by Creative Commons Attribution (CCO license), or explicated labeled as “public domain”, you must have written permission from them to use the file. Upload this written permission below.
          </div>
            <div id="drop_zone" (drop)="dropHandler($event)" (dragover)="dragOverHandler($event)">
                <p>Drag file to this Drop Zone ...</p>
              </div>
              <div style="display: inline-block;margin-left: 15px;">
                {{permission_file_name}}
              </div>
              <!-- <div>
                  <button style=" font-family:OpenSans-Light !important;" type="button" class="btn btn-success btn-s" (click)="onUploadPermission()" [disabled]="!permission_file_name">
                        <span class="glyphicon glyphicon-upload"></span> Upload
                    </button>
              </div> -->
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>
          <button style=" font-family:OpenSans-Light !important;" type="button" class="btn btn-primary btn-s" (click)="onUploadPermission()" [disabled]="!permission_file_name">
            <span class="glyphicon glyphicon-upload"></span> Add approval
        </button>
        </div>
      </div>
    </div>
  </div>
  <!--Footer div-->
  <footer></footer>
</div>
<script>
  $(document).ready(function () {
    $('[data-toggle="tooltip"]').tooltip();
  });

</script>
<style>
  input[type=file] {
    visibility: hidden;
  }

  #drop_zone {
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
    height: 200px;
    border: 6px solid #e3e3e3;
    border-style: dashed;
    text-align: center;
  }
  
  #drop_zone {
    display: inline-block;
    width:  250px;
    height: 150px;
}
  .glyphicon-refresh-animate {
    -animation: spin .7s infinite linear;
    -webkit-animation: spin2 .7s infinite linear;
  }

  @-webkit-keyframes spin2 {
    from {
      -webkit-transform: rotate(0deg);
    }

    to {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    from {
      transform: scale(1) rotate(0deg);
    }

    to {
      transform: scale(1) rotate(360deg);
    }
  }

  div.fadeMe {
    opacity: 1;
    filter: alpha(opacity=20);
    background-color: #000;
    width: 100%;
    height: 100%;
    z-index: 10;
    top: 0;
    left: 0;
    position: fixed;
  }

  .not-selectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  input[type=file] {
    visibility: hidden;
  }

  .contributor-ul {
    width: 95%;
    list-style-type: none;
    padding: 0px;
    position: absolute;
    z-index: 1;
    background: #eee;
  }
</style>