import { AddReferences } from './../referencescomponent/references.component';
import { EmailComponent } from './../emailsendingcomponent/email.component';
import { Router, ActivatedRoute } from '@angular/router';
import { Ng2AutoCompleteModule } from 'ng2-auto-complete';

import {
  Component,
  NgZone,
  EventEmitter,
  Input,
  Output,
  ChangeDetectorRef,
  ViewChild, ElementRef, Inject, PLATFORM_ID
} from '@angular/core';

import {
  ProjectService
}
  from './../projectservice';


import { IMultiSelectOption } from 'angular-2-dropdown-multiselect';
import * as _ from 'lodash';

import {
  CookieService
} from 'angular2-cookie/core';

import { Title } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'createaccount',
  templateUrl: './createaccount.html'
})

export class CreateAccount {
  loginStatus: boolean = false;
  professionalCheck: any;
  studentCheck: any;
  educatorCheck: any;
  data: any = 'RePicture Engineering will not sell your personal data to third parties. By clicking the "Join Now" button below I am consenting to use of my personal data for RePicture Engineering\'s internal business purposes.  I verify that I am not a citizen of any jurisdiction (such as the European Union) that imposes regulations on data processors or data controllers that are more stringent than those imposed by the United States government';
  students: any = ['7th grade', '8th grade', '9th grade', '10th grade', '11th grade', '12th grade', 'College 1st year', 'College 2nd year', 'College 3rd year', 'College 4th year', 'College 5th year', 'Grad student - masters', 'Grad student - PhD', 'Other'];
  educators: any = ['k-3rd grade', 'k-12th grade', '4th grade', '5th grade', '6th grade', '7th grade', '8th grade', '9th grade', '10th grade', '11th grade', '12th grade', 'College', 'Other'];
  designation: any = 'Civil Engineer';
  selectedStudent: any = '7th grade';
  selectedEducator: any = [];
  othersEducator: boolean = false;
  othersProfessional: boolean = false;
  uploadPic: boolean = false;
  image: any;
  success: boolean = false;
  user: any = {};
  peopleid: any;
  designationArr: any = [];
  studentsOther: any;
  educatorOther: any;
  professionalOther: any;
  uploaded: boolean = false;
  uploading: boolean = false;
  isPublicProfile: boolean = false;
  private educatorsOptions: IMultiSelectOption[] = [
    { id: 'k-3rd grade', name: 'k-3rd grade' },
    { id: 'k-12th grade', name: 'k-12th grade' },
    { id: '4th grade', name: '4th grade' },
    { id: '5th grade', name: '5th grade' },
    { id: '6th grade', name: '6th grade' },
    { id: '7th grade', name: '7th grade' },
    { id: '8th grade', name: '8th grade' },
    { id: '9th grade', name: '9th grade' },
    { id: '10th grade', name: '10th grade' },
    { id: '11th grade', name: '11th grade' },
    { id: '12th grade', name: '12th grade' },
    { id: 'College', name: 'College' },
    { id: 'Other', name: 'Other' },
  ];
  @ViewChild('input', { static: false }) fileInput: ElementRef;

  // tslint:disable-next-line:max-line-length
  constructor(public service: ProjectService, private cookie: CookieService, private router: Router, private route: ActivatedRoute, private cd: ChangeDetectorRef, private titleService: Title, @Inject(PLATFORM_ID) private platform: any) {
    if (localStorage.getItem('peopleid')) {
      this.router.navigate(['']);
    }
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platform)) {
      if (this.cookie.get('public')) {
        this.cookie.remove('public');
        this.isPublicProfile = true;
      }
      try {
        this.route.params.subscribe(params => {
          if (params['id']) {
            this.peopleid = params['id'];
            localStorage.setItem("peopleid", params['id']);
            this.loginStatus = true;
          } else {
            this.loginStatus = false;
          }
        });
      } catch (e) { }
    }
  }
  // signup() {
  //   if (this.validate()) {
  //     this.service.doReg(this.user.first_name, this.user.last_name, this.user.email, this.user.password, this.user.password, this.user.email).then(res => {
  //       this.user.first_name = '';
  //       this.user.last_name = '';
  //       this.user.email = '';
  //       this.user.password = '';
  //       window.localStorage.setItem("first_name", this.user.first_name);
  //       window.localStorage.setItem("last_name", this.user.last_name);
  //       window.localStorage.setItem("username", this.user.email);
  //       //alert('An email has been sent to your email address with account activation instructions.');
  //       this.service.sendAlert('Success', 'An email has been sent to your email address with account activation instructions.', 1);
  //       setTimeout(() => {
  //       }, 1000);
  //     });
  //   }
  // }

  validate() {
    if (!this.user.first_name || this.user.first_name.trim() == "") {
      //alert("Please provide your first name!");
      this.service.sendAlert('Error', 'Please provide your first name!', 0);
      return false;
    }

    if (!this.user.email || this.user.email.trim() == "") {
      // alert("Please provide your email!");
      this.service.sendAlert('Error', 'Please provide your email!', 0);
      return false;
    } else {
      if (this.validateEmail(this.user.email.trim())) {
        // alert("You have entered an invalid email address!");
        this.service.sendAlert('Error', 'You have entered an invalid email address!', 0);
        return false;
      }
    }

    var elem = document.getElementById("pwd");

    var pattern = elem.getAttribute("pattern");
    var re = new RegExp(pattern);
    if (!re.test(elem['value'])) {
      // alert('Please revise password to meet complexity requirements!');
      this.service.sendAlert('Error', 'Please revise password to meet complexity requirements!', 0);
      return false;
    }

    return true;
  }

  validateEmail(email) {
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    }
  }

  addUserDetails() {
    this.designationArr = [];
    if (this.studentCheck) {
      if (this.selectedStudent == 'Other') {
        if (this.studentsOther) {
          this.designationArr.push(this.studentsOther);
        }
      } else {
        this.designationArr.push(this.selectedStudent);
      }
    }

    if (this.educatorCheck) {
      if (this.othersEducator) {
        if (this.educatorOther) {
          let index = this.selectedEducator.indexOf('Other');
          this.selectedEducator.splice(index, 1);
          this.selectedEducator.push(this.educatorOther);
        } else {

        }
      }
      _.forEach(this.selectedEducator, (value, key) => {
        this.designationArr.push(value);
      })
    }

    if (this.professionalCheck) {
      if (this.designation == 'Other') {
        if (this.studentsOther) {
          this.designationArr.push(this.professionalOther);
        }
      } else {
        this.designationArr.push(this.designation);
      }
    }

    let body = {
      user_designation: this.designationArr.toString()
    }

    this.service.doUserProfilePatch(this.peopleid, body).then(res => {
      this.uploadPic = true;
    });
  }

  onChangedd($event) {
    this.selectedEducator = $event;
    if (_.includes($event, 'Other')) {
      this.othersEducator = true;
    }
  }

  choosePic() {
    this.fileInput.nativeElement.click();
  }

  fileChange(event) {
    this.uploading = true;
    let file = event.target.files[0];
    if (file) {
      this.service.setPeoplePic(this.peopleid, file).then(res => {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.uploaded = true;
          this.uploading = false;
          this.image = e.target['result'];
          document.getElementById('be').style.backgroundImage = "url(" + this.image + ")";
          document.getElementById('be').style.height = "150px";
        }
        reader.readAsDataURL(file);

      });
    } else {
      //alert('File is invalid!');
      this.service.sendAlert('Error', 'File is invalid!', 0);
    }
  }
}