import {
  Component,
  NgZone,
  Input,
  EventEmitter,
  Output,
  ViewEncapsulation, ViewChild, ElementRef, OnInit, ChangeDetectorRef
} from '@angular/core';

import {
  trigger,
  state,
  style,
  animate,
  transition,
  keyframes
} from '@angular/animations'


import {
  ProjectService
}
  from './../../projectservice';

import * as _ from 'lodash';

import { IMultiSelectOption } from 'angular-2-dropdown-multiselect';

@Component({
  selector: 'app-editprofilemystory',
  templateUrl: './editprofilemystory.component.html',
  styleUrls: ['./editprofilemystory.component.css'],
  animations: [
    trigger('fadeInOut', [
      transition('void => *', [
        style({ opacity: 0 }), //style only for transition transition (after transiton it removes)
        animate(700, style({ opacity: 1 })) // the new state of the transition(after transiton it removes)
      ]),
      transition('* => void', [
        animate(0, style({ opacity: 0 })) // the new state of the transition(after transiton it removes)
      ])
    ])
  ]
})

export class EditprofilemystoryComponent implements OnInit {

  @Input() mx: any;
  @Input() isEnginner: any;
  @Input() edit: any;
  @Input() hideToggle: any;
  @Input() fieldname: any;
  @Output() certificates: EventEmitter<any> = new EventEmitter();
  @Output() awards: EventEmitter<any> = new EventEmitter();
  @Output() externalLinks: EventEmitter<any> = new EventEmitter();
  @ViewChild('info', { static: false }) Elem: ElementRef;
  
  textinput: any;
  addmoreCliked: boolean = false;
  exLinks: Link = {
    link_title: '',
    link_url: ''
  }
  tech_expert: any;
  projectobj: any;
  toggleVisibitly = false;
  ShowWebsiteDiv: boolean = false;
  ShowProjectTypeDiv: boolean = false;
  EditToggle: boolean = false;
  showTexbox: boolean = false;

  ShowWebsiteDivData: boolean = false;
  ShowProjectTypeDivData: boolean = false;
  projecttypeArr: any = [];
  url: string;
  desc: any;
  printArray: any = [];
  data: any;
  data1: any = [];
  varib: boolean = false;
  fields: any;
  txt: any
  info: any;
  sfs: any;
  tags: any = [];
  exp: any = ["0", "0 - 1", "1 - 2", "2 - 3", "3 - 4", "4 - 5", "5 - 6", "6 - 7", "7 - 8", "8 - 9", "9 - 10", "10+"];
  selectedCareerStatus = [];
  careerStatus: IMultiSelectOption[] = [
    { id: 'Currently working as engineer', name: 'Currently working as engineer', isLabel: false, isDisabled: false },
    { id: 'Currently working in engineering-related field', name: 'Currently working in engineering-related field', isLabel: false, isDisabled: false },
    { id: 'Previously worked as engineer', name: 'Previously worked as engineer', isLabel: false, isDisabled: false },
    // { id: 'Previously worked in engineering-related field', name: 'Previously worked in engineering-related field' },
    // { id: 'Never worked as engineer', name: 'Never worked as engineer' },
    // { id: 'Looking for an engineering job', name: 'Looking for an engineering job' },
    { id: 'Previously worked in engineering-related field', name: 'Previously worked in engineering-related field', isLabel: false, isDisabled: false },
    { id: 'Never worked as engineer or in engineering-related field', name: 'Never worked as engineer or in engineering-related field', isLabel: false, isDisabled: false },
    { id: 'Looking for job', name: 'Looking for job', isLabel: false, isDisabled: false }

  ];

  nonEngineersCareerStatus = [
    // { id: 'Currently working as engineer', name: 'Currently working as engineer' },
    { id: 'Currently working in engineering-related field', name: 'Currently working in engineering-related field' },
    // { id: 'Previously worked as engineer', name: 'Previously worked as engineer' },
    { id: 'Previously worked in engineering-related field', name: 'Previously worked in engineering-related field' },
    { id: 'Never worked in engineering-related field', name: 'Never worked in engineering-related field' },
    { id: 'Looking for job', name: 'Looking for job' }

  ];

  is_prof_certi_other: boolean = false;
  prof_certi_other: any;
  certi_location: any;
  selected_certifications = [];
  certifications: any = [
    { id: 'Fundamentals of Engineering (FE/EIT)', name: 'Fundamentals of Engineering (FE/EIT)' },
    { id: 'Professional Engineer (PE)', name: 'Professional Engineer (PE)' },
    { id: 'Structural Engineer (SE)', name: 'Structural Engineer (SE)' },
    { id: 'Registered Engineer (RE)', name: 'Registered Engineer (RE)' },
    { id: 'International Professional Engineer (IntPE)', name: 'International Professional Engineer (IntPE)' },
    { id: 'Chartered Engineer (CEng)', name: 'Chartered Engineer (CEng)' },
    { id: 'Incorporated Engineer (IEng)', name: 'Incorporated Engineer (IEng)' },
    { id: 'Engineering Technician (EngTech)', name: 'Engineering Technician (EngTech)' },
    { id: 'Information and Communications Technology Technicians (ICTTech)', name: 'Information and Communications Technology Technicians (ICTTech)' },
    { id: 'Project Management Professional (PMP)', name: 'Project Management Professional (PMP)' },
    { id: 'LEED AP', name: 'LEED AP' },
    { id: 'LEED Green Associate', name: 'LEED Green Associate' },
    { id: 'Other', name: 'Other' }
  ];
  collectionCertificates: any = [];
  birth_year: any;
  death_year: any;
  user_award_url: any;
  user_award_name_mystory: string;
  collectionUserAwards: any = [];
  collectionExternalLinks: any = [];
  selected_certifications1 = [];
  mySettings: IMultiSelectSettings;
  myOptions: IMultiSelectOption;
  myTexts: { checkAll: string; uncheckAll: string; checked: string; checkedPlural: string; searchPlaceholder: string; defaultTitle: any; };
  constructor(public service: ProjectService, private cd: ChangeDetectorRef) { }

  ngOnInit() {
    if (this.mx.fieldname == 'professional_certification') {
      //console.log(this.myOptions);
      this.mySettings = {
        pullRight: false,
        enableSearch: false,
        checkedStyle: 'glyphicon',
        buttonClasses: 'btn btn-default',
        selectionLimit: 0,
        closeOnSelect: false,
        autoUnselect: true,
        showCheckAll: false,
        showUncheckAll: true,
        dynamicTitleMaxItems: 1,
        maxHeight: '200px'
      };

      this.myTexts = {
        checkAll: 'Check all',
        uncheckAll: 'Uncheck all',
        checked: 'checked',
        checkedPlural: 'checked',
        searchPlaceholder: 'Search...',
        defaultTitle: 'Certifications'
      }
    }
  }

 

  onChangedd($event) {
    console.log($event);
    this.selectedCareerStatus = $event;
  }

  // certificationChange(item, isChecked) {
  //   if (isChecked) {
  //     this.selected_certifications.push(item);
  //     if (item == 'Other') {
  //       this.is_prof_certi_other = true;
  //     }
  //   } else {
  //     let index = this.selected_certifications.indexOf(item);
  //     if (index != -1) {
  //       this.selected_certifications.splice(index, 1);
  //     }
  //     if (item == 'Other') {
  //       this.is_prof_certi_other = false;
  //     }
  //   }
  // }

  removeExternalLinks(index) {
    this.collectionExternalLinks.splice(index, 1);
  }

  removeUserAwards(index) {
    this.collectionUserAwards.splice(index, 1);
    //console.log(this.collectionEducation);
    //return;
    // let body = {
    //   [this.mx.fieldname]: this.collectionUserAwards
    // };

    // let people_id = sessionStorage.getItem("currentUserSlug");
    // if (this.service.publicUser) {
    //   people_id = this.service.publicUser;
    // }
    // this.service.doUserProfilePatch(people_id, body).then(result => {
    // });
  }

  addExternalLinks() {
    if (_.isEmpty(this.exLinks.link_title) || _.isEmpty(this.exLinks.link_url)) {
      this.service.sendAlert('Error', 'please enter title and URL.', 0);
      return;
    }

    let obj = {
      link_title: this.exLinks.link_title,
      link_url: this.exLinks.link_url
    };

    this.collectionExternalLinks.push(obj);
    this.externalLinks.emit(this.collectionExternalLinks);
    this.exLinks.link_title = '';
    this.exLinks.link_url = '';
    this.addmoreCliked = false;
  }

  

  addUserAward() {
    if (_.isEmpty(this.user_award_name_mystory)) {
      //alert('please enter award name');
      this.service.sendAlert('Error', 'please enter award name', 0);
      return;
    }

    let obj = {
      award_name: this.user_award_name_mystory,
      award_url: this.user_award_url
    };
    

    this.collectionUserAwards.push(obj);
    this.awards.emit(this.collectionUserAwards);
    this.user_award_url = '';
    this.user_award_name_mystory = '';
    this.addmoreCliked = false;
    //console.log(this.collectionEducation);
    //return;

    // let body = {
    //   [this.mx.fieldname]: this.collectionUserAwards
    // };

    // //this.toggleVisibitly = true;
    // let people_id = sessionStorage.getItem("currentUserSlug");
    // if (this.service.publicUser) {
    //   people_id = this.service.publicUser;
    // }
    // this.service.doUserProfilePatch(people_id, body).then(result => {
    //   this.mx.data = this.collectionUserAwards;
    //   this.EditToggle = false;
    //   this.showTexbox = true;
    //   this.user_award_url = '';
    //   this.user_award_name_mystory = '';
    // });
  }
  emitOtherCert() {
    if (this.is_prof_certi_other) {
      for (let i = 0; i < this.selected_certifications.length; i++) {
        for (let j = 0; j < this.certifications.length; j++) {
          if (!_.includes(_.map(this.certifications, 'id'), this.selected_certifications[i])) {
            this.selected_certifications.splice(i, 1);
          }
        }
      }
      this.selected_certifications.push(this.prof_certi_other);
      this.certificates.emit([{ 'certifications': this.selected_certifications, 'location': '' }]);
    }
  }

  certificationChange($event) {
    console.log('child', $event);
    // this.cd.detectChanges();
    this.selected_certifications = $event;
    // this.cd.detectChanges();
    if (_.includes($event, 'Other')) {
      this.is_prof_certi_other = true;
    } else {
      this.is_prof_certi_other = false;
    }
    this.certificates.emit([{ 'certifications': $event, 'location': '' }]);
    // console.log('cu',this.selected_certifications);
  }

  // hasProfCert(item) {
  //   // console.log('cu', item, this.selected_certifications);
  //   for (let i = 0; i < this.selected_certifications.length; i++) {
  //     if (_.includes(this.selected_certifications[i].certifications, item)) {
  //       return true;
  //     }
  //     return false;
  //   }
  // }

  removeCertificate(index) {
    this.collectionCertificates.splice(index, 1);
  }

  addCertification() {
    if (_.isEmpty(this.selected_certifications)) {
      //alert('please select certifications');
      this.service.sendAlert('Error', 'please select certifications', 0);
      return;
    }

    if (this.is_prof_certi_other) {
      if (_.includes(this.selected_certifications, 'Other')) {
        let index = this.selected_certifications.indexOf('Other');
        this.selected_certifications.splice(index, 1);
      }
      this.selected_certifications.push(this.prof_certi_other);
    }

    let obj = {
      certifications: this.selected_certifications,
      location: this.certi_location
    }

    this.collectionCertificates.push(obj);

    // let body = {
    //   [this.mx.fieldname]: this.collectionCertificates
    // };

    // //this.toggleVisibitly = true;
    // let people_id = sessionStorage.getItem("currentUserSlug");
    // if (this.service.publicUser) {
    //   people_id = this.service.publicUser;
    // }
    // this.service.doUserProfilePatch(people_id, body).then(result => {

    //   //this.toggleVisibitly = false;
    //   try {
    //     document.getElementsByClassName('cmw')[0]['style'].display = 'inline-block';
    //   } catch (e) { console.log('hide edit btn try'); }

    //   this.mx.data = this.collectionCertificates;
    //   this.EditToggle = false;
    //   this.showTexbox = true;
    //   this.certi_location = '';
    //   this.selected_certifications = [];
    // });
  }

  onChange(item) {
    // if (isChecked) {
    //   this.selectedCareerStatus.push(item);
    // } else {
    //   const index = this.selectedCareerStatus.indexOf(item, 0);
    //   if (index > -1) {
    //     this.selectedCareerStatus.splice(index, 1);
    //   }
    // }
    console.log(item);
  }

  onConditionCheck(item) {
    let index = this.careerStatus.indexOf(item)

    switch (index) {
      case 0:

        this.applyContion([1, 2, 3, 4], item.isLabel);
        break;

      case 1:

        this.applyContion([0, 3, 4], item.isLabel);
        break;

      case 2:

        this.applyContion([0, 3, 4], item.isLabel);
        break;

      case 3:

        this.applyContion([0, 1, 2, 4], item.isLabel);
        break;
      case 4:

        this.applyContion([0, 1, 2, 3], item.isLabel);
        break;

      default:
        break;
    }
  }



  applyContion(arr: number[], ischecked: boolean) {

    if (ischecked) {
      arr.forEach(element => {
        this.careerStatus[element].isDisabled = true;
        this.careerStatus[element].isLabel = false;
      });
    }
    else {
      arr.forEach(element => {
        this.careerStatus[element].isDisabled = false;
        // this.careerStatus[element].isLabel = false;
      });
    }

  }

  hasCareerStatus(item) {
    if (_.includes(this.careerStatus, item)) {
      return true;
    }
    return false;
  }

  ngOnChanges() {
    try {
      if (this.mx.data) {
        console.log("onchanges if my story", " ", this.mx.fieldname, " ", this.mx.data);
        this.showTexbox = true;
        if (this.mx.fieldname == 'q7_ans') {
          // for (let i = 0; i < this.mx.data.split(',').length; i++) {
          //   this.tags.push(this.mx.data.split(',')[i]);
          // }
          this.tags = this.mx.data;
          // this.tag = this.mx.data.toString();
        }
        if (this.mx.fieldname == 'career_status') {
          this.selectedCareerStatus = this.mx.data;
          for (let i = 0; i < this.selectedCareerStatus.length; i++) {
            for (let j = 0; j < this.careerStatus.length; j++) {
              if (this.careerStatus[j].id == this.selectedCareerStatus[i]) {
                this.careerStatus[j].isLabel = true;
              }
            }
          }
          // if (!this.isEnginner) {
          //   this.careerStatus = this.nonEngineersCareerStatus;
          // }
        }
        if (this.mx.fieldname == 'user_awards') {
          this.awards.emit(this.mx.data);
        }

        if (this.mx.fieldname == 'professional_certification') {
          // this.selected_certifications = this.mx.data;
          // console.log('baghu', this.selected_certifications);
          this.collectionCertificates = this.mx.data;
          let arr = [];
          for (let i = 0; i < this.mx.data.length; i++) {
            for (let j = 0; j < this.mx.data[i].certifications.length; j++) {
              if (!_.includes(_.map(this.certifications, 'id'), this.mx.data[i].certifications[j])) {
                arr.push('Other');
                this.prof_certi_other = this.mx.data[i].certifications[j];
                this.is_prof_certi_other = true;
              } else {
                arr.push(this.mx.data[i].certifications[j]);
              }
            }
            this.selected_certifications1 = arr;
            console.log('arr', arr)
            this.selected_certifications.push({ 'certifications': arr, 'location': '' });
          }

          this.certificates.emit(arr);
          console.log('baghu', this.selected_certifications);
        }
        // this.mx.data = this.addLineBreaks(this.mx.data);
        if (this.mx.fieldname == 'user_awards') {
          this.collectionUserAwards = this.mx.data;
        }
        if (this.mx.fieldname == 'external_links') {
          this.collectionExternalLinks = this.mx.data;
          this.externalLinks.emit(this.collectionExternalLinks);
        }
      } else {
        console.log("onchanges else", " ", this.mx.fieldname, " ", this.mx.data);
        this.showTexbox = false;
      }
    } catch (e) { //console.log("e",e); 
      this.showTexbox = false;
    }
    if (!this.edit) {
      this.EditToggle = false;
    }
  }


  showText() {
    this.showTexbox = true;
    this.EditToggle = true;
  }


  hideBtn() {
    //document.getElementsByClassName('cmw')[0]['style'].display = 'none';
  }

  tag: any;

  addTag() {
    if (this.tag) {
      let lastChar = this.tag.charAt(this.tag.length - 1);
      let firstChar = this.tag.charAt(0);
      if (lastChar == ',') {
        this.tag = this.tag.substr(0, this.tag.length - 1);
      }
      if (firstChar == ',') {
        this.tag = this.tag.slice(1, this.tag.length);
      }
      // this.tags = [];
      for (let i = 0; i < this.tag.split(',').length; i++) {
        this.tags.push(this.tag.split(',')[i]);
      }
      let body = {
        q7_ans: this.tags
      };
      let people_id = sessionStorage.getItem("currentUserSlug");
      if (this.service.publicUser) {
        people_id = this.service.publicUser;
      }
      this.service.doUserProfilePatch(people_id, body).then(result => {
        if (!this.tag.trim()) {
          console.log('false me');
          this.showTexbox = false;
        } else {
          console.log('true me');
          this.showTexbox = true;
        }
        this.tag = '';
        this.mx.data = this.tags;
        this.EditToggle = false;
      });
    }
  }

  updateCareerStatus() {

    let people_id = sessionStorage.getItem("currentUserSlug");
    if (this.service.publicUser) {
      people_id = this.service.publicUser;
    }
    let body = {
      career_status: this.selectedCareerStatus
    };
    this.service.doUserProfilePatch(people_id, body).then(result => {
      if (_.isEmpty(this.selectedCareerStatus)) {
        console.log('false me');
        this.showTexbox = false;
      } else {
        console.log('true me');
        this.showTexbox = true;
      }
      this.mx.data = this.selectedCareerStatus;
      this.EditToggle = false;
    });
  }

  removeCareer(index) {
    this.selectedCareerStatus.splice(index, 1);
    this.updateCareerStatus();
  }

  removeHobbies(i) {
    // console.log(this.data);
    this.tags.splice(i, 1);
    // console.log(this.tags);
    // let body = {
    //   q7_ans: this.tags
    // };
    // let people_id = sessionStorage.getItem("currentUserSlug");
    // if (this.service.publicUser) {
    //   people_id = this.service.publicUser;
    // }
    // this.service.doUserProfilePatch(people_id, body).then(result => {
    //   console.log('deleted');
    // });
  }

  onEnterDesc() {
    // if(!value.trim()){
    //   alert('field cannot be blank');
    //   console.log("empty dala");
    //   return;
    // }
    // console.log(this.Elem.nativeElement.value);
    // return;
    let para;
    if (this.Elem.nativeElement.value.trim()) {
      para = this.service.addLineBreaks(this.Elem.nativeElement.value);
    } else {
      para = '';
    }

    let body = {
      [this.mx.fieldname]: para
    };

    //this.toggleVisibitly = true;
    let people_id = sessionStorage.getItem("currentUserSlug");
    if (this.service.publicUser) {
      people_id = this.service.publicUser;
    }
    this.service.doUserProfilePatch(people_id, body).then(result => {

      //this.toggleVisibitly = false;
      try {
        document.getElementsByClassName('cmw')[0]['style'].display = 'inline-block';
      } catch (e) { console.log('hide edit btn try'); }
      if (!this.Elem.nativeElement.value.trim()) {
        this.showTexbox = false;
      } else {
        this.showTexbox = true;
      }
      this.mx.data = this.Elem.nativeElement.value;
      this.EditToggle = false;
      console.log(result);
    });
  }
}

interface Link {
  link_title: string;
  link_url: string;
}

interface IMultiSelectSettings {
  pullRight?: boolean;
  enableSearch?: boolean;
  checkedStyle?: 'checkboxes' | 'glyphicon' | 'fontawsome';
  buttonClasses?: string;
  selectionLimit?: number;
  closeOnSelect?: boolean;
  autoUnselect?: boolean;
  showCheckAll?: boolean;
  showUncheckAll?: boolean;
  dynamicTitleMaxItems?: number;
  maxHeight?: string;
}

interface IMultiSelectTexts {
  checkAll?: string;
  uncheckAll?: string;
  checked?: string;
  checkedPlural?: string;
  searchPlaceholder?: string;
  defaultTitle?: string;
}
