/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dropdown.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/angular-2-dropdown-multiselect/dropdown/dropdown.component.ngfactory";
import * as i3 from "angular-2-dropdown-multiselect/dropdown/search-filter.pipe";
import * as i4 from "angular-2-dropdown-multiselect/dropdown/dropdown.component";
import * as i5 from "@angular/forms";
import * as i6 from "./multiselectdropdown.component";
var styles_MultiselectDropdown = [i0.styles];
var RenderType_MultiselectDropdown = i1.ɵcrt({ encapsulation: 0, styles: styles_MultiselectDropdown, data: {} });
export { RenderType_MultiselectDropdown as RenderType_MultiselectDropdown };
export function View_MultiselectDropdown_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "ss-multiselect-dropdown", [], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], ["document", "click"], ["document", "touchstart"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("document:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("document:touchstart" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onClick($event.target) !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.selectedOptions = $event) !== false);
        ad = (pd_2 && ad);
    } if (("ngModelChange" === en)) {
        var pd_3 = (_co.onChangex($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i2.View_MultiselectDropdown_0, i2.RenderType_MultiselectDropdown)), i1.ɵprd(512, null, i3.MultiSelectSearchFilter, i3.MultiSelectSearchFilter, []), i1.ɵdid(2, 1032192, null, 0, i4.MultiselectDropdown, [i1.ElementRef, i5.FormBuilder, i3.MultiSelectSearchFilter, i1.IterableDiffers], { options: [0, "options"], settings: [1, "settings"], texts: [2, "texts"] }, null), i1.ɵprd(1024, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.MultiselectDropdown]), i1.ɵdid(4, 671744, null, 0, i5.NgModel, [[8, null], [8, null], [8, null], [6, i5.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i5.NgControl, null, [i5.NgModel]), i1.ɵdid(6, 16384, null, 0, i5.NgControlStatus, [[4, i5.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.myOptions; var currVal_8 = _co.mySettings; var currVal_9 = _co.myTexts; _ck(_v, 2, 0, currVal_7, currVal_8, currVal_9); var currVal_10 = _co.selectedOptions; _ck(_v, 4, 0, currVal_10); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 6).ngClassValid; var currVal_5 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_MultiselectDropdown_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "my-dropdown", [], null, null, null, View_MultiselectDropdown_0, RenderType_MultiselectDropdown)), i1.ɵdid(1, 638976, null, 0, i6.MultiselectDropdown, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MultiselectDropdownNgFactory = i1.ɵccf("my-dropdown", i6.MultiselectDropdown, View_MultiselectDropdown_Host_0, { mySettings: "mySettings", myOptions: "myOptions", defaultText: "defaultText", selectedOptions: "selectedOptions", makeArrayEmpty: "makeArrayEmpty" }, { selectedArray: "selectedArray" }, []);
export { MultiselectDropdownNgFactory as MultiselectDropdownNgFactory };
