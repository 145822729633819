import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { profileMiniModel } from './profileMiniModel';

@Component({
  selector: 'app-profile-mini-card',
  templateUrl: './profile-mini-card.component.html',
  styleUrls: ['./profile-mini-card.component.scss']
})
export class ProfileMiniCardComponent implements OnInit {

  @Input() profileMiniData?:profileMiniModel;
  
  constructor(private router:Router) { }

  ngOnInit(): void {
    // console.log(this.profileMiniData);

  }

  goToProfile(slug,e){
    console.log(slug);
    // e.stopPropagation();
    this.router.navigate(['/userprofile/'+ slug])
  }

}
