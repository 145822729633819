import { Router, ActivatedRoute } from '@angular/router';

import {
  Component,
  NgZone,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
  ChangeDetectorRef,
  ViewEncapsulation,
  PLATFORM_ID,
  Inject
} from '@angular/core';

import {
  trigger,
  state,
  style,
  animate,
  transition,
  keyframes
} from '@angular/animations'

import { Title } from '@angular/platform-browser';

import {
  ProjectService
}
  from './../projectservice';

import {
  CookieService
} from 'angular2-cookie/core';
import { windowToggle } from 'rxjs/operator/windowToggle';
import { isPlatformBrowser } from '@angular/common';
import { AuthService } from '../new-repicture/auth/auth.service';
declare var jQuery: any;

@Component({
  selector: 'navbar',
  templateUrl: './navbar.html',
  styleUrls: ['./navbar.scss'],
  animations: [
    trigger('fadeInOut', [
      transition('void => *', [
        style({
          left: -408
        }), //style only for transition transition (after transiton it removes)
        animate(200, style({
          left: 0
        })) // the new state of the transition(after transiton it removes)
      ]),
      transition('* => void', [
        style({
          left: 0
        }),
        animate(200, style({
          left: -408
        })) // the new state of the transition(after transiton it removes)
      ])
    ]),
    trigger('LegendFlyout', [
      transition('void => *', [
        style({
          right: -200
        }), //style only for transition transition (after transiton it removes)
        animate(200, style({
          right: 0
        })) // the new state of the transition(after transiton it removes)
      ]),
      transition('* => void', [
        style({
          right: 0
        }),
        animate(200, style({
          right: -200
        })) // the new state of the transition(after transiton it removes)
      ])
    ])
  ],
})
export class NavbarComponent {

  @Input() loginstatus: boolean;
  @Input() addOrg: boolean;
  // @Input() loginstatus: boolean;
  @Input() checkUserStateInput: boolean = false;
  @Output() changeInLogin = new EventEmitter<any>();
  @Output() logout1 = new EventEmitter<any>();
  @Output() messagingLogout = new EventEmitter<any>();
  
  @ViewChild('loginModal', { static: false }) btn: ElementRef;
  imagesrc: any;
  profileURL: any;
  url: any = "";
  showModal: boolean = false;
  validToggle: any;
  legend: boolean = false; flag: number = 0;
  show: boolean = false;
  pageTitle: any;
  company_id: any;
  @Input() changeNavItem: any;
  showAddProject: boolean = false;
  showAddPerson: boolean = false;
  showAddOrg: boolean = false;
  constructor(private authService:AuthService,public service: ProjectService, private router: Router, private cookie: CookieService, private cd: ChangeDetectorRef, private title: Title, @Inject(PLATFORM_ID) private platform: any) {
    if (!localStorage.getItem("peopleid") && !localStorage.getItem('full_name_slug')) {
      this.logout();
      return;
    } else {
      this.service.getPeopleDetail(localStorage.getItem("full_name_slug")).subscribe(res => {
      
        this.service.loggedInUserSetter(res);
        // if (!res['user_agreement']) {
        //   this.router.navigate(['/agreement']);
        // }
        if (res['is_superuser']) {
          this.service.isAdmin = true;
        } else if (res['full_name_slug'] === 'katherine' || res['full_name_slug'] === 'dani') {
          this.service.semiAdmin = true;
        } else {
          this.service.semiAdmin = false;
          this.service.isAdmin = false;
        }
        if (res['user_type'] == 2) {
          this.service.isTeachingAssistant = true;
          this.service.teachingAssistant = res['id'];
        } else {
          this.service.isTeachingAssistant = false;
        }
        this.company_id = res['org_name_slug'];
        this.profileURL = localStorage.getItem("full_name_slug");
        if (res['org_name_slug']) {
          this.service.myOrg = res['org_name_slug'];
        } else {
          this.service.myOrg = '';
        }
        if (res['linkedin_signup']) {
          this.imagesrc = res['linkedinProfileImageURL'];
          localStorage.setItem('profileImage', this.imagesrc);
        } else if (res['profileImage_thumbnail_v1']) {
          this.imagesrc = res['profileImage_thumbnail_v1'];
          localStorage.setItem('profileImage', this.imagesrc);
        } else {
          this.imagesrc = 'assets/images/default.png';
          localStorage.setItem('profileImage', this.imagesrc);
        }
        localStorage.setItem("first_name", res['first_name']);
        localStorage.setItem("last_name", res['last_name']);
        // localStorage.setItem("username", res['email']);
      });
    }
  }


  ngOnInit() {
    if (localStorage.getItem('authtoken')) {
      this.loginstatus = true;
      this.authService.login();
    }
    // below code is for login to app restriction
    // else {
    //   this.loginstatus = false;
    //   if (this.title.getTitle() != 'Reset Password')
    //     this.router.navigate(['']);
    // }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.pageTitle = this.title.getTitle();
    }, 0);

    // setTimeout(() => {
    //   if (isPlatformBrowser(this.platform)) {
    //     this.show = true;
    //     let elem = document.createElement('feedback');
    //     document.getElementById('feedback').appendChild(elem);
    //   }
    // }, 1000);
  }

  sab() {
    this.router.navigateByUrl('userprofile/' + this.profileURL);
    // userprofile
  }

  sendEvt(item) {
    this.service.sendEvent('click', 'redirect from menu item', 'clicked item: ' + item);
  }

  // sendEvt1(item) {
  //   this.service.explorer_dd = 'companies';
  //   setTimeout(()=> {
  //     this.router.navigate(['explorer']);
  //   }, 500)
  // }

  logout() {
    if (isPlatformBrowser(this.platform)) {
      this.cookie.removeAll();
    }
    localStorage.clear();
    this.loginstatus = false;
    this.authService.logout();
    this.service.projectInfoShow = true;
    this.changeInLogin.emit(false);
    this.messagingLogout.emit(false);
    this.service.isAdmin = false;
    this.service.teachingAssistant = '';
    this.service.isTeachingAssistant = false;
    if (this.pageTitle == 'Account' || this.pageTitle == 'Manage projects') {
      this.router.navigate(['']);
    }
    if (this.pageTitle == 'Create project | Repicture App' || this.pageTitle == 'Create person') {
      this.logout1.emit(false);
    }
    //below code is for login to app restriction
    // this.router.navigate(['']);
  }

  ngOnChanges() {
    // console.log('ngchnage');
    // if(this.checkUserStateInput == true){
    //   console.log('ngchnage1');
    //   setTimeout(()=> {
    //     document.getElementById('loginModal').click();
    //     this.cd.detectChanges();
    //     this.btn.nativeElement.click();
    //     this.cd.detectChanges();
    //   }, 4);
    // }else {
    // if (window.localStorage.getItem('authtoken')) {
    //   this.loginstatus = true;
    //   this.service.getPeopleDetail(window.localStorage.getItem("full_name_slug")).then(res => {
    //     this.profileURL = window.localStorage.getItem("full_name_slug");
    //     this.company_id = res['org_name_slug'];
    //     if (res['org_name_slug']) {
    //       this.service.myOrg = res['org_name_slug'];
    //     } else {
    //       this.service.myOrg = '';
    //     }
    //     if (res['profileImage_thumbnail_v1']) {
    //       this.imagesrc = res['profileImage_thumbnail_v1'];
    //       window.localStorage.setItem('profileImage', this.imagesrc);
    //     } else {
    //       this.imagesrc = 'assets/images/default.png';
    //       window.localStorage.setItem('profileImage', this.imagesrc);
    //     }
    //   });
    // }
    // else {
    //   this.loginstatus = false;
    //   if (this.pageTitle == 'Account' || this.pageTitle == 'Manage projects') {
    //     this.router.navigate(['']);
    //   }
    // }

    // if (this.pageTitle == "Projects list | Repicture App") {
    //   if (this.changeNavItem == 'projects') {
    //     this.showAddOrg = false;
    //     this.showAddPerson = false;
    //     this.showAddProject = true;
    //   } else if (this.changeNavItem == 'people') {
    //     this.showAddProject = false;
    //     this.showAddOrg = false;
    //     this.showAddPerson = true;
    //   } else if (this.changeNavItem == 'companies') {
    //     this.showAddProject = false;
    //     this.showAddPerson = false;
    //     this.showAddOrg = true;
    //   }
    // }
  }
  // }

  dd() {
    if (this.pageTitle == 'Projects list' && this.flag == 0) {
      jQuery('#bs-example-navbar-collapse-1').addClass('bd');
      this.flag = 1;
    } else if (this.pageTitle == 'Projects list' && this.flag == 1) {
      jQuery('#bs-example-navbar-collapse-1').removeClass('bd');
      this.flag = 0;
    }
  }

  checkState($event) {
    if ($event) {
      this.changeInLogin.emit(true);
      this.loginstatus = true;
      this.service.getPeopleDetail(localStorage.getItem("full_name_slug")).subscribe(res => {
        this.profileURL = localStorage.getItem("full_name_slug");
        this.company_id = res['org_name_slug'];
        if (res['org_name_slug']) {
          this.service.myOrg = res['org_name_slug'];
        } else {
          this.service.myOrg = '';
        }
        if (res['profileImage_thumbnail_v1']) {
          this.imagesrc = res['profileImage_thumbnail_v1'];
          localStorage.setItem('profileImage', this.imagesrc);
        } else {
          this.imagesrc = 'assets/images/default.png';
          localStorage.setItem('profileImage', this.imagesrc);
        }
      });
    }
    else {
      this.loginstatus = false;
      if (this.pageTitle == 'Account' || this.pageTitle == 'Manage projects') {
      

        this.router.navigate(['']);
      }
    }
  }

  goToUrl(url,fragment) {
    this.router.navigate([url], { fragment:  fragment});

  }

  // toggleList1() {
  //   if (this.legend) {
  //     this.legend = false;
  //     document.getElementById("main").style.right = '-352px';
  //   } else {
  //     this.legend = true;
  //     document.getElementById("main").style.right = '0px';
  //   }
  // }

}