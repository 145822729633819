<!-- <navbar></navbar> -->
<!-- <div class="navbarCover"></div> -->
<div class="container" style="margin-top: 100px;">
    <div class="text-center" *ngIf="expired">
        <div class="alert alert-danger">
            <strong>Failed!</strong> This verify link has been expired, please try creating a new account.
        </div>
    </div>
    <div *ngIf="loading">
        <h2 class="text-center">Please wait while we authenticate you!</h2>
    </div>
    <div class="loader1" *ngIf="loading"></div>
</div>
<style>
    @font-face {
        font-family: OpenSans-Regular;
        src: url('/assets/fonts/new/opensans-regular-webfont.eot?#iefix') format('embedded-opentype'), /* IE */
        url('/assets/fonts/new/opensans-regular-webfont.ttf') format("truetype");
        /* non-IE */
    }
    
    @font-face {
        font-family: OpenSans-Light;
        src: url('/assets/fonts/new/opensans-light-webfont.eot?#iefix') format('embedded-opentype'), /* IE */
        url('/assets/fonts/new/opensans-light-webfont.ttf') format("truetype");
        /* non-IE */
    }
    
    @font-face {
        font-family: OpenSans-Bold;
        src: url('/assets/fonts/new/opensans-bold-webfont.eot?#iefix') format('embedded-opentype'), /* IE */
        url('/assets/fonts/new/opensans-bold-webfont.ttf') format("truetype");
        /* non-IE */
    }
    
    @font-face {
        font-family: OpenSans-ExtraBold;
        src: url('/assets/fonts/new/opensans-extrabold-webfont.eot?#iefix') format('embedded-opentype'), /* IE */
        url('/assets/fonts/new/opensans-extrabold-webfont.ttf') format("truetype");
        /* non-IE */
    }
    
    @font-face {
        font-family: OpenSans-Semibold;
        src: url('/assets/fonts/new/opensans-semibold-webfont.eot?#iefix') format('embedded-opentype'), /* IE */
        url('/assets/fonts/new/opensans-semibold-webfont.ttf') format("truetype");
        /* non-IE */
    }
    
    .alert {
        width: auto;
        display: inline-block;
    }
    
    .navbarCover {
        background-color: transparent;
        height: 60px;
        position: absolute;
        width: 100%;
        top: 0;
    }
    
    .loader1 {
        border: 4px solid #f3f3f3;
        border-top: 4px solid #3498db;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        animation: spin 1s linear infinite;
        position: absolute;
        top: 35%;
        left: 49%;
    }
    
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
</style>