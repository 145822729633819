<div *ngIf="showTexbox" [@fadeInOut]="showTexbox">
    <div class="fadeinout" [@fadeInOut]="!EditToggle" *ngIf="!EditToggle && fields == 'name'">
        <span class="regular" style="font-size: 2.5em;">{{ mx.data }}</span>
        <span *ngIf="edit" (click)="EditToggle = !EditToggle;" class="glyphicon glyphicon-pencil"></span>
    </div>
    <div class="fadeinout" [@fadeInOut]="!EditToggle" *ngIf="!EditToggle && fields == 'user_designation'"
        style="margin-bottom:7px;">
        <span class="water-res" style="color:rgba(26, 73, 109, 255);font-family: open-regular;font-size: 1.2em;
        letter-spacing: 2px;text-transform: uppercase;">{{ mx.data }}</span>
        <span *ngIf="edit" (click)="EditToggle = !EditToggle; set" class="glyphicon glyphicon-pencil"></span>
    </div>
    <div class="fadeinout" [@fadeInOut]="!EditToggle" *ngIf="!EditToggle && fields == 'professional_certification'"
        style="margin-bottom:7px;">
        <span *ngIf="collectionCertificates.length > 0" style="font-family: OpenSans-SemiBold;">&#8226;
            Certification:</span>&nbsp;
        <span *ngFor="let item of collectionCertificates; let i = index" class="water-res"
            style="color:rgba(26, 73, 109, 255);font-family: open-regular;">
            <span *ngIf="getType(item) == 'object'">
            <span class="certif1" *ngFor="let item1 of item.certifications">{{ item1 }}</span>
            </span>
            <span *ngIf="getType(item) != 'object'" class="certif1">{{ item }}</span>
            <!-- <span *ngIf="item.location"> - {{item.location}}</span> -->
        </span>
        <!--<span *ngIf="edit" (click)="EditToggle = !EditToggle;" class="glyphicon glyphicon-pencil"></span>-->
        <a *ngIf="edit" (click)="EditToggle = !EditToggle;" style="font-size: 13px; cursor: pointer;">Add more</a>
    </div>
    <div class="fadeinout" [@fadeInOut]="!EditToggle" *ngIf="!EditToggle && fields == 'user_experience'"
        style="margin-bottom:7px;">
        <span style="font-family: OpenSans-SemiBold;">&#8226; Work Experience:</span>&nbsp;
        <span class="exp-desc"
            style="color:rgba(26, 73, 109, 255);font-family: open-regular;font-size:14px;">{{ mx.data }}</span>&nbsp;
        <span
            *ngIf="mx.data != 'Retired' && mx.data != 'High School Student' && mx.data != 'College Student: Graduating within 1 year' && mx.data != 'Graduating within 1 year' && mx.data != 'College Student: Graduating more than 1 year' && mx.data != 'Graduating more than 1 year'"
            class="exp-desc" style="color:rgba(26, 73, 109, 255);font-family: open-regular;font-size:14px;">years</span>
        <span *ngIf="edit" (click)="EditToggle = !EditToggle;" class="glyphicon glyphicon-pencil"></span>
    </div>
    <div class="fadeinout" [@fadeInOut]="!EditToggle" *ngIf="!EditToggle && fields == 'birth_death'"
        style="margin-bottom:7px;">
        <span class="exp-desc"
            style="color:rgba(26, 73, 109, 255);font-family: open-regular;font-size:14px;">{{ mx.data }}</span>
        <span *ngIf="edit" (click)="EditToggle = !EditToggle;" class="glyphicon glyphicon-pencil"></span>
    </div>
    <div class="fadeinout" [@fadeInOut]="!EditToggle" *ngIf="!EditToggle && fields == 'user_education'"
        style="margin-bottom:7px;">
        <div *ngFor="let item of collectionEducation; let i = index" class="water-res"
            style="color:rgba(26, 73, 109, 255);font-family: open-regular;font-size:1.2em !important;">
            <span *ngIf="item.school" class="">{{ item.school }} - </span>
            <span *ngIf="item.degrees?.length > 0">
                <span class="certi1" *ngFor="let item1 of item.degrees">{{ item1 }}</span>
            </span>
            <span *ngIf="item.degrees?.length > 0">&nbsp;</span>
            <span *ngIf="item.field_of_study" class="certi1">{{ item.field_of_study }}</span>
            <span *ngIf="item.year" class="">{{ item.year }}</span>
            <span *ngIf="edit" class="glyphicon glyphicon-minus-sign" (click)="removeEducation(i)"></span>
        </div>
        <a *ngIf="edit" (click)="EditToggle = !EditToggle;" style="font-size: 13px; cursor: pointer;">Add more</a>
        <!--<br *ngIf="edit"><a *ngIf="edit" class="awardsmore" (click)="addmore(track)">Add more</a>-->
    </div>
    <div style="margin-top: 15px;" class="fadeinout" [@fadeInOut]="!EditToggle"
        *ngIf="!EditToggle && fields == 'social_network'">
        <span style="color: #333;font-family: OpenSans-SemiBold;">
            <span>&#8226; Find me on:</span>&nbsp;
            <span class="types" *ngFor="let item of socialNetworkArr; let i = index;">
                <a *ngIf="item.key != 'Other'" target="_blank" href="{{ item.value }}"
                    style="color: rgb(70, 165, 247); text-decoration: none;">{{item.key}}</a>
                <a *ngIf="item.key == 'Other'" target="_blank" href="{{ item.value.displayURL }}"
                    style="color: rgb(70, 165, 247); text-decoration: none;">{{item.value.displayText}}</a>
                <i *ngIf="edit" class="glyphicon glyphicon-minus-sign" (click)="deleteSocial(item.key, i)"
                    style="color: black"></i>
            </span>
        </span>
        <span *ngIf="edit && socialNetworkArr?.length < 4" (click)="EditToggle = !EditToggle;" class="addmore">Add
            more</span>
    </div>
    <div class="fadeinout" [@fadeInOut]="!EditToggle" *ngIf="!EditToggle && fields == 'user_quotes'"
        style="margin-bottom:15px;">
        <span class="regular" style="font-size: 15px;">
            <span style="font-family: OpenSans-SemiBold;">&#8226; Favorite Quote or
                Motto:</span>&nbsp;<span style="font-style: italic;">{{mx.data }}</span></span>

        <span *ngIf="edit" (click)="EditToggle = !EditToggle;" class="glyphicon glyphicon-pencil"></span>
    </div>
    <div class="fadeinout" [@fadeInOut]="!EditToggle" *ngIf="!EditToggle && fields == 'user_awards'">
        <div>
            <span style="font-family: OpenSans-SemiBold;">&#8226; Awards:</span>&nbsp;
            <ul>
                <li class="awards" style="list-style-type: none;"
                    *ngFor="let item of collectionUserAwards; let i = index;">
                    <span style="font-family: OpenSans-SemiBold;">&#8226;</span>&nbsp;
                    <a *ngIf="item.award_url" target="_blank" href="{{ item.award_url }}"
                        style="font-family: open-regular;font-size:14px; text-decoration: none;">{{item.award_name}}</a>
                    <a *ngIf="!item.award_url"
                        style="color:rgba(26, 73, 109, 255);font-family: open-regular;font-size:14px; text-decoration: none; cursor:text;">{{item.award_name}}</a>
                    <i *ngIf="edit" class="glyphicon glyphicon-minus-sign" (click)="removeUserAwards(i)"
                        style="color: black"></i>
                </li>
            </ul>
            <!-- <div class="awards" *ngFor="let item of collectionUserAwards; let i = index;">
                    <span style="font-family: OpenSans-SemiBold;">&#8226;</span>&nbsp;
                <a *ngIf="item.award_url" target="_blank" href="{{ item.award_url }}"
                    style="font-family: open-regular;font-size:14px; text-decoration: none;">{{item.award_name}}</a>
                <a *ngIf="!item.award_url"
                    style="color:rgba(26, 73, 109, 255);font-family: open-regular;font-size:14px; text-decoration: none; cursor:text;">{{item.award_name}}</a>
                <i *ngIf="edit" class="glyphicon glyphicon-minus-sign" (click)="removeUserAwards(i)"
                    style="color: black"></i>
            </div> -->
        </div>
        <a *ngIf="edit" (click)="EditToggle = !EditToggle;" style="cursor: pointer; font-size: 13px;">Add more</a>
    </div>
    <div [@fadeInOut]="EditToggle" *ngIf="EditToggle && edit">
        <div *ngIf="fields == 'name'" style="margin-bottom: 10px;">
            <input id="desc1" type="text" class="txt-data1" value="{{ mx.data }}" placeholder="{{ mx.placeholder }}"
                style="margin-bottom: 5px;">
            <i (click)="onEnterDesc()" class="glyphicon glyphicon-ok"></i>
        </div>
        <div *ngIf="fields == 'user_designation'" style="margin-bottom: 10px;">
            Professional role:
            <select id="desc1" [(ngModel)]="designation" #peopleSelect class="form-control txt-select"
                (change)="onEnterDesc()">

                <option value="">Select role</option>

                <option value="Civil Engineer">Civil Engineer</option>

                <option value="Architect">Architect</option>

                <option value="Construction Contractor">Construction Contractor</option>

                <option value="Developer">Developer</option>

                <option value="Electrical Engineer">Electrical Engineer</option>

                <option value="Chemical Engineer">Chemical Engineer</option>

                <option value="Environmental Consultant">Environmental Consultant</option>

                <option value="Estimator">Estimator</option>

                <option value="Landscape Architect">Landscape Architect</option>

                <option value="Mechanical Engineer">Mechanical Engineer</option>

                <option value="Owner">Owner</option>

                <option value="Planner">Planner</option>

                <option value="Surveyor">Surveyor</option>

                <option value="Software Engineer">Software Engineer</option>

                <option value="Artist">Artist</option>

                <option value="Designer">Designer</option>

                <option value="Other">Other</option>

            </select>
            <input *ngIf="peopleSelect.value == 'Other'" id="desc2" type="text" class="txt-data1"
                style="margin-top: 5px;" placeholder="Enter other role" />
            <i *ngIf="peopleSelect.value == 'Other'" class="glyphicon glyphicon-ok"
                (click)="otherClicked = true;onEnterDesc()"></i>
        </div>
        <div *ngIf="fields == 'professional_certification'" style="margin-bottom: 10px;">
            Professional certification:
            <br>
            <div *ngFor="let item of collectionCertificates; let i = index" class="water-res"
                style="color:rgba(26, 73, 109, 255);font-family: open-regular;">
                <span class="certif1" *ngFor="let item1 of item.certifications">{{ item1 }}</span>
                <!-- <span *ngIf="item.location"> - {{item.location}}</span> -->
            </div>
            <div>
                <my-dropdown [defaultText]="'Certifications'" [selectedOptions]="selected_certifications"
                    [myOptions]="certifications" (selectedArray)="certificationChange($event)"></my-dropdown>
            </div>
            <input *ngIf="is_prof_certi_other" [(ngModel)]="prof_certi_other" type="text" class="txt-data1"
                style="margin-top: 5px;" placeholder="Enter other certification" />
            <input [(ngModel)]="certi_location" type="text" class="txt-data1"
                style="margin-top: 5px;width: 93%;font-size: 13px;"
                placeholder="Enter state(s)/location(s) where applicable" />
            <i class="glyphicon glyphicon-ok" (click)="addCertification()"></i>
        </div>
        <div *ngIf="fields == 'user_experience'" style="margin-bottom: 10px;">
            Years experience:
            <select #sel *ngIf="fields == 'user_experience'" class="form-control txt-select"
                (change)="expChange(sel.value)" style="margin-bottom: 5px; width: 35%;">
                <option *ngFor="let item of exp">{{ item }}</option>
            </select>
        </div>
        <div *ngIf="fields == 'birth_death'" style="margin-bottom: 10px;">
            Birth year:
            <input [(ngModel)]="birth_year" type="text" class="txt-data1" style="margin-top: 5px;"
                placeholder="Enter birth year" />
            <br> Death year:
            <input [(ngModel)]="death_year" type="text" class="txt-data1" style="margin-top: 5px;"
                placeholder="Enter death year" />
            <i class="glyphicon glyphicon-ok" (click)="addBirthDeath()"></i>
        </div>
        <div *ngIf="fields == 'user_education'" style="margin-bottom: 10px;">
            Education:
            <br>
            <!--<div *ngFor="let item of collectionEducation" >-->
            <div *ngFor="let item of collectionEducation; let i = index" class="water-res"
                style="color:rgba(26, 73, 109, 255);font-family: open-regular;">
                <span *ngIf="item.school" class="">{{ item.school }} - </span>
                <span *ngIf="item.degrees?.length > 0">
                    <span class="certi1" *ngFor="let item1 of item.degrees">{{ item1 }}</span>
                </span>
                <span *ngIf="item.field_of_study" class="certi1">{{ item.field_of_study }}</span>
                <span *ngIf="item.year" class="">{{ item.year }}</span>
                <span class="glyphicon glyphicon-minus-sign" (click)="removeEducation(i)"></span>
            </div>
            <input [(ngModel)]="school" type="text" class="txt-data1" placeholder="Enter school" />
            <div style="display: inline-block;">
                <my-dropdown [defaultText]="'Degrees'" [selectedOptions]="selected_degrees" [myOptions]="degrees"
                    (selectedArray)="degreesChange($event)"></my-dropdown>
            </div>
            <input *ngIf="is_degree_other" [(ngModel)]="degree_other" type="text" class="txt-data1"
                style="margin-top: 5px;" placeholder="Enter other degree" />
            <br>
            <input [(ngModel)]="field_of_study" type="text" class="txt-data1"
                placeholder="Enter Major/Field of study" />
            <input [(ngModel)]="year" type="number" maxlength="4" class="txt-data1" style="margin-top: 5px;"
                placeholder="Enter year completed" />
            <!--</div>-->
            <i class="glyphicon glyphicon-ok" (click)="addEducation()"></i>
        </div>
        <div *ngIf="fields == 'user_awards'" style="margin-bottom: 10px;">
            Awards:
            <br>
            <div style="margin-bottom: 5px;">
                <span class="awards" *ngFor="let item of collectionUserAwards; let i = index;">
                    <a target="_blank" href="{{ item.award_url }}"
                        style="color:rgba(26, 73, 109, 255);font-family: open-regular;font-size:14px; text-decoration: none;">{{item.award_name}}</a>
                    <i *ngIf="edit" class="glyphicon glyphicon-minus-sign" (click)="removeUserAwards(i)"
                        style="color: black"></i>
                </span>
            </div>
            <input [(ngModel)]="user_award_name" type="text" class="txt-data1" placeholder="Enter award name" />
            <input [(ngModel)]="user_award_url" type="text" class="txt-data1" style="margin-top: 5px;"
                placeholder="Enter link to award announcement" />
            <i class="glyphicon glyphicon-ok" (click)="addUserAward()"></i>

        </div>
        <div *ngIf="fields == 'social_network'">
            <div>
                <span>Find me on: </span>
                <span class="types" *ngFor="let item of socialNetworkArr; let i = index;">
                    <a target="_blank" href="{{ item.value }}"
                        style="color: rgb(70, 165, 247); text-decoration: none;">{{item.key}}</a>
                    <i *ngIf="edit" class="glyphicon glyphicon-minus-sign" (click)="deleteSocial(item.key, i)"
                        style="color: black"></i>
                </span>
            </div>
            <select #selSocial style="margin-bottom: 5px;" (change)="socialChange($event)"
                class="form-control txt-select" style="width: 30%;">
                <option *ngFor="let item of socialArr" value="{{item}}">{{item}}</option>
            </select>&nbsp;
            <span *ngIf="!other">
                <input id="socialURL" #socialURL type="text" class="txt-data1" placeholder="Enter Complete URL"
                    style="border-radius: 4px;">
                <i class="glyphicon glyphicon-ok" (click)="updateSocialNetwork(selSocial.value, socialURL.value)"></i>
            </span>
            <div *ngIf="other">
                <input #socialTextOther type="text" class="txt-data1" placeholder="Enter text to display">&nbsp;
                <input id="socialURL" #socialURL type="text" class="txt-data1" placeholder="Enter Complete URL">
                <i class="glyphicon glyphicon-ok"
                    (click)="updateSocialNetwork(socialTextOther.value, socialURL.value)"></i>
            </div>
        </div>
        <div *ngIf="fields == 'user_quotes'" style="margin-bottom: 5px;">
            <span>Favorite Quote or Motto:</span>
            <textarea id="desc1" *ngIf="fields == 'user_quotes'" class="txt-data1" value="{{ mx.data }}"
                placeholder="Add quote or motto"
                style="height: 60px; padding: 5px !important; margin: 0; width: 80% !important; resize: none;"></textarea>
            <i *ngIf="fields == 'user_quotes'" (click)="onEnterDesc()" class="glyphicon glyphicon-ok"
                style="vertical-align: top;"></i>
        </div>
    </div>
</div>
<div *ngIf="!showTexbox && edit" [@fadeInOut]="!showTexbox">
    <p style="font-style: italic;">Edit to add {{ mx.btntitle }}
        <span *ngIf="edit" class="glyphicon glyphicon-pencil" (click)="showText()"></span>
    </p>
</div>
<style>
    /*::-webkit-input-placeholder { 
  color: black;
}
::-moz-placeholder { 
  color: black;
}
:-ms-input-placeholder { 
  color: black;
}
:-moz-placeholder { 
  color: black;
}*/

    .awardsmore {
        cursor: pointer;
    }

    :host ::ng-deep .dropdown button {
        /*color: #6d6d6d;*/
        height: 26px;
        padding: 2px 4px;
    }

    :host ::ng-deep .dropdown .dropdown-menu {
        width: 300px !important;
    }

    .fadeinout {
        /*margin-bottom: 10px;*/
    }

    .addmore {
        color: #14578a;
        font-size: 12px;
        cursor: pointer;
        margin-left: 10px;
    }

    .txt-select {
        height: 23px;
        font-size: 14px;
        color: black;
        width: auto;
        padding: 0;
        display: inline-block;
    }

    .regular {
        font-family: open-regular;
    }

    .water-res {
        font-family: OpenSans-Semibold;
        color: #929292;
        margin: 0 0 0px;
        font-size: 14px;
    }

    .exp-desc {
        color: #a2a8a8;
        font-size: 14px;
    }

    .para-title {
        padding-left: 30px;
        font-size: 13px;
        color: grey;
    }

    .types:not(:last-child):after {
        content: "|";
        padding: 0 5px;
    }

    .awards:not(:last-child):after {
        content: "|";
        padding: 0 5px;
    }

    .certi1:not(:last-child):after {
        content: ", ";
        /*padding: 0 5px;*/
    }

    .certi:not(:last-child):after {
        content: ", ";
        /*padding: 0 5px;*/
    }

    .certif:not(:last-child):after {
        content: ", ";
    }

    .certif1:not(:last-child):after {
        content: ", ";
    }

    textarea {
        max-height: 250px;
        padding: 0px !important;
        margin: 0px 0px 0px 30px;
        width: 95% !important;
        max-width: 480px;
        height: 150px;
    }

    select {
        display: inline-block;
        height: 26px;
        padding: 0px 5px;
        border: 1px solid #ccc;
        margin-bottom: 5px;
        width: 80%;
    }

    @font-face {
        font-family: open-regular;
        src: url('/assets/fonts/new/opensans-regular-webfont.eot?#iefix') format('embedded-opentype'),
            /* IE */
            url('/assets/fonts/new/opensans-regular-webfont.ttf') format("truetype");
        /* non-IE */
    }

    @font-face {
        font-family: OpenSans-Semibold;
        src: url('/assets/fonts/new/opensans-semibold-webfont.eot?#iefix') format('embedded-opentype'),
            /* IE */
            url('/assets/fonts/new/opensans-semibold-webfont.ttf') format("truetype");
        /* non-IE */
    }

    @font-face {
        font-family: OpenSans-Bold;
        src: url('/assets/fonts/new/opensans-bold-webfont.eot?#iefix') format('embedded-opentype'),
            /* IE */
            url('/assets/fonts/new/opensans-bold-webfont.ttf') format("truetype");
        /* non-IE */
    }
</style>