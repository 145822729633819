<navbar></navbar>
<div class="">
  <div class="header">

    <div class="search-left">
      <div class="header-buttons">
        <button rp-button size="small" type="primary" id="projectid" [ngClass]="{'active': selectedItemId === 1}"
          routerLink="/explorer/projects" routerLinkActive="active"
          (click)="removeQuery(); selectedItemId = 1; selectbox = 'projects';tabChanged(1)">Projects</button>
        <button rp-button size="small" type="primary" id="peopleid" [ngClass]="{'active': selectedItemId === 2}"
          routerLink="/explorer/people" routerLinkActive="active"
          (click)="removeQuery(); selectedItemId = 2; selectbox = 'people';tabChanged(2)">People</button>
        <button rp-button size="small" type="primary" id="orgid" [ngClass]="{'active': selectedItemId === 3}"
          routerLink="/explorer/org" routerLinkActive="active"
          (click)="removeQuery(); selectedItemId = 3; selectbox = 'org';tabChanged(3)">Organizations</button>
        <!-- <button rp-button size="small" type="primary" id="lessonplansid" [ngClass]="{'active': selectedItemId === 4}"
          routerLink="/explorer/lessonplans" routerLinkActive="active"
          (click)="removeQuery(); selectedItemId = 4; selectbox = 'lessonplans';tabChanged(4)">Lesson Plans</button>
        <button rp-button size="small" type="primary" id="jobid" [ngClass]="{'active': selectedItemId === 5}"
          routerLink="/explorer/jobs" routerLinkActive="active"
          (click)="removeQuery(); selectedItemId = 5; selectbox = 'jobs';tabChanged(5)">Careers</button>
        <button rp-button size="small" type="primary" id="chatid" [ngClass]="{'active': selectedItemId === 6}"
          routerLink="/explorer/chat" routerLinkActive="active"
          (click)="removeQuery(); selectedItemId = 6; selectbox = 'chat';tabChanged(6)">Connect</button> -->
      </div>
      <div class="keywordsIp">

        <input type="text" placeholder="Keywords" class="" [(ngModel)]="query" (keydown)="onKeydown($event)" />
        <div class="" (click)="searchWithKeyword(); searchBtnClicked = true;">
          <i>

            <img class="icon icons8-Search-Filled" width="25"
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAC50lEQVRoQ+2a/5VNMRDHv1uBVQFbASpABagAFaACVIAKUAEqWFvBbgeoABVwPk6yJ7JJ3kxyL3n37Jzz/njn3ZvJZzK/MrsH2ogcbIRDlyDhJA8l3ZZ0X9J1SXy/GX47k/RD0ldJHyWdhO+rOEHvidyR9CQAeDYG0EtJQC4qXhCs/lzSo8FdvAtAnNYi4gHBfd4G91lCOW73OLjd8HpWENzodUXbt7AZ3IbNRbchVogZDMDnWuX9p5LejJJYQFD0qqCI4H0h6bNxE8QVz5MccnnWMJRp+V0gWPLDwoprhnkw4mYtEAL7NIuJn5Kw7GjWwe04ySuJkXDLWyFdm04hfagFQmZ5mK2IolGIuCQwGCqV970ZsQaC1Y8zJcN+XDBzyc26jFUDIQPdSxQT2MCtIbhYmgA+dRTaYq9Fyvye7fiuIzt5YUunvysJXdBReiHPVNQJAn9NocKndcadwUogeZBTrPDlNYViS9GN4g76Ekjus2u6Vdx47l7umCyBkBJjK46iriziPL48FZPi0WuWEsiv7G134Jm1//3gkN5Ng3CsNxJj/Q/XcmfKTQf7DOnXnfItBZFiddQZwNbXvmRFd5GCOEOLctU7cbE2jRRJCuMaQpedNqTuqs6mNt/GA7n2xarU9Xa18K0T4Tc6XmpKfh3FxUZvibQkuBTxGIVrNDq58rplV/tRGz6MjHBqoyUuc2SrLtkFwqK1qQcJgPGnZxzElLJ108SdGdq5xQLSguG3OKTGorhHOqDDLeOAzno564KxgrBhNoSSNGbcljO+4IbxgMQEwLQwHxMZ93f+GLUCaa3jgvGCxJ2QdQBKJy0WGADYYIyrUopP1zHD9IJEZaRPgjf9Q0+8AsR4IWb4xCF3DrwIzCiI5RQszwzDzAIC7BDMTCBDMLOBdMPMCNIFMyuIBYZWhrj6IzODtGAuXL5mBynBFG+Q+wCSwlSvwfsCAgz/pHAeE3mV3SeQZodwCWJpoP7lM78BA82jMzUV/gIAAAAASUVORK5CYII=">
          </i>
        </div>
      </div>



      <button *ngIf="selectedItemId == 1" routerLink="/listprojects" rp-button type="primary" size="small">
        <img height="22px" src="assets/images/maps_view.png" />

        Map View
      </button>



      <!-- <ng-container>


          <span   class="addBtns">Add
           </span>

          <span *ngIf="selectbox == 'people'" routerLink="/createperson" routerLinkActive="active" class="addBtns">Add
            a person</span>
          <span *ngIf="selectbox == 'projects'" routerLink="/createproject" routerLinkActive="active"
            class="addBtns">Add
            a project</span>
          <span *ngIf="selectbox == 'chat'"  class="addBtns">Add
            a group</span>
        </ng-container> -->

    </div>
    <div>
      <button rp-button type="primary" size="small" *ngIf="selectbox === 'projects'" routerLink="/createproject"
        routerLinkActive="active">Add a project</button>
      <button rp-button type="primary" size="small" *ngIf="selectbox === 'people'" routerLink="/createperson">Add a
        person</button>
      <button rp-button type="primary" size="small" *ngIf="selectbox === 'org'" routerLink="/createcompany">Add an
        organization</button>
      <button rp-button type="primary" size="small" *ngIf="selectbox === 'chat'" data-toggle="modal"
        data-target="#addgroupmodal">Add a group</button>
    </div>
    <div class="selectAndFilter">
      <select (change)="ddSearch()" [(ngModel)]="ddSearchVal" class="form-control">
        <option value="projects">Projects</option>
        <option value="people">People</option>
        <option value="org">Organizations</option>
        <!-- <option value="lessonplans">Lesson Plans</option>
        <option value="jobs">Careers</option>
        <option value="chat">Connect</option> -->
      </select>
      <button rp-button type="primary" size="small" (click)="openFilter();">Filter</button>
    </div>
  </div>
  <div class="secondKeywordsIp">
    <input id="keyword2" type="text" placeholder="Keywords" class="keyword" [(ngModel)]="query"
      (keydown)="onKeydown($event)" />
    <i (click)="searchWithKeyword(); searchBtnClicked = true;">
      <!-- Search Filled icon by Icons8 -->
      <img class="icon icons8-Search-Filled" width="25"
        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAC50lEQVRoQ+2a/5VNMRDHv1uBVQFbASpABagAFaACVIAKUAEqWFvBbgeoABVwPk6yJ7JJ3kxyL3n37Jzz/njn3ZvJZzK/MrsH2ogcbIRDlyDhJA8l3ZZ0X9J1SXy/GX47k/RD0ldJHyWdhO+rOEHvidyR9CQAeDYG0EtJQC4qXhCs/lzSo8FdvAtAnNYi4gHBfd4G91lCOW73OLjd8HpWENzodUXbt7AZ3IbNRbchVogZDMDnWuX9p5LejJJYQFD0qqCI4H0h6bNxE8QVz5MccnnWMJRp+V0gWPLDwoprhnkw4mYtEAL7NIuJn5Kw7GjWwe04ySuJkXDLWyFdm04hfagFQmZ5mK2IolGIuCQwGCqV970ZsQaC1Y8zJcN+XDBzyc26jFUDIQPdSxQT2MCtIbhYmgA+dRTaYq9Fyvye7fiuIzt5YUunvysJXdBReiHPVNQJAn9NocKndcadwUogeZBTrPDlNYViS9GN4g76Ekjus2u6Vdx47l7umCyBkBJjK46iriziPL48FZPi0WuWEsiv7G134Jm1//3gkN5Ng3CsNxJj/Q/XcmfKTQf7DOnXnfItBZFiddQZwNbXvmRFd5GCOEOLctU7cbE2jRRJCuMaQpedNqTuqs6mNt/GA7n2xarU9Xa18K0T4Tc6XmpKfh3FxUZvibQkuBTxGIVrNDq58rplV/tRGz6MjHBqoyUuc2SrLtkFwqK1qQcJgPGnZxzElLJ108SdGdq5xQLSguG3OKTGorhHOqDDLeOAzno564KxgrBhNoSSNGbcljO+4IbxgMQEwLQwHxMZ93f+GLUCaa3jgvGCxJ2QdQBKJy0WGADYYIyrUopP1zHD9IJEZaRPgjf9Q0+8AsR4IWb4xCF3DrwIzCiI5RQszwzDzAIC7BDMTCBDMLOBdMPMCNIFMyuIBYZWhrj6IzODtGAuXL5mBynBFG+Q+wCSwlSvwfsCAgz/pHAeE3mV3SeQZodwCWJpoP7lM78BA82jMzUV/gIAAAAASUVORK5CYII=">
    </i>
  </div>

</div>
<div class="main" id="container" [ngStyle]="{'margin-top': selectbox == 'lessonplans' ? '0' : '0'}">
  <div *ngIf="selectbox != 'lessonplans'" id="mySidenav" class="sidefilter1">

    <div class="filter">

      <h3>Filters</h3>

      <button rp-button size="small" type="tertiary" (click)="resetFilter()">Reset</button>
    </div>

    <div class="">
      <button rp-button size="small" type="tertiary" style="margin-bottom: 10px;margin-top: 5px;" (click)="show()">Search</button>
    </div>

    <div *ngIf="selectbox == 'projects'" class="chipsDiv">
      <app-rp-chips *ngFor="let item of chips; let i = index" (removed)="remove(i)">
        {{item.name}}
      </app-rp-chips>
    </div>
    <div class="filters-div">

      <div *ngIf="selectbox == 'projects'" class="">
        <p class="filter-title">Keywords</p>
        <p>
          <input type="text" class="form-control" placeholder="Keywords" (keyup.enter)="filterResults()"
            [(ngModel)]="project_filters.search" />
        </p>
        <p class="filter-title">Location</p>
        <p>
          <input type="text" class="form-control" placeholder="State or country" id="autocompleteinput"
            [(ngModel)]="project_filters.project_location" />
        </p>
        <p class="filter-title">Disciplines that work on project</p>
        <p>
          <input type="text" class="form-control" placeholder="Disciplines"
            [(ngModel)]="project_filters.project_discipline" />
        </p>
        <p class="filter-title">Tags (e.g., robotics, clean energy)</p>
        <p>
          <input type="text" class="form-control" placeholder="Tags" (keyup.enter)="filterResults()"
            [(ngModel)]="project_filters.project_tags" />
        </p>
        <p class="filter-title">Sort by</p>
        <p>
          <select class="form-control" [(ngModel)]="project_filters.sort_by">
            <option value="">Select sort by</option>
            <option value="Recently added">Recently added</option>
            <option value="recent">Recently edited</option>
            <option value="A-Z">A-Z</option>
            <option value="Z-A">Z-A</option>
          </select>
        </p>
        <p class="filter-title">Year Completed</p>
        <div style="margin-bottom: 10px;">
          <select class="form-control" [(ngModel)]="project_filters.project_completion_date">
            <option value="">Select year completed</option>
            <option value="{{minus5Year}},{{currentYear}}">Within the last 0-5 years</option>
            <option value="{{minus25Year}},{{minus5Year}}">5-25 years ago</option>
            <option value="1600,{{minus25Year}}">25+ years ago</option>
            <!-- <option value="date-range">Enter a date range</option> -->
          </select>
          <div style="margin-top: 5px;text-align: center;"
            *ngIf="project_filters.project_completion_date == 'date-range'">
            <div class="width45">Start year</div>
            <div class="width45">End year</div>
            <div class="width45"><input type="number" [(ngModel)]="start_year" maxlength="4"></div> -
            <div class="width45"><input type="number" [(ngModel)]="end_year" maxlength="4"></div>
          </div>
        </div>
        <p class="filter-title">
          <label class="checkbox-inline" style="margin-left: 0px;">
            <input type="checkbox" [(ngModel)]="project_filters.project_video_references">Has video</label>
        </p>

        <p class="filter-title">Project Status</p>
        <p>
          <select class="form-control" id="sel1" (focus)="closeMultidd()" [(ngModel)]="project_filters.project_status">
            <option value="">Select project status</option>
            <option value="Study/Design Phase">Study/Design Phase</option>
            <option value="Under Construction">Under Construction</option>
            <option value="Complete">Complete</option>
            <option value="Operation">Operation & Maintenance</option>
            <option value="Seeking Help - Unpaid">Seeking Help - Unpaid</option>
            <option value="Seeking Help - Paid">Seeking Help - Paid</option>
            <option value="Seeking Funding">Seeking Funding</option>
            <option value="Seeking Grad Student">Seeking Grad Student</option>
            <option value="Other">Other</option>
          </select>
          <input type="text" *ngIf="project_filters.project_status == 'Other'" class="form-control"
            placeholder="Other project status" [(ngModel)]="project_status_other" />
        </p>
        <p class="filter-title">Project Cost</p>
        <p>
          <select class="form-control" (focus)="closeMultidd()" [(ngModel)]="project_filters.project_cost">
            <option value="">Select project cost</option>
            <option value="less than $100K">$ = less than 100K</option>
            <option value="$100K to $1M">$$ = 100K to 1M</option>
            <option value="$1M to $10M">$$$ = 1M to 10M</option>
            <option value="$10M to $100M">$$$$ = 10M to 100M</option>
            <option value="over $100M">$$$$$ = over 100M</option>
          </select>
        </p>
        <p class="filter-title">Awards Received</p>
        <p>
          <input type="text" class="form-control" placeholder="Awards"
            [(ngModel)]="project_filters.project_awards_name" />

        </p>
        <p class="filter-title">Funding Type</p>
        <p>
          <my-dropdown [defaultText]="'Select funding type'" [selectedOptions]="project_filters.project_funding_type"
            [myOptions]="fundingSourceOptions" (selectedArray)="onChangedd1($event)"></my-dropdown>
        </p>
        <p class="filter-title">Project Details</p>
        <p>
          <my-dropdown (click)="onOpen($event)" [defaultText]="'Select project detail'"
            [selectedOptions]="project_filters.project_details_title" [myOptions]="projectDetailsOptions"
            (selectedArray)="onChangedd3($event)"></my-dropdown>
        </p>
        <p class="filter-title">Added by Educational Program</p>
        <p class="topMultiselect">
          <my-dropdown [defaultText]="'Select Educational Program'"
            [selectedOptions]="project_filters.project_institution" [myOptions]="educationalProgram"
            (selectedArray)="onChangedd5($event)"></my-dropdown>
        </p>
      </div>
      <div *ngIf="selectbox == 'people'" class="">
        <p class="filter-title">Keywords</p>
        <p>
          <input type="text" class="form-control" placeholder="Keywords" (keyup.enter)="filterResults()"
            [(ngModel)]="people_filters.search" />
        </p>
        <p class="filter-title">Sort by</p>
        <p>
          <select class="form-control" [(ngModel)]="people_filters.sort_by">
            <option value="">Select sort by</option>
            <option value="Recently added">Recently added</option>
            <option value="recent">Recently edited</option>
            <option value="A-Z">A-Z</option>
            <option value="Z-A">Z-A</option>
          </select>
        </p>
        <p class="filter-title">Years Experience</p>
        <p>
          <select class="form-control" [(ngModel)]="people_filters.user_experience">
            <option value="">Select experience</option>
            <option *ngFor="let item of exp" value="{{item}}">{{item}}</option>
          </select>
        </p>
        <!-- <p class="filter-title">Career Status</p>
              <p>
                  <my-dropdown [defaultText]="'Select career status'" [selectedOptions]="people_filters.career_status" [myOptions]="careerStatusOptions" (selectedArray)="onChangedd4($event)"></my-dropdown>
              </p> -->
        <p class="filter-title">Professional role</p>
        <p>
          <select [(ngModel)]="people_filters.user_designation" class="form-control">

            <option value="">Select role</option>

            <option *ngFor="let item of lineofwork" value="{{item}}">{{item}}</option>

          </select>
          <input *ngIf="people_filters.user_designation == 'Other'" [(ngModel)]="user_designation_other_role"
            type="text" class="form-control" style="margin-top: 5px;" placeholder="Enter other role" />
        </p>
        <!-- <p class="filter-title">Education</p>
      <p>
        <my-dropdown [defaultText]="'Select degrees'" [selectedOptions]="people_filters.user_degrees"
          [myOptions]="userDegreesOptions" (selectedArray)="onChangedd4($event)"></my-dropdown>
      </p> -->
        <p class="filter-title">University Attended</p>
        <p>
          <input type="text" class="form-control" placeholder="University Attended"
            [(ngModel)]="people_filters.user_education" />
        </p>
        <p class="filter-title">Major</p>
        <p>
          <input type="text" class="form-control" placeholder="Major/Field of Study"
            [(ngModel)]="people_filters.field_of_study" />
        </p>
        <p class="filter-title">
          <label class="checkbox-inline" style="margin-left: 0px;">
            <input type="checkbox" [(ngModel)]="people_filters.external_video">Has video</label>
        </p>
        <p class="filter-title">
          <label class="checkbox-inline" style="margin-left: 0px;">
            <input type="checkbox" [(ngModel)]="people_filters.has_projects">Has projects worked on</label>
        </p>
        <p class="filter-title">
          <label class="checkbox-inline" style="margin-left: 0px;">
            <input type="checkbox" [(ngModel)]="people_filters.social_network">Has LinkedIn Profile</label>
        </p>
        <!-- <p class="filter-title">Profile Questions</p>
      <p>
        <select [(ngModel)]="people_filters.profile_q" class="form-control">
          <option value="">Select question</option>
          <option value="What_college_classes_have_you_used_the_most?">What college classes have you used the most? </option>
          <option value="What_skills_have_been_more_important_to_your_career?">What skills have been more important to your career?</option>
        </select>
      </p> -->
      </div>
      <div *ngIf="selectbox == 'org'" class="">
        <p class="filter-title">Keywords</p>
        <p>
          <input type="text" class="form-control" placeholder="Keywords" (keyup.enter)="filterResults()"
            [(ngModel)]="org_filters.search" />
        </p>
        <p class="filter-title">Location</p>
        <p>
          <input type="text" class="form-control" [(ngModel)]="org_filters.org_hq_location" id="autocompleteinput2"
            placeholder="Enter location" />
        </p>
        <p class="filter-title">Organization Type</p>
        <p>
          <select class="form-control" [(ngModel)]="org_filters.org_type">
            <option value="">Select type</option>
            <option value="firm">Firm</option>
            <option value="univ">University</option>
            <option value="npo">Non-profit</option>
            <option value="govt">Government</option>
            <option value="pa">Professional Association</option>
            <option value="other">Other</option>
          </select>
        </p>
        <p class="filter-title">Sort by</p>
        <p>
          <select class="form-control" [(ngModel)]="org_filters.sort_by">
            <option value="">Select sort by</option>
            <option value="Recently added">Recently added</option>
            <option value="recent">Recently edited</option>
            <option value="A-Z">A-Z</option>
            <option value="Z-A">Z-A</option>
          </select>
        </p>
        <p class="filter-title">
          <label class="checkbox-inline" style="margin-left: 0px;">
            <input type="checkbox" [(ngModel)]="org_filters.pe_inhouse">Has Licensed Professional
            Engineer(s)</label>
        </p>
        <p class="filter-title">Services Provided</p>
        <p>
          <input type="text" class="form-control" placeholder="Enter services" [(ngModel)]="org_filters.org_services" />
        </p>
        <p class="filter-title">Certifications</p>
        <p class="dr">
          <!-- <input type="text" class="form-control" placeholder="Certifications" [(ngModel)]="org_filters.certifications" /> -->
          <my-dropdown [defaultText]="'Certifications'" [selectedOptions]="org_filters.org_certification"
            [myOptions]="orgCertifications" (selectedArray)="certificationChange($event)"></my-dropdown>
          <input *ngIf="is_prof_certi_other" [(ngModel)]="org_filters.certifications_other" type="text"
            class="form-control" style="margin-top: 5px;" placeholder="Enter other certification" />
          <input [(ngModel)]="org_filters.certi_location" type="text" class="form-control" style="margin-top: 5px;"
            placeholder="Enter state(s)/location(s) where applicable" />
        </p>
        <p class="filter-title">No. of employees</p>
        <p>
          <!-- <input type="text" class="form-control" placeholder="No. of employees" [(ngModel)]="org_filters.employees_count" /> -->
          <select [(ngModel)]="org_filters.org_size" class="form-control">
            <option value="">Select</option>
            <option value="1 - 10">1 - 10</option>
            <option value="11 - 50">11 - 50</option>
            <option value="51 - 200">51 - 200</option>
            <option value="201 - 500">201 - 500</option>
            <option value="501 - 1000">501 - 1000</option>
            <option value="1,001 - 10,000">1,001 - 10,000</option>
            <option value="10,000">10,000+</option>
          </select>
        </p>
        <p class="filter-title">Hiring</p>
        <p>
          <!-- <input type="text" class="form-control" placeholder="No. of employees" [(ngModel)]="org_filters.employees_count" /> -->
          <select [(ngModel)]="org_filters.org_hiring" class="form-control">
            <option value="">Select</option>
            <option value="Interns">Interns</option>
            <option value="Entry-level">Entry-level</option>
            <option value="Experienced">Experienced</option>
          </select>
        </p>
        <p class="filter-title">Commitments</p>
        <p>
          <!-- <input type="text" class="form-control" placeholder="No. of employees" [(ngModel)]="org_filters.employees_count" /> -->
          <select [(ngModel)]="org_filters.org_commitments" class="form-control">
            <option value="">Select</option>
            <option value="We give back to our community">We give back to our community</option>
            <option value="We invest in staff development">We invest in staff development</option>
            <option value="We invest in technology">We invest in technology</option>
            <option value="We support diversity in engineering">We support diversity in engineering</option>
          </select>
        </p>
        <p class="filter-title">
          <label class="checkbox-inline" style="margin-left: 0px;">
            <input type="checkbox" [(ngModel)]="org_filters.org_is_enhanced">Has enhanced profile</label>
        </p>
      </div>
      <div *ngIf="selectbox == 'chat'" class="">
        <p class="filter-title">Group Title Keywords</p>
        <p>
          <input type="text" class="form-control" placeholder="Keywords" [(ngModel)]="chat_filters.group_name" />
        </p>
        <p class="filter-title">Threads Keywords</p>
        <p>
          <input type="text" class="form-control" placeholder="Keywords" [(ngModel)]="chat_filters.topic_name" />
        </p>
        <p class="filter-title">Sort by</p>
        <p>
          <select class="form-control" [(ngModel)]="chat_filters.sort_by">
            <option value="">Select sort by</option>
            <option value="Recently added">Recently added</option>
            <option value="recent">Recently edited</option>
            <option value="A-Z">A-Z</option>
            <option value="Z-A">Z-A</option>
          </select>
        </p>
        <p class="filter-title">Group type</p>
        <p>
          <select class="form-control" [(ngModel)]="chat_filters.group_type__iexact">
            <option value="">Select group type</option>
            <option value="public">Public</option>
            <option value="private">Private</option>
          </select>
        </p>
        <!-- <p class="filter-title">
          <label class="checkbox-inline" style="margin-left: 0px;">
            <input type="checkbox" [(ngModel)]="chat_filters.is_public">Is Public</label>
        </p> -->
        <!-- <p class="filter-title">
          <label class="checkbox-inline" style="margin-left: 0px;">
            <input type="checkbox" [(ngModel)]="chat_filters.is_private">Is Private</label>
        </p> -->
      </div>
      <div *ngIf="selectbox == 'jobs'" class="">
        <p class="filter-title">Keywords</p>
        <p>
          <input type="text" class="form-control" placeholder="Keywords" [(ngModel)]="job_filters.search" />
        </p>
        <p class="filter-title">Location</p>
        <p>
          <input type="text" class="form-control" placeholder="Location" id="autocompleteinput"
            [(ngModel)]="job_filters.job_location" />
        </p>
        <p class="filter-title">Sort by</p>
        <p>
          <select class="form-control" [(ngModel)]="job_filters.sort_by">
            <option value="">Select sort by</option>
            <option value="Recently added">Recently added</option>
            <option value="recent">Recently edited</option>
            <option value="A-Z">A-Z</option>
            <option value="Z-A">Z-A</option>
          </select>
        </p>
      </div>
    </div>

  </div>
  <div class="cardsDiv" infiniteScroll [infiniteScrollDisabled]="scrollDisabled" [infiniteScrollDistance]="1"
    [infiniteScrollThrottle]="50" (scrolled)="onScroll()" [scrollWindow]="false"
    [ngClass]="selectbox == 'lessonplans' ? 'cardsDivLessonPlans' : 'cardsDiv'">
    <div class="loader1" *ngIf="loading"></div>
    <p style="font-size: 24px;" class="text-center" *ngIf="noData">No {{noDataString}} found!</p>
    <p class="text-center" *ngIf="selectbox == 'projects' && noData">
      <a style="cursor: pointer;" routerLinkActive="active" routerLink="/createproject">Be the first to upload the
        project you're looking for.</a>
    </p>
    <p class="text-center" *ngIf="selectbox == 'people' && noData">
      <a style="text-decoration: none;">Invite someone to add their profile.</a>
    </p>
    <p class="text-center" *ngIf="selectbox == 'org' && noData">
      <a style="text-decoration: none;">Invite an organization to add their profile.</a>
    </p>
    <div class="text-center" *ngIf="noDataHomepageSearch">

      <div>
        <p class="no_projects">No projects there yet!</p>
        <p class="be_first">Be the first to
          <button class="button1" routerLinkActive="active" routerLink="/createproject">Upload a
            Project</button> in this location.
        </p>
        <p class="text-right">
          <button routerLinkActive="active" routerLink="/listprojects"
            (click)="service.sendEvent('click', 'map view', '')" class="open_map_view">Open Map
            View</button>
        </p>
      </div>
    </div>
    <!-- <div>
      {{(isLoading| async)}}

    </div> -->

    <div *ngIf="!noData || (selectbox == 'projects' && noDataHomepageSearch)">
      <div #scrollMe id="scrollMe" *ngIf="noDataString == 'projects'">
        <div>
          <h2 class="list-Title">STEM Projects</h2>
          <p class="list-subTitle">
            Wonder what types of projects you would work on in different careers and jobs in Science, Technology,
            Engineering and Math (STEM)? Use the filters or
            scroll to explore projects shaping your
            community and the world.
          </p>
        </div>

        <!-- <div class="column col-md-4" *ngFor="let item of collection; let i = index">
         
          <div class="card" routerLink="/project/{{item.project_name_slug}}">
            <div class="imgParent" [ngStyle]="{'background-image': 'url('+item.imageurl+')'}">
              <i *ngIf="service.isAdmin" class="glyphicon glyphicon-trash deleteicon"
                (click)="deleteProject($event, item.project_name_slug, item.name, i)"></i>
           
            </div>
            <div class="container1">
              <h4>{{item.name}}</h4>
              <p style="height: 20px;">{{item.address}}</p>
              <p>
                <a routerLink="/project/{{item.project_name_slug}}" routerLinkActive="active"
                  (click)="service.sendEvent('goto project', 'redirect', 'project: name: '+item.name)">
                  <button class="button">Go to Project</button>
                </a>
              </p>
            </div>
          </div>
          
        </div> -->
        <div>
          <div *ngIf="(exploreProjectsCardData$ | async).length > 0 " class="projectCardGrid">
            <div class="project-card" *ngFor="let project of (exploreProjectsCardData$ | async)">
              <app-project-card (click)="goToProject(project.slug)" [project]="project"></app-project-card>
            </div>
          </div>
        </div>

      
          <div *ngIf="!(isLoading| async) && !(exploreProjectsCardData$ | async).length > 0">
            <div class="noDataFound">
              <h1>No projects found!</h1>
              <p routerLink="/createproject">Be the first to upload the project you're looking for.</p>
            </div>
          </div>
      

      </div>

      <div *ngIf="noDataString == 'people'">
        <div>
          <h2 class="list-Title">Engineers, Scientists, and Other STEM Professionals and Students</h2>
          <p class="list-subTitle">
            Who is shaping our future? Find role models and meet the creative engineers and scientists that are making a difference.  Learn about their careers and join RePicture to showcase your work.
          </p>
        </div>
        <div *ngIf="(explorePeopleCardData$ | async).length > 0">
          <div class="profileCardGrid">
            <div class="profile-card" *ngFor="let people of (explorePeopleCardData$ | async)">
              <app-profile-card [profileCardData]="people"></app-profile-card>
            </div>
          </div>
        </div>
        <div *ngIf="!(explorePeopleCardData$ | async).length > 0 && !(isLoading | async)">
          <div class="noDataFound">
            <h1>No people found!</h1>
            <p>Invite someone to add their profile.</p>
          </div>
        </div>
        <!-- <div class="column col-md-4" *ngFor="let item of collection_people; let i = index">
          <div class="card" routerLink="/userprofile/{{item.username}}">
            <div class="box" *ngIf="item.hasAudio || item.hasVideo">
              <div class="ribbon">
               
                <img *ngIf="item.hasAudio" src="assets/images/Audio.png" />
                <img *ngIf="item.hasVideo" src="assets/images/Video.png" />
              </div>
            </div>
            <div class="imgParent" [ngStyle]="{'background-image': 'url('+item.imageurl+')'}"
              style="padding-top: 100%;">
              <i *ngIf="service.isAdmin" class="glyphicon glyphicon-eye-close deleteicon"
                (click)="deleteUser($event, item.userid, item.username, item.name, i)"></i>
            </div>
            <div class="container1">
              <h4>{{item.name}}</h4>
              <p style="height: 20px;">{{item.designation}}</p>
              <p class="text-center">
                <a routerLinkActive="active" routerLink="/userprofile/{{item.username}}" style="text-decoration: none;">
                  <button class="button">Go to Profile</button>
                </a>
              </p>
            </div>
          </div>
          
        </div> -->
      </div>
      <div *ngIf="noDataString == 'org'">
        <div>
          <h2 class="list-Title">STEM Organizations</h2>
          <p class="list-subTitle">
            Explore Science, Technology, Engineering and Math (STEM) companies and organizations that are doing the work
            you are interested in. Learn about their projects and open positions.
          </p>
        </div>
        <div *ngIf="(exploreOrgCardData$ | async).length > 0">
          <div class="orgCardGrid">
            <div class="org-card" *ngFor="let org of (exploreOrgCardData$ | async)">
              <app-org-card [orgCardData]="org"></app-org-card>
            </div>
          </div>
        </div>
        

        <div *ngIf="!(exploreOrgCardData$ | async).length > 0 && !(isLoading | async)">
          <div class="noDataFound">
            <h1>No organization found!</h1>
            <p>Invite an organization to add their profile.</p>
          </div>
        </div>
        <!-- <div class="column col-md-4" *ngFor="let item of collection_companies; let i = index">
          <div class="card" routerLink="/companyprofile/{{item.username}}">
            <div class="box" *ngIf="item.hasAudio || item.hasVideo">
              <div class="ribbon" style="bottom: 87px;">
                <img *ngIf="item.hasAudio" src="assets/images/Audio.png" />
                <img *ngIf="item.hasVideo" src="assets/images/Video.png" />
              </div>
            </div>
            <div class="imgParent border-bottom" [ngStyle]="{'background-image': 'url('+item.imageurl+')'}">
              <i *ngIf="service.isAdmin" class="glyphicon glyphicon-trash deleteicon"
                (click)="deleteOrg($event, item.companyid, item.username, item.name, i)"></i>
            </div>
            <div class="container1">
              <h4>{{item.name}}</h4>
              <p class="text-center">
                <a routerLinkActive="active" routerLink="/companyprofile/{{item.username}}">
                  <button class="button">Go to Profile</button>
                </a>
              </p>
            </div>
          </div>
         
        </div> -->
      </div>
      <div *ngIf="noDataString == 'lessonplans'">
        <app-lessonplans></app-lessonplans>
      </div>
      <div *ngIf="noDataString == 'jobs'">
        <h1 class="list-Title">Coming soon...</h1>
        <!-- <app-career [dataChanged]="jobs"></app-career> -->
      </div>
      <div *ngIf="noDataString == 'chat'">
        <h1 class="list-Title">Coming soon...</h1>
        <!-- <app-chat [dataChanged]='chatCollection'></app-chat> -->
      </div>
      <div *ngIf="loading2" class="loader2">
        <div></div>
      </div>
    </div>
  </div>
</div>
<app-confirm-c [sanket]="confirmObject" (performAction)="confirmedAction($event)"></app-confirm-c>
<div id="addgroupmodal" class="modal fade" role="dialog" data-keyboard="false" data-backdrop="static">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header" style="background-color: #FFE089;">
        <h4 class="modal-title">Add a group</h4>
      </div>
      <div class="modal-body text-center">
        <p>Email us at: <a style="color: blue; text-decoration: underline;">info@RePicture.com</a> if you would like us
          to add a group.</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal">OK</button>
      </div>
    </div>
  </div>
</div>
<style>
  @font-face {
    font-family: OpenSans-Regular;
    src: url('/assets/fonts/new/opensans-regular-webfont.eot?#iefix') format('embedded-opentype'),
      /* IE */
      url('/assets/fonts/new/opensans-regular-webfont.ttf') format("truetype");
    /* non-IE */
  }

  @font-face {
    font-family: OpenSans-Light;
    src: url('/assets/fonts/new/opensans-light-webfont.eot?#iefix') format('embedded-opentype'),
      /* IE */
      url('/assets/fonts/new/opensans-light-webfont.ttf') format("truetype");
    /* non-IE */
  }

  @font-face {
    font-family: OpenSans-Bold;
    src: url('/assets/fonts/new/opensans-bold-webfont.eot?#iefix') format('embedded-opentype'),
      /* IE */
      url('/assets/fonts/new/opensans-bold-webfont.ttf') format("truetype");
    /* non-IE */
  }

  @font-face {
    font-family: OpenSans-ExtraBold;
    src: url('/assets/fonts/new/opensans-extrabold-webfont.eot?#iefix') format('embedded-opentype'),
      /* IE */
      url('/assets/fonts/new/opensans-extrabold-webfont.ttf') format("truetype");
    /* non-IE */
  }

  @font-face {
    font-family: OpenSans-Semibold;
    src: url('/assets/fonts/new/opensans-semibold-webfont.eot?#iefix') format('embedded-opentype'),
      /* IE */
      url('/assets/fonts/new/opensans-semibold-webfont.ttf') format("truetype");
    /* non-IE */
  }

  * {
    /* font-family: OpenSans-Regular; */
  }

  .invalid-feedback {
    color: #dc3545;
  }

  .is-invalid {
    border-color: #dc3545;
  }

  .width45 {
    display: inline-block;
    width: 45%;
    text-align: center;
  }

  .width45 input {
    width: 100%;
    text-align: center;
  }

  .no_projects {
    font-family: OpenSans-Bold;
    font-size: 27px;
    color: #1a496c;
  }

  .be_first {
    font-family: OpenSans-Light;
    font-size: 25px;
    color: #1a496c;
    margin-top: 30px;
  }

  .addBtns {
    color: white;
    cursor: pointer;
    position: absolute;
    right: 10px;
    border: none;
    outline: none;
    font-size: 18px;
    font-family: OpenSans-Bold;
    margin-top: 10px;
  }

  .button1 {
    border: 6px solid #8cd3f4;
    background-color: #f0f0f0;
    width: 229px;
    height: 70px;
    border-radius: 8px;
    font-family: OpenSans-Light;
    outline: none;
    user-select: none;
  }

  .open_map_view {
    font-family: OpenSans-Semibold;
    font-size: 21px;
    width: 175px;
    height: 39px;
    user-select: none;
    background-color: #1a496c;
    color: white;
    outline: none;
    border-radius: 10px;
    border: none;
    margin-right: 30px;
  }

  /* Three columns side by side */

  .box {
    /*width: 200px;
      height: 300px;*/
    /* position: relative; */
    /*border: 1px solid #BBB;*/
    background: #EEE;
  }

  .secondKeywordsSearch {
    height: 30px;
    display: none;
    align-items: center;
    justify-content: center;
    border: 1px solid;
    margin: 5px 0;
    border-radius: 5px;
  }

  .chip {
    display: inline-block;
    padding: 0 10px;
    height: 25px;
    font-size: 11px;
    line-height: 25px;
    border-radius: 25px;
    background-color: #ffe284;
    margin: 3px;
  }

  .closebtn {
    padding-left: 10px;
    color: #888;
    font-weight: bold;
    float: right;
    font-size: 20px;
    cursor: pointer;
  }

  .closebtn:hover {
    color: #000;
  }

  .ribbon {
    /* position: absolute;
      right: 5px;
      top: 5px;
      z-index: 1;
      overflow: hidden;
      background: #ffe284;
      width: 50px;
      height: 43px;
      text-align: center;
      text-align: center;
      border-bottom-left-radius: 13px;
      border-bottom-right-radius: 26px; */
    position: absolute;
    right: 35px;
    bottom: 115px;
    z-index: 1;
  }

  .ribbon img {
    width: 30px;
  }

  .ribbon span {
    font-size: 10px;
    font-weight: bold;
    color: #FFF;
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    width: 100px;
    display: block;
    background: #79A70A;
    background: linear-gradient(#9BC90D 0%, #79A70A 100%);
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 19px;
    right: -21px;
  }

  .ribbon span::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 100%;
    z-index: -1;
    border-left: 3px solid #79A70A;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #79A70A;
  }

  .ribbon span::after {
    content: "";
    position: absolute;
    right: 0px;
    top: 100%;
    z-index: -1;
    border-left: 3px solid transparent;
    border-right: 3px solid #79A70A;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #79A70A;
  }

  .bottomBtns {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    /*background-color: #cbcbcb;*/
  }

  @media (min-width: 900px) {
    .sidefilter1 {
      width: 285px;
      display: inline-block;
      background-color: white;
      height: calc(100% - 142px);
      padding: 6px 12px 12px 12px;
      position: fixed;
      left: 0 !important;
    }
  }

  @media (min-width: 320px) and (max-width: 1440px) {

    .searchbox .search,
    .searchbox .dedButtons,
    .searchbox .padding-topbottom {
      display: none !important;
    }

    .secondKeywordsSearch {
      display: flex;
    }

    .loader2 {
      width: 100% !important;
    }

    .sidefilter1 {
      bottom: 0px;
      position: absolute !important;
      height: auto !important;
      z-index: 2;
      top: 0px;
      left: -290px;
      width: 285px;
      display: inline-block;
      background-color: white;
      height: calc(100% - 142px);
      padding: 6px 12px 12px 12px;
      transition: 0.5s;
      -webkit-box-shadow: 9px -7px 3px -8px #888;
      box-shadow: 9px -7px 3px -8px #888;
    }

    .searchbox {
      margin-left: 0px !important;
      display: unset !important;
    }

    .res {
      display: inline-block !important;
    }



    .addBtns {
      font-size: 12px !important;
      right: 37px !important;
      width: 20% !important;
    }

    .main {
      /* height: calc(100vh - 170px) !important; */
    }

    .no_projects {
      font-size: 18px !important;
    }

    .be_first {
      font-size: 14px !important;
      margin-top: 0 !important;
    }

    .be_first button {
      width: auto;
      height: auto;
    }

    .open_map_view {
      width: auto;
      height: auto;
      font-size: 18px !important;
    }
  }

  @media only screen and (min-width: 1150px) {
    .textbox {
      width: 220px;
    }
  }

  @media only screen and (min-width: 1195px) {
    .textbox {
      width: 260px;
    }
  }

  @media only screen and (min-width: 1165px) {
    .textbox {
      width: 240px;
    }
  }

  .buttons {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 26px;
    cursor: pointer;
  }

  .ach {
    color: black !important;
    text-decoration: none !important;
  }

  .loader1 {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    position: absolute;
    top: 35%;
    left: 49%;
  }

  :host ::ng-deep .topMultiselect .dropdown ul {
    top: -160px;
  }

  @media screen and (min-width: 890px) {
    /* :host ::ng-deep .dr .dropdown ul {
        width: auto !important;
        position: fixed !important;
        top: auto !important;
        left: inherit !important;
      } */
  }

  .loader2 {
    text-align: center;
    position: fixed;
    bottom: 0;
    width: calc(100% - 290px);
    background-color: white;
    opacity: 0.8;
  }

  .loader2 div {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    display: inline-block;
    margin: 6px 0;
  }

  .deleteicon {
    font-size: 20px;
    color: black;
    font-family: 'Glyphicons Halflings' !important;
    top: 3px;
    position: absolute;
    padding: 5px;
    background-color: white;
    right: 3px;
    border-radius: 50%;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .container1 h4,
  .container1 p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .container1 p:not(:last-child) {
    padding: 0 10px;
  }

  .container1 p:last-child {
    margin-bottom: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 6px;
  }

  .container1 h4 {
    padding-bottom: 3px;
    height: 20px;
    padding: 0 10px;
  }

  .mapsBtn {
    font-family: sans-serif;
    background-color: #ffe284;
    cursor: pointer;
    padding: 2px 10px;
    margin-left: 5px;
    text-align: center;
    font-weight: bold;
    color: black !important;
    text-decoration: none !important;
  }

  .mapsBtn img {
    width: 40px;
    padding: 4px;
  }

  .imgParent {
    width: 100%;
    padding-top: 75%;
    position: relative;
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: rgba(212, 212, 212, 0.4);
  }

  .border-bottom {
    border-bottom: 1px solid;
  }

  .imgParent img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    max-height: 100%;
    max-width: 100%;
    display: block;
    margin: auto;
    /* image-rendering: pixelated; */
    /* padding: 6px 0; */
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .column {
    margin-bottom: 15px;
    margin-top: 0px;
  }

  /* Display the columns below each other instead of side by side on small screens */

  @media (max-width: 650px) {
    .column {
      width: 100%;
      display: block;
    }
  }

  /* Add some shadows to create a card effect */

  .card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    cursor: pointer;
  }

  /* Some left and right padding inside the container */

  .container1 {
    /* padding: 0 16px; */
    border-radius: 5px;
  }

  /* Clear floats */

  .container1::after,
  .row::after {
    content: "";
    clear: both;
    display: table;
  }

  .title {
    color: grey;
  }

  .button {
    border: none;
    outline: 0;
    display: inline-block;
    padding: 8px;
    color: white;
    background-color: #1a496d;
    text-align: center;
    cursor: pointer;
    width: 100%;
  }

  .button:hover {
    background-color: burlywood;
  }

  .filter {
    font-size: 26px;
    text-align: left;
    margin-bottom: 10px;
  }

  .filter-title {
    font-size: 14px;
  }

  .checkbox-inline {
    width: 100%;
  }

  .checkbox-inline:first-child {
    margin-left: 10px;
  }

  .project_name {
    font-family: OpenSans-Semibold;
    font-size: 18px;
    line-height: 1.2;
  }

  .project_desc {
    font-family: OpenSans-Semibold;
    font-size: 14px;
    margin-top: 5px;
    color: #8e8e8e;
    line-height: 1.2;
  }

  .listParent {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    padding: 5px;
    margin: 5px;
    cursor: pointer;
  }

  .listParent:hover {
    background-color: rgb(226, 226, 226);
  }

  .listRight {
    width: calc(100% - 100px);
    float: right;
    padding: 7px;
    padding-left: 20px;
  }

  .listLeft {
    width: 100px;
    float: left;
  }

  .box {
    /*height: 100px;
      margin: 10px;*/
    /*width: 100px;*/
  }

  .closeBtn {
    float: left;
    width: 100%;
  }

  .closeBtn i {
    float: right;
    font-size: 20px;
  }

  .main {
    position: relative;
    /* height: calc(100vh - 152px); */
  }

  .sidefilter {
    bottom: 0px;
    width: 285px;
    /* position: fixed; */
    position: absolute;
    z-index: 2;
    /* top: 105px; */
    top: 0px;
    left: -290px;
    background-color: white;
    overflow-x: hidden;
    transition: 0.5s;
    padding: 6px 12px 12px 12px;
    -webkit-box-shadow: 9px -7px 3px -8px #888;
    box-shadow: 9px -7px 3px -8px #888;
  }

  .sidefilter1 {
    /* bottom: 0px; */
    /* width: 285px;
            position: fixed; */
    /* z-index: 2; */
    /* top: 126px;
      left: -290px; */
    /* background-color: white; */
    /* overflow-y: auto; */
    /* height: calc(100% - 135px); */
    /* transition: 0.5s; */
    /* padding: 6px 12px 12px 12px; */
    /* -webkit-box-shadow: 9px -7px 3px -8px #888;
      box-shadow: 9px -7px 3px -8px #888; */
    /* width: 285px;
        display: inline-block;
        background-color: white;
        height: calc(100% - 142px);
        padding: 6px 12px 12px 12px;
        position: fixed;
        left: 0; */
  }



  .keyword {
    outline: none;
    border: none;
    width: 100%;
    padding: 0 10px;
  }

  .searchbox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /*background-color: white;
      padding: 0;*/
    height: 44px;
    /* margin-left: 50px; */
  }

  .dedButtons {
    /* position: absolute; */
    /* left: 0px; */
    margin-top: 26px;
    margin-right: 10px;
  }

  .dedButtons button {
    border: none;
    /*color: black;*/
    outline: none;
    color: white;
    text-align: center;
    padding: 8px 14px;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    /*margin: 4px 2px;*/
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    cursor: pointer;
    /*background-color: white;*/
    background-color: #1a496d;
  }

  .dedButtons button:hover {
    /*background-color: #ffe284;*/
    background-color: white;
    color: black !important;
  }

  .active1 {
    /*background-color: #ffe284 !important;*/
    background-color: white !important;
    color: black !important;
  }

  .padding-topbottom {
    height: 100%;
    display: flex;
    background-color: white;
    justify-content: center;
  }

  .search {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 0;
    /* background-color: white; */
  }

  .search i {
    padding: 5px 12px;
    /* background-color: #ffe284; */
    cursor: pointer;
    top: 0;
    font-size: 24px;
  }

  .padding-topbottom select {
    outline: none;
    border: none;
    width: 100%;
    background-color: white;
    color: dimgray;
  }

  .scroll {
    height: calc(100% - 110px);
    padding-right: 10px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  /* Track */

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #888;
    /*-webkit-border-radius: 10px;
    border-radius: 10px;*/
  }

  /* Handle */

  ::-webkit-scrollbar-thumb {
    /*-webkit-border-radius: 10px;
    border-radius: 10px;*/
    background: #808080;
    /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); */
  }

  ::-webkit-scrollbar-thumb:window-inactive {
    /*background: rgba(255,0,0,0.4); */
  }
</style>