/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirm-c.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./confirm-c.component";
import * as i3 from "../projectservice";
var styles_ConfirmCComponent = [i0.styles];
var RenderType_ConfirmCComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmCComponent, data: {} });
export { RenderType_ConfirmCComponent as RenderType_ConfirmCComponent };
export function View_ConfirmCComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "modal fade"], ["data-backdrop", "static"], ["data-keyboard", "false"], ["id", "confirmModal"], ["role", "dialog"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["class", "modal-dialog"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 12, "div", [["class", "modal-content"], ["style", "border-radius: 0px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "button", [["class", "close"], ["data-dismiss", "modal"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.no() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Confirm"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, [" ", " "])), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "button", [["class", "btn btn-default"], ["data-dismiss", "modal"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.no() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["No"])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["data-dismiss", "modal"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.yes() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Yes"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.confirmTitle; _ck(_v, 9, 0, currVal_0); }); }
export function View_ConfirmCComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-confirm-c", [], null, null, null, View_ConfirmCComponent_0, RenderType_ConfirmCComponent)), i1.ɵdid(1, 638976, null, 0, i2.ConfirmCComponent, [i3.ProjectService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConfirmCComponentNgFactory = i1.ɵccf("app-confirm-c", i2.ConfirmCComponent, View_ConfirmCComponent_Host_0, { sanket: "sanket" }, { performAction: "performAction" }, []);
export { ConfirmCComponentNgFactory as ConfirmCComponentNgFactory };
