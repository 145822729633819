<div *ngIf="showTexbox" [@fadeInOut]="showTexbox">
    <div class="fadeinout" [@fadeInOut]="!EditToggle" *ngIf="!EditToggle">
        <div style="display: inline-block; width: 125px; vertical-align: top;">
            <div class="imgParent" routerLinkActive="active" routerLink="/project/{{mx.project_name_slug}}" style="text-decoration: none; border: 1px solid gray;    background-size: cover;
      background-repeat: no-repeat;
      background-position: center;">
                <img *ngIf="mx.project_images.length > 0" src="{{ mx.project_images[0].image_detail }}"
                    class="img-responsive flight-img">
                <img *ngIf="mx.project_images.length == 0" src="assets/images/no-image.jpg"
                    class="img-responsive flight-img">
            </div>
        </div>

        <div class="text">
            <a routerLinkActive="active" routerLink="/project/{{mx.project_name_slug}}">
                <p class="title-top1">{{ mx.project_name ? mx.project_name : 'No Name' }}</p>
            </a>
            <span *ngIf="edit" (click)="EditToggle = !EditToggle" class="glyphicon glyphicon-pencil"
                style="vertical-align: super; margin-right: 5px;"></span>
            <span *ngIf="edit" data-toggle="dropdown" class="glyphicon glyphicon-trash" style="vertical-align: super;"
                (click)="removeUserSelfFromProjects(mx.project_id, currentProject)"></span>
            <!--<ul  *ngIf="!edit" class="dropdown-menu" style="float: right; padding: 5px; right: 1px; left: 70%;top: 90px;">
        <li style="padding: 0;" (click)="removeUserSelfFromProjects(mx.project_id)">I didn't work on this project</li>
      </ul>-->
            <p class="title-top2">{{ mx.project_location }}</p>
            <p *ngIf="userData.project_role || selfUser" class="title-top3">Discipline</p>
            <p *ngIf="userData.project_role" class="msg-content">{{ userData.project_role }}</p>
            <p *ngIf="!userData.project_role && selfUser" class="msg-content" style="font-style: italic;">Edit to add
                more information</p>
            <p *ngIf="userData.job_title || selfUser" class="title-top3">Job Title At Time of This Project</p>
            <p *ngIf="userData.job_title" class="msg-content">{{ userData.job_title }}</p>
            <p *ngIf="!userData.job_title && selfUser" class="msg-content" style="font-style: italic;">Edit to add more
                information</p>
            <!-- <p *ngIf="userData.project_contribution" class="title-top3">Work Performed</p>
      <p *ngIf="userData.project_contribution" class="msg-content">
        {{ service.addLineBreaks(userData.project_contribution) }}
      </p> -->
            <p *ngIf="userData.project_role_desc || selfUser" class="title-top3 abc">Description of Role</p>
            <p *ngIf="userData.project_role_desc" class="msg-content"
                [innerHTML]="service.addLineBreaks(userData.project_role_desc)"></p>
            <p *ngIf="!userData.project_role_desc && selfUser" class="msg-content" style="font-style: italic;">Edit to
                add more information</p>
            <p *ngIf="(userData.typical_day && !service.ispublic) || selfUser" class="title-top3 abc">A Typical Day On
                This Project
            </p>
            <p *ngIf="(userData.typical_day && !service.ispublic)" class="msg-content"
                [innerHTML]="service.addLineBreaks(userData.typical_day)"></p>
            <p *ngIf="!userData.typical_day && selfUser" class="msg-content" style="font-style: italic;">Edit to add
                more information</p>
            <p *ngIf="(userData.project_highlights && !service.ispublic) || selfUser" class="title-top3 abc">What I
                Liked About This Project
            </p>
            <p *ngIf="(userData.project_highlights && !service.ispublic)" class="msg-content"
                [innerHTML]="service.addLineBreaks(userData.project_highlights)"></p>
            <p *ngIf="!userData.project_highlights && selfUser" class="msg-content" style="font-style: italic;">Edit to
                add more information</p>
            <p *ngIf="userData.project_papers?.length > 0 || userData.project_people_media.length > 0 || selfUser"
                class="title-top3">My Articles/Presentations On This Project
            </p>
            <p *ngIf="userData.project_papers?.length > 0 || userData.project_people_media.length > 0"
                class="msg-content">
                
                <span class="people"  *ngFor="let people of userData.project_papers; let i = index"
                    style="padding-right: 3px;">
                    {{i+1}}.
                    <a target="_blank" class="abc" href="{{people.url}}"
                        style="cursor: pointer;color: #337ab7;"> {{people.desc}}</a>
                        
                </span>
                
                <span class="people" *ngFor="let people of userData.project_people_media; let i = index"
                    style="padding-right: 3px;">
                    <a target="_blank" class="abc" href="{{people.project_contributor_file}}"
                        style="cursor: pointer;color: #337ab7;">{{people.project_contributor_file_name}}</a>
                </span>
            </p>
            <p *ngIf="userData.project_papers?.length == 0 && userData.project_people_media.length == 0 && selfUser"
                class="msg-content" style="font-style: italic;">Edit to add more information</p>
            <p *ngIf="userData.external_video || selfUser" class="title-top3">Video</p>
            <div class="msg-content" *ngIf="userData.external_video" style="margin-bottom: 10px;">
                <div class="intrinsic-container exvideo intrinsic-container-16x9">
                    <div style="width: 100%; height: 100%; position: absolute;background-color: lightgray;"
                        [innerHTML]="userData.external_video1"></div>
                </div>
            </div>
            <p *ngIf="!userData.external_video && selfUser" class="msg-content" style="font-style: italic;">Edit to add
                more information</p>
            <p *ngIf="userData.relevant_experience_v2 || selfUser" class="title-top3">How many years of relevant
                experience did you have when you worked on this project?</p>
            <p *ngIf="userData.relevant_experience_v2" class="msg-content">
                <span>{{ userData.relevant_experience_v2 }} years</span>
            </p>
            <p *ngIf="!userData.relevant_experience_v2 && selfUser" class="msg-content" style="font-style: italic;">Edit
                to add more information</p>
            <p *ngIf="(userData.acknowledge && !service.ispublic) || selfUser" class="title-top3">Who would you like to
                thank or acknowledge for their work on this project?</p>
            <p *ngIf="(userData.acknowledge && !service.ispublic)" class="msg-content"
                [innerHTML]="service.addLineBreaks(userData.acknowledge)"></p>
            <p *ngIf="!userData.acknowledge && selfUser" class="msg-content" style="font-style: italic;">Edit to add
                more information</p>
        </div>
    </div>
    <div [@fadeInOut]="EditToggle" *ngIf="EditToggle">
        <div style="display: inline-block; width: 125px; vertical-align: top;">
            <div class="imgParent" routerLinkActive="active" routerLink="/project/{{mx.project_name_slug}}"
                 style="text-decoration: none; border: 1px solid gray;    background-size: contain;
      background-repeat: no-repeat;
      background-position: center;">
                <img *ngIf="mx.project_images.length > 0" src="{{ mx.project_images[0].image_detail }}" class="img-responsive flight-img"
          alt="Cinque Terre">
        <img *ngIf="mx.project_images.length == 0" src="assets/images/no-image.jpg" class="img-responsive flight-img"
          alt="Cinque Terre">
            </div>
        </div>

        <div id="text{{currentProject}}" class="text">
            <!--<p style="padding-left: 27px;">
        <button class="btn btn-danger" (click)="removeUserSelfFromProjects(mx.project_id)">Remove this project</button>
      </p>-->
            <p class="projectNameEdit title-top1 projectName">{{ mx.project_name ? mx.project_name : 'No Name' }}</p>
            <button *ngIf="EditToggle" class="editToggle savebtn"
                (click)="save(jobTitle.value,roledesc.value,typical_day.value,highlights.value,acknowledge.value)">Save</button>
            <p class="title-top2">{{ mx.project_location }}</p>
            <!--<p *ngIf="userData.project_role" class="title-top3">Role and Discipline</p>-->
            <!--<p *ngIf="userData.project_role" class="msg-content">{{userData.project_role}}</p>-->
            <p class="subtitles title-top3" style="font-size: 16px;">Discipline</p>
            <p class="msg-content" style="width: 90%;">
                <select #peopleSelect class="form-control" [(ngModel)]="selectedRole" (change)="roleddChanged()">

                    <option value="">Enter role on project</option>

                    <option *ngFor="let item of lineofwork" value="{{item}}">{{item}}</option>

                </select>
            </p>
            <p *ngIf="otherRole" class="title-top3">
                <input spellcheck="true" type="text" [(ngModel)]="otherRoleValue" class="form-control"
                    placeholder="Enter other role" style="width: 90%;" />
            </p>
            <p class="subtitles title-top3" style="font-size: 16px;">Job Title At Time of This Project</p>
            <p class="msg-content">
                <input spellcheck="true" #jobTitle type="text"
                    value="{{ service.removeLineBreaks(userData.job_title) }}" class="form-control"
                    placeholder="Enter information" style="width: 90%;" />
            </p>
            <!-- <p class="title-top3">Work Performed (eg. Pond Design)</p>
      <p class="msg-content">
        <input spellcheck="true" #workperformed type="text" value="{{ service.removeLineBreaks(userData.project_contribution) }}"
          class="form-control" placeholder="Enter information" style="width: 90%;" />
      </p> -->
            <p class="subtitles title-top3" style="font-size: 16px;">Description of Role</p>
            <p class="msg-content">
                <textarea autosize [minRows]="1" spellcheck="true" #roledesc
                    [ngModel]="service.removeLineBreaks(userData.project_role_desc)"
                    placeholder="Enter information"></textarea>
                <!--<i class="glyphicon glyphicon-check dsc-glyp"
          (click)="onEnterDesc(roledesc.value, 'project_role_desc')"></i>-->
            </p>
            <p *ngIf="!service.ispublic" class="subtitles title-top3" style="font-size: 16px;">A Typical Day On This
                Project
            </p>
            <p [hidden]="service.ispublic" class="msg-content">
                <textarea autosize [minRows]="1" spellcheck="true" #typical_day
                    [ngModel]="service.removeLineBreaks(userData.typical_day)"
                    placeholder="Enter information"></textarea>
                <!--<i class="glyphicon glyphicon-check dsc-glyp" (click)="onEnterDesc(desc.value,'typical_day')"></i>-->
            </p>
            <p *ngIf="!service.ispublic" class="subtitles title-top3" style="font-size: 16px;">What I Liked About This
                Project
            </p>
            <p [hidden]="service.ispublic" class="msg-content">
                <textarea autosize [minRows]="1" spellcheck="true" #highlights
                    [ngModel]="service.removeLineBreaks(userData.project_highlights)"
                    placeholder="Enter information"></textarea>
                <!--<i class="glyphicon glyphicon-check dsc-glyp" (click)="onEnterDesc(desc.value,project_highlights)"></i>-->
            </p>
            <p class="subtitles title-top3" style="font-size: 16px;">Did You Write Any Articles or Give Any
                Presentations on This Project?</p>
            <div class="msg-content">
                <div style="margin-bottom: 5px;float: left;width: 100%;">
                    <span class="people" *ngFor="let people of peoples_edit; let i = index"
                        style="float:left; padding-right: 3px;">
                        <a class="abc" target="_blank" href="{{people.url}}"
                            style="cursor: pointer;color: #337ab7;">{{people.desc}}</a>&nbsp;
                        <i class="glyphicon glyphicon-minus-sign" (click)="removeArticle(i)"></i>
                    </span>
                    <span class="people" *ngFor="let people of media_papers_edit; let i = index"
                        style="padding-right: 3px;">
                        <a target="_blank" class="abc" href="{{people.project_contributor_file}}"
                            style="cursor: pointer;color: #337ab7;">{{people.project_contributor_file_name}}</a>&nbsp;
                        <i class="glyphicon glyphicon-minus-sign" (click)="removepdf(i)"></i>
                    </span>
                </div>
                <div>
                    <p>
                        Upload a pdf
                        <input [disabled]="evt" type="file" accept="application/pdf" multiple="false"
                            (change)="mediaSelected1($event)"
                            style="display: inline-block; font-family: OpenSans-Light;" />
                        <button [disabled]="clicked" (click)="clicked = true;mediaSelected(evt)"
                            class="editToggle btn btn-default" *ngIf="evt">
                            <i id="btn" class="glyphicon glyphicon-upload"></i> Upload</button>
                    </p>
                    <div style="margin-bottom: 5px;" *ngIf="addmoreCliked || peoples?.length == 0">
                        <div style="margin-bottom: 5px;">
                            <input spellcheck="true" type="text" class="form-control" placeholder="Enter complete URL"
                                [(ngModel)]="paperURL" style="width: 60%; display: inline-block;" />
                        </div>
                        <div>
                            <input spellcheck="true" type="text" class="form-control"
                                placeholder="Enter text to display" [(ngModel)]="paperDesc"
                                style="width: 60%; display: inline-block;" /> <i class="glyphicon glyphicon-ok"
                                (click)="addPapers()"></i>
                        </div>
                    </div>
                    <a *ngIf="!addmoreCliked && peoples?.length > 0" (click)="addmoreCliked = true"
                        style="cursor: pointer" class="awardsmore">Add more</a>
                </div>
            </div>


            <br>
            <p style="margin-top: 15px;" class="subtitles title-top3" style="font-size: 16px;">How many years of
                relevant experience did you have when you worked on this project?</p>
            <!-- <p class="msg-content"> -->
            <div class="flex-column" style="height: 65px;">
                <p class="col-sm-4" *ngFor="let item of exp">
                    <label class="radio-inline checkboxes">
                        <input type="radio" name="exp{{currentProject}}" [checked]="item.isChecked"
                            (change)="onChangeExp($event)" value="{{item.experience}}" />{{item.experience}}
                    </label>
                </p>
            </div>
            <br>
            <!-- <select class="form-control" [(ngModel)]="selectedExp">

          <option value="">Select relevant experience</option>

          <option value="0-1">0 - 1</option>

          <option value="1-2">1 - 2</option>

          <option value="2-5">2 - 5</option>

          <option value="5-10">5 - 10</option>

          <option value="10-15">10 - 15</option>

          <option value="15-None">15+</option>


        </select> -->
            <!-- </p> -->
            <p *ngIf="selectedExp == 'Other'" class="title-top3">
                <input spellcheck="true" type="text" [(ngModel)]="otherExpValue" class="form-control"
                    placeholder="Enter other experience" style="width: 88%;" />
            </p>
            <p *ngIf="!service.ispublic" class="subtitles title-top3" style="font-size: 16px;">Who would you like to
                thank or acknowledge for their work on this project?</p>
            <p [hidden]="service.ispublic" class="msg-content">
                <textarea autosize [minRows]="1" spellcheck="true" #acknowledge
                    [ngModel]="service.removeLineBreaks(userData.acknowledge)"
                    placeholder="Enter information"></textarea>
            </p>

            <p *ngIf="!external_video_edit" class="subtitles title-top3" style="margin-top:10px; font-size: 16px;">Video
            </p>
            <div *ngIf="external_video_edit" style="margin-bottom: 10px;">
                <div class="subtitles title-top3" style="font-size: 16px;">Video</div>
                <div class="col-md-6" style="float:none;text-align: right;">
                    <i (click)="deleteMedia('external_video')" class="glyphicon glyphicon-trash"></i>
                    <div class="intrinsic-container intrinsic-container-4x3"
                        style="margin-left: 12px;text-align: right;">
                        <div style="width: 100%; height: 100%; position: absolute;background-color: lightgray;"
                            [innerHTML]="external_video_iframe"></div>
                    </div>
                </div>

            </div>
            <div class="msg-content">
                <input type="text" placeholder="Enter complete Youtube/Vimeo video url" class="form-control"
                    [(ngModel)]="videoURL" style="width: 90%; ">
                <button (click)="externalMediaSelected()" class="editToggle btn btn-default" style="margin-top: 5px;">
                    <i id="btn11" class="glyphicon glyphicon-upload"></i> Upload</button>
            </div>
        </div>
    </div>
    <hr style="border-top: 1px solid #e0e0e0 !important;">
</div>
<!-- <app-confirm-c [sanket]="confirmObject" (performAction)="confirmedAction($event)"></app-confirm-c> -->
<div id="confirmModal20" class="modal fade" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content" style="border-radius: 0px;">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <h4 class="modal-title">Confirm</h4>
            </div>
            <div class="modal-body">
                {{this.confirmTitle}}
            </div>
            <div class="modal-footer">
                <button class="btn btn-default" data-dismiss="modal">No</button>
                <button class="btn btn-primary" data-dismiss="modal" (click)="yes()">Yes</button>
            </div>
        </div>
    </div>
</div>

<style>

    p{
        margin: 8px 0;
    }
    .modal-header {
        background-color: #ffe284;
    }

    @media (min-width: 320px) and (max-width: 480px) {
        .flex-column {
            height: auto !important;
        }

        input.form-control {
            width: 90% !important;
        }
    }

    :host ::ng-deep iframe #player .vp-player-layout {
        left: 0 !important;
        right: 0 !important;
    }

    .intrinsic-container {
        position: relative;
        height: 0;
        overflow: hidden;
    }

    /* 16x9 Aspect Ratio */

    .intrinsic-container-16x9 {
        padding-bottom: 56.25%;
    }

    /* 4x3 Aspect Ratio */

    .intrinsic-container-4x3 {
        padding-bottom: 75%;
    }

    .glyphicon-refresh-animate {
        -animation: spin .7s infinite linear;
        -webkit-animation: spin2 .7s infinite linear;
    }

    @-webkit-keyframes spin2 {
        from {
            -webkit-transform: rotate(0deg);
        }

        to {
            -webkit-transform: rotate(360deg);
        }
    }

    @keyframes spin {
        from {
            transform: scale(1) rotate(0deg);
        }

        to {
            transform: scale(1) rotate(360deg);
        }
    }

    .savebtn {
        visibility: hidden;
    }

    .flex-column {
        display: flex;
        max-height: 250px;
        flex-wrap: wrap;
        flex-direction: column;
        padding-left: 27px;
    }

    :host ::ng-deep .abc br {
        line-height: 27px;
    }

    .imgParent {
        width: 100%;
        padding-top: 75%;
        position: relative;
        background: rgba(212, 212, 212, 0.4);
    }

    .msg-content br {
        line-height: 1.5;
    }

    .aspect {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        max-height: 100%;
        max-width: 100%;
        display: block;
        margin: auto;
    }

    .imgParent img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        max-height: 100%;
        max-width: 100%;
        display: block;
        margin: auto;
        /* image-rendering: pixelated; */
    }

    div.fadeMe {
        opacity: 1;
        filter: alpha(opacity=20);
        background-color: #000;
        width: 100%;
        height: 100%;
        z-index: 10;
        top: 0;
        left: 0;
        position: fixed;
        text-align: center;
    }

    .not-selectable {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .backgroundPhotoText {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        font-weight: 600;
        color: #656565;
        font-family: OpenSans-Regular;
        cursor: pointer;
    }

    .editToggle {
        border-radius: 4px;
        background-color: #ffe27e;
        border: none;
        height: 30px;
        font-family: OpenSans-Bold;
        font-size: 0.9em;
    }

    .editToggle1 {
        border: none;
        font-size: 12px;
        height: 30px;
        background-color: transparent;
        font-family: OpenSans-Bold;
        color: #656565;
        padding: 0 2px
    }

    #input {
        position: absolute;
        margin-top: -200px;
    }

    .imgDiv {
        position: absolute;
        margin-left: 30px;
        margin-top: -10px;
    }

    .editPic {
        position: absolute;
        top: 160px;
        left: 179px;
        text-align: center;
        background-color: #ffe27e;
        width: 30px;
        height: 30px;
    }

    .navbar {
        margin-bottom: 0 !important;
    }

    .exp-desc {
        color: #6d6d6d;
        font-size: 13px;
    }

    .usr-name {
        font-family: open-regular;
        margin-top: 20px;
        margin-bottom: 20px;
        /*padding-left: 50px;*/
    }

    .para-span {
        color: #444343;
        font-family: open-regular;
    }

    .para-title {
        padding-left: 30px;
        font-size: 13px;
        color: grey;
    }

    .stry-btn {
        background-color: #ffe27e;
        border: none;
        height: 30px;
        font-family: OpenSans-Bold;
        font-size: 0.9em;
    }

    .my-stry {
        display: inline-block;
        /*padding-right: 25px;
    padding-left: 30px;*/
        font-family: open-regular;
    }

    .div-pad_btm {
        padding-bottom: 15px;
    }

    .subtitles {
        font-size: 16px;
    }

    .title-top3 {
        /*color: #555555;*/
        padding-left: 27px;
        font-family: OpenSans-Bold;
        font-size: 12px;
    }

    .title-top2 {
        padding-left: 27px;
        color: #898989;
        font-size: 14px;
        overflow-y: auto;
        max-height: 40px;
    }

    .projectNameEdit {
        font-family: OpenSans-Bold;
        color: #333;
        font-size: 20px;
    }

    .title-top1 {
        font-family: OpenSans-Semibold;
        color: rgb(70, 165, 247);
        padding-left: 27px;
        font-size: 20px;
        margin: 0 0 1px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-decoration: underline;
    }

    .water-res {
        font-family: OpenSans-Semibold;
        color: #808080;
        margin: 0 0 0px;
        font-size: 14px;
    }

    .desc-top {
        padding-top: 55px;
        font-family: open-regular;
    }

    .msg-content {
        padding-left: 27px;
        color: #828282;
        font-size: 14px;
        padding-right: 9px;
        font-family: open-regular;
        word-wrap: break-word;
        display: flex;
        flex-direction: column;
        /*padding-bottom: 10px;*/
    }

    .img-second-title {
        margin-top: -10px;
        padding-bottom: 14px;
        font-family: open-regular;
        color: #898989;
        font-size: 13px;
    }

    .img-title {
        font-family: OpenSans-Bold;
        color: #777777;
        font-size: 13px;
    }

    /*#contain:hover div.text {
    display: block;
  }*/
    /*.text:before {
    right: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    top: -3px;
    position: absolute;
    pointer-events: none;
    border-right-color: #ebebeb;
    border-width: 22px;
  }*/

    .text {
        /*border: 1px solid #d4d4d4;
    width: 55%;
    margin-top: -108px;
    /*background-color: #ebebeb;*/
        /*padding: 5px 0;
    max-height: 80px;*/
        /*display: none;*/
        /*overflow: auto;*/
        /*position: absolute;
    margin-left: 130px;*/
        display: inline-block;
        width: calc(100% - 130px);
    }

    #contain {
        clear: both;
        /*padding-bottom: 10px;*/
    }

    .img-grid {
        border: 5px solid #aae7c8;
        background-color: white;
    }

    .row-grid {
        border-bottom: 2px solid #aae7c8;
    }

    @media only screen and (max-width: 768px) {
        .img-grid {
            margin-bottom: 0px;
        }
    }

    /*@media only screen and (max-width: 1199px) {
    .text:after,
    .text:before {
      right: 57%;
    }
  }*/

    .content-txt1 {
        margin: 40px 0;
    }

    .flight-img {
        /* border: 2px solid #ffe27e; */
        /* display: inline-block;
    vertical-align: top;
    height: 120px;
    width: 120px; */
    }

    .popover.right>.arrow:after {
        border-right-color: #ebebeb;
    }

    .popover-content {
        background-color: #ebebeb;
    }

    @font-face {
        font-family: open-regular;
        src: url('/assets/fonts/new/opensans-regular-webfont.eot?#iefix') format('embedded-opentype'),
            /* IE */
            url('/assets/fonts/new/opensans-regular-webfont.ttf') format("truetype");
        /* non-IE */
    }

    @font-face {
        font-family: OpenSans-Light;
        src: url('/assets/fonts/new/opensans-light-webfont.eot?#iefix') format('embedded-opentype'),
            /* IE */
            url('/assets/fonts/new/opensans-light-webfont.ttf') format("truetype");
        /* non-IE */
    }

    @font-face {
        font-family: OpenSans-Semibold;
        src: url('/assets/fonts/new/opensans-semibold-webfont.eot?#iefix') format('embedded-opentype'),
            /* IE */
            url('/assets/fonts/new/opensans-semibold-webfont.ttf') format("truetype");
        /* non-IE */
    }

    @font-face {
        font-family: OpenSans-Bold;
        src: url('/assets/fonts/new/opensans-bold-webfont.eot?#iefix') format('embedded-opentype'),
            /* IE */
            url('/assets/fonts/new/opensans-bold-webfont.ttf') format("truetype");
        /* non-IE */
    }

    .prjct-wored {
        font-family: open-regular;
        padding-bottom: 15px;
    }

    @media only screen and (max-width: 768px) {
        .prjct-wored {
            margin-top: 0px;
        }

        .text {
            width: 100%;
            margin-top: 10px;
        }
    }

    .arrow-left {
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-right: 10px solid blue;
    }

    /* Let's get this party started */

    .scroll::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */

    .scroll::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px #888;
        /*-webkit-border-radius: 10px;
    border-radius: 10px;*/
    }

    /* Handle */

    .scroll::-webkit-scrollbar-thumb {
        /*-webkit-border-radius: 10px;
    border-radius: 10px;*/
        background: #808080;
        /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); */
    }

    .scroll::-webkit-scrollbar-thumb:window-inactive {
        /*background: rgba(255,0,0,0.4); */
    }

    textarea,
    .textareaClass {
        width: 90%;
        height: 100px;
        -moz-border-bottom-colors: none;
        -moz-border-left-colors: none;
        -moz-border-right-colors: none;
        -moz-border-top-colors: none;
        background: none repeat scroll 0 0 rgba(0, 0, 0, 0.07);
        border-color: -moz-use-text-color #FFFFFF #FFFFFF -moz-use-text-color;
        border-image: none;
        border-radius: 6px 6px 6px 6px;
        border-style: none solid solid none;
        border-width: medium 1px 1px medium;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12) inset;
        color: #555555;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 1em;
        line-height: 1.4em;
        padding: 5px 8px;
        transition: background-color 0.2s ease 0s;
    }

    textarea:focus,
    textareaClass:focus {
        background: none repeat scroll 0 0 #FFFFFF;
        outline-width: 0;
    }

    i {
        margin-left: 5px;
        color: black;
        font-size: 13px;
    }

    select {
        /*height: 26px;
    padding: 0px 5px;
    border: 1px solid #ccc;*/
        width: 90%;
    }

    .editToggle {
        border-radius: 4px;
        background-color: #ffe27e;
        border: none;
        height: 30px;
        font-family: OpenSans-Bold;
        font-size: 0.9em;
        vertical-align: text-bottom;
    }

    .projectName {
        width: 85%;
        display: inline-block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        color: #333;
        font-family: OpenSans-Bold;
    }

    /* .people:not(:last-child):after {
        display: flex;
        content: "";
        padding: 0 10px;
    } */

    /*#contain:not(:last-child) {
    content: "|";
    padding: 0 10px;
  }*/
</style>