<navbar (messagingLogout)="logoutMode($event)"></navbar>
<div class="main" *ngIf="isDataAvailable">
    <div class="bg2">
        <div id="mySidenav" class="col-sm-3 sidebar text-center">
            <a *ngIf="service.mobilecheck()" class="closebtn" (click)="closeNav()">&times;</a>
            <div class="dropdown drp1 text-left">
                <h3 class="group-title-sidenav">{{group_details.group_name}}</h3>
            </div>
            <div class="action-modal" *ngIf="showActionModal">
                <p style="margin-bottom: 18px;">
                    <img src="{{profileImage}}" class="user-dp" />
                    {{fullname}}
                </p>
                <p data-toggle="modal" data-target="#notifModal">Notifications</p>
                <p *ngIf="groupJoined" (click)="leaveGroup()">Leave Group</p>
                <p routerLink="/userprofile/{{full_name_slug}}">Profile</p>
                <i class="glyphicon glyphicon-remove" (click)="showActionModal = false;"></i>
            </div>
            <div *ngIf="groupJoined || group_details.group_type?.toUpperCase() == 'PUBLIC'">
                <div *ngIf="loginstatus">
                    <p class="user-name"><i class="dot"></i><span
                            style="text-decoration: underline; text-decoration-color: white;"
                            (click)="showActionModal = true;">{{fullname}}</span><span class="member"
                            *ngIf="groupJoined"> (Member)</span></p>
                </div>
                <div class="search">
                    <input #search type="text" placeholder="Search.." class="form-control" id="inputSuccess2"
                        (keydown.enter)="searchMessages(search.value)">
                </div>
                <div *ngIf="!groupJoined && group_details.group_type?.toUpperCase() == 'PUBLIC'">
                    <h3 class="text-left" style="color: white; cursor: pointer;border-radius: 4px; text-decoration: underline;" (click)="joinG()">Join Group</h3>
                </div>
                <h3 class="text-left" style="color: white; cursor: pointer;border-radius: 4px; text-decoration: underline;"
                    (click)='showChats = false; showMembers(); activeEl = -1;' [ngClass]="{active: activeEl == -1}" [ngStyle]="{'padding': activeEl == -1 ? '10px 15px' : ''}">Members</h3>
                <div class="dropdown drp1 text-left">
                    <h3>Topics</h3>
                    <div class="lass text-center" data-toggle="modal" data-target="#topicModal"
                        (click)="topicName = '';"><a class="las" style="color: #E8E8E8; text-decoration: none;">Add a
                            topic</a>
                        <span><img src="assets/images/plus1.png"></span></div>
                    <ul class="nav nav-pills nav-stacked" style="margin-top: 15px; height: calc(100vh - 454px);
                    overflow-y: auto;">
                        <li *ngFor="let item of group_details.topics; let i = index;" style="cursor: pointer;"
                            (click)="gotoTopic(item.slug, i); closeNav();">
                            <a class="las" [ngClass]="{active: activeEl == i}">#{{item.name}}</a>
                        </li>
                    </ul>
                </div>
            </div> 
        </div>
        <div class="col-sm-9 part" (scroll)="scrollChats($event)">
            <div class="a" #scrollMe infiniteScroll [infiniteScrollDistance]="0" [infiniteScrollThrottle]="50" (scrolledUp)="onScrollUp()" (scrolled)="onScrollDown()" [scrollWindow]="false">
                <div *ngIf="service.mobilecheck()" style="margin: 0 15px;">
                    <span style="font-size:22px;cursor:pointer" (click)="openNav()">&#9776; Topics</span>
                    <span class="badge" style="float: right;">{{group_details.group_type}}</span>
                </div>
                <div class="fix-div">
                    <div class="container-fluid sidemain">
                        <div class="text-center">
                            <div class="right-arrow"></div>
                        </div>
                        <div class="col-sm-3 imgdiv text-center">
                            <img src="{{group_details.group_logo}}">
                        </div>
                        <div class="col-sm-9 orginfo">
                            <h3>{{group_details.group_name}} <span *ngIf="!service.mobilecheck()"
                                    class="badge">{{group_details.group_type}}</span></h3>
                            <p style="font-size: 14px; font-family: OpenSans-Semibold;">
                                <span>{{group_details.group_location}}</span>
                                <span class="p1" *ngIf="group_details.group_homepage_url"
                                    routerLink="/companyprofile/{{group_details.group_homepage_url}}">Organization
                                    Hompage</span>
                            </p>
                            <p style="margin: 0; font-family: OpenSans-Semibold;">{{group_details.group_description}}
                            </p>
                        </div>
                    </div>
                </div>
                <div id="jumbo" class="jumbo col-sm-12" *ngIf="groupJoined || group_details.group_type?.toUpperCase() == 'PUBLIC'" [ngStyle]="{'display': showChats ? 'block' : 'none'}">
                    <div class="head">
                        <h4 *ngIf="group_details.topics.length > 0 && activeEl != -1">#{{group_details.topics[activeEl].name}}</h4>
                        <span class="glyphicon glyphicon-flag" data-toggle="modal" data-target="#flagModal2"
                            style="font-size: 20px; padding: 0 10px; color: gray;"></span>
                    </div>
                </div>
                <div *ngIf="showChats" class="container-data" style="float: left; width: 100%;">
                    <div *ngIf="loading" class="loader1"></div>
                    <div *ngIf="groupJoined || group_details.group_type?.toUpperCase() == 'PUBLIC'" style="float: left; width: 100%;">
                        <div #allTheseThings *ngFor="let message_details of messageGroups" style="float: left; width: 100%;">
                            <h5><span>{{message_details.timestamp_date}}</span></h5>
                            <div class="content1" *ngFor="let message of message_details.data">
                                <div [ngStyle]="{'display': service.mobilecheck() ? 'none' : 'inline-block'}"
                                    style="flex: 0 0 90px; padding: 0 15px;"
                                    routerLink="/userprofile/{{message.created_by_slug}}" routerLinkActive="active">
                                    <img src="{{message.created_by_image}}" onError="this.src='assets/images/default.jpeg'"
                                        class="img-rounded" height="60">
                                </div>
                                <div style="width: 100%; margin: 0 15px;">
                                    <div class="data1">
                                        <a routerLink="/userprofile/{{message.created_by_slug}}" routerLinkActive="active">
                                            <img src="assets/images/iconss.png" height="15">
                                            <span class="names">{{message.created_by_name}}</span>
                                        </a>
                                        <span class="times"> {{message.timestamp_time}}</span>
                                        <p class="ptage" [innerHTML]="message.message | linky:{stripPrefix: false}"></p>
                                        <div *ngIf="message.attachment">
                                            <a class="filesParent" target="_blank" href="{{message.attachment}}">
                                                <!-- <span class="file-blocks">
                                                    <i *ngIf="message.attachment.split('.').pop().toLowerCase() == 'png' || message.attachment.split('.').pop().toLowerCase() == 'jpg'" class="glyphicon glyphicon-picture"></i>
                                                    <i *ngIf="message.attachment.split('.').pop().toLowerCase() == 'pdf'" class="glyphicon glyphicon-file"></i>
                                                    <span class="filename">{{message.attachment.substring(message.attachment.lastIndexOf('/')+1)}}</span>
                                                </span> -->
                                                <img *ngIf="message.attachment.split('.').pop().toLowerCase() == 'png' || message.attachment.split('.').pop().toLowerCase() == 'jpg'"
                                                    class="glyphicon glyphicon-picture" src="{{message.attachment}}" />
                                            </a>
                                        </div>
                                        <!-- <hr> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="messageGroups?.length == 0 && !noTopicsAdded && !loading">
                            <h2 class="text-center" style="color: gray;">No messages here...</h2>
                        </div>
                        <div *ngIf="noTopicsAdded && !loading">
                            <h2 class="text-center" style="color: gray;">Add a topic to start a chat</h2>
                        </div>
                    </div>
                    <div *ngIf="!groupJoined && group_details.group_type?.toUpperCase() == 'PRIVATE'">
                        <h4 style="color: red; padding: 0 15px;">This group is private.  You must be a member of the group to see content. To be added to the group, email info@RePicture.com.</h4>
                    </div>
                </div>
                <div *ngIf="!showChats" style="background-color: white; float: left; width: 100%;">
                        <div class="column col-md-4" *ngFor="let item of collection_members; let i = index">
                            <!--<a class="ach" ksfs="fs" routerLinkActive="active" routerLink="userprofile/{{item.userid}}">-->
                            <div class="card">
                                <div class="box" *ngIf="item.hasAudio || item.hasVideo">
                                    <div class="ribbon">
                                        <!--<span>Has media</span>-->
                                        <img *ngIf="item.hasAudio" src="assets/images/Audio.png" />
                                        <img *ngIf="item.hasVideo" src="assets/images/Video.png" />
                                    </div>
                                </div>
                                <div class="imgParent" [ngStyle]="{'background-image': 'url('+item.imageurl+')'}" style="padding-top: 100%;">
                                    <!-- <img src="{{item.imageurl}}" alt="image"> -->
                                    <!-- <i *ngIf="service.isAdmin" class="glyphicon glyphicon-trash deleteicon"
                                        (click)="deleteUser(item.userid, item.username, item.name, i)"></i> -->
                                </div>
                                <div class="container1">
                                    <h4>{{item.name}}</h4>
                                    <p style="height: 20px;">{{item.designation}}</p>
                                    <p class="text-center">
                                        <a routerLinkActive="active" routerLink="/userprofile/{{item.username}}"
                                            style="text-decoration: none;">
                                            <button class="button">Go to Profile</button>
                                        </a>
                                    </p>
                                </div>
                            </div>
                            <!--</a>-->
                        </div>
                </div>
            </div>
            <div *ngIf="showChats" class="col-sm-12 foo">
                <input #attachment [disabled]="!loginstatus || isPrivate || noTopicsAdded || (!groupJoined && group_details.group_type?.toUpperCase() == 'PRIVATE')" type="file"
                    multiple="false" (change)="fileChanged($event)" style="display: none">
                <img src="assets/images/attch.png" style="margin-right: 10px;" (click)="attachment.click()">
                <textarea #msgInput autosize (keydown.enter)="sendMessage(msgInput.value);false;msgInput.value='';"
                    class="form-control" [disabled]="!loginstatus || isPrivate || noTopicsAdded || (!groupJoined && group_details.group_type?.toUpperCase() == 'PRIVATE')"
                    placeholder="{{checkplaceholder}}" id="comment"></textarea>
                <img src="assets/images/arrow.png" (click)="sendMessage(msgInput.value);false;msgInput.value='';"
                    style="margin-left: 5px; width: 40px;">
            </div>
        </div>
    </div>
    <div id="flagModal2" class="modal fade" role="dialog">
        <div class="modal-dialog">
            <div class="modal-content" style="border-radius: 0px;">
                <div class="modal-body">
                    <h4>Report content violation</h4>
                    <br>
                    <div class="form-group">
                        <label class="radio-inline">
                            <input type="radio" (click)="radioClick('1')" name="flagReason"> Violent or repulsive
                            content</label>
                    </div>
                    <div class="form-group">
                        <label class="radio-inline">
                            <input type="radio" (click)="radioClick('2')" name="flagReason"> Hateful or abusive
                            content</label>
                    </div>
                    <div class="form-group">
                        <label class="radio-inline">
                            <input type="radio" (click)="radioClick('3')" name="flagReason"> Promotes terrorism</label>
                    </div>
                    <div class="form-group">
                        <label class="radio-inline">
                            <input type="radio" (click)="radioClick('4')" name="flagReason"> Spam or misleading</label>
                    </div>
                    <div class="form-group">
                        <label class="radio-inline">
                            <input type="radio" (click)="radioClick('5')" name="flagReason"> Infringes my rights</label>
                    </div>
                    <div class="form-group">
                        <label class="radio-inline">
                            <input type="radio" (click)="radioClick('6')" name="flagReason"> Caption issue</label>
                    </div>
                    <div class="form-group">
                        <label class="radio-inline">
                            <input type="radio" (click)="radioClick('7')" name="flagReason"> Other</label>
                    </div>
                    <div *ngIf="showOther" class="form-group">
                        <input type="text" class="form-control" [(ngModel)]="otherReason" placeholder="Other reason" />
                    </div>
                    <p>
                        Flagged content will be reviewed by staff and appropriate action will be taken.
                    </p>
                    <br>
                    <br>
                    <div style="text-align: right;">
                        <button class="btn btn-default" data-dismiss="modal">Cancel</button>&nbsp;
                        <button class="btn btn-primary" data-dismiss="modal" (click)="submitReport()">Submit Report
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="UploadModal" class="modal fade" role="dialog" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog">
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header" style="background-color: #FFE089;">
                    <h4 class="modal-title">Upload a file <button type="button" class="close"
                            data-dismiss="modal">&times;</button></h4>
                </div>
                <div class="modal-body">
                    <p><input type="text" class="form-control" [(ngModel)]="uploadMsg" placeholder="Add message"></p>
                    <div class="files-preview">
                        <div *ngFor="let item of files; let i = index" class="filesParent">
                            <div class="file-blocks">
                                <i *ngIf="item.type.indexOf('image') == -1" class="glyphicon glyphicon-file"></i>
                                <i *ngIf="item.type.indexOf('image') != -1" class="glyphicon    glyphicon-picture"></i>
                                <br>
                                <p>{{item.name}}</p>
                            </div>
                            <i *ngIf="files.length > 1" class="glyphicon glyphicon-remove removeIcon"
                                (click)="removeAttachment(i)"></i>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>
                    <button [disabled]="!loginstatus" type="button" class="btn btn-primary" data-dismiss="modal"
                        (click)="sendMessageWithMedia()">Upload</button>
                </div>
            </div>
        </div>
    </div>
    <div id="topicModal" class="modal fade" role="dialog" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog">
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header" style="background-color: #FFE089;">
                    <h4 class="modal-title">Add new topic</h4>
                </div>
                <div class="modal-body text-center">
                    <input *ngIf="loginstatus && !isPrivate" type="text" class="form-control" [(ngModel)]="topicName"
                        placeholder="Enter topic name">
                    <!-- <p *ngIf="!loginstatus">Kindly login first..</p> -->
                    <p *ngIf="!loginstatus || isPrivate">You must have a RePicture public profile and be logged in to
                        add a
                        topic. To join go <a (click)="gotoJoin()">here</a></p>
                    <file-viewer src='https://beta-api.repicture.com/media/groups/attachments/dream-job-2904780.jpg'
                        width='500px' height='500px' controls='false'></file-viewer>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>
                    <button *ngIf="loginstatus && !isPrivate" type="button" class="btn btn-primary" data-dismiss="modal"
                        (click)="addTopic()">Add</button>
                </div>
            </div>
        </div>
    </div>
    <div id="notifModal" class="modal fade" role="dialog" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog">
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header" style="background-color: #FFE089;">
                    <h4 class="modal-title">Notification Preferences <button type="button" class="close"
                            data-dismiss="modal">&times;</button></h4>
                </div>
                <div class="modal-body">
                    <div *ngIf="loginstatus">
                        <p class="subheadings">Indicate your preference to receive an email when new messages are posted
                            to
                            this group chat</p>
                        <div style="font-family: OpenSans-regular;margin-left: 15px;">
                            <p>
                                <label class="radio-inline">
                                    <input type="radio" name="frequency" value="never" [(ngModel)]="freq">Never
                                </label>
                            </p>
                            <p>
                                <label class="radio-inline">
                                    <input type="radio" name="frequency" value="realtime" [(ngModel)]="freq" />Real time
                                </label>
                            </p>
                            <p>
                                <label class="radio-inline">
                                    <input type="radio" name="frequency" value="weekly" [(ngModel)]="freq" />Weekly
                                </label>
                            </p>
                            <p>
                                <label class="radio-inline">
                                    <input type="radio" name="frequency" value="daily" [(ngModel)]="freq" />Daily
                                </label>
                            </p>
                        </div>
                        <div *ngIf="freq != 'never'">
                            <p class="subheadings">Notifications will be sent to:</p>
                            <p>{{useremail}}</p>
                        </div>
                        <!-- <br>
                    <div *ngIf="freq != 'Never'">
                        <p>
                            <label class="checkbox-inline subheadings">
                                <input type="checkbox" (change)="changeEmailCheck($event)" />Change notification
                                preference email
                            </label>
                        </p>
                        <p>
                            <input *ngIf="showInput" type="email" [(ngModel)]="newEmail" class="form-control" placeholder="Enter your desired email" style="width: 60%;">
                        </p>
                    </div> -->
                    </div>
                    <p *ngIf="!loginstatus" class="subheadings">To Sign up for notifications, you must log in or create
                        an
                        account
                    </p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" data-dismiss="modal"
                        (click)="setnotifPref()">OK</button>
                </div>
            </div>
        </div>
    </div>