import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { ProjectService } from './projectservice';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(public service: ProjectService, public router: Router) {}

  canActivate(): boolean {
    if (!this.service.isAuthenticated) {
      this.router.navigate(['']);
      return false;
    }
    return true;
  }

}