/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./profile-mini-card.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./profile-mini-card.component";
import * as i4 from "@angular/router";
var styles_ProfileMiniCardComponent = [i0.styles];
var RenderType_ProfileMiniCardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProfileMiniCardComponent, data: {} });
export { RenderType_ProfileMiniCardComponent as RenderType_ProfileMiniCardComponent };
function View_ProfileMiniCardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", ""]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", (_co.profileMiniData.image ? _co.profileMiniData.image : "assets/new-assets/default/default.png"), ""); _ck(_v, 0, 0, currVal_0); }); }
function View_ProfileMiniCardComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "h1", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToProfile(_co.profileMiniData.slug, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["By"])), (_l()(), i1.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.profileMiniData.name; _ck(_v, 3, 0, currVal_0); }); }
function View_ProfileMiniCardComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.profileMiniData.role; _ck(_v, 1, 0, currVal_0); }); }
function View_ProfileMiniCardComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", ""]], [[8, "src", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToProfile(_v.parent.context.$implicit.slug) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", (_v.parent.context.$implicit.image ? _v.parent.context.$implicit.image : "assets/new-assets/default/default.png"), ""); _ck(_v, 0, 0, currVal_0); }); }
function View_ProfileMiniCardComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileMiniCardComponent_6)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = (_v.context.index < 5); _ck(_v, 2, 0, currVal_0); }, null); }
function View_ProfileMiniCardComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "mulImages"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileMiniCardComponent_5)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.profileMiniData.co_owners; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ProfileMiniCardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "profile-mini-main"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileMiniCardComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileMiniCardComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileMiniCardComponent_3)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileMiniCardComponent_4)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.profileMiniData.co_owners; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.profileMiniData.name; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.profileMiniData.role; _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.profileMiniData.co_owners; _ck(_v, 9, 0, currVal_3); }, null); }
export function View_ProfileMiniCardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-profile-mini-card", [], null, null, null, View_ProfileMiniCardComponent_0, RenderType_ProfileMiniCardComponent)), i1.ɵdid(1, 114688, null, 0, i3.ProfileMiniCardComponent, [i4.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProfileMiniCardComponentNgFactory = i1.ɵccf("app-profile-mini-card", i3.ProfileMiniCardComponent, View_ProfileMiniCardComponent_Host_0, { profileMiniData: "profileMiniData" }, {}, []);
export { ProfileMiniCardComponentNgFactory as ProfileMiniCardComponentNgFactory };
