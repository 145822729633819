import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-addprojectlesson',
  templateUrl: './addprojectlesson.component.html',
  styleUrls: ['./addprojectlesson.component.css']
})
export class AddprojectlessonComponent implements OnInit {

  constructor(private title: Title, private meta: Meta) { }

  ngOnInit() {
    this.title.setTitle('Adding STEM Projects to RePicture | Lesson Plan');
    this.meta.updateTag({ property: 'og:title', content: 'Adding STEM Projects to RePicture | Lesson Plan' });
    this.meta.updateTag({ property: 'og:name', content: 'Adding STEM Projects to RePicture | Lesson Plan' });
    this.meta.updateTag({ property: 'og:description', content: 'In this guide for students about engineering and STEM projects, students will learn how to add projects to RePicture.com.' });
    this.meta.updateTag({ name: 'name', content: 'Adding STEM Projects to RePicture | Lesson Plan' });
    this.meta.updateTag({ name: 'description', content: 'In this guide for students about engineering and STEM projects, students will learn how to add projects to RePicture.com.' });
  }

}
