import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { of } from "rxjs";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class AboutusService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.baseUrl = environment.baseurl;
        this.getInTouchUrl = this.baseUrl + "/api-v1/projects/contact/";
        this.team = [
            {
                image: "brian",
                name: "Bryan Ha",
                position: "Advisory Board Member",
                company: "Director of Government Relations, California Faculty Association ",
                slug: "bryan-ha",
                bio: `Bryan Ha is Director of Government Relations at the California Faculty Association [CFA], a union of 29,000
        teaching and research faculty, psychological counselors, librarians and coaches employed at the California State
        University system. Bryan leads political, legislative, and political organizing efforts
        for CFA.
        <br>
        <br>
        Before joining the CFA leadership team Bryan was a lobbyist for the California Federation of Teachers [CFT],
        where he specialized in higher education policy and budget. His work there included guiding legislation
        pertaining to California Community Colleges, University of California, women's rights, social justice, civic
        engagement, quality education for our students, fairness/living wage for teachers, and access to affordable
        housing into statute.
        <br>
        <br>
        Prior to CFT Bryan worked for the United Ways of California [UWCA] as Director of Government Affairs, At UWCA he
        provided legislative and political counsel to UWCA and its 31 United Way affiliates. Before UWCA Bryan was
        Director of Field and Advocacy at the Faculty Association of California Community Colleges, and Director of
        Advocacy for the American Heart Association.
        <br>
        <br>
        Bryan spent nearly a decade with the California State Senate, serving as Chief Consultant to the Joint Committee
        on the Arts and as Legislative Consultant to Senator Jack Scott. While at university Bryan was Student Body
        President at California State University Los Angeles, and participated in the Senate Fellowship program.
        <br>
        <br>
        Bryan has devoted his career to working with public service organizations as they sought to be active
        participants in the political process in California. His wealth of relationships, vast experience, and
        encyclopaedic knowledge of California policy and politics enable him to be a very effective advocate and to
        provide wise counsel to policy makers, public officials and the public.`,
                isMentor: false,
            },
            {
                image: "moaz",
                name: "Moaz Hamid",
                position: "Advisory Board Member",
                company: "Founding Partner, mvmt ventures",
                slug: "moaz-hamid",
                bio: `Moaz (モゼ) is a mission-driven technologist and strategist, solving for the future of jobs, medicine, and accessible technologies, and the role of Robotics and AI. He is the founder of mvmt inc, a former advisor for SoftBank Robotics, a member of AI and Health WG, and served on Robotics WG for the Consumer Technology Association (CTA), and Martin Luther King Jr. Community Healthcare dream council.<br>
      <br>
      Moaz is also an advisor and mentor for early-stage startups for Fast Track by First Round Capital, Yellow (accelerator) by Snap Inc., leAD Sports & Health Tech, Founder Institute, Founders Boost (pre-accelerator), and LA Mayor (Garcetti’s) Entrepreneurship Program for underserved-youth (embarcLA).<br>
      <br>
      He previously served as general manager and partner with Google and helped with the emerging market growth for Maps, Apps, and Search. And before that, Microsoft, helping bring smartphones to the forefront of Microsoft agenda in the emerging market from internal to partners programs to creating and bringing to market new smartphone solutions.<br>
      <br>
      Moaz also led the investment, strategy, and launch of HTC, the smartphone company, and many other global brands, like Skype, BullGuard, Sonim, Neo, Sygic, and SouthWing for Dangaard (Nordic Capital), and the investment and enterprise strategy of many other smartphone companies, like Palm, Samsung Mobile, and Sony (Ericsson) Xperia on behalf of Microsoft, and later on their transition to Android / Chrome OS for Google.<br>
      <br>
      Moaz studied systems analysis and design, and then went on to design, code, and create user experience processes for a multitude of organizations. and most recently studied Venture Capital and Angel Investing with Black Venture Institute, Salesforce Ventures, and Operator Collective at the University of California, Berkeley, Haas School of Business.
      Moaz starts every day by meditating to become organized and focused for the day. And he is also an avid runner.
      `,
                isMentor: false,
            },
            {
                image: "jennifer",
                name: "Jennifer Ives",
                position: "Advisory Board Member",
                company: "Global Executive | Board Member | Author",
                slug: "jennifer-ives",
                bio: `Jennifer Ives is an award-winning business executive with more than 20 years of experience
      leading high performing teams, crafting revenue-generating strategies, and driving double-digit
      financial results at digital product and data intensive companies around the world. During her
      career, she has held responsibility for P&L and a variety of leadership functions, including global
      sales, marketing, strategic growth, and partnerships.
      <br>
      <br>
      Jennifer began her professional journey as a geospatial engineer where she developed and
managed the creation of digital products for national security agencies and global
organizations, alike, and has worked at the intersection of emerging technologies, business, and
people ever since. Prior to her role as Senior Vice President at 3Pillar Global, a custom digital
product services company, she was the Senior Vice President of North America at Top
Employers Institute, an #HR insights and analytics services firm serving Fortune 500 clients
around the world. Prior to that, she led strategic growth at LiveSafe, a venture-backed,
award-winning emerging technology company that developed an enterprise-class safety and
risk intelligence platform serving hundreds of universities, the military, and enterprise
organizations - now part of the VectorSolutions family. Very notably, Jennifer served as an
Organizing Board Member for Startup Virginia, part of the Startup America family, and spoke at
the White House on the topic of Entrepreneurship and the Innovation Economy during the Obama
administration.
      <br>
        <br>
        A self-described ‘data geek,’ Jennifer is a sought-after speaker, podcast guest, and author, with
        multiple recognitions as a top executive, including being highlighted by industry peers and
        analysts as a Business Transformation 150 (#BT150) honoree, and named two times as a Top
        50 Women Leaders in SaaS by The Software Report. She serves on the boards of Terazo, Inc.
        and Boolean Girl, is an advisory board member of RePicture and IES’ Women in Sales Leadership
        Forum, is a founding member of CHIEF, and a member of The Leadership Center for Excellence.
        Most recently, Jennifer co-authored the top-selling book: Heels to Deals: How Women Are
        Dominating In Business-To-Business Sales.
      `,
                isMentor: false,
            },
            {
                image: "rohit",
                name: "Rohit Gupta",
                position: "Advisory Board Member",
                company: "Managing Director, Future Communities Capital",
                slug: "rohit-gupta",
                bio: `Rohit Gupta is the Managing Director of Future Communities Capital (FCC). Rohit has led investments into Coupang, Counsyl, Biomeme, SpaceX, Akido, Lyft, Roofr, and more.<br>
      <br>
      FCC looks for technology entrepreneurs focused on disrupting legacy industries such as government, healthcare, finance, and real estate. Portfolio companies are tackling problems ranging from smart city infrastructure to disease outbreak management.<br>
      <br>
      Rohit holds an MS in Environmental Engineering from Cornell University. He completed his BS at the University of Southern California.
      `,
                isMentor: false,
            },
            {
                image: "lynn",
                name: "Lynn Mayo",
                position: "Co-Founder, CEO",
                company: "RePicture",
                slug: "lynn-mayo",
                bio: `Lynn has devoted her career to excellence, achievement, and academic-style rigor. She believes in taking action rooted in research and is motivated by practical solutions that deliver results. She is personally on a mission to use the 30+ years of research on increasing diversity in STEM with her experience in industry to apply practical solutions that solve the talent gap in STEM.  
      <br>
      <br>
      Through her over 30 years of experience as a civil engineer and program manager on a wide range of projects for local, state, and federal clients, Lynn has a proven track record for growing highly productive, high-functioning, successful, diverse, and inclusive teams. She built and managed a team of over 100 staff as a Business Operations Manager at URS (now AECOM) and was later AECOM’s North American Water Resources Technical Practice Director where she was responsible for sharing knowledge, transferring technology, and leveraging the firm’s best technical capabilities to clients. She has played a key leadership role in countless proposals, winning hundreds of millions of dollars in federal contracts and a Billion-dollar infrastructure project. In a field where only 12% of civil engineers are women, Lynn built her team to include the best scientists and engineers and had near gender parity with significantly higher than average numbers of women in staff and in leadership roles. 
      <br>
      <br>
      Lynn understands the industry perspective and has seen the gap between education and career through her experience hiring and onboarding hundreds of engineers and scientists. She knows what STEM companies are looking for in candidates and what graduates need to know to stand out on job applications and to succeed on teams and in the STEM workplace. 
      <br>
      <br>
      Lynn is a graduate of the Founder Institute and graduated from Bucknell University with a BS degree in Civil Engineering and from Stanford University with a MS degree in Civil and Environmental Engineering. Lynn enjoys boating and in her free time, she is on a quest to find the perfect swimming spot in the Chesapeake Bay.
      `,
                isMentor: false,
            },
            {
                image: "aelisa",
                name: "Aelisa Carr",
                position: "Co-Founder, President",
                company: "RePicture",
                slug: "aelisa-carr",
                bio: `Aelisa is passionate about social justice, helping people follow their hearts and dreams, and transforming the world of STEM so that the people designing our world and future reflect the people they’re designing it for. 
      <br>
      <br>
      Her passion for social justice stems from her experiences growing up in Berkeley Public Schools. Berkeley is a unique and special place and was one of the first communities to voluntarily begin busing students to achieve racial integration in elementary grades in 1968. Aelisa was among the first cohorts of students at Berkeley High School to be enrolled in a new class on campus called “Ethnic Studies”, a course that continues to evolve and grapple with some of the toughest challenges around inequality facing society.  
      <br>
      <br>
      Aelisa’s life-long interest in the built and natural environment was cultivated while growing up camping, hiking, and running on the trails in California and the Bay Area, and visiting her dad’s construction sites. Her fascination with every new urban development around her was likely an early indicator of her future career as a civil engineer. Before starting RePicture, Aelisa was a civil engineer for 11 years, most recently working on a variety of high-profile projects in Washington, DC. In her job she saw first-hand the impact that engineers have on our everyday lives, and how the design decisions made at work and around conference tables (and now on zoom!) impact people’s lives and communities. 
      <br>
      <br>
      She is a first-generation-to-college graduate and understands first-hand the unique “added” challenges that come with the privilege; navigating the transition from high school to college and the professional world and finding a sense of belonging along the way. 
      <br>
      <br>
      Aelisa has a B.S. in Civil and Environmental Engineering from UC Davis and is a graduate of the CITRIS Foundry, UC Berkeley’s startup incubator.
      `,
                isMentor: false,
            },
            {
                image: "thomas",
                name: "Thomas Gerken",
                position: "Strategy Advisor",
                company: "Data Science and Business, UC Berkeley",
                slug: "thomas-gerken",
                bio: `Thomas is an undergraduate at the University of California, Berkeley studying Data Science and Economics. He brings to RePicture a variety of experiences in Business Development (VIZIO), Business Strategy and Analytics (NextGen Consulting), and Communications and Teaching (DATA 8: The Foundations of Data Science). It is Thomas’ goal to apply his analytical thinking and problem solving skills to help companies of all sizes overcome some of their most current challenges. He is continually inspired by RePicture’s ongoing mission to increase interest and diversity in STEM, and works closely with the co-founders to help shape the future roadmap for RePicture’s long term success. `,
                isMentor: false,
            },
            {
                image: "saurabh",
                name: "Saurabh Kulkarni",
                position: "Technology Advisor",
                company: "Founder and CEO, Analogy Plus, Director of Engineering, Aspire Public Schools",
                slug: "saurabh-kulkarni",
                bio: `Saurabh is passionate about using technology to empower and connect people and to bring ideas to life. He brings 17 years of experience in engineering, technology, software development, and geographic information systems to support RePicture. His background in disaster management and technology allowed him to deliver key technology to win and deliver on a National Science Foundation funded grant, selected among hundreds of applications and presented at the National Portrait Gallery in Washington, DC. As a startup founder, business owner and technology advisor on a wide range of technology projects, Saurabh brings unique insights to complex technology challenges.  Saurabh is a registered Professional Engineer in the state of Virginia and holds an M.S. in Environmental Engineering from Old Dominion University and a B.S. in Civil Engineering from the University of Mumbai.`,
                isMentor: false,
            },
            {
                image: "wall",
                name: "Jennifer Wall",
                position: "Digital Marketing Advisor",
                company: "RePicture ",
                slug: "jennifer-wall",
                bio: `Jennifer stands for what she believes in and for work being a source of meaning and inspiration. With RePicture, she uses her quick wit, savvy marketing prowess, astute observation of social trends, and great attention to detail to help get the message and our mission out there to those seeking success in their dream STEM career. She does things right, even when it’s harder and takes more time, and will always tell you what you need to hear. She runs a tight ship and handles situations with no instruction manual by creating an instruction manual. 
      <br>
      <br>
      Jennifer is a seasoned digital marketing professional with expertise in SEO, PPC, App, UA, Growth, and digital strategy. Most recently she worked in Paid Search, Apple Search Ads with Apple. 
      <br>
      <br>
      In her down time, Jennifer is a legitimate (although technically non-professional) comedian and can have an entire dinner party wiping tears away from their eyes. She lives in Napa with her husband and two young children. 
      `,
                isMentor: false,
            },
            {
                image: "jodye",
                name: "Jodye Russell",
                position: "Coach and Mentor",
                company: "Biologist, Environmental Scientist, Environmental Engineer",
                slug: "jodye-russell",
                bio: `Jodye’s passion, talent, and dedication to helping others has in recent years found many avenues for expression, including in mentoring and coaching the next generation of STEM leaders in RePicture’s Career Accelerator.
      <br>
      <br>
      Before marathon zoom sessions using her keen listening skills to get into the hearts and minds of young people figuring out their lives, Jodye got her career inspiration early on and had a long career helping to protect public health and the environment. She grew up in Northeast Philadelphia, in a very urban and densely populated middle class neighborhood and although the area offered little in the way of natural beauty, she developed a keen appreciation for the natural world and realized what she wanted to do.  By the time she was in junior high, she was working every Saturday at a high school recycling station and later had an EPA bumper sticker that said "I Can Save the Earth."
      <br>
      <br>
      Jodye studied biology and environmental science at the University of Pennsylvania, where she developed a passion for sludge, now more politically correctly called biosolids. She got her first job out of college by writing to every company that advertised in Sludge Magazine and has since used the same tenacity and personal touch in all of her endeavors, including in her work at the Washington Suburban Sanitary Commission, (where she was for 22 years) and at DC Water, two of the largest public water utilities in the world.  
      <br>
      <br>
      Jodye is a recent transplant from Maryland to the eastern shore of Delaware, and has been married for nearly 40 years to her husband who she has known since 2nd grade. The pride of their lives are their twin granddaughters, Isabel and Gabrielle.
      `,
                isMentor: false,
            },
            {
                image: "jnoran",
                name: "Jeraldyne Norman",
                position: "Panel Moderator, Peer Mentor",
                company: "Jr. Transportation Planner",
                slug: "jerri-norman",
                bio: "",
                isMentor: true,
            },
            {
                image: "rupesh",
                name: "Rupesh Kadam",
                position: "Software Developer",
                company: "RePicture, Analogy Plus",
                slug: "rupesh-kadam",
                bio: `Rupesh is a software developer with 5 years of experience in the industry, He has a proven track record in both web development and mobile app development. His expertise in front-end technologies has enabled him to create highly responsive and user-friendly websites.
      <br>
      <br>
      He has a passion for creating engaging and innovative digital experiences, and He is constantly seeking new challenges to enhance his skills. With a strong attention to detail, He delivers clean and well-structured code that not only meets client requirements, but also exceeds their expectations.
      <br>
      <br>
      He is a quick learner and a team player, who is always willing to go the extra mile to ensure project success. Rupesh is a Professional Engineer and holds B.E. in Electronics Engineering from the University of Mumbai.`,
                isMentor: false,
            },
            {
                image: "akshay",
                name: "Akshay Patil",
                position: "Software Developer",
                company: "RePicture, Analogy Plus",
                slug: "akshay-patil",
                bio: `Akshay is a backend developer with 5 years of experience is a skilled programmer with expertise in server-side architecture, database management, and API development. He is proficient in programming languages such as Python, Java, and have experience working with web frameworks and database technologies. He is familiar with server-side technologies and cloud platforms, and have experience deploying and managing applications. His technical skills are complemented by strong problem-solving abilities, attention to detail, and a collaborative approach to working with cross-functional teams.
      <br>
      <br>
      Akshay is a Professional Engineer and holds B.E. in Electronics and Telecommunication Engineering from the University of Mumbai.
      `,
                isMentor: false,
            },
            {
                image: "maritza",
                name: "Maritza Padilla",
                position: "Student Solutions Specialist",
                company: "UC Santa Cruz",
                slug: "maritza",
                bio: `Maritza is a first generation college graduate from UC Santa Cruz. Long before she fell in love with Computer Science, she spent her time writing stories and drawing using various art programs on computers she grew up around. Now she devotes her creative thinking skills to developing software solutions for causes she cares about, like diversifying STEM fields and making technology and opportunities accessible to all. Her favorite place to unwind after a hard day’s work is at the beach with a good book in hand!
      `,
                isMentor: false,
            },
            {
                image: "abolaji",
                name: "Abolaji (BJ) Oke",
                position: "Social Media Marketing Intern",
                company: "University of Miami",
                slug: "abolaji",
                bio: `BJ is our Social Media Marketing Intern. She is currently a junior at the University of Miami, studying Health Management & Policy with a minor in Public Health. At Repicture, she uses her creativity and ability to read data trends to post thoughtful content on our platforms to broaden RePicture's audience. She decided to join the team because she strongly believes in the mission to diversify the STEM world, and wants to be a part of that change anyway she can!
      <br>
      <br>
      In her spare time, she loves to cook and try new hairstyles. She also loves anything to do with the water, especially paddle boarding!
      `,
                isMentor: false,
            },
            {
                image: "lindsey",
                name: "Lindsey Tatsumi",
                position: "Peer Mentor, 2022",
                company: "University of California, Merced Bioengineering ‘25",
                slug: "lindsey",
                bio: "",
                isMentor: true,
            },
            {
                image: "katherine",
                name: "Katherine Callahan",
                position: "Peer Mentor, 2020-2022",
                company: "Environmental Engineer",
                slug: "katherine-callahan",
                bio: "",
                isMentor: true,
            },
            {
                image: "tabitha",
                name: "Tabitha Nowak",
                position: "Peer Mentor, 2021",
                company: "Lehigh University, Environmental Science ‘23",
                slug: "tabitha-nowak",
                bio: "",
                isMentor: true,
            },
            {
                image: "ashai",
                name: "Ashai Moreno",
                position: "Peer Mentor, 2022",
                company: "University of California, Santa Cruz, B.S. Astrophysics ‘25",
                slug: "ashai-moreno",
                bio: "",
                isMentor: true,
            },
            {
                image: "jim",
                name: "Jim Song-Lopez",
                position: "Peer Mentor, 2022",
                company: "University of California, Merced, B.S. Political Science ‘22",
                slug: "jim-song",
                bio: "",
                isMentor: true,
            },
            {
                image: "michael",
                name: "Michael Fischer-Haagens",
                position: "Peer Mentor, 2022",
                company: "University of California, Santa Cruz, B.A. Music ‘25",
                slug: "michael-fischer-haagens",
                bio: "",
                isMentor: true,
            },
            {
                image: "lauren",
                name: "Lauren Murphey",
                position: "Peer Mentor, 2020",
                company: "Mechanical Engineer-In-Training",
                slug: "lauren-murphy",
                bio: "",
                isMentor: true,
            },
            {
                image: "danielle",
                name: "Danielle Kleinberg",
                position: "Peer Mentor, 2020",
                company: "Gettysburg College, B.S. Chemistry and Environmental Studies ‘23",
                slug: "danielle-kleinberg",
                bio: "",
                isMentor: true,
            },
        ];
        this.endorsments = [
            {
                image: "nancy",
                name: "Nancy Skinner",
                position: "Senator",
                company: "California State Senator",
            },
            {
                image: "anthony",
                name: "Anthony Rendon",
                position: "Speaker",
                company: "California Speaker of the House",
            },
            {
                image: "mike",
                name: " Mike Fong",
                position: "Assemblymember",
                company: "California State Assemblymember",
            },
            {
                image: "chris",
                name: " Chris Holden",
                position: "Assemblymember",
                company: "California State Assemblymember",
            },
            {
                image: "diane",
                name: "Diane Papan",
                position: "Assemblymember",
                company: "California State Assemblymember",
            },
            {
                image: "phillip",
                name: "Phillip Chen",
                position: "Assemblymember",
                company: "California State Assemblymember",
            },
            {
                image: "matt",
                name: "Matt Haney",
                position: "Assemblymember",
                company: "California State Assemblymember",
            },
            {
                image: "evan",
                name: "Evan Low",
                position: "Assemblymember",
                company: "California State Assemblymember",
            },
        ];
    }
    getInTouch(user) {
        return this.httpClient.post(this.getInTouchUrl, user).pipe(map((data) => {
            return data;
        }), catchError((error) => {
            console.log(error);
            return of(null);
        }));
    }
}
AboutusService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AboutusService_Factory() { return new AboutusService(i0.ɵɵinject(i1.HttpClient)); }, token: AboutusService, providedIn: "root" });
