import { Directive, HostBinding, HostListener, OnInit, Optional, Self, ElementRef, Renderer2, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appInput]'
})
export class InputDirective implements OnInit {

  @Input() size = '';

  // @HostBinding('class')get primary(): string{
  //   return 'rp-input'
  // }

  @HostListener('blur') onblur() {
    // console.log(this.errorMessage);
    if(this.errorMessage){
      this.renderer.addClass(this.el.nativeElement,'rp-input--on-error')
    }else{
      this.renderer.removeClass(this.el.nativeElement,'rp-input--on-error')

    }
  }

  constructor(@Optional() @Self() public ngControl: NgControl,private el:ElementRef,private renderer: Renderer2) { }

  ngOnInit(): void {
    this.renderer.addClass(this.el.nativeElement,'rp-input')
    
    // this.setSize();
  
   
  }

  setSize(){
    if (this.size) {
      switch (this.size) {  
          case 'large':
            this.renderer.addClass(this.el.nativeElement,'rp-input--large');
            break;  
        case 'medium':
          this.renderer.addClass(this.el.nativeElement,'rp-input--medium');
          break;       
          case 'small':
            this.renderer.addClass(this.el.nativeElement,'rp-input--small');
            break;  

        default:
          this.renderer.addClass(this.el.nativeElement,'rp-input--large');
          break;
      }
      
    }else{
      this.renderer.addClass(this.el.nativeElement,'rp-input--large');

    }

  }

  get errorMessage(): string | null {
    if(this.ngControl){

      const touched = this.ngControl.control.touched;
      const errors = Object.entries(
        this.ngControl.control.errors || {}
      );
      if (!errors.length) {
        return null; 
      }
      if (touched) {
        const [key, value] = errors[0];
        return 'error';
      }
      return null;
    }
  }
  


}
