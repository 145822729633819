import { DesignUtilityService } from './../shared/design-utility.service';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpResponse, HttpRequest, HttpHandler } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

    service_count = 0


    constructor(private du: DesignUtilityService) {

    }
    intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // console.log('loading',this.service_count);
        this.service_count++;
        this.du.isLoading.next(true);
        return next.handle(httpRequest).pipe(
            finalize(() => {
                this.service_count--;
                 if (this.service_count === 0) {
                    // hide spinner
                //  console.log('not loading');

                this.du.isLoading.next(false);

                }
                
            }),
        );
    }
}