import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { ProfileCard } from './profile-card';

@Component({
  selector: 'app-profile-card',
  templateUrl: './profile-card.component.html',
  styleUrls: ['./profile-card.component.scss']
})
export class ProfileCardComponent implements OnInit {

  @Input() profileCardData?:ProfileCard;
  

  constructor(private router:Router ) { }

  ngOnInit(): void {
  }

  goToProfile(slug){
    if(slug != 'lastnull'){
      this.router.navigate(['/userprofile/' + slug])
    }else{
      this.router.navigate(['explorer/people/'])

    }

  }

}
