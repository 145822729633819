import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class AuthService {
    constructor() {
        this.$loginStatus = new BehaviorSubject(false);
    }
    getFullNameSlug() {
        if (localStorage.getItem("full_name_slug")) {
            return localStorage.getItem("full_name_slug");
        }
        else {
            return '';
        }
    }
    isLoggedIn() {
        return this.$loginStatus.asObservable();
    }
    login() {
        this.$loginStatus.next(true);
    }
    logout() {
        this.$loginStatus.next(false);
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(); }, token: AuthService, providedIn: "root" });
