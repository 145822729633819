import { Component, OnInit, ViewChild } from '@angular/core';
import { ProjectService } from '../../projectservice';
import { Title } from '@angular/platform-browser';
import { trigger, transition, style, animate } from '@angular/animations';
import * as $ from 'jquery';

@Component({
  selector: 'app-paymentmaincomponent',
  templateUrl: './paymentmaincomponent.component.html',
  styleUrls: ['./paymentmaincomponent.component.css'],
  animations: [
    trigger('fadeInOut', [
      transition('void => *', [
        style({
          opacity: 0
        }), //style only for transition transition (after transiton it removes)
        animate(700, style({
          opacity: 1
        })) // the new state of the transition(after transiton it removes)
      ]),
      transition('* => void', [
        animate(350, style({
          opacity: 0
        })) // the new state of the transition(after transiton it removes)
      ])
    ])
  ]
})
export class PaymentmaincomponentComponent implements OnInit {
  num: any;
  @ViewChild('target', { static: false }) target: any;
  endDate: any;
  crossedRate: any = '$359.94';
  index: number = 1;
  biannualRate: any = '$179.94';
  annualRate: any = '$599';
  tax: any = '$0.00';
  period: any = 'Biannual';
  total: any = '$179.94';
  today = new Date();
  nextDayCurrentYear: any = new Date(this.today.setDate(this.today.getDate() + 1));
  nextDayNextYear: any = new Date(this.today.setMonth(this.today.getMonth() + 13));
  sameDayWithSixMonthsAdded: any = new Date(new Date().setMonth(new Date().getMonth() + 7));
  month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  disabled: boolean = false;
  data: any;
  text = "Website Terms of Use<br>" +
    "<br>" +
    "Agreement between user and RePicture Engineering, PBC<br>" +
    "<br>" +
    "Welcome to www.iLookLikeACivilEngineer.com, RePictureEngineeering.com, and/or RePictureApp.com. The www.iLookLikeACivilEngineer.com, RePictureEngineeering.com, and RePictureApp.com website (the \"Site\") is comprised of various web pages operated by RePicture Engineering (\"RePicture Engineering\").<br>" +
    "<br>" +
    "The Site is offered to you conditioned on your acceptance without modification of the terms, conditions, and notices contained herein (the \"Terms\"). Your use of the Site constitutes your agreement to all such Terms. Please read these terms carefully, and keep a copy of them for your reference.<br>" +
    "<br>" +
    "The site is a Forum/Online discussion Site to increase interest and diversity in Engineering<br>" +
    "<br>" +
    "Electronic Communications<br>" +
    "<br>" +
    "Visiting the Site or sending emails to RePicture Engineering constitutes electronic communications. You consent to receive electronic communications and you agree that all agreements, notices, disclosures and other communications that we provide to you electronically, via email and on the Site, satisfy any legal requirement that such communications be in writing.<br>" +
    "<br>" +
    "RePicture Engineering does not knowingly collect, either online or offline, personal information from persons under the age of thirteen. If you are under 18, you may use the SIte only with permission of a parent or guardian.<br>" +
    "<br>" +
    "Links to third party sites/Third party services<br>" +
    "<br>" +
    "The Site may contain links to other websites (\"Linked Sites\"). The Linked Sites are not under the control of RePicture Engineering RePicture Engineering is not responsible for the contents of any Linked Site, including without limitation any link contained in a Linked Site, or any changes or updates to a Linked Site. RePicture Engineering is providing these links to you only as a convenience, and the inclusion of any link does not imply endorsement by RePicture Engineering of the site or any association with its operators.<br>" +
    "<br>" +
    "Certain services made available via the Site are delivered by third party sites and organizations. By using any product, service or functionality originating from the Site domain, you hereby acknowledge and consent that RePicture Engineering may share such information and data with any third party with whom RePicture Engineering has a contractual relationship to provide the requested product, service or functionality on behalf of the Site users and customers.<br>" +
    "<br>" +
    "No unlawful or prohibited use/Intellectual Property<br>" +
    "<br>" +
    "You are granted a non-exclusive, non-transferable, revocable license to access and use the Site strictly in accordance with these terms of use. As a condition of your use of the Site, you warrant to RePicture Engineering that you will not use the Site for any purpose that is unlawful or prohibited by these Terms. You may not use the Site in any manner which could damage, disable, overburden, or impair the Site or interfere with any other party's use and enjoyment of the Site. You may not obtain or attempt to obtain any materials or information through any means not intentionally made available or provided for through the Site.<br>" +
    "<br>" +
    "All content included as part of the Service, such as text, graphics, logos, images, as well as the compilation thereof, and any software used on the Site, is the property of RePicture Engineering or its suppliers and protected by copyright and other laws that protect intellectual property and proprietary rights. You agree to observe and abide by all copyright and other proprietary notices, legends or other restrictions contained in any such content and will not make any changes thereto.<br>" +
    "<br>" +
    "You will not modify, publish, transmit, reverse engineer, participate in the transfer or sale, create derivative works, or in any way exploit any of the content, in whole or in part, found on the Site. RePicture Engineering content is not for resale. Your use of the Site does not entitle you to make any unauthorized use of any protected content, and in particular you will not delete or alter any proprietary rights or attribution notices in any content. You will use protected content solely for your personal use, and will make no other use of the content without the express written permission of RePicture Engineering and the copyright owner. You agree that you do not acquire any ownership rights in any protected content. We do not grant you any licenses, express or implied, to the intellectual property of RePicture Engineering or our licensors except as expressly authorized by these Terms.<br>" +
    "<br>" +
    "Use of communication services<br>" +
    "<br>" +
    "The Site may contain bulletin board services, chat areas, news groups, forums, communities, personal web pages, calendars, and/or other message or communication facilities designed to enable you to communicate with the public at large or with a group (collectively, \"Communication Services\"), you agree to use the Communication Services only to post, send and receive messages and material that are proper and related to the particular Communication Service.<br>" +
    "<br>" +
    "By way of example, and not as a limitation, you agree that when using a Communication Service, you will not: defame, abuse, harass, stalk, threaten or otherwise violate the legal rights (such as rights of privacy and publicity) of others; publish, post, upload, distribute or disseminate any inappropriate, profane, defamatory, infringing, obscene, indecent or unlawful topic, name, material or information; upload files that contain software or other material protected by intellectual property laws (or by rights of privacy of publicity) unless you own or control the rights thereto or have received all necessary consents; upload files that contain viruses, corrupted files, or any other similar software or programs that may damage the operation of another's computer; advertise or offer to sell or buy any goods or services for any business purpose, unless such Communication Service specifically allows such messages; conduct or forward surveys, contests, pyramid schemes or chain letters; download any file posted by another user of a Communication Service that you know, or reasonably should know, cannot be legally distributed in such manner; falsify or delete any author attributions, legal or other proper notices or proprietary designations or labels of the origin or source of software or other material contained in a file that is uploaded, restrict or inhibit any other user from using and enjoying the Communication Services; violate any code of conduct or other guidelines which may be applicable for any particular Communication Service; harvest or otherwise collect information about others, including e-mail addresses, without their consent; violate any applicable laws or regulations.<br>" +
    "<br>" +
    "RePicture Engineering has no obligation to monitor the Communication Services. However, RePicture Engineering reserves the right to review materials posted to a Communication Service and to remove any materials in its sole discretion. RePicture Engineering reserves the right to terminate your access to any or all of the Communication Services at any time without notice for any reason whatsoever.<br>" +
    "<br>" +
    "RePicture Engineering reserves the right at all times to disclose any information as necessary to satisfy any applicable law, regulation, legal process or governmental request, or to edit, refuse to post or to remove any information or materials, in whole or in part, in RePicture Engineering's sole discretion.<br>" +
    "<br>" +
    "Always use caution when giving out any personally identifying information about yourself or your children in any Communication Service. RePicture Engineering does not control or endorse the content, messages or information found in any Communication Service and, therefore, RePicture Engineering specifically disclaims any liability with regard to the Communication Services and any actions resulting from your participation in any Communication Service. Managers and hosts are not authorized RePicture Engineering spokespersons, and their views do not necessarily reflect those of RePicture Engineering.<br>" +
    "<br>" +
    "Materials uploaded to a Communication Service may be subject to posted limitations on usage, reproduction and/or dissemination. You are responsible for adhering to such limitations if you upload the materials.<br>" +
    "<br>" +
    "Materials provided to the Site or posted on any RePicture Engineering web page<br>" +
    "<br>" +
    "RePicture Engineering does not claim ownership of the materials you provide to the Site (including feedback and suggestions) or post, upload, input or submit to any RePicture Engineering Site or our associated services (collectively \"Submissions\"). However, by posting, uploading, inputting, providing or submitting your Submission you are granting RePicture Engineering, our affiliated companies and necessary sublicensees permission to use your Submission in connection with the operation of their Internet businesses including, without limitation, the rights to: copy, distribute, transmit, publicly display, publicly perform, reproduce, edit, translate and reformat your Submission; and to publish your name in connection with your Submission.<br>" +
    "<br>" +
    "No compensation will be paid with respect to the use of your Submission, as provided herein. RePicture Engineering is under no obligation to post or use any Submission you may provide and may remove any Submission at any time in RePicture Engineering's sole discretion.<br>" +
    "<br>" +
    "By posting, uploading, inputting, providing or submitting your Submission you warrant and represent that you own or otherwise control all of the rights to your Submission as described in this section including, without limitation, all the rights necessary for you to provide, post, upload, input or submit the Submissions.<br>" +
    "<br>" +
    "Third Party Accounts<br>" +
    "<br>" +
    "You will be able to connect your RePicture Engineering account to third party accounts. By connecting your RePicture Engineering account to your third party account, you acknowledge and agree that you are consenting to the continuous release of information about you to others (in accordance with your privacy settings on those third party sites). If you do not want information about you to be shared in this manner, do not use this feature.<br>" +
    "<br>" +
    "International Users<br>" +
    "<br>" +
    "The Service is controlled, operated and administered by RePicture Engineering from our offices within the USA. If you access the Service from a location outside the USA, you are responsible for compliance<br>" +
    "<br>" +
    "with all local laws. You agree that you will not use the RePicture Engineering Content accessed through the Site in any country or in any manner prohibited by any applicable laws, restrictions or regulations.<br>" +
    "<br>" +
    "Indemnification<br>" +
    "<br>" +
    "You agree to indemnify, defend and hold harmless RePicture Engineering, its officers, directors, employees, agents and third parties, for any losses, costs, liabilities and expenses (including reasonable attorneys' fees) relating to or arising out of your use of or inability to use the Site or services, any user postings made by you, your violation of any terms of this Agreement or your violation of any rights of a third party, or your violation of any applicable laws, rules or regulations. RePicture Engineering reserves the right, at its own cost, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will fully cooperate with RePicture Engineering in asserting any available defenses.<br>" +
    "<br>" +
    "Liability disclaimer<br>" +
    "<br>" +
    "THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN. REPICTURE ENGINEERING, PBC AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES IN THE SITE AT ANY TIME.<br>" +
    "<br>" +
    "REPICTURE ENGINEERING, PBC AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS CONTAINED ON THE SITE FOR ANY PURPOSE. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS ARE PROVIDED \"AS IS\" WITHOUT WARRANTY OR CONDITION OF ANY KIND. REPICTURE ENGINEERING, PBC AND/OR ITS SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS, INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NONINFRINGEMENT.<br>" +
    "<br>" +
    "TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL REPICTURE ENGINEERING, PBC AND/OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE SITE, WITH THE DELAY OR INABILITY TO USE THE SITE OR RELATED SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE SITE, OR OTHERWISE ARISING OUT OF THE USE OF THE SITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF REPICTURE ENGINEERING, PBC OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SITE, OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SITE.<br>" +
    "<br>" +
    "Termination/access restriction<br>" +
    "<br>" +
    "RePicture Engineering reserves the right, in its sole discretion, to terminate your access to the Site and the related services or any portion thereof at any time, without notice. To the maximum extent permitted by law, this agreement is governed by the laws of the State of Maryland and you hereby consent to the exclusive jurisdiction and venue of courts in Maryland in all disputes arising out of or relating to the use of the Site. Use of the Site is unauthorized in any jurisdiction that does not give effect to all provisions of these Terms, including, without limitation, this section.<br>" +
    "<br>" +
    "You agree that no joint venture, partnership, employment, or agency relationship exists between you and RePicture Engineering as a result of this agreement or use of the Site. RePicture Engineering's performance of this agreement is subject to existing laws and legal process, and nothing contained in this agreement is in derogation of RePicture Engineering's right to comply with governmental, court and law enforcement requests or requirements relating to your use of the Site or information provided to or gathered by RePicture Engineering with respect to such use. If any part of this agreement is determined to be invalid or unenforceable pursuant to applicable law including, but not limited to, the warranty disclaimers and liability limitations set forth above, then the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of the agreement shall continue in effect.<br>" +
    "<br>" +
    "Unless otherwise specified herein, this agreement constitutes the entire agreement between the user and RePicture Engineering with respect to the Site and it supersedes all prior or contemporaneous communications and proposals, whether electronic, oral or written, between the user and RePicture Engineering with respect to the Site. A printed version of this agreement and of any notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to this agreement to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form. It is the express wish to the parties that this agreement and all related documents be written in English.<br>" +
    "<br>" +
    "Changes to Terms<br>" +
    "<br>" +
    "RePicture Engineering reserves the right, in its sole discretion, to change the Terms under which the Site is offered. The most current version of the Terms will supersede all previous versions. RePicture Engineering encourages you to periodically review the Terms to stay informed of our updates.<br>" +
    "<br>" +
    "Contact Us<br>" +
    "<br>" +
    "RePicture Engineering welcomes your questions or comments regarding the Terms:<br>" +
    "<br>" +
    "RePicture Engineering, PBC<br>" +

    "PO Box 342231<br>" +

    "Bethesda, MD 20827<br>" +

    "Email Address: aelisa@RePicture Engineering.com<br>" +
    "<br>" +
    "Effective as of March 2, 2018<br>";
  constructor(public service: ProjectService, private title: Title) {
  }

  ngOnInit() {
    this.title.setTitle("Payment | Repicture App");
    this.nextDayCurrentYear = this.month[this.nextDayCurrentYear.getMonth()] + ' ' + this.nextDayCurrentYear.getDate() + ', ' + this.nextDayCurrentYear.getFullYear();
    this.nextDayNextYear = this.month[this.nextDayNextYear.getMonth()] + ' ' + this.nextDayNextYear.getDate() + ', ' + this.nextDayNextYear.getFullYear();
    this.sameDayWithSixMonthsAdded = new Date(this.sameDayWithSixMonthsAdded.setDate(this.sameDayWithSixMonthsAdded.getDate() + 1));
    this.sameDayWithSixMonthsAdded = this.month[this.sameDayWithSixMonthsAdded.getMonth()] + ' ' + this.sameDayWithSixMonthsAdded.getDate() + ', ' + this.sameDayWithSixMonthsAdded.getFullYear();
    this.endDate = this.sameDayWithSixMonthsAdded;
  }

  sendEvt(item) {
    this.service.sendEvent('click', 'redirect from menu item', 'clicked item: ' + item);
  }

  tabClicked(index) {
    this.index = index;
    if (index == 1) {
      this.total = this.biannualRate;
      this.period = 'Biannual';
      this.endDate = this.sameDayWithSixMonthsAdded;
    } else {
      this.total = this.annualRate;
      this.period = 'Annual';
      this.endDate = this.nextDayNextYear;
    }
    this.updateValues();
  }

  planSelected(num) {
    this.disabled = true;
    this.num = num;
    if (this.num == 'Basic') {
      this.biannualRate = '$179.94';
      this.annualRate = '$599';
      this.crossedRate = '$359.94';
    } else if (this.num == 'Premium') {
      this.biannualRate = '$179.94';
      this.annualRate = '$699';
      this.crossedRate = '$479.94';      
    } else if (this.num == 'Platinum') {
      this.biannualRate = '$699';
      this.annualRate = '$1099';
      this.crossedRate = '$899';
    }
    this.tabClicked(this.index);
    if (this.target) {
      this.target.nativeElement.scrollIntoView({ block: 'start', behavior: 'smooth' });
    } else {
      setTimeout(() => {
        console.log(this.target);
        this.target.nativeElement.scrollIntoView({ block: 'start', behavior: 'smooth' });
      }, 1000);
    }
    this.updateValues();

  }

  updateValues() {
    this.data = {
      'type': this.num.toLowerCase(),
      'duration': this.period.toLowerCase(),
      'total': this.total
    };
    console.log(this.data);
  }

  divide(dollarValue) {
    let num: number = dollarValue.split('$')[1];
    return num / 2;
  }

}
