import { Meta, Title } from '@angular/platform-browser';
import * as i0 from "@angular/core";
import * as i1 from "@angular/platform-browser";
export class MetaService {
    constructor(title, meta) {
        this.title = title;
        this.meta = meta;
    }
    setTitle(title) {
        this.title.setTitle(title);
    }
    updateTitle(title) {
        this.meta.updateTag({ name: 'name', property: 'og:title', content: title });
    }
    updateUrl(url) {
        this.meta.updateTag({ name: 'url', property: 'og:url', content: url });
    }
    updateOgImage(url) {
        this.meta.updateTag({ property: 'og:image', content: url });
    }
    updateDescription(desc) {
        this.meta.updateTag({ name: 'description', property: 'og:description', content: desc });
    }
    removeTag() {
        this.meta.removeTag("property='og:title'");
    }
}
MetaService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MetaService_Factory() { return new MetaService(i0.ɵɵinject(i1.Title), i0.ɵɵinject(i1.Meta)); }, token: MetaService, providedIn: "root" });
