import { library } from '@fortawesome/fontawesome-svg-core';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons/faFacebookSquare';
import { faTwitterSquare } from '@fortawesome/free-brands-svg-icons/faTwitterSquare';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin';
import { Injector } from '@angular/core';
export let InjectorInstance;
// import { LinkedinResponseComponent } from './linkedin-login/linkedin-response/linkedin-response.component';
// import { HttpErrorInterceptorProvider } from './404-interceptor.service';
const icons = [
    faFacebookSquare,
    faTwitterSquare,
    faLinkedin
];
const config = {
    btnClass: 'default',
    zoomFactor: 0.1,
    containerBackgroundColor: '#ccc',
    wheelZoom: true,
    allowFullscreen: true,
    allowKeyboardNavigation: true,
    btnIcons: {
        zoomIn: 'glyphicon glyphicon-zoom-in',
        zoomOut: 'glyphicon glyphicon-zoom-out',
        rotateClockwise: 'fa fa-repeat',
        rotateCounterClockwise: 'fa fa-undo',
        next: 'glyphicon glyphicon-chevron-right',
        prev: 'glyphicon glyphicon-chevron-left',
        fullscreen: 'glyphicon glyphicon-fullscreen',
    },
    btnShow: {
        zoomIn: true,
        zoomOut: true,
        rotateClockwise: false,
        rotateCounterClockwise: false,
        next: true,
        prev: true
    }
};
library.add(...icons);
const shareProp = {
    facebook: {
        icon: ['fab', 'facebook-square']
    },
    twitter: {
        icon: ['fab', 'twitter-square']
    },
    linkedin: {
        icon: ['fab', 'linkedin']
    }
};
const ɵ0 = {};
export class AppModule {
    constructor(injector) {
        this.injector = injector;
        InjectorInstance = this.injector;
    }
}
export { ɵ0 };
