import { Component, OnInit, Input, SimpleChanges, OnChanges, AfterViewChecked } from '@angular/core';
import { Router } from '@angular/router';
import { ProjectService } from '../../projectservice';
declare let paypal: any;
@Component({
  selector: 'app-paymentcorecomponent',
  templateUrl: './paymentcorecomponent.component.html',
  styleUrls: ['./paymentcorecomponent.component.css']
})
export class PaymentcorecomponentComponent implements OnInit, OnChanges, AfterViewChecked {

  pay: number = 2;
  basic: boolean = true;
  premium: boolean = false;
  platinum: boolean = false;
  biannual: boolean = true;
  annual: boolean = false;

  @Input() data: any;

  constructor(private router: Router, private service: ProjectService) { }

  addScript: boolean = false;
  paypalLoad: boolean = true;

  finalAmount: number = 1;

  paypalConfig = {
    env: 'production',
    client: {
      sandbox: 'ARNeH3G3ZLiuZTboZhm36UIyG90clSJR-VfgHl1U3Yo4V0Oyjc5-32Zn0uSruyW7qH_6hX-VJIkVXgZq',
      production: 'AcVzm-80ZEkvu3UA4-7hwFyyUMKXvusEQPDvlUPBw84Arnoe1-Su0LhWJknC6mM9--mjRJl0XbR6WKGl'
    },
    commit: true,
    style: {
      color: 'gold',   // 'gold, 'blue', 'silver', 'black'
      size:  'medium', // 'medium', 'small', 'large', 'responsive'
      shape: 'pill'    // 'rect', 'pill'
    },
    payment: (data, actions) => {
      return actions.payment.create({
        payment: {
          transactions: [
            { amount: { total: this.finalAmount, currency: 'USD' } }
          ]
        }
      });
    },
    onAuthorize: (data, actions) => {
      return actions.payment.execute().then((payment) => {
        //Do something when payment is successful.
        alert('Payment Successful, Thank You!');
        setTimeout(() => {
          this.upgradeProfile();          
        }, 1000);
      })
    }
  };

  upgradeProfile() {
		let body = {
			'org_is_enhanced': true
		}
		this.service.doCompanyProfilePatch(sessionStorage.getItem("companyId"), body).then(res => {
			this.service.isProfileEnhanced = true;
      this.router.navigate(['/companyprofile', sessionStorage.getItem("companyId")]);
		});
	}

  ngAfterViewChecked(): void {
    if (!this.addScript) {
      this.addPaypalScript().then(() => {
        paypal.Button.render(this.paypalConfig, '#paypal-checkout-btn');
        this.paypalLoad = false;
      })
    }
  }

  addPaypalScript() {
    this.addScript = true;
    return new Promise((resolve, reject) => {
      let scripttagElement = document.createElement('script');
      scripttagElement.src = 'https://www.paypalobjects.com/api/checkout.js';
      scripttagElement.onload = resolve;
      document.body.appendChild(scripttagElement);
    })
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    console.log('changes', changes);
    this.basic = false;
    this.premium = false;
    this.platinum = false;
    this.biannual = false;
    this.annual = false;
    this[changes.data.currentValue['type']] = true;
    this[changes.data.currentValue['duration']] = true;
    this.finalAmount = changes.data.currentValue['total'].split('$')[1];
    console.log(this.platinum, this.finalAmount);
  }

}
