/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./student-teacher.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../navbar/navbar.component.ngfactory";
import * as i3 from "../../navbar/navbar.component";
import * as i4 from "../../new-repicture/auth/auth.service";
import * as i5 from "../../projectservice";
import * as i6 from "@angular/router";
import * as i7 from "angular2-cookie/services/cookies.service";
import * as i8 from "@angular/platform-browser";
import * as i9 from "../../registercomponent/register.component.ngfactory";
import * as i10 from "../../registercomponent/register.component";
import * as i11 from "./student-teacher.component";
var styles_StudentTeacherComponent = [i0.styles];
var RenderType_StudentTeacherComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StudentTeacherComponent, data: {} });
export { RenderType_StudentTeacherComponent as RenderType_StudentTeacherComponent };
export function View_StudentTeacherComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "navbar", [], null, null, null, i2.View_NavbarComponent_0, i2.RenderType_NavbarComponent)), i1.ɵdid(1, 4833280, null, 0, i3.NavbarComponent, [i4.AuthService, i5.ProjectService, i6.Router, i7.CookieService, i1.ChangeDetectorRef, i8.Title, i1.PLATFORM_ID], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["style", "margin-top: 50px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "col-sm-8 col-sm-offset-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "regForm", [], null, null, null, i9.View_RegisterForm_0, i9.RenderType_RegisterForm)), i1.ɵdid(5, 638976, null, 0, i10.RegisterForm, [i5.ProjectService, i6.Router, i6.ActivatedRoute, i1.PLATFORM_ID], { forStudents: [0, "forStudents"] }, null)], function (_ck, _v) { _ck(_v, 1, 0); var currVal_0 = true; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_StudentTeacherComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-student-teacher", [], null, null, null, View_StudentTeacherComponent_0, RenderType_StudentTeacherComponent)), i1.ɵdid(1, 114688, null, 0, i11.StudentTeacherComponent, [i6.Router, i6.ActivatedRoute, i5.ProjectService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StudentTeacherComponentNgFactory = i1.ɵccf("app-student-teacher", i11.StudentTeacherComponent, View_StudentTeacherComponent_Host_0, {}, {}, []);
export { StudentTeacherComponentNgFactory as StudentTeacherComponentNgFactory };
