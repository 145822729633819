/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./org-card.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../directives/button-directive/rp-buttons.directive";
import * as i3 from "./org-card.component";
import * as i4 from "@angular/router";
var styles_OrgCardComponent = [i0.styles];
var RenderType_OrgCardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OrgCardComponent, data: {} });
export { RenderType_OrgCardComponent as RenderType_OrgCardComponent };
export function View_OrgCardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["style", "height: 100%;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "org-flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["alt", ""]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "data-div"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h1", [["class", "org-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "button", [["class", "org-button"], ["rp-button", ""], ["size", "medium"], ["type", "accent"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToOrg(((_co.orgCardData == null) ? null : _co.orgCardData.slug)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 4276224, null, 0, i2.RpButtonsDirective, [i1.ElementRef, i1.Renderer2], { size: [0, "size"], type: [1, "type"] }, null), (_l()(), i1.ɵted(-1, null, ["View Profile"]))], function (_ck, _v) { var currVal_2 = "medium"; var currVal_3 = "accent"; _ck(_v, 8, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", (((_co.orgCardData == null) ? null : _co.orgCardData.image) ? ((_co.orgCardData == null) ? null : _co.orgCardData.image) : "assets/new-assets/default/default.png"), ""); _ck(_v, 3, 0, currVal_0); var currVal_1 = ((_co.orgCardData == null) ? null : _co.orgCardData.name); _ck(_v, 6, 0, currVal_1); }); }
export function View_OrgCardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-org-card", [], null, null, null, View_OrgCardComponent_0, RenderType_OrgCardComponent)), i1.ɵdid(1, 114688, null, 0, i3.OrgCardComponent, [i4.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OrgCardComponentNgFactory = i1.ɵccf("app-org-card", i3.OrgCardComponent, View_OrgCardComponent_Host_0, { orgCardData: "orgCardData" }, {}, []);
export { OrgCardComponentNgFactory as OrgCardComponentNgFactory };
