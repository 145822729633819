import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ProjectService } from '../projectservice';

@Component({
  selector: 'app-inspire',
  templateUrl: './inspire.component.html',
  styleUrls: ['./inspire.component.css']
})
export class InspireComponent implements OnInit {

  constructor(private meta: Meta, private titleService: Title, private service: ProjectService) { }

  ngOnInit() {
    this.titleService.setTitle("Engineers & Scientists: Tell your story and inspire students!" + " | " + this.service.project_name);
    this.meta.updateTag({ property: 'og:title', content: "Engineers & Scientists: Tell your story and inspire students!" + " | " + this.service.project_name });
    this.meta.updateTag({ property: 'og:name', content: "Engineers & Scientists: Tell your story and inspire students!" + " | " + this.service.project_name });
    this.meta.updateTag({ property: 'og:description', content: 'Help increase interest and diversity in science, technology, engineering, and math (STEM) by telling the stories of STEM professionals and their projects.' });
    this.meta.updateTag({ name: 'name', content: "Engineers & Scientists: Tell your story and inspire students!" + " | " + this.service.project_name });
    this.meta.updateTag({ name: 'description', content: 'Help increase interest and diversity in science, technology, engineering, and math (STEM) by telling the stories of STEM professionals and their projects.' });
  }

}
