import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-rp-chips',
  templateUrl: './rp-chips.component.html',
  styleUrls: ['./rp-chips.component.scss']
})
export class RpChipsComponent implements OnInit {

  @Output() readonly removed: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
    
  }

  remove(){
    this.removed.emit(this);
  }

}
