/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./addprojectlesson.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../navbar/navbar.component.ngfactory";
import * as i3 from "../navbar/navbar.component";
import * as i4 from "../new-repicture/auth/auth.service";
import * as i5 from "../projectservice";
import * as i6 from "@angular/router";
import * as i7 from "angular2-cookie/services/cookies.service";
import * as i8 from "@angular/platform-browser";
import * as i9 from "../../../node_modules/ng2-pdfjs-viewer/ng2-pdfjs-viewer.ngfactory";
import * as i10 from "ng2-pdfjs-viewer";
import * as i11 from "./addprojectlesson.component";
var styles_AddprojectlessonComponent = [i0.styles];
var RenderType_AddprojectlessonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AddprojectlessonComponent, data: {} });
export { RenderType_AddprojectlessonComponent as RenderType_AddprojectlessonComponent };
export function View_AddprojectlessonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "navbar", [], null, null, null, i2.View_NavbarComponent_0, i2.RenderType_NavbarComponent)), i1.ɵdid(1, 4833280, null, 0, i3.NavbarComponent, [i4.AuthService, i5.ProjectService, i6.Router, i7.CookieService, i1.ChangeDetectorRef, i8.Title, i1.PLATFORM_ID], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "ng2-pdfjs-viewer", [["pdfSrc", "/assets/pdfs/RePicture_Adding_Projects_Guide_Nov_25_2019.pdf"], ["zoom", "auto"]], null, null, null, i9.View_PdfJsViewerComponent_0, i9.RenderType_PdfJsViewerComponent)), i1.ɵdid(4, 114688, null, 0, i10.PdfJsViewerComponent, [], { zoom: [0, "zoom"], pdfSrc: [1, "pdfSrc"] }, null)], function (_ck, _v) { _ck(_v, 1, 0); var currVal_0 = "auto"; var currVal_1 = "/assets/pdfs/RePicture_Adding_Projects_Guide_Nov_25_2019.pdf"; _ck(_v, 4, 0, currVal_0, currVal_1); }, null); }
export function View_AddprojectlessonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-addprojectlesson", [], null, null, null, View_AddprojectlessonComponent_0, RenderType_AddprojectlessonComponent)), i1.ɵdid(1, 114688, null, 0, i11.AddprojectlessonComponent, [i8.Title, i8.Meta], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AddprojectlessonComponentNgFactory = i1.ɵccf("app-addprojectlesson", i11.AddprojectlessonComponent, View_AddprojectlessonComponent_Host_0, {}, {}, []);
export { AddprojectlessonComponentNgFactory as AddprojectlessonComponentNgFactory };
