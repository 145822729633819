import {
  Component
} from '@angular/core';


import {
  ProjectService
}
from './../projectservice';

@Component({
  selector: 'register',
  templateUrl: './createprofile.html'
 
})
export class CreateProfileComponent {
    constructor(public service: ProjectService) {

  }

  doReg(username:string,email:string,pass:string)
  {

  }


}


interface marker {
  lat: string;
  lng: string;
  label: string;
  name: string;
  type: string;
  subtype: string;
  status: string;
  draggable: boolean;
}

