<div [ngSwitch]="data.type" style="height: 100%;">
  <div *ngSwitchCase="'paymentConfirm'">
    <h1 [ngClass]="data.content.type == 'success' ? 'success' : 'error'" class="title" mat-dialog-title>{{ data.title }}
    </h1>

    <mat-dialog-content class="content" [innerHtml]="data.content.message"></mat-dialog-content>


    <div style="margin: -10px auto;" mat-dialog-actions>
      <button [ngClass]="data.content.type == 'success' ? 'success' : 'error'" class="donebtn" mat-button
        [mat-dialog-close]="paymentConfirmfunc" cdkFocusInitial>
        OK
      </button>
    </div>
  </div>
  <div *ngSwitchCase="'common'">
    <h1 [ngClass]="data.content.type == 'success' ? 'success' : 'error'" class="title" mat-dialog-title>{{ data.title }}
    </h1>

    <mat-dialog-content class="content" [innerHtml]="data.content.message"></mat-dialog-content>


    <div style="margin: -10px auto;" mat-dialog-actions>
      <button [ngClass]="data.content.type == 'success' ? 'success' : 'error'" class="donebtn" mat-button
        [mat-dialog-close]="okayOrRetry" cdkFocusInitial>
        OK
      </button>
    </div>
  </div>

  <div *ngSwitchCase="'orgCode'" class="orgCode-main">



    <div class="orgCode">
      <h1>{{orgcodeTitle}}</h1>
      <p>{{orgcodeSubTitle}}</p>
      <form action="">
      <app-form-field [label]="" *ngIf="!isCodeApplied" >
        <input [(ngModel)]="code" placeholder="XXXXXX" autocomplete="off" type="text" appInput #codefield="ngModel" [ngModelOptions]="{standalone: true}"/>
        

      </app-form-field>
    </form>
    </div>
    <div class="discount-div" *ngIf="isCodeApplied">
      <button class="priceButton">$299
        <div class="crossline"></div>
      </button>
      <h1>$0</h1>
    </div>



    <button rp-button size="medium" type="primary" (click)="orgCodeEnterd(code)">{{orgButtonTitle}}</button>

    <img (click)="close()" src="assets/new-assets/icons/closewhite.svg" alt="">

  </div>
</div>