import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProjectService } from '../../projectservice';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-career-job-listing',
  templateUrl: './career-job-listing.component.html',
  styleUrls: ['./career-job-listing.component.css']
})
export class CareerJobListingComponent implements OnInit {
  pdfSrc: any;
  replacement: any;
  doneLoading: boolean = false;
  careerData: any;
  @ViewChild('pdfViewerAutoLoad', { static: true }) pdfViewerAutoLoad;

  constructor(private route: ActivatedRoute, private service: ProjectService, public sanitizer: DomSanitizer, private meta: Meta, private title: Title, private http: HttpClient) {
    let length = this.meta.getTags('property="og:image"').length;
    for (let i = 0; i < length; i++) {
      this.meta.removeTag('property="og:image"');
    }
  }

  ngOnInit() {
    this.route.params.subscribe(param => {
      this.service.getCareerDetails(param['slug']).subscribe(res => {
        this.careerData = res;
        this.title.setTitle(this.careerData['title']);
        this.meta.updateTag({ property: 'og:title', content: this.careerData['title'] });
        this.meta.addTag({ property: 'og:image', content: this.careerData['logo'] });
        this.meta.updateTag({ property: 'og:description', content: this.careerData['description'] });
        this.meta.updateTag({ name: 'description', content: this.careerData['description'] });
        this.meta.updateTag({ property: 'og:name', content: this.careerData['name'] });
        this.meta.updateTag({ name: 'name', content: this.careerData['name'] });
        // this.pdfSrc = this.sanitizer.bypassSecurityTrustResourceUrl('https://cors-anywhere.herokuapp.com/https://docs.google.com/gview?url=' + this.careerData['pdf_url'] + '&embed=true');
        let url = 'https://cors-anywhere.herokuapp.com/' + this.careerData['pdf_url'];
       
        this.downloadFile(url).subscribe(
          (res) => {
            this.pdfViewerAutoLoad.pdfSrc = res; // pdfSrc can be Blob or Uint8Array
            this.pdfViewerAutoLoad.refresh(); // Ask pdf viewer to load/refresh pdf
          }
        );
      });
    });
  }

  private downloadFile(url: string): any {
    return this.http.get(url, { responseType: 'blob' })
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  pdfURL() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.pdfSrc);
  }

}
