import { Router, ActivatedRoute } from '@angular/router';
import { Ng2AutoCompleteModule } from 'ng2-auto-complete';

import {
  Component,
  NgZone,
  EventEmitter,
  Input,
  Output,
  ChangeDetectorRef
} from '@angular/core';

import {
  ProjectService
}
  from './../projectservice';


import { IMultiSelectOption } from 'angular-2-dropdown-multiselect';

import * as _ from 'lodash';

import {
  CookieService
} from 'angular2-cookie/core';

import { Title } from '@angular/platform-browser';

@Component({
  selector: 'createcompany',
  templateUrl: './createcompany.html'
})

export class CompanyCreate {

  otherRoleDialog: boolean = false;
  companyCreated: boolean = false;
  otherCat: any;
  id: any;
  email: any;
  company_name: any;
  useremail: any;
  count: any = 0;
  companyRole: any = [];
  addorganization: any;
  user: any = {}
  loginStatus: boolean = false;
  created: boolean = false;
  checked: boolean = false;
  createdByRep: boolean = false;
  createdByOther: boolean = false;
  verified: boolean = false;
  verify_code: any;
  notifySetting: boolean = false;
  notify_email: any;
  loginObject: any = {};
  loginNow: boolean = false;
  private companyRoleOptions: IMultiSelectOption[] = [
    { id: 'Engineering Firm', name: 'Engineering Firm', params: 'firm' },
    { id: 'Architect Firm', name: 'Architect Firm', params: 'firm' },
    { id: 'Architect & Engineering Firm', name: 'Architect & Engineering Firm', params: 'firm' },
    { id: 'Environmental Firm', name: 'Environmental Firm', params: 'firm' },
    { id: 'Geotechnical Firm', name: 'Geotechnical Firm', params: 'firm' },
    { id: 'Planning Firm', name: 'Planning Firm', params: 'firm' },
    { id: 'Construction Contractor', name: 'Construction Contractor', params: 'firm' },
    { id: 'Engineering/Construction Supplier', name: 'Engineering/Construction Supplier', params: 'firm' },
    { id: 'Developer/Property Owner', name: 'Developer/Property Owner', params: 'firm' },
    { id: 'College or University', name: 'College or University', params: 'univ' },
    { id: 'School or School District', name: 'School District', params: 'other' },
    { id: 'Utility', name: 'Utility', params: 'other' },
    { id: 'Nonprofit Organization', name: 'Nonprofit Organization', params: 'npo' },
    { id: 'Professional Association', name: 'Professional Association', params: 'pa' },
    { id: 'State or Local Government', name: 'State or Local Government', params: 'govt' },
    { id: 'Federal Government', name: 'Federal Government', params: 'govt' },
    { id: 'Military', name: 'Military', params: 'govt' },
    { id: 'Other', name: 'Other', params: 'other' },
  ];
  org_slug: any;
  org_types = [];

  constructor(public service: ProjectService, private cookie: CookieService, private cd: ChangeDetectorRef, private titleService: Title) { }

  ngOnInit() {
    this.titleService.setTitle("Create company | Repicture App");
    if (localStorage.getItem('authtoken')) {
      this.loginStatus = true;
    }
  }

  cancel() {
    this.service.cancelOrgCreation(this.id).then(res => {
      //alert('Organization creation has been cancelled.');
      this.service.sendAlert('Error', 'Organization creation has been cancelled.', 0);
      this.createdByRep = false;
      this.created = false;
    });
  }

  onChangedd($event) {
    console.log($event);
    this.companyRole = $event;
    if (_.includes($event, 'Other')) {
      this.otherRoleDialog = true;
    } else {
      this.otherRoleDialog = false;
    }
  }

  createCompany(name) {
    if (localStorage.getItem('authtoken')) {
      if (this.service.myOrg && this.checked) {
        // alert('Your profile is already associated with another organization. Please uncheck the box to continue.');
        this.service.sendAlert('Error', 'Your profile is already associated with another organization. Please uncheck the box to continue.', 0);
        return;
      }
      if (_.isEmpty(this.companyRole) || !name) {
        //alert('Complete all fields to continue.');
        this.service.sendAlert('Error', 'Complete all fields to continue.', 0);
        return;
      }
      if (this.checked && !this.email) {
        //alert("Please enter your organization's email.");
        this.service.sendAlert('Error', "Please enter your organization's email.", 0);
        return;
      }
      // this.service.sendAlert('Info', "Please contact info@RePicture.com to add an organization.", 1);
      // return;
      if (this.checked && this.email) {
        this.service.isCompanyEmailValid(this.email).then(res => {
          if (res['status'] == "Invalid email") {
            //alert("Email doesn't seem to be an organizations's email.");
            this.service.sendAlert('Error', "Email doesn't seem to be an organizations's email.", 0);
            return;
          } else if (res['status'] == "Valid email") {
            if (_.includes(this.companyRole, 'Other')) {
              let index = this.companyRole.indexOf('Other');
              this.companyRole.splice(index, 1);
              this.companyRole.push(this.otherCat);
            }
            this.create(name);
          }
        });
      }
      if (!this.checked) {
        this.create(name);
      }
    } else {
      this.loginStatus = false;
    }
  }

  create(name) {
    for (let j = 0; j < this.companyRole.length; j++) {
      for (let i = 0; i < this.companyRoleOptions.length; i++) {
        if (this.companyRole[j] === this.companyRoleOptions[i].id) {
          if (!_.includes(this.org_types, this.companyRoleOptions[i].params)) {
            this.org_types.push(this.companyRoleOptions[i].params);
          }
        }
      }
    }
    let obj = {
      org_name: name,
      org_role: this.companyRole,
      org_types: this.org_types,
      org_certification: null,
      org_achievements: null,
      org_other: "{}",
      org_type: "CORP",
      org_size: "",
      org_email: this.checked ? this.email : '',
      is_representative: this.checked ? true : false
    };


    this.service.addOrg(obj).then(result => {
      this.company_name = name;
      this.id = result['id'];
      this.org_slug = result['org_name_slug'];
      this.created = true;
      if (this.checked) {
        this.createdByRep = true;
        this.service.sendCode(this.id, this.email).then(res => {
        });
      } else {
        this.createdByOther = true;
      }
    });
  }

  verifyCode() {
    if (!this.verify_code) {
      // alert('Please enter the code.')
      this.service.sendAlert('Error', "Please enter the code.", 0);
      return;
    }
    let obj = {
      org_verify_code: this.verify_code,
      org_id: this.id
    }
    this.service.verifyOrgRep(obj).then(result => {
      if (result['status'] == "Verified") {
        this.createdByRep = false;
        this.verified = true;
        this.addorganization = this.id
      } else if (result['status'] == "Incorrect code") {
        //alert('Incorrect code, please try again!');
        this.service.sendAlert('Error', "Incorrect code, please try again!", 0);
        return;
      } else {
        //alert('Unknown error!');
        this.service.sendAlert('Error', "Unknown error!", 0);
        return;
      }
    });

  }

  notify() {
    if (!this.notify_email) {
      //alert('Please enter email.');
      this.service.sendAlert('Error', "Please enter email.", 0);
      return;
    }
    // let body;
    // if (this.notifySetting) {
    //   body = 'Hi, Someone has created a profile for your organization. Please claim if it belongs to your organization. Please goto following URL to visit the page: ' + this.service.url + '/companyprofile/' + this.id;
    // } else {
    //   body = 'Hi, ' + localStorage.getItem('first_name') + ' ' + localStorage.getItem('last_name') + ' has created a profile for your organization. Please goto following URL to visit the page: ' + this.service.url + '/companyprofile/' + this.id;
    // }

    let obj = {
      to: this.notify_email,
      org_id: this.id,
      org_name: this.company_name,
      is_anonymous: this.notifySetting,
      org_name_slug: this.org_slug
    }
    this.service.notifyUser(obj).then(result => {
      let body = {
        "created_by_name": localStorage.getItem('first_name') + ' ' + localStorage.getItem('last_name')
      };
      // let companyId = this.id;
      this.service.doCompanyProfilePatch(this.org_slug, body).then(result => {
        //alert('Email has been sent.');
        this.service.sendAlert('Success', "Email has been sent.", 1);
      });
    });
  }

  // signup() {
  //   if (this.validate()) {
  //     this.service.doReg(this.user.first_name, this.user.last_name, this.user.email, this.user.password, this.user.password, this.user.email).then(res => {
  //       this.user.first_name = '';
  //       this.user.last_name = '';
  //       this.user.email = '';
  //       this.user.password = '';
  //       window.localStorage.setItem("first_name", this.user.first_name);
  //       window.localStorage.setItem("last_name", this.user.last_name);
  //       window.localStorage.setItem("username", this.user.email);
  //      //alert('An email has been sent to your email address with account activation instructions.');
  //      this.service.sendAlert('Success', "An email has been sent to your email address with account activation instructions.", 1);
  //       setTimeout(() => {
  //       }, 1000);
  //     });
  //   }
  // }

  validate() {
    if (this.user.first_name.trim() == "") {
      //alert("Please provide your first name!");
      this.service.sendAlert('Error', "Please provide your first name!", 0);
      return false;
    }

    if (this.user.email == "") {
      //alert("Please provide your email!");
      this.service.sendAlert('Error', "Please provide your email!", 0);
      return false;
    } else {
      if (this.validateEmail(this.user.email)) {
        //alert("You have entered an invalid email address!");
        this.service.sendAlert('Error', "You have entered an invalid email address!", 0);
        return false;
      }
    }

    var elem = document.getElementById("pwd");

    var pattern = elem.getAttribute("pattern");
    var re = new RegExp(pattern);
    if (!re.test(elem['value'])) {
      //alert('Please revise password to meet complexity requirements!');
      this.service.sendAlert('Error', "Please revise password to meet complexity requirements!", 0);
      return false;
    }

    return true;
  }

  login() {
    if (!this.loginObject.email.trim() || !this.loginObject.password.trim()) {
      return;
    }
    if (this.validateEmail(this.loginObject.email)) {
      //alert("You have entered an invalid email address!");
      this.service.sendAlert('Error', "You have entered an invalid email address!", 0);
      return false;
    }

    this.service.doLogin(this.loginObject.email, this.loginObject.password).then(result => {
      if (result['token']) {
        this.loginStatus = true;
        this.loginNow = true;
      } else {
        let varx = "";

        _.forEach(<any>result, function (value, key) {

          varx = varx + value[0];

        });
        //alert(varx);
        this.service.sendAlert('Error', varx, 0);
      }
    });
  }

  validateEmail(email) {
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    }
  }
}