import { Directive, ElementRef, HostBinding, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[rp-button]',
})
export class RpButtonsDirective implements OnInit {

  

  @Input() size = '';
  @Input() type = '';
  @Input() textColor = '';

  constructor(private el: ElementRef,private renderer: Renderer2) { 

   }

   ngOnInit() {
    this.renderer.addClass(this.el.nativeElement,'rp-button');

    this.setType();
    this.setSize();
    this.setTextColor();
    
  }

  ngAfterViewInit(): void {

  }

  setType(){
    if(this.type){
      switch (this.type) {
        case 'primary':
          // console.log('prim');
          this.renderer.addClass(this.el.nativeElement,'rp-button--primary');
          break;       
          case 'accent':
            this.renderer.addClass(this.el.nativeElement,'rp-button--accent');
            break;  
          case 'secondary':
            this.renderer.addClass(this.el.nativeElement,'rp-button--secondary');
            break;  
          case 'tertiary':
            this.renderer.addClass(this.el.nativeElement,'rp-button--tertiary');
            break;  
          case 'transparent':
            this.renderer.addClass(this.el.nativeElement,'rp-button--transparent');
            break;  
        default:
          break;
      }
    }
  }

  setTextColor(){
    if(this.textColor){
      switch (this.textColor) {
        case 'col-primary':
          this.renderer.addClass(this.el.nativeElement,'rp-button--col-primary');
          break;
          
        case 'col-secondary':
          this.renderer.addClass(this.el.nativeElement,'rp-button--col-secondary');
          break;
          

        default:
          break;
      }
    }
  }

  setSize(){
    if (this.size) {
      switch (this.size) {
        case 'jumbo':
          this.renderer.addClass(this.el.nativeElement,'rp-button--jumbo');
          this.renderer.addClass(this.el.nativeElement,'rp-button--icon');
          break;       
          case 'large':
            this.renderer.addClass(this.el.nativeElement,'rp-button--large');
            break;  
        case 'medium':
          this.renderer.addClass(this.el.nativeElement,'rp-button--medium');
          break;       
          case 'small':
            this.renderer.addClass(this.el.nativeElement,'rp-button--small');
            break;  
          case 'tiny':
            this.renderer.addClass(this.el.nativeElement,'rp-button--tiny');
          this.renderer.addClass(this.el.nativeElement,'rp-button--icon');

            break;  
        default:
          break;
      }
      
    }

  }



  

}
