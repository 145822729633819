import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProjectCardModel } from './ProjectCardModel';

@Component({
  selector: 'app-project-card',
  templateUrl: './project-card.component.html',
  styleUrls: ['./project-card.component.scss']
})
export class ProjectCardComponent implements OnInit {

  @Input() project?:ProjectCardModel;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  goToProject(slug){
    this.router.navigate(["project/"+ slug]);
  }

}
