import { NgModule,Input,
  EventEmitter,
  Output, 
  NO_ERRORS_SCHEMA} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from '../navbar/navbar.component';
import { Routes, RouterModule } from '@angular/router';
import { FooterComponent } from '../footerComponent/footer.component';
import { FeedbackComponent } from '../feedbackcomponent/feedback.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertCComponent } from '../alert-c/alert-c.component';
import { ConfirmCComponent } from '../confirm-c/confirm-c.component';
import { MultiselectDropdown } from '../multiselectdropdown/multiselectdropdown.component';
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';
import { HttpModule, JsonpModule } from '@angular/http';
// import { PopoverModule } from "ngx-popover";
import {
  CookieService
} from 'angular2-cookie/core';

import {
  Title
} from '@angular/platform-browser';

import { CarouselModule } from 'ngx-bootstrap/carousel';

import { ProjectService } from './../projectservice';
import { AutosizeModule } from 'ngx-autosize';
import {MatSelectModule} from '@angular/material/select';
import { MatFormFieldModule, MatInputModule } from '@angular/material';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatButtonModule} from '@angular/material/button';
import {MatRadioModule} from '@angular/material/radio';
import {MatDialogModule} from '@angular/material/dialog';
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    MultiselectDropdownModule,
    ReactiveFormsModule,
    CarouselModule.forRoot(),
    AutosizeModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule
  ],
  declarations: [
    NavbarComponent,
    FooterComponent,
    FeedbackComponent,
    AlertCComponent,
    ConfirmCComponent,
    MultiselectDropdown,
    
  ],
  exports: [
    NavbarComponent,
    FooterComponent,
    FeedbackComponent,
    AlertCComponent,
    ConfirmCComponent,
    MultiselectDropdown,
    CarouselModule,
    AutosizeModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatButtonModule,
    MatRadioModule,
    MatDialogModule
  ]
})
export class SharedModule { }
