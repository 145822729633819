import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProjectService } from '../projectservice';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-covid19',
  templateUrl: './covid19.component.html',
  styleUrls: ['./covid19.component.css']
})
export class Covid19Component implements OnInit {

  @ViewChild('pdfViewerAutoLoad', { static: true }) pdfViewerAutoLoad;
  // tslint:disable-next-line:max-line-length
  constructor(private route: ActivatedRoute, private service: ProjectService, public sanitizer: DomSanitizer, private meta: Meta, private title: Title, private http: HttpClient) {
    const length = this.meta.getTags('property="og:image"').length;
    for (let i = 0; i < length; i++) {
      this.meta.removeTag('property="og:image"');
    }
  }

  ngOnInit() {
    let url;
    if (this.route.snapshot.routeConfig.path === 'covid19') {
      this.title.setTitle('Coronavirus Workplace Mitigation Checklist' + ' | ' + this.service.project_name);
      this.meta.updateTag({ property: 'og:title', content: 'Coronavirus Workplace Mitigation Checklist' + ' | ' + this.service.project_name });
      this.meta.addTag({ property: 'og:image', content: '/assets/images/metaphotos/coronavirus.jpg' });
      this.meta.updateTag({ property: 'og:description', content: 'Are you a small business looking for ways to mitigate the Coronavirus (COVID-19) in your workplace?  Here is a checklist to get you started' });
      this.meta.updateTag({ name: 'description', content: 'Are you a small business looking for ways to mitigate the Coronavirus (COVID-19) in your workplace?  Here is a checklist to get you started' });
      this.meta.updateTag({ property: 'og:name', content: 'Coronavirus Workplace Mitigation Checklist' + ' | ' + this.service.project_name });
      this.meta.updateTag({ name: 'name', content: 'Coronavirus Workplace Mitigation Checklist' + ' | ' + this.service.project_name });
      url = 'assets/pdfs/covid19.pdf';
    } else {
      url = 'assets/pdfs/covid19DCjobs.pdf';
      this.title.setTitle('Hiring CEs During COVID-19: 4/23/2020' + ' | ' + this.service.project_name);
    }

    this.downloadFile(url).subscribe(
      (res) => {
        this.pdfViewerAutoLoad.pdfSrc = res; // pdfSrc can be Blob or Uint8Array
        this.pdfViewerAutoLoad.refresh(); // Ask pdf viewer to load/refresh pdf
      }
    );
  }

  downloadFile(url: string): any {
    return this.http.get(url, { responseType: 'blob' })
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }
}
