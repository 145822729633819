/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../navbar/navbar.component.ngfactory";
import * as i2 from "../navbar/navbar.component";
import * as i3 from "../new-repicture/auth/auth.service";
import * as i4 from "../projectservice";
import * as i5 from "@angular/router";
import * as i6 from "angular2-cookie/services/cookies.service";
import * as i7 from "@angular/platform-browser";
import * as i8 from "./userlist.component";
var styles_UserListComponent = [];
var RenderType_UserListComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_UserListComponent, data: {} });
export { RenderType_UserListComponent as RenderType_UserListComponent };
export function View_UserListComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "navbar", [], null, null, null, i1.View_NavbarComponent_0, i1.RenderType_NavbarComponent)), i0.ɵdid(1, 4833280, null, 0, i2.NavbarComponent, [i3.AuthService, i4.ProjectService, i5.Router, i6.CookieService, i0.ChangeDetectorRef, i7.Title, i0.PLATFORM_ID], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_UserListComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 1, "my-projectlist", [], null, null, null, View_UserListComponent_0, RenderType_UserListComponent)), i0.ɵdid(1, 114688, null, 0, i8.UserListComponent, [i4.ProjectService, i5.Router, i0.ViewContainerRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserListComponentNgFactory = i0.ɵccf("my-projectlist", i8.UserListComponent, View_UserListComponent_Host_0, {}, {}, []);
export { UserListComponentNgFactory as UserListComponentNgFactory };
