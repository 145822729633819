import { Component, OnInit, Input, OnChanges, AfterContentInit, AfterViewChecked, AfterViewInit } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import {
  ProjectService
}
  from './../../projectservice';

import * as _ from 'lodash';
import { windowToggle } from 'rxjs/operator/windowToggle';
declare var jQuery: any;

@Component({
  selector: 'app-commitmentscomponent',
  templateUrl: './commitmentscomponent.component.html',
  styleUrls: ['./commitmentscomponent.component.css'],
  animations: [
    trigger('fadeInOut', [
      transition('void => *', [
        style({ opacity: 0 }), //style only for transition transition (after transiton it removes)
        animate(700, style({ opacity: 1 })) // the new state of the transition(after transiton it removes)
      ]),
      transition('* => void', [
        animate(0, style({ opacity: 0 })) // the new state of the transition(after transiton it removes)
      ])
    ])
  ]
})
export class CommitmentscomponentComponent implements OnInit, OnChanges {

  @Input() mx: any;
  @Input() edit: any;
  @Input() fieldname: any;
  EditToggle: boolean = false;
  showTexbox: boolean = false;
  options
  commitments = [
    { 'title': 'We give back to our community', 'bool': false, 'isEditing': false, 'data': '' },
    { 'title': 'We invest in staff development', 'bool': false, 'isEditing': false, 'data': '' },
    { 'title': 'We invest in technology', 'bool': false, 'isEditing': false, 'data': '' },
    { 'title': 'We support diversity in engineering', 'bool': false, 'isEditing': false, 'data': '' }
  ]

  constructor(public service: ProjectService) { }

  ngOnInit() {
    try {
      if (!_.isEmpty(this.mx.data)) {
      console.log('moon',this.mx);
      this.commitments = this.mx.data;
        this.showTexbox = true;
      } else {
        this.showTexbox = false;
      }
    } catch (e) {
      this.showTexbox = false;
    }
  }

  ngOnChanges(): void {
    if(!this.edit) {
      this.EditToggle = false;
    }
  }

  showText(i, evt) {
    if(evt.srcElement.classList.contains('enhanced-pencil') && !this.service.isAdmin) {
			jQuery('#enhancedModal').modal('show');
			return;
		}
    this.showTexbox = true;
    this.EditToggle = true;
    this.commitments[i].isEditing = true
  }

  showText1(evt) {
    if(evt.srcElement.classList.contains('enhanced-pencil') && !this.service.isAdmin) {
			jQuery('#enhancedModal').modal('show');
			return;
		}
    this.showTexbox = true;
    this.EditToggle = true;
    for (let i = 0; i < this.commitments.length; i++) {
      this.commitments[i].isEditing = true;
    }
  }

  save(i) {
    let obj = {
      'org_commitments': this.commitments
    };
    this.service.doCompanyProfilePatch(sessionStorage.getItem("companyId"), obj).then(res=> {
      this.commitments[i].isEditing = false;
      console.log(i, this.commitments);
    });
  }

}
