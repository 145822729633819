/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./rp-chips.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./rp-chips.component";
var styles_RpChipsComponent = [i0.styles];
var RenderType_RpChipsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RpChipsComponent, data: {} });
export { RenderType_RpChipsComponent as RenderType_RpChipsComponent };
export function View_RpChipsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "chip-small"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(2, 0, null, null, 2, "button", [["class", "clear"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.remove() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, ":svg:svg", [["fill", "none"], ["height", "13"], ["viewBox", "0 0 13 13"], ["width", "13"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, ":svg:path", [["d", "M12.0581 2.37718C12.3918 2.04344 12.3776 1.48811 12.0263 1.13681C11.675 0.785503 11.1197 0.771264 10.7859 1.105L7.30589 4.58504C6.91537 4.97556 6.26554 4.9589 5.85446 4.54782L2.19126 0.884625C1.83996 0.533322 1.28463 0.519083 0.95089 0.852821C0.617152 1.18656 0.631392 1.74189 0.982695 2.0932L4.64589 5.75639C5.05697 6.16747 5.07363 6.8173 4.68311 7.20782L1.20307 10.6879C0.869332 11.0216 0.883572 11.5769 1.23487 11.9282C1.58618 12.2795 2.14151 12.2938 2.47525 11.96L5.95529 8.48C6.34581 8.08948 6.99564 8.10614 7.40672 8.51722L11.0699 12.1804C11.4212 12.5317 11.9766 12.546 12.3103 12.2122C12.644 11.8785 12.6298 11.3231 12.2785 10.9718L8.61529 7.30865C8.20421 6.89757 8.18755 6.24774 8.57807 5.85722L12.0581 2.37718Z"], ["fill", "currentColor"]], null, null, null, null, null))], null, null); }
export function View_RpChipsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-rp-chips", [], null, null, null, View_RpChipsComponent_0, RenderType_RpChipsComponent)), i1.ɵdid(1, 114688, null, 0, i2.RpChipsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RpChipsComponentNgFactory = i1.ɵccf("app-rp-chips", i2.RpChipsComponent, View_RpChipsComponent_Host_0, {}, { removed: "removed" }, ["*"]);
export { RpChipsComponentNgFactory as RpChipsComponentNgFactory };
