<div class="wrapperMain">
<navbar></navbar>
<div class="wrap2">
  <div class="wrapper1">
    <div class="row" style="margin: 0;">
      <div class="col-sm-6">
        <p class="txt-get">GET IN <span class="touch">TOUCH</span></p>
        <div class="col-sm-12 details">
          <div class="col-sm-offset-6 col-sm-6 font-div">
            <div class="form-group email-txt">
              <label for="usr" class="white">EMAIL:</label>
              <p class="white">info@repictureengineering.com</p>
            </div>
            <div class="form-group">
              <label for="usr" class="white">PHONE:</label>
              <p class="white">(202) 681-3030</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 font-div">
        <form #contactForm id="contactForm" (ngSubmit)="onSubmit(firstname.value, lastname.value, email.value, website.value, message.value)" >
          <div class="div-input">
            <div class="col-xs-6">
              <input type="text" #firstname placeholder="First Name*" required>
            </div>
            <div class="col-xs-6">
              <input type="text" #lastname placeholder="Last Name*" required>
            </div>
          </div>
          <div class="div-input1">
            <div class="col-xs-6">
              <input type="email" #email placeholder="Email*" required>
            </div>
            <div class="col-xs-6">
              <input type="text" #website placeholder="Website">
            </div>
          </div>
          <div class="form-group msg-pad">
            <textarea #message class="txt-msg" placeholder="Message*" rows="6" id="comment" required></textarea>
          </div>
          <br>
          <div class="checkbox chkbox">
            <label class="chck-txt">
            <input type="checkbox" (change)="check($event.target.checked)" checked>Yes, sign me up to receive a occasional news and updates</label>&nbsp;
            <button type="submit" class="btn btn-default btn-sbmit">SUBMIT</button>
          </div>
        </form>
      </div>
    </div>
    <div *ngIf="success" class="alert alert-success" style="width: 47%; float: right;">
      <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
      <strong>Thank You!</strong> We will get back to you shortly.
    </div>
    <div *ngIf="fail" class="alert alert-danger">
      Something went wrong!, Please try again later.
    </div>
  </div>
</div>
<footer></footer>
</div>
