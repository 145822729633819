import { Router, ActivatedRoute } from '@angular/router';
import {
  ProjectService
}
  from './../projectservice';

import { Title } from '@angular/platform-browser';
import { Component } from '@angular/core';
import { CookieService } from 'angular2-cookie/core';

@Component({
  selector: 'rest-auth',
  templateUrl: './restauth.html'
})

export class RestAuth {

  expired: boolean = false;
  loading: boolean = true;
  user_id: any;
  constructor(public service: ProjectService, private titleService: Title, private router: Router, private route: ActivatedRoute, private cookie: CookieService) {

  }

  ngOnInit() {
    this.titleService.setTitle("Authentication | Repicture App");
    window.history.forward();
    try {
      this.route.params.subscribe(params => {
        if (params['key']) {
          this.checkVerifyLink(params['key']);
        } else {
          this.expired = true;
          this.loading = false;
        }
      });
    } catch (e) { }
    // this.checkVerifyLink('MTUx:1fIvmv:ue6twT8dWeFfCjta4eM31e51QhA');
  }

  checkVerifyLink(id) {
    let obj = {
      key: id
    };

    this.service.checkAccountVerifyURL(obj)
      .subscribe(
        data => {
          setTimeout(() => {
            // console.log('sfs', data);
            this.user_id = data['user_id'];
            localStorage.setItem("peopleid", data['user_id']);
            localStorage.setItem("full_name_slug", data['full_name_slug']);
            localStorage.setItem("authtoken", data['token']);
            if (data['update'] == true) {
              //alert('Your email address is verified successfully!');
              this.service.sendAlert('Success', 'Your email address is verified successfully!', 1);
              this.router.navigate(['']);
            } else {
              // this.cookie.put('verifyingUser', data['full_name_slug']);
              this.router.navigate(['']);
            }
          }, 2000);
        },
        error => {
          if (error.status === 404) {
            this.expired = true;
            this.loading = false;
          }
        }
      );
  }
}