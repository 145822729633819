import { Router, ActivatedRoute } from '@angular/router';
import { Ng2AutoCompleteModule } from 'ng2-auto-complete';

import {
  Component,
  NgZone,
  EventEmitter,
  Input,
  Output,
  ChangeDetectorRef
} from '@angular/core';

import {
  ProjectService
}
  from './../projectservice';


import { IMultiSelectOption } from 'angular-2-dropdown-multiselect';

import * as _ from 'lodash';

import {
  CookieService
} from 'angular2-cookie/core';

import { Title } from '@angular/platform-browser';

@Component({
  selector: 'createperson',
  templateUrl: './createperson.html'
})

export class CreatePerson {
  loginStatus: boolean = false;
  loginNow: boolean = false;
  created: boolean = false;
  designation: any = '';
  otherRole: any;
  user: any = {};
  fname: any;
  lname: any;
  personType: any = 'Famous STEM professional';
  id: any;
  notify_email: any;
  notifySetting: any;
  loginObject: any = {};
  full_name_slug: any;
  lineofwork = ['Aerospace Engineer', 'Biomedical Engineer', 'Chemical Engineer', 'Civil Engineer', 'Computer Hardware Engineer', 'Electrical Engineer', 'Electronics Engineer', 'Environmental Engineer', 'Industrial Engineer', 'Materials Engineer', 'Mechanical Engineer', 'Petroleum Engineer', 'Software Engineer', 'Other Engineer', 'Architect', 'Biologist', 'Computer Scientist', 'Chemist', 'Environmental Scientist', 'Landscape Architect', 'Planner', 'Project Manager', 'Surveyor', 'Other'];

  constructor(public service: ProjectService, private cookie: CookieService, private cd: ChangeDetectorRef, private titleService: Title) { }

  ngOnInit() {
    this.titleService.setTitle("Create person | Repicture App");
    if (localStorage.getItem('authtoken')) {
      this.loginStatus = true;
    } else {
      this.loginStatus = false;
    }
  }

  // signup() {
  //   if (this.validate()) {
  //     this.service.doReg(this.user.first_name, this.user.last_name, this.user.email, this.user.password, this.user.password, this.user.email).then(res => {
  //       this.user.first_name = '';
  //       this.user.last_name = '';
  //       this.user.email = '';
  //       this.user.password = '';
  //       window.localStorage.setItem("first_name", this.user.first_name);
  //       window.localStorage.setItem("last_name", this.user.last_name);
  //       window.localStorage.setItem("username", this.user.email);
  //       //alert('An email has been sent to your email address with account activation instructions.');
  //       this.service.sendAlert('Success', "An email has been sent to your email address with account activation instructions.", 1);
  //       setTimeout(() => {
  //       }, 1000);
  //     });
  //   }
  // }

  validate() {
    if (this.user.first_name.trim() == "") {
      //alert("Please provide your first name!");
      this.service.sendAlert('Error', "Please provide your first name!", 0);
      return false;
    }

    if (this.user.email == "") {
      //alert("Please provide your email!");
      this.service.sendAlert('Error', "Please provide your email!", 0);
      return false;
    } else {
      if (this.validateEmail(this.user.email)) {
        //alert("You have entered an invalid email address!");
        this.service.sendAlert('Error', "You have entered an invalid email address!", 0);
        return false;
      }
    }

    var elem = document.getElementById("pwd");

    var pattern = elem.getAttribute("pattern");
    var re = new RegExp(pattern);
    if (!re.test(elem['value'])) {
      //alert('Please revise password to meet complexity requirements!');
      this.service.sendAlert('Error', "Please revise password to meet complexity requirements!", 0);
      return false;
    }

    return true;
  }

  createPerson(name) {
    if (!this.fname || !this.lname || !this.designation) {
      //alert("All fields must be complete.");
      this.service.sendAlert('Error', "All fields must be complete.", 0);
      return;
    }

    if (this.designation == 'Other' && !this.otherRole) {
     // alert("Please enter other role.");
     this.service.sendAlert('Error', "Please enter other role.", 0);
      return;
    }

    let obj = {};

    obj = {
      first_name: this.fname,
      last_name: this.lname,
      user_designation: this.designation == 'Other' ? this.otherRole : this.designation,
      is_public: true,
      is_claimable: this.personType == 'Famous STEM professional' ? true : false
    };

    this.service.createPublicProfiles(obj).then(result => {
      this.created = true;
      this.id = result['id'];
      this.full_name_slug = result['full_name_slug'];
      this.service.sendEvent('click', 'public user created', 'created user/id: ' + this.fname + ' ' + this.lname + '/' + this.id);
    });
  }

  notify() {
    // this.service.sendAlert('Info', "Notify email is temporarily disabled. It will be back soon.", 1);
    // return;
    if (!this.notify_email) {
     // alert('Please enter email.');
     this.service.sendAlert('Error', "Please enter email.", 0);
      return;
    }
    let obj = {
      to: this.notify_email,
      is_anonymous: this.notifySetting,
      full_name: this.fname + ' ' + this.lname,
      full_name_slug: this.full_name_slug,
      is_claimable: this.personType == 'Famous STEM professional' ? true : false
    }
    this.service.notifyUser(obj).then(result => {
      this.notify_email = '';
      //alert('Email has been sent.');
      this.service.sendAlert('Success', "Email has been sent.", 1);
      let body = {
        "created_by_name": localStorage.getItem('first_name') + ' ' + localStorage.getItem('last_name')
      };
      this.service.doUserProfilePatch(this.full_name_slug, body).then(result => {
      });
    });
  }

  login() {
    if (!this.loginObject.email.trim() || !this.loginObject.password.trim()) {
      //alert('Both fields are mandatory!');
      this.service.sendAlert('Error', "Both fields are mandatory!", 0);
      return;
    }
    if (this.validateEmail(this.loginObject.email)) {
      //alert("You have entered an invalid email address!");
      this.service.sendAlert('Error', "You have entered an invalid email address!", 0);
      return false;
    }
    this.service.doLogin(this.loginObject.email, this.loginObject.password).then(result => {
      if (result['token']) {
        this.loginStatus = true;
      } else {
        let varx = "";

        _.forEach(<any>result, function (value, key) {

          varx = varx + value[0];

        });
        //alert(varx);
        this.service.sendAlert('Error', varx, 0);
      }
    });
  }

  validateEmail(email) {
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    }
  }
}