import { CookieService } from "angular2-cookie/core";
import { Router, ActivatedRoute } from "@angular/router";
import { DomSanitizer, SafeHtml, Meta } from "@angular/platform-browser";
import { Http, RequestOptions, Headers } from "@angular/http";
import { Title } from "@angular/platform-browser";

import {
	Component,
	NgZone,
	EventEmitter,
	Input,
	Output,
	ElementRef,
	ViewChild,
	ViewEncapsulation
} from "@angular/core";

import { ProjectService } from "./../projectservice";

import { IMultiSelectOption } from "angular-2-dropdown-multiselect";

import * as _ from "lodash";
import * as $ from "jquery";
declare var jQuery: any;

@Component({
	selector: "companyprofile",
	templateUrl: "./companyprofile.html",
	encapsulation: ViewEncapsulation.Emulated
})
export class CompanyProfile {

	@ViewChild('file', { static: true }) fileInput: ElementRef;
	hiringURL: any;
	imagesrc: any;
	userInfo: any;
	doneloading: boolean = false;
	textboxedit: boolean = false;
	textboxedit1: boolean = false;
	textboxedit2: boolean = false;
	companyProjects: any = [];
	schoolUniversityKeywords = ['Engineering School or University', 'School', 'University'];
	noProjects: boolean = false;
	cile: boolean = false;
	audio: any;
	video: any;
	evt: any;
	org_role: any = [];
	videoURL: any;
	external_video: any;
	isUploading: boolean = false;
	websiteTextbox: boolean = false;
	nameTextbox: boolean = false;
	socialNetworkArr: any = [];
	socialTextbox: boolean = false;
	hasSocial: boolean = false;
	stem: any;
	other: boolean = false;
	showwilling: boolean = false;
	socialArr = ["Facebook", "LinkedIn", "Twitter", "Instagram", "YouTube", "Other"];
	EditToggle1: boolean = false;
	claimed: boolean = true;
	user: any;
	selectedRole: any;
	otherOwnershipValue: any;
	selectedOwnershipType: any = '';
	ownershipType: any;
	aboutUsCollection: any = {
		'org_name': {
			'btntitle': 'org_name',
			'fieldname': 'org_name',
			'edit': false,
			'data': '',
			'project_id': ''
		},
		'org_role': {
			'btntitle': 'org_role',
			'fieldname': 'org_role',
			'edit': false,
			'data': '',
			'project_id': ''
		},
		'org_website': {
			'btntitle': 'org_website',
			'fieldname': 'org_website',
			'edit': false,
			'data': '',
			'project_id': ''
		},
		'org_ownership_type': {
			'btntitle': 'org_ownership_type',
			'fieldname': 'org_ownership_type',
			'edit': false,
			'data': '',
			'project_id': ''
		},
		'org_social_network': {
			'btntitle': 'org_social_network',
			'fieldname': 'org_social_network',
			'edit': false,
			'data': '',
			'project_id': ''
		},
		'org_overview': {
			'btntitle': 'org_overview',
			'fieldname': 'org_overview',
			'edit': false,
			'data': '',
			'type': 'textarea',
			'project_id': '',
			'enhanced': false
		},
		'org_mission': {
			'btntitle': 'org_mission',
			'fieldname': 'org_mission',
			'edit': false,
			'data': '',
			'type': 'textarea',
			'project_id': '',
			'enhanced': true
		},
		'org_size': {
			'btntitle': 'org_size',
			'fieldname': 'org_size',
			'edit': false,
			'data': '',
			'type': 'select',
			'project_id': '',
			'enhanced': false
		},
		'org_year_founded': {
			'btntitle': 'org_year_founded',
			'fieldname': 'org_year_founded',
			'edit': false,
			'data': '',
			'type': 'textbox',
			'project_id': '',
			'enhanced': false
		},
		'org_offered_services': {
			'btntitle': 'org_offered_services',
			'fieldname': 'org_offered_services',
			'edit': false,
			'data': '',
			'type': 'textarea',
			'project_id': '',
			'enhanced': false
		},
		'org_certification': {
			'btntitle': 'org_certification',
			'fieldname': 'org_certification',
			'edit': false,
			'data': '',
			'type': 'multitextbox',
			'project_id': '',
			'enhanced': false
		},
		'org_about': {
			'btntitle': 'org_about',
			'fieldname': 'org_about',
			'edit': false,
			'data': '',
			'type': 'textarea',
			'project_id': '',
			'enhanced': false
		},
		'pe_inhouse': {
			'btntitle': 'pe_inhouse',
			'fieldname': 'pe_inhouse',
			'edit': false,
			'data': '',
			'type': 'radio',
			'project_id': '',
			'enhanced': false
		},
		'org_story': {
			'btntitle': 'org_story',
			'fieldname': 'org_story',
			'edit': false,
			'data': '',
			'type': 'textarea',
			'project_id': '',
			'enhanced': true
		},
		'org_audio_video': {
			'btntitle': 'org_audio_video',
			'fieldname': 'org_audio_video',
			'edit': false,
			'data': '',
			'type': 'org_audio_video',
			'project_id': '',
			'enhanced': true
		},
		'org_photos': {
			'btntitle': 'org_photos',
			'fieldname': 'org_photos',
			'edit': false,
			'data': '',
			'type': 'org_photos',
			'project_id': '',
			'enhanced': true
		},
		'org_awards_distinctions': {
			'btntitle': 'org_awards_distinctions',
			'fieldname': 'org_awards_distinctions',
			'edit': false,
			'data': '',
			'type': 'org_awards_distinctions',
			'project_id': '',
			'enhanced': true
		},
		'org_headquarters_location': {
			'btntitle': 'org_headquarters_location',
			'fieldname': 'org_headquarters_location',
			'edit': false,
			'data': '',
			'type': 'org_headquarters_location',
			'project_id': '',
			'enhanced': false
		},
		'org_our_commitments': {
			'btntitle': 'org_our_commitments',
			'fieldname': 'org_our_commitments',
			'edit': false,
			'data': '',
			'type': 'org_our_commitments',
			'project_id': '',
			'enhanced': true
		},
		'our_leadership_team': {
			'btntitle': 'our_leadership_team',
			'fieldname': 'our_leadership_team',
			'edit': false,
			'data': '',
			'type': 'our_leadership_team',
			'project_id': '',
			'enhanced': false
		}
	}

	private companyRoleOptions: IMultiSelectOption[] = [
		{ id: 'Engineering Firm', name: 'Engineering Firm', params: 'firm' },
		{ id: 'Architect Firm', name: 'Architect Firm', params: 'firm' },
		{ id: 'Architect & Engineering Firm', name: 'Architect & Engineering Firm', params: 'firm' },
		{ id: 'Environmental Firm', name: 'Environmental Firm', params: 'firm' },
		{ id: 'Geotechnical Firm', name: 'Geotechnical Firm', params: 'firm' },
		{ id: 'Planning Firm', name: 'Planning Firm', params: 'firm' },
		{ id: 'Construction Contractor', name: 'Construction Contractor', params: 'firm' },
		{ id: 'Engineering/Construction Supplier', name: 'Engineering/Construction Supplier', params: 'firm' },
		{ id: 'Developer/Property Owner', name: 'Developer/Property Owner', params: 'firm' },
		{ id: 'College or University', name: 'College or University', params: 'univ' },
		{ id: 'School or School District', name: 'School District', params: 'other' },
		{ id: 'Utility', name: 'Utility', params: 'other' },
		{ id: 'Nonprofit Organization', name: 'Nonprofit Organization', params: 'npo' },
		{ id: 'Professional Association', name: 'Professional Association', params: 'pa' },
		{ id: 'State or Local Government', name: 'State or Local Government', params: 'govt' },
		{ id: 'Federal Government', name: 'Federal Government', params: 'govt' },
		{ id: 'Military', name: 'Military', params: 'govt' },
		{ id: 'Other', name: 'Other', params: 'other' },
	  ];

	private companyHirings: IMultiSelectOption[] = [
		{ id: 'Interns', name: 'Interns' },
		{ id: 'Entry-level', name: 'Entry-level' },
		{ id: 'Experienced', name: 'Experienced' },
	];

	ownershipOptions = [
		'Publically owned',
		'Privately owned',
		'Employee owned',
		'Unknown',
		'Other',
	];


	private companyRoleOptions1: any = [
		'Civil Engineer', 'Architect', 'Construction Contractor', 'Developer', 'Electrical Engineer', 'Chemical Engineer', 'Environmental Consultant', 'Estimator',
		'Landscape Architect', 'Mechanical Engineer', 'Owner', 'Planner', 'Surveyor', 'Other'];

	companyRole: any;
	otherRoleDialog: boolean = false;
	roleTextbox: boolean = false;
	ownershipTextbox: boolean = false;
	hiringTextbox: boolean = false;
	isProfileEnhanced: boolean = false;
	otherRoleDialogValue: any;
	selectedHiring: any;
	companyid: any;
	org_logo_alt: any;
	isUniversity: boolean = false;
	universityData: any;
	univ_students = [];
	univ_alumni = [];
	UnivChatURL: any;
	univModal: any;
	list = [];
	org_types = [];
	constructor(public service: ProjectService, private cookie: CookieService, private http: Http, private router: Router, private route: ActivatedRoute, private title: Title, private _sanitizer: DomSanitizer, private meta: Meta) { }


	ngOnInit() {
		// this.title.setTitle("Company Profile");
		let length = this.meta.getTags('property="og:image"').length;
		for (let i = 0; i < length; i++) {
			this.meta.removeTag('property="og:image"');
		}
		if (localStorage.getItem('authtoken')) {
			this.service.getPeopleDetail(localStorage.getItem('full_name_slug'))
				.subscribe(res1 => {
					this.service.myOrg = res1['org_name_slug'];
					try {
						this.route.params.subscribe(params => {
							this.service.getOrgDetail(params['id']).subscribe(res => {
								this.meta.addTag({ property: 'og:image', content: res['org_image'] });
								this.meta.updateTag({ property: 'og:description', content: 'See what types of engineering projects ' + res["org_name"] + ' works on, what services they provide, and if they have job openings.' });
								this.meta.updateTag({ name: 'description', content: 'See what types of engineering projects ' + res["org_name"] + ' works on, what services they provide, and if they have job openings.' });
								sessionStorage.setItem("companyId", params['id']);
								this.companyid = res['id'];
								localStorage.setItem('org_id', this.companyid);

								if (res['is_representative'] && res['org_verified']) {
									this.claimed = true;
									if (this.service.myOrg == params['id']) {
										this.cile = true;
									} else {
										this.cile = false;
									}
								} else {
									this.claimed = false;
									this.cile = true;
								}

								if (res1['is_superuser']) {
									this.cile = true;
								}

								this.aboutUsCollection.org_role.data = res['org_role'];
								this.selectedRole = res['org_role'].slice(0);
								//for university demo change
								this.UnivChatURL = res['group_slug'];
								if (_.some(this.schoolUniversityKeywords, (el) => _.includes(this.selectedRole, el))) {
									this.isUniversity = true;
									this.service.getUniversityData(params['id']).then(res => {
										this.universityData = res;
										if (this.universityData.length > 0) {
											// this.UnivChatURL = this.universityData[0].group_slug;
											for (let i = 0; i < this.universityData.length; i++) {
												if (this.universityData[i].user_education_v1.length > 0) {
													if (this.universityData[i].user_education_v1[0].degrees.length > 0) {
														if (this.universityData[i].user_education_v1[0].degrees[0].indexOf('(') != -1) {
															this.universityData[i]['edu'] = this.universityData[i].user_education_v1[0].degrees[0].replace(/ *\([^)]*\) */g, "");
															if (this.universityData[i].user_education_v1[0].year) {
																this.universityData[i]['edu'] = this.universityData[i]['edu'] + ", '" + this.universityData[i].user_education_v1[0].year.toString().substr(-2);
															}
														} else {
															this.universityData[i]['edu'] = this.universityData[i].user_education_v1[0].degrees[0];
															if (this.universityData[i].user_education_v1[0].year) {
																this.universityData[i]['edu'] = this.universityData[i]['edu'] + ", '" + this.universityData[i].user_education_v1[0].year.toString().substr(-2);
															}
														}
													}
												}
												if (this.universityData[i].is_student) {
													this.univ_students.push(this.universityData[i]);
												} else {
													this.univ_alumni.push(this.universityData[i]);
												}
											}
										}
									});
								}
								//end demo change
								let title_role = '';
								if (this.selectedRole.length > 0) {
									this.org_logo_alt = this.selectedRole[0] + ' ' + res['org_name'] + ' logo';
									title_role = ' | ' + this.selectedRole[0].split(' ')[0];
								} else {
									this.org_logo_alt = res['org_name'] + ' logo';
								}
								this.title.setTitle(res['org_name'] + title_role + ' | ' + this.service.project_name);
								this.meta.updateTag({ property: 'og:name', content: res['org_name'] + title_role + ' | ' + this.service.project_name });
								this.meta.updateTag({ name: 'name', content: res['org_name'] + title_role + ' | ' + this.service.project_name });
								for (let i = 0; i < this.selectedRole.length; i++) {
									if (!_.includes(_.map(this.companyRoleOptions, 'id'), this.selectedRole[i])) {
										this.selectedRole.splice(i, 1);
									}
								}

								console.log('sf', this.selectedRole);

								this.aboutUsCollection.org_name.data = res['org_name'];
								// this.title.setTitle(this.aboutUsCollection.org_name.data + ' | ' + this.service.project_name);
								this.aboutUsCollection.org_website.data = res['org_website'];
								this.aboutUsCollection.org_ownership_type.data = res['org_ownership_type'];
								this.selectedOwnershipType = res['org_ownership_type'];
								if (!_.includes(this.ownershipOptions, this.selectedOwnershipType)) {
									this.otherOwnershipValue = this.selectedOwnershipType;
									this.selectedOwnershipType = 'Other';
								}
								this.selectedHiring = res['org_hiring'];
								this.hiringURL = res['org_hiring_url'];
								this.aboutUsCollection.org_social_network.data = res['org_social_network'];
								if (this.aboutUsCollection.org_social_network.data) {
									this.socialNetworkArr = this.createDemo(this.aboutUsCollection.org_social_network.data);
									setTimeout(() => {

										for (let i = 0; i < this.socialNetworkArr.length; i++) {
											if (_.includes(this.socialArr, this.socialNetworkArr[i].key)) {
												let index = this.socialArr.indexOf(this.socialNetworkArr[i].key);
												this.socialArr.splice(index, 1);
											} else {
												let index = this.socialArr.indexOf("Other");
												this.socialArr.splice(index, 1);
											}

										}

										if (this.socialArr.length == 1) {
											if (_.includes(this.socialArr, 'Other')) {
												this.other = true;
											}
										}
									}, 1000);
								}
								if (!_.isEmpty(res['org_social_network'])) {
									this.socialNetworkArr = this.createDemo(res['org_social_network']);
									this.hasSocial = true;
								}
								if (res['org_questions_willingness']) {
									this.stem = 'Yes';
								} else if (!res['org_questions_willingness']) {
									this.stem = 'No';
								}
								this.aboutUsCollection.org_overview.data = res['org_overview'];
								this.aboutUsCollection.org_mission.data = res['org_mission'];
								this.aboutUsCollection.org_size.data = res['org_size'];
								this.aboutUsCollection.org_year_founded.data = res['org_year_founded'];
								this.aboutUsCollection.org_certification.data = res['org_certification'];
								this.aboutUsCollection.org_about.data = res['org_about'];
								this.aboutUsCollection.pe_inhouse.data = res['pe_inhouse_v1'];
								this.aboutUsCollection.org_offered_services.data = res['org_offered_services'];
								this.aboutUsCollection.org_story.data = res['org_story'];
								this.aboutUsCollection.org_photos.data = _.isEmpty(res['org_media']) ? '' : res['org_media'];
								this.aboutUsCollection.org_our_commitments.data = res['org_commitments'];
								this.aboutUsCollection.our_leadership_team.data = res['leadership_roles'];
								this.aboutUsCollection.org_awards_distinctions.data = res['org_awards'];
								this.aboutUsCollection.org_headquarters_location.data = res['org_hq_location'];
								this.service.isProfileEnhanced = res['org_is_enhanced'];
								this.audio = res['org_about_audio'];
								this.video = res['org_about_video'];
								this.external_video = res['org_external_video'];// ? this.convertMedia(res['org_external_video']) : '';

								let org_audio_video_obj = {
									org_about_audio: this.audio,
									org_about_video: this.video,
									org_external_video: this.external_video
								};

								console.log('orgobj', org_audio_video_obj);

								_.forEach(org_audio_video_obj, (value, key) => {
									if (value) {
										this.aboutUsCollection.org_audio_video.data = org_audio_video_obj;
										return false;
									}
								});

								if (res['org_image']) {
									this.imagesrc = res['org_image'];
								} else {
									this.imagesrc = '/assets/images/no-image.jpg';
								}

								this.service.sendEvent('organization page visit', 'view/edit organization', 'visited organization id/name: ' + params['id'] + '/' + res['org_name']);
							});

							this.service.getCompanyProjects(params['id']).then(res => {
								this.companyProjects = res;
								if (_.isEmpty(this.companyProjects)) {
									this.noProjects = true;
								}
								if (localStorage.getItem('authtoken'))
									this.followers(params['id']);
							});

						});
					} catch (e) { }
				});
		} else {
			try {
				this.route.params.subscribe(params => {
					this.service.getOrgDetail(params['id']).subscribe(res => {
						this.meta.addTag({ property: 'og:image', content: res['org_image'] });
						this.meta.updateTag({ property: 'og:description', content: 'See what types of engineering projects ' + res["org_name"] + ' works on, what services they provide, and if they have job openings.' });
						this.meta.updateTag({ name: 'description', content: 'See what types of engineering projects ' + res["org_name"] + ' works on, what services they provide, and if they have job openings.' });
						sessionStorage.setItem("companyId", params['id']);
						this.companyid = res['id'];
						localStorage.setItem('org_id', this.companyid);
						if (res['is_representative'] && res['org_verified']) {
							this.claimed = true;
							if (this.service.myOrg == params['id']) {
								this.cile = true;
							} else {
								this.cile = false;
							}
						} else {
							this.claimed = false;
							this.cile = false;
						}

						this.aboutUsCollection.org_role.data = res['org_role'];
						this.selectedRole = res['org_role'].slice(0);
						//for university demo change
						this.UnivChatURL = res['group_slug'];
						if (_.some(this.schoolUniversityKeywords, (el) => _.includes(this.selectedRole, el))) {
							this.isUniversity = true;
							this.service.getUniversityData(params['id']).then(res => {
								this.universityData = res;
								if (this.universityData.length > 0) {
									// this.UnivChatURL = this.universityData[0].group_slug;
									for (let i = 0; i < this.universityData.length; i++) {
										if (this.universityData[i].user_education_v1.length > 0) {
											if (this.universityData[i].user_education_v1[0].degrees.length > 0) {
												if (this.universityData[i].user_education_v1[0].degrees[0].indexOf('(') != -1) {
													this.universityData[i]['edu'] = this.universityData[i].user_education_v1[0].degrees[0].replace(/ *\([^)]*\) */g, "");
													if (this.universityData[i].user_education_v1[0].year) {
														this.universityData[i]['edu'] = this.universityData[i]['edu'] + ", '" + this.universityData[i].user_education_v1[0].year.toString().substr(-2);
													}
												} else {
													this.universityData[i]['edu'] = this.universityData[i].user_education_v1[0].degrees[0];
													if (this.universityData[i].user_education_v1[0].year) {
														this.universityData[i]['edu'] = this.universityData[i]['edu'] + ", '" + this.universityData[i].user_education_v1[0].year.toString().substr(-2);
													}
												}
											}
										}
										if (this.universityData[i].is_student) {
											this.univ_students.push(this.universityData[i]);
										} else {
											this.univ_alumni.push(this.universityData[i]);
										}
									}
								}
							});
						}
						//end demo change
						let title_role = '';
						if (this.selectedRole.length > 0) {
							this.org_logo_alt = this.selectedRole[0] + ' ' + res['org_name'] + ' logo';
							title_role = ' | ' + this.selectedRole[0].split(' ')[0];
						} else {
							this.org_logo_alt = res['org_name'] + ' logo';
						}
						this.title.setTitle(res['org_name'] + title_role + ' | ' + this.service.project_name);
						this.meta.updateTag({ property: 'og:name', content: res['org_name'] + title_role + ' | ' + this.service.project_name });
						this.meta.updateTag({ name: 'name', content: res['org_name'] + title_role + ' | ' + this.service.project_name });

						for (let i = 0; i < this.selectedRole.length; i++) {
							if (!_.includes(_.map(this.companyRoleOptions, 'id'), this.selectedRole[i])) {
								this.selectedRole.splice(i, 1);
							}
						}

						this.aboutUsCollection.org_name.data = res['org_name'];
						// this.title.setTitle(this.aboutUsCollection.org_name.data + ' | ' + this.service.project_name);
						this.aboutUsCollection.org_website.data = res['org_website'];
						this.aboutUsCollection.org_ownership_type.data = res['org_ownership_type'];
						this.selectedOwnershipType = res['org_ownership_type'];
						if (!_.includes(this.ownershipOptions, this.selectedOwnershipType)) {
							this.otherOwnershipValue = this.selectedOwnershipType;
							this.selectedOwnershipType = 'Other';
						}
						this.selectedHiring = res['org_hiring'];
						this.hiringURL = res['org_hiring_url'];
						this.aboutUsCollection.org_social_network.data = res['org_social_network'];
						if (this.aboutUsCollection.org_social_network.data) {
							this.socialNetworkArr = this.createDemo(this.aboutUsCollection.org_social_network.data);
							setTimeout(() => {

								for (let i = 0; i < this.socialNetworkArr.length; i++) {
									if (_.includes(this.socialArr, this.socialNetworkArr[i].key)) {
										let index = this.socialArr.indexOf(this.socialNetworkArr[i].key);
										this.socialArr.splice(index, 1);
									} else {
										let index = this.socialArr.indexOf("Other");
										this.socialArr.splice(index, 1);
									}

								}

								if (this.socialArr.length == 1) {
									if (_.includes(this.socialArr, 'Other')) {
										this.other = true;
									}
								}
							}, 1000);
						}
						if (!_.isEmpty(res['org_social_network'])) {
							this.socialNetworkArr = this.createDemo(res['org_social_network']);
							this.hasSocial = true;
						}
						if (res['org_questions_willingness']) {
							this.stem = 'Yes';
						} else if (!res['org_questions_willingness']) {
							this.stem = 'No';
						}
						this.aboutUsCollection.org_overview.data = res['org_overview'];
						this.aboutUsCollection.org_mission.data = res['org_mission'];
						this.aboutUsCollection.org_size.data = res['org_size'];
						this.aboutUsCollection.org_year_founded.data = res['org_year_founded'];
						this.aboutUsCollection.org_certification.data = res['org_certification'];
						this.aboutUsCollection.org_about.data = res['org_about'];
						this.aboutUsCollection.pe_inhouse.data = res['pe_inhouse_v1'];
						this.aboutUsCollection.org_offered_services.data = res['org_offered_services'];
						this.aboutUsCollection.org_story.data = res['org_story'];
						this.aboutUsCollection.org_photos.data = _.isEmpty(res['org_media']) ? '' : res['org_media'];
						this.aboutUsCollection.org_our_commitments.data = res['org_commitments'];
						this.aboutUsCollection.our_leadership_team.data = res['leadership_roles'];
						this.aboutUsCollection.org_awards_distinctions.data = res['org_awards'];
						this.aboutUsCollection.org_headquarters_location.data = res['org_hq_location'];
						this.service.isProfileEnhanced = res['org_is_enhanced'];
						this.audio = res['org_about_audio'];
						this.video = res['org_about_video'];
						this.external_video = res['org_external_video'];// ? this.convertMedia(res['org_external_video']) : '';

						let org_audio_video_obj = {
							org_about_audio: this.audio,
							org_about_video: this.video,
							org_external_video: this.external_video
						};

						console.log('orgobj', org_audio_video_obj);

						_.forEach(org_audio_video_obj, (value, key) => {
							if (value) {
								this.aboutUsCollection.org_audio_video.data = org_audio_video_obj;
								return false;
							}
						});

						if (res['org_image']) {
							this.imagesrc = res['org_image'];
						} else {
							this.imagesrc = '/assets/images/no-image.jpg';
						}

						this.service.sendEvent('organization page visit', 'view/edit organization', 'visited organization id/name: ' + params['id'] + '/' + res['org_name']);
					});

					this.service.getCompanyProjects(params['id']).then(res => {
						this.companyProjects = res;
						if (_.isEmpty(this.companyProjects)) {
							this.noProjects = true;
						}
						if (localStorage.getItem('authtoken'))
							this.followers(params['id']);
					});

				});
			} catch (e) { }
		}
	}

	doneModelYesClicked() {
		this.textboxedit2 = !this.textboxedit2;
	}

	gotoUser(slug) {
		jQuery('#univ_modal').modal('hide');
		setTimeout(() => {
			this.router.navigate(['/userprofile', slug]);
		}, 800);
	}

	openHiringURL() {
		if (this.hiringURL) {
			this.service.sendEvent('click', "We're hiring button", 'hiring URL: ' + this.hiringURL);
			let win = window.open(this.hiringURL, '_blank');
			win.focus();
		}
	}

	showOther: boolean = false;
	otherReason: any;
	reason1: any;

	upgrade() {
		jQuery('#enhancedModal').modal('hide');
		setTimeout(() => {
			this.service.isAuthenticated = true;
			this.router.navigate(['/payment']);
		}, 1000);
	}

	radioClick(reason) {
		if (reason == '7') {
			this.showOther = true;
			this.reason1 = reason;
		} else {
			this.showOther = false;
			this.reason1 = reason
		}
	}

	submitReport() {
		if (!localStorage.getItem('authtoken')) {
			this.service.sendAlert('Error', "Please login to continue!", 0);
			return;
		}
		if (this.showOther) {
			if (!this.otherReason) {
				//alert('Please enter your reason!');
				this.service.sendAlert('Error', 'Please enter your reason!', 0);
				return;
			}
		} else {
			if (!this.reason1) {
				//alert('Please select one of your reason!');
				this.service.sendAlert('Error', 'Please select one of your reason!', 0);
				return;
			}
		}
		let obj = {
			organization: this.companyid,
			reason: this.reason1
		}
		if (this.showOther) {
			obj['other_reason'] = this.otherReason;
		}
		this.service.flagOrg(obj).then(res => {
			// this.flaggedAlready = true;
			jQuery('#flagModal2').modal('hide');
			let x2 = document.getElementById("snackbar3");
			x2.className = "show";
			setTimeout(function () { x2.className = x2.className.replace("show", ""); }, 3000);
		});
	}

	followers(company_id) {
		this.getFollowers(company_id);
		this.service.getPeopleDetail(localStorage.getItem("full_name_slug")).subscribe(res => {
			if (!_.isEmpty(res['org_followed'])) {
				if (_.includes(res['org_followed'], company_id)) {
					this.followed = true;
				} else {
					this.followed = false;
				}
			}
		});
	}

	showText(val) {
		// if (!this.claimed && !this.service.isAdmin) {
		// 	this.service.sendAlert('', 'You need to claim this profile in order to edit this field.', 0);
		// 	return;
		// }

		this[val] = true;
		if (val == 'hasSocial') {
			this.EditToggle1 = true;
		} else if (val == 'roleTextbox') {
			this.checkOther();
		}
	}

	setRoles() {
		if (this.cookie.get('abc')) {
			this.aboutUsCollection.org_role.data = this.cookie.get('abc').split(',');
			console.log(this.aboutUsCollection.org_role.data, 'sf');
			this.cookie.remove('abc');
		}
	}

	checkOther() {
		if (this.selectedRole.length == 0) {
			this.selectedRole = [];
			return;
		}
		this.cookie.put('abc', this.aboutUsCollection.org_role.data.toString());

		for (let i = 0; i < this.selectedRole.length; i++) {
			if (!_.includes(_.map(this.companyRoleOptions, 'id'), this.selectedRole[i])) {
				this.otherRoleDialogValue = this.selectedRole[i];
				this.selectedRole.splice(i, 1);
				this.selectedRole.push('Other');
				this.otherRoleDialog = true;
			}
		}
	}

	role() {
		if (_.includes(this.companyRole, 'Other')) {
			let index = this.companyRole.indexOf('Other');
			this.companyRole.splice(index, 1);
			if (this.otherRoleDialog) {
				this.companyRole.push(this.otherRoleDialogValue);
			}
		}

		for (let j = 0; j < this.companyRole.length; j++) {
			for (let i = 0; i < this.companyRoleOptions.length; i++) {
				if (this.companyRole[j] === this.companyRoleOptions[i].id) {
					if (!_.includes(this.org_types, this.companyRoleOptions[i].params)) {
						this.org_types.push(this.companyRoleOptions[i].params);
					}
				}
			}
		}
		
		let body = {
			org_role: this.companyRole,
			org_types: this.org_types
		}
		this.service.doCompanyProfilePatch(sessionStorage.getItem("companyId"), body).then(res => {
			this.aboutUsCollection.org_role.data = this.companyRole;
			this.cookie.put('abc', this.aboutUsCollection.org_role.data.toString());
			this.roleTextbox = false;
		});
	}

	saveHirings() {
		let body = {
			'org_hiring': this.selectedHiring,
			'org_hiring_url': this.hiringURL ? this.hiringURL : null
		}
		this.service.doCompanyProfilePatch(sessionStorage.getItem("companyId"), body).then(res => {
			this.hiringTextbox = false;
		});
	}

	upgradeForFree() {
		let body = {
			'org_is_enhanced': true
		}
		this.service.doCompanyProfilePatch(sessionStorage.getItem("companyId"), body).then(res => {
			this.service.isProfileEnhanced = true;
		});
	}

	ownership() {
		// if (_.includes(this.companyRole, 'Other')) {
		// 	let index = this.companyRole.indexOf('Other');
		// 	this.companyRole.splice(index, 1);
		// 	if (this.otherRoleDialog) {
		// 		this.companyRole.push(this.otherRoleDialogValue);
		// 	}
		// }
		if (this.selectedOwnershipType == 'Other') {
			this.selectedOwnershipType = this.otherOwnershipValue;
		}
		let body = {
			'org_ownership_type': this.selectedOwnershipType
		}
		this.service.doCompanyProfilePatch(sessionStorage.getItem("companyId"), body).then(res => {
			this.aboutUsCollection.org_ownership_type.data = this.selectedOwnershipType;
			this.ownershipTextbox = false;
		});
	}

	onChangedd($event) {
		this.companyRole = $event;
		if (_.includes($event, 'Other')) {
			this.otherRoleDialog = true;
		} else {
			this.otherRoleDialog = false;
		}
	}

	onChangedd1($event) {
		this.selectedHiring = $event;
	}

	checkSocial() {
		if (!_.isEmpty(this.socialNetworkArr)) {
			this.hasSocial = true;
		} else {
			this.hasSocial = false;
		}
	}

	changeWillingness($event) {
		let body = {
			"org_questions_willingness": $event.target.value
		};
		let companyId = sessionStorage.getItem("companyId");
		this.service.doCompanyProfilePatch(companyId, body).then(result => {
			if ($event.target.value == "true") {
				this.stem = 'Yes';
			} else {
				this.stem = 'No';
			}
			this.showwilling = false;
		});

	}

	socialChange(e) {
		if (e.target.value == 'Other') {
			this.other = true;
		} else {
			this.other = false;
		}
	}

	updateSocialNetwork(type, url) {
		if (!url.trim()) {
			return;
		}
		let innerObj = {};
		innerObj[type] = url;

		let body = {
			org_social_network: innerObj
		};

		let companyId = sessionStorage.getItem("companyId");
		this.service.doCompanyProfilePatch(companyId, body).then(result => {
			try {
				document.getElementsByClassName('cmw')[0]['style'].display = 'inline-block';
			} catch (e) { }

			if (this.aboutUsCollection.org_social_network.data) {
				this.aboutUsCollection.org_social_network.data[type] = url;
				this.socialNetworkArr = this.createDemo(this.aboutUsCollection.org_social_network.data);
			} else {
				let obj = {};
				obj[type] = url;
				this.socialNetworkArr = this.createDemo(obj);
				this.aboutUsCollection.org_social_network.data = obj;
			}

			let index = this.socialArr.indexOf(type);
			this.socialArr.splice(index, 1);

			if (this.other) {
				this.other = false;
			}

			if (this.socialArr.length == 1) {
				if (_.includes(this.socialArr, 'Other')) {
					this.other = true;
				}
			}
			this.aboutUsCollection.org_social_network.data[type] = url;
			this.socialNetworkArr = this.createDemo(this.aboutUsCollection.org_social_network.data);
			this.socialTextbox = false;
			this.EditToggle1 = false;
			this.hasSocial = true;
		});


	}

	deleteSocial(name, i) {
		let innerObj = {};
		innerObj['remove'] = name;

		let body = {
			"org_social_network": innerObj
		};

		let company_id = sessionStorage.getItem("companyId");
		this.service.doCompanyProfilePatch(company_id, body).then(result => {
			this.socialNetworkArr.splice(i, 1);
			this.socialArr.push(name);
			if (this.socialArr.length == 1) {
				if (_.includes(this.socialArr, 'Other')) {
					this.other = true;
				}
			}
		});
	}

	deleteMedia(type) {
		if (confirm('Do you want to delete this file?')) {
			let body = {
				[type]: null
			};
			let company_id = sessionStorage.getItem("companyId");
			this.service.doCompanyProfilePatch(company_id, body).then(result => {
				if (type == 'org_about_video') {
					this.video = null;
				} else if (type == 'org_external_video') {
					this.external_video = null;
				} else {
					this.audio = null;
				}
			});
		}
	}

	visitWebsite(data) {
		if (!data) {
			return;
		}

		window.open(data);
	}

	addWebsite(value) {
		let body = {
			'org_website': value
		};

		let companyId = sessionStorage.getItem("companyId");
		this.service.doCompanyProfilePatch(companyId, body).then(result => {
			this.aboutUsCollection.org_website.data = value;
			this.websiteTextbox = false;
		});
	}

	addName(value) {
		let body = {
			'org_name': value
		};

		let companyId = sessionStorage.getItem("companyId");
		this.service.doCompanyProfilePatch(companyId, body).then(result => {
			this.aboutUsCollection.org_name.data = value;
			this.nameTextbox = false;
			this.service.companies = [];

		});
	}

	checkEmptyProjects(i) {
		this.companyProjects.splice(i, 1);
		if (this.companyProjects.length === 0) {
			this.noProjects = true;
		}
	}
	mediaSelected1($event) {
		this.evt = $event;
	}

	mediaSelected($event) {
		let btnUpload = document.getElementById('btn');
		btnUpload.classList.remove("glyphicon-upload");
		btnUpload.classList.add("glyphicon-refresh");
		btnUpload.classList.add("glyphicon-refresh-animate");
		let elem = $event.target.files[0];
		let type = $event.target.files[0].type.split('/')[0];
		this.service.setUserAudioVideoForCompany(sessionStorage.getItem("companyId"), elem).then(res => {
			//location.reload();
			if (type == 'audio') {
				this.audio = res['org_about_audio'];
			} else if (type == 'video') {
				this.video = res['org_about_video'];
			}
			this.evt = '';
			btnUpload.classList.add("glyphicon-upload");
			btnUpload.classList.remove("glyphicon-refresh");
			btnUpload.classList.remove("glyphicon-refresh-animate");
		});
	}

	abc: boolean = false;

	getId(url) {
		var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
		var match = url.match(regExp);

		if (match && match[2].length == 11) {
			return match[2];
		} else {
			return 'error';
		}
	}

	convertMedia(html) {
		var pattern1 = /(?:http?s?:\/\/)?(?:www\.)?(?:vimeo\.com)\/?(.+)/g;
		var pattern2 = /(?:http?s?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?(.+)/g;
		// var pattern3 = /([-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?(?:jpg|jpeg|gif|png))/gi;

		if (pattern1.test(html)) {
			var replacement = '<iframe style="border: none; margin-top: 5px; width: 100%; height: 100%;" src="//player.vimeo.com/video/$1" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>';
			var html = html.replace(pattern1, replacement);
		}


		if (pattern2.test(html)) {
			var id = this.getId(html);
			var replacement = '<iframe style="border: none; margin-top: 5px; width: 100%; height: 100%;" src="https://www.youtube.com/embed/' + id + '" allowfullscreen></iframe>';
			var html = html.replace(pattern2, replacement);
		}

		if (!pattern1.test(html) && !pattern2.test(html)) {
			//alert('Please enter only youtube or vimeo video URL');
			this.service.sendAlert('Error', 'Please enter only youtube or vimeo video URL', 0);
			return;
		}

		// if(pattern3.test(html)){
		//     var replacement = '<a href="$1" target="_blank"><img class="sml" src="$1" /></a><br />';
		//     var html = html.replace(pattern3, replacement);
		// }          
		return this._sanitizer.bypassSecurityTrustHtml(html);
	}

	externalMediaSelected() {
		let btnUpload = document.getElementById('btn1');
		btnUpload.classList.remove("glyphicon-upload");
		btnUpload.classList.add("glyphicon-refresh");
		btnUpload.classList.add("glyphicon-refresh-animate");
		let obj;
		obj = {
			'org_external_video': this.videoURL
		};
		this.service.setOrgExternalVideo(sessionStorage.getItem("companyId"), obj).then(res => {
			this.external_video = this.convertMedia(this.videoURL);
			this.videoURL = '';
			this.abc = false;
			btnUpload.classList.add("glyphicon-upload");
			btnUpload.classList.remove("glyphicon-refresh");
			btnUpload.classList.remove("glyphicon-refresh-animate");
		});
	}

	isEmpty(obj) {
		for (var prop in obj) {
			if (obj.hasOwnProperty(prop))
				return false;
		}

		return JSON.stringify(obj) === JSON.stringify({});
	}

	mouseOver(e) {
		$('.text').css({ top: e.clientY - 200 + "px" });
	}

	goToProject(proj_id) {
		let win = window.open();
	}

	checkHiring(evt) {
		if (evt.srcElement.classList.contains('enhanced-pencil') && !this.service.isAdmin) {
			if (!this.claimed) {
				this.service.sendAlert('', 'Claim this profile and upgrade to an Enhanced Profile to unlock this field.', 0);
			} else {
				jQuery('#enhancedModal').modal('show');
			}

			return;
		}
		this.hiringTextbox = !this.hiringTextbox;
	}

	checkWilling(evt) {
		if (evt.srcElement.classList.contains('enhanced-pencil') && !this.service.isAdmin) {
			if (!this.claimed) {
				this.service.sendAlert('', 'Claim this profile and upgrade to an Enhanced Profile to unlock this field.', 0);
			} else {
				jQuery('#enhancedModal').modal('show');
			}
			return;
		}
		this.showwilling = true
	}

	askClicked() {
		this.service.sendAlert('', 'This feature is available only for students enrolled in the RePicture Educational Program. Email info@RePictureEngneering.com if you would like more information about this program.', 0);
	}

	chooseFile(evt) {
		if (evt.srcElement.classList.contains('enhanced-pencil') && !this.service.isAdmin) {
			if (!this.claimed) {
				this.service.sendAlert('', 'Claim this profile and upgrade to an Enhanced Profile to unlock this field.', 0);
			} else {
				jQuery('#enhancedModal').modal('show');
			}
			return;
		}
		// if (!this.claimed) {
		// 	//alert('You must claim this profile before uploading a logo.');
		// 	this.service.sendAlert('Error', 'You must claim this profile before uploading a logo.', 0);
		// 	return;
		// }
		this.fileInput.nativeElement.click();
	}

	fileChanged(event) {
		let file = event.target.files[0]
		if (file) {
			var reader = new FileReader();
			reader.onload = this.imageIsLoaded;
			this.service.addOrgProfilePic(sessionStorage.getItem("companyId"), file).then(res => {
				this.service.companies = [];
				reader.readAsDataURL(file);
			})
		}
	}
	imageIsLoaded(e) {
		this.imagesrc = e.target.result;
		document.getElementById('img-profile')['src'] = e.target.result;
	}

	checkLogin(param) {
		if (param == "first")
			this.textboxedit = !this.textboxedit;

		if (param == "second")
			this.textboxedit1 = !this.textboxedit1;

		if (param == "third")
			this.textboxedit2 = !this.textboxedit2;

	}

	changeInLogin1($event) {
		if ($event) {
			this.cile = true;
		} else {
			this.cile = false;
		}
	}
	createDemo(mydemo: any): Array<demo> {
		const tempdemo: Array<demo> = [];
		for (const key of Object.keys(mydemo)) {
			tempdemo.push(
				{ key: key, value: mydemo[key] }
			);
		}

		return tempdemo;
	}

	followed: boolean = false;
	count: any = 0;

	follow() {
		// let companyId = sessionStorage.getItem("companyId");
		this.service.followOrg(this.companyid).then(res => {
			this.followed = true;
			this.count++;
		});
	}

	getFollowers(company_id) {
		this.service.getFollowersOfOrg(company_id).then(res => {
			this.count = res['count'];
		});
	}

	unfollow() {
		// let companyId = sessionStorage.getItem("companyId");
		this.service.unfollowOrg(this.companyid).then(res => {
			this.followed = false;
			this.count--;
		});
	}

	claim() {
		if (this.service.myOrg) {
			//alert('You already have an organization associated with you.');
			this.service.sendAlert('Error', 'You already have an organization associated with you.', 0);
			return;
		}
		this.cookie.put('claimCompanyId', this.companyid);
		this.cookie.put('claimCompanySlug', sessionStorage.getItem("companyId"));
		this.cookie.put('claimCompanyName', this.aboutUsCollection.org_name.data);
		this.cookie.put('claimCompanyRole', this.aboutUsCollection.org_role.data.toString());

		this.service.claimableCompanyObject.claimCompanyId = this.companyid;
		this.service.claimableCompanyObject.claimCompanyName = this.aboutUsCollection.org_name.data;
		this.service.claimableCompanyObject.claimCompanySlug = sessionStorage.getItem("companyId");
		this.service.claimableCompanyObject.claimCompanyRole = this.aboutUsCollection.org_role.data.toString();
		console.log(this.service.claimableCompanyObject);
		this.router.navigate(['/claimcompany']);
	}
}

interface demo {
	key: string;
	value: string;
}
