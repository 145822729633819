<div *ngIf="showTexbox" [@fadeInOut]="showTexbox">
    <div class="fadeinout" [@fadeInOut]="!EditToggle" *ngIf="!EditToggle">
        <div *ngFor="let item of commitments; let i = index;">
            <p *ngIf="item.bool">
                <i style="font-size: 16px; padding-left: 0; padding-right: 10px;" class="glyphicon glyphicon-ok"></i>
                <span class="title"> {{ item.title }} </span>
                <i *ngIf="edit" class="glyphicon glyphicon-pencil" [ngClass]="{'enhanced-pencil': !service.isProfileEnhanced}" (click)="showText(i, $event)"></i>
            </p>
            <div style="margin-bottom: 20px;" *ngIf="item.data" [innerHTML]="service.addLineBreaks(item.data)">
                <!-- {{ item.data }} -->
            </div>
        </div>
    </div>
    <div [@fadeInOut]="EditToggle" *ngIf="EditToggle">
        <div *ngFor="let item of commitments; let index = index;">
            <p>
                <span>{{ item.title }}</span>
                <span style="float: right;">
          <label class="radio-inline">
            <input type="radio" name="radioGroup{{index}}" [value]="true" [(ngModel)]="item.bool">Yes</label>
          <label class="radio-inline">
            <input type="radio" name="radioGroup{{index}}" [value]="false" [(ngModel)]="item.bool">No</label>
        </span>
            </p>
            <p *ngIf="item.bool && item.isEditing">
                <textarea placeholder="Enter information" [(ngModel)]="item.data"></textarea>
                <i class="glyphicon glyphicon-check" (click)="save(index)"></i>
            </p>
            <div *ngIf="item.bool && !item.isEditing">
                <p [innerHTML]="service.addLineBreaks(item.data) | linky:{stripPrefix: false}">
                    <!-- {{ item.data }} -->
                    <i class="glyphicon glyphicon-pencil" [ngClass]="{'enhanced-pencil': !service.isProfileEnhanced}" (click)="showText(index, $event)"></i>
                </p>

            </div>
        </div>
    </div>
</div>
<div *ngIf="!showTexbox" [@fadeInOut]="!showTexbox">
    <p style="font-style: italic;">Edit to add more information
        <i *ngIf="edit" class="glyphicon glyphicon-pencil" [ngClass]="{'enhanced-pencil': !service.isProfileEnhanced}" (click)="showText1($event)"></i>
    </p>
</div>
<style>
    .enhanced-pencil {
        background: skyblue;
        padding: 6px;
        border-radius: 50%;
        color: black;
        margin-left: 5px;
    }
    
    .dsc-glyp {
        vertical-align: top !important;
    }
    
    i {
        vertical-align: top;
        padding-left: 5px;
    }
    
    .regular {
        font-family: open-regular;
    }
    
    .water-res {
        font-family: OpenSans-Semibold;
        color: #929292;
        margin: 0 0 0px;
        font-size: 13px;
    }
    
    .exp-desc {
        color: #a2a8a8;
        font-size: 13px;
    }
    
    .para-title {
        padding-left: 30px;
        font-size: 13px;
        color: grey;
    }
    
    textarea {
        max-width: 500px;
        width: 90%;
        height: 100px;
        -moz-border-bottom-colors: none;
        -moz-border-left-colors: none;
        -moz-border-right-colors: none;
        -moz-border-top-colors: none;
        background: none repeat scroll 0 0 rgba(0, 0, 0, 0.07);
        border-color: -moz-use-text-color #FFFFFF #FFFFFF -moz-use-text-color;
        border-image: none;
        border-radius: 6px 6px 6px 6px;
        border-style: none solid solid none;
        border-width: medium 1px 1px medium;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12) inset;
        color: #555555;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 1em;
        line-height: 1.4em;
        padding: 5px 8px;
        transition: background-color 0.2s ease 0s;
    }
    
    textarea:focus {
        background: none repeat scroll 0 0 #FFFFFF;
        outline-width: 0;
    }
    
    @font-face {
        font-family: open-regular;
        src: url('/assets/fonts/new/opensans-regular-webfont.eot?#iefix') format('embedded-opentype'), /* IE */
        url('/assets/fonts/new/opensans-regular-webfont.ttf') format("truetype");
        /* non-IE */
    }
    
    @font-face {
        font-family: OpenSans-Semibold;
        src: url('/assets/fonts/new/opensans-semibold-webfont.eot?#iefix') format('embedded-opentype'), /* IE */
        url('/assets/fonts/new/opensans-semibold-webfont.ttf') format("truetype");
        /* non-IE */
    }
    
    @font-face {
        font-family: OpenSans-Bold;
        src: url('/assets/fonts/new/opensans-bold-webfont.eot?#iefix') format('embedded-opentype'), /* IE */
        url('/assets/fonts/new/opensans-bold-webfont.ttf') format("truetype");
        /* non-IE */
    }
</style>