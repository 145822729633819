	<navbar></navbar>

<!-- <md-card *ngFor="let d of listcolletion">
   <md-card-title-group>
      <img md-card-sm-image src={{d.profileImage}}>
      <md-card-title>{{d.username}}</md-card-title>
      <md-card-subtitle>{{d.desc}}</md-card-subtitle>
   </md-card-title-group>
   <md-card-actions>
        <button md-button>LIKE</button>
        <button md-button (click)="GoToDetailPage(d.id)">VIEW</button>
   </md-card-actions>
</md-card> -->