<div *ngIf="doneloading"
    style="overflow-x: hidden;background-color: #e9ebee;height: 100%;-webkit-overflow-scrolling: touch; overflow-y: scroll;">
    <navbar (changeInLogin)="changeInLogin1($event)"></navbar>
    <div class="container-fluid" *ngIf="!privacy">
        <!-- <div class="row row-grid" style="background: linear-gradient(to right, rgb(217, 244, 247) , rgb(155, 214, 232)); padding: 15px 0; min-height:232px;">
            <input type="file" (change)="fileChange($event)" id="input" #input style="visibility: hidden;" />
            <div class="col-sm-3 col-xs-12">
                <img src="{{ imagesrc }}" class="img-circle img-grid" alt="{{user_pic_alt}}" width="220" height="220">
                <span *ngIf="textboxedit" class="editPic img-circle" (click)="choosePic()">
          <i style="margin-top: 7px;" class="glyphicon glyphicon-pencil"></i>
        </span>
            </div>
            <div *ngIf="!is_public" class="col-sm-6 col-xs-12 usr-name" style="padding: 0;">
                <div style="display: flex;">
                    <div class="col-md-6">
                        <div>
                            <textboxprofile [mx]="ObjCollection.name" [edit]="textboxedit"></textboxprofile>
                        </div>
                        <div *ngIf="!sameUser && (service.isAdmin || ObjCollection.user_designation.data)">
                            <textboxprofile [mx]="ObjCollection.user_designation" [edit]="textboxedit" (careerChanged)="updateCareer($event)"></textboxprofile>
                        </div>
                        <div *ngIf="sameUser">
                            <textboxprofile [mx]="ObjCollection.user_designation" [edit]="textboxedit" (careerChanged)="updateCareer($event)"></textboxprofile>
                        </div>
                        <div class="" *ngIf="!sameUser && (service.isAdmin || ObjCollection.user_experience.data)">
                            <textboxprofile [mx]="ObjCollection.user_experience" [edit]="textboxedit"></textboxprofile>
                        </div>
                        <div class="" *ngIf="sameUser">
                            <textboxprofile [mx]="ObjCollection.user_experience" [edit]="textboxedit"></textboxprofile>
                        </div>
                        <div *ngIf="!sameUser && (service.isAdmin || ObjCollection.professional_certification.data)">
                            <textboxprofile [mx]="ObjCollection.professional_certification" [edit]="textboxedit"></textboxprofile>
                        </div>
                        <div *ngIf="sameUser">
                            <textboxprofile [mx]="ObjCollection.professional_certification" [edit]="textboxedit"></textboxprofile>
                        </div>
                    </div>
                    <div *ngIf="!sameUser && (service.isAdmin || ObjCollection.user_quotes.data)" class="col-md-6">
                        <textboxprofile [mx]="ObjCollection.user_quotes" [edit]="textboxedit"></textboxprofile>
                    </div>
                    <div *ngIf="sameUser" class="col-md-6">
                        <textboxprofile [mx]="ObjCollection.user_quotes" [edit]="textboxedit"></textboxprofile>
                    </div>
                </div>
                <div *ngIf="!sameUser && (service.isAdmin || ObjCollection.user_education.data)" class="col-md-12">
                    <div>
                        <textboxprofile [mx]="ObjCollection.user_education" [edit]="textboxedit"></textboxprofile>
                    </div>
                </div>
                <div *ngIf="sameUser" class="col-md-12">
                    <div>
                        <textboxprofile [mx]="ObjCollection.user_education" [edit]="textboxedit"></textboxprofile>
                    </div>
                </div>
                <div *ngIf="!sameUser && (service.isAdmin || ObjCollection.user_awards.data)" class="col-md-12">
                    <div>
                        <textboxprofile [mx]="ObjCollection.user_awards" [edit]="textboxedit"></textboxprofile>
                    </div>
                </div>
                <div *ngIf="sameUser" class="col-md-12">
                    <div>
                        <textboxprofile [mx]="ObjCollection.user_awards" [edit]="textboxedit"></textboxprofile>
                    </div>
                </div>
                <div *ngIf="!sameUser && (service.isAdmin || ObjCollection.social_network.data)" class="col-md-12">
                    <div>
                        <textboxprofile [mx]="ObjCollection.social_network" [edit]="textboxedit"></textboxprofile>
                    </div>
                </div>
                <div *ngIf="sameUser" class="col-md-12">
                    <div>
                        <textboxprofile [mx]="ObjCollection.social_network" [edit]="textboxedit"></textboxprofile>
                    </div>
                </div>
            </div>
            <div *ngIf="is_public" class="col-sm-6 col-xs-12 usr-name" style="padding: 0;">
                <div style="display: flex;">
                    <div class="col-md-6">
                        <div>
                            <textboxprofile [mx]="ObjCollection.name" [edit]="textboxedit"></textboxprofile>
                        </div>
                        <div *ngIf="is_claimable">
                            <textboxprofile [mx]="ObjCollection.user_experience" [edit]="textboxedit"></textboxprofile>
                        </div>
                        <div *ngIf="!is_claimable">
                            <textboxprofile [mx]="ObjCollection.birth_death" [edit]="textboxedit"></textboxprofile>
                        </div>
                        <div>
                            <textboxprofile [mx]="ObjCollection.user_designation" [edit]="textboxedit" (careerChanged)="updateCareer($event)"></textboxprofile>
                        </div>
                        <div>
                            <textboxprofile [mx]="ObjCollection.professional_certification" [edit]="textboxedit"></textboxprofile>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <textboxprofile [mx]="ObjCollection.user_quotes" [edit]="textboxedit"></textboxprofile>
                    </div>
                </div>
                <div class="col-md-12">
                    <div>
                        <textboxprofile [mx]="ObjCollection.user_education" [edit]="textboxedit"></textboxprofile>
                    </div>
                </div>
                <div class="col-md-12">
                    <div>
                        <textboxprofile [mx]="ObjCollection.user_awards" [edit]="textboxedit"></textboxprofile>
                    </div>
                </div>
                <div class="col-md-12">
                    <div>
                        <textboxprofile [mx]="ObjCollection.social_network" [edit]="textboxedit"></textboxprofile>
                    </div>
                </div>
            </div>
            <div class="col-sm-3 col-xs-12" style="padding-left: 8%;">
                <i *ngIf="!cile && !service.isAdmin" class="glyphicon glyphicon-flag" data-toggle="modal" data-target="#flagModal1" style="color: gray;vertical-align: bottom; font-size: 20px; margin-left: 5px; cursor: pointer;"></i>
                <div *ngIf="cile || service.isAdmin || service.teachingAssistant == ta_id" style="margin: 20px 0;">
                    <button *ngIf="!textboxedit" (click)="checkLogin('first');" class="editToggle">Edit Profile&nbsp;
                        <i class="glyphicon glyphicon-pencil"></i>
                    </button>
                    <button *ngIf="textboxedit" (click)="textboxedit = !textboxedit" class="editToggle cmw">Done</button>
                    <button *ngIf="textboxedit" (click)="textboxedit = !textboxedit" class="editToggle1 btn btn-default cmw">Cancel</button>
                    <i class="glyphicon glyphicon-flag" data-toggle="modal" data-target="#flagModal1" style="color: gray;vertical-align: bottom; font-size: 20px; margin-left: 5px; cursor: pointer; top: -7px;"></i>
                </div>
                <div *ngIf="is_claimable && is_public && !sameUser" style="margin-top: 10px;text-align: left;">
                    <button class="btn btn-danger" style="background-color: red;" (click)="claim()">Is this you?</button>
                    <p style="font-size:12px; float: left; margin-top: 5px; width: 130px;">Claim this profile to add a photo and control the content.</p>
                </div>
                <div *ngIf="!is_claimable" style="width: 175px; margin-top: 10px;text-align: left;">
                    <p style="font-size:12px; margin-top: 5px; color: gray;">This is a public profile that can be edited by anyone. No one can claim this profile. Changes are tracked.</p>
                </div>
                <div *ngIf="cile && service.isTeachingAssistant">
                    <a style="cursor: pointer;" (click)="gotoDashboard()">View  Dashboard</a>
                </div>
                <div *ngIf="cile && service.isTeachingAssistant">
                    <a style="cursor: pointer;" data-toggle="modal" data-target="#dashboardModal">Share dashboard access</a>
                </div>
            </div>
        </div> -->
        <div class="row row-grid"
            style="padding: 25px 0; min-height:232px; background-color: white; display: flex;flex-wrap: wrap;">
            <div class="col-md-1 arrow-div" style="display: flex; align-items: center; padding: 0;">
                <div class="row right-arrow"></div>
            </div>
            <input type="file" (change)="fileChange($event)" id="input" #input style="visibility: hidden;" />
            <div class="col-md-3 col-xs-12" style="display: flex; align-items: center; justify-content: center;">
                <img src="{{ imagesrc }}" class="img-circle img-grid" alt="{{user_pic_alt}}">
                <span *ngIf="textboxedit" class="editPic img-circle" (click)="choosePic()">
                    <i style="margin-top: 7px;" class="glyphicon glyphicon-pencil"></i>
                </span>
            </div>
            <div *ngIf="!is_public" class="col-md-6 col-xs-12 usr-name">
                <div style="display: flex;">
                    <div class="col-sm-12">
                        <div>
                            <textboxprofile [mx]="ObjCollection.name" [edit]="textboxedit"></textboxprofile>
                        </div>
                        <div *ngIf="!sameUser && (service.isAdmin || ObjCollection.user_designation.data)">
                            <textboxprofile [mx]="ObjCollection.user_designation" [edit]="textboxedit"
                                (careerChanged)="updateCareer($event)"></textboxprofile>
                        </div>
                        <div *ngIf="sameUser">
                            <textboxprofile [mx]="ObjCollection.user_designation" [edit]="textboxedit"
                                (careerChanged)="updateCareer($event)"></textboxprofile>
                        </div>
                        <div *ngIf="!sameUser && (service.isAdmin || ObjCollection.user_education.data)">
                            <textboxprofile [mx]="ObjCollection.user_education" [edit]="textboxedit">
                            </textboxprofile>
                        </div>
                        <div *ngIf="sameUser">
                            <textboxprofile [mx]="ObjCollection.user_education" [edit]="textboxedit">
                            </textboxprofile>
                        </div>
                        <div *ngIf="!sameUser && (service.isAdmin || ObjCollection.user_quotes.data)">
                            <textboxprofile [mx]="ObjCollection.user_quotes" [edit]="textboxedit"></textboxprofile>
                        </div>
                        <div *ngIf="sameUser">
                            <textboxprofile [mx]="ObjCollection.user_quotes" [edit]="textboxedit"></textboxprofile>
                        </div>
                        <div *ngIf="!sameUser && (service.isAdmin || ObjCollection.user_experience.data)">
                            <textboxprofile [mx]="ObjCollection.user_experience" [edit]="textboxedit"></textboxprofile>
                        </div>
                        <div *ngIf="sameUser">
                            <textboxprofile [mx]="ObjCollection.user_experience" [edit]="textboxedit"></textboxprofile>
                        </div>
                        <div *ngIf="!sameUser && (service.isAdmin || ObjCollection.professional_certification.data)">
                            <textboxprofile [mx]="ObjCollection.professional_certification" [edit]="textboxedit">
                            </textboxprofile>
                        </div>
                        <div *ngIf="sameUser">
                            <textboxprofile [mx]="ObjCollection.professional_certification" [edit]="textboxedit">
                            </textboxprofile>
                        </div>
                        <div *ngIf="!sameUser && (service.isAdmin || ObjCollection.user_awards.data)">
                            <textboxprofile [mx]="ObjCollection.user_awards" [edit]="textboxedit"></textboxprofile>
                        </div>
                        <div *ngIf="sameUser">
                            <textboxprofile [mx]="ObjCollection.user_awards" [edit]="textboxedit"></textboxprofile>
                        </div>
                        <div *ngIf="!sameUser && (service.isAdmin || ObjCollection.social_network.data)">
                            <textboxprofile [mx]="ObjCollection.social_network" [edit]="textboxedit"></textboxprofile>
                        </div>
                        <div *ngIf="sameUser">
                            <textboxprofile [mx]="ObjCollection.social_network" [edit]="textboxedit"></textboxprofile>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="is_public" class="col-md-6 col-xs-12 usr-name">
                <div style="display: flex;">
                    <div class="col-sm-12">
                        <div>
                            <textboxprofile [mx]="ObjCollection.name" [edit]="textboxedit"></textboxprofile>
                        </div>

                        <div>
                            <textboxprofile [mx]="ObjCollection.user_designation" [edit]="textboxedit"
                                (careerChanged)="updateCareer($event)"></textboxprofile>
                        </div>
                        <div>
                            <textboxprofile [mx]="ObjCollection.user_education" [edit]="textboxedit">
                            </textboxprofile>
                        </div>
                        <div>
                            <textboxprofile [mx]="ObjCollection.user_quotes" [edit]="textboxedit"></textboxprofile>
                        </div>
                        <div *ngIf="is_claimable">
                            <textboxprofile [mx]="ObjCollection.user_experience" [edit]="textboxedit"></textboxprofile>
                        </div>
                        <div *ngIf="!is_claimable">
                            <textboxprofile [mx]="ObjCollection.birth_death" [edit]="textboxedit"></textboxprofile>
                        </div>
                        <div>
                            <textboxprofile [mx]="ObjCollection.professional_certification" [edit]="textboxedit">
                            </textboxprofile>
                        </div>
                        <div>
                            <textboxprofile [mx]="ObjCollection.user_awards" [edit]="textboxedit"></textboxprofile>
                        </div>
                        <div>
                            <textboxprofile [mx]="ObjCollection.social_network" [edit]="textboxedit">
                            </textboxprofile>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-2 col-xs-12">
                <div *ngIf="cile || service.isAdmin || (service.teachingAssistant == ta_id && loginStatus)"
                    style="margin: 20px 0;">
                    <button *ngIf="!textboxedit" (click)="checkLogin('first');" class="editToggle">Edit Profile&nbsp;
                        <i class="glyphicon glyphicon-pencil"></i>
                    </button>
                    <button *ngIf="textboxedit" (click)="textboxedit = !textboxedit"
                        class="editToggle cmw">Done</button>
                    <button *ngIf="textboxedit" (click)="textboxedit = !textboxedit"
                        class="editToggle1 btn btn-default cmw">Cancel</button>
                    <i class="glyphicon glyphicon-flag" data-toggle="modal" data-target="#flagModal1"
                        style="color: gray;vertical-align: bottom; font-size: 20px; margin-left: 5px; cursor: pointer; top: -7px;"></i>
                </div>
                <div *ngIf="is_claimable && is_public && !sameUser" style="margin-top: 10px;text-align: left;">
                    <button class="btn btn-danger" style="background-color: red;" (click)="claim()">Is this
                        you?</button>
                    <p style="font-size:12px; float: left; margin-top: 5px; width: 130px;">Claim this profile to add a
                        photo and control the content.</p>
                </div>
                <div *ngIf="!is_claimable" style="width: 175px; margin-top: 10px;text-align: left;">
                    <p style="font-size:12px; margin-top: 5px; color: gray;">This is a public profile that can be edited
                        by anyone. No one can claim this profile. Changes are tracked.</p>
                </div>
                <div *ngIf="cile && service.isTeachingAssistant && user_type == 2">
                    <a style="cursor: pointer;" (click)="gotoDashboard()">View Dashboard</a>
                </div>
                <!-- <div *ngIf="cile && service.isTeachingAssistant && user_type == 2">
                    <a style="cursor: pointer;" data-toggle="modal" data-target="#dashboardModal">Share dashboard
                        access</a>
                </div> -->
            </div>
        </div>
        <div class=" row content-txt1" *ngIf="!privacy">
            <div *ngIf="is_claimable && is_public" style="background: white; padding: 6px; color: black;text-align: left; padding-left: 15px;
                margin-top: 10px; border: 2px solid #999;margin-left: 2%; margin-right: 2%;">
                <p style="font-family: OpenSans-ExtraBold; margin-bottom: 5px; font-size: 18px;"><span
                        style="text-transform: uppercase;">{{ObjCollection.name.data}}</span> is an unclaimed page</p>
                <p style="font-family: OpenSans-Regular; margin: 0;">Only <span
                        style="text-transform: uppercase;">{{ObjCollection.name.data}}</span> may claim this page, Know
                    this person? Let them know to claim this page.</p>
            </div>
            <!-- <div *ngIf="is_claimable && user && is_public"
                style="background: red; font-style: italic; padding: 6px; color: white;text-align: center;">This profile
                is
                publicly owned and was created by a {{ service.project_name }} contributor.
                <b>Claim this profile</b> using the claim button above to add a photo and control the content.</div> -->
            <div class="row middle" style="display: flex; justify-content: center;">
                <div class="col-sm-5 col-xs-12 scroll"
                    style="background-color: white; padding: 15px 25px;height:100vmin;overflow-y:auto; margin-top: 20px;">
                    <div class="div-pad_btm">
                        <p *ngIf="service.mobilecheck() && cile">To edit your profile, sign-in on a laptop or desktop
                            computer</p>
                        <h4 class="my-stry" *ngIf="!is_public">My Story</h4>
                        <h4 class="my-stry" *ngIf="is_public">About</h4>
                        <div *ngIf="cile || service.isAdmin" style="float: right;">
                            <!-- <button *ngIf="!textboxedit1" (click)="checkLogin('second');" class="editToggle" style="float: right;">Edit
              <i class="glyphicon glyphicon-pencil"></i>
            </button> -->
                            <button *ngIf="textboxedit1" (click)="textboxedit1 = !textboxedit1"
                                class="editToggle1 btn btn-default cmw" style="float: right;">Cancel</button>
                            <button *ngIf="textboxedit1" (click)="textboxedit1 = !textboxedit1" class="editToggle cmw"
                                style="float: right;">Done</button>
                        </div>
                        <div *ngIf="stem == 'Yes'" style="float:right; margin-right: 5px;">
                            <button class="editToggle" (click)="showAlert()" style="float: right;">Ask Me a
                                Question</button>
                        </div>
                    </div>
                    <div *ngIf="!is_public">
                        <p *ngIf="audio">
                            <span style="font-family: OpenSans-Bold;">Audio</span>
                            <i *ngIf="textboxedit1" style="float: right;" (click)="deleteMedia('user_audio')"
                                class="glyphicon glyphicon-trash"></i>
                            <audio controls style="width: 100%; margin-top: 5px;">
                                <source src="{{audio}}" type="audio/mp3" /> Your browser does not support the audio
                                element.
                            </audio>
                        </p>
                        <!-- <p *ngIf="video">
            <span style="font-family: OpenSans-Bold;">Video</span>
            <i (click)="deleteMedia('user_video')" *ngIf="textboxedit1" style="float: right;" class="glyphicon glyphicon-trash"></i>
            <video controls style="width: 100%; margin-top: 5px;">
              <source src="{{video}}" type="video/mp4" /> Your browser does not support the video element.
            </video>
          </p> -->
                 <span style="font-family: OpenSans-Bold;">External Video</span>

                        <div id="vide" *ngIf="external_video?.length > 0" style="margin-bottom: 10px;display: flex;gap: 10px;justify-content: space-between;flex-wrap: wrap;">
                            <div class="exvideos"  *ngFor="let item of external_video_iframe; let i = index">
                               
                                <!-- <div class="intrinsic-container intrinsic-container-16x9"> -->
                                    
                                    <div style="height: 100%;width: 100%;"  [innerHTML]="item">
                                    </div>
                                <!-- </div> -->
                            </div>
                        </div>
                        <p *ngIf="textboxedit1" style="font-family: OpenSans-Bold;">
                            <!-- <span style="margin-right: 5px;">Upload Audio/Video Files</span> -->
                            <span style="margin-right: 5px;">Upload Audio File</span>
                            <!-- <input [disabled]="abc" type="file" accept="audio/*,video/*" multiple="false" (change)="mediaSelected1($event)" style="display: inline-block; font-family: OpenSans-Light;" /> -->

                            <input [disabled]="abc" type="file" accept="audio/*" multiple="false"
                                (change)="mediaSelected1($event)"
                                style="display: inline-block; font-family: OpenSans-Light;" />
                            <br>OR
                            <br>
                            <input type="text" placeholder="Enter complete Youtube/Vimeo video url"
                                [(ngModel)]="videoURL" (keyup)="videoURL?.length > 0 ? abc = true : abc = false"
                                [disabled]="evt" style="width: 100%;">
                            <br>
                            <br>
                            <button (click)="mediaSelected(evt)" class="editToggle btn btn-default" *ngIf="evt">
                                <i id="btn" class="glyphicon glyphicon-upload"></i> Upload</button>
                            <button (click)="externalMediaSelected()" class="editToggle btn btn-default" *ngIf="!evt">
                                <i id="btn1" class="glyphicon glyphicon-upload"></i> Upload</button>

                            <span style="display: block;">Note: You can add 1 audio or video file at a time</span>
                        </p>
                        <p *ngIf="textboxedit1" style="font-family: OpenSans-Bold;">
                            <span>Are you willing to answer questions from kids in participating STEM programs?</span>
                            <a style="font-family: OpenSans-Light;" data-toggle="collapse"
                                data-target="#learnMoreText">Learn more</a>
                            <span *ngIf="showwilling" style="display: block;">
                                <label class="radio-inline">
                                    <input type="radio" name="optradio1" (change)="changeWillingness($event)"
                                        [checked]="stem == 'Yes'" value="true">Yes</label>
                                <label class="radio-inline">
                                    <input type="radio" name="optradio1" (change)="changeWillingness($event)"
                                        [checked]="stem == 'No'" value="false">No</label>
                            </span>
                            <span style="display: block; font-family: OpenSans-Regular;" *ngIf="!showwilling">{{stem}}
                                <i class="glyphicon glyphicon-pencil" (click)="showwilling = true"></i>
                            </span>
                        </p>
                        <p id="learnMoreText" class="collapse" style="background-color:#FFE089; padding:10px;">
                            Our education collaborators are working on programs to implement the app in STEM classrooms
                            and
                            clubs for kids and teens nationally and around the world to learn about how engineering
                            shapes
                            our every day lives. Kids and teens in these programs will be
                            invited to connect with engineers through the app so they can meet and interact with
                            professionals in their fields of interest. Please contact us to learn more about the program
                            and
                            how you can participate.
                        </p>
                        <div *ngIf="!sameUser && (service.isAdmin || myStoryCollection.technical_expertise.data)">
                            <p style="font-family: OpenSans-Bold;">
                                Technical and Software Expertise
                            </p>
                            <p class="padding-bottom">
                                <userprofiletextbox [mx]="myStoryCollection.technical_expertise" [edit]="textboxedit1">
                                </userprofiletextbox>

                            </p>
                        </div>
                        <div *ngIf="sameUser">
                            <p style="font-family: OpenSans-Bold;">
                                Technical and Software Expertise
                            </p>
                            <p class="padding-bottom">
                                <userprofiletextbox [mx]="myStoryCollection.technical_expertise" [edit]="textboxedit1">
                                </userprofiletextbox>

                            </p>
                        </div>
                        <!-- <div *ngIf="!sameUser && (service.isAdmin || myStoryCollection.career_status.data)">
                            <p style="font-family: OpenSans-Bold;">
                                Career Status
                            </p>
                            <p class="padding-bottom">
                                <userprofiletextbox [mx]="myStoryCollection.career_status" [edit]="textboxedit1">
                                </userprofiletextbox>

                            </p>
                        </div>
                        <div *ngIf="sameUser">
                            <p style="font-family: OpenSans-Bold;">
                                Career Status
                            </p>
                            <p class="padding-bottom">
                                <userprofiletextbox [mx]="myStoryCollection.career_status" [edit]="textboxedit1">
                                </userprofiletextbox>

                            </p>
                        </div> -->
                        <div *ngIf="!sameUser && (service.isAdmin || myStoryCollection.q1_ans.data)">
                            <p style="font-family: OpenSans-Bold;">
                                I am...
                            </p>
                            <p class="padding-bottom">
                                <userprofiletextbox [mx]="myStoryCollection.q1_ans" [edit]="textboxedit1">
                                </userprofiletextbox>

                            </p>
                        </div>
                        <div *ngIf="sameUser">
                            <p style="font-family: OpenSans-Bold;">
                                I am...
                            </p>
                            <p class="padding-bottom">
                                <userprofiletextbox [mx]="myStoryCollection.q1_ans" [edit]="textboxedit1">
                                </userprofiletextbox>

                            </p>
                        </div>
                        <div *ngIf="!sameUser && (service.isAdmin || myStoryCollection.q2_ans.data)">
                            <p style="font-family: OpenSans-Bold;">
                                I chose {{career}} because...
                            </p>
                            <p class="padding-bottom">
                                <userprofiletextbox [mx]="myStoryCollection.q2_ans" [edit]="textboxedit1">
                                </userprofiletextbox>

                            </p>
                        </div>
                        <div *ngIf="sameUser">
                            <p style="font-family: OpenSans-Bold;">
                                I chose {{career}} because...
                            </p>
                            <p class="padding-bottom">
                                <userprofiletextbox [mx]="myStoryCollection.q2_ans" [edit]="textboxedit1">
                                </userprofiletextbox>

                            </p>
                        </div>
                        <div *ngIf="!sameUser && (service.isAdmin || myStoryCollection.q3_ans.data)">
                            <p style="font-family: OpenSans-Bold;">
                                I like my job because...
                            </p>
                            <p class="padding-bottom">
                                <userprofiletextbox [mx]="myStoryCollection.q3_ans" [edit]="textboxedit1">
                                </userprofiletextbox>

                            </p>
                        </div>
                        <div *ngIf="sameUser">
                            <p style="font-family: OpenSans-Bold;">
                                I like my job because...
                            </p>
                            <p class="padding-bottom">
                                <userprofiletextbox [mx]="myStoryCollection.q3_ans" [edit]="textboxedit1">
                                </userprofiletextbox>

                            </p>
                        </div>
                        <div *ngIf="!sameUser && (service.isAdmin || myStoryCollection.q4_ans.data)">
                            <p style="font-family: OpenSans-Bold;">
                                My typical day at work involves...
                            </p>
                            <p class="padding-bottom">
                                <userprofiletextbox [mx]="myStoryCollection.q4_ans" [edit]="textboxedit1">
                                </userprofiletextbox>

                            </p>
                        </div>
                        <div *ngIf="sameUser">
                            <p style="font-family: OpenSans-Bold;">
                                My typical day at work involves...
                            </p>
                            <p class="padding-bottom">
                                <userprofiletextbox [mx]="myStoryCollection.q4_ans" [edit]="textboxedit1">
                                </userprofiletextbox>

                            </p>
                        </div>
                        <div *ngIf="!sameUser && (service.isAdmin || myStoryCollection.q5_ans.data)">
                            <p style="font-family: OpenSans-Bold;">
                                I want others to know...
                            </p>
                            <p class="padding-bottom">
                                <userprofiletextbox [mx]="myStoryCollection.q5_ans" [edit]="textboxedit1">
                                </userprofiletextbox>

                            </p>
                        </div>
                        <div *ngIf="sameUser">
                            <p style="font-family: OpenSans-Bold;">
                                I want others to know...
                            </p>
                            <p class="padding-bottom">
                                <userprofiletextbox [mx]="myStoryCollection.q5_ans" [edit]="textboxedit1">
                                </userprofiletextbox>

                            </p>
                        </div>
                        <div
                            *ngIf="!sameUser && (service.isAdmin || myStoryCollection.engineering_education_helped_me.data)">
                            <p style="font-family: OpenSans-Bold;">
                                My STEM education has helped me ...
                            </p>
                            <p class="padding-bottom">
                                <userprofiletextbox [mx]="myStoryCollection.engineering_education_helped_me"
                                    [edit]="textboxedit1">
                                </userprofiletextbox>

                            </p>
                        </div>
                        <div *ngIf="sameUser">
                            <p style="font-family: OpenSans-Bold;">
                                My STEM education has helped me ...
                            </p>
                            <p class="padding-bottom">
                                <userprofiletextbox [mx]="myStoryCollection.engineering_education_helped_me"
                                    [edit]="textboxedit1">
                                </userprofiletextbox>

                            </p>
                        </div>
                        <div *ngIf="!sameUser && (service.isAdmin || myStoryCollection.user_team_experience.data)">
                            <p style="font-family: OpenSans-Bold;">
                                My experience working in teams
                            </p>
                            <p class="padding-bottom">
                                <userprofiletextbox [mx]="myStoryCollection.user_team_experience" [edit]="textboxedit1">
                                </userprofiletextbox>

                            </p>
                        </div>
                        <div *ngIf="sameUser">
                            <p style="font-family: OpenSans-Bold;">
                                My experience working in teams
                            </p>
                            <p class="padding-bottom">
                                <userprofiletextbox [mx]="myStoryCollection.user_team_experience" [edit]="textboxedit1">
                                </userprofiletextbox>

                            </p>
                        </div>
                        <div *ngIf="!sameUser && (service.isAdmin || myStoryCollection.q6_ans.data)">
                            <p style="font-family: OpenSans-Bold;">
                                This is My Story
                            </p>
                            <p class="padding-bottom">
                                <userprofiletextbox [mx]="myStoryCollection.q6_ans" [edit]="textboxedit1">
                                </userprofiletextbox>

                            </p>
                        </div>
                        <div *ngIf="sameUser">
                            <p style="font-family: OpenSans-Bold;">
                                This is My Story
                            </p>
                            <p class="padding-bottom">
                                <userprofiletextbox [mx]="myStoryCollection.q6_ans" [edit]="textboxedit1">
                                </userprofiletextbox>

                            </p>
                        </div>
                        <div *ngIf="!sameUser && (service.isAdmin || myStoryCollection.q7_ans.data)">
                            <!-- <ng-container *ngIf="myStoryCollection.q7_ans.data[0]"> -->

                            <p style="font-family: OpenSans-Bold;">
                                Hobbies and interests
                            </p>
                            <p class="padding-bottom">
                                <userprofiletextbox [mx]="myStoryCollection.q7_ans" [edit]="textboxedit1">
                                </userprofiletextbox>

                            </p>
                            <!-- </ng-container> -->
                        </div>
                        <div *ngIf="sameUser">
                            <p style="font-family: OpenSans-Bold;">
                                Hobbies and interests
                            </p>
                            <p class="padding-bottom">
                                <userprofiletextbox [mx]="myStoryCollection.q7_ans" [edit]="textboxedit1">
                                </userprofiletextbox>

                            </p>
                        </div>
                        <div *ngIf="!sameUser && (service.isAdmin || myStoryCollection.q8_ans.data)">
                            <p style="font-family: OpenSans-Bold;">
                                What classes have you used most?
                            </p>
                            <p class="padding-bottom">
                                <userprofiletextbox [mx]="myStoryCollection.q8_ans" [edit]="textboxedit1">
                                </userprofiletextbox>

                            </p>
                        </div>
                        <div *ngIf="sameUser">
                            <p style="font-family: OpenSans-Bold;">
                                What classes have you used most?
                            </p>
                            <p class="padding-bottom">
                                <userprofiletextbox [mx]="myStoryCollection.q8_ans" [edit]="textboxedit1">
                                </userprofiletextbox>

                            </p>
                        </div>
                        <div *ngIf="!sameUser && (service.isAdmin || myStoryCollection.q9_ans.data)">
                            <p style="font-family: OpenSans-Bold;">
                                What skills have been most important to your career?
                            </p>
                            <p class="padding-bottom">
                                <userprofiletextbox [mx]="myStoryCollection.q9_ans" [edit]="textboxedit1">
                                </userprofiletextbox>
                            </p>
                        </div>
                        <div *ngIf="sameUser">
                            <p style="font-family: OpenSans-Bold;">
                                What skills have been most important to your career?
                            </p>
                            <p class="padding-bottom">
                                <userprofiletextbox [mx]="myStoryCollection.q9_ans" [edit]="textboxedit1">
                                </userprofiletextbox>
                            </p>
                        </div>
                        <div
                            *ngIf="!sameUser && (service.isAdmin || myStoryCollection.external_links.data?.length > 0)">
                            <p style="font-family: OpenSans-Bold;">
                                External Links
                            </p>
                            <p class="padding-bottom">
                                <userprofiletextbox [mx]="myStoryCollection.external_links" [edit]="textboxedit1">
                                </userprofiletextbox>
                            </p>
                        </div>
                        <div *ngIf="sameUser">
                            <p style="font-family: OpenSans-Bold;">
                                External Links
                            </p>
                            <p class="padding-bottom">
                                <userprofiletextbox [mx]="myStoryCollection.external_links" [edit]="textboxedit1">
                                </userprofiletextbox>
                            </p>
                        </div>
                    </div>
                    <div *ngIf="is_public">
                        <!-- <p *ngIf="textboxedit1" style="font-family: OpenSans-Bold;"> -->
                        <!-- <span style="margin-right: 5px;">Upload Audio/Video Files</span> -->
                        <!-- <span style="margin-right: 5px;">Upload Audio File</span> -->
                        <!-- <input [disabled]="abc" type="file" accept="audio/*,video/*" multiple="false" (change)="mediaSelected1($event)" style="display: inline-block; font-family: OpenSans-Light;" /> -->
                        <!-- 
                        <input [disabled]="abc" type="file" accept="audio/*" multiple="false" (change)="mediaSelected1($event)" style="display: inline-block; font-family: OpenSans-Light;" />
                        <br>OR
                        <br>
                        <input type="text" placeholder="Enter complete Youtube/Vimeo video url" [(ngModel)]="videoURL" (keyup)="videoURL.length > 0 ? abc = true : abc = false" [disabled]="evt" style="width: 100%;">
                        <br>
                        <br>
                        <button (click)="mediaSelected(evt)" class="editToggle btn btn-default" *ngIf="evt">
              <i id="btn" class="glyphicon glyphicon-upload"></i> Upload</button>
                        <button (click)="externalMediaSelected()" class="editToggle btn btn-default" *ngIf="!evt">
              <i id="btn1" class="glyphicon glyphicon-upload"></i> Upload</button>

                        <span style="display: block;">Note: You can add 1 audio or video file at a time</span>
                    </p> -->
                        <p style="font-family: OpenSans-Bold;">
                            About
                        </p>
                        <p class="padding-bottom">
                            <userprofiletextbox [mx]="myStoryCollection.about" [edit]="textboxedit1">
                            </userprofiletextbox>

                        </p>
                        <div id="vide" *ngIf="external_video?.length > 0" style="margin-bottom: 10px;">
                            <span style="font-family: OpenSans-Bold;">External Video</span>
                            <div *ngFor="let item of external_video_iframe; let i = index">
                                <!-- <i (click)="deleteExternalMedia(i)" *ngIf="textboxedit1" style="display: block; text-align: right;" class="glyphicon glyphicon-trash"></i> -->
                                <div class="intrinsic-container intrinsic-container-16x9">
                                    <!-- <iframe style="border: none; margin-top: 5px;" [src]="sanitizer.bypassSecurityTrustResourceUrl(external_video)" >
                      </iframe> -->
                                    <div style="width: 100%; height: 100%; position: absolute;" [innerHTML]="item">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="!sameUser && (service.isAdmin || myStoryCollection.technical_expertise.data)">
                            <p style="font-family: OpenSans-Bold;">
                                Technical and Software Expertise
                            </p>
                            <p class="padding-bottom">
                                <userprofiletextbox [mx]="myStoryCollection.technical_expertise" [edit]="textboxedit1">
                                </userprofiletextbox>

                            </p>
                        </div>
                        <div
                            *ngIf="!sameUser && (service.isAdmin || myStoryCollection.external_links.data?.length > 0)">
                            <p style="font-family: OpenSans-Bold;">
                                External Links
                            </p>
                            <p class="padding-bottom">
                                <userprofiletextbox [mx]="myStoryCollection.external_links" [edit]="textboxedit1">
                                </userprofiletextbox>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-xs-12 scroll"
                    style="overflow-y:auto; background-color: white; padding: 15px 25px; margin-left: 20px;height:100vmin; margin-top: 20px;">
                    <h4 class="prjct-wored" style="display: inline-block;">Examples of Projects I've Worked On <span
                            *ngIf="userProjects.length > 0">(click on project title for more informaton)</span></h4>
                    <div *ngIf="cile || service.isAdmin" style="float: right;">
                        <!-- <button *ngIf="!textboxedit2 && !noProjects" (click)="checkLogin('third');" class="editToggle" style="float: right;">Edit
            <i class="glyphicon glyphicon-pencil"></i>
          </button> -->
                        <button *ngIf="textboxedit2" (click)="textboxedit2 = !textboxedit2"
                            class="editToggle1 btn btn-default cmw" style="float: right;">Cancel</button>
                        <button *ngIf="textboxedit2" (click)="textboxedit2 = !textboxedit2" class="editToggle cmw"
                            style="float: right;">Done</button>
                    </div>
                    <!-- v.z -->
                    <p class="prjct-wored" *ngIf="noProjects">Currently no projects uploaded</p>
                    <div *ngIf="cile && service.projectInfoShow"
                        style="padding-top:20px; background-color: #ffe27e;padding-right: 10px;padding-left: 10px;">
                        <i style="float: right;" class="glyphicon glyphicon-remove"
                            (click)="service.projectInfoShow = !service.projectInfoShow"></i>
                        <p style="font-size: 13px;">Add
                            <span *ngIf="!is_public">your</span>
                            <span *ngIf="is_public">{{ObjCollection.name.data}}'s</span> name in the team section on any
                            project and the project will automatically show up here
                            <span *ngIf="!is_public"> on your profile.</span>
                            <span *ngIf="is_public">.</span>
                        </p>
                        <p style="font-size: 13px; padding-bottom: 20px;">
                            <span style="cursor: pointer; text-decoration: underline;"
                                (click)="service.explorerSection = 'projects'" routerLinkActive="active"
                                routerLink="/explorer/projects">Browse the explorer</span> to find projects
                            <span *ngIf="!is_public">you worked on</span> or
                            <span style="cursor: pointer; text-decoration: underline;" routerLinkActive="active"
                                routerLink="/createproject">upload
                                a project here</span>.
                        </p>
                    </div>
                    <div id="contain" *ngFor="let item of userProjects; let i = index">
                        <div id="projects{{i}}">
                            <userprojects (checkProjectLength)="checkEmptyProjects();" [mx]="item" [edit]="textboxedit2"
                                [currentProject]="i" [selfUser]="sameUser"></userprojects>
                        </div>
                    </div>
                    <h4 class="prjct-wored" *ngIf="userCreatedAdoptedProjects.length > 0 || sameUser"
                        style="display: inline-block;">Projects I've Authored on RePicture</h4>
                    <p class="prjct-wored" *ngIf="userCreatedAdoptedProjects.length == 0 && sameUser">
                        Currently no projects uploaded</p>
                    <div *ngFor="let item of userCreatedAdoptedProjects; let i = index">
                        <div style="display: inline-block; width: 125px; vertical-align: top;">
                            <div class="imgParent" routerLinkActive="active"
                                routerLink="/project/{{item.project_name_slug}}"
                                [ngStyle]="{'background-image': 'url('+item.imageurl+')'}"
                                style="text-decoration: none; border: 1px solid gray; background-size: contain; background-repeat: no-repeat; background-position: center;">
                            </div>
                        </div>
                        <div class="text">
                            <a routerLinkActive="active" routerLink="/project/{{item.project_name_slug}}">
                                <p class="title-top1">{{item.name }}</p>
                            </a>
                            <p class="title-top2">{{ item.address }}</p>
                        </div>
                        <hr style="border-top: 1px solid #e0e0e0 !important;">
                    </div>
                    <h4 class="prjct-wored" *ngIf="adoptedWithOthersProjects.length > 0 || sameUser"
                        style="display: inline-block;">Projects I've Authored With Others</h4>
                    <p class="prjct-wored" *ngIf="adoptedWithOthersProjects.length == 0 && sameUser">
                        Currently no projects uploaded</p>
                    <div *ngFor="let item of adoptedWithOthersProjects; let i = index">
                        <div style="display: inline-block; width: 125px; vertical-align: top;">
                            <div class="imgParent" routerLinkActive="active"
                                routerLink="/project/{{item.project_name_slug}}"
                                [ngStyle]="{'background-image': 'url('+item.imageurl+')'}"
                                style="text-decoration: none; border: 1px solid gray; background-size: contain; background-repeat: no-repeat; background-position: center;">
                            </div>
                        </div>
                        <div class="text">
                            <a routerLinkActive="active" routerLink="/project/{{item.project_name_slug}}">
                                <p class="title-top1">{{item.name }}</p>
                            </a>
                            <p class="title-top2">{{ item.address }}</p>
                        </div>
                        <hr style="border-top: 1px solid #e0e0e0 !important;">
                    </div>
                </div>
            </div>
        </div>
        <div class="fadeMe not-selectable" *ngIf="Onimage">
            <div>
                <button class="btn-default" (click)="Oncropped()">Crop & Save</button>
                <button class="btn-default" (click)="onCancel()">Cancel</button>
            </div>
            <img-cropper class="cropper1" #cropper [image]="dat" [settings]="cropperSettings1"
                (onCrop)="cropped($event)">
            </img-cropper>

            <div class="result" *ngIf="dat.image" style="visibility: hidden;">
                <img #imgcrp [src]="dat.image" [width]="croppedWidth" [height]="croppedHeight">
            </div>
        </div>
        <footer style="overflow: hidden; margin: 0 -15px;" *ngIf="!privacy"></footer>
        <!--flag Modal -->
        <div id="flagModal1" class="modal fade" role="dialog">
            <div class="modal-dialog" style="width: 350px;">
                <div class="modal-content" style="border-radius: 0px; width: 350px;">
                    <div class="modal-body">
                        <h4>Report content violation</h4>
                        <br>
                        <div class="form-group">
                            <label class="radio-inline">
                                <input type="radio" (click)="radioClick('1')" name="flagReason"> Violent or repulsive
                                content</label>
                        </div>
                        <div class="form-group">
                            <label class="radio-inline">
                                <input type="radio" (click)="radioClick('2')" name="flagReason"> Hateful or abusive
                                content</label>
                        </div>
                        <div class="form-group">
                            <label class="radio-inline">
                                <input type="radio" (click)="radioClick('3')" name="flagReason"> Promotes
                                terrorism</label>
                        </div>
                        <div class="form-group">
                            <label class="radio-inline">
                                <input type="radio" (click)="radioClick('4')" name="flagReason"> Spam or
                                misleading</label>
                        </div>
                        <div class="form-group">
                            <label class="radio-inline">
                                <input type="radio" (click)="radioClick('5')" name="flagReason"> Infringes my
                                rights</label>
                        </div>
                        <div class="form-group">
                            <label class="radio-inline">
                                <input type="radio" (click)="radioClick('6')" name="flagReason"> Caption issue</label>
                        </div>
                        <div class="form-group">
                            <label class="radio-inline">
                                <input type="radio" (click)="radioClick('7')" name="flagReason"> Other</label>
                        </div>
                        <div *ngIf="showOther" class="form-group">
                            <input type="text" class="form-control" [(ngModel)]="otherReason"
                                placeholder="Other reason" />
                        </div>
                        <p>
                            Flagged content will be reviewed by staff and appropriate action will be taken.
                        </p>
                        <br>
                        <br>
                        <div style="text-align: right;">
                            <button class="btn btn-default" data-dismiss="modal">Cancel</button>&nbsp;
                            <button class="btn btn-primary" (click)="submitReport();">Submit Report </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="pickPhotoModal" class="modal fade" role="dialog">
            <div class="modal-dialog">
                <div class="modal-content" style="border-radius: 0px;">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                        <h4 class="modal-title">Pick a candid photo</h4>
                    </div>
                    <div class="modal-body">
                        You can help change perceptions by adding a photo of yourself in one of your happy moments. It
                        could
                        be doing something you love to do, enjoying a place you love to visit, or trying something
                        you've
                        never tried before.
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-default" data-dismiss="modal">Cancel</button>
                        <button class="btn btn-primary" data-dismiss="modal" (click)="choose()">Upload Photo</button>
                    </div>
                </div>
            </div>
        </div>
        <div id="snackbar3">Thank you for helping to keep this site positive.</div>
    </div>
    <div class="private-box" *ngIf="privacy">
        <h2 style="margin-top: 0;"><span style="top: 5px;" class="glyphicon glyphicon-exclamation-sign"
                aria-hidden="true"></span> This profile is private.</h2>
        <br>
        <button class="btn btn-info" style="background-color: #1a496d;font-family: OpenSans-Semibold;"
            [routerLink]="['']" routerLinkActive="router-link-active">Goto Home</button>
    </div>
    <div class="editMode" *ngIf="textboxedit">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <h3 class="editTitleName">Editing {{ObjCollection.name.data}}'s Profile
                    </h3>

                    <div class="tabbable-panel" style="overflow: hidden;">
                        <div class="tabbable-line">
                            <ul class="nav nav-tabs ">
                                <li class="active" (click)="tab = 1">
                                    <a *ngIf="!service.mobilecheck()" id="tab1" href="#tab_default_1" data-toggle="tab">
                                        Basic Info </a>
                                    <a *ngIf="service.mobilecheck()" id="tab1" href="#tab_default_1" data-toggle="tab">
                                        <i class="glyphicon glyphicon-user"></i> </a>
                                </li>
                                <li (click)="tab = 2;">
                                    <a *ngIf="!service.mobilecheck()" id="tab2" href="#tab_default_2" data-toggle="tab">
                                        <span *ngIf="is_public || !is_claimable">Their Story</span><span
                                            *ngIf="!is_public">My Story</span> </a>
                                    <a *ngIf="service.mobilecheck()" id="tab2" href="#tab_default_2" data-toggle="tab">
                                        <i style="font-size: 23px;" class="glyphicon glyphicon-education"></i>
                                    </a>
                                </li>
                                <li (click)="tab = 3;">
                                    <a *ngIf="!service.mobilecheck()" id="tab3" href="#tab_default_3" data-toggle="tab">
                                        Projects </a>
                                    <a *ngIf="service.mobilecheck()" id="tab3" href="#tab_default_3" data-toggle="tab">
                                        <i class="glyphicon glyphicon-home"></i> </a>
                                </li>
                                <li (click)="tab = 4;">
                                    <a (click)="clickProjectOrder()" *ngIf="!service.mobilecheck()" id="tab4" href="#tab_default_4" data-toggle="tab">
                                        Projects Order</a>
                                    <a (click)="clickProjectOrder()" *ngIf="service.mobilecheck()" id="tab4" href="#tab_default_4" data-toggle="tab">
                                        <i class="glyphicon glyphicon-th-list"></i> </a>
                                </li>
                                <li *ngIf="service.mobilecheck()" (click)="textboxedit = !textboxedit;"
                                    style="float: right;margin: 0;">
                                    <i class="glyphicon glyphicon-remove"></i>
                                </li>
                            </ul>
                            <div class="tab-content">
                                <div class="tab-pane active" id="tab_default_1">
                                    <p class="required-info">
                                        * required info
                                    </p>
                                    <input type="file" (change)="fileChange($event)" id="input" #input
                                        style="visibility: hidden;" />
                                    <div style="z-index: 50;" class="col-sm-12 col-xs-12 text-center">
                                        <img src="{{ imagesrc }}" class="img-circle img-grid tempImg"
                                            alt="Cinque Terre">
                                        <!-- <span *ngIf="textboxedit" class="editPic img-circle" (click)="choosePic()"> -->
                                        <!-- <i style="margin-top: 7px;" class="glyphicon glyphicon-pencil"></i> -->
                                        <a style="display: block; margin-top: 10px;" *ngIf="textboxedit"
                                            class="changePicture" (click)="choosePic()">change profile picture</a>
                                        <!-- </span> -->
                                    </div>
                                    <div class="col-sm-8" style="margin: 20px 0; padding: 0; float: left;
                                    width: 100%;">
                                        <div class="col-sm-6">
                                            <p class="editTitles">First Name*</p>
                                            <p><input class="form-control" [(ngModel)]="first_name"
                                                    placeholder="First Name" type="text"></p>
                                        </div>
                                        <div class="col-sm-6">
                                            <p class="editTitles">Last Name</p>
                                            <p><input class="form-control" [(ngModel)]="last_name"
                                                    placeholder="Last Name" type="text"></p>
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <p class="editTitles">Line of Work*</p>
                                        <div class="flex-column">
                                            <p class="col-sm-4" *ngFor="let item of lineofwork">
                                                <label class="checkbox-inline checkboxes">
                                                    <input type="checkbox"
                                                        [checked]="hasLineOfWork(item) || (item == 'Other' && hasOtherLineOfWork) || (item == 'Other Engineer' && hasOtherEngineer)"
                                                        (change)="onChange(item, $event.target.checked)" />{{item}}
                                                </label>
                                                <input class="form-control" style="width: 100%;"
                                                    *ngIf="item == 'Other Engineer' && hasOtherEngineer" type="text"
                                                    [(ngModel)]="otherEngineers" />
                                                <input class="form-control" style="width: 100%;"
                                                    *ngIf="item == 'Other' && hasOtherLineOfWork" type="text"
                                                    [(ngModel)]="otherLOW" />
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 education" style="margin: 20px 0;">
                                        <p class="editTitles">Education</p>
                                        <div *ngFor="let item of collectionEducation; let i = index" class="water-res"
                                            style="color:rgba(26, 73, 109, 255);font-family: open-regular;padding-bottom: 5px;">
                                            <span *ngIf="item.school" class="">{{ item.school }} - </span>
                                            <span *ngIf="item.degrees?.length > 0">
                                                <span class="certi1" *ngFor="let item1 of item.degrees">{{ item1
                                                    }}</span>
                                            </span>
                                            <span *ngIf="item.field_of_study" class="certi1">
                                                {{ item.field_of_study }}</span>
                                            <span *ngIf="item.year" class="">{{ item.year }}</span> &nbsp;
                                            <span class="glyphicon glyphicon-minus-sign"
                                                (click)="removeEducation(i)"></span>
                                        </div>
                                        <p><a *ngIf="!addmoreCliked && collectionEducation?.length > 0"
                                                (click)="addmoreCliked = true" style="cursor: pointer">Add
                                                more</a></p>
                                        <div *ngIf="collectionEducation?.length == 0 || addmoreCliked">
                                            <form action="" class="txt-data1 ">
                                                <input [(ngModel)]="school"  type="text" class=" form-control"
                                                placeholder="Enter school" [formControl]="schoolTypeField" [matAutocomplete]="auto"/>
                                                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                                    <mat-option *ngFor="let school of schoolArray$ | async" [value]="option">
                                                      {{school.name}}
                                                    </mat-option>
                                                  </mat-autocomplete>
                                            </form>
                                            
                                            <div style="display: inline-block;width: 40%;">
                                                <my-dropdown [defaultText]="'Degrees'"
                                                    [selectedOptions]="selected_degrees" [myOptions]="degrees"
                                                    (selectedArray)="degreesChange($event)">
                                                </my-dropdown>
                                            </div>
                                            <input *ngIf="is_degree_other" [(ngModel)]="degree_other" type="text"
                                                class="txt-data1 form-control" style="margin-top: 5px;"
                                                placeholder="Enter other degree" />
                                            <br>
                                            <input [(ngModel)]="field_of_study" type="text"
                                                class="txt-data1 form-control"
                                                placeholder="Enter Major/Field of study" />
                                            <input [(ngModel)]="year" type="number" maxlength="4"
                                                class="txt-data1 form-control" style="margin-top: 5px;"
                                                placeholder="Enter year completed" />
                                            <i class="glyphicon glyphicon-ok" (click)="addEducation()"></i>
                                        </div>
                                    </div>
                                    <div *ngIf="is_claimable" class="col-sm-12">
                                        <p class="editTitles">Experience Level</p>
                                        <div class="flex-column" style="height: 112px;">
                                            <p class="col-sm-4" *ngFor="let item of exp; let i = index;">
                                                <label class="radio-inline checkboxes">
                                                    <input type="radio" name="exp"
                                                        [checked]="ObjCollection.user_experience.data == item"
                                                        (change)="onChangeExp(item)" />{{item}} <span
                                                        *ngIf="i < 5">years of
                                                        work experience</span>
                                                </label>
                                            </p>
                                        </div>
                                    </div>
                                    <div *ngIf="!is_claimable" class="col-sm-12">
                                        <p class="editTitles">Birth & Death</p>
                                        <div class="col-sm-6">
                                            <p style="font-weight: 700;">Birth year:</p>
                                            <input [(ngModel)]="birth_year" type="text" maxlength="4"
                                                pattern="/^-?\d+\.?\d*$/" class="form-control"
                                                placeholder="Enter birth year"
                                                onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57" />
                                        </div>
                                        <div class="col-sm-6">
                                            <p style="font-weight: 700;">Death year:</p>
                                            <input [(ngModel)]="death_year" type="text" maxlength="4"
                                                pattern="/^-?\d+\.?\d*$/" class="form-control"
                                                placeholder="Enter death year"
                                                onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57" />
                                        </div>
                                    </div>
                                    <div class="col-sm-12 education" style="margin: 20px 0;">
                                        <p class="editTitles">Social Media Links</p>
                                        <div class="col-sm-6">
                                            <div class="form-inline">
                                                <label>LinkedIn:</label>
                                                <input type="text" class="form-control"
                                                    [(ngModel)]="existingSocial.LinkedIn"
                                                    placeholder="Enter LinkedIn URL e.g. https://www.linkedin.com/example/">
                                            </div>
                                            <div class="form-inline">
                                                <label>Facebook:</label>
                                                <input type="text" class="form-control"
                                                    [(ngModel)]="existingSocial.Facebook"
                                                    placeholder="Enter Facebook URL e.g. https://www.facebook.com/example/">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="form-inline">
                                                <label>Twitter:</label>
                                                <input type="text" class="form-control"
                                                    [(ngModel)]="existingSocial.Twitter"
                                                    placeholder="Enter Twitter URL e.g. https://www.twitter.com/example/">
                                            </div>
                                            <div class="form-inline">
                                                <label>Instagram:</label>
                                                <input type="text" class="form-control"
                                                    [(ngModel)]="existingSocial.Instagram"
                                                    placeholder="Enter Instagram URL e.g. https://www.instagram.com/example/">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="form-inline">
                                                <label>Other:</label>
                                                <input type="text" class="form-control"
                                                    [(ngModel)]="existingSocial.Other.displayText"
                                                    placeholder="Enter text to display">
                                                <label></label>
                                                <input type="text" class="form-control"
                                                    [(ngModel)]="existingSocial.Other.displayURL"
                                                    placeholder="Enter URL e.g. https://www.example.com/example/"
                                                    style="margin-top: 5px;">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane" id="tab_default_2">
                                    <div *ngIf="!is_public">
                                        <!-- <p class="editTitles">
                                            Career Status
                                        </p>
                                        <p style="float: left; width: 100%;">
                                            <app-editprofilemystory [mx]="myStoryCollection.career_status"
                                                [isEnginner]="isEngineer">
                                            </app-editprofilemystory>
                                            <br>
                                        </p> -->
                                        <div style="margin-bottom: 10px;">
                                            <span class="editTitles">Are you willing to answer questions from students
                                                in
                                                participating
                                                RePicture
                                                Educational Programs?</span>&nbsp;
                                            <a style="font-family: OpenSans-Light; cursor:pointer;"
                                                data-toggle="collapse" data-target="#learnMoreText1">Learn
                                                more</a><br>
                                            <label class="radio-inline">
                                                <input type="radio" name="optradio1"
                                                    (change)="changeWillingness($event)" [checked]="stem == 'Yes'"
                                                    value="true">Yes</label>
                                            <label class="radio-inline">
                                                <input type="radio" name="optradio1"
                                                    (change)="changeWillingness($event)" [checked]="stem == 'No'"
                                                    value="false">No</label>
                                            <p id="learnMoreText1" class="collapse"
                                                style="background-color:#FFE089; padding:10px; width: 100%;">
                                                RePicture is teaming with education collaborators to use the RePicture
                                                App
                                                in classrooms and clubs so students can discover careers shaping the
                                                future.
                                                Students enrolled in these programs will be invited to connect with STEM
                                                professionals through the
                                                App. Please contact edu@RePictureEngineering.com to learn more about the
                                                program and how you can participate.
                                            </p>
                                            <br><br>
                                        </div>
                                        <p class="editTitles">
                                            Professional certification
                                        </p>
                                        <p>
                                            <app-editprofilemystory [mx]="ObjCollection.professional_certification"
                                                (certificates)="putCertificates($event)"></app-editprofilemystory>
                                            <br>
                                        </p>
                                        <p class="editTitles">
                                            Technical and Software Expertise (eg. stormwater manangement)
                                        </p>
                                        <p>
                                            <app-editprofilemystory [mx]="myStoryCollection.technical_expertise">
                                            </app-editprofilemystory>
                                            <br>
                                        </p>
                                        <p class="editTitles">
                                            Classes I have used the most
                                        </p>
                                        <p>
                                            <app-editprofilemystory [mx]="myStoryCollection.q8_ans">
                                            </app-editprofilemystory>
                                            <br><br>
                                        </p>
                                        <p class="editTitles">
                                            Skills that have been most important to my career
                                        </p>
                                        <p>
                                            <app-editprofilemystory [mx]="myStoryCollection.q9_ans">
                                            </app-editprofilemystory>
                                            <br><br>
                                        </p>
                                        <p class="editTitles">
                                            I chose my career because...
                                        </p>
                                        <p>
                                            <app-editprofilemystory [mx]="myStoryCollection.q2_ans">
                                            </app-editprofilemystory>
                                            <br><br>
                                        </p>
                                        <p class="editTitles">
                                            I like my job because...
                                        </p>
                                        <p>
                                            <app-editprofilemystory [mx]="myStoryCollection.q3_ans">
                                            </app-editprofilemystory>
                                            <br><br>
                                        </p>
                                        <p class="editTitles">
                                            My typical day at work involves...
                                        </p>
                                        <p>
                                            <app-editprofilemystory [mx]="myStoryCollection.q4_ans">
                                            </app-editprofilemystory>
                                            <br><br>
                                        </p>
                                        <p class="editTitles">
                                            Ways my STEM education has helped me
                                        </p>
                                        <p>
                                            <app-editprofilemystory
                                                [mx]="myStoryCollection.engineering_education_helped_me">
                                            </app-editprofilemystory>
                                            <br><br>
                                        </p>
                                        <p class="editTitles">
                                            My experience working in teams
                                        </p>
                                        <p>
                                            <app-editprofilemystory [mx]="myStoryCollection.user_team_experience">
                                            </app-editprofilemystory>
                                            <br><br>
                                        </p>
                                        <p class="editTitles">
                                            I want others to know...
                                        </p>
                                        <p>
                                            <app-editprofilemystory [mx]="myStoryCollection.q5_ans">
                                            </app-editprofilemystory>
                                            <br><br>
                                        </p>
                                        <p class="editTitles">
                                            I am...
                                        </p>
                                        <p>
                                            <app-editprofilemystory [mx]="myStoryCollection.q1_ans">
                                            </app-editprofilemystory>
                                            <br><br>
                                        </p>
                                        <p class="editTitles">
                                            This is My Story
                                        </p>
                                        <p>
                                            <app-editprofilemystory [mx]="myStoryCollection.q6_ans">
                                            </app-editprofilemystory>
                                            <br><br>
                                        </p>
                                        <p class="editTitles">
                                            Favorite Quote or Motto
                                        </p>
                                        <p>
                                            <app-editprofilemystory [mx]="ObjCollection.user_quotes">
                                            </app-editprofilemystory>
                                            <br><br>
                                        </p>
                                        <p class="editTitles">
                                            Awards
                                        </p>
                                        <p>
                                            <app-editprofilemystory [mx]="ObjCollection.user_awards"
                                                (awards)="putAwards($event)">
                                            </app-editprofilemystory>
                                            <br>
                                        </p>
                                        <p class="editTitles">
                                            Hobbies and interests
                                        </p>
                                        <p>
                                            <app-editprofilemystory [mx]="myStoryCollection.q7_ans">
                                            </app-editprofilemystory>
                                            <br>
                                            <bR>
                                        </p>
                                        <p class="editTitles">
                                            Upload Video
                                        </p>
                                        <div *ngIf="external_video_edit?.length > 0" style="margin-bottom: 10px;float: left; width: 100%; margin-left: -15px;
                                    margin-right: -15px;">
                                            <div class="col-md-4"
                                                *ngFor="let item of external_video_iframe_edit; let i = index">
                                                <i (click)="deleteExternalMedia(i)"
                                                    style="display: block; text-align: right;"
                                                    class="glyphicon glyphicon-trash"></i>
                                                <div class="intrinsic-container intrinsic-container-4x3">
                                                    <div style="width: 100%; height: 100%; position: absolute;"
                                                        [innerHTML]="item"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <p>
                                            <input type="text" class="form-control"
                                                placeholder="Enter complete Youtube/Vimeo video url"
                                                [(ngModel)]="videoURL" style="width: 100%; margin-bottom: 5px;">

                                            <button (click)="externalMediaSelected()"
                                                class="editToggle btn btn-default">
                                                <i id="btn1" class="glyphicon glyphicon-upload"></i> Upload</button>
                                            <br><br>
                                        </p>
                                        <p class="editTitles">
                                            External Links
                                        </p>
                                        <p>
                                            <app-editprofilemystory [mx]="myStoryCollection.external_links"
                                                (externalLinks)="putLinks($event)">
                                            </app-editprofilemystory>
                                            <br>
                                        </p>
                                    </div>
                                    <div *ngIf="is_public">
                                        <p class="editTitles">
                                            About
                                        </p>
                                        <p style="float: left; width: 100%;margin-bottom: 25px;">
                                            <textarea autosize placeholder="Enter information" id="mystory_about"
                                                [innerHTML]="service.removeLineBreaks(myStoryCollection.about.data)"></textarea>
                                        </p>
                                        <p class="editTitles">
                                            Technical and Software Expertise (eg. stormwater manangement)
                                        </p>
                                        <p>
                                            <app-editprofilemystory [mx]="myStoryCollection.technical_expertise">
                                            </app-editprofilemystory>
                                            <br>
                                        </p>
                                        <p class="editTitles">
                                            Awards
                                        </p>
                                        <p>
                                            <app-editprofilemystory [mx]="ObjCollection.user_awards"
                                                (awards)="putAwards($event)">
                                            </app-editprofilemystory>
                                            <br>
                                        </p>
                                        <p class="editTitles">
                                            Upload Video
                                        </p>
                                        <div *ngIf="external_video_edit?.length > 0" style="margin-bottom: 10px;float: left; width: 100%; margin-left: -15px;
                                        margin-right: -15px;">
                                            <div class="col-md-4"
                                                *ngFor="let item of external_video_iframe_edit; let i = index">
                                                <i (click)="deleteExternalMedia(i)"
                                                    style="display: block; text-align: right;"
                                                    class="glyphicon glyphicon-trash"></i>
                                                <div class="intrinsic-container intrinsic-container-4x3">
                                                    <div style="width: 100%; height: 100%; position: absolute;"
                                                        [innerHTML]="item"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <p>
                                            <input type="text" class="form-control"
                                                placeholder="Enter complete Youtube/Vimeo video url"
                                                [(ngModel)]="videoURL" style="width: 100%; margin-bottom: 5px;">

                                            <button (click)="externalMediaSelected()"
                                                class="editToggle btn btn-default">
                                                <i id="btn1" class="glyphicon glyphicon-upload"></i> Upload</button>
                                            <br><br>
                                        </p>
                                        <p class="editTitles">
                                            External Links
                                        </p>
                                        <p>
                                            <app-editprofilemystory [mx]="myStoryCollection.external_links"
                                                (externalLinks)="putLinks($event)">
                                            </app-editprofilemystory>
                                            <br>
                                        </p>
                                    </div>
                                </div>
                                <div class="tab-pane" id="tab_default_3">
                                    <!-- <p class="required-info">
                                    * required info
                                </p> -->
                                    <!-- v.z -->
                                    <p class="prjct-wored" *ngIf="noProjects">Currently no projects uploaded</p>
                                    <div *ngIf="cile && service.projectInfoShow"
                                        style="padding-top:20px; background-color: #ffe27e;padding-right: 10px;padding-left: 10px;">
                                        <i style="float: right;" class="glyphicon glyphicon-remove"
                                            (click)="service.projectInfoShow = !service.projectInfoShow"></i>
                                        <p style="font-size: 13px;">Add
                                            <span *ngIf="!is_public">your</span>
                                            <span *ngIf="is_public">{{ObjCollection.name.data}}'s</span> name in the
                                            team
                                            section on any project and the project will automatically show up here
                                            <span *ngIf="!is_public"> on your profile.</span>
                                            <span *ngIf="is_public">.</span>
                                        </p>
                                        <p style="font-size: 13px; padding-bottom: 20px;">
                                            <span style="cursor: pointer; text-decoration: underline;"
                                                (click)="service.explorerSection = 'projects'" routerLinkActive="active"
                                                routerLink="/explorer/projects">Browse the explorer</span> to find
                                            projects
                                            <span *ngIf="!is_public">you worked on</span> or
                                            <span style="cursor: pointer; text-decoration: underline;"
                                                routerLinkActive="active" routerLink="/createproject">upload
                                                a project here</span>.
                                        </p>
                                    </div>
                                    <div id="contain" *ngFor="let item of userProjects; let i = index">
                                        <div id="projects{{i}}">
                                            <userprojects (checkProjectLength)="checkEmptyProjects();" [editMode]="true"
                                                [mx]="item" [edit]="textboxedit2" [currentProject]="i"></userprojects>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane" id="tab_default_4">
                                    <!-- <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
                                        <div class="example-box" *ngFor="let project of dragDropArray" cdkDrag>
                                            {{project}}</div>
                                    </div> -->
                                    <app-projectsorder [mainArray]="projectObj"></app-projectsorder>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="text-center btn-grp">
                        <button *ngIf="!service.mobilecheck()" type="button" class="btn grp1 cancel"
                            (click)="textboxedit = !textboxedit; addmoreCliked = false; tab = 1;">Cancel Edits</button>
                        <button *ngIf="tab != 4" type="button" class="btn btn-success grp1 grpsav"
                            (click)="save('continue')">Save &
                            Continue</button>
                        <button type="button" class="btn btn-success grp1 grpsav" (click)="save('exit')">Save &
                            Exit</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-confirm-c [sanket]="confirmObject" (performAction)="confirmedAction($event)"></app-confirm-c>
    <div id="dashboardModal" class="modal fade" role="dialog" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog">
            <!-- Modal content-->
            <div class="modal-content" style="border-radius: 0px !important;">
                <div class="modal-header" style="background-color: #FFE089;">
                    <h4 class="modal-title">Share dashboard access</h4>
                </div>
                <div class="modal-body">
                    <p>Below enter registered email address of whom you want to share access</p>
                    <input type="text" placeholder="Enter email address here" class="form-control" #email />
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>
                    <button class="btn btn-primary" data-dismiss="modal"
                        (click)="shareDashboardAccess(email.value);email.value = ''">Give access</button>
                </div>
            </div>
        </div>
    </div>
    <style>
        @media screen and (max-width: 991px) {
            .arrow-div {
                display: none !important;
            }
        }

        /* .example-list {
            width: 500px;
            max-width: 100%;
            border: solid 1px #ccc;
            min-height: 60px;
            display: block;
            background: white;
            border-radius: 4px;
            overflow: hidden;
        }

        .example-box {
            padding: 20px 10px;
            border-bottom: solid 1px #ccc;
            color: rgba(0, 0, 0, 0.87);
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            box-sizing: border-box;
            cursor: move;
            background: white;
            font-size: 14px;
        }

        .cdk-drag-preview {
            box-sizing: border-box;
            border-radius: 4px;
            box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
                0 8px 10px 1px rgba(0, 0, 0, 0.14),
                0 3px 14px 2px rgba(0, 0, 0, 0.12);
        }

        .cdk-drag-placeholder {
            opacity: 0;
        }

        .cdk-drag-animating {
            transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
        }

        .example-box:last-child {
            border: none;
        }

        .example-list.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
            transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
        } */

        .right-arrow {
            width: 100%;
            height: 0;
            padding-top: 100%;
            padding-bottom: 100%;
            padding-left: 100%;
            overflow: hidden;
        }

        .right-arrow:after {
            content: "";
            display: block;
            width: 0;
            height: 0;
            margin-top: -500px;
            margin-left: -515px;
            border-top: 500px solid transparent;
            border-bottom: 500px solid transparent;
            border-left: 500px solid #7ac90a;
        }

        .imgParent {
            width: 100%;
            padding-top: 75%;
            position: relative;
            background: rgba(212, 212, 212, 0.4);
        }

        .text {
            display: inline-block;
            width: calc(100% - 130px);
        }

        .padding-bottom {
            margin-bottom: 22px;
        }

        .blur {
            filter: blur(5px);
        }

        .private-box {
            padding: 40px;
            text-align: center;
            background: white;
            opacity: 1;
            z-index: 2;
            border-radius: 4px;
            display: inline-block;
            margin-top: 50px;
            position: absolute;
            left: 50%;
            transform: translate(-50%, 0);
            -webkit-box-shadow: 0 10px 6px -6px #777;
            -moz-box-shadow: 0 10px 6px -6px #777;
            box-shadow: 0 10px 6px -6px #777;
        }

        .private {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            font-family: open-regular;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .private:after {
            content: '';
            background-color: rgb(0, 0, 0);
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            opacity: 0.7;
            bottom: 0;
        }

        #mystory_about {
            /* max-width: 500px; */
            width: 98%;
            height: 100px;
            -moz-border-bottom-colors: none;
            -moz-border-left-colors: none;
            -moz-border-right-colors: none;
            -moz-border-top-colors: none;
            background: none repeat scroll 0 0 rgba(0, 0, 0, 0.07);
            border-color: -moz-use-text-color #FFFFFF #FFFFFF -moz-use-text-color;
            border-image: none;
            border-radius: 6px 6px 6px 6px;
            border-style: none solid solid none;
            border-width: medium 1px 1px medium;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12) inset;
            color: #555555;
            font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
            font-size: 1em;
            line-height: 1.4em;
            padding: 5px 8px;
            transition: background-color 0.2s ease 0s;
        }

        #mystory_about:focus {
            background: none repeat scroll 0 0 #FFFFFF;
            outline-width: 0;
        }

        .flex-column {
            display: flex;
            height: 260px;
            flex-wrap: wrap;
            flex-direction: column;
            overflow-x: auto;
        }

        .flex-column p {
            margin-bottom: 6px !important;
        }

        .form-inline {
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            margin-bottom: 5px;
        }

        .form-inline label {
            margin: 5px 10px 5px 0;
            width: 25%;
        }

        .form-inline input {
            width: 70%;
        }

        .form-inline button {
            padding: 10px 20px;
            background-color: dodgerblue;
            border: 1px solid #ddd;
            color: white;
            cursor: pointer;
        }

        .form-inline button:hover {
            background-color: royalblue;
        }

        @media (max-width: 800px) {
            .form-inline input {
                margin: 10px 0;
            }

            .form-inline {
                flex-direction: column;
                align-items: stretch;
            }
        }

        .exvideos{
            flex-basis: 48%;
            height: 230px;
        }
        @media (max-width: 1000px) {
            .exvideos{
            flex-basis: 100%;
            height: 230px;
        }
        }

        .awardsmore {
            cursor: pointer;
        }

        :host ::ng-deep .dropdown .dropdown-menu {
            width: auto !important;
        }

        .fadeinout {
            /*margin-bottom: 10px;*/
        }

        .addmore {
            color: #14578a;
            font-size: 12px;
            cursor: pointer;
            margin-left: 10px;
        }

        .txt-select {
            height: 23px;
            font-size: 14px;
            color: black;
            width: auto;
            padding: 0;
            display: inline-block;
        }

        .regular {
            font-family: open-regular;
        }

        .water-res {
            font-family: OpenSans-Semibold;
            color: #929292;
            margin: 0 0 0px;
            font-size: 14px;
        }

        .exp-desc {
            color: #a2a8a8;
            font-size: 14px;
        }

        .para-title {
            padding-left: 30px;
            font-size: 13px;
            color: grey;
        }

        .types:not(:last-child):after {
            content: "|";
            padding: 0 5px;
        }

        .awards:not(:last-child):after {
            content: "|";
            padding: 0 5px;
        }

        .certi1:not(:last-child):after {
            content: ", ";
            /*padding: 0 5px;*/
        }

        .certi:not(:last-child):after {
            content: ", ";
            /*padding: 0 5px;*/
        }

        .certif:not(:last-child):after {
            content: ", ";
        }

        .certif1:not(:last-child):after {
            content: ", ";
        }

        /* textarea {
        max-height: 250px;
        padding: 0px !important;
        margin: 0px 0px 0px 30px;
        width: 95% !important;
        max-width: 480px;
        height: 150px;
    } */

        select {
            display: inline-block;
            height: 26px;
            padding: 0px 5px;
            border: 1px solid #ccc;
            margin-bottom: 5px;
            width: 80%;
        }

        :host ::ng-deep .tab-content .education .dropdown button {
            width: 100%;
            margin-top: -4px;
        }

        .tab-content .education .txt-data1 {
            width: 40%;
            display: inline-block;
            margin-right: 5px;
        }

        .tab-content .img-grid {
            border: 0.5px solid #69A5FF;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            width: 140px;
            height: 140px;
            padding: 2px;
        }

        .tab-content .editPic {
            position: absolute;
            text-align: center;
            background-color: #ffe27e;
            width: 30px;
            height: 30px;
            bottom: 0px;
            top: unset;
            left: unset;
        }

        .tab-content .changePicture {
            /* margin-left: 30px; */
            text-decoration: underline;
            color: #3577EF;
            cursor: pointer;
        }

        .tab-content {
            float: left;
            width: 100%;
        }

        .checkboxes {
            font-size: 15px;
        }

        .editTitles {
            font-family: OpenSans-Bold;
            font-size: 16px;
        }

        .required-info {
            text-align: right;
            margin-right: 10px;
        }

        .editMode {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgb(155, 214, 232);
            font-family: open-regular;
            overflow-y: auto;
        }

        /* Tabs panel */

        .tabbable-panel {
            /* border: 1px solid #eee; */
            padding: 10px 20px;
            border-radius: 20px;
            background: white;
            height: calc(100vh - 155px);
            /* overflow-y: auto; */
        }

        .cancel {
            background-color: gray;
            color: white;
        }

        .grp1 {
            padding-left: 25px;
            padding-right: 25px;
            padding-top: 7px;
            padding-bottom: 7px;
            margin: 25px;
            font-size: 16px;
        }

        .btn-grp {
            margin-top: 0px;
        }

        .nav {
            font-size: 18px;
            word-spacing: 4px;
            font-weight: 600;
        }

        /* Default mode */

        .tabbable-line>.nav-tabs {
            border: none;
            margin: 0px;
        }

        .tabbable-line>.nav-tabs>li {
            margin-right: 15px;
            font-family: OpenSans-Bold;
            font-size: 18px;
            word-spacing: 0;
        }

        .tabbable-line>.nav-tabs>li>a {
            border: 0;
            margin-right: 0;
            color: #737373;
        }

        .tabbable-line>.nav-tabs>li>a>i {
            color: #a6a6a6;
        }

        .tabbable-line>.nav-tabs>li.open,
        .tabbable-line>.nav-tabs>li:hover {
            border-bottom: 4px solid blue;
        }

        .tabbable-line>.nav-tabs>li.open>a,
        .tabbable-line>.nav-tabs>li:hover>a {
            border: 0;
            background: none !important;
            color: #333333;
        }

        .tabbable-line>.nav-tabs>li.open>a>i,
        .tabbable-line>.nav-tabs>li:hover>a>i {
            color: #a6a6a6;
        }

        .tabbable-line>.nav-tabs>li.open .dropdown-menu,
        .tabbable-line>.nav-tabs>li:hover .dropdown-menu {
            margin-top: 0px;
        }

        .tabbable-line>.nav-tabs>li.active {
            border-bottom: 4px solid blue;
            position: relative;
        }

        .tabbable-line>.nav-tabs>li.active>a {
            border: 0;
            color: #333333;
        }

        .tabbable-line>.nav-tabs>li.active>a>i {
            color: #404040;
        }

        .tabbable-line>.tab-content {
            margin-top: -3px;
            background-color: #fff;
            border: 0;
            border-top: 4px solid #eee;
            padding: 15px 0;
            overflow-y: scroll;
            overflow-x: hidden;
            height: calc(100vh - 215px);
        }

        .portlet .tabbable-line>.tab-content {
            padding-bottom: 0;
        }

        /* Below tabs mode */

        .tabbable-line.tabs-below>.nav-tabs>li {
            border-top: 4px solid transparent;
        }

        .tabbable-line.tabs-below>.nav-tabs>li>a {
            margin-top: 0;
        }

        .tabbable-line.tabs-below>.nav-tabs>li:hover {
            border-bottom: 0;
            border-top: 4px solid blue;
        }

        .tabbable-line.tabs-below>.nav-tabs>li.active {
            margin-bottom: -2px;
            border-bottom: 0;
            border-top: 4px solid blue;
        }

        .tabbable-line.tabs-below>.tab-content {
            margin-top: -10px;
            border-top: 0;
            border-bottom: 1px solid #eee;
            padding-bottom: 15px;
        }

        .modal-header {
            background-color: #ffe284;
        }

        #snackbar3 {
            visibility: hidden;
            /* Hidden by default. Visible on click */
            min-width: 250px;
            /* Set a default minimum width */
            margin-left: -125px;
            /* Divide value of min-width by 2 */
            background-color: #333;
            /* Black background color */
            color: #fff;
            /* White text color */
            text-align: center;
            /* Centered text */
            border-radius: 2px;
            /* Rounded borders */
            padding: 16px;
            /* Padding */
            position: fixed;
            /* Sit on top of the screen */
            z-index: 1;
            /* Add a z-index if needed */
            left: 50%;
            /* Center the snackbar */
            bottom: 30px;
            /* 30px from the bottom */
        }

        /* Show the snackbar when clicking on a button (class added with JavaScript) */

        #snackbar.show {
            visibility: visible;
            /* Show the snackbar */
            /* Add animation: Take 0.5 seconds to fade in and out the snackbar. 
However, delay the fade out process for 2.5 seconds */
            -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
            animation: fadein 0.5s, fadeout 0.5s 2.5s;
        }

        #snackbar2.show {
            visibility: visible;
            /* Show the snackbar */
            /* Add animation: Take 0.5 seconds to fade in and out the snackbar. 
However, delay the fade out process for 2.5 seconds */
            -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
            animation: fadein 0.5s, fadeout 0.5s 2.5s;
        }

        #snackbar3.show {
            visibility: visible;
            /* Show the snackbar */
            /* Add animation: Take 0.5 seconds to fade in and out the snackbar. 
However, delay the fade out process for 2.5 seconds */
            -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
            animation: fadein 0.5s, fadeout 0.5s 2.5s;
        }

        /* Animations to fade the snackbar in and out */

        @-webkit-keyframes fadein {
            from {
                bottom: 0;
                opacity: 0;
            }

            to {
                bottom: 30px;
                opacity: 1;
            }
        }

        @keyframes fadein {
            from {
                bottom: 0;
                opacity: 0;
            }

            to {
                bottom: 30px;
                opacity: 1;
            }
        }

        @-webkit-keyframes fadeout {
            from {
                bottom: 30px;
                opacity: 1;
            }

            to {
                bottom: 0;
                opacity: 0;
            }
        }

        @keyframes fadeout {
            from {
                bottom: 30px;
                opacity: 1;
            }

            to {
                bottom: 0;
                opacity: 0;
            }
        }

        input[type=file] {
            /*visibility: hidden;*/
        }

        :host ::ng-deep iframe #player .vp-player-layout {
            left: 0 !important;
            right: 0 !important;
        }

        .intrinsic-container {
            position: relative;
            height: 0;
            overflow: hidden;
        }

        /* 16x9 Aspect Ratio */

        .intrinsic-container-16x9 {
            padding-bottom: 56.25%;
            margin-bottom: 10px;
        }

        /* 4x3 Aspect Ratio */

        .intrinsic-container-4x3 {
            padding-bottom: 75%;
        }

        .intrinsic-container iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .glyphicon-refresh-animate {
            -animation: spin .7s infinite linear;
            -webkit-animation: spin2 .7s infinite linear;
        }

        @-webkit-keyframes spin2 {
            from {
                -webkit-transform: rotate(0deg);
            }

            to {
                -webkit-transform: rotate(360deg);
            }
        }

        @keyframes spin {
            from {
                transform: scale(1) rotate(0deg);
            }

            to {
                transform: scale(1) rotate(360deg);
            }
        }

        div.fadeMe {
            opacity: 1;
            background-color: #000;
            width: 100%;
            height: 100%;
            z-index: 10;
            top: 0;
            left: 0;
            position: fixed;
            text-align: center;
        }

        .not-selectable {
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }

        .backgroundPhotoText {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            font-weight: 600;
            color: #656565;
            font-family: OpenSans-Regular;
            cursor: pointer;
        }

        .editToggle {
            border-radius: 4px;
            background-color: #ffe27e;
            border: none;
            height: 30px;
            font-family: OpenSans-Bold;
            font-size: 0.9em;
        }

        .editToggle1 {
            border: none;
            font-size: 12px;
            height: 30px;
            background-color: transparent;
            font-family: OpenSans-Bold;
            color: #656565;
            padding: 0 2px
        }

        #input {
            position: absolute;
            margin-top: -200px;
        }

        .imgDiv {
            position: absolute;
            margin-left: 30px;
            margin-top: -10px;
        }

        .editPic {
            position: absolute;
            top: 160px;
            left: 179px;
            text-align: center;
            background-color: #ffe27e;
            width: 30px;
            height: 30px;
        }

        .navbar {
            margin-bottom: 0 !important;
        }

        .exp-desc {
            color: #6d6d6d;
            font-size: 13px;
        }

        .usr-name {
            font-family: open-regular;
            margin-top: 20px;
            margin-bottom: 20px;
            /*padding-left: 50px;*/
        }

        .para-span {
            color: #444343;
            font-family: open-regular;
        }

        .para-title {
            padding-left: 30px;
            font-size: 13px;
            color: grey;
        }

        .stry-btn {
            background-color: #ffe27e;
            border: none;
            height: 30px;
            font-family: OpenSans-Bold;
            font-size: 0.9em;
        }

        .my-stry {
            display: inline-block;
            /*padding-right: 25px;
    padding-left: 30px;*/
            font-family: open-regular;
        }

        .div-pad_btm {
            padding-bottom: 15px;
        }

        .title-top3 {
            /*color: #555555;*/
            padding-left: 27px;
            font-family: OpenSans-Bold;
            font-size: 12px;
        }

        .title-top2 {
            padding-left: 27px;
            color: #898989;
            font-size: 13px;
            overflow-y: auto;
            max-height: 40px;
        }

        .title-top1 {
            font-family: OpenSans-Semibold;
            color: rgb(70, 165, 247);
            padding-left: 27px;
            font-size: 18px;
            margin: 0 0 1px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-decoration: underline;
        }

        .water-res {
            font-family: OpenSans-Semibold;
            color: #808080;
            margin: 0 0 0px;
            font-size: 14px;
        }

        .desc-top {
            padding-top: 55px;
            font-family: open-regular;
        }

        .msg-content {
            padding-left: 27px;
            color: #828282;
            font-size: 12px;
            padding-right: 9px;
            font-family: open-regular;
            /*padding-bottom: 10px;*/
        }

        .img-second-title {
            margin-top: -10px;
            padding-bottom: 14px;
            font-family: open-regular;
            color: #898989;
            font-size: 13px;
        }

        .img-title {
            font-family: OpenSans-Bold;
            color: #777777;
            font-size: 13px;
        }

        /*#contain:hover div.text {
    display: block;
  }*/
        /*.text:before {
    right: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    top: -3px;
    position: absolute;
    pointer-events: none;
    border-right-color: #ebebeb;
    border-width: 22px;
  }*/

        .text {
            /*border: 1px solid #d4d4d4;
    width: 55%;
    margin-top: -108px;
    /*background-color: #ebebeb;*/
            /*padding: 5px 0;
    max-height: 80px;*/
            /*display: none;*/
            /*overflow: auto;*/
            /*position: absolute;
    margin-left: 130px;*/
            display: inline-block;
            width: calc(100% - 130px);
        }

        #contain {
            clear: both;
            /*padding-bottom: 10px;*/
        }

        .img-grid {
            /* border: 5px solid #aae7c8;
        background-color: white; */
            height: 275px;
            width: 275px;
            border: 2px solid #ddd;
            padding: 2px;
        }

        .row-grid {
            border-bottom: 2px solid #aae7c8;
        }

        @media only screen and (max-width: 768px) {
            .img-grid {
                margin-bottom: 0px;
            }
        }

        @media (max-width: 575px) {
            .middle {
                display: unset !important;
            }

            .scroll {
                margin-left: 0 !important;
            }
        }

        @media (min-width: 320px) and (max-width: 480px) {
            .flex-column {
                height: auto !important;
            }

            .grp1 {
                margin: 5px 4px !important;
                padding: 5px !important;
            }

            .tab-content .education .txt-data1 {
                width: 90% !important;
            }

            :host ::ng-deep .tab-content .education .dropdown button {
                margin: 5px 0 !important;
            }

            .form-inline input {
                width: 100%;
            }
        }

        /*@media only screen and (max-width: 1199px) {
    .text:after,
    .text:before {
      right: 57%;
    }
  }*/

        .content-txt1 {
            /*margin: 40px 0;*/
            margin-bottom: 40px;
        }

        .flight-img {
            border: 2px solid #ffe27e;
            display: inline-block;
            vertical-align: top;
            height: 120px;
            width: 120px;
        }

        .popover.right>.arrow:after {
            border-right-color: #ebebeb;
        }

        .popover-content {
            background-color: #ebebeb;
        }

        @font-face {
            font-family: open-regular;
            src: url('/assets/fonts/new/opensans-regular-webfont.eot?#iefix') format('embedded-opentype'),
                /* IE */
                url('/assets/fonts/new/opensans-regular-webfont.ttf') format("truetype");
            /* non-IE */
        }

        @font-face {
            font-family: OpenSans-Light;
            src: url('/assets/fonts/new/opensans-light-webfont.eot?#iefix') format('embedded-opentype'),
                /* IE */
                url('/assets/fonts/new/opensans-light-webfont.ttf') format("truetype");
            /* non-IE */
        }

        @font-face {
            font-family: OpenSans-Semibold;
            src: url('/assets/fonts/new/opensans-semibold-webfont.eot?#iefix') format('embedded-opentype'),
                /* IE */
                url('/assets/fonts/new/opensans-semibold-webfont.ttf') format("truetype");
            /* non-IE */
        }

        @font-face {
            font-family: OpenSans-Bold;
            src: url('/assets/fonts/new/opensans-bold-webfont.eot?#iefix') format('embedded-opentype'),
                /* IE */
                url('/assets/fonts/new/opensans-bold-webfont.ttf') format("truetype");
            /* non-IE */
        }

        .prjct-wored {
            font-family: open-regular;
            padding-bottom: 15px;
        }

        @media only screen and (max-width: 768px) {
            .prjct-wored {
                margin-top: 0px;
            }

            .scroll {
                height: auto !important;
            }
        }

        .arrow-left {
            width: 0;
            height: 0;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-right: 10px solid blue;
        }

        .scroll::-webkit-scrollbar {
            width: 5px;
        }

        .scroll::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px #888;
        }

        .scroll::-webkit-scrollbar-thumb {
            background: #808080;
        }
    </style>