import { Component, OnInit } from '@angular/core';
import { Route } from '@angular/compiler/src/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { ProjectService } from '../../projectservice';

@Component({
  selector: 'app-student-teacher',
  templateUrl: './student-teacher.component.html',
  styleUrls: ['./student-teacher.component.css']
})
export class StudentTeacherComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute, private service: ProjectService) { }

  ngOnInit() {
    
  }

}
