<div *ngIf="showTexbox" [@fadeInOut]="showTexbox">
    <div class="fadeinout" [@fadeInOut]="!EditToggle" *ngIf="!EditToggle">
        <div style="display: inline-block; width: 125px; vertical-align: top;">
            <div class="imgParent" routerLinkActive="active" routerLink="/project/{{mx.project_name_slug}}" [ngStyle]="{'background-image': 'url('+mx.project_images[0].image_detail+')'}" style="text-decoration: none; border: 1px solid gray;    background-size: cover;
      background-repeat: no-repeat;
      background-position: center;">
                <!-- <img *ngIf="mx.project_images.length > 0" src="{{ mx.project_images[0].image }}" class="img-responsive flight-img" alt="Cinque Terre">
        <img *ngIf="mx.project_images.length == 0" src="assets/images/no-image.jpg" class="img-responsive flight-img" alt="Cinque Terre"> -->
            </div>
        </div>

        <div class="text">
            <a routerLinkActive="active" routerLink="/project/{{mx.project_name_slug}}">
                <p class="title-top1 projectName">{{ mx.project_name ? mx.project_name : 'No Name' }}</p>
            </a>
            <span *ngIf="edit" (click)="showTextbox()" class="glyphicon glyphicon-pencil" style="vertical-align: super; margin-right: 5px;"></span>
            <span *ngIf="edit" data-toggle="dropdown" class="glyphicon glyphicon-trash" style="vertical-align: super;" (click)="removeCompanySelfFromProjects(mx.project_id, currentProject)"></span>
            <p class="title-top2" style="padding-bottom: 0px !important;">{{ mx.project_location }}</p>
            <p *ngIf="companyData.org_civil_role" class="title-top3">Role on Project</p>
            <p *ngIf="companyData.org_civil_role" class="msg-content">
                <span class="roles" *ngFor="let item of companyData.org_civil_role">{{item}}</span>
            </p>
            <p *ngIf="companyData.org_contribution" class="title-top3">Type of Work Performed <span *ngIf="show">(ie. Site Design)</span></p>
            <p *ngIf="companyData.org_contribution" class="msg-content" [innerHTML]="service.addLineBreaks(companyData.org_contribution)">
            </p>
            <p *ngIf="companyData.org_contrib_details" class="title-top3">A Closer Look at What We Did</p>
            <p *ngIf="companyData.org_contrib_details" class="msg-content" [innerHTML]="service.addLineBreaks(companyData.org_contrib_details)">
            </p>
            <p *ngIf="companyData.org_page" class="msg-content">
                <span class="title-top3" style="padding-left: 0;">Link: </span><a href="{{companyData.org_page}}" target="_blank">Organization Project Page</a>
            </p>
            <p *ngIf="papers2" class="title-top3">Project Awards</p>
            <ul *ngIf="papers2" style="margin-left: 25px;">
                <li *ngFor="let item of peoples1">
                    <span *ngIf="!item.award_link">{{item.award_name}}<span *ngIf="item.award_description"> - {{item.award_description}}</span></span>
                    <span *ngIf="item.award_link"><a href="{{item.award_link}}" target="_blank">{{item.award_name}}</a><span *ngIf="item.award_description"> - {{item.award_description}}</span></span>
                </li>
            </ul>
            <p *ngIf="papers" class="title-top3">Organization Media <span *ngIf="show">(Articles, Presentations, etc.)</span></p>
            <p *ngIf="papers" class="msg-content">
                <span class="people" *ngFor="let people of peoples; let i = index" style="float:left; padding-right: 3px;">
          <a target="_blank" class="abc" href="{{people.reference_url}}" style="cursor: pointer;color: #337ab7;">{{people.reference_desc}} <span *ngIf="people.reference_source" > - {{people.reference_source}}</span></a>
                </span>
                <span class="people" *ngFor="let people of media_papers; let i = index" style="padding-right: 3px;">
            <a target="_blank" class="abc" href="{{people.project_organization_file}}" style="cursor: pointer;color: #337ab7;">{{people.project_organization_file_name}}</a>
          </span>
            </p>
            <p *ngIf="companyData.external_video" class="title-top3">Video</p>
            <div class="msg-content" *ngIf="companyData.external_video" style="margin-bottom: 10px;">
                <div class="intrinsic-container exvideo intrinsic-container-16x9">
                    <div style="width: 100%; height: 100%; position: absolute;background-color: lightgray;" [innerHTML]="companyData.external_video1"></div>
                </div>
            </div>
        </div>
    </div>
    <div [@fadeInOut]="EditToggle" *ngIf="EditToggle">
        <div style="display: inline-block; width: 125px; vertical-align: top;">
            <div class="imgParent" routerLinkActive="active" routerLink="/project/{{mx.project_name_slug}}" style="text-decoration: none; border: 2px solid #ffe27e;">
                <img *ngIf="mx.project_images.length > 0" src="{{ mx.project_images[0].image }}" class="img-responsive flight-img" alt="Cinque Terre">
                <img *ngIf="mx.project_images.length == 0" src="assets/images/no-image.jpg" class="img-responsive flight-img" alt="Cinque Terre">
            </div>
        </div>

        <div class="text">
            <p class="title-top1 projectName">{{ mx.project_name ? mx.project_name : 'No Name' }}</p>
            <button *ngIf="edit && EditToggle" class="editToggle" (click)="save(workperformed.value,closerlook.value,link.value)">Save</button>
            <p class="title-top2" style="padding-bottom: 0px !important;">{{ mx.project_location }}</p>
            <p class="title-top3">Role on Project</p>
            <p class="msg-content" style="width: 90%;">
                <my-dropdown [defaultText]="'Organization role'" [selectedOptions]="companyRole" [myOptions]="companyRoleOptions" (selectedArray)="onChangedd($event)" ngDefaultControl></my-dropdown>
                <input spellcheck="true" *ngIf="companyOther" type="text" [(ngModel)]="otherRole" class="textareaClass" placeholder="Enter other role and click save" style="height: 25px;outline: none; border: none;margin-top: 5px;" />
            </p>
            <p class="title-top3">
                Type of Work Performed <span *ngIf="show">(ie. Site Design)</span> <br> List key services provided on project (limit 300 characters)
            </p>
            <p class="msg-content">
                <textarea spellcheck="true" #workperformed placeholder="Enter information">{{companyData.org_contribution}}</textarea>
            </p>
            <p class="title-top3">A Closer Look at What We Did</p>
            <p class="msg-content" style="font-size: 12px; font-style: italic;">Be as lenghty as you like.</p>
            <p class="msg-content">
                <textarea spellcheck="true" #closerlook placeholder="Enter information" [innerHTML]="service.removeLineBreaks(companyData.org_contrib_details)"></textarea>
            </p>
            <p class="title-top3">Link to organization's Project Page</p>
            <p class="msg-content">
                <input spellcheck="true" #link type="text" class="textareaClass" value="{{companyData.org_page}}" placeholder="Enter complete URL" style="height: 25px;outline: none; border: none;" />
            </p>
            <p class="title-top3">Project Awards</p>
            <div class="msg-content">
                <div *ngIf="peoples1.length > 0" style="margin-bottom: 5px;float: left;width: 100%;">
                    <ul style="margin-left: 25px;">
                        <li *ngFor="let people of peoples1; let i = index">
                            {{people.award_name}}<span *ngIf="people.award_description"> - {{people.award_description}}</span> <i class="glyphicon glyphicon-minus-sign" (click)="removeAward(i)"></i>
                        </li>
                    </ul>
                </div>
                <div>
                    <p style="font-style: italic;">Answer will also appear on project page in awards section</p>
                    <div style="margin-bottom: 5px;" *ngFor="let item of collectionAwards; let i = index">
                        <div style="margin-bottom: 5px;">
                            <input spellcheck="true" type="text" id="awardUrl{{i}}" class="textareaClass" placeholder="Enter award name" value="{{item.award_name}}" style="height: 25px;outline: none; border: none;" />
                        </div>
                        <div style="margin-bottom: 5px;">
                            <input spellcheck="true" type="text" id="awardLink{{i}}" class="textareaClass" placeholder="Enter link to award announcement" value="{{item.award_link}}" style="height: 25px;outline: none; border: none;" />
                        </div>
                        <div>
                            <input spellcheck="true" type="text" id="awardText{{i}}" class="textareaClass" placeholder="Enter award recipients (comma separated)" value="{{item.award_description}}" style="height: 25px;outline: none; border: none;" />
                        </div>
                    </div>
                    <p>
                        <a class="awardsmore" (click)="addmoreAwards(track1)">Add more</a>
                    </p>
                </div>
            </div>
            <p class="title-top3">Organization Media <span *ngIf="show">(Articles, Presentations, etc.)</span></p>
            <div class="msg-content">
                <div style="margin-bottom: 5px;float: left;width: 100%;">
                    <span class="people" *ngFor="let people of peoples; let i = index" style="float:left; padding-right: 3px;">
          <a class="abc" target="_blank" href="{{people.reference_url}}" style="cursor: pointer;color: #337ab7;">{{people.reference_desc}} <span *ngIf="people.reference_source" > - {{people.reference_source}}</span></a>
                    <i class="glyphicon glyphicon-minus-sign" (click)="removeArticle(i)"></i>
                    </span>
                    <span class="people" *ngFor="let people of media_papers; let i = index" style="padding-right: 3px;">
              <a target="_blank" class="abc" href="{{people.project_organization_file}}" style="cursor: pointer;color: #337ab7;">{{people.project_organization_file_name}}</a>
            </span>
                </div>
                <div>
                    <p>
                        Upload a pdf <input [disabled]="evt" type="file" accept="application/pdf" multiple="false" (change)="mediaSelected1($event)" style="display: inline-block; font-family: OpenSans-Light;" />
                        <button [disabled]="clicked" (click)="clicked = true;mediaSelected(evt)" class="editToggle btn btn-default" *ngIf="evt"><i id="btn" class="glyphicon glyphicon-upload"></i> Upload</button>
                    </p>
                    <p style="font-style: italic;">Answer will also appear on project page in Media references section</p>
                    <div style="margin-bottom: 5px;" *ngFor="let item of collectionArticles; let i = index">
                        <div style="margin-bottom: 5px;">
                            <input spellcheck="true" type="text" id="articleUrl{{i}}" class="textareaClass" placeholder="Enter complete URL" value="{{item.reference_url}}" style="height: 25px;outline: none; border: none;" />
                        </div>
                        <div style="margin-bottom: 5px;">
                            <input spellcheck="true" type="text" id="articleText{{i}}" class="textareaClass" placeholder="Enter text to display" value="{{item.reference_desc}}" style="height: 25px;outline: none; border: none;" />
                        </div>
                        <div>
                            <input spellcheck="true" type="text" id="articleSource{{i}}" class="textareaClass" placeholder="Enter source" value="{{item.reference_source}}" style="height: 25px;outline: none; border: none;" />
                        </div>
                    </div>
                    <p>
                        <a class="awardsmore" (click)="addmoreArticles(track2)">Add more</a>
                    </p>
                </div>
            </div>
            <p class="title-top3">Video</p>
            <div *ngIf="external_video_edit" style="margin-bottom: 10px;">
                <!-- <div class="subtitles title-top3" style="font-size: 16px;">Video</div> -->
                <div class="col-md-12" style="float:none;text-align: right;">
                    <i (click)="deleteMedia()" class="glyphicon glyphicon-trash"></i>
                    <div class="intrinsic-container intrinsic-container-16x9" style="margin-left: 12px;text-align: right;">
                        <div style="width: 100%; height: 100%; position: absolute;background-color: lightgray;" [innerHTML]="external_video_iframe"></div>
                    </div>
                </div>
            </div>
            <div class="msg-content">
                <input type="text" placeholder="Enter complete Youtube/Vimeo video url" class="form-control" [(ngModel)]="videoURL" style="width: 90%; ">
                <button (click)="externalMediaSelected()" class="editToggle btn btn-default" style="margin-top: 5px;"><i id="btn11" class="glyphicon glyphicon-upload"></i> Upload</button>
            </div>
        </div>
    </div>
    <hr style="border-top: 1px solid #e0e0e0 !important;">
</div>
<style>
     :host ::ng-deep .abc br {
        line-height: 27px;
    }

    :host ::ng-deep iframe #player .vp-player-layout {
        left: 0 !important;
        right: 0 !important;
    }
    
    .intrinsic-container {
        position: relative;
        height: 0;
        overflow: hidden;
    }
    /* 16x9 Aspect Ratio */
    
    .intrinsic-container-16x9 {
        padding-bottom: 56.25%;
    }
    /* 4x3 Aspect Ratio */
    
    .intrinsic-container-4x3 {
        padding-bottom: 75%;
    }
    
    .glyphicon-refresh-animate {
        -animation: spin .7s infinite linear;
        -webkit-animation: spin2 .7s infinite linear;
    }
    
    @-webkit-keyframes spin2 {
        from {
            -webkit-transform: rotate(0deg);
        }
        to {
            -webkit-transform: rotate(360deg);
        }
    }
    
    @keyframes spin {
        from {
            transform: scale(1) rotate(0deg);
        }
        to {
            transform: scale(1) rotate(360deg);
        }
    }
    
    .imgParent {
        width: 100%;
        padding-top: 75%;
        position: relative;
        background: rgba(212, 212, 212, 0.4);
    }
    
    .imgParent img {
        cursor: pointer;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        max-height: 100%;
        max-width: 100%;
        display: block;
        margin: auto;
    }
    
    .awardsmore {
        cursor: pointer;
    }
    
    i {
        vertical-align: top;
        color: black;
        font-size: 13px;
    }
    
    select {
        height: 26px;
        padding: 0px 5px;
        border: 1px solid #ccc;
        width: 90%;
    }
    
    .editToggle {
        border-radius: 4px;
        background-color: #ffe27e;
        border: none;
        height: 30px;
        font-family: OpenSans-Bold;
        font-size: 0.9em;
        vertical-align: text-bottom;
    }
    
    .projectName {
        width: 85%;
        display: inline-block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    
    .people:not(:last-child):after {
        content: "|";
        padding: 0 10px;
    }
    
    .roles:not(:last-child):after {
        content: ",  "
    }
    
    .content-gray {
        color: gray;
        font-size: 13px;
    }
    
    div.fadeMe {
        opacity: 1;
        filter: alpha(opacity=20);
        background-color: #000;
        width: 100%;
        height: 100%;
        z-index: 10;
        top: 0;
        left: 0;
        position: fixed;
        text-align: center;
    }
    
    .not-selectable {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    
    .backgroundPhotoText {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        font-weight: 600;
        color: #656565;
        font-family: OpenSans-Regular;
        cursor: pointer;
    }
    
    .editToggle {
        border-radius: 4px;
        background-color: #ffe27e;
        border: none;
        height: 30px;
        font-family: OpenSans-Bold;
        font-size: 0.9em;
    }
    
    .editToggle1 {
        border: none;
        font-size: 12px;
        height: 30px;
        background-color: transparent;
        font-family: OpenSans-Bold;
        color: #656565;
        padding: 0 2px
    }
    
    #input {
        position: absolute;
        margin-top: -200px;
    }
    
    .imgDiv {
        position: absolute;
        margin-left: 30px;
        margin-top: -10px;
    }
    
    .editPic {
        position: absolute;
        top: 160px;
        left: 179px;
        text-align: center;
        background-color: #ffe27e;
        width: 30px;
        height: 30px;
    }
    
    .navbar {
        margin-bottom: 0 !important;
    }
    
    .exp-desc {
        color: #6d6d6d;
        font-size: 13px;
    }
    
    .usr-name {
        font-family: open-regular;
        margin-top: 20px;
        margin-bottom: 20px;
        /*padding-left: 50px;*/
    }
    
    .para-span {
        color: #444343;
        font-family: open-regular;
    }
    
    .para-title {
        padding-left: 30px;
        font-size: 13px;
        color: grey;
    }
    
    .stry-btn {
        background-color: #ffe27e;
        border: none;
        height: 30px;
        font-family: OpenSans-Bold;
        font-size: 0.9em;
    }
    
    .my-stry {
        display: inline-block;
        font-family: open-regular;
    }
    
    .div-pad_btm {
        padding-bottom: 15px;
    }
    
    .title-top3 {
        padding-left: 27px;
        font-family: OpenSans-Bold;
        font-size: 12px;
    }
    
    .title-top2 {
        padding-left: 27px;
        color: #898989;
        font-size: 13px;
        overflow-y: auto;
    }
    
    .title-top1 {
        font-family: OpenSans-Semibold;
        color: #767676;
        padding-left: 27px;
        font-size: 18px;
        margin: 0 0 1px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #4285f3;
    }
    
    .water-res {
        font-family: OpenSans-Semibold;
        color: #808080;
        margin: 0 0 0px;
        font-size: 14px;
    }
    
    .desc-top {
        padding-top: 55px;
        font-family: open-regular;
    }
    
    .msg-content {
        padding-left: 27px;
        color: #828282;
        font-size: 12px;
        padding-right: 9px;
        font-family: open-regular;
    }
    
    .img-second-title {
        margin-top: -10px;
        padding-bottom: 14px;
        font-family: open-regular;
        color: #898989;
        font-size: 13px;
    }
    
    .img-title {
        font-family: OpenSans-Bold;
        color: #777777;
        font-size: 13px;
    }
    
    .text {
        display: inline-block;
        width: calc(100% - 130px);
    }
    
    .img-grid {
        border: 5px solid #aae7c8;
        background-color: white;
    }
    
    .row-grid {
        border-bottom: 2px solid #aae7c8;
    }
    
    @media only screen and (max-width: 768px) {
        .img-grid {
            margin-bottom: 0px;
        }
    }
    
    .flight-img {
        /* border: 2px solid #ffe27e; */
        /* display: inline-block; */
        /* vertical-align: top; */
        height: 120px;
        /* width: 120px; */
    }
    
    .popover.right>.arrow:after {
        border-right-color: #ebebeb;
    }
    
    .popover-content {
        background-color: #ebebeb;
    }
    
    @font-face {
        font-family: open-regular;
        src: url('/assets/fonts/new/opensans-regular-webfont.eot?#iefix') format('embedded-opentype'), /* IE */
        url('/assets/fonts/new/opensans-regular-webfont.ttf') format("truetype");
        /* non-IE */
    }
    
    @font-face {
        font-family: OpenSans-Light;
        src: url('/assets/fonts/new/opensans-light-webfont.eot?#iefix') format('embedded-opentype'), /* IE */
        url('/assets/fonts/new/opensans-light-webfont.ttf') format("truetype");
        /* non-IE */
    }
    
    @font-face {
        font-family: OpenSans-Semibold;
        src: url('/assets/fonts/new/opensans-semibold-webfont.eot?#iefix') format('embedded-opentype'), /* IE */
        url('/assets/fonts/new/opensans-semibold-webfont.ttf') format("truetype");
        /* non-IE */
    }
    
    @font-face {
        font-family: OpenSans-Bold;
        src: url('/assets/fonts/new/opensans-bold-webfont.eot?#iefix') format('embedded-opentype'), /* IE */
        url('/assets/fonts/new/opensans-bold-webfont.ttf') format("truetype");
        /* non-IE */
    }
    
    .prjct-wored {
        font-family: open-regular;
        padding-bottom: 15px;
    }
    
    @media only screen and (max-width: 768px) {
        .prjct-wored {
            margin-top: 0px;
        }
        .text {
            width: 100% !important;
            margin-top: 10px !important;
        }
    }
    
    .arrow-left {
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-right: 10px solid blue;
    }
    
    .scroll::-webkit-scrollbar {
        width: 5px;
    }
    
    .scroll::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px #888;
    }
    
    .scroll::-webkit-scrollbar-thumb {
        background: #808080;
    }
    
    .scroll::-webkit-scrollbar-thumb:window-inactive {}
    
    textarea,
    .textareaClass {
        max-width: 500px;
        width: 90%;
        height: 100px;
        -moz-border-bottom-colors: none;
        -moz-border-left-colors: none;
        -moz-border-right-colors: none;
        -moz-border-top-colors: none;
        background: none repeat scroll 0 0 rgba(0, 0, 0, 0.07);
        border-color: -moz-use-text-color #FFFFFF #FFFFFF -moz-use-text-color;
        border-image: none;
        border-radius: 6px 6px 6px 6px;
        border-style: none solid solid none;
        border-width: medium 1px 1px medium;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12) inset;
        color: #555555;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 1em;
        line-height: 1.4em;
        padding: 5px 8px;
        transition: background-color 0.2s ease 0s;
    }
    
    textarea:focus,
    textareaClass:focus {
        background: none repeat scroll 0 0 #FFFFFF;
        outline-width: 0;
    }
</style>