import { Router, ActivatedRoute, NavigationEnd, Scroll } from '@angular/router';


import {
  Component,
  NgZone,
  AfterViewInit,
  Inject,
  PLATFORM_ID
} from '@angular/core';

import { NgProgress } from '@ngx-progressbar/core';

// import {
//   MapsAPILoader
// } from 'angular2-google-maps/core';
import { CookieService } from 'angular2-cookie/core';
import { Title, Meta } from '@angular/platform-browser';
import { DomService } from './dom.service';
import { FeedbackComponent } from './feedbackcomponent/feedback.component';
import { isPlatformBrowser, Location, ViewportScroller } from '@angular/common';
import { delay, filter } from 'rxjs/operators';
import { MetaService } from './new-repicture/shared/meta.service';
// import { NotificationBarModule, NotificationType, NotificationBarService } from 'angular2-notification-bar';
declare var google: any;
declare var ga: Function;
declare var gtag: Function;
declare var jQuery: any;

@Component({
  selector: 'my-app',
  template: `<ng-progress [spinnerPosition]="'right'" [thick]="true" [color]="'#78C60A'"></ng-progress>
  <app-alert-c></app-alert-c>

  <router-outlet></router-outlet>
  <button (click)="topFunction()" id="myBtn" title="Go to top">&#9650;</button>`
})
export class AppComponent {

  constructor(private router: Router, private ngProgress: NgProgress, private cookie: CookieService, private activatedRoute: ActivatedRoute, private titleService: Title, private domService: DomService, @Inject(PLATFORM_ID) private platform: any, private location: Location,viewportScroller: ViewportScroller, private metaService :MetaService ,private meta:Meta) {
    this.router.events.subscribe(event => {
      // this.ngProgress.start();
      if (event instanceof NavigationEnd) {

        // gtag('config', 'AW-815370562', {
        //   'page_path':event.urlAfterRedirects
        // });
        // console.log('beta', { property: 'og:url', content: 'https://www.repicture.com' + this.location.path() })
        this.metaService.updateUrl('https://www.repicture.com' + this.location.path());
        // this.ngProgress.done();
        if (isPlatformBrowser(this.platform)) {
          (<any>window).ga('set', 'page', event.urlAfterRedirects);
          (<any>window).ga('send', 'pageview');
          jQuery('.modal-backdrop').hide();
          sessionStorage.removeItem('query');
        }
        //adding meta tags dynamically for SEO
        // var root = this.router.routerState.snapshot.root;
    
        // while (root) {
        
 
        //   if (root.children && root.children.length) {
        //     root = root.children[0];
        //   } else if (root.data && root.data["title"]) {
        //     this.titleService.setTitle(root.data["title"] + " | RePicture");
        //     let tags = root.data["metatags"];
        //     for (let tag in tags) {
        //       console.log(tag);
        //       this.meta.updateTag({ name: tag, content: tags[tag] });
        //     }

        //     return;
        //   } else {
        //     return;
        //   }
        // }
      }
    });
    this.router.events
      .pipe(filter((e): e is Scroll => e instanceof Scroll))
      .pipe(delay(5))   // <--------------------------- This line
      .subscribe((e) => {
        if (e.position) {
          // backward navigation
          viewportScroller.scrollToPosition(e.position);
        } else if (e.anchor) {
          // anchor navigation
  
          viewportScroller.scrollToAnchor(e.anchor);
        } else {
          // forward navigation
          viewportScroller.scrollToPosition([0, 0]);
        }
      });
  }

  ngOnInit() {

    this.metaService.setTitle('RePicture STEM and Discover Careers Shaping the Future')
    this.metaService.updateTitle('RePicture STEM and Discover Careers Shaping the Future')
    this.metaService.updateDescription('RePicture is a Science, Technology, Engineering, and Mathematics (STEM) career accelerator platform.  RePicture helps you take control of your career destiny.')
    this.metaService.updateOgImage('assets/new-assets/repicture/repicture.png')

    
    // if (!this.cookie.get('FirstVisitToApp')) {
    //   this.notificationBar.create({
    //     message: 'Note to our users: This site is currently optimized for modern browsers. Please use Google Chrome for best results.',
    //     type: NotificationType.Success,
    //     allowClose: true,
    //     autoHide: false,
    //     hideDelay: 5000,
    //     hideOnHover: false
    //   });
    //   this.cookie.put('FirstVisitToApp', 'true');
    // }
  }

  // When the user clicks on the button, scroll to the top of the document
  topFunction() {
    document.body.scrollTop = 0; // For Chrome, Safari and Opera
    document.documentElement.scrollTop = 0; // For IE and Firefox
  }
}
