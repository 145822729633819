
import { Routes, RouterModule, ExtraOptions } from '@angular/router';

import { HomePageMapComponent } from './homepagemapcomponent/homepagemap.component';

import { LoginComponent } from './logincomponent/login.component';

import { CreateProfileComponent } from './createprofilecomponent/createprofile.component';

import { ProjectDetailComponent } from './projectdetailcomponent/projectdetail.component';
import { CompanyProfile } from './companyprofilecomponent/companyprofile.component';


import { ProjectCreate } from './projectcreatecomponent/projectcreate.component';
import { ProjectListComponent } from './projectlistcomponent/projectlist.component';
import { ListProjectComponent } from './listprojectcomponent/listproject.component';

import { LoginregComponent } from './loginregcomponent/loginreg.component';

import { UserProfileComponent } from './userprofilecomponent/userprofile.component';
import { UserListComponent } from './userlistcomponent/userlist.component';
import { ContactPage } from './contactpagecomponent/contactpage.component';
import { CompanyCreate } from './companycreatecomponent/createcompany.component';
import { ClaimCompany } from './companyclaimcomponent/claimcompany.component';
import { ClaimPerson } from './personclaimcomponent/claimperson.component';
import { Account } from './accountcomponent/account.component';
import { CreatePerson } from './createpersoncomponent/createperson.component';
import { CreateAccount } from './createaccountcomponent/createaccount.component';
import { Login } from './securelogincomponent/securelogin.component';
import { ManageProjects } from './manageprojectscomponent/manageprojects.component';
import { Agreement } from './agrrementcomponent/agreement.component';
import { FeedbackComponent } from './feedbackcomponent/feedback.component';
import { ForgotPassword } from './forgotpasswordcomponent/forgotpassword.component';
import { RestAuth } from './restauthlandingcomponent/restauth.component';
import { PaymentmaincomponentComponent } from './paymentmodule/paymentmaincomponent/paymentmaincomponent.component';
import { AuthGuardService as AuthGuard } from './auth-guard.service';
import { MessagingComponent } from './messaging/messaging.component';
import { CareerJobListingComponent } from './career/career-job-listing/career-job-listing.component';
import { StudentTeacherComponent } from './createaccountcomponent/student-teacher/student-teacher.component';
import { StudentDashboardComponent } from './student-dashboard/student-dashboard.component';
import { AddprojectlessonComponent } from './addprojectlesson/addprojectlesson.component';
import { LessonplansComponent } from './lessonplans/lessonplans.component';
import { TeacherStudentGuideComponent } from './lessonplans/teacher-student-guide/teacher-student-guide.component';
import { InspireComponent } from './inspire/inspire.component';
import { ChatComponent } from './chat/chat.component';
import { Covid19Component } from './covid19/covid19.component';
import { LinkedinResponseComponent } from './linkedin-login/linkedin-response/linkedin-response.component';
import { NgModule } from '@angular/core';
import { GetInTouchComponent } from './new-repicture/pages/get-in-touch/get-in-touch.component';
// import { PageNotFoundComponent } from './404';


export const appRoutes: Routes = [

  {
    path: '',
    loadChildren: () => import('./new-repicture/homepage/home.module').then(m => m.HomeModule),
  },
  {
    path: 'jobs/:slug',
    component: CareerJobListingComponent,

  },
  {
    path: 'createprofile',
    component: CreateProfileComponent,

  },
  {
    path: 'linkedinresponse',
    loadChildren: () => import('./linkedin-login/linkedin-login.module').then(m => m.LinkedinLoginModule)
  },

  {
    path: 'login',
    component: Login,
    data: {
      title: 'Login',
      metatags: {
        name: 'Repicture App',
        // description: 'repicture login.',
        keywords: 'repicture login, RePicture login'
      }
    }
  },

  {
    path: 'about',
  
    loadChildren: () => import('./new-repicture/aboutuspage/aboutus.module').then(m => m.AboutusModule)


  },

  {

    path: 'accelerator',
    loadChildren: () => import('./new-repicture/accelerator/accelerator.module').then(m => m.AcceleratorModule)

  },


  {
    path:'get-in-touch',
    component: GetInTouchComponent
  },

  {
    path: 'lessonplans',
    component: ListProjectComponent,
  },

  {
    path: 'contact',
    component: ContactPage,
    data: {
      title: 'Contact',
      metatags: {
        name: 'Repicture App',
        // description: 'repicture contact',
        keywords: 'repicture contact, RePicture contact'
      }
    }

  },

  // {

  //   path: 'project/:id/:flag',
  //   component: ProjectDetailComponent,

  // },

  {

    path: 'project',
    // component: ProjectDetailComponent,
    loadChildren: () => import('./detail/detail.module').then(m => m.DetailModule)

  },

  // {

  //   path: 'students',
  //   // component: ProjectDetailComponent,
  //   loadChildren: () => import('./intro/intro.module').then(m => m.IntroModule)

  // },
  // {

  //   path: 'accelerator',
  //   // component: ProjectDetailComponent,
  //   loadChildren: () => import('./intro/intro.module').then(m => m.IntroModule)

  // },
  
  // {

  //   path: 'educators',
  //   // component: ProjectDetailComponent,
  //   loadChildren: () => import('./intro/intro.module').then(m => m.IntroModule)

  // },
  // {

  //   path: 'colleges',
  //   // component: ProjectDetailComponent,
  //   loadChildren: () => import('./intro/intro.module').then(m => m.IntroModule)

  // },
  // {

  //   path: 'jobseekers',
  //   // component: ProjectDetailComponent,
  //   loadChildren: () => import('./intro/intro.module').then(m => m.IntroModule)

  // },
  // {

  //   path: 'professionals',
  //   // component: ProjectDetailComponent,
  //   loadChildren: () => import('./intro/intro.module').then(m => m.IntroModule)

  // },
  // {

  //   path: 'employers',
  //   // component: ProjectDetailComponent,
  //   loadChildren: () => import('./intro/intro.module').then(m => m.IntroModule)

  // },

  //    {


  //    path: '',

  //    component: ProjectDetailComponent,

  //  },

  {


    path: 'explorer/:type',

    component: ListProjectComponent
  },

  {
    path: 'jobs',

    component: ListProjectComponent,
    data: {
      title: 'Civil Engineering Career Advice, Engineering Jobs',
      metatags: {
        name: 'Civil Engineering Career Advice, Engineering Jobs',
        description: 'Learn about different engineering careers, find first engineering job, entry level  jobs, and engineering career advice',
        keywords: 'repicture jobs, careers, engineering, civil, RePicture, RePicture Engineering'
      },
      kind:'jobs'
    }
  },

  {
    path: 'chat',

    component: ListProjectComponent,
    data: {
      title: 'Engineering Career Advice',
      metatags: {
        name: 'Engineering Career Advice',
        description: 'Do you have questions about engineering?  Chat with engineers, get engineering career advice, learn about first engineering jobs',
        keywords: 'repicture groups, engineering, civil, RePicture, RePicture Engineering'
      }
    }
  },

  {
    path: 'chat/:groupname',

    component: MessagingComponent,
  },

  {
    path: 'chat/:groupname/:topicname',

    component: MessagingComponent,
  },

  // {


  //   path: '',

  //   component: ListProjectComponent,

  // },

  {


    path: 'listprojects',

    component: ProjectListComponent,
  },

  // {


  //   path: '',

  //   component: ProjectListComponent,

  // },



  {


    path: 'createproject/:id',

    component: ProjectCreate,
    data: {
      title: 'Create project',
      metatags: {
        name: 'Repicture App',
        // description: 'repicture create project',
        // keywords: 'repicture create project, RePicture create project'
      }
    }
  },


  {


    path: 'createproject',

    component: ProjectCreate,
    data: {
      title: 'Create project',
      metatags: {
        name: 'Repicture App',
        // description: 'repicture create project',
        keywords: 'repicture create project, RePicture create project'
      }
    }
  },

  {


    path: 'createcompany',

    component: CompanyCreate,

  },

  //  {


  //    path: '',

  //    component: CompanyCreate,

  //  },


  // {


  //    path: '',

  //    component: ProjectCreate,

  //  },

  //  {


  //    path: '',

  //    component: CompanyProfile,

  //  },

  {


    path: 'companyprofile/:id',

    component: CompanyProfile,
    data: {
      title: 'Company Profile',
      metatags: {
        keywords: 'companies, engineering companies, repicture, repicture company, organization, organisation'
      }
    }
  },

  {


    path: 'userprofile/:id',

    component: UserProfileComponent,
    data: {
      title: 'User profile',
      metatags: {
        keywords: 'engineers, repicture, person, app user'
      }
    }
  },

  //  {


  //    path: '',

  //    component: UserProfileComponent,

  //  },

  {
    path: 'userlist',

    component: UserListComponent
  },
  {
    path: 'manageprojects',

    component: ManageProjects
  },

  {
    path: 'account',

    component: Account
  },

  {
    path: 'createperson',

    component: CreatePerson
  },

  {
    path: ':collegename/students/createaccount',

    component: StudentTeacherComponent
  },

  {
    path: 'createaccount',

    component: CreateAccount,
    data: {
      title: 'Create account',
      metatags: {
        name: 'Repicture App',
        // description: 'repicture signup register',
        keywords: 'repicture signup register, RePicture signup register'
      }
    }
  },

  {
    path: 'createaccount/:id',

    component: CreateAccount,
    data: {
      title: 'Create account'
    }
  },

  {
    path: 'claimcompany',

    component: ClaimCompany
  },

  {
    path: 'claimperson',

    component: ClaimPerson
  },

  {
    path: 'feedback',

    component: FeedbackComponent
  },

  {
    path: 'agreement',

    component: Agreement
  },

  {
    path: 'reset/:uid/:key',

    component: ForgotPassword
  },

  {
    path: 'rest-auth/registration/account-confirm-email/:key',

    component: RestAuth
  },
  {
    path: 'payment',

    component: PaymentmaincomponentComponent,

    canActivate: [AuthGuard]
  },

  {
    path: 'messaging',

    component: MessagingComponent,

    // canActivate: [AuthGuard]
  },

  {
    path: 'dashboard/:id',

    component: StudentDashboardComponent,

  },

  {
    path: 'lessonplans/:type',

    component: TeacherStudentGuideComponent,

  },
  {
    path: 'covid19',

    component: Covid19Component,

  },
  {
    path: 'covid19DCjobs',

    component: Covid19Component,

  },
  {
    path: 'addprojectlesson',

    component: AddprojectlessonComponent

  },
  {
    path: 'inspire',

    component: InspireComponent,
    data: {
      title: 'Engineers and Scientists: Tell Your Story',
    }

  },

  {
    path: 'people',
    redirectTo: '/explorer/people'
  },

  {
    path: 'projects',
    redirectTo: '/explorer/projects'
  },

  {
    path: 'org',
    redirectTo: '/explorer/org'
  },
//   {
//     component: PageNotFoundComponent,
//     path: '404',
// },
// {
//     path: '**',
//     redirectTo: '404'
// }

];


export const routing :ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  initialNavigation:'enabled'
};
 