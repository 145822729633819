/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./projectsorder.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/cdk/drag-drop";
import * as i3 from "@angular/common";
import * as i4 from "@angular/cdk/bidi";
import * as i5 from "./projectsorder.component";
import * as i6 from "../projectservice";
import * as i7 from "@angular/router";
var styles_ProjectsorderComponent = [i0.styles];
var RenderType_ProjectsorderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProjectsorderComponent, data: {} });
export { RenderType_ProjectsorderComponent as RenderType_ProjectsorderComponent };
function View_ProjectsorderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 8, "div", [["cdkDrag", ""], ["class", "example-box cdk-drag"]], [[2, "cdk-drag-disabled", null], [2, "cdk-drag-dragging", null]], null, null, null, null)), i1.ɵprd(6144, null, i2.ɵb, null, [i2.CdkDrag]), i1.ɵdid(2, 4866048, [[1, 4]], 3, i2.CdkDrag, [i1.ElementRef, [3, i2.CDK_DROP_LIST], i3.DOCUMENT, i1.NgZone, i1.ViewContainerRef, i2.CDK_DRAG_CONFIG, [2, i4.Directionality], i2.DragDrop, i1.ChangeDetectorRef], null, null), i1.ɵqud(603979776, 2, { _handles: 1 }), i1.ɵqud(603979776, 3, { _previewTemplate: 0 }), i1.ɵqud(603979776, 4, { _placeholderTemplate: 0 }), (_l()(), i1.ɵeld(6, 0, null, null, 0, "img", [["alt", ""], ["style", "width: 60px;height: 60px;border-radius: 50%;"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "p", [["style", "padding: 0px 20px;margin: 0;"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵand(0, null, null, 0))], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).disabled; var currVal_1 = i1.ɵnov(_v, 2)._dragRef.isDragging(); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.project_images[0].image_thumbnail, ""); _ck(_v, 6, 0, currVal_2); var currVal_3 = _v.context.$implicit.project_name; _ck(_v, 8, 0, currVal_3); }); }
function View_ProjectsorderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Examples of Projects I've Worked On"])), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["cdkDropList", ""], ["class", "example-list cdk-drop-list"]], [[8, "id", 0], [2, "cdk-drop-list-disabled", null], [2, "cdk-drop-list-dragging", null], [2, "cdk-drop-list-receiving", null]], [[null, "cdkDropListDropped"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("cdkDropListDropped" === en)) {
        var pd_0 = (_co.dropPeopleProject($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(6144, null, i2.CDK_DROP_LIST, null, [i2.CdkDropList]), i1.ɵdid(5, 1196032, null, 1, i2.CdkDropList, [i1.ElementRef, i2.DragDrop, i1.ChangeDetectorRef, [2, i4.Directionality], [3, i2.CdkDropListGroup]], null, { dropped: "cdkDropListDropped" }), i1.ɵqud(603979776, 1, { _draggables: 1 }), i1.ɵprd(256, null, i2.CdkDropListGroup, undefined, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectsorderComponent_2)), i1.ɵdid(9, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.mainArray["pr_user_ordering"]; _ck(_v, 9, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).id; var currVal_1 = i1.ɵnov(_v, 5).disabled; var currVal_2 = i1.ɵnov(_v, 5)._dropListRef.isDragging(); var currVal_3 = i1.ɵnov(_v, 5)._dropListRef.isReceiving(); _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
function View_ProjectsorderComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 8, "div", [["cdkDrag", ""], ["class", "example-box cdk-drag"]], [[2, "cdk-drag-disabled", null], [2, "cdk-drag-dragging", null]], null, null, null, null)), i1.ɵprd(6144, null, i2.ɵb, null, [i2.CdkDrag]), i1.ɵdid(2, 4866048, [[5, 4]], 3, i2.CdkDrag, [i1.ElementRef, [3, i2.CDK_DROP_LIST], i3.DOCUMENT, i1.NgZone, i1.ViewContainerRef, i2.CDK_DRAG_CONFIG, [2, i4.Directionality], i2.DragDrop, i1.ChangeDetectorRef], null, null), i1.ɵqud(603979776, 6, { _handles: 1 }), i1.ɵqud(603979776, 7, { _previewTemplate: 0 }), i1.ɵqud(603979776, 8, { _placeholderTemplate: 0 }), (_l()(), i1.ɵeld(6, 0, null, null, 0, "img", [["alt", ""], ["style", "width: 60px;height: 60px;border-radius: 50%;"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "p", [["style", "padding: 0px 20px;margin: 0;"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵand(0, null, null, 0))], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).disabled; var currVal_1 = i1.ɵnov(_v, 2)._dragRef.isDragging(); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.imageurl, ""); _ck(_v, 6, 0, currVal_2); var currVal_3 = _v.context.$implicit.name; _ck(_v, 8, 0, currVal_3); }); }
function View_ProjectsorderComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Projects I've Authored on RePicture"])), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["cdkDropList", ""], ["class", "example-list cdk-drop-list"]], [[8, "id", 0], [2, "cdk-drop-list-disabled", null], [2, "cdk-drop-list-dragging", null], [2, "cdk-drop-list-receiving", null]], [[null, "cdkDropListDropped"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("cdkDropListDropped" === en)) {
        var pd_0 = (_co.dropProjectAdopted($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(6144, null, i2.CDK_DROP_LIST, null, [i2.CdkDropList]), i1.ɵdid(5, 1196032, null, 1, i2.CdkDropList, [i1.ElementRef, i2.DragDrop, i1.ChangeDetectorRef, [2, i4.Directionality], [3, i2.CdkDropListGroup]], null, { dropped: "cdkDropListDropped" }), i1.ɵqud(603979776, 5, { _draggables: 1 }), i1.ɵprd(256, null, i2.CdkDropListGroup, undefined, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectsorderComponent_4)), i1.ɵdid(9, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.mainArray["adopted"]; _ck(_v, 9, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).id; var currVal_1 = i1.ɵnov(_v, 5).disabled; var currVal_2 = i1.ɵnov(_v, 5)._dropListRef.isDragging(); var currVal_3 = i1.ɵnov(_v, 5)._dropListRef.isReceiving(); _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
function View_ProjectsorderComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 8, "div", [["cdkDrag", ""], ["class", "example-box cdk-drag"]], [[2, "cdk-drag-disabled", null], [2, "cdk-drag-dragging", null]], null, null, null, null)), i1.ɵprd(6144, null, i2.ɵb, null, [i2.CdkDrag]), i1.ɵdid(2, 4866048, [[9, 4]], 3, i2.CdkDrag, [i1.ElementRef, [3, i2.CDK_DROP_LIST], i3.DOCUMENT, i1.NgZone, i1.ViewContainerRef, i2.CDK_DRAG_CONFIG, [2, i4.Directionality], i2.DragDrop, i1.ChangeDetectorRef], null, null), i1.ɵqud(603979776, 10, { _handles: 1 }), i1.ɵqud(603979776, 11, { _previewTemplate: 0 }), i1.ɵqud(603979776, 12, { _placeholderTemplate: 0 }), (_l()(), i1.ɵeld(6, 0, null, null, 0, "img", [["alt", ""], ["style", "width: 60px;height: 60px;border-radius: 50%;"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "p", [["style", "padding: 0px 20px;margin: 0;"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵand(0, null, null, 0))], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).disabled; var currVal_1 = i1.ɵnov(_v, 2)._dragRef.isDragging(); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.imageurl, ""); _ck(_v, 6, 0, currVal_2); var currVal_3 = _v.context.$implicit.name; _ck(_v, 8, 0, currVal_3); }); }
function View_ProjectsorderComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Projects I've Authored With Others"])), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["cdkDropList", ""], ["class", "example-list cdk-drop-list"]], [[8, "id", 0], [2, "cdk-drop-list-disabled", null], [2, "cdk-drop-list-dragging", null], [2, "cdk-drop-list-receiving", null]], [[null, "cdkDropListDropped"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("cdkDropListDropped" === en)) {
        var pd_0 = (_co.dropProjectAdoptedWithOthers($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(6144, null, i2.CDK_DROP_LIST, null, [i2.CdkDropList]), i1.ɵdid(5, 1196032, null, 1, i2.CdkDropList, [i1.ElementRef, i2.DragDrop, i1.ChangeDetectorRef, [2, i4.Directionality], [3, i2.CdkDropListGroup]], null, { dropped: "cdkDropListDropped" }), i1.ɵqud(603979776, 9, { _draggables: 1 }), i1.ɵprd(256, null, i2.CdkDropListGroup, undefined, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectsorderComponent_6)), i1.ɵdid(9, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.mainArray["adopted_with_others"]; _ck(_v, 9, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).id; var currVal_1 = i1.ɵnov(_v, 5).disabled; var currVal_2 = i1.ɵnov(_v, 5)._dropListRef.isDragging(); var currVal_3 = i1.ɵnov(_v, 5)._dropListRef.isReceiving(); _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
export function View_ProjectsorderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectsorderComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectsorderComponent_3)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectsorderComponent_5)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.mainArray["pr_user_ordering"].length > 0); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.mainArray["adopted"].length > 0); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.mainArray["adopted_with_others"].length > 0); _ck(_v, 5, 0, currVal_2); }, null); }
export function View_ProjectsorderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-projectsorder", [], null, null, null, View_ProjectsorderComponent_0, RenderType_ProjectsorderComponent)), i1.ɵdid(1, 573440, null, 0, i5.ProjectsorderComponent, [i6.ProjectService, i7.ActivatedRoute], null, null)], null, null); }
var ProjectsorderComponentNgFactory = i1.ɵccf("app-projectsorder", i5.ProjectsorderComponent, View_ProjectsorderComponent_Host_0, { mainArray: "mainArray" }, {}, []);
export { ProjectsorderComponentNgFactory as ProjectsorderComponentNgFactory };
