import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-read-more',
  template: `
      <a *ngIf="isCollapsed" (click)="isCollapsed = !isCollapsed">read more</a>
      <div [class.collapsed]="isCollapsed" class="text-left">
      <br>
        <ng-content></ng-content>
        <a *ngIf="!isCollapsed" (click)="isCollapsed = !isCollapsed">read less</a>
      </div>
  `,
  styles: [`
      div.collapsed {
        line-height: 2.5ex;
        height: 0ex;
        overflow: hidden;
      }

      a {
        cursor: pointer;
        margin-left: 4px;
      }
  `]
})

export class ReadMoreComponent {
  isCollapsed = true;
  @Input() datatext: any;
}
