// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = { 
  production: false,
  envName : "local",
  baseurl :"https://beta-api.repicture.com",
  stripeKey :"pk_test_51KeITUB5ziCoLQ7PnbPQyfBdwTNxpwOqdpfPWwDr8AmpRJWJVXtIttEeTuCsOle0XCcrWsMk8mNsqnmjEKV6GcVJ003dHdbdTW"
};

//just to test prod api
// export const environment = {
//   production: false,
//   baseurl :"https://repicture-api.repicture.com",
//   stripeKey :"pk_test_51KeITUB5ziCoLQ7PnbPQyfBdwTNxpwOqdpfPWwDr8AmpRJWJVXtIttEeTuCsOle0XCcrWsMk8mNsqnmjEKV6GcVJ003dHdbdTW"
// };
// pk_live_51KeITUB5ziCoLQ7PD3w1xWER1lJKvybjSaGbTNj58cyVFbBizMPdylXm5q7xfphL4sTDpUDJrcTA6AeoXIbXJQZi00Mkvp6xee

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
