import { Component, OnInit } from '@angular/core';
import {
  ProjectService
} from './../projectservice';

import { Subscription } from 'rxjs';
// import 'rxjs/add/observable/timer;
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
import { Router } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-alert-c',
  templateUrl: './alert-c.component.html',
  styleUrls: ['./alert-c.component.css'],
  animations: [
    trigger('hideShowAnimator', [
      state('true', style({ opacity: 1 })),
      state('false', style({ opacity: 0 })),
      transition('0 => 1', animate('.5s')),
      transition('1 => 0', animate('.9s'))
    ])
  ]
})
export class AlertCComponent implements OnInit {




  title = '';
  description: SafeHtml;
  typeoferror = ['alert-warning', 'alert-success', 'alert-info',
    'alert-danger', 'alert-primary'
    , 'alert-secondary'
    , 'alert-light', 'alert-dark'];
  showAlert = false;
  typeoferrorIndex = 0;
  subscription: Subscription;


  constructor(private obj: ProjectService, private router: Router, public _sanitizer: DomSanitizer) {



  }

  ngOnInit() {

    this.obj.currentAlert.subscribe(data => {
      console.log('from main', data);
      this.title = data.title;
      this.description = this._sanitizer.bypassSecurityTrustHtml(data.desc);
      this.typeoferrorIndex = data.typeofalert;
      this.showhideAlert(true);



    },
      error => {
        console.log(error);
      }


    );



  }

  clickedelem(evt) {
    if (event.target['className'] == 'routerlink') {
      console.log(evt);
      let route = evt.target.attributes['attr.link'].value;
      this.showAlert = false;
      this.router.navigate([route]);
    }
  }

  showhideAlert(showAlert: boolean) {

    this.showAlert = showAlert;

      setTimeout(() => {
        
        this.showAlert = false;
      }, 25000);
   
  }

  dismissAlert() {
    this.showAlert = false;
    // this.subscription.unsubscribe();
  }

}
