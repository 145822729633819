/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./inspire.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./inspire.component";
import * as i3 from "@angular/platform-browser";
import * as i4 from "../projectservice";
var styles_InspireComponent = [i0.styles];
var RenderType_InspireComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InspireComponent, data: {} });
export { RenderType_InspireComponent as RenderType_InspireComponent };
export function View_InspireComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "iframe", [["src", "assets/pdfs/homepage/InspireStudents.pdf"]], null, null, null, null, null))], null, null); }
export function View_InspireComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-inspire", [], null, null, null, View_InspireComponent_0, RenderType_InspireComponent)), i1.ɵdid(1, 114688, null, 0, i2.InspireComponent, [i3.Meta, i3.Title, i4.ProjectService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InspireComponentNgFactory = i1.ɵccf("app-inspire", i2.InspireComponent, View_InspireComponent_Host_0, {}, {}, []);
export { InspireComponentNgFactory as InspireComponentNgFactory };
