var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { MetaService } from './../new-repicture/shared/meta.service';
import { DesignUtilityService } from './../new-repicture/shared/design-utility.service';
import { ExplorerService } from './explorer.service';
import { SubscriptionsContainer } from './../new-repicture/subscriptions-container';
import { ViewContainerRef, ElementRef, ChangeDetectorRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Location } from '@angular/common';
import { isPlatformBrowser } from '@angular/common';
// import { ScrollEvent } from 'ngx-scroll-event';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ProjectService } from './../projectservice';
import * as $ from 'jquery';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
export class ListProjectComponent {
    constructor(du, service, router, explorerService, route, titleService, viewContainerRef, cd, metaService, platform, locationObj) {
        this.du = du;
        this.service = service;
        this.router = router;
        this.explorerService = explorerService;
        this.route = route;
        this.titleService = titleService;
        this.viewContainerRef = viewContainerRef;
        this.cd = cd;
        this.metaService = metaService;
        this.platform = platform;
        this.locationObj = locationObj;
        this.selectedItemId = 1;
        this.url1 = this.service.url + "/api-v1/projects/redis-list/";
        this.url = this.service.url + "/api-v1/projects/search/";
        this.userProfileURL = this.service.url + "/api-v1/projects/redis-user/";
        this.companyProfileURL = this.service.url + "/api-v1/organizations/listings/";
        this.groupChatsURL = this.service.url + "/api-v1/groups/grouplist/";
        this.projectFilterURL = this.service.url + "/api-v1/projects/filter/";
        this.peopleFilterURL = this.service.url + "/api-v1/projects/peoplefilter/";
        this.orgFilterURL = this.service.url + "/api-v1/organizations/filter/";
        this.chatFilterURL = this.service.url + "/api-v1/groups/groupfilter/";
        this.jobsFilterURL = this.service.url + "/api-v1/postings/jobfilter/";
        this.collection = [];
        this.collection_people = [];
        this.collection_companies = [];
        this.selectbox = "projects";
        this.noData = false;
        this.noDataString = "projects";
        this.loading = false;
        this.loading2 = false;
        this.homepageSearch = false;
        this.noDataHomepageSearch = false;
        this.displayPag = false;
        this.hasMedia = false;
        this.hasAudio = false;
        this.hasVideo = false;
        this.val = "";
        this.chips = [];
        this.jobs = [];
        this.project_filters = {
            search: "",
            project_location: "",
            project_discipline: "",
            sort_by: "",
            project_completion_date: "",
            project_video_references: false,
            project_type: [],
            project_type_other: "",
            project_sub_type: [],
            project_status: "",
            project_cost: "",
            project_awards_name: [],
            project_funding_type: [],
            project_tags: "",
            project_details_title: [],
            project_institution: [],
        };
        this.project_filters_names = {
            search: "Keywords",
            project_location: "Location",
            project_discipline: "Project Discipline",
            sort_by: "Sort by",
            project_completion_date: "Year Completed",
            project_video_references: "Has video",
            project_type: "Project Type",
            project_type_other: "",
            project_sub_type: "Project Subtype",
            project_status: "Project Status",
            project_cost: "Project Cost",
            project_awards_name: "Awards Received",
            award_other: "",
            project_funding_type: "Funding Type",
            project_tags: "Tags",
            project_details_title: "Project Details",
            project_institution: "Project Institution",
        };
        this.people_filters = {
            search: "",
            user_experience: "",
            career_status: [],
            sort_by: "",
            user_degrees: [],
            user_degrees_other: "",
            user_designation: "",
            has_projects: false,
            external_video: false,
            social_network: false,
            user_education: "",
            profile_q: "",
            field_of_study: "",
        };
        this.org_filters = {
            search: "",
            org_hq_location: "",
            org_type: "",
            sort_by: "",
            pe_inhouse: false,
            org_size: "",
            org_services: "",
            org_certification: [],
            certifications_other: "",
            certi_location: "",
            org_hiring: "",
            org_commitments: "",
            org_is_enhanced: false,
        };
        this.chat_filters = {
            group_name: "",
            topic_name: "",
            sort_by: "",
            is_private: false,
            group_type__iexact: ""
        };
        this.job_filters = {
            search: "",
            job_location: "",
            sort_by: "",
        };
        this.projectTypeOptions = [
            { id: "ENV", name: "Water/Environmental" },
            { id: "TRN", name: "Transportation" },
            { id: "STR", name: "Building/Structural" },
            { id: "SFT", name: "Software" },
            { id: "PRO", name: "Product" },
            { id: "OTR", name: "Other" },
        ];
        this.certifications = [
            {
                id: "Fundamentals of Engineering (FE)",
                name: "Fundamentals of Engineering (FE)",
            },
            { id: "Professional Engineer (PE)", name: "Professional Engineer (PE)" },
            { id: "Structural Engineer (SE)", name: "Structural Engineer (SE)" },
            { id: "Registered Engineer (RE)", name: "Registered Engineer (RE)" },
            {
                id: "International Professional Engineer (IntPE)",
                name: "International Professional Engineer (IntPE)",
            },
            { id: "Chartered Engineer (CEng)", name: "Chartered Engineer (CEng)" },
            {
                id: "Incorporated Engineer (IEng)",
                name: "Incorporated Engineer (IEng)",
            },
            {
                id: "Engineering Technician (EngTech)",
                name: "Engineering Technician (EngTech)",
            },
            {
                id: "Information and Communications Technology Technicians (ICTTech)",
                name: "Information and Communications Technology Technicians (ICTTech)",
            },
            {
                id: "Project Management Professional (PMP)",
                name: "Project Management Professional (PMP)",
            },
            { id: "LEED AP", name: "LEED AP" },
            { id: "LEED Green Associate", name: "LEED Green Associate" },
            { id: "Other", name: "Other" },
        ];
        this.awardsOptions = [
            { id: "show_all", name: "Show all award winning projects" },
            {
                id: "ACEC Engineering Excellence Award",
                name: "ACEC Engineering Excellence Award",
            },
            { id: "ASCE Historic Landmark", name: "ASCE Historic Landmark" },
            { id: "OTR", name: "Other" },
        ];
        this.projectDetailsOptions = [
            { id: "aboutProject", name: "About this project" },
            {
                id: "benefitfromProject",
                name: "Who benefits from this project, and how?",
            },
            { id: "envmtProject", name: "Environmental considerations" },
            { id: "uniqueaboutProject", name: "What's unique about this project?" },
        ];
        this.educationalProgram = [
            { id: "antioch", name: "Antioch High School, CA (2019)" },
            { id: "berkeley", name: "Uni of California, Berkeley (2019)" },
            { id: "cwru", name: "Case Western Structural" },
            { id: "udc", name: "Uni of DC (2020)" },
        ];
        this.projectSubtypeOptions = [];
        this.type1 = [
            { id: "FS", name: "Flood Study" },
            { id: "SR", name: "Stream Restoration" },
            { id: "SM", name: "Stormwater Management" },
            { id: "ES", name: "Erosion & Sediment Control" },
            { id: "WS", name: "Water Supply" },
            { id: "WR", name: "Water Reuse" },
            { id: "WW", name: "Wastewater" },
        ];
        this.type2 = [
            { id: "HP", name: "Hospitality" },
            { id: "RS", name: "Residential" },
            { id: "MU", name: "Mixed-Use" },
            { id: "CM", name: "Commercial" },
            { id: "OS", name: "Office Space" },
        ];
        this.type3 = [
            { id: "RW", name: "Roadway" },
            { id: "SW", name: "Subway" },
            { id: "BD", name: "Bridge" },
            { id: "PW", name: "Pedestrian Walkway" },
            { id: "BP", name: "Bike Path" },
        ];
        this.fundingSourceOptions = [
            { id: "Public", name: "Public" },
            { id: "Private", name: "Private" },
        ];
        this.userDegreesOptions = [
            { id: "Bachelor's Degree", name: "Bachelor's Degree" },
            { id: "Bachelor of Arts (B.A.)", name: "Bachelor of Arts (B.A.)" },
            { id: "Bachelor of Science (B.S.)", name: "Bachelor of Science (B.S.)" },
            { id: "Bachelor of Fine Arts (BFA)", name: "Bachelor of Fine Arts (BFA)" },
            {
                id: "Bachelor of Applied Science (BAS)",
                name: "Bachelor of Applied Science (BAS)",
            },
            {
                id: "Bachelor of Engineering (B.E.)",
                name: "Bachelor of Engineering (B.E.)",
            },
            { id: "Master's Degree", name: "Master's Degree" },
            { id: "Master of Arts (M.A.)", name: "Master of Arts (M.A.)" },
            { id: "Master of Science (M.S.)", name: "Master of Science (M.S.)" },
            {
                id: "Master of Engineering (M.E.)",
                name: "Master of Engineering (M.E.)",
            },
            { id: "Doctoral Degree", name: "Doctoral Degree" },
            {
                id: "Doctor of Philosophy (Ph.D.)",
                name: "Doctor of Philosophy (Ph.D.)",
            },
            { id: "Doctor of Education (Ed.D.)", name: "Doctor of Education (Ed.D.)" },
            { id: "Juris Doctorate (J.D.)", name: "Juris Doctorate (J.D.)" },
            { id: "Associate's Degree ", name: "Associate's Degree " },
            { id: "Associate of Arts (A.A.)", name: "Associate of Arts (A.A.)" },
            { id: "Associate of Science (A.S.)", name: "Associate of Science (A.S.)" },
            {
                id: "Associate of Applied Science (A.A.S.)",
                name: "Associate of Applied Science (A.A.S.)",
            },
            { id: "Other", name: "Other" },
        ];
        this.orgCertifications = [
            { id: "show_all", name: "Any Certification" },
            {
                id: "Disadvantaged Business Enterprise (DBE)",
                name: "Disadvantaged Business Enterprise (DBE)",
            },
            {
                id: "Disabled Veteran Business Enterprise (DVBE)",
                name: "Disabled Veteran Business Enterprise (DVBE)",
            },
            {
                id: "Local Business Enterprise (LBE)",
                name: "Local Business Enterprise (LBE)",
            },
            {
                id: "Minority Business Enterprise (MBE)",
                name: "Minority Business Enterprise (MBE)",
            },
            {
                id: "Small Business Enterprise (SBE)",
                name: "Small Business Enterprise (SBE)",
            },
            {
                id: "Small Disadvantaged Business (SDB)",
                name: "Small Disadvantaged Business (SDB)",
            },
            {
                id: "Underutilized Disadvantaged Business Enterprise (UDBE)",
                name: "Underutilized Disadvantaged Business Enterprise (UDBE)",
            },
            {
                id: "Women Business Enterprise (WBE)",
                name: "Women Business Enterprise (WBE)",
            },
            {
                id: "Veteran Business Enterprise (VBE)",
                name: "Veteran Business Enterprise (VBE)",
            },
            { id: "8(a)", name: "8(a)" },
            { id: "Other", name: "Other" },
        ];
        this.careerStatusOptions = [
            {
                id: "Currently working as engineer",
                name: "Currently working as engineer",
            },
            {
                id: "Currently working in engineering-related field",
                name: "Currently working in engineering-related field",
            },
            {
                id: "Previously worked as engineer",
                name: "Previously worked as engineer",
            },
            {
                id: "Previously worked in engineering-related field",
                name: "Previously worked in engineering-related field",
            },
            {
                id: "Never worked as engineer or in engineering-related field",
                name: "Never worked as engineer or in engineering-related field",
            },
            {
                id: "Looking for an engineering job",
                name: "Looking for an engineering job",
            },
        ];
        this.filter_all_cleared = false;
        this.ddSearchVal = "projects";
        this.filterOpened = false;
        this.exp = [
            "0 - 2",
            "2 - 5",
            "5 - 10",
            "10 - 20",
            "20+",
            "Retired",
            "College Student: Graduating within 1 year",
            "College Student: Graduating more than 1 year",
            "High School Student",
        ];
        this.submitted = false;
        this.chatCollection = [];
        this.lineofwork = [
            "Aerospace Engineer",
            "Biomedical Engineer",
            "Chemical Engineer",
            "Civil Engineer",
            "Computer Hardware Engineer",
            "Electrical Engineer",
            "Electronics Engineer",
            "Environmental Engineer",
            "Industrial Engineer",
            "Materials Engineer",
            "Mechanical Engineer",
            "Petroleum Engineer",
            "Software Engineer",
            "Other Engineer",
            "Architect",
            "Biologist",
            "Chemist",
            "Computer Scientist",
            "Environmental Scientist",
            "Landscape Architect",
            "Planner",
            "Project Manager",
            "Surveyor",
            "Other",
        ];
        this.subs = new SubscriptionsContainer();
        this.hideAddElements = false;
        this.isLoading = this.du.isLoading.asObservable();
        this.scrollDisabled = false;
        this.clear_filter = false;
        console.log("constr");
    }
    tabChanged(id) {
        switch (id) {
            case 1:
                console.log(id);
                break;
            case 2:
                console.log(id);
                break;
            case 3:
                console.log(id);
                break;
            default:
                break;
        }
    }
    getProjectsNew() {
        this.explorerService.getProjects(this.explorerService.projectUrl);
    }
    getPeopleNew() {
        this.explorerService.getPeople(this.explorerService.peopleUrl);
    }
    getOrgNew() {
        this.explorerService.getOrgs(this.explorerService.orgUrl);
    }
    setProjects() {
        this.selectbox = 'projects';
        this.selectedItemId = 1;
        this.noDataString = 'projects';
        if (!_.isEmpty(this.route.snapshot.queryParams)) {
            for (let key in this.route.snapshot.queryParams) {
                if (this.route.snapshot.queryParams.hasOwnProperty(key)) {
                    let value = this.route.snapshot.queryParams[key];
                    this.project_filters[key] = value;
                    console.log(key, value);
                }
            }
            this.show();
        }
        else {
            this.subs.add = this.explorerService.exploreProjectsSubject.subscribe(p => {
                if (!p) {
                    console.log('here');
                    this.getProjectsNew();
                }
            });
        }
    }
    setPeople() {
        this.selectbox = 'people';
        this.selectedItemId = 2;
        this.noDataString = 'people';
        if (!_.isEmpty(this.route.snapshot.queryParams)) {
            for (let key in this.route.snapshot.queryParams) {
                if (this.route.snapshot.queryParams.hasOwnProperty(key)) {
                    let value = this.route.snapshot.queryParams[key];
                    console.log(key, value);
                    this.people_filters[key] = value;
                }
            }
            this.show();
        }
        else {
            this.subs.add = this.explorerService.explorePeopleSubject.subscribe(p => {
                if (!p) {
                    this.getPeopleNew();
                }
            });
        }
    }
    setOrgs() {
        this.selectbox = 'org';
        this.selectedItemId = 3;
        this.noDataString = 'org';
        console.log('here0');
        if (!_.isEmpty(this.route.snapshot.queryParams)) {
            for (let key in this.route.snapshot.queryParams) {
                if (this.route.snapshot.queryParams.hasOwnProperty(key)) {
                    let value = this.route.snapshot.queryParams[key];
                    console.log(key, value);
                    this.org_filters[key] = value;
                }
            }
            this.show();
        }
        else {
            this.subs.add = this.explorerService.exploreOrgSubject.subscribe(o => {
                if (!o) {
                    console.log('here');
                    this.getOrgNew();
                }
            });
        }
    }
    setLessonPlans() {
        this.selectbox = 'lessonplans';
        this.selectedItemId = 4;
        this.noDataString = 'lessonplans';
    }
    setJobs() {
        this.selectbox = 'jobs';
        this.selectedItemId = 5;
        this.noDataString = 'jobs';
    }
    setChat() {
        this.selectbox = 'chat';
        this.selectedItemId = 6;
        this.noDataString = 'chat';
    }
    ngOnInit() {
        this.exploreProjectsCardData$ = this.explorerService.exploreProjectsData$;
        this.explorePeopleCardData$ = this.explorerService.explorePeopleData$;
        this.exploreOrgCardData$ = this.explorerService.exploreOrgData$;
        if (history.state) {
            if (history.state.val) {
                //getting data from home page explore projects and applying the filter
                this.project_filters.project_discipline = history.state.val;
                this.show();
            }
        }
        this.route.params.subscribe(param => {
            // console.log(this.route.snapshot.queryParams, param);
            switch (param["type"]) {
                case 'projects':
                    console.log(param["type"]);
                    this.setProjects();
                    this.metaService.setTitle('Science and Engineering Projects | RePicture');
                    this.metaService.updateTitle('Science and Engineering Projects | RePicture');
                    break;
                case 'people':
                    console.log(param["type"]);
                    this.setPeople();
                    this.metaService.setTitle('Engineers, Scientists Careers | RePicture');
                    this.metaService.updateTitle('Engineers, Scientists Careers | RePicture');
                    break;
                case 'org':
                    this.setOrgs();
                    this.metaService.setTitle('Engineering and Science Companies | RePicture');
                    this.metaService.updateTitle('Engineering and Science Companies | RePicture');
                    break;
                case 'lessonplans':
                    this.setLessonPlans();
                    break;
                case 'jobs':
                    this.setJobs();
                    break;
                case 'chat':
                    this.setChat();
                    break;
                default:
                    break;
            }
        });
        // if (this.router.url.indexOf("lessonplans") != -1) {
        //   this.setLessonPlans();
        // } else if (this.router.url.indexOf("jobs") != -1) {
        //   this.setJobs();
        // } else if (this.router.url.indexOf("chat") != -1) {
        //   this.setChat();
        // }
        return;
    }
    ngOnDestroy() {
        console.log('destroyed');
        this.subs.dispose();
        this.explorerService.resetData();
        // console.log(this.project_filters,this.chips);
    }
    goToProject(slug) {
        this.router.navigate(["project/" + slug]);
    }
    ddSearch() {
        // if (this.router.url.indexOf('careers') !== -1) {
        //   this.titleService.setTitle('Careers');
        //   this.selectedItemId = 5;
        //   this.noDataString = 'job';
        //   this.selectbox = 'job';
        //   return;
        // } else if (this.router.url.indexOf('chat') !== -1) {
        //   this.titleService.setTitle('Group Chat');
        //   this.selectedItemId = 4;
        //   this.noDataString = 'chat';
        //   this.selectbox = 'chat';
        //   return;
        // }
        // this.selectedItemId = 1;
        this.selectbox = this.ddSearchVal;
        // this.service.explorerSection = '';
        this.router.navigate(["explorer/" + this.ddSearchVal]);
        console.log("from ddsearch", "explorer/" + this.ddSearchVal);
        // this.search();
    }
    certificationChange($event) {
        this.org_filters.org_certification = $event;
        if (_.includes($event, "Other")) {
            this.is_prof_certi_other = true;
        }
        else {
            this.is_prof_certi_other = false;
        }
    }
    onOpen($event) {
        console.log($("#mySidenav .scroll"));
        setTimeout(() => {
            $("#mySidenav .scroll").scrollTop($("#mySidenav .scroll")[0].scrollHeight);
        }, 300);
    }
    onScroll() {
        console.log("infinitScroll");
        if (this.selectbox == "projects") {
            this.explorerService.loadMoreProjects();
        }
        else if (this.selectbox == "people") {
            this.explorerService.loadMorePeople();
        }
        else if (this.selectbox == 'org') {
            this.explorerService.loadMoreOrgs();
        }
        return;
        // console.log("sfs");
        // if ($event.isReachingBottom) {
        if (!this.loading2) {
            if (this.selectbox == "projects") {
                let list_url;
                if ((this.clear_filter || this.filter_applied) &&
                    !this.noDataHomepageSearch) {
                    list_url = this.nextURL;
                }
                else {
                    list_url = this.service.nextURL;
                }
                if (!list_url) {
                    return;
                }
                this.loading2 = true;
                this.service.getProjectList(list_url).subscribe((result) => __awaiter(this, void 0, void 0, function* () {
                    if ((this.clear_filter || this.filter_applied) && !this.noDataHomepageSearch) {
                        this.nextURL = result['next'];
                    }
                    else {
                        this.service.nextURL = result["next"];
                    }
                    let i;
                    for (i = 0; i < result["results"].length; i++) {
                        let data;
                        let obj = result["results"][i];
                        if (obj.is_published) {
                            if (_.isEmpty(obj.shorter_location)) {
                                if (obj.centroid.coordinates[1] === 0 &&
                                    obj.centroid.coordinates[0] === 0) {
                                    data = obj.project_location;
                                    this.collection.push({
                                        address: data,
                                        name: obj.project_name
                                            ? obj.project_name
                                            : "Name This Project",
                                        imageurl: obj.project_media.toLowerCase().indexOf('.gif') == -1 ? obj.project_media_thumbnail : obj.project_media,
                                        project_id: obj.project_id,
                                        project_name_slug: obj.project_name_slug,
                                    });
                                }
                                else {
                                    yield this.service
                                        .getShorterAddress(obj.centroid.coordinates[1], obj.centroid.coordinates[0])
                                        .then((result) => {
                                        let res = result["results"];
                                        let indice = null;
                                        try {
                                            data = "";
                                            for (let j = 0; j < res[0].address_components.length; j++) {
                                                if (res[0].address_components[j].types[0] == "locality") {
                                                    data =
                                                        res[0].address_components[j].long_name + ", ";
                                                }
                                                if (res[0].address_components[j].types[0] ==
                                                    "administrative_area_level_1") {
                                                    data +=
                                                        res[0].address_components[j].long_name + ", ";
                                                }
                                                if (res[0].address_components[j].types[0] == "country") {
                                                    data += res[0].address_components[j].long_name;
                                                }
                                            }
                                        }
                                        catch (e) { }
                                        this.collection.push({
                                            address: data,
                                            name: obj.project_name
                                                ? obj.project_name
                                                : "Name This Project",
                                            imageurl: obj.project_media.toLowerCase().indexOf('.gif') == -1 ? obj.project_media_thumbnail : obj.project_media,
                                            project_id: obj.project_id,
                                            project_name_slug: obj.project_name_slug,
                                        });
                                        // if(this.service.isAdmin)
                                        //   this.addShorterAddressToProject(obj.project_name_slug, data);
                                    }, error => {
                                        this.collection.push({
                                            address: 'Unknown',
                                            name: obj.project_name
                                                ? obj.project_name
                                                : "Name This Project",
                                            imageurl: obj.project_media.toLowerCase().indexOf('.gif') == -1 ? obj.project_media_thumbnail : obj.project_media,
                                            project_id: obj.project_id,
                                            project_name_slug: obj.project_name_slug,
                                        });
                                    });
                                }
                            }
                            else {
                                this.collection.push({
                                    address: obj.shorter_location,
                                    name: obj.project_name
                                        ? obj.project_name
                                        : "Name This Project",
                                    imageurl: obj.project_media.toLowerCase().indexOf('.gif') == -1 ? obj.project_media_thumbnail : obj.project_media,
                                    project_id: obj.project_id,
                                    project_name_slug: obj.project_name_slug,
                                });
                            }
                        }
                        else {
                        }
                    } //);
                    if (i == result["results"].length) {
                        this.loading2 = false;
                        if (!this.query && !this.clear_filter) {
                            this.service.projectsSetter(this.collection);
                        }
                    }
                }));
                console.log(this.collection, "from sfs");
            }
            else if (this.selectbox == "people") {
                let list_url;
                if (this.clear_filter || this.filter_applied) {
                    list_url = this.people_nextURL;
                }
                else {
                    list_url = this.service.people_nextURL;
                }
                if (!list_url) {
                    return;
                }
                this.loading2 = true;
                this.service.getPeopleList(list_url).then((result) => {
                    if (this.clear_filter || this.filter_applied) {
                        this.people_nextURL = result["next"];
                    }
                    else {
                        this.service.people_nextURL = result["next"];
                    }
                    for (var i = 0; i < result["results"].length; i++) {
                        let obj = result["results"][i];
                        if (!obj.is_private) {
                            if (!_.isEmpty(obj.external_audio) ||
                                !_.isEmpty(obj.user_audio)) {
                                this.hasAudio = true;
                            }
                            else {
                                this.hasAudio = false;
                            }
                            if (!_.isEmpty(obj.external_video) ||
                                !_.isEmpty(obj.user_video)) {
                                this.hasVideo = true;
                            }
                            else {
                                this.hasVideo = false;
                            }
                            if (obj.user_designation) {
                                this.collection_people.push({
                                    designation: _.uniq(obj.user_designation.split(",")).toString(),
                                    name: obj.first_name + " " + obj.last_name,
                                    imageurl: obj.profileImage_thumbnail_v1
                                        ? obj.profileImage_thumbnail_v1
                                        : "assets/images/default.png",
                                    userid: obj.id,
                                    hasAudio: this.hasAudio,
                                    hasVideo: this.hasVideo,
                                    username: obj.full_name_slug,
                                });
                                if (obj.linkedin_signup) {
                                    this.collection_people[i].imageUrl = obj['linkedinProfileImageURL'];
                                }
                            }
                            else {
                                this.collection_people.push({
                                    designation: "",
                                    name: obj.first_name + " " + obj.last_name,
                                    imageurl: obj.profileImage_thumbnail_v1
                                        ? obj.profileImage_thumbnail_v1
                                        : "assets/images/default.png",
                                    userid: obj.id,
                                    hasAudio: this.hasAudio,
                                    hasVideo: this.hasVideo,
                                    username: obj.full_name_slug,
                                });
                                if (obj.linkedin_signup) {
                                    this.collection_people[i].imageUrl = obj['linkedinProfileImageURL'];
                                }
                            }
                        }
                    }
                    if (i == result["results"].length) {
                        this.loading2 = false;
                        if (!this.query && !this.clear_filter) {
                            this.service.peopleSetter(this.collection_people);
                        }
                    }
                });
            }
            else if (this.selectbox == "org" && this.service.company_nextURL) {
                let list_url;
                if (this.clear_filter || this.filter_applied) {
                    list_url = this.company_nextURL;
                }
                else {
                    list_url = this.service.company_nextURL;
                }
                if (!list_url) {
                    return;
                }
                this.loading2 = true;
                this.service.getOrgList(list_url).then((result) => {
                    if (this.clear_filter || this.filter_applied) {
                        this.company_nextURL = result["next"];
                    }
                    else {
                        this.service.company_nextURL = result["next"];
                    }
                    for (var i = 0; i < result["results"].length; i++) {
                        let obj = result["results"][i];
                        if (!obj.is_private) {
                            if (obj.org_external_audio || obj.org_about_audio) {
                                this.hasAudio = true;
                            }
                            else {
                                this.hasAudio = false;
                            }
                            if (obj.org_external_video || obj.org_about_video) {
                                this.hasVideo = true;
                            }
                            else {
                                this.hasVideo = false;
                            }
                            this.collection_companies.push({
                                name: obj.org_name,
                                imageurl: obj.org_image_thumbnail
                                    ? obj.org_image_thumbnail
                                    : "assets/images/no-image.jpg",
                                companyid: obj.id,
                                hasAudio: this.hasAudio,
                                hasVideo: this.hasVideo,
                                username: obj.org_name_slug,
                            });
                        }
                    }
                    if (i == result["results"].length) {
                        this.loading2 = false;
                        if (!this.query && !this.clear_filter) {
                            this.service.companySetter(this.collection_companies);
                        }
                    }
                });
            }
            // else if (this.selectbox == 'chat') {
            //   let list_url;
            //   if (this.clear_filter || this.filter_applied) {
            //     list_url = this.chat_nextURL;
            //   } else {
            //     list_url = this.service.chat_nextURL;
            //   }
            //   if (!list_url) {
            //     return;
            //   }
            //   this.loading2 = true;
            //   this.service.getChatGroups(list_url).then(result => {
            //     if (this.clear_filter || this.filter_applied) {
            //       this.chat_nextURL = result['next'];
            //     } else {
            //       this.service.chat_nextURL = result['next'];
            //     }
            //     this.chatCollection = result['results'];
            //     this.loading2 = false;
            //     if (!this.query && !this.clear_filter) {
            //       this.service.groupSetter(this.chatCollection);
            //     }
            //   });
            // }
        }
        // }
        // }
    }
    addShorterAddressToProject(project_name_slug, data) {
        this.service.project_name_slug = project_name_slug;
        let obj = {
            shorter_location: data,
        };
        this.service.addShorterLocation(project_name_slug, obj);
        // .then(res => {
        //   console.log('shorter_location updated for ' + project_name_slug);
        // });
    }
    onChangedd1($event) {
        console.log($event);
        this.project_filters.project_funding_type = $event;
        console.log(this.project_filters.project_funding_type);
    }
    onChangedd2($event) {
        this.project_filters.project_awards_name = $event;
        if (_.includes($event, "OTR")) {
            this.award_other = true;
        }
        else {
            this.award_other = false;
        }
    }
    onChangedd3($event) {
        console.log($event);
        this.project_filters.project_details_title = $event;
    }
    onChangedd4($event) {
        console.log($event);
        this.people_filters.career_status = $event;
    }
    onChangedd5($event) {
        console.log($event);
        this.project_filters.project_institution = $event;
    }
    closeMultidd() {
        try {
            for (let i = 0; i < 2; i++) {
                document.getElementsByClassName("dropdown-menu")[i]["style"].display =
                    "none";
            }
        }
        catch (e) { }
    }
    onChangedd($event, ddId) {
        if (ddId == 1) {
            if (_.isEmpty($event)) {
                document
                    .getElementsByClassName("dropdown-toggle")[this.multiddindex].setAttribute("disabled", "disabled");
            }
            else {
                document
                    .getElementsByClassName("dropdown-toggle")[this.multiddindex].removeAttribute("disabled");
            }
            if (_.includes($event, "OTR")) {
                this.project_type_other = true;
            }
            else {
                this.project_type_other = false;
            }
            this.project_filters.project_type = $event;
            console.log($event);
            this.projectSubtypeOptions = [];
            _.forEach($event, (value) => {
                if (value == "ENV") {
                    this.projectSubtypeOptions = _.concat(this.projectSubtypeOptions, this.type1);
                }
                if (value == "STR") {
                    this.projectSubtypeOptions = _.concat(this.projectSubtypeOptions, this.type2);
                }
                if (value == "TRN") {
                    this.projectSubtypeOptions = _.concat(this.projectSubtypeOptions, this.type3);
                }
            });
        }
        if (ddId == 2) {
            this.project_filters.project_sub_type = $event;
        }
    }
    isEmpty(obj) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }
    filterResults() {
        this.show();
    }
    test() {
        let filters;
        if (this.selectbox == "projects") {
            filters = this.project_filters;
        }
        else if (this.selectbox == "people") {
            filters = this.people_filters;
        }
        else if (this.selectbox == "org") {
            filters = this.org_filters;
        }
        else if (this.selectbox == "chat") {
            filters = this.chat_filters;
        }
        else if (this.selectbox == "jobs") {
            filters = this.job_filters;
        }
        // Object.keys(filters).forEach((key) => {
        //   console.log(filters[key]);
        //   if (!_.isEmpty(filters[key]) || filters[key] === true) {
        //     console.log('true');
        //     return true;
        //   }
        // });
        for (let i = 0; i < Object.keys(filters).length; i++) {
            console.log(filters[Object.keys(filters)[i]]);
            if (!_.isEmpty(filters[Object.keys(filters)[i]]) ||
                filters[Object.keys(filters)[i]] === true) {
                console.log(filters[Object.keys(filters)[i]]);
                return true;
            }
        }
    }
    enableAndDisableInfiniteScroll() {
        //very important
        this.scrollDisabled = true;
        setTimeout(() => {
            this.scrollDisabled = false;
        }, 1000);
    }
    searchWithKeyword() {
        console.log(this.query);
        switch (this.selectbox) {
            case 'projects':
                this.project_filters.search = this.query;
                this.show();
                break;
            case 'people':
                this.people_filters.search = this.query;
                this.show();
                break;
            case 'org':
                this.org_filters.search = this.query;
                this.show();
                break;
            default:
                break;
        }
    }
    show() {
        this.enableAndDisableInfiniteScroll();
        if (this.selectbox == "projects") {
            if (this.test()) {
                this.filter_all_cleared = false;
                if (_.includes(this.project_filters.project_awards_name, "OTR")) {
                    let index = this.project_filters.project_awards_name.indexOf("OTR");
                    this.project_filters.project_awards_name.splice(index, 1);
                    this.project_filters.project_awards_name.push(this.award_other_value);
                }
                //v.z
                //unclear
                // if (_.includes(this.project_filters.project_type, 'OTR')) {
                //   let index = this.project_filters.project_type.indexOf('OTR');
                //   this.project_filters.project_type.splice(index, 1);
                //   this.project_filters.project_type.push(this.project_filters.project_type_other);
                // }
                let str = "";
                this.chips = [];
                Object.keys(this.project_filters).forEach((key) => {
                    if (!_.isEmpty(this.project_filters[key]) ||
                        this.project_filters[key] === true) {
                        this.chips.push({
                            name: this.project_filters_names[key],
                            key: key,
                            value: this.project_filters[key],
                        });
                        if (this.project_filters.project_status == 'Other') {
                            this.project_filters.project_status = this.project_status_other;
                        }
                        if (this.project_filters.project_completion_date == "date-range") {
                            if (!_.isEmpty(this.start_year) && !_.isEmpty(this.end_year)) {
                                this.yearCheck = true;
                            }
                        }
                        if (key == "project_completion_date" &&
                            this.project_filters.project_completion_date == "date-range" &&
                            this.yearCheck) {
                            if (_.isEmpty(str)) {
                                str += "?" + key + "=" + this.start_year + "," + this.end_year;
                            }
                            else {
                                str += "&" + key + "=" + this.start_year + "," + this.end_year;
                            }
                            this.yearCheck = false;
                        }
                        else if (key == "project_completion_date" &&
                            this.project_filters.project_completion_date != "date-range") {
                            if (_.isEmpty(str)) {
                                str += "?" + key + "=" + this.project_filters[key].toString();
                            }
                            else {
                                str += "&" + key + "=" + this.project_filters[key].toString();
                            }
                        }
                        else if (key != "project_completion_date") {
                            if (_.isEmpty(str)) {
                                str += "?" + key + "=" + this.project_filters[key].toString();
                            }
                            else {
                                str += "&" + key + "=" + this.project_filters[key].toString();
                            }
                        }
                    }
                });
                console.log(this.chips);
                console.log(str);
                sessionStorage.setItem('query', str);
                this.explorerService.getProjects(this.explorerService.projectFilterURL + str);
                this.locationObj.replaceState("explorer/" + this.selectbox + str);
                this.filter_applied = true;
                let query = this.projectFilterURL + str;
                // if (str) this.getFilteredProjects(query);
            }
            else {
                this.service.sendAlert("Error", "No filter has been selected!", 0);
            }
        }
        else if (this.selectbox == "people") {
            if (this.test()) {
                let str = "";
                Object.keys(this.people_filters).forEach((key) => {
                    if (!_.isEmpty(this.people_filters[key]) ||
                        this.people_filters[key] === true) {
                        if (key == "user_designation" &&
                            this.people_filters.user_designation == "Other") {
                            if (_.isEmpty(str)) {
                                str +=
                                    "?" + key + "=" + this.user_designation_other_role.toString();
                            }
                            else {
                                str +=
                                    "&" + key + "=" + this.user_designation_other_role.toString();
                            }
                        }
                        else {
                            console.log(key, this.people_filters[key]);
                            if (_.isEmpty(str)) {
                                str += "?" + key + "=" + this.people_filters[key].toString();
                            }
                            else {
                                str += "&" + key + "=" + this.people_filters[key].toString();
                            }
                        }
                    }
                });
                this.locationObj.replaceState("explorer/" + this.selectbox + str);
                this.explorerService.getPeople(this.explorerService.peopleFilterURL + str);
                this.filter_applied = true;
                let query = this.peopleFilterURL + str;
                console.log(query);
                // if (str) this.getPeoples(query);
            }
        }
        else if (this.selectbox == "org") {
            if (this.test()) {
                let str = "";
                Object.keys(this.org_filters).forEach((key) => {
                    if (!_.isEmpty(this.org_filters[key]) ||
                        this.org_filters[key] === true) {
                        if (_.isEmpty(str)) {
                            str += "?" + key + "=" + this.org_filters[key].toString();
                        }
                        else {
                            str += "&" + key + "=" + this.org_filters[key].toString();
                        }
                    }
                });
                this.locationObj.replaceState("explorer/" + this.selectbox + str);
                this.filter_applied = true;
                let query = this.orgFilterURL + str;
                console.log(query);
                this.explorerService.getOrgs(this.explorerService.orgFilterUrl + str);
                // if (str) this.getCompanies(query);
            }
        }
        else if (this.selectbox === "chat") {
            console.log(this.chat_filters);
            if (this.test()) {
                let str = "";
                Object.keys(this.chat_filters).forEach((key) => {
                    if (!_.isEmpty(this.chat_filters[key]) ||
                        this.chat_filters[key] === true) {
                        // if (key === 'is_private') {
                        //   key = 'is_private';
                        //   this.chat_filters[key] = '1';
                        // }else {
                        //   key = 'is_private';
                        //   this.chat_filters[key] = '0';
                        // }
                        if (this.chat_filters.is_private) {
                            key = "is_private";
                            this.chat_filters[key] = 1;
                        }
                        // } else {
                        //   this.chat_filters[key] = '1';
                        // }
                        console.log(str);
                        if (_.isEmpty(str)) {
                            str += "?" + key + "=" + this.chat_filters[key].toString();
                        }
                        else {
                            str += "&" + key + "=" + this.chat_filters[key].toString();
                        }
                    }
                });
                this.locationObj.replaceState("/" + this.selectbox + str);
                this.filter_applied = true;
                let query = this.chatFilterURL + str;
                console.log(query);
                if (str)
                    this.filterGroups(query);
            }
        }
        else if (this.selectbox == "jobs") {
            if (this.test()) {
                let str = "";
                Object.keys(this.job_filters).forEach((key) => {
                    if (!_.isEmpty(this.job_filters[key]) ||
                        this.job_filters[key] === true) {
                        if (_.isEmpty(str)) {
                            str += "?" + key + "=" + this.job_filters[key].toString();
                        }
                        else {
                            str += "&" + key + "=" + this.job_filters[key].toString();
                        }
                    }
                });
                this.locationObj.replaceState("explorer/" + this.selectbox + str);
                this.filter_applied = true;
                let query = this.jobsFilterURL + str;
                console.log(query);
                if (str)
                    this.filterJobs(query);
            }
        }
        if (this.service.mobilecheck() && window.innerHeight > window.innerWidth)
            this.closeFilter();
    }
    ngAfterContentInit() {
        if (isPlatformBrowser(this.platform)) {
            // if (this.service.mobilecheck()) {
            //   document.getElementById('mySidenav').className = 'sidefilter';
            // } else {
            //   document.getElementById('mySidenav').className = 'sidefilter1';
            // }
            if (this.selectbox != 'people') {
                setTimeout(() => {
                    var input = document.getElementById('autocompleteinput');
                    var autocomplete = new google.maps.places.Autocomplete(input);
                    autocomplete.addListener('place_changed', () => {
                        var place = autocomplete.getPlace();
                        this.location = document.getElementById("autocompleteinput")['value'];
                        let location_array = this.location.split(',');
                        this.loc = location_array[0];
                        if (!place.geometry) {
                            //window.alert("No details available for '" + place.name + "'");
                            this.service.sendAlert('Error', "No details available for " + place.name, 0);
                            return;
                        }
                        this.latformarker = place.geometry.location.lat();
                        this.lngformarker = place.geometry.location.lng();
                        let componentForm = {
                            locality: 'long_name',
                            administrative_area_level_1: 'long_name',
                            country: 'long_name'
                        };
                        var val = '';
                        for (var i = 0; i < place.address_components.length; i++) {
                            var addressType = place.address_components[i].types[0];
                            if (componentForm[addressType]) {
                                if (addressType == 'country') {
                                    val += place.address_components[i][componentForm[addressType]];
                                }
                                else {
                                    val += place.address_components[i][componentForm[addressType]] + ', ';
                                }
                            }
                        }
                        this.loc = val;
                        this.project_filters.project_location = this.loc;
                    });
                }, 1000);
            }
            setTimeout(() => {
                if (!this.service.hasfilterOpened) {
                }
                this.disabledd();
            }, 2000);
        }
        this.currentYear = new Date().getFullYear();
        this.minus5Year = new Date().getFullYear() - 5;
        this.minus25Year = new Date().getFullYear() - 25;
    }
    disabledd() {
        try {
            for (let i = 0; i < document.getElementsByClassName("dropdown-toggle").length; i++) {
                if (document
                    .getElementsByClassName("dropdown-toggle")[i]["innerText"].toString()
                    .trim() == "Select project subtype") {
                    document
                        .getElementsByClassName("dropdown-toggle")[i].setAttribute("disabled", "disabled");
                    document.getElementsByClassName("dropdown-toggle")[i]["style"].marginTop = "5px";
                    this.multiddindex = i;
                    break;
                }
            }
        }
        catch (e) { }
    }
    remove(index) {
        this.reset(index);
    }
    onChange() {
        this.noData = false;
        this.collection = [];
        this.loading = true;
        if (this.selectbox == "projects") {
            this.getProjects(this.url1);
        }
        else if (this.selectbox == "people") {
            this.getPeoples(this.userProfileURL);
        }
        else if (this.selectbox == "org") {
            this.getCompanies(this.companyProfileURL);
        }
    }
    removeQuery() {
        this.query = '';
        sessionStorage.removeItem('query');
    }
    get_proj() {
        if (!_.isEmpty(this.service.projectsGetter())) {
            this.collection = [];
            this.collection = this.service.projectsGetter();
            // this.shuffle(this.service.projectsGetter());
            if (!this.query) {
                this.service.projectsSetter(this.collection);
            }
            this.loading = false;
        }
        else {
            this.loading = true;
            this.getProjects(this.url1);
        }
        setTimeout(() => {
            this.disabledd();
        }, 1000);
    }
    search() {
        this.noData = false;
        this.noDataHomepageSearch = false;
        this.homepageSearch = false;
        this.filter_applied = false;
        this.clear_filter = false;
        this.collection = [];
        this.loading = true;
        this.noDataString = this.selectbox;
        this.displayPag = false;
        // this.service.explorerSection = this.selectbox;
        // console.log('this.service.explorerSection11', this.service.explorerSection);
        this.service.sendEvent("click", "tab clicked: " + this.selectbox, "");
        if (!this.query) {
            if (!this.searchBtnClicked) {
                this.query = sessionStorage.getItem('query');
            }
            if (this.selectbox == 'projects') {
                if (this.query) {
                    this.searchProjects(this.query);
                }
                else {
                    this.get_proj();
                }
                setTimeout(() => {
                    this.setupLocations(1);
                });
            }
            else if (this.selectbox == "people") {
                if (this.query) {
                    this.searchPeoples(this.query);
                }
                else if (!_.isEmpty(this.service.peopleGetter())) {
                    this.collection_people = [];
                    this.collection_people = this.service.peopleGetter();
                    this.loading = false;
                }
                else {
                    this.loading = true;
                    this.getPeoples(this.userProfileURL);
                }
            }
            else if (this.selectbox == "org") {
                console.log(this.service.companyGetter());
                setTimeout(() => {
                    this.setupLocations(2);
                });
                if (this.query) {
                    this.searchCompanies(this.query);
                }
                else if (!_.isEmpty(this.service.companyGetter())) {
                    this.collection_companies = [];
                    this.collection_companies = this.service.companyGetter();
                    this.loading = false;
                }
                else {
                    this.loading = true;
                    this.getCompanies(this.companyProfileURL);
                }
            }
            else if (this.selectbox == "chat") {
                if (this.query) {
                    this.searchGroups(this.query);
                }
                else if (!_.isEmpty(this.service.groupGetter())) {
                    this.chatCollection = [];
                    this.chatCollection = this.service.groupGetter();
                    this.loading = false;
                }
                else {
                    this.loading = true;
                    this.getGroups(this.groupChatsURL);
                }
            }
            else if (this.selectbox == "jobs") {
                if (this.query) {
                    this.searchJobs(this.query);
                }
                else {
                    this.service.getCareers().then((res) => {
                        this.jobs = res["results"];
                        this.loading = false;
                    });
                }
            }
        }
        else {
            sessionStorage.setItem('query', this.query);
            this.clear_filter = true;
            if (this.selectbox == "projects") {
                this.searchProjects(this.query);
                this.disabledd();
                setTimeout(() => {
                    this.setupLocations(1);
                });
            }
            else if (this.selectbox == "people") {
                this.searchPeoples(this.query);
            }
            else if (this.selectbox == "org") {
                this.searchCompanies(this.query);
                setTimeout(() => {
                    this.setupLocations(2);
                });
            }
            else if (this.selectbox == "chat") {
                this.searchGroups(this.query);
            }
            else if (this.selectbox == "jobs") {
                this.searchJobs(this.query);
            }
            this.service.sendEvent("search explorer", "search type: " + this.selectbox, "search query: " + this.query);
        }
        this.cd.detectChanges();
        // this.val = this.selectbox;
        this.cd.detectChanges();
    }
    setupLocations(flag) {
        if (isPlatformBrowser(this.platform)) {
            if (flag == 1) {
                var input = document.getElementById('autocompleteinput');
                var autocomplete2 = new google.maps.places.Autocomplete(input);
                let componentForm = {
                    locality: 'long_name',
                    administrative_area_level_1: 'long_name',
                    country: 'long_name'
                };
                autocomplete2.addListener('place_changed', () => {
                    let place = autocomplete2.getPlace();
                    var val = '';
                    for (var i = 0; i < place.address_components.length; i++) {
                        var addressType = place.address_components[i].types[0];
                        if (componentForm[addressType]) {
                            if (addressType == 'country') {
                                val += place.address_components[i][componentForm[addressType]];
                            }
                            else {
                                val += place.address_components[i][componentForm[addressType]] + ', ';
                            }
                        }
                    }
                    this.loc = val;
                    this.project_filters.project_location = this.loc;
                });
            }
            else {
                var input = document.getElementById('autocompleteinput2');
                var autocomplete2 = new google.maps.places.Autocomplete(input);
                let componentForm = {
                    locality: 'long_name',
                    administrative_area_level_1: 'long_name',
                    country: 'long_name'
                };
                autocomplete2.addListener('place_changed', () => {
                    let place = autocomplete2.getPlace();
                    var val = '';
                    for (var i = 0; i < place.address_components.length; i++) {
                        var addressType = place.address_components[i].types[0];
                        if (componentForm[addressType]) {
                            if (addressType == 'country') {
                                val += place.address_components[i][componentForm[addressType]];
                            }
                            else {
                                val += place.address_components[i][componentForm[addressType]] + ', ';
                            }
                        }
                    }
                    this.loc = val;
                    this.org_filters.org_hq_location = this.loc;
                });
            }
        }
    }
    clearResults() {
        this.clear_filter = false;
        this.query = "";
        // this.search();
    }
    searchGroups(query) {
        this.getGroups(this.url + "?data=" + query + "&type=chat");
    }
    searchJobs(query) {
        this.getjobs(this.url + "?data=" + query + "&type=job");
    }
    filterGroups(url) {
        this.getGroups(url);
    }
    filterJobs(url) {
        this.getjobs(url);
    }
    searchCompanies(name) {
        this.getCompanies(this.url + "?data=" + name + "&type=org");
    }
    searchPeoples(name) {
        this.getPeoples(this.url + "?data=" + name + "&type=user");
    }
    searchProjects(query) {
        this.getFilteredProjects(this.url + "?data=" + query + "&type=project");
    }
    deleteUser(evt, id, username, name, i) {
        evt.stopPropagation();
        this.confirmObject = {
            confirmTitle: "Do you really want to make user: " + name + " as private?",
            tag: "deleteUser",
            id: id,
            username: username,
            index: i,
        };
    }
    deleteProject(evt, project_id, name, i) {
        evt.stopPropagation();
        this.confirmObject = {
            confirmTitle: "Do you really want to delete project: " + name + "?",
            tag: "deleteProject",
            id: project_id,
            username: "",
            index: i,
        };
    }
    confirmedAction($event) {
        switch ($event["tag"]) {
            case "deleteOrg":
                this.confirmDeleteOrg($event["username"], $event["index"]);
                break;
            case "deleteUser":
                this.confirmDeleteUser($event["username"], $event["index"]);
                break;
            case "deleteProject":
                this.confirmDeleteProject($event["id"], $event["index"]);
                break;
            default:
                break;
        }
    }
    confirmDeleteProject(id, i) {
        this.service.deleteProjectFromApp(id).then((res) => {
            this.collection.splice(i, 1);
            if (!this.query)
                this.service.peopleSetter(this.collection_people);
        });
    }
    confirmDeleteUser(id, i) {
        this.service.deleteUserService(id).then((res) => {
            this.service.sendAlert("Success", "That user is now hidden.", 0);
            this.collection_people.splice(i, 1);
            if (!this.query)
                this.service.peopleSetter(this.collection_people);
        });
    }
    confirmDeleteOrg(id, i) {
        this.service.deleteOrgService(id).then((res) => {
            this.collection_companies.splice(i, 1);
            if (!this.query)
                this.service.peopleSetter(this.collection_companies);
        });
    }
    deleteOrg(evt, id, username, name, i) {
        evt.stopPropagation();
        this.confirmObject = {
            confirmTitle: "Do you really want to delete organization: " + name + "?",
            tag: "deleteOrg",
            id: id,
            username: username,
            index: i,
        };
    }
    getPeoples(url) {
        this.collection_people = [];
        this.service.getPeopleList(url).then((result) => {
            this.coobj = result["results"];
            this.noDataString = "people";
            this.noData = false;
            if (_.isEmpty(this.coobj)) {
                this.loading = false;
                this.noData = true;
                return;
            }
            if (this.filter_applied) {
                this.clear_filter = true;
            }
            if (this.clear_filter) {
                this.people_nextURL = result["next"];
            }
            else {
                this.service.people_nextURL = result["next"];
            }
            //}
            let collection1 = [];
            for (var i = 0; i < this.coobj.length; i++) {
                let obj = this.coobj[i];
                if (!obj.is_private) {
                    if (!_.isEmpty(obj.external_audio) || !_.isEmpty(obj.user_audio)) {
                        this.hasAudio = true;
                    }
                    else {
                        this.hasAudio = false;
                    }
                    if (!_.isEmpty(obj.external_video) || !_.isEmpty(obj.user_video)) {
                        this.hasVideo = true;
                    }
                    else {
                        this.hasVideo = false;
                    }
                    if (obj.user_designation) {
                        collection1.push({
                            designation: _.uniq(obj.user_designation.split(",")).toString(),
                            name: obj.first_name + " " + obj.last_name,
                            imageurl: obj.profileImage_thumbnail_v1
                                ? obj.profileImage_thumbnail_v1
                                : "assets/images/default.png",
                            userid: obj.id,
                            hasAudio: this.hasAudio,
                            hasVideo: this.hasVideo,
                            username: obj.full_name_slug,
                        });
                    }
                    else {
                        collection1.push({
                            designation: "",
                            name: obj.first_name + " " + obj.last_name,
                            imageurl: obj.profileImage_thumbnail_v1
                                ? obj.profileImage_thumbnail_v1
                                : "assets/images/default.png",
                            userid: obj.id,
                            hasAudio: this.hasAudio,
                            hasVideo: this.hasVideo,
                            username: obj.full_name_slug,
                        });
                    }
                }
            }
            setTimeout(() => {
                this.loading = false;
                this.collection_people = collection1;
                if (_.isEmpty(this.collection_people)) {
                    this.noData = true;
                    //return;
                }
                else {
                    if (!this.query && !this.clear_filter) {
                        this.service.peopleSetter(this.collection_people);
                    }
                }
            }, 1000);
        });
    }
    getCompanies(url) {
        this.collection_companies = [];
        this.service.getOrgList(url).then((result) => {
            this.coobj = result["results"];
            this.noDataString = "org";
            this.noData = false;
            if (_.isEmpty(this.coobj)) {
                this.loading = false;
                this.noData = true;
                return;
            }
            if (this.filter_applied) {
                this.clear_filter = true;
            }
            if (this.clear_filter) {
                this.company_nextURL = result["next"];
            }
            else {
                this.service.company_nextURL = result["next"];
            }
            let collection1 = [];
            for (var i = 0; i < this.coobj.length; i++) {
                let obj = this.coobj[i];
                if (!obj.is_private) {
                    if (obj.org_external_audio || obj.org_about_audio) {
                        this.hasAudio = true;
                    }
                    else {
                        this.hasAudio = false;
                    }
                    if (obj.org_external_video || obj.org_about_video) {
                        this.hasVideo = true;
                    }
                    else {
                        this.hasVideo = false;
                    }
                    collection1.push({
                        name: obj.org_name,
                        imageurl: obj.org_image_thumbnail
                            ? obj.org_image_thumbnail
                            : "assets/images/no-image.jpg",
                        companyid: obj.id,
                        hasAudio: this.hasAudio,
                        hasVideo: this.hasVideo,
                        username: obj.org_name_slug,
                    });
                }
            }
            setTimeout(() => {
                this.loading = false;
                this.collection_companies = collection1;
                if (_.isEmpty(this.collection_companies)) {
                    this.noData = true;
                    //return;
                }
                else {
                    if (!this.query && !this.clear_filter) {
                        this.service.companySetter(this.collection_companies);
                    }
                }
                if (this.selectbox == 'org') {
                    if (isPlatformBrowser(this.platform)) {
                        var input = document.getElementById('autocompleteinput2');
                        var autocomplete2 = new google.maps.places.Autocomplete(input);
                        let componentForm = {
                            locality: 'long_name',
                            administrative_area_level_1: 'long_name',
                            country: 'long_name'
                        };
                        autocomplete2.addListener('place_changed', () => {
                            let place = autocomplete2.getPlace();
                            var val = '';
                            for (var i = 0; i < place.address_components.length; i++) {
                                var addressType = place.address_components[i].types[0];
                                if (componentForm[addressType]) {
                                    if (addressType == 'country') {
                                        val += place.address_components[i][componentForm[addressType]];
                                    }
                                    else {
                                        val += place.address_components[i][componentForm[addressType]] + ', ';
                                    }
                                }
                            }
                            this.loc = val;
                            this.org_filters.org_hq_location = this.loc;
                        });
                    }
                }
            }, 1000);
        });
    }
    getGroups(url) {
        this.service.getChatGroups(url).then((res) => {
            if (url.indexOf("?") == -1) {
                this.service.groupSetter(res["results"]);
            }
            this.coobj = res["results"];
            this.noDataString = "chat";
            this.noData = false;
            if (_.isEmpty(this.coobj)) {
                this.loading = false;
                this.noData = true;
                return;
            }
            if (this.filter_applied) {
                this.clear_filter = true;
            }
            if (this.clear_filter) {
                this.chat_nextURL = res["next"];
            }
            else {
                this.service.chat_nextURL = res["next"];
            }
            this.chatCollection = this.coobj;
            this.loading = false;
        });
    }
    getjobs(url) {
        this.service.getJobs(url).then((res) => {
            // if (url.indexOf('?') == -1) {
            //   this.service.groupSetter(res['results']);
            // }
            this.coobj = res["results"];
            this.noDataString = "jobs";
            this.noData = false;
            if (_.isEmpty(this.coobj)) {
                this.loading = false;
                this.noData = true;
                return;
            }
            if (this.filter_applied) {
                this.clear_filter = true;
            }
            if (this.clear_filter) {
                this.job_nextURL = res["next"];
            }
            else {
                this.service.job_nextURL = res["next"];
            }
            this.jobs = this.coobj;
            this.loading = false;
        });
    }
    getFilteredProjects(url) {
        this.service.getProjectList(url).subscribe((result) => __awaiter(this, void 0, void 0, function* () {
            this.loading = false;
            this.coobj = result["results"];
            this.count = result["count"];
            this.noDataString = "projects";
            if (_.isEmpty(this.coobj)) {
                this.noData = true;
                if (this.homepageSearch) {
                    this.noData = false;
                    this.noDataHomepageSearch = true;
                    this.get_proj();
                }
                return;
            }
            //this.clear_filter = true;
            this.nextURL = result["next"];
            this.collection = [];
            let i;
            for (i = 0; i < this.coobj.length; i++) {
                let data;
                let obj = this.coobj[i];
                if (obj.is_published) {
                    if (_.isEmpty(obj.shorter_location)) {
                        if (obj.centroid.coordinates[1] === 0 &&
                            obj.centroid.coordinates[0] === 0) {
                            data = obj.project_location;
                            this.collection.push({
                                address: data,
                                name: obj.project_name ? obj.project_name : "Name This Project",
                                imageurl: obj.project_media.toLowerCase().indexOf('.gif') == -1 ? obj.project_media_thumbnail : obj.project_media,
                                project_id: obj.project_id,
                                project_name_slug: obj.project_name_slug,
                            });
                        }
                        else {
                            yield this.service
                                .getShorterAddress(obj.centroid.coordinates[1], obj.centroid.coordinates[0])
                                .then((result) => {
                                let res = result["results"];
                                let indice = null;
                                try {
                                    data = "";
                                    for (let j = 0; j < res[0].address_components.length; j++) {
                                        if (res[0].address_components[j].types[0] == "locality") {
                                            data = res[0].address_components[j].long_name + ", ";
                                        }
                                        if (res[0].address_components[j].types[0] ==
                                            "administrative_area_level_1") {
                                            data += res[0].address_components[j].long_name + ", ";
                                        }
                                        if (res[0].address_components[j].types[0] == "country") {
                                            data += res[0].address_components[j].long_name;
                                        }
                                    }
                                }
                                catch (e) { }
                                this.collection.push({
                                    address: data,
                                    name: obj.project_name
                                        ? obj.project_name
                                        : "Name This Project",
                                    imageurl: obj.project_media.toLowerCase().indexOf('.gif') == -1 ? obj.project_media_thumbnail : obj.project_media,
                                    project_id: obj.project_id,
                                    project_name_slug: obj.project_name_slug,
                                });
                                // if(this.service.isAdmin)
                                //   this.addShorterAddressToProject(obj.project_name_slug, data);
                            });
                        }
                    }
                    else {
                        this.collection.push({
                            address: obj.shorter_location,
                            name: obj.project_name ? obj.project_name : "Name This Project",
                            imageurl: obj.project_media.toLowerCase().indexOf('.gif') == -1 ? obj.project_media_thumbnail : obj.project_media,
                            project_id: obj.project_id,
                            project_name_slug: obj.project_name_slug,
                        });
                        this.noData = false;
                    }
                }
                else {
                }
            }
            console.log(this.collection, "fromConsole2");
        }));
    }
    getProjects(url) {
        this.collection = [];
        this.service.getProjectList(url).subscribe((result) => __awaiter(this, void 0, void 0, function* () {
            this.coobj = result['results'];
            this.count = result['count'];
            this.noDataString = 'projects';
            if (_.isEmpty(this.coobj)) {
                this.noData = true;
                if (this.homepageSearch) {
                    this.noData = false;
                    this.noDataHomepageSearch = true;
                }
                return;
            }
            this.service.nextURL = result["next"];
            let collection1 = [];
            let i;
            for (i = 0; i < this.coobj.length; i++) {
                let data;
                let obj = this.coobj[i];
                if (obj.is_published) {
                    if (_.isEmpty(obj.shorter_location)) {
                        if (this.coobj[i].centroid.coordinates[1] === 0 &&
                            this.coobj[i].centroid.coordinates[0] === 0) {
                            data = this.coobj[i].project_location;
                            this.collection.push({
                                address: data,
                                name: obj.project_name ? obj.project_name : "Name This Project",
                                imageurl: obj.project_media.toLowerCase().indexOf('.gif') == -1 ? obj.project_media_thumbnail : obj.project_media,
                                project_id: obj.project_id,
                                project_name_slug: obj.project_name_slug,
                            });
                        }
                        else {
                            console.log("calling " + i);
                            yield this.service
                                .getShorterAddress(this.coobj[i].centroid.coordinates[1], this.coobj[i].centroid.coordinates[0])
                                .then((result) => {
                                console.log("call end " + i);
                                let res = result["results"];
                                let indice = null;
                                try {
                                    data = "";
                                    for (let j = 0; j < res[0].address_components.length; j++) {
                                        if (res[0].address_components[j].types[0] == "locality") {
                                            data = res[0].address_components[j].long_name + ", ";
                                        }
                                        if (res[0].address_components[j].types[0] ==
                                            "administrative_area_level_1") {
                                            data += res[0].address_components[j].long_name + ", ";
                                        }
                                        if (res[0].address_components[j].types[0] == "country") {
                                            data += res[0].address_components[j].long_name;
                                        }
                                    }
                                }
                                catch (e) { }
                                console.log(i);
                                this.collection.push({
                                    address: data,
                                    name: obj.project_name
                                        ? obj.project_name
                                        : "Name This Project",
                                    imageurl: obj.project_media.toLowerCase().indexOf('.gif') == -1 ? obj.project_media_thumbnail : obj.project_media,
                                    project_id: obj.project_id,
                                    project_name_slug: obj.project_name_slug,
                                });
                                // if(this.service.isAdmin)
                                //   this.addShorterAddressToProject(obj.project_name_slug, data);
                            });
                        }
                    }
                    else {
                        console.log(i);
                        this.collection.push({
                            address: obj.shorter_location,
                            name: obj.project_name ? obj.project_name : "Name This Project",
                            imageurl: obj.project_media.toLowerCase().indexOf('.gif') == -1 ? obj.project_media_thumbnail : obj.project_media,
                            project_id: obj.project_id,
                            project_name_slug: obj.project_name_slug,
                        });
                    }
                }
                else {
                }
            }
            setTimeout(() => {
                this.loading = false;
            }, 1000);
            this.displayPag = true;
            setTimeout(() => {
                if (_.isEmpty(this.collection) && i === this.coobj.length) {
                    this.noData = true;
                }
                else if (!_.isEmpty(this.collection) && i === this.coobj.length) {
                    // this.shuffle(this.collection);
                    if (!this.query) {
                        this.service.projectsSetter(this.collection);
                    }
                }
            }, 550);
            console.log(this.collection, "fromConsole1");
        }));
    }
    imageResizeUrl(imageUrl, ratio) {
        if (imageUrl != null && imageUrl.length > 0 && imageUrl !== 'assets/images/no-image.jpg' && imageUrl !== 'assets/images/default.png') {
            const array = imageUrl.split('/');
            const lastsegment = array[array.length - 1];
            const lastsegmentcopy = lastsegment.split(/\.(?=[^\.]+$)/)[0];
            let stringobj = imageUrl;
            // console.log(lastsegment);
            // if(lastsegmentcopy.search('.jpg') !== -1){
            //   lastsegmentcopy = lastsegment.replace('.jpg','.jpeg')
            // }
            // if(lastsegmentcopy.search('.png') !== -1){
            //   lastsegmentcopy = lastsegment.replace('.png','.jpeg')
            // }
            // if(lastsegmentcopy.search('.JPG') !== -1){
            //   lastsegmentcopy = lastsegment.replace('.JPG','.jpeg')
            // }
            // if(lastsegmentcopy.search('.PNG') !== -1){
            //   lastsegmentcopy = lastsegment.replace('.PNG','.jpeg')
            // }
            // if(lastsegmentcopy.search('.JPG') === -1 && lastsegmentcopy.search('.jpg') === -1 && lastsegmentcopy.search('.png') === -1){
            //   lastsegmentcopy = lastsegment+'.png'
            // }
            let obj = stringobj.replace(lastsegment, ratio + lastsegmentcopy + '.jpg');
            // console.log(obj);
            return obj;
        }
        else {
            // console.log('no image');
            if (imageUrl === 'assets/images/default.png') {
                return imageUrl;
            }
            else {
                return 'assets/images/no-image.jpg';
            }
        }
    }
    reset(i) {
        let value = this.chips[i].value;
        let key = this.chips[i].key;
        this.chips.splice(i, 1);
        this.resetSpecificFilter(value, key);
    }
    resetSpecificFilter(value, key) {
        switch (typeof value) {
            case "boolean":
                setTimeout(() => {
                    this.project_filters.project_video_references = false;
                });
            case "string":
                this.project_filters[key] = "";
            case "object":
                this.project_filters[key] = [];
        }
        if (this.chips.length == 0) {
            // this.search();
            this.resetFilter();
        }
        else {
            this.show();
        }
    }
    resetFilter() {
        // sessionStorage.removeItem('query');
        this.locationObj.replaceState("explorer/" + this.selectbox);
        if (this.selectbox == "projects") {
            this.getProjectsNew();
            Object.keys(this.project_filters).forEach((key) => {
                switch (typeof this.project_filters[key]) {
                    case "boolean":
                        setTimeout(() => {
                            this.project_filters.project_video_references = false;
                        });
                    case "string":
                        this.project_filters[key] = "";
                    case "object":
                        this.project_filters[key] = [];
                }
            });
            this.end_year = "";
            this.start_year = "";
            this.chips = [];
        }
        else if (this.selectbox == "people") {
            this.getPeopleNew();
            Object.keys(this.people_filters).forEach((key) => {
                switch (typeof this.people_filters[key]) {
                    case "boolean":
                        setTimeout(() => {
                            this.people_filters.external_video = false;
                            this.people_filters.social_network = false;
                            this.people_filters.has_projects = false;
                        });
                    case "string":
                        this.people_filters[key] = "";
                    case "object":
                        this.people_filters[key] = [];
                }
            });
        }
        else if (this.selectbox == "org") {
            this.getOrgNew();
            Object.keys(this.org_filters).forEach((key) => {
                switch (typeof this.org_filters[key]) {
                    case "boolean":
                        setTimeout(() => {
                            this.org_filters.pe_inhouse = false;
                            this.org_filters.org_is_enhanced = false;
                        });
                    case "string":
                        this.org_filters[key] = "";
                    case "object":
                        this.org_filters[key] = [];
                }
            });
        }
        else if (this.selectbox == "chat") {
            Object.keys(this.chat_filters).forEach((key) => {
                switch (typeof this.chat_filters[key]) {
                    case "boolean":
                        setTimeout(() => {
                            this.chat_filters.is_private = false;
                        });
                    case "string":
                        this.chat_filters[key] = "";
                    case "object":
                        this.chat_filters[key] = [];
                }
            });
            this.getGroups(this.groupChatsURL);
        }
        else if (this.selectbox == "jobs") {
            Object.keys(this.job_filters).forEach((key) => {
                switch (typeof this.job_filters[key]) {
                    case "string":
                        this.job_filters[key] = "";
                    case "object":
                        this.job_filters[key] = [];
                }
            });
        }
        // console.log(window.loca.split('?')[0]);
        // const resetUrl = this.router.url;
        // this.locationObj.replaceState(resetUrl);
        this.clearResults();
    }
    shuffle(a) {
        var j, x, i;
        for (i = a.length - 1; i > 0; i--) {
            j = Math.floor(Math.random() * (i + 1));
            x = a[i];
            a[i] = a[j];
            a[j] = x;
        }
        if (!this.query) {
            this.service.projectsSetter(this.collection);
        }
    }
    openFilter() {
        if (!this.filterOpened) {
            document
                .getElementById("mySidenav")
                .style.setProperty("left", "0", "important"); //style.left = "0px";
            this.service.sendEvent("click", "open filter", "");
            this.filterOpened = true;
        }
        else {
            this.closeFilter();
            this.filterOpened = false;
        }
    }
    closeFilter() {
        document.getElementById("mySidenav").style.left = "-290px";
    }
    //v.z
    onKeydown(event) {
        console.log(event);
        if (event.key === "Enter" || event.keyCode === 13) {
            jQuery('#keyword2').blur();
            this.searchBtnClicked = true;
            this.searchWithKeyword();
        }
    }
}
