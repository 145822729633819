/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../node_modules/@ngx-progressbar/core/ngx-progressbar-core.ngfactory";
import * as i2 from "@ngx-progressbar/core";
import * as i3 from "./alert-c/alert-c.component.ngfactory";
import * as i4 from "./alert-c/alert-c.component";
import * as i5 from "./projectservice";
import * as i6 from "@angular/router";
import * as i7 from "@angular/platform-browser";
import * as i8 from "./app.component";
import * as i9 from "angular2-cookie/services/cookies.service";
import * as i10 from "./dom.service";
import * as i11 from "@angular/common";
import * as i12 from "./new-repicture/shared/meta.service";
var styles_AppComponent = [];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-progress", [], null, null, null, i1.View_ɵa_0, i1.RenderType_ɵa)), i0.ɵdid(1, 573440, null, 0, i2.ɵa, [i2.NgProgress], { spinnerPosition: [0, "spinnerPosition"], color: [1, "color"], thick: [2, "thick"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 1, "app-alert-c", [], null, null, null, i3.View_AlertCComponent_0, i3.RenderType_AlertCComponent)), i0.ɵdid(3, 114688, null, 0, i4.AlertCComponent, [i5.ProjectService, i6.Router, i7.DomSanitizer], null, null), (_l()(), i0.ɵeld(4, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(5, 212992, null, 0, i6.RouterOutlet, [i6.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null), (_l()(), i0.ɵeld(6, 0, null, null, 1, "button", [["id", "myBtn"], ["title", "Go to top"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.topFunction() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["\u25B2"]))], function (_ck, _v) { var currVal_0 = "right"; var currVal_1 = "#78C60A"; var currVal_2 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); _ck(_v, 3, 0); _ck(_v, 5, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "my-app", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i0.ɵdid(1, 114688, null, 0, i8.AppComponent, [i6.Router, i2.NgProgress, i9.CookieService, i6.ActivatedRoute, i7.Title, i10.DomService, i0.PLATFORM_ID, i11.Location, i11.ViewportScroller, i12.MetaService, i7.Meta], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i0.ɵccf("my-app", i8.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
