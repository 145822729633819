import {
    Component,
    NgZone,
    Input,
    Output, EventEmitter, ViewEncapsulation, ChangeDetectorRef
} from '@angular/core';

import {
    trigger,
    state,
    style,
    animate,
    transition,
    keyframes
} from '@angular/animations'

import { Router, ActivatedRoute } from '@angular/router';
import {
    ProjectService
}
    from './../projectservice';
import { DomSanitizer } from '@angular/platform-browser';


import * as _ from 'lodash';
import * as $ from 'jquery';
declare var jQuery: any;

import { IMultiSelectOption } from 'angular-2-dropdown-multiselect';

@Component({
    selector: 'userprojects',
    templateUrl: './userprojects.component.html',
    animations: [
        trigger('fadeInOut', [
            transition('void => *', [
                style({ opacity: 0 }), //style only for transition transition (after transiton it removes)
                animate(700, style({ opacity: 1 })) // the new state of the transition(after transiton it removes)
            ]),
            transition('* => void', [
                animate(0, style({ opacity: 0 })) // the new state of the transition(after transiton it removes)
            ])
        ])
    ],
})
export class UserProjectsComponent {

    confirmObject2 = {};
    @Input() mx: any;
    @Input() edit: any;
    @Input() editMode: boolean;
    @Input() currentProject: any;
    @Input() fieldname: any;
    @Input() selfUser: any;
    @Output() checkProjectLength: EventEmitter<any> = new EventEmitter();
    textinput: any;
    addmoreCliked: boolean = false;
    paperDesc:any;
    paperURL:any;
    projectobj: any;
    toggleVisibitly = false;
    ShowWebsiteDiv: boolean = false;
    ShowProjectTypeDiv: boolean = false;
    EditToggle: boolean = false;
    showTexbox: boolean = false;
    collectionArticles = [];
    track2: any = 0;
    ShowWebsiteDivData: boolean = false;
    ShowProjectTypeDivData: boolean = false;
    projecttypeArr: any = [];
    url: string;
    desc: any;
    printArray: any = [];
    data: any;
    peoples: any = [];
    peoples_edit = [];
    media_papers: any = [];
    media_papers_edit = []
    varib = false;
    fields: any;
    txt: any
    userData: any;
    papers = false;
    selectedRole: any;
    otherRoleValue: any;
    otherRole: any;
    id: any;
    external_video: any;
    external_video_iframe: any;
    videoURL: any;
    relavant_exp: any;
    otherExpValue: any;
    relevant_experience: any;
    selectedExp: any = '';
    lineofwork = ['Aerospace Engineer', 'Biomedical Engineer', 'Chemical Engineer', 'Civil Engineer', 'Computer Hardware Engineer', 'Electrical Engineer', 'Electronics Engineer', 'Environmental Engineer', 'Industrial Engineer', 'Materials Engineer', 'Mechanical Engineer', 'Petroleum Engineer', 'Software Engineer', 'Other Engineer', 'Architect', 'Biologist', 'Computer Scientist', 'Chemist', 'Environmental Scientist', 'Landscape Architect', 'Planner', 'Project Manager', 'Surveyor', 'Other'];
    exp: any = [{ experience: '0 to 2', isChecked: false }, { experience: '2 to 5', isChecked: false }, { experience: '5 to 10', isChecked: false }, { experience: '10 to 20', isChecked: false }, { experience: 'more than 20', isChecked: false }];
    hideSave = false;
    obj: { paper_file: any; paper_file_name: any; people_id: string; project_id: any; };
    external_video_edit = false;
    external_video_changed = false;
    obj2: {
        'user_id': string; 'project_id': any; 'external_video': any; // .replace("watch?v=", "embed/")
    };
    mediaFiles = [];
    deletedMediaFiles = [];
    confirmTitle = 'Do you want to delete this file?';
    isVideoDeleted = false;
    isDocumentDeleted = false;
    constructor(public service: ProjectService, private route: ActivatedRoute, private _sanitizer: DomSanitizer, private cd: ChangeDetectorRef) { }

    // ngOnInit() {
       
        

        // $('#text textarea').change(() => {
        //     if (!_.includes(this.service.userprojectsindex, this.currentProject)) {
        //         this.service.userprojectsindex.push(this.currentProject);
        //     }
        //         console.log('textarea', this.service.userprojectsindex);
        // });

        // $('#text radio').change(() => {
        //     if (!_.includes(this.service.userprojectsindex, this.currentProject)) {
        //         this.service.userprojectsindex.push(this.currentProject);
        //     }
        //         console.log('radio', this.service.userprojectsindex);
        // });

        // $('#text select').change(() => {
        //     if (!_.includes(this.service.userprojectsindex, this.currentProject)) {
        //         this.service.userprojectsindex.push(this.currentProject);
        //     }
        //         console.log('select', this.service.userprojectsindex);
        // });
    // }

    onChangeExp(event) {
        console.log(event.target.value);
        this.relevant_experience = event.target.value;
    }

    addPapers() {
        if (!this.paperDesc || !this.paperURL) {
            return;
        }
        this.peoples_edit.push({ url: this.paperURL, desc: this.paperDesc });
        this.paperURL = '';
        this.paperDesc = '';
        this.addmoreCliked = false;
    }

    ngOnInit() {
        console.log('moul', this.currentProject,  this.selfUser);
        if (this.editMode) {
            this.external_video_changed = false;
            this.EditToggle = true;
            this.hideSave = true;
        }
        try {
            if (this.mx) {
                console.log('onchanges if1', ' ', this.mx);
                this.showTexbox = true;
                this.userData = null;
                if (this.mx.project_people.length > 1) {
                    this.route.params.subscribe(params => {
                        const people_id = params['id'];
                        for (let i = 0; i < this.mx.project_people.length; i++) {
                            if (people_id === this.mx.project_people[i].project_contributor_slug) {
                                this.userData = this.mx.project_people[i];
                                break;
                            }
                        }
                    });
                } else {
                    this.userData = this.mx.project_people[0];
                }
                this.selectedRole = this.userData.project_role;
                // this.selectedRole = !_.includes(this.lineofwork, this.selectedRole) ? 'Other' : this.selectedRole;
                if (!_.includes(this.lineofwork, this.selectedRole)) {
                    this.selectedRole = 'Other';
                    this.otherRole = true;
                    this.otherRoleValue = this.userData.project_role;
                }

                if (this.userData.external_video && this.userData.external_video != null) {
                    const url = this.userData.external_video;
                    console.log('urlChnaged', url);
                    this.userData.external_video1 = this.convertMedia(url);
                    this.external_video_edit = true;
                    this.external_video_iframe = this.convertMedia(url);
                    // console.log('pgss', url);
                    // this.userData.external_video = this.convertMedia(url);
                    // console.log('pgs', this.userData.external_video);
                    // this.external_video_edit = this.convertMedia(url);
                }

                if (!_.isEmpty(this.userData.project_papers)) {
                    this.papers = true;
                    this.peoples = this.userData.project_papers;
                    this.peoples_edit = this.userData.project_papers;
                }

                if (!_.isEmpty(this.userData.project_people_media)) {
                    this.papers = true;
                    this.media_papers_edit = this.userData.project_people_media.slice(0); // this.userData.project_people_media;
                }

                if (this.userData.relevant_experience_v2) {
                    for (let i = 0; i < this.exp.length; i++) {
                        if (this.userData.relevant_experience_v2 === this.exp[i].experience) {
                            console.log('expeos', this.exp[i].experience);
                            this.exp[i].isChecked = true;
                            break;
                        }
                    }


                    this.typical_dayx = this.userData.typical_day;
                    this.highlights = this.userData.project_highlights;
                    this.acknowledge = this.userData.acknowledge;
                    //     console.log('exp', this.userData.relevant_experience);
                    // if (_.includes(this.exp, this.userData.relevant_experience)) {
                    //     this.relavant_exp = this.userData.relevant_experience;
                    //     this.selectedExp = this.userData.relevant_experience;
                    // } else {
                    //     this.selectedExp = 'Other';
                    //     this.otherExpValue = this.userData.relevant_experience;
                    // }

                    // if (this.userData.relevant_experience.upper == '100') {
                    //     this.relavant_exp = "15+";
                    //     this.selectedExp = "15-None";
                    // } else {
                    //     this.relavant_exp = this.userData.relevant_experience.lower + " - " + this.userData.relevant_experience.upper;
                    //     this.selectedExp = this.userData.relevant_experience.lower + "-" + this.userData.relevant_experience.upper;
                    // }


                    // console.log('resgs', this.relavant_exp, this.selectedExp);
                }

            } else {
                console.log('onchanges else', ' ', this.mx);
                this.showTexbox = false;
            }
        } catch (e) {
            console.log('e', e);
            this.showTexbox = false;
        }

        console.log(this.userData);
        setTimeout(() => {
            $('#text' + this.currentProject).on('keyup change paste', ':input', (e) => {
            if (!_.includes(this.service.userprojectsindex, this.currentProject)) {
                this.service.userprojectsindex.push(this.currentProject);
            }
                console.log('input', this.service.userprojectsindex);
        });
        }, 2000);
        console.log('mumm', this.confirmTitle);
    }

    ngOnChanges() {
        if (!this.edit) {
            this.EditToggle = false;
        }
    }

    showText() {
        this.showTexbox = true;
        this.EditToggle = true;
    }

    roleddChanged() {
        if (this.selectedRole === 'Other') {
            this.otherRole = true;
        } else {
            this.otherRole = false;
        }
    }

    evt: any;
    clicked = false;
    mediaSelected1($event) {
        
        this.evt = $event;
    }

    mediaSelected($event) {
        // let btnUpload = document.getElementById('btn');
        // btnUpload.classList.remove("glyphicon-upload");
        // btnUpload.classList.add("glyphicon-refresh");
        // btnUpload.classList.add("glyphicon-refresh-animate");
        // //console.log($event);
        const file = $event.target.files[0];
        const name = $event.target.files[0].name;
        // console.log(elem, name);
        this.obj = {
            paper_file: file,
            paper_file_name: name,
            people_id: sessionStorage.getItem('currentUserId'),
            project_id: this.mx.project_id
        };
        console.log(this.obj);
        this.mediaFiles.push(this.obj);
        this.media_papers_edit.push({ 'project_contributor_file': file, 'project_contributor_file_name': name });
        this.evt = '';
        this.clicked = false;
        // btnUpload.classList.add("glyphicon-upload");
        // btnUpload.classList.remove("glyphicon-refresh");
        // btnUpload.classList.remove("glyphicon-refresh-animate");
    }

    highlights = '';
    typical_dayx = '';
    acknowledge = '';

    save(job_title: any, role_description: any, typical_day: any, like_about_project: any, acknowledge: any) {
        console.log('save');
       

        
        this.addPapers();

        // let url1 = document.getElementById("articleUrl" + this.track2)['value'];
        // let desc = document.getElementById("articleText" + this.track2)['value'];
        let exp: any;
        // if (this.selectedExp == 'Other') {
        //     exp = this.otherExpValue;
        // } else
        if (this.selectedExp !== '') {
            // exp = this.selectedExp ? this.selectedExp.split(',').map(Number) : '';
            const arr = this.selectedExp.split('-');
            exp = {
                'lower': parseInt(arr[0]),
                'upper': arr[1] === 'None' ? 100 : parseInt(arr[1])
            };

        } else {
            exp = null;
        }
        // if (url1 || desc) {
        //     this.collectionArticles.splice(this.track2, 1, { 'url': url1, 'desc': desc });
        // } else {
        //     this.collectionArticles.splice(-1, 1);
        // }
        // let newArray = [];
        // newArray = _.concat(this.peoples, this.collectionArticles);
        const people_id = sessionStorage.getItem('currentUserId');
        // if (this.service.publicUser) {
        //     people_id = this.service.publicUser;
        // }
        const body = {
            'user_id': people_id,
            'project_id': this.mx.project_id,
            'project_role': this.selectedRole === 'Other' ? this.otherRoleValue : this.selectedRole,
            // "project_contribution": this.service.addLineBreaks(work_performed),
            'project_role_desc': this.service.addLineBreaks(role_description),
            'typical_day': this.service.addLineBreaks(typical_day),
            'project_highlights': this.service.addLineBreaks(like_about_project),
            'project_papers': this.peoples_edit,
            'relevant_experience_v2': this.relevant_experience,
            'acknowledge': acknowledge,
            'job_title': job_title
        };

        // console.log(body);
        this.service.addUserProjectSpecificData(body).then(result => {
            console.log(result);
            // location.reload();
            this.userData.project_contribution = result['project_contribution'];
            this.userData.project_highlights = result['project_highlights'];
            this.userData.typical_day = result['typical_day'];
            this.userData.project_role_desc = result['project_role_desc'];
            this.userData.acknowledge = result['acknowledge'];
            this.userData.project_role = this.selectedRole === 'Other' ? this.otherRoleValue : this.selectedRole;
            this.userData.job_title = result['job_title'];
            this.userData.relevant_experience_v2 = result['relevant_experience_v2'];
            this.userData.project_papers = result['project_papers'];
            if (!_.isEmpty(result['project_papers'])) {
                this.papers = true;
            }
            if (this.evt){
                if (this.mediaFiles.length >=1){ 
                }else{
                    console.log("is there");
                    this.mediaSelected(this.evt);
    
                }
            }

            if (this.mediaFiles.length > 0) {
                for (let i = 0; i < this.mediaFiles.length; i++) {
                    console.log('setUserFiles');
                    // if (this.obj && this.media_papers_edit.length > 0) {
                    this.service.setUserFiles(this.mediaFiles[i]).then(res => {
                        console.log('media', res);
                        // let obj = {
                        //     project_contributor_file_name: res['project_contributor_file_name'],
                        //     project_contributor_file: res['project_contributor_file']
                        // }
                        this.userData.project_people_media.push(res);
                        this.papers = true;
                    });
                    // }
                }
            } else if (this.isDocumentDeleted) {
                // delete pdf api
                for (let i = 0; i < this.deletedMediaFiles.length; i++) {
                    this.service.deleteUserFiles(this.deletedMediaFiles[i].id).then(res => {
                        console.log('deleted');
                    });
                }
                this.userData.project_people_media = this.media_papers_edit;
                this.isDocumentDeleted = false;
            }

            console.log('external_video');
            if (this.external_video_changed) {
                console.log('ev', this.obj2);
                this.isVideoDeleted = false;
                this.service.addUserProjectSpecificData(this.obj2).then(res => {
                    // this.external_video = this.external_video_edit;
                    // $(".exvideo div iframe").remove();
                    // setTimeout(() => {
                    // alert('sfs');
                    this.userData.external_video = this.obj2.external_video;
                    this.userData.external_video1 = this.convertMedia(this.obj2.external_video);
                    this.external_video_iframe = this.convertMedia(this.obj2.external_video);
                    // $(".exvideo div").html(this.external_video);
                    // console.log('pgs', this.external_video);
                    // }, 2000);

                });
            }

            if (this.isVideoDeleted) {
                this.confirmedAction(this.confirmObject2);
            }
            // if (exp) {
            //     if (exp.upper == '100') {
            //         this.relavant_exp = "15+";
            //         this.selectedExp = "15-None";
            //     } else {
            //         this.relavant_exp = exp.lower + " - " + exp.upper;
            //         this.selectedExp = exp.lower + "-" + exp.upper;
            //     }
            // }
            // this.selectedExp = exp.lower + "-" + exp.upper == '100' ? 'None' : exp.upper;
            // if (!_.isEmpty(newArray)) {
            //     console.log('if')
            //     this.papers = true;
            //     this.peoples = newArray;
            //     this.cd.detectChanges();
            // } else {
            //     console.log('else')
            //     this.papers = false;
            //     this.cd.detectChanges();
            // }
            // this.collectionArticles = [{ url: '', desc: '' }];
            // this.track2 = 0;
            // this.EditToggle = false;
        });
    }

    formatExp(exp) {
        return exp.replace(',', ' - ');
    }

    externalMediaSelected() {
        if (this.convertMedia(this.videoURL)) {
            const btnUpload = document.getElementById('btn11');
            btnUpload.classList.remove('glyphicon-upload');
            btnUpload.classList.add('glyphicon-refresh');
            btnUpload.classList.add('glyphicon-refresh-animate');
            const people_id = sessionStorage.getItem('currentUserId');

            this.obj2 = {
                'user_id': people_id,
                'project_id': this.mx.project_id,
                'external_video': this.videoURL // .replace("watch?v=", "embed/")
            };
            console.log('obj2d', this.obj2);
            this.external_video_edit = true;
            this.external_video_changed = true;
            // this.external_video = this.videoURL;
            this.external_video_iframe = this.convertMedia(this.videoURL);
            this.videoURL = '';
            btnUpload.classList.add('glyphicon-upload');
            btnUpload.classList.remove('glyphicon-refresh');
            btnUpload.classList.remove('glyphicon-refresh-animate');
        }
    }

    confirmedAction(obj) {
        const people_id = sessionStorage.getItem('currentUserId');
        const type = obj['type'];
        const body = {
            'user_id': people_id,
            'project_id': this.mx.project_id,
            [type]: null
        };

        this.service.addUserProjectSpecificData(body).then(result => {
            this.userData.external_video = null;
        });
    }

    yes() {
        this.isVideoDeleted = true;
        this.cd.detectChanges();
        this.external_video_edit = false;
        this.cd.detectChanges();
        if (!_.includes(this.service.userprojectsindex, this.currentProject)) {
            this.service.userprojectsindex.push(this.currentProject);
        }
            console.log('input', this.service.userprojectsindex);
        // this.confirmedAction(this.confirmObject2);
    }

    deleteMedia(type) {
        // console.log(type, this.confirmTitle);
        this.confirmObject2 = {
            'confirmTitle': 'Do you want to delete this file?',
            'tag': 'delete',
            'type': type
        };
        this.external_video_edit = false;
        this.isVideoDeleted = true;
        this.external_video_edit = false;
        if (!_.includes(this.service.userprojectsindex, this.currentProject)) {
            this.service.userprojectsindex.push(this.currentProject);
        }
    }

    getId(url) {
        console.log('urllll',url)
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        const match = url.match(regExp);

        if (match && match[2].length === 11) {
            return match[2];
        } else {
            return 'error';
        }
    }

    convertMedia(url) {
        let html;
        const pattern1 = /(?:http?s?:\/\/)?(?:www\.)?(?:vimeo\.com)\/?(.+)/g;
        const pattern2 = /(?:http?s?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?(.+)/g;
        // var pattern3 = /([-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?(?:jpg|jpeg|gif|png))/gi;

        if (pattern1.test(url)) {
            const replacement = '<iframe style="border: none; width: 100%; height: 100%;" src="//player.vimeo.com/video/$1" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>';
            html = url.replace(pattern1, replacement);
        }


        if (pattern2.test(url)) {
            let vari = url.slice(0);
            // console.log('varieeeeeeeeeee', vari)
            const id = this.getId(vari);
            const replacement = '<iframe style="border: none; width: 100%; height: 100%;" src="https://www.youtube.com/embed/' + id + '" allowfullscreen></iframe>';
            html = url.replace(pattern2, replacement);
        }

        if (!pattern1.test(url) && !pattern2.test(url)) {
            // alert('Please enter only youtube or vimeo video URL');
            this.service.sendAlert('Error', 'Please enter only youtube or vimeo video URL', 0);
            return false;
        }

        // if(pattern3.test(html)){
        //     var replacement = '<a href="$1" target="_blank"><img class="sml" src="$1" /></a><br />';
        //     var html = html.replace(pattern3, replacement);
        // }
        return this._sanitizer.bypassSecurityTrustHtml(html);
    }

    addmoreArticles(index) {
        const url1 = document.getElementById('articleUrl' + index)['value'];
        const desc = document.getElementById('articleText' + index)['value'];
        this.collectionArticles.splice(index, 1, { 'url': url1, 'desc': desc });
        this.collectionArticles.push({ 'url': '', 'desc': '' });
        this.track2++;
    }

    removeArticle(i) {
        this.peoples_edit.splice(i, 1);
        if (!_.includes(this.service.userprojectsindex, this.currentProject)) {
            this.service.userprojectsindex.push(this.currentProject);
        }
            console.log('input', this.service.userprojectsindex);
    }

    removepdf(i) {
        this.deletedMediaFiles.push(this.userData.project_people_media[i]);
        this.isDocumentDeleted = true;
        this.media_papers_edit.splice(i, 1);
        console.log(this.userData.project_people_media);
        if (!_.includes(this.service.userprojectsindex, this.currentProject)) {
            this.service.userprojectsindex.push(this.currentProject);
        }
            console.log('input', this.service.userprojectsindex);
    }

    removeUserSelfFromProjects(project_id, currProject) {
        const obj = {
            'project_name': project_id,
            'user_id': localStorage.getItem('peopleid')
        };

        this.service.removeUserSelf(obj).then(result => {
            console.log('success');
            document.getElementById('projects' + currProject).style.display = 'none';
            this.checkProjectLength.emit();
        });
    }

    imageResizeUrl(imageUrl: string, ratio: string) {
        // return imageUrl;
        if (imageUrl != null && imageUrl.length > 0 && imageUrl !== 'assets/images/no-image.jpg') {
          const array = imageUrl.split('/');
    
          const lastsegment:string = array[array.length-1];
    
          const lastsegmentcopy = lastsegment.split(/\.(?=[^\.]+$)/)[0];
    
    
          let stringobj : string = imageUrl;
          // console.log(lastsegment);
    
    
          // if(lastsegmentcopy.search('.jpg') !== -1){
    
          //   lastsegmentcopy = lastsegment.replace('.jpg','.jpeg')
    
          // }
    
          // if(lastsegmentcopy.search('.png') !== -1){
          //   lastsegmentcopy = lastsegment.replace('.png','.jpeg')
          // }
    
    
          // if(lastsegmentcopy.search('.JPG') !== -1){
    
          //   lastsegmentcopy = lastsegment.replace('.JPG','.jpeg')
    
          // }
    
          // if(lastsegmentcopy.search('.PNG') !== -1){
          //   lastsegmentcopy = lastsegment.replace('.PNG','.jpeg')
          // }
    
    
          // if(lastsegmentcopy.search('.JPG') === -1 && lastsegmentcopy.search('.jpg') === -1 && lastsegmentcopy.search('.png') === -1){
    
          //   lastsegmentcopy = lastsegment+'.png'
    
          // }
    
    
    
          let obj =  stringobj.replace(lastsegment, ratio + lastsegmentcopy+'.jpg')
    
    
    
          // console.log(obj);
          return obj;
    
    
        }else {
          // console.log('no image');
    
         return 'assets/images/no-image.jpg';
        }
      }
}
