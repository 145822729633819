import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ProjectService } from '../projectservice';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../projectservice";
export class ProfileService {
    constructor(httpcClient, service) {
        this.httpcClient = httpcClient;
        this.service = service;
    }
    getSchoolList(searchedText) {
        console.log(searchedText);
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem('authtoken')
        });
        return this.httpcClient.post(this.service.url + "/" + searchedText, headers);
    }
}
ProfileService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProfileService_Factory() { return new ProfileService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ProjectService)); }, token: ProfileService, providedIn: "root" });
