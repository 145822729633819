<div style="height: 100%;">
    <div class="profile-flex">
        <div *ngIf="profileCardData?.image != 'lastnull'">
            <!-- <img src="../../assets/new-assets/testimonials/{{profileCardData?.image}}.png" alt=""> -->
            <img (click)="goToProfile(profileCardData?.slug)" src="{{profileCardData?.image ? profileCardData.image : 'assets/new-assets/default/default.png'}}" alt="">
        </div>
        <div class="data-div">
            <h1 [ngClass]="(profileCardData?.image != 'lastnull') ? 'profile-name' : 'name-explore'" class="profile-name">{{profileCardData?.name}}</h1>
            <h2 [ngClass]="(profileCardData?.image != 'lastnull') ? 'profile-role' : 'profile-explore'" >{{profileCardData?.role}}</h2>
        
            <p class="profile-school">{{profileCardData?.major}}<span *ngIf="profileCardData?.major">,</span> {{profileCardData?.school}}<span *ngIf="profileCardData?.year">,</span> {{profileCardData?.year}}</p>
        </div>
        
        <button (click)="goToProfile(profileCardData?.slug)" class="profile-button"  rp-button size="medium" type="accent">{{profileCardData?.image != 'lastnull' ? 'View Profile' : 'Explore Community'}}</button>
    </div>
    
</div>
