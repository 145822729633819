<navbar></navbar>
<div class="navbarCover"></div>
<p class="headline">Thanks for helping to test the {{ service.project_name }}!</p>
<p class="agree">
    <input type="checkbox" [(ngModel)]="checked" /> I agree to the <span class="link" (click)="data = text;" data-toggle="modal" data-target="#agreementModal">Software Beta Tester Nondisclosure Agreement</span> and the <span (click)="data = text3;" class="link"
        data-toggle="modal" data-target="#agreementModal">Website Terms of Use</span>
</p>
<div class="text-center">
    <button class="btn btn-default login" (click)="agree()">Continue</button>
</div>
<div id="agreementModal" class="modal fade" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content" style="border-radius: 0px;">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <h4 class="modal-title">Agreement</h4>
            </div>
            <div class="modal-body" [innerHTML]="data">
            </div>
            <div class="modal-footer">
                <button class="btn btn-default" data-dismiss="modal">OK</button>
            </div>
        </div>
    </div>
</div>
<style>
    @font-face {
        font-family: OpenSans-Regular;
        src: url('/assets/fonts/new/opensans-regular-webfont.eot?#iefix') format('embedded-opentype'), /* IE */
        url('/assets/fonts/new/opensans-regular-webfont.ttf') format("truetype");
        /* non-IE */
    }
    
    @font-face {
        font-family: OpenSans-Light;
        src: url('/assets/fonts/new/opensans-light-webfont.eot?#iefix') format('embedded-opentype'), /* IE */
        url('/assets/fonts/new/opensans-light-webfont.ttf') format("truetype");
        /* non-IE */
    }
    
    @font-face {
        font-family: OpenSans-Bold;
        src: url('/assets/fonts/new/opensans-bold-webfont.eot?#iefix') format('embedded-opentype'), /* IE */
        url('/assets/fonts/new/opensans-bold-webfont.ttf') format("truetype");
        /* non-IE */
    }
    
    @font-face {
        font-family: OpenSans-ExtraBold;
        src: url('/assets/fonts/new/opensans-extrabold-webfont.eot?#iefix') format('embedded-opentype'), /* IE */
        url('/assets/fonts/new/opensans-extrabold-webfont.ttf') format("truetype");
        /* non-IE */
    }
    
    @font-face {
        font-family: OpenSans-Semibold;
        src: url('/assets/fonts/new/opensans-semibold-webfont.eot?#iefix') format('embedded-opentype'), /* IE */
        url('/assets/fonts/new/opensans-semibold-webfont.ttf') format("truetype");
        /* non-IE */
    }
    
    * {
        font-family: OpenSans-Semibold;
        color: gray;
    }
    
    .navbarCover {
        background-color: transparent;
        height: 60px;
        position: absolute;
        width: 100%;
        top: 0;
    }
    
    .modal-body {
        height: calc(100vh - 183px);
        overflow-y: auto;
    }
    
    .modal-header {
        background-color: #ffe284;
    }
    
    .text-center {
        padding-top: 30px;
    }
    
    .login {
        background-color: #ffe284;
        font-family: OpenSans-Bold;
    }
    
    .headline {
        font-size: 16px;
        text-align: center;
        margin-top: 50px;
    }
    
    .link {
        color: #4688F0;
        text-align: center;
        cursor: pointer;
    }
    
    .agree {
        text-align: center;
        padding-top: 15px;
    }
</style>