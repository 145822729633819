/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./alert-c.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./alert-c.component";
import * as i4 from "../projectservice";
import * as i5 from "@angular/router";
import * as i6 from "@angular/platform-browser";
var styles_AlertCComponent = [i0.styles];
var RenderType_AlertCComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AlertCComponent, data: { "animation": [{ type: 7, name: "hideShowAnimator", definitions: [{ type: 0, name: "true", styles: { type: 6, styles: { opacity: 1 }, offset: null }, options: undefined }, { type: 0, name: "false", styles: { type: 6, styles: { opacity: 0 }, offset: null }, options: undefined }, { type: 1, expr: "0 => 1", animation: { type: 4, styles: null, timings: ".5s" }, options: null }, { type: 1, expr: "1 => 0", animation: { type: 4, styles: null, timings: ".9s" }, options: null }], options: {} }] } });
export { RenderType_AlertCComponent as RenderType_AlertCComponent };
function View_AlertCComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", "!\u00A0"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); }); }
function View_AlertCComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "c-alert"]], [[24, "@hideShowAnimator", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "button", [["class", "close c-close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismissAlert() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertCComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 0, "span", [["class", "desc"]], [[8, "innerHTML", 1]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickedelem($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.title; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showAlert; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "alert ", _co.typeoferror[_co.typeoferrorIndex], " "); _ck(_v, 1, 0, currVal_1); var currVal_3 = _co.description; _ck(_v, 6, 0, currVal_3); }); }
function View_AlertCComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "body"]], null, null, null, null, null))], null, null); }
export function View_AlertCComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertCComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertCComponent_3)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showAlert; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.showAlert; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_AlertCComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-alert-c", [], null, null, null, View_AlertCComponent_0, RenderType_AlertCComponent)), i1.ɵdid(1, 114688, null, 0, i3.AlertCComponent, [i4.ProjectService, i5.Router, i6.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlertCComponentNgFactory = i1.ɵccf("app-alert-c", i3.AlertCComponent, View_AlertCComponent_Host_0, {}, {}, []);
export { AlertCComponentNgFactory as AlertCComponentNgFactory };
