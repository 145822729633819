<navbar></navbar>
<div class="container" *ngIf="show">
    <h2 class="text-center">{{techer_name}}</h2>
    <!-- <h2 #ta class="text-center" *ngIf="!isCustomDashboardName">{{techer_name}}</h2> -->
    <div class="text-center" style="margin-bottom: 15px;" *ngIf="isHeadTA" >
        <button *ngIf="!showEdit" class="btn btn-default" (click)="showEdit = true;">Edit title <i class="glyphicon glyphicon-pencil"></i></button>
        <div *ngIf="showEdit">
            <input type="text" [(ngModel)]="techer_name" class="form-control" style="width: 60%; display: inline-block;" />  <i (click)="patchDashboardTitle()" class="glyphicon glyphicon-ok"></i>
        </div>
    </div>
    <table *ngIf="students.length > 0" class="table table-striped table-bordered" style="width:100%;">
        <tr>
            <th>Student Name</th>
            <th>Project Name</th>
            <th>Link to Project</th>
            <th>Reviewers Summary</th>
            <th>Publishing Status</th>
        </tr>
        <tr *ngFor="let item of students">
            <td style="text-align: center;">
                <a routerLink="/userprofile/{{item.student_slug}}">{{item.student_name}}</a>
            </td>
            <td>
                <ol *ngIf="item.projects.length > 0">
                    <li *ngFor="let project of item.projects">
                        <td>{{project.project_name}}</td>
                    </li>
                </ol>
                <span *ngIf="item.projects.length == 0">No Project Started</span>
            </td>
            <td>
                <ol *ngIf="item.projects.length > 0">
                    <li *ngFor="let project of item.projects">
                        <a routerLink="/project/{{project.project_name_slug}}">View project</a>
                    </li>
                </ol>
                <span *ngIf="item.projects.length == 0">Not available</span>
            </td>
            <td>
                <ol *ngIf="item.projects.length > 0">
                    <li *ngFor="let project of item.projects">
                        <span class="colorCode" *ngIf="project.project_review.length > 0" [style.background-color]="getColorStatus(project.project_review[0].reviewers_summary)"></span>
                        <span *ngIf="project.project_review[0]?.reviewers_summary">{{project.project_review[0].reviewers_summary}}</span>
                        <span style="font-style: italic;" *ngIf="!project.project_review[0]?.reviewers_summary">No summary added</span>
                    </li>
                </ol>
                <span *ngIf="item.projects.length == 0">Not available</span>
            </td>
            <td>
                <ol *ngIf="item.projects.length > 0">
                    <li *ngFor="let project of item.projects">
                        {{project.project_publish_status}}
                    </li>
                </ol>
                <span *ngIf="item.projects.length == 0">Not available</span>
            </td>
    </table>
    <h3 class="text-center" *ngIf="students.length == 0">No data found...</h3>
</div>