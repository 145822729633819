<navbar [addOrg]="addorganization" [checkUserStateInput]="loginNow"></navbar>
<div style="margin-bottom: 50px;">
    <div *ngIf="!loginStatus" class="col-sm-8 col-sm-offset-2">
        <div class="text-center">
            <h4 style="color: gray;font-family: OpenSans-Semibold;font-size: 16px;">You must be logged in to claim this profile.</h4>
            <input type="text" class="form-control" [(ngModel)]="loginObject.email" placeholder="Enter email" style="width: 30%; display: inline-block;" />
            <input type="password" class="form-control margin-left" [(ngModel)]="loginObject.password" placeholder="Enter password" style="width: 30%; display: inline-block;" />
            <button class="btn btn-default login margin-left" (click)="login()">Login</button>
        </div>
        <br>
        <br>
        <regForm></regForm>
    </div>
    <div *ngIf="loginStatus && !created" class="col-sm-6 col-sm-offset-3" style="text-align: center;">
        <h3 style="color: gray;font-family: OpenSans-Semibold;margin-bottom: 30px;">Claim Organization Profile</h3>
        <div class="form-group">
            <input #name type="text" class="textbox form-control" [(ngModel)]="companyname" id="exampleInputEmail1" placeholder="Enter organization name" style="color: black;" disabled>
        </div>
        <div class="form-group">
            <my-dropdown [selectedOptions]="companyRole" [defaultText]="'Organization role'" [myOptions]="companyRoleOptions" (selectedArray)="onChangedd($event)"></my-dropdown>
        </div>
        <div class="form-group" *ngIf="otherRoleDialog">
            <input #otherCategory style="color: black;" [(ngModel)]="otherCat" type="text" class="textbox form-control" id="otherCat" placeholder="Enter Other Category" [ngModelOptions]="{standalone: true}">
        </div>
        <br>
        <div class="checkboxdiv">
            <div>
                <div>
                    <label class="checkbox-inline" style="font-size: 14px; text-align:left;"><input type="checkbox" name="personType" [(ngModel)]="checked" />
        Yes! I verify that I'm a representative of this organization <br>and have the authorization to act on their behalf.</label>
                </div>
                <div class="form-group text-center" *ngIf="checked" style="margin-top: 20px; font-family: OpenSans-Regular;">
                    <input #name type="text" class="textbox form-control" id="email" [(ngModel)]="email" placeholder="Enter your organization email" style="color: black;">
                </div>
                <div style="margin-top: 20px;">
                    <button class="btn btn-default login" (click)="claimCompany()">Claim Profile</button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="loginStatus && createdByRep" class="col-sm-10 col-sm-offset-1">
        <p style="padding: 15px; color: black;font-family: OpenSans-Regular; text-align: center;">
            A verification code has been sent to the email provided. Please enter the code to continue.
        </p>
        <div class="form-group text-left" *ngIf="checked" style="float: left; width: 100%; font-family: OpenSans-Regular;">
            <div class="text-center col-sm-6 col-sm-offset-3">
                <input #name type="text" class="form-control" id="verify_code" [(ngModel)]="verify_code" placeholder="Enter code" style="color: black; width: 96px; display: inline-block;text-align: center;">
            </div>
        </div>
        <p class="text-center">
            <button class="btn btn-default login" (click)="verifyCode()">Verify code</button>
        </p>
    </div>
    <p *ngIf="loginStatus && verified" style="font-family: OpenSans-Semibold;font-size: 20px;text-align:center;margin-top: 50px;">
        Congratulations! The profile for {{companyname}} now belongs to you. <a style="color: #4688F0;" routerLinkActive="active" routerLink="/companyprofile/{{org_name_slug}}">Click here</a> to view profile.
    </p>
    <div *ngIf="loginStatus && verified" style="font-family: OpenSans-Semibold;" class="col-sm-8 col-sm-offset-2">
    </div>
</div>

<style>
    .margin-left {
        margin-left: 10px;
    }
    
    sup {
        color: red;
    }
    
    .createOrganizationcontent {
        padding: 20px;
        background-color: lightgray;
        margin-top: 20px;
    }
    
    @font-face {
        font-family: OpenSans-Regular;
        src: url('/assets/fonts/new/opensans-regular-webfont.eot?#iefix') format('embedded-opentype'), /* IE */
        url('/assets/fonts/new/opensans-regular-webfont.ttf') format("truetype");
        /* non-IE */
    }
    
    @font-face {
        font-family: OpenSans-Light;
        src: url('/assets/fonts/new/opensans-light-webfont.eot?#iefix') format('embedded-opentype'), /* IE */
        url('/assets/fonts/new/opensans-light-webfont.ttf') format("truetype");
        /* non-IE */
    }
    
    @font-face {
        font-family: OpenSans-Bold;
        src: url('/assets/fonts/new/opensans-bold-webfont.eot?#iefix') format('embedded-opentype'), /* IE */
        url('/assets/fonts/new/opensans-bold-webfont.ttf') format("truetype");
        /* non-IE */
    }
    
    @font-face {
        font-family: OpenSans-ExtraBold;
        src: url('/assets/fonts/new/opensans-extrabold-webfont.eot?#iefix') format('embedded-opentype'), /* IE */
        url('/assets/fonts/new/opensans-extrabold-webfont.ttf') format("truetype");
        /* non-IE */
    }
    
    @font-face {
        font-family: OpenSans-Semibold;
        src: url('/assets/fonts/new/opensans-semibold-webfont.eot?#iefix') format('embedded-opentype'), /* IE */
        url('/assets/fonts/new/opensans-semibold-webfont.ttf') format("truetype");
        /* non-IE */
    }
    
    * {
        color: gray;
    }
    
    .form-group label {
        font-family: OpenSans-Semibold;
    }
    
    .video {
        height: 400px;
        border: 1px solid;
    }
    
    .checkboxdiv {
        text-align: center;
        font-family: OpenSans-Semibold;
    }
    
    .signupbox {
        background-color: #d6d6d6;
        text-align: center;
        padding: 18px;
    }
    
    .login {
        background-color: #ffe284;
        margin-top: -2px;
    }
    
    .heading {
        color: #757575;
        font-family: OpenSans-Regular !important;
        padding-bottom: 20px;
        margin-bottom: 20px;
        padding-left: 15px;
        padding-right: 15px;
        text-align: center;
        margin-top: 100px;
    }
    
    .textbox {
        display: inline-block;
        width: 50%;
    }
</style>