<div *ngIf="showTexbox" [@fadeInOut]="showTexbox">
    <div class="fadeinout" [@fadeInOut]="!EditToggle" *ngIf="!EditToggle">
        <div *ngIf="mx.type == 'textbox' || mx.type == 'radio' || mx.type == 'select'">
            <p class="regular">
                {{ mx.data }}
                <span *ngIf="mx.type == 'select'">employees</span>
                <span *ngIf="edit" (click)="EditToggle = !EditToggle" class="glyphicon glyphicon-pencil"></span>
            </p>
        </div>
        <div *ngIf="mx.type == 'textarea'">
            <p class="regular" [innerHTML]="service.addLineBreaks(mx.data) | linky:{stripPrefix: false}"></p>
            <span *ngIf="edit" (click)="showText($event)" class="glyphicon glyphicon-pencil" [ngClass]="{'enhanced-pencil': mx.enhanced && !service.isProfileEnhanced}"></span>
        </div>
        <div *ngIf="mx.type == 'multitextbox'">
            <ul style="width: 90%; display: inline-block;">
                <li *ngFor="let item of certifications">
                    {{item.certification}} - {{item.location}}
                </li>
            </ul>
            <span *ngIf="edit" (click)="showText($event)" class="glyphicon glyphicon-pencil"></span>
        </div>
        <div *ngIf="mx.type == 'org_audio_video'" style="margin-top: 5px;">
            <span *ngIf="edit" (click)="showText($event)" style="float: right;" class="glyphicon glyphicon-pencil" [ngClass]="{'enhanced-pencil': mx.enhanced && !service.isProfileEnhanced}"></span>
            <p *ngIf="audio">
                <span style="font-family: OpenSans-Bold;">Audio</span>
                <!-- <i (click)="deleteMedia('org_about_audio')" style="float: right;" class="glyphicon glyphicon-trash"></i> -->
                <audio controls style="width: 100%;">
          <source src="{{audio}}" type="audio/mp3" /> Your browser does not support the audio element.
        </audio>
            </p>
            <p *ngIf="video">
                <span style="font-family: OpenSans-Bold;color: #333;font-size: 14px;">Video</span>
                <!-- <i (click)="deleteMedia('org_about_video')" style="float: right;" class="glyphicon glyphicon-trash"></i> -->
                <video controls style="width: 100%;">
          <source src="{{video}}" type="video/mp4" /> Your browser does not support the video element.
        </video>
            </p>
            <div *ngIf="external_video">
                <!-- <i (click)="deleteMedia('org_external_video')" style="float: right;" class="glyphicon glyphicon-trash"></i> -->
                <span style="font-family: OpenSans-Bold;color: #333;font-size: 14px;">External Video</span>
                <div style="display: block" class="intrinsic-container intrinsic-container-16x9">
                    <div id="vide" style="width: 100%; height: 100%; position: absolute;" [innerHTML]="external_video"></div>
                </div>
            </div>
        </div>
        <div *ngIf="mx.type == 'org_photos'">
            <span style="font-family: OpenSans-Bold;color: #333;font-size: 14px;">Photos</span>
            <span *ngIf="edit" (click)="showText($event)" style="float: right;" class="glyphicon glyphicon-pencil" [ngClass]="{'enhanced-pencil': mx.enhanced && !service.isProfileEnhanced}"></span>
            <div style="overflow-x: auto; white-space: nowrap;" *ngIf="org_photos.length > 0">
                <div class="orgImg" *ngFor="let item of org_photos; let i = index">
                    <i *ngIf="edit" class="glyphicon glyphicon-trash" (click)="deleteOrgPhoto(i, item.id)"></i>
                    <div class="images" data-toggle="modal" data-target="#myModal" (click)="openModal(item.org_image, i)">
                        <img src="{{ item.org_image }}" />
                    </div>
                </div>
            </div>
            <div *ngIf="photo_url" id="myModal" class="modal fade" role="dialog">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content" style="height: 93%;">
                        <div class="modal-body" style="height: 100%;text-align: center;">
                            <ngx-image-viewer [src]="photos" [(index)]="imageIndex"></ngx-image-viewer>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="mx.type == 'org_awards_distinctions'">
            <ul style="width: 90%; display: inline-block;">
                <li *ngFor="let item of awardsDist">
                    <a target="_blank" href="{{item.link}}">{{item.name}}</a>
                </li>
            </ul>
            <span *ngIf="edit" (click)="showText($event)" class="glyphicon glyphicon-pencil"></span>
        </div>
        <div *ngIf="mx.type == 'org_headquarters_location'">
            <p>{{ mx.data }}</p>
            <span *ngIf="edit" (click)="showText($event)" class="glyphicon glyphicon-pencil"></span>
        </div>
        <div *ngIf="mx.type == 'our_leadership_team'">
            <ul style="width: 90%; display: inline-block;">
                <li *ngFor="let item of leadership_roles">
                        <a routerLink="/userprofile/{{item.full_name_slug}}">{{item.name}}</a> - {{item.role}}
                    <i *ngIf="edit" class="glyphicon glyphicon-minus-sign" (click)="removeLeader(i)"></i>
                </li>
            </ul>
            <span *ngIf="edit" (click)="showText($event)" class="glyphicon glyphicon-pencil"></span>
        </div>
    </div>
    <div [@fadeInOut]="EditToggle" *ngIf="EditToggle">
        <div *ngIf="mx.type == 'textarea'">
            <p *ngIf="mx.fieldname != 'org_story' && mx.fieldname != 'org_offered_services'" style="font-family: OpenSans-Light; font-style: italic; color:black;">enter 1 to 3 sentences</p>
            <p *ngIf="mx.fieldname == 'org_story'" style="font-family: OpenSans-Light; font-style: italic; color:black;">Be as lengthy as you'd like.</p>
            <textarea #desc value="" placeholder="Enter information" [ngModel]="service.removeLineBreaks(mx.data)"
            style="max-width: 100%; width: 95%;"
            ></textarea>
            <i class="glyphicon glyphicon-check dsc-glyp" (click)="onEnterDesc(desc.value)"></i>
        </div>
        <div *ngIf="mx.type == 'select'">
            <select #sel (change)="sizeChange(sel.value)" class="form-control">
        <option value="">Select</option>
        <option value="1 - 10">1 - 10</option>
        <option value="11 - 50">11 - 50</option>
        <option value="51 - 200">51 - 200</option>
        <option value="201 - 500">201 - 500</option>
        <option value="501 - 1000">501 - 1000</option>
        <option value="1,001 - 10,000">1,001 - 10,000</option>
        <option value="10,000+">10,000+</option>
      </select>
        </div>
        <div *ngIf="mx.type == 'textbox'">
            <input #year type="text" class="form-control" placeholder="Enter year" style="width:85%;display:inline-block" />
            <i class="glyphicon glyphicon-ok" (click)="onEnterDesc(year.value)"></i>
        </div>
        <div *ngIf="mx.type == 'multitextbox'">
            <ul style="margin-bottom: 5px;">
                <li *ngFor="let item of certifications; let i = index">
                    {{item.certification}} - {{item.location}}
                    <i class="glyphicon glyphicon-minus-sign" (click)="removeCertification(i)"></i>
                </li>
            </ul>
            <select #certiType [(ngModel)]="certiName" class="form-control" style="width: 85%; margin-bottom: 5px;">
        <option value="">Select</option>
        <option *ngFor="let item of certidd" value="{{item}}">{{item}}</option>
      </select>
            <input *ngIf="certiName == 'Other'" [(ngModel)]="certiOther" type="text" class="form-control" placeholder="Enter name of certification" style="width:85%;display:inline-block; margin-bottom:5px;" />
            <input id="autocompleteinput" [(ngModel)]="certiLocation1" type="text" class="form-control" placeholder="Enter state/location where applicable" style="width:85%;display:inline-block" />
            <i class="glyphicon glyphicon-ok" (click)="onEnterDesc1(certiType.value, certiLocation1)"></i>
        </div>
        <div *ngIf="mx.type == 'our_leadership_team'">
            <ul style="margin-bottom: 5px;">
                <li *ngFor="let item of leadership_roles; let i = index">
                    <a routerLink="/userprofile/{{item.full_name_slug}}">{{item.name}}</a> - {{item.role}}
                    <i class="glyphicon glyphicon-minus-sign" (click)="removeLeader(i)"></i>
                </li>
            </ul>
            <input (keyup)="searchTerm$.next($event.target.value)" [(ngModel)]="model1" type="text" class="form-control" placeholder="Enter name" style="width:85%;display:inline-block; margin-bottom:5px;" />
            <ul *ngIf="arrayOfStrings.length > 0" class="contributor-ul">
                <li *ngFor="let result of arrayOfStrings | slice:0:9">
                    <div style="padding: 5px 0; cursor: pointer;" (mousedown)="myCallback(result, 1)">
                        <img src='{{result.profile_image}}' onError="this.src='assets/images/default.png'" style='width:30px;height:30px;border-radius:50%; margin-top: -3px;' /> {{result.fullname}}
                    </div>
                </li>
              </ul>
            <select [(ngModel)]="leaderName" class="form-control" style="width: 85%; margin-bottom: 5px; display: inline-block;">
                <option value="">Select</option>
                <option value="CEO">CEO</option>
                <option value="President">President</option>
                <option value="Board Member">Board Member</option>
                <option value="Other">Other</option>
            </select>
            <input *ngIf="leaderName == 'Other'" [(ngModel)]="leaderOther" type="text" class="form-control" placeholder="Enter leader type" style="width:85%;display:inline-block; margin-bottom:5px;" />
            <i class="glyphicon glyphicon-ok" (click)="updateLeaders()"></i>
        </div>
        <div *ngIf="mx.type == 'radio'">
            <!-- <label class="radio-inline">
        <input type="radio" name="optradio1" value="true" (change)="changePE($event)" [checked]="mx.data == 'Yes'">Yes</label>
            <label class="radio-inline">
        <input type="radio" name="optradio1" value="false" (change)="changePE($event)" [checked]="mx.data == 'No'">No</label> -->
            <select (change)="changePE($event)" [(ngModel)]="mx.data" class="form-control">
                <option value="">Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
            </select>
        </div>
        <div *ngIf="mx.type == 'org_audio_video'" style="margin-top: 5px;">
            <p *ngIf="audio">
                <span style="font-family: OpenSans-Bold;">Audio</span>
                <i (click)="deleteMedia('org_about_audio')" style="float: right;" class="glyphicon glyphicon-trash"></i>
                <audio controls style="width: 100%;">
          <source src="{{audio}}" type="audio/mp3" /> Your browser does not support the audio element.
        </audio>
            </p>
            <p *ngIf="video">
                <span style="font-family: OpenSans-Bold;color: #333;font-size: 14px;">Video</span>
                <i (click)="deleteMedia('org_about_video')" style="float: right;" class="glyphicon glyphicon-trash"></i>
                <video controls style="width: 100%;">
          <source src="{{video}}" type="video/mp4" /> Your browser does not support the video element.
        </video>
            </p>
            <p *ngIf="external_video">
                <i (click)="deleteMedia('org_external_video')" style="float: right;" class="glyphicon glyphicon-trash"></i>
                <span style="font-family: OpenSans-Bold;color: #333;font-size: 14px;">External Video</span>
                <span style="display: block" class="intrinsic-container intrinsic-container-16x9">
          <div style="width: 100%; height: 100%; position: absolute;" [innerHTML]="external_video"></div>
        </span>
            </p>
            <p style="font-family: OpenSans-Bold;">
                <!-- <span style="margin-right: 5px;">Upload Audio/Video File</span> -->
                <button [disabled]="abc" (click)="choose1(file1)">Choose file</button>
                <input #file1 type="file" accept="audio/*,video/*" multiple="false" (change)="mediaSelected1($event)" style="display: inline-block; font-family: OpenSans-Light;visibility: hidden;" />
                <br>OR
                <br>
                <input type="text" placeholder="Enter complete Youtube/Vimeo video url" [(ngModel)]="videoURL" (keyup)="videoURL.length > 0 ? abc = true : abc = false" [disabled]="evt" style="width: 100%;">
                <br>
                <br>
                <button (click)="mediaSelected(evt)" class="editToggle btn btn-default" *ngIf="evt">
          <i id="btn" class="glyphicon glyphicon-upload"></i> Upload Video</button>
                <button (click)="externalMediaSelected()" class="editToggle btn btn-default" *ngIf="!evt">
          <i id="btn1" class="glyphicon glyphicon-upload"></i> Upload Video</button>
            </p>
        </div>
        <div *ngIf="mx.type == 'org_photos'">
            <span style="font-family: OpenSans-Bold;color: #333;font-size: 14px;">Photos</span>
            <div style="overflow-x: auto; white-space: nowrap; margin-bottom: 10px;" *ngIf="org_photos.length > 0">
                <div class="orgImg" *ngFor="let item of org_photos; let i = index;">
                    <div class="images" data-toggle="modal" data-target="#myModal" (click)="openModal(item.org_image, i)">
                        <img src="{{ item.org_image }}" />
                    </div>
                </div>
            </div>
            <p style="font-family: OpenSans-Bold;">
                <!-- <span style="margin-right: 5px;">Add a Photo</span> -->
                <button *ngIf="!evt1" (click)="choose1(file2)">Choose file</button>
                <input #file2 type="file" accept="image/*" multiple="false" (change)="photosSelected($event)" style="display: inline-block; font-family: OpenSans-Light; visibility: hidden;" />
                <br>
                <button *ngIf="evt1" (click)="photosUpload()" class="editToggle btn btn-default">
          <i id="btn2" style="padding-left: 0px;" class="glyphicon glyphicon-upload"></i> Upload Photo </button>
            </p>
            <div *ngIf="photo_url" id="myModal" class="modal fade" role="dialog">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-body" style="text-align: center;">
                            <ngx-image-viewer [src]="photos" [(index)]="imageIndex"></ngx-image-viewer>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="mx.type == 'org_awards_distinctions'">
            <ul style="margin-bottom: 5px;">
                <li *ngFor="let item of awardsDist; let i = index">
                    <a href="{{item.link}}">{{item.name}}</a>
                    <i class="glyphicon glyphicon-minus-sign" (click)="removeAwardsDistinct(i)"></i>
                </li>
            </ul>
            <input [(ngModel)]="awardsDistinction.awardName" type="text" class="form-control" placeholder="Enter award/distinction name" style="width:85%;display:inline-block; margin-bottom:5px;" />
            <input [(ngModel)]="awardsDistinction.link" type="text" class="form-control" placeholder="Enter link to annnouncement" style="width:85%;display:inline-block" />
            <i class="glyphicon glyphicon-ok" (click)="addAwardsDistinction()"></i>
        </div>
        <div *ngIf="mx.type == 'org_headquarters_location'">
            <input #headquarters id="autocompleteinput1" type="text" value="{{mx.data}}" class="form-control" placeholder="Enter state/location" style="width:85%;display:inline-block" />
            <i class="glyphicon glyphicon-ok" (click)="addHeadquarter()"></i>
        </div>
        <!-- <div *ngIf="mx.type == 'org_our_commitments'">

      <span></span>
      <label class="radio-inline">
        <input type="radio" name="optradio1" (change)="changeWillingness($event)" value="true">Yes</label>
      <label class="radio-inline">
        <input type="radio" name="optradio1" (change)="changeWillingness($event)" value="false">No</label>
    </div> -->
    </div>
</div>
<div *ngIf="!showTexbox && mx.type != 'org_audio_video' && mx.type != 'org_photos'" [@fadeInOut]="!showTexbox">
    <p style="font-style: italic;">Edit to add more information
        <i *ngIf="edit" class="glyphicon glyphicon-pencil" (click)="showText($event)" [ngClass]="{'enhanced-pencil': mx.enhanced && !service.isProfileEnhanced}"></i>
    </p>
</div>
<div *ngIf="!showTexbox && (mx.type == 'org_audio_video' || mx.type == 'org_photos')" [@fadeInOut]="!showTexbox">
    <p style="font-style: italic;">Edit to add
        <i *ngIf="edit" class="glyphicon glyphicon-pencil" [ngClass]="{'enhanced-pencil': mx.enhanced && !service.isProfileEnhanced}" (click)="showText($event)"></i>
    </p>
</div>
<app-confirm-c [sanket]="confirmObject" (performAction)="confirmedAction($event)"></app-confirm-c>

<!-- Modal -->
<div *ngIf="photo_url" id="myModal1" class="modal fade" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body" style="text-align: center;">
                <img src="{{ photo_url }}" style="max-width: 100%; max-height: 100%;">
            </div>
        </div>
    </div>
</div>
<style>
    .contributor-ul {
        width: 75%;
        list-style-type: none;
        padding: 0px;
        position: absolute;
        z-index: 1;
        background: #eee;
    }

    .viewer {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
    
    .orgImg {
        width: 45%;
        display: inline-block;
    }
    
    .orgImg:not(:last-child) {
        margin-right: 10px;
    }
    
    .images {
        /* display: inline-flex;
    padding: 10px;
    width: 150px;
    height: 150px; */
        width: 100%;
        padding-top: 75%;
        position: relative;
        background: rgba(212, 212, 212, .4);
    }
    
    .images img {
        /* width: 100%;
    height: 100%;
    object-fit: cover; */
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        max-height: 100%;
        max-width: 100%;
        display: block;
        margin: auto;
    }
    
    .intrinsic-container {
        position: relative;
        height: 0;
        overflow: hidden;
    }
    /* 16x9 Aspect Ratio */
    
    .intrinsic-container-16x9 {
        padding-bottom: 56.25%;
    }
    /* 4x3 Aspect Ratio */
    
    .intrinsic-container-4x3 {
        padding-bottom: 75%;
    }
    
    .intrinsic-container iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    
    .glyphicon-refresh-animate {
        animation: spin .7s infinite linear;
        -webkit-animation: spin2 .7s infinite linear;
    }
    
    @-webkit-keyframes spin2 {
        from {
            -webkit-transform: rotate(0deg);
        }
        to {
            -webkit-transform: rotate(360deg);
        }
    }
    
    @keyframes spin {
        from {
            transform: scale(1) rotate(0deg);
        }
        to {
            transform: scale(1) rotate(360deg);
        }
    }
    
    .enhanced-pencil {
        background: skyblue;
        padding: 6px;
        border-radius: 50%;
        color: black;
        margin-left: 5px;
    }
    
    .dsc-glyp {
        vertical-align: top !important;
    }
    
    i {
        padding-left: 5px;
    }
    
    .regular {
        font-family: open-regular;
    }
    
    .water-res {
        font-family: OpenSans-Semibold;
        color: #929292;
        margin: 0 0 0px;
        font-size: 13px;
    }
    
    .exp-desc {
        color: #a2a8a8;
        font-size: 13px;
    }
    
    .para-title {
        padding-left: 30px;
        font-size: 13px;
        color: grey;
    }
    
    textarea {
        max-width: 500px;
        width: 90%;
        height: 100px;
        -moz-border-bottom-colors: none;
        -moz-border-left-colors: none;
        -moz-border-right-colors: none;
        -moz-border-top-colors: none;
        background: none repeat scroll 0 0 rgba(0, 0, 0, 0.07);
        border-color: -moz-use-text-color #FFFFFF #FFFFFF -moz-use-text-color;
        border-image: none;
        border-radius: 6px 6px 6px 6px;
        border-style: none solid solid none;
        border-width: medium 1px 1px medium;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12) inset;
        color: #555555;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 1em;
        line-height: 1.4em;
        padding: 5px 8px;
        transition: background-color 0.2s ease 0s;
    }
    
    textarea:focus {
        background: none repeat scroll 0 0 #FFFFFF;
        outline-width: 0;
    }
    
    @font-face {
        font-family: open-regular;
        src: url('/assets/fonts/new/opensans-regular-webfont.eot?#iefix') format('embedded-opentype'), /* IE */
        url('/assets/fonts/new/opensans-regular-webfont.ttf') format("truetype");
        /* non-IE */
    }
    
    @font-face {
        font-family: OpenSans-Semibold;
        src: url('/assets/fonts/new/opensans-semibold-webfont.eot?#iefix') format('embedded-opentype'), /* IE */
        url('/assets/fonts/new/opensans-semibold-webfont.ttf') format("truetype");
        /* non-IE */
    }
    
    @font-face {
        font-family: OpenSans-Bold;
        src: url('/assets/fonts/new/opensans-bold-webfont.eot?#iefix') format('embedded-opentype'), /* IE */
        url('/assets/fonts/new/opensans-bold-webfont.ttf') format("truetype");
        /* non-IE */
    }
</style>