<div class="border whole-div">
  <div class="payments">
    <!-- <input type="radio" name="1" (click)="pay = 1;" >
    <img src="assets/images/payments/american.png" class="img-pay" height="85">
    <img src="assets/images/payments/mastercard.png" class="img-pay" height="60">
    <img src="assets/images/payments/Visa.png" class="img-pay" height="80">
    <img src="assets/images/payments/discover.png" class="img-pay" height="62"> -->
    <input type="radio" class="radio2" name="1" (click)="pay = 2;" checked>
    <span class="checkmark"></span>
    <img src="assets/images/payments/paypal.png" class="img-pay dis" height="62">
    <span class="checkmark"></span>
  </div>
  <hr class="hrs">
  <!--  -->
  <!-- <div *ngIf="pay == 1">
    <div class="">
      <div class="form-group row form-grp">
        <div class="col-xs-6">
          <label for="ex3">First Name *</label>
          <input class="form-control" type="text">
        </div>
        <div class="col-xs-6">
          <label for="ex3">Last Name *</label>
          <input class="form-control" type="text">
        </div>

      </div>
      <div class="form-group row form-grp">
        <div class="col-xs-6">
          <label for="ex3">Credit or debit card Number *</label>
          <input class="form-control" type="text">
        </div>
        <div class="col-xs-2">
          <label for="sel1">Expiration Date *</label>
          <select class="form-control" id="sel1">
            <option>MM</option>
            <option>01</option>
            <option>02</option>
            <option>03</option>
            <option>05</option>
            <option>06</option>
            <option>07</option>
            <option>08</option>
            <option>09</option>
            <option>10</option>
            <option>11</option>
            <option>12</option>
          </select>
        </div>
        <div class="col-xs-2">
          <label for="sel1" class="yr"></label>
          <select class="form-control" id="sel1">
            <option>YYYY</option>
            <option>2018</option>
            <option>2019</option>
            <option>2020</option>
          </select>
        </div>
        <div class="col-xs-2">
          <label for="ex3">Security Code *</label>
          <input class="form-control" type="password" minlength="3">
        </div>
      </div>
      <div class="form-group row form-grp">
        <div class="col-xs-6">
          <label for="sel1">Country *</label>
          <select class="form-control" id="sel1">
            <option>United States</option>
            <option>India</option>
            <option>England</option>
            <option>Germany</option>
          </select>
        </div>
        <div class="col-xs-6">
          <label for="ex3">Postal Code *</label>
          <input class="form-control" type="text">
        </div>
      </div>
    </div>
    <div class="grp-btns text-center">
      <button type="button" class="btn btn-primary btn-review btns">Review Order</button>
      <button type="button" class="btn btn-default btn-cancel btns">Cancel</button>
    </div>
  </div> -->
  <div style="margin: 30px;" *ngIf="pay == 2">
    <div>
      <p class="text-center">
        Click
        <span class="continue">PayPal Checkout</span> to checkout as a guest or log in to confirm your purchase.
        <!-- You'll be sent back to this page to finish up. -->
      </p>
      <div class="grp-btns text-center">
        <!-- <button type="button" class="btn btn-primary btn-review btns">Continue to PayPal</button> -->
        <!-- Basic 6 month  $180 -->
        <!-- <form *ngIf="basic && biannual" #form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
          <input type="hidden" name="cmd" value="_s-xclick">
          <input type="hidden" name="hosted_button_id" value="QYHYQKD5QW2US">
          <table>
            <tr>
              <td>
                <input type="hidden" name="on0" value="Basic - 6 months">Basic - 6 months</td>
            </tr>
          </table>
          <input (click)="form.submit()" type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_buynowCC_LG.gif" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!">
          <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1">
        </form> -->
        <!-- <form #form action="https://www.sandbox.paypal.com/cgi-bin/webscr" method="post" target="_top">
          <input type="hidden" name="cmd" value="_s-xclick">
          <input type="hidden" name="hosted_button_id" value="76WK7SYBZRW3Y">
          <input (click)="form.submit()" type="image" src="https://www.sandbox.paypal.com/en_GB/i/btn/btn_buynowCC_LG.gif" border="0" name="submit" alt="PayPal – The safer, easier way to pay online!">
          <img alt="" border="0" src="https://www.sandbox.paypal.com/en_GB/i/scr/pixel.gif" width="1" height="1">
          </form> -->


        <!-- <input type="number" [(ngModel)]="finalAmount" style="padding-bottom: 10px;"> -->
        <h3>Amount Payable: ${{finalAmount}}</h3>
        <h2 *ngIf="paypalLoad">Paypal button is loading</h2>
        <div id="paypal-checkout-btn"></div>

        <!-- Basic annual  $599 -->
        <!-- <form *ngIf="basic && annual" #formBas action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
          <input type="hidden" name="cmd" value="_s-xclick">
          <input type="hidden" name="hosted_button_id" value="T6CEF32YJ84EG">
          <table>
            <tr>
              <td>
                <input type="hidden" name="on0" value="Basic - Annual">Basic - Annual</td>
            </tr>
          </table>
          <input (click)="formBas.submit()" type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_buynowCC_LG.gif"
            border="0" name="submit" alt="PayPal - The safer, easier way to pay online!">
          <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1">
        </form> -->

        <!-- Premium 6 months  $180 -->
        <!-- <form *ngIf="premium && biannual" #formPrem action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
          <input type="hidden" name="cmd" value="_s-xclick">
          <input type="hidden" name="hosted_button_id" value="VWRGFW2Y7QM7N">
          <table>
            <tr>
              <td>
                <input type="hidden" name="on0" value="Premium - 6 months">Premium - 6 months</td>
            </tr>
          </table>
          <input (click)="formPrem.submit()" type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_buynowCC_LG.gif"
            border="0" name="submit" alt="PayPal - The safer, easier way to pay online!">
          <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1">
        </form> -->

        <!-- Premium – annual  $599 -->
        <!-- <form *ngIf="premium && annual" #formPrem action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
          <input type="hidden" name="cmd" value="_s-xclick">
          <input type="hidden" name="hosted_button_id" value="9Q8UNE42KKU4W">
          <table>
            <tr>
              <td>
                <input type="hidden" name="on0" value="Premium - Annual">Premium - Annual</td>
            </tr>
          </table>
          <input (click)="formPrem.submit()" type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_buynowCC_LG.gif"
            border="0" name="submit" alt="PayPal - The safer, easier way to pay online!">
          <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1">
        </form> -->


        <!-- Platinum – $699.99  - 6 month -->
        <!-- <form *ngIf="platinum && biannual" #formPlat action="https://www.paypal.com/cgi-bin/webscr" method="post"
          target="_top">
          <input type="hidden" name="cmd" value="_s-xclick">
          <input type="hidden" name="hosted_button_id" value="V4PB3T7QS2E96">
          <table>
            <tr>
              <td>
                <input type="hidden" name="on0" value="Platinum - 6 months">Platinum - 6 months</td>
            </tr>
          </table>
          <input (click)="formPlat.submit()" type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_buynowCC_LG.gif"
            border="0" name="submit" alt="PayPal - The safer, easier way to pay online!">
          <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1">
        </form> -->
        <!-- Platinum - $999.99  - annual -->

        <!-- <form *ngIf="platinum && annual" #formPlat action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
          <input type="hidden" name="cmd" value="_s-xclick">
          <input type="hidden" name="hosted_button_id" value="US8NCJTZ4K3H6">
          <table>
            <tr>
              <td>
                <input type="hidden" name="on0" value="Platinum - Annual">Platinum - Annual</td>
            </tr>
          </table>
          <input (click)="formPlat.submit()" type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_buynowCC_LG.gif"
            border="0" name="submit" alt="PayPal - The safer, easier way to pay online!">
          <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1">
        </form> -->
      </div>
    </div>
  </div>
</div>
