import { Subscription } from 'rxjs';
export class SubscriptionsContainer {
    subs = [];

    set add(sub :Subscription){
        this.subs.push(sub);
    }

    dispose(){
        this.subs.forEach(s => s.unsubscribe());
    }
}