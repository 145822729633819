import {
  Component,
  NgZone,
  Input,
  EventEmitter,
  Output,
  ViewEncapsulation, ViewChild, ElementRef, OnChanges
} from '@angular/core';

import {
  trigger,
  state,
  style,
  animate,
  transition,
  keyframes
} from '@angular/animations'


import {
  ProjectService
}
  from './../projectservice';

import * as _ from 'lodash';

import { IMultiSelectOption } from 'angular-2-dropdown-multiselect';
import { Router } from '@angular/router';

@Component({
  selector: 'userprofiletextbox',
  templateUrl: './userprofiletextbox.html',
  animations: [
    trigger('fadeInOut', [
      transition('void => *', [
        style({ opacity: 0 }), //style only for transition transition (after transiton it removes)
        animate(700, style({ opacity: 1 })) // the new state of the transition(after transiton it removes)
      ]),
      transition('* => void', [
        animate(0, style({ opacity: 0 })) // the new state of the transition(after transiton it removes)
      ])
    ])
  ]

})
export class UserProfileTextboxComponent implements OnChanges{

  @Input() mx: any;
  @Input() edit: any;
  @Input() hideToggle: any;
  @Input() fieldname: any;
  @ViewChild('info', { static: false }) Elem : ElementRef;
  textinput: any;

  projectobj: any;
  toggleVisibitly = false;
  ShowWebsiteDiv: boolean = false;
  ShowProjectTypeDiv: boolean = false;
  EditToggle: boolean = false;
  showTexbox: boolean = false;

  ShowWebsiteDivData: boolean = false;
  ShowProjectTypeDivData: boolean = false;
  projecttypeArr: any = [];
  url: string;
  desc: any;
  printArray: any = [];
  data: any;
  data1: any = [];
  varib: boolean = false;
  fields: any;
  txt: any
  info: any;
  tags: any = [];
  exp: any = ["0", "0 - 1", "1 - 2", "2 - 3", "3 - 4", "4 - 5", "5 - 6", "6 - 7", "7 - 8", "8 - 9", "9 - 10", "10+"];
  selectedCareerStatus = [];
  private careerStatus: IMultiSelectOption[] = [
    { id: 'Currently working as engineer', name: 'Currently working as engineer' },
    { id: 'Currently working in engineering-related field', name: 'Currently working in engineering-related field' },
    { id: 'Previously worked as engineer', name: 'Previously worked as engineer' },
    { id: 'Previously worked in engineering-related field', name: 'Previously worked in engineering-related field' },
    { id: 'Never worked as engineer or in engineering-related field', name: 'Never worked as engineer or in engineering-related field' },
    { id: 'Looking for an engineering job', name: 'Looking for an engineering job' }

  ];
  constructor(public service: ProjectService, private router: Router) { }

  // ngOnInit() {
  // }

  onChangedd($event) {
    console.log($event);
    this.selectedCareerStatus = $event;
  }

  gotoPeopleExplorer(item) {
    this.router.navigateByUrl('/explorer/people?search='+item);
  }

  ngDoCheck(): void {
    //Called every time that the input properties of a component or a directive are checked. Use it to extend change detection by performing a custom check.
    //Add 'implements DoCheck' to the class.
    try {
      if (this.mx.data) {
        // console.log("onchanges if", " ", this.mx.fieldname, " ", this.mx.data);
        this.showTexbox = true;
        if (this.mx.fieldname == 'q7_ans') {
          // for (let i = 0; i < this.mx.data.split(',').length; i++) {
          //   this.tags.push(this.mx.data.split(',')[i]);
          // }
          if (this.mx.data.length > 0) {
            this.tags = this.mx.data;
          } else {
            this.showTexbox = false;
          }
          // this.tag = this.mx.data.toString();
        }
        if (this.mx.fieldname == 'career_status') {
          this.selectedCareerStatus = this.mx.data;        
        }
        if(this.mx.fieldname == 'external_links' && this.mx.data.length == 0) {
          this.showTexbox = false;
        }
        // this.mx.data = this.addLineBreaks(this.mx.data);
      } else {
        // console.log("onchanges else", " ", this.mx.fieldname, " ", this.mx.data);
        this.showTexbox = false;
      }
    } catch (e) { //console.log("e",e); 
      this.showTexbox = false;
    }
    if (!this.edit) {
      this.EditToggle = false;
    }
  }

  ngOnChanges() {
    try {
      if (this.mx.data) {
        // console.log("onchanges if", " ", this.mx.fieldname, " ", this.mx.data);
        this.showTexbox = true;
        if (this.mx.fieldname == 'q7_ans') {
          // for (let i = 0; i < this.mx.data.split(',').length; i++) {
          //   this.tags.push(this.mx.data.split(',')[i]);
          // }
          if (this.mx.data.length > 0) {
            this.tags = this.mx.data;
          } else {
            this.showTexbox = false;
          }
          // this.tag = this.mx.data.toString();
        }
        if (this.mx.fieldname == 'career_status') {
          this.selectedCareerStatus = this.mx.data;        
        }
        // this.mx.data = this.addLineBreaks(this.mx.data);
      } else {
        // console.log("onchanges else", " ", this.mx.fieldname, " ", this.mx.data);
        this.showTexbox = false;
      }
    } catch (e) { //console.log("e",e); 
      this.showTexbox = false;
    }
    if (!this.edit) {
      this.EditToggle = false;
    }
  }


  showText() {
    this.showTexbox = true;
    this.EditToggle = true;
  }


  hideBtn() {
    //document.getElementsByClassName('cmw')[0]['style'].display = 'none';
  }

  tag: any;

  addTag() {
    if (this.tag) {
      let lastChar = this.tag.charAt(this.tag.length - 1);
      let firstChar = this.tag.charAt(0);
      if (lastChar == ',') {
        this.tag = this.tag.substr(0, this.tag.length - 1);
      }
      if (firstChar == ',') {
        this.tag = this.tag.slice(1, this.tag.length);
      }
      // this.tags = [];
      for (let i = 0; i < this.tag.split(',').length; i++) {
        this.tags.push(this.tag.split(',')[i]);
      }
      let body = {
        q7_ans: this.tags
      };
      let people_id = sessionStorage.getItem("currentUserSlug");
      if (this.service.publicUser) {
        people_id = this.service.publicUser;
      }
      this.service.doUserProfilePatch(people_id, body).then(result => {
        if (!this.tag.trim()) {
          console.log('false me');
          this.showTexbox = false;
        } else {
          console.log('true me');
          this.showTexbox = true;
        }
        this.tag = '';
        this.mx.data = this.tags;
        this.EditToggle = false;
      });
    }
  }

  updateCareerStatus() {

    let people_id = sessionStorage.getItem("currentUserSlug");
    if (this.service.publicUser) {
      people_id = this.service.publicUser;
    }
    let body = {
      career_status: this.selectedCareerStatus
    };
    this.service.doUserProfilePatch(people_id, body).then(result => {
      if (_.isEmpty(this.selectedCareerStatus)) {
        console.log('false me');
        this.showTexbox = false;
      } else {
        console.log('true me');
        this.showTexbox = true;
      }
      this.mx.data = this.selectedCareerStatus;
      this.EditToggle = false;
    });
  }

  removeCareer(index) {
    this.selectedCareerStatus.splice(index, 1);
    this.updateCareerStatus();
  }

  removeHobbies(i) {
    // console.log(this.data);
    this.tags.splice(i, 1);
    console.log(this.tags);
    let body = {
      q7_ans: this.tags
    };
    let people_id = sessionStorage.getItem("currentUserSlug");
    if (this.service.publicUser) {
      people_id = this.service.publicUser;
    }
    this.service.doUserProfilePatch(people_id, body).then(result => {
      console.log('deleted');
    });
  }

  onEnterDesc() {
    // if(!value.trim()){
    //   alert('field cannot be blank');
    //   console.log("empty dala");
    //   return;
    // }
    // console.log(this.Elem.nativeElement.value);
    // return;
    let para;
    if(this.Elem.nativeElement.value.trim()) {
      para = this.service.addLineBreaks(this.Elem.nativeElement.value);
    } else {
      para = '';
    }

    let body = {
      [this.mx.fieldname]: para
    };

    //this.toggleVisibitly = true;
    let people_id = sessionStorage.getItem("currentUserSlug");
    if (this.service.publicUser) {
      people_id = this.service.publicUser;
    }
    this.service.doUserProfilePatch(people_id, body).then(result => {

      //this.toggleVisibitly = false;
      try {
        document.getElementsByClassName('cmw')[0]['style'].display = 'inline-block';
      } catch (e) { console.log('hide edit btn try'); }
      if (!this.Elem.nativeElement.value.trim()) {
        this.showTexbox = false;
      } else {
        this.showTexbox = true;
      }
      this.mx.data = this.Elem.nativeElement.value;
      this.EditToggle = false;
      console.log(result);
    });
  }

}

