import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { NgModule, Injectable, ErrorHandler, NO_ERRORS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { appRoutes, routing } from './app.routing';
import { ProjectService } from './projectservice';
import { HttpModule } from '@angular/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomePageMapComponent } from './homepagemapcomponent/homepagemap.component';
import { ProjectCreate } from './projectcreatecomponent/projectcreate.component';
import { CreateProfileComponent } from './createprofilecomponent/createprofile.component';
import { LoginComponent } from './logincomponent/login.component';
import { JsonpModule } from '@angular/http';
import { FileUploadModule } from 'ng2-file-upload';
// import { ProjectDetailComponent } from './projectdetailcomponent/projectdetail.component';
import { ProjectListComponent } from './projectlistcomponent/projectlist.component';
import { ListProjectComponent } from './listprojectcomponent/listproject.component';
import { SpinnerComponent } from './spinnercomponent/spinner.component';
import { UploadImageComponent } from './uploadimagecomponent/uploadimage.component';
// import { UploadImageComponent1 } from './uploadimagedetailpage/uploadimage1.component';
// import { NavbarComponent } from './navbar/navbar.component';
// import { EditboxComponent } from './editboxcomponent/editbox.component';
import { ImagePreview } from './imagepreviewcomponent/imagepreview.component';
// import { TextboxComponent } from './textboxcomponent/textbox.component';

// import { FooterComponent } from './footerComponent/footer.component';
import { LoginregComponent } from './loginregcomponent/loginreg.component';
import { ContactPage } from './contactpagecomponent/contactpage.component';
// import { CookieService } from 'angular2-cookie/services/cookies.service';
import { CookieService, CookieOptions } from 'angular2-cookie/core';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';
// import { MaterialModule } from '@angular/material';
import { MultiselectDropdown } from './multiselectdropdown/multiselectdropdown.component';
// import { AddContributors } from './addcontributors/addcontributors.component';
// import { AddProjectCost } from './fundingandcostcomponent/fundingcost.component';
// import { AddAwards } from './awardscomponent/awards.component';
// import { AddReferences } from './referencescomponent/references.component';
import { UserProfileComponent } from './userprofilecomponent/userprofile.component';
import { ManageProjects } from './manageprojectscomponent/manageprojects.component';
import { TextboxProfileComponent } from './textboxprofilecomponent/textboxprofile.component';
// import { KeysPipe } from './textboxprofilecomponent/textboxprofile.component';
import { TextboxCompanyProfileComponent } from './textboxcompanyprofile/textboxcompany.component';
import { Account } from './accountcomponent/account.component';
import { AddprojectDetails } from './addprojectdetails/addprojectdetails.component';
import { CompanyProfile } from './companyprofilecomponent/companyprofile.component';
import { UserProfileTextboxComponent } from './userprofiletextboxtcomponent/userprofiletextbox.component';
import { CompanyProfileTextboxComponent } from './companyprofiletextboxcomponent/companyprofiletextbox.component';
import { UserProjectsComponent } from './userprojectscomponent/userprojects.component';
import { CompanyProjectsComponent } from
  './companyprojectscomponent/companyprojects.component';
import { CompanyCreate } from './companycreatecomponent/createcompany.component';
import { Ng2AutoCompleteModule } from 'ng2-auto-complete';
import { TooltipModule } from 'ngx-tooltip';
// import { AgmCoreModule, LatLngLiteral, GoogleMapsAPIWrapper } from 'angular2-google-maps/core';
import {AutosizeModule} from 'ngx-autosize';
import { UserListComponent } from './userlistcomponent/userlist.component';
import { PopoverModule } from 'ngx-popover';
import { EmailComponent } from './emailsendingcomponent/email.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ImageCropperComponent, CropperSettings, Bounds, ImageCropperModule } from 'ng2-img-cropper';
import { NguiAutoCompleteModule } from '@ngui/auto-complete';
// import { Ng2FileDropModule }  from 'ng2-file-drop';
// import { FileDroppa } from 'file-droppa';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import {NgxPaginationModule} from 'ngx-pagination';
import { Login } from './securelogincomponent/securelogin.component';
import { Ng2ScrollableModule } from 'ng2-scrollable';
// import { CeiboShare } from 'ng2-social-share';
import { CreatePerson } from './createpersoncomponent/createperson.component';
import { ClaimCompany } from './companyclaimcomponent/claimcompany.component';
import { ClaimPerson } from './personclaimcomponent/claimperson.component';
import { CreateAccount } from './createaccountcomponent/createaccount.component';
// import { FeedbackComponent } from './feedbackcomponent/feedback.component';
import { Agreement } from './agrrementcomponent/agreement.component';
import { ForgotPassword } from './forgotpasswordcomponent/forgotpassword.component';
import { RestAuth } from './restauthlandingcomponent/restauth.component';
import { BrowserXhr } from '@angular/http';
// import { NgProgressModule, NgProgressBrowserXhr } from 'ngx-progressbar';
import { NgProgressModule } from '@ngx-progressbar/core';
import { NgProgressRouterModule } from '@ngx-progressbar/router';
import { NgProgressHttpClientModule } from '@ngx-progressbar/http-client';
import { NgProgressHttpModule } from '@ngx-progressbar/http';
import { RegisterForm } from './registercomponent/register.component';
// import { ScrollEventModule } from 'ngx-scroll-event';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CommitmentscomponentComponent } from './companyprofilecomponent/commitmentscomponent/commitmentscomponent.component';
import { AlertCComponent } from './alert-c/alert-c.component';
import { PaymentmaincomponentComponent } from './paymentmodule/paymentmaincomponent/paymentmaincomponent.component';
import { PaymentcorecomponentComponent } from './paymentmodule/paymentcorecomponent/paymentcorecomponent.component';
// import { ConfirmCComponent } from './confirm-c/confirm-c.component';
import { ImageViewerModule } from 'ngx-image-viewer';
import { ShareButtonModule } from '@ngx-share/button';
// import { NotificationBarModule } from 'angular2-notification-bar';
import { LinkyModule } from 'ngx-linky';
import { library } from '@fortawesome/fontawesome-svg-core';
import { NgxLinkifyjsModule } from 'ngx-linkifyjs';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons/faFacebookSquare';
import { faTwitterSquare } from '@fortawesome/free-brands-svg-icons/faTwitterSquare';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin';
import { SharedModule } from './shared/shared.module';
import { AuthGuardService } from './auth-guard.service';
import { DomService } from './dom.service';
import {Injector} from '@angular/core';
import { MessagingComponent } from './messaging/messaging.component';
import { EditprofilemystoryComponent } from './userprofilecomponent/editprofilemystory/editprofilemystory.component';
import { CareerComponent } from './career/career.component';
import { ChatComponent } from './chat/chat.component';
import { CareerJobListingComponent } from './career/career-job-listing/career-job-listing.component';

export let InjectorInstance: Injector;
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { StudentTeacherComponent } from './createaccountcomponent/student-teacher/student-teacher.component';
import { StudentDashboardComponent } from './student-dashboard/student-dashboard.component';
import { AddprojectlessonComponent } from './addprojectlesson/addprojectlesson.component';
import { LessonplansComponent } from './lessonplans/lessonplans.component';
import { ReadMoreComponent } from './read-more/read-more.component';
import { TeacherStudentGuideComponent } from './lessonplans/teacher-student-guide/teacher-student-guide.component';
import { InspireComponent } from './inspire/inspire.component';
import { Covid19Component } from './covid19/covid19.component';
import { PageNotFoundComponent } from './404';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { ProjectsorderComponent } from './projectsorder/projectsorder.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { TransferHttpModule, TransferHttpService } from '@gorniv/ngx-transfer-http';
import { DialogueComponent } from './dialogue/dialogue.component';
import { LandingCardComponent } from './landing-card/landing-card.component';
import { CoreModule } from './new-repicture/core/core.module';
import { SharedModuleNew } from './new-repicture/shared/shared.module';
import { LoaderInterceptor } from './new-repicture/interceptors/loader-interceptor';
import { GetInTouchComponent } from './new-repicture/pages/get-in-touch/get-in-touch.component';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from 'src/environments/environment';
// import { LinkedinResponseComponent } from './linkedin-login/linkedin-response/linkedin-response.component';
// import { HttpErrorInterceptorProvider } from './404-interceptor.service';
const icons = [
  faFacebookSquare,
  faTwitterSquare,
  faLinkedin
];

const config = {
  btnClass: 'default', // The CSS class(es) that will apply to the buttons
  zoomFactor: 0.1, // The amount that the scale will be increased by
  containerBackgroundColor: '#ccc', // The color to use for the background. This can provided in hex, or rgb(a).
  wheelZoom: true, // If true, the mouse wheel can be used to zoom in
  allowFullscreen: true, // If true, the fullscreen button will be shown, allowing the user to entr fullscreen mode
  allowKeyboardNavigation: true, // If true, the left / right arrow keys can be used for navigation
  btnIcons: { // The icon classes that will apply to the buttons. By default, font-awesome is used.
    zoomIn: 'glyphicon glyphicon-zoom-in',
    zoomOut: 'glyphicon glyphicon-zoom-out',
    rotateClockwise: 'fa fa-repeat',
    rotateCounterClockwise: 'fa fa-undo',
    next: 'glyphicon glyphicon-chevron-right',
    prev: 'glyphicon glyphicon-chevron-left',
    fullscreen: 'glyphicon glyphicon-fullscreen',
  },
  btnShow: {
    zoomIn: true,
    zoomOut: true,
    rotateClockwise: false,
    rotateCounterClockwise: false,
    next: true,
    prev: true
  }
};

library.add(...icons);

const shareProp = {
  facebook: {
    icon: ['fab', 'facebook-square']
  },
  twitter: {
    icon: ['fab', 'twitter-square']
  },
  linkedin: {
    icon: ['fab', 'linkedin']
  }
};

@NgModule({
  declarations:
    [
      // CeiboShare,
      AppComponent,
      UploadImageComponent,
      // UploadImageComponent1,
      // MultiselectDropdown,
      ImagePreview,
      LoginregComponent,
      // NavbarComponent,
      // TextboxComponent,
      // EditboxComponent,
      SpinnerComponent,
      ProjectListComponent,
      ListProjectComponent,
      // ProjectDetailComponent,
      HomePageMapComponent,
      ProjectCreate,
      CreateProfileComponent,
      LoginComponent,
      // FooterComponent,
      // AddContributors,
      // AddProjectCost,
      // AddAwards,
      // AddReferences,
      // AddprojectDetails,
      UserProfileComponent,
      TextboxProfileComponent,
      UserListComponent,
      EmailComponent,
      ContactPage,
      CompanyProfile,
      TextboxCompanyProfileComponent,
      UserProfileTextboxComponent,
      UserProjectsComponent, 
      CompanyProfileTextboxComponent,
      CompanyProjectsComponent,
      CompanyCreate,
      ManageProjects,
      Account,
      Login,
      CreatePerson,
      CreateAccount,
      ClaimCompany,
      ClaimPerson,
      // FeedbackComponent,
      Agreement,
      ForgotPassword,
      RestAuth,
      RegisterForm,
      CommitmentscomponentComponent,
     // AlertCComponent,
      PaymentmaincomponentComponent,
      PaymentcorecomponentComponent,
      MessagingComponent,
      EditprofilemystoryComponent,
      CareerComponent,
      ChatComponent,
      CareerJobListingComponent,
      StudentTeacherComponent,
      StudentDashboardComponent,
      AddprojectlessonComponent,
      LessonplansComponent,
      ReadMoreComponent,
      TeacherStudentGuideComponent,
      InspireComponent,
      Covid19Component,
      // ConfirmCComponent
      PageNotFoundComponent,
      ProjectsorderComponent,
      DialogueComponent,
      LandingCardComponent,
      GetInTouchComponent
      // LinkedinResponseComponent
      // KeysPipe
    ],
  entryComponents: [DialogueComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'my-app' }),
    BrowserTransferStateModule,
    TransferHttpModule,
    ReactiveFormsModule,
    SharedModule,
    LinkyModule,
    NgxLinkifyjsModule.forRoot(),

    ImageViewerModule.forRoot(config),

    MultiselectDropdownModule,
    FileUploadModule,
    ShareButtonModule.forRoot({ prop: shareProp }),

    CommonModule,
    FormsModule,
    HttpModule,
    HttpClientModule,
    NgProgressModule.forRoot(),
    NgProgressRouterModule,
    NgProgressHttpClientModule,
    NgProgressHttpModule,
    ImageCropperModule,
    
    JsonpModule,
    Ng2AutoCompleteModule,
    NguiAutoCompleteModule,
   
    BrowserAnimationsModule,
 
    Ng2ScrollableModule,

    TooltipModule,
    PopoverModule,
    NgProgressModule,
   
    InfiniteScrollModule,
    ModalModule.forRoot(),
    PdfJsViewerModule,
    MatAutocompleteModule,
   
    DragDropModule,
    CoreModule,
    SharedModuleNew,

    RouterModule.forRoot(appRoutes,routing)
    
  ],
  providers: [ProjectService, CookieService, { provide: CookieOptions, useValue: {} }, AuthGuardService, DomService,TransferHttpService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA]
})
export class AppModule {
  constructor(private injector: Injector) 
  {
    InjectorInstance = this.injector;
  }
 }
