import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ProjectService } from '../projectservice';
import { DomSanitizer, Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-lessonplans',
  templateUrl: './lessonplans.component.html',
  styleUrls: ['./lessonplans.component.css']
})
export class LessonplansComponent implements OnInit {
  registerForm: FormGroup;
  submitted: boolean = false;
  three = "<b>Best for:</b> College or high school introduction to engineering classes<br>" +
	"<br>" +
	"<b>Benefit of Lesson Plan:</b> Helps students discover different types of engineering careers and engineering projects that excite them<br>" +
	"<br>" +
	"<b>Summary:</b> Students research their chosen major using RePicture.com and the U.S. Bureau of Labor Statistics. Using RePicture they find an engineer and/or engineering project that resonates with them.<br>" +
	"<br>" +
	"<b>What RePicture Offers:</b><br>" +
	"• Profiles of engineers that match the demographics (e.g., gender, race) of the U.S.<br>" +
	"students, so students can find an engineer that “looks” like them.<br>" +
	"• Ability to search for engineers and projects based on key words, such as “energy” for<br>" +
	"students interested in clean energy.<br>" +
	"• Chance for student to learn about how the engineering projects benefit society and/or<br>" +
	"the environment.<br>" +
  "<br>" +
  "<b>Feedback: </b>In many cases, the students have found something valuable on RePicture.com. It certainly added to the experience... RePicture.com is a truly valuable portal into the world of engineering for students who are studying AND graduating. – Charles Riley, Oregon Institute of Technology" +
	"<br>" +
	"<br>" +
	"<b>To Use RePicture:</b> Students can go to RePicture.com and use the key word search and filters to find projects and professionals. Note that currently the majority of professional and projects on the site are related to civil engineering. We are continually adding more projects and people in other STEM disciplines.<br>" +
	"Students can use the project filters to find project write-ups that are more complete, such as selecting that all four of the Project Details (last item under filter) must be complete, or filter for only projects with a video.<br>" +
	"Students can use the people filters to find people write-ups that are more complete, such as filtering on people with a video, or people with a project.<br>" +
	"<br>" +
	"<b>Assignment</b><br>" +
	"<br>" +
  "Investigate your chosen major and opportunities using the U.S. Bureau of Labor Statics and RePicture.com. Provide a short summary of what you learned about your major from the U.S. Bureau of Labor Statics site and discuss one or two projects or professionals on RePicture.com that interests you.<br>";
  
  four = "<b>Best for:</b> All girls high school STEM class or club<br>" +
	"<br>" +
	"<b>Benefit of Lesson Plan:</b> Helps girls understand the important role they can have in impacting engineering projects and change their perceptions of who are engineers.<br>" +
	"<br>" +
	"<b>Summary:</b> Students learn about the benefits of engineering and the benefits of having diversity in design. They then use the engineering design process to brainstorm ways to increase diversity in engineering and discuss RePicture’s use to change perceptions about engineering<br>" +
	"<br>" +
	"<b>Assignment</b><br>" +
	"<br>" +
  "Contact edu@RePicture.com to request the lesson plan.<br>";
  
  five = "<b>Best for:</b> College introduction to engineering classes or high school after school programs, especially targeted to girls<br>" +
	"<br>" +
	"<b>Benefit of Lesson Plan:</b> Helps all students understand the breadth of work engineers and scientist do. Also provides girls and other underrepresented groups role models so they see that they “belong” in STEM<br>" +
	"<br>" +
	"<b>Summary:</b> Either the teacher can provide short summary about one engineer found on RePicture.com at the beginning of each class, or a student can present a summary at the beginning of the club meeting.<br>" +
	"<br>" +
	"<b>What RePicture Offers:</b><br>" +
	"<br>" +
	"Profiles of engineers that match the demographics (e.g., gender, race) of the U.S. students, so students can find an engineer that “looks” like them.<br>" +
	"<br>" +
	"Ability to search for engineers based on key words, such as “chemical” for students interested in chemical engineering.<br>" +
	"<br>" +
	"Chance for student to learn about the work STEM professionals, such as the projects they work on, what their typical day is like, and what skills have been most important to their career.<br>" +
	"<br>" +
	"<b>To Use RePicture:</b> Teachers or students can go to RePicture.com and use the key word search and filters to find professionals that interest them. Note that currently the majority of professional on the site are related to civil engineering, but most engineering disciplines have at least one or more professional on the site. We are continually adding more people in other STEM disciplines.<br>" +
	"<br>" +
	"Students can use the people filters to find people write-ups that are more complete, such as filtering on people with a video, or people with a project.<br>" +
	"<br>" +
	"<b>Assignment</b><br>" +
	"<br>" +
	"<b>Teacher:</b> The teacher can use a profile on RePicture at the beginning of each introduction to engineering class to showcase the various work engineers do. At the beginning of each class, the teacher can present a short summary such as of the type of engineer, example project, and typical day at work. Students can be encouraged to go to the RePicture profile for more information, if they want more information.<br>" +
	"<br>" +
	"<b>Students:</b> Student find a STEM professional on RePicture.com who interests them. The students can then rotate giving a short presentation at the beginning of each class or club about the STEM person.<br>";
	html: any;
  
  constructor(public service: ProjectService, private formBuilder: FormBuilder, private sanitizer: DomSanitizer, private title: Title, private meta: Meta) { }

  ngOnInit() {
	this.title.setTitle('STEM Lesson Plans for High School and College | RePicture');
	this.meta.updateTag({ property: 'og:title', content: 'STEM Lesson Plans for High School and College | RePicture' });
	this.meta.updateTag({ property: 'og:name', content: 'STEM Lesson Plans for High School and College | RePicture' });
	this.meta.updateTag({ property: 'og:description', content: 'Free teacher and professor resources for engineering and science.  Lesson plans, activity guides, grading rubrics, and project ideas for high school and college inquiry.' });
	this.meta.updateTag({ name: 'name', content: 'STEM Lesson Plans for High School and College | RePicture' });
	this.meta.updateTag({ name: 'description', content: 'Free teacher and professor resources for engineering and science.  Lesson plans, activity guides, grading rubrics, and project ideas for high school and college inquiry.' });
    this.registerForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
	});
	this.initializeVideo();
  }

  submitNotifEmail() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
  }
    let obj = {
      'email': this.registerForm.value['email'],
      'to_email': 'info@repicture.com',
      'subject': 'Lesson plans'
    }
    this.service.sendJobUpdates(obj).then(res => {
      this.registerForm.reset();
      this.submitted = false;
      this.service.sendAlert('Thank you', 'You will be notified for updates.', 1);
    });
  }

// convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }

  initializeVideo() {
	const id = this.getId('https://youtu.be/V4cD1eCUNfM');
	let replacement = '<iframe style="border: none; margin-top: 5px; width: 100%; height: 100%;" src="https://www.youtube.com/embed/' + id + '" allowfullscreen></iframe>';
	this.html = this.sanitizer.bypassSecurityTrustHtml(replacement);
}

getId(url) {
  let regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  let match = url.match(regExp);

  if (match && match[2].length == 11) {
	return match[2];
  } else {
	return 'error';
  }
}

}
